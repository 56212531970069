import React from "react";

import {useQuery} from "@tanstack/react-query";
import {useAuth} from "../../../contexts/AuthContext";
import ListViewTable, {TableLink,} from "../../elements/tables/ListViewTable";
import {useNavigate, useParams} from "react-router-dom";
import {deleteEntry, findEntry, getQuestionnaireDetail, getQuestionnaireEntryExcel} from "../../../api/questionnaires";
import {DownloadButton} from "../../elements/Buttons";
import checkPermission from "../../../utils/permissions";


export default function QuestionnaireIndex() {

    const {currentUser, permissions} = useAuth();
    const navigate = useNavigate()
    const {questionnaireId} = useParams();

    const {data: questionnaire} = useQuery(
        ["questionnaire_detail", questionnaireId, currentUser.user_id],
        () => getQuestionnaireDetail(questionnaireId)
    )

    async function findEntryAndRedirect(targetObjectId) {
        const entry = await findEntry(questionnaireId, targetObjectId);
        if (entry) navigate(`/questionnaires/entries/${entry.id}/edit`);
    }

    async function restart(entryId, targetObjectId) {
        // delete the entry and then re-create
        try {
            await deleteEntry(entryId)
        } catch (e) {}
        return findEntryAndRedirect(targetObjectId)
    }

    if (!questionnaire) return <></>

    let columns = [
        {
            accessorKey: "target_object.full_name",
            id: "name",
            header: "Name",
            enableColumnFilter: false,
        },
        {
            accessorFn: (row) => row.result_entry_id ? "Abgeschlossen" : row.entry_id ? "Begonnen" : "Ausstehend",
            id: "status",
            header: "Status",
            headerClassNames: "medium-filter",
            enableColumnFilter: true,
            filterFn: "containsString"
        },
        {
            accessorKey: "entry_id",
            id: "entry",
            cell: ({row, value}) => (
                 <div className="flex gap-2 justify-between" key={row.original.target_object.id}>
                     {questionnaire.can_edit && <>
                    {row.original.entry_id ? <>
                        {!row.original.result_entry_id && <TableLink key="edit"
                                                        label="Bearbeiten"
                                                        to={`/questionnaires/entries/${row.original.entry_id}/edit`}
                        />}
                        {row.original.result_entry_id && <TableLink key="edit"
                                                                     label="Neu starten"
                                                                     onClick={() => restart(row.original.entry_id, row.original.target_object.id)}
                                                                     to={`#`}
                        />}
                    </>: <TableLink key="start"
                                    label="Start"
                                    to={'#'}
                                    onClick={() => findEntryAndRedirect(row.original.target_object.id)}
                    />}
                     </>}
                     </div>

            ),
            header: "Bearbeiten",
            enableColumnFilter: false,
            disableRowLink: true
        },
        {
            accessorKey: "target_object",
            id: "result",
            cell: ({row, value}) => (
                <>
                    {row.original.result_entry_id && <div className="flex gap-2 justify-between">
                        <TableLink key="result" label="Ergebnis"
                                   to={`/questionnaires/entries/${row.original.entry_id}/result`}
                        />
                    </div>}
                </>
            ),
            header: "Ergebnis",
            enableColumnFilter: false,
            disableRowLink: true
        }
    ]

    const canDownload = checkPermission(permissions, "forms.change_questionnaire")

    return (

        <>
            <main className="py-10 h-full bg-gray-100">
                <div
                    className="max-w-3xl mx-auto px-4 sm:px-6 md:flex md:items-center md:justify-between md:space-x-5 lg:max-w-7xl lg:px-8">
                    <div className="flex items-center space-x-5">
                        <div>
                            <h1 className="text-2xl font-bold text-gray-900">
                                {questionnaire.title}
                            </h1>
                            {/*<p className="text-sm font-medium text-gray-500">*/}
                            {/*    {tutoring.student.student_number}, Klasse {tutoring.student.school_class}*/}
                            {/*</p>*/}
                        </div>
                    </div>
                    <div
                        className="mt-6 flex flex-col-reverse justify-stretch space-y-4 space-y-reverse sm:flex-row-reverse sm:justify-end sm:space-x-reverse sm:space-y-0 sm:space-x-3 md:mt-0 md:flex-row md:space-x-3">

                        {canDownload && <DownloadButton
                            apiFunction={() => getQuestionnaireEntryExcel(questionnaireId)}
                            fileName={`Fragebogen.xls`}
                            label="Excel herunterladen"/>}
                    </div>
                </div>

                <div
                    className="mt-4 max-w-3xl mx-auto grid grid-cols-1 gap-6 sm:px-6 lg:max-w-7xl lg:grid-flow-col-dense lg:grid-cols-3">
                    <div className="space-y-6 lg:col-start-1 lg:col-span-2">

                        <section aria-labelledby="questionnaire-info">
                            <div className="bg-white shadow sm:rounded-lg">

                                <div className="border-t border-gray-200 px-4 py-5 sm:px-6">

                                    {questionnaire.closed && <div className="">
                                        <p className="text-md whitespace-pre-wrap">
                                            {questionnaire.closed === 'before' ? questionnaire.closed_message_before : questionnaire.closed_message_after}
                                        </p>
                                    </div>}

                                    {!questionnaire.closed && <div className="">
                                        <p className="text-md text-gray-900 whitespace-pre-wrap" dangerouslySetInnerHTML={{__html: questionnaire.content}}>
                                        </p>
                                    </div>}

                                </div>
                            </div>
                        </section>

                        {!questionnaire.closed && <section aria-labelledby="entry-list">
                            <div className="bg-white shadow sm:rounded-lg">
                                <div className="border-t border-gray-200">
                                    <div className="inline-block min-w-full py-2 align-middle">
                                        {questionnaire.lines && (
                                            <ListViewTable
                                                data={questionnaire.lines}
                                                columns={columns}
                                                isLoading={false}
                                                disableGlobalSearch={false}
                                                rowSelection={null}
                                                setRowSelection={null}
                                                onDelete={null}
                                            />
                                        )}
                                    </div>
                                </div>
                            </div>
                        </section>}
                    </div>

                    <section aria-labelledby="right-section" className="lg:col-span-1 lg:col-start-3">
                        {/* section on the right if needed */}
                    </section>
                </div>


            </main>
        </>


    );
}
