import {useParams} from "react-router-dom";
import {React, useEffect, useState} from "react";
import {SecondaryButton} from "../../elements/Buttons";
import checkPermission from "../../../utils/permissions";
import {useAuth} from "../../../contexts/AuthContext";
import ListViewTable from "../../elements/tables/ListViewTable";
import {toast} from "react-toastify";
import {DeleteButton} from "../../elements/DeleteButton";
import {formatDate} from "../../../utils/formatters";
import {attendanceApi, getCareerStudentDetail} from "../../../api/career";
import {useUserInfo} from "../../../contexts/UserInfoContext";


export default function StudentDetail() {

    const {studentId} = useParams();
    const {renderUserImage} = useUserInfo();
    const {permissions} = useAuth();
    const [data, setData] = useState()

    const canDeleteAttendance = checkPermission(permissions, "career.delete_attendance")

    let columns = [
        {
            accessorFn: (row) => `${formatDate(row.date_from)} - ${formatDate(row.date_to)}`,
            id: "date",
            header: "Datum",
            enableColumnFilter: false,
        },
        {
            accessorKey: "title",
            id: "title",
            header: "Modul",
            enableColumnFilter: false,
        },
        {
            accessorKey: "category",
            id: "category",
            header: "Kategorie",
            enableColumnFilter: false,
        },
        {
            accessorKey: "costs",
            id: "costs",
            header: "Kosten",
            enableColumnFilter: false,
        },
        {
            accessorKey: "time_needed",
            id: "time_needed",
            header: "Zeitaufwand",
            enableColumnFilter: false,
        },
        {
            accessorKey: "credits",
            id: "credits",
            header: "Credits",
            enableColumnFilter: false,
        },
        ...canDeleteAttendance ? [
            {
                accessorKey: "id",
                id: "link",
                cell: ({row, value}) => (
                    <>
                        {row.original.id && <DeleteButton iconOnly={true}
                                      onDelete={() => handleDeleteAttendance(row.original.id)} />}
                    </>
                ),
                header: "",
                enableColumnFilter: false,
                disableRowLink: true
            }
        ] : []
    ]

    useEffect(() => {
        loadData()
    }, [studentId])

    async function loadData() {
        const newData = await getCareerStudentDetail(studentId)
        setData(newData)
    }

    async function handleDeleteAttendance(attendanceId) {
        if (!attendanceId) return
        try {
            await attendanceApi('delete', attendanceId)
            const newData = {...data,
                attendance_lines: data.attendance_lines.filter(row => row.id !== attendanceId)}
            setData(newData)
            toast.success("Teilnahme gelöscht.")
        } catch (e) {
            toast.error("Beim Speichern ist ein Fehler aufgetreten.")
        }
    }

    function getRowLink(row) {
        if (!row.original.module_id) return ''
        return `/career/modules/${row.original.module_id}/`;
    }

    if (!data) return <></>;
    return (
        <>
            <main className="py-10 h-full bg-gray-100">
                {/* Page header */}
                <div
                    className="max-w-3xl mx-auto px-4 sm:px-6 md:flex md:items-center md:justify-between md:space-x-5 lg:max-w-7xl lg:px-8">
                    <div className="flex items-center space-x-5">
                        <div className="flex-shrink-0">
                            <div className="relative">
                                {renderUserImage(data.student.user_id, 80)}
                                <span
                                    className="absolute inset-0 shadow-inner rounded-full"
                                    aria-hidden="true"
                                />
                            </div>
                        </div>
                        <div>
                            <h1 className="text-2xl font-bold text-gray-900">
                                {data.student.full_name}
                            </h1>
                            <p className="text-sm font-medium text-gray-500">
                                {data.student.student_number}
                            </p>
                        </div>
                    </div>
                    <div
                        className="mt-6 flex flex-col-reverse justify-stretch space-y-4 space-y-reverse sm:flex-row-reverse sm:justify-end sm:space-x-reverse sm:space-y-0 sm:space-x-3 md:mt-0 md:flex-row md:space-x-3">
                        <SecondaryButton label="Schülerblatt" link={`/school/students/${studentId}`}/>

                    </div>
                </div>

                <div
                    className="mt-8 max-w-3xl mx-auto grid grid-cols-1 gap-6 sm:px-6 lg:max-w-7xl lg:grid-flow-col-dense lg:grid-cols-4">

                    <div className="space-y-6 lg:col-start-1 lg:col-span-3">

                        <section aria-labelledby="notes-title">
                                <ListViewTable
                                    data={data.attendance_lines}
                                    columns={columns}
                                    disableGlobalSearch={true}
                                    getRowLink={null}
                                    getRowLink={getRowLink}
                                />
                        </section>

                        {/* Table */}
                        <section aria-labelledby="applicant-information-title"></section>
                    </div>

                    <section aria-labelledby="timeline-title" className="lg:col-span-1 mt-6">
                        <section aria-labelledby="applicant-information-title">
                            <div className="bg-white shadow overflow-hidden sm:rounded-lg">

                                <div className="px-4 py-5 sm:px-6">
                                    <h3 className="text-lg leading-6 font-medium text-gray-900">
                                        Credits: {data.total_credits}
                                    </h3>
                                </div>
                            </div>
                        </section>
                    </section>
                </div>

            </main>
        </>
    )
}

