import {SimplePage} from "../../elements/Pages";
import React from "react";

import {useQuery} from "@tanstack/react-query";
import {useAuth} from "../../../contexts/AuthContext";
import ListViewTable, {TableLink} from "../../elements/tables/ListViewTable";
import {DownloadButton} from "../../elements/Buttons";
import {getStudents, getStudentsBasicExcel} from "../../../api/school";
import env from "react-dotenv";


export default function StudentList() {


    const columns = [
        {
            accessorFn: (row) => `${row.last_name}, ${row.first_name} ${row.student_number}`,
            id: "student_search",
            header: "SchülerIn",
            hide: true,
            enableColumnFilter: false,
        },
        {
            accessorFn: (row) => row,
            id: "student",
            header: "SchülerIn",
            columnType: "student",
            enableColumnFilter: false,
            sortingFn: "lastNameSorting",
        },
        ...env.SCHOOL === 'KPI' ? [
        {
            accessorKey: "current_plupu_rating",
            id: "current_plupu_rating",
            header: "Status",
            columnType: "plupu_rating",
            enableColumnFilter: true,
            headerClassNames: "narrow-filter",
        }] : [],
        {
            accessorKey: "house",
            id: "house",
            header: "Mentorat",
            enableColumnFilter: true,
            headerClassNames: "narrow-filter",
        },
        {
            accessorKey: "grade",
            id: "grade",
            header: "Stufe",
            enableColumnFilter: true,
            headerClassNames: "narrow-filter",
            filterFn: "containsString",
        },
        {
            accessorKey: "school_class",
            id: "school_class",
            header: "Klasse",
            enableColumnFilter: true,
            headerClassNames: "narrow-filter",
            sortingFn: "schoolClassSorting",
            filterFn: "containsString",
        },
        {
            accessorKey: "id",
            id: "nb",
            cell: (info) => (
                <TableLink
                    label="Logbuch"
                    to={`/logbook/students/${info.getValue()}`}
                />
            ),
            disableRowLink: true,
            header: "Logbuch",
            enableColumnFilter: false,
        },
        {
            accessorKey: "id",
            id: "hw",
            cell: (info) => (
                <TableLink
                    label="Klassenbuch"
                    to={`/class-register/students/${info.getValue()}`}
                />
            ),
            disableRowLink: true,
            header: "Klassenbuch",
            enableColumnFilter: false,
        },
        {
            accessorKey: "id",
            id: "courses",
            cell: (info) => (
                <TableLink
                    label="Kurse"
                    to={`/courses/students/${info.getValue()}`}
                />
            ),
            disableRowLink: true,
            header: "Kurse",
            enableColumnFilter: false,
        },
        {
            accessorKey: "id",
            id: "projects",
            cell: (info) => (
                <TableLink
                    label="Projekte"
                    to={`/projects/students/${info.getValue()}`}
                />
            ),
            disableRowLink: true,
            header: "Projekte",
            enableColumnFilter: false,
        },
    ];


    if (env.SCHOOL === 'KPI') {
        columns.push({
            accessorKey: "is_cooperation_student",
            id: "is_cooperation_student",
            header: "Kooperation",
            columnType: "yesno",
            headerClassNames: "narrow-filter",
            enableColumnFilter: true,
            filterFn: "boolean",
        })
    }

    const {permissions} = useAuth();

    const {isLoading, isError, isSuccess, data, error, refetch} = useQuery(
        ["students"],
        getStudents,
        {refetchInterval: 1000 * 60 * 60 * 24}
    )

    function getRowLink(row) {
        return `/school/students/${row.original.id}/`;
    }

    return (
        <SimplePage>
            {/*  Simple striped table */}
            <div className="px-4 sm:px-6 lg:px-8">
                <div className="sm:flex sm:items-center">
                    <div className="sm:flex-auto">
                        <h3 className="text-lg leading-6 font-medium text-gray-900 mb-2">
                            SchülerInnen
                        </h3>
                    </div>
                    <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
                        <DownloadButton
                            apiFunction={() => getStudentsBasicExcel()}
                            fileName="Schüler.xls"
                            label="Schülerliste herunterladen (Basis)"/>
                    </div>
                </div>
                <div className="flex flex-col">
                    <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">

                        <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                            {isSuccess && data && (
                                <ListViewTable
                                    data={data}
                                    columns={columns}
                                    isLoading={isLoading}
                                    disableGlobalSearch={false}
                                    rowSelection={null}
                                    setRowSelection={null}
                                    getRowLink={getRowLink}
                                />
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </SimplePage>
    );
}
