import {React} from "react";
import {useQuery} from '@tanstack/react-query'
import {useParams} from "react-router-dom";
import {getFlatPage} from "../../../api/flat_pages";


export default function FlatPages() {

    const {slug} = useParams()
    const {data: page} = useQuery(['flat_pages', slug], () => getFlatPage(slug), {refetchInterval: 1000 * 60 * 60 * 24})

    function createMarkup() {
        if (!page) return {__html: ""}
        return {__html: page.content}
    }

    if (!page) return <></>

    return (

        <>
            <main className="py-10 h-full bg-gray-100">
                <div
                    className="max-w-3xl mx-auto px-4 sm:px-6 md:flex md:items-center md:justify-between md:space-x-5 lg:max-w-7xl lg:px-8">
                    <div className="flex items-center space-x-5">
                        <div>
                            <h1 className="text-2xl font-bold text-gray-900">{page.title}</h1>
                        </div>
                    </div>
                    <div
                        className="mt-6 flex flex-col-reverse justify-stretch space-y-4 space-y-reverse sm:flex-row-reverse sm:justify-end sm:space-x-reverse sm:space-y-0 sm:space-x-3 md:mt-0 md:flex-row md:space-x-3">
                    </div>
                </div>

                <div
                    className="mt-8 max-w-3xl mx-auto grid grid-cols-1 gap-6 sm:px-6 lg:max-w-7xl lg:grid-flow-col-dense lg:grid-cols-3">
                    <div className="space-y-6 lg:col-start-1 lg:col-span-2">
                        <div className="bg-white shadow sm:rounded-lg sm:overflow-hidden">
                            <div className="divide-y divide-gray-200">
                                <div className="px-4 py-5 sm:px-6 whitespace-pre-wrap text-sm text-gray-900">
                                    <div dangerouslySetInnerHTML={createMarkup()}></div>
                                </div>
                                <div className="mt-0">

                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </main>

        </>
    )
}
