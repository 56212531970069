import {SimplePage} from "../../elements/Pages";
import React, {useState} from "react";

import {useQuery} from "@tanstack/react-query";
import {useAuth} from "../../../contexts/AuthContext";
import ListViewTable, {TableEditLink, TableLink, TwoLineColumn,} from "../../elements/tables/ListViewTable";
import checkPermission from "../../../utils/permissions";
import {classNames} from "../../../utils/misc";
import {getSubstitutionExcel, getTimetableChanges} from "../../../api/timetables";
import {DownloadButton, PrimaryButton} from "../../elements/Buttons";
import {getAnnouncements, getKeys, purchaseRequestApi} from "../../../api/organizational";
import env from "react-dotenv";



export default function Keys() {

    const {permissions, currentUser} = useAuth();

    const {isLoading, isError, isSuccess, data, error, refetch} = useQuery(
        ["keys"],
        () => getKeys(),
        {refetchInterval: 1000 * 60 * 60 * 24}
    )

    const canIssueKeys = checkPermission(permissions, "organizational.add_keyissue")

    function getRowLink(row) {
        return `/service/keys/${row.original.id}`
    }

    const columns = [
        {
            accessorKey: "key_number",
            id: "key_number",
            header: "Nummer",
            enableColumnFilter: false,
            headerClassNames: "medium-filter",
        },
        {
            accessorKey: "key_system.name",
            id: "key_system",
            header: "Schließsystem",
            enableColumnFilter: true,
            headerClassNames: "medium-filter",
        },
        {
            accessorKey: "remarks",
            id: "remarks",
            header: "Bemerkungen",
            enableColumnFilter: false,
            headerClassNames: "medium-filter",
        },
        {
            accessorKey: "current_issue.user.full_name",
            id: "currently_with_full_name",
            header: "Aktuell bei",
            enableColumnFilter: true,
            headerClassNames: "medium-filter",
            cell: ({row, value}) => {
                if (row.original.current_issue) {
                    return canIssueKeys ? <TableLink to={`/service/keys/${row.original.current_issue.id}`} label={row.original.current_issue.user.full_name} />
                        : <>{row.original.current_issue.user.full_name}</>
                }
                return <></>
            }
        },
        ...canIssueKeys ? [ {
            accessorKey: "id",
            id: "new_issue",
            header: "Neue Ausgabe",
            enableColumnFilter: false,
            disableRowLink: true,
            cell: ({row, value}) => (
                <TableLink label="Neue Ausgabe" to={`/service/key-issues/new/${row.original.id}`}/>
            )
        }] : [],
    ]

    return (
        <SimplePage title="Schlüssel">

            <div className="px-4 sm:px-6 lg:px-8">
                <div className="sm:flex sm:items-center">
                    <div className="sm:flex-auto">
                        <h3 className="text-lg leading-6 font-medium text-gray-900">
                            Schlüssel
                        </h3>
                    </div>
                    <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
                        {canIssueKeys && <PrimaryButton
                            label="Schlüssel ausgeben"
                            link={`/service/key-issues/new`}
                        />}
                    </div>
                </div>
                <div className="flex flex-col">
                    <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
                        <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                            {isSuccess && data && data?.length > 0 && (
                                <ListViewTable
                                    data={data}
                                    columns={columns}
                                    isLoading={isLoading}
                                    disableGlobalSearch={false}
                                    getRowLink={getRowLink}
                                />
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </SimplePage>
    )
}
