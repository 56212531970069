import React from "react";
import { Route, Routes } from "react-router-dom";
import { NoPaddingPage } from "../../elements/Pages";
import MessengerIndex from "./MessengerIndex";


function MessengerRouter() {
  return (
    <Routes>
      <Route index element={<MessengerIndex />} />
      <Route path=":conversationId" element={<MessengerIndex />} />
    </Routes>
  );
}

export default function Messenger() {
  return (
    <NoPaddingPage>
      <MessengerRouter />
    </NoPaddingPage>
  );
}
