import {genericApi} from "./generic";
import http from "../services/httpService";
import httpServiceDownloads from "../services/httpServiceDownloads";
import httpNoAuthService from "../services/httpNoAuthService";

export async function cancellationApi(method, id, data) {
    return genericApi('/cancellations/', method, id, data)
}

export async function roomChangeApi(method, id, data) {
    return genericApi('/room_changes/', method, id, data)
}

export async function substitutionApi(method, id, data) {
    return genericApi('/substitutions/', method, id, data)
}

export async function getSubstitutionBySecret(id, secret) {
    const resp = await httpNoAuthService.get(`/substitutions/${id}/get_with_secret/?secret=${secret}`)
    return resp.data
}

export async function getSubstitutionExcel() {
    const resp = await httpServiceDownloads.get(`/substitutions/excel/`)
    return resp.data
}

export async function getTimetableChanges(allOrFuture) {
    const resp = await http.get(`/timetables/timetable_changes/`
    + (allOrFuture === 'all' ? `?all=true` : ``)
    )
    return resp.data
}

export async function getMyTimetableChanges() {
    const resp = await http.get(`/timetables/timetable_changes/my/`)
    return resp.data
}

export async function confirmSubstitution(substitution) {
    const data = { secret: substitution.secret }
    const resp = await httpNoAuthService.post(`/substitutions/${substitution.id}/confirm/` , data)
    return resp.data
}

export async function getTimetableChangesAula() {
    const resp = await httpNoAuthService.get(`/timetables/timetable_changes/aula/`)
    return resp.data
}

export async function getTimetableChangesTeachersLounge() {
    const resp = await httpNoAuthService.get(`/timetables/timetable_changes/teachers_lounge/`)
    return resp.data
}