import {SimplePage} from "../../elements/Pages";
import React, {useEffect, useState} from "react";
import {DownloadButton, PrimaryButton} from "../../elements/Buttons";
import {MultiSelect, Select} from "../../elements/forms/Inputs";
import {baseHandleChange} from "../../elements/forms/Form";
import {useQuery} from "@tanstack/react-query";
import {getGrades, getSchoolClasses} from "../../../api/school";
import {getHouseList} from "../../../api/boarding_school";
import {getAssessmentPeriods} from "../../../api/assessments";
import {getGradeListData, getGradeListDataExcel} from "../../../api/grades";
import Joi from "joi";
import SchoolYearFilter from "../../elements/SchoolYearFilter";
import {useSchoolYears} from "../../../contexts/SchoolYearContext";
import {validate} from "../../../utils/validation";
import Alert from "../../elements/Alert";

export default function GradeLists() {

    const [errors, setErrors] = useState([])
    const {schoolYears, currentSchoolYear} = useSchoolYears();
    const [schoolYearId, setSchoolYearId] = useState();
    const [error, setError] = useState("")
    const [gradeLists, setGradeLists] = useState()

    useEffect(() => {
        if (currentSchoolYear) setSchoolYearId(currentSchoolYear["id"]);
    }, [currentSchoolYear]);


    const [data, setData] = useState({
        assessment_period: null,
        grade_type: null,
        grades: [],
        school_classes: [],
        houses: []
    })
    const schema = {
        assessment_period: Joi.number().required().messages({ "number.base": "Bitte eine Auswahl treffen"}),
        grade_type: Joi.string().required()
    }

    const gradeTypeChoices = [
        {value: 'Epochennote'},
        {value: 'Endnote (Halbjahr)'},
        {value: 'Endnote (Ganzjahr/alle)'},
        {value: 'Klausurnoten'},
        {value: 'Klausurnoten (Durchschnitt)'},
        {value: 'Durchschnitt schriftlich'},
        {value: 'Durchschnitt schriftlich (aggregiert)'},
        {value: 'Durchschnitt'},
        {value: 'Durchschnitt (aggregiert)'}
    ]

    const {data: assessmentPeriods} = useQuery(["assessment_periods", schoolYearId], () => getAssessmentPeriods(schoolYearId), {
        refetchInterval: 1000 * 60 * 60 * 24,
        enabled: !!schoolYearId
    });
    const {data: schoolClasses} = useQuery(["school_classes"], getSchoolClasses, {refetchInterval: 1000 * 60 * 60 * 24});
    const {data: houses} = useQuery(["house_list"], getHouseList, {refetchInterval: 1000 * 60 * 60 * 24});
    const {data: grades} = useQuery(["grades"], getGrades, {refetchInterval: 1000 * 60 * 60 * 24});

    async function handleSubmit(e) {

        if (e) e.preventDefault()
        // validation of the form
        const validationErrors = validate(schema, data)
        setErrors(validationErrors)
        if (validationErrors) {
            setError("Bitte korrigiere die markierten Felder.")
            return validationErrors
        } else {
            setError("")
            return null
        }
    }

    function handleChange(evt) {
        baseHandleChange(evt, data, setData, errors, setErrors, schema)
    }

    function handleMultiSelect(selectedOptions, path) {
        let newData = {...data}
        newData[path] = selectedOptions
        setData(newData)
    }

    async function loadGradeLists(e) {
        const valErrors = await handleSubmit(e)
        if (valErrors) return
        const newData = await getGradeListData(data)
        setGradeLists(newData)
    }

    return (
        <SimplePage>
            <div className="px-4 sm:px-6 lg:px-8">
                <div className="sm:flex sm:items-center">
                    <div className="sm:flex-auto">
                        <h3 className="text-lg leading-6 font-medium text-gray-900">
                            Notenlisten
                        </h3>
                    </div>
                    <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
                    </div>
                </div>
                <div className="flex flex-col">
                    <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">

                        <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                            <SchoolYearFilter
                                schoolYears={schoolYears}
                                schoolYearId={schoolYearId}
                                setSchoolYearId={setSchoolYearId}
                            />
                        </div>

                        <div className="inline-block py-2 align-middle md:px-6 lg:px-8">

                            <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">

                                <Select
                                    path="assessment_period"
                                    label="Zeitraum"
                                    className="sm:col-span-3"
                                    options={assessmentPeriods}
                                    valueAttr="id"
                                    labelAttr="name"
                                    onChange={handleChange}
                                    errors={errors}
                                    data={data}
                                    isClearable={true}
                                    valueOnly={true}
                                />

                                <Select
                                    path="grade_type"
                                    label="Noten"
                                    className="sm:col-span-3"
                                    options={gradeTypeChoices}
                                    valueAttr="value"
                                    labelAttr="value"
                                    onChange={handleChange}
                                    errors={errors}
                                    data={data}
                                    isClearable={false}
                                    valueOnly={true}
                                />

                                <MultiSelect
                                    path="grades"
                                    label="Klassenstufe"
                                    className="sm:col-span-6"
                                    options={grades}
                                    valueAttr="id"
                                    labelAttr="name"
                                    onChange={(selectedOptions) => handleMultiSelect(selectedOptions, 'grades')}
                                    errors={errors}
                                    data={data}
                                    isClearable={true}
                                    valueOnly={true}
                                />

                                <MultiSelect
                                    path="school_classes"
                                    label="Klasse"
                                    className="sm:col-span-6"
                                    options={schoolClasses}
                                    valueAttr="id"
                                    labelAttr="group"
                                    onChange={(selectedOptions) => handleMultiSelect(selectedOptions, 'school_classes')}
                                    errors={errors}
                                    data={data}
                                    isClearable={true}
                                    valueOnly={true}
                                />

                                <MultiSelect
                                    path="houses"
                                    label="Mentorat"
                                    className="sm:col-span-6"
                                    options={houses}
                                    valueAttr="id"
                                    labelAttr="name"
                                    onChange={(selectedOptions) => handleMultiSelect(selectedOptions, 'houses')}
                                    errors={errors}
                                    data={data}
                                    isClearable={true}
                                    valueOnly={true}
                                />

                                {error && <div className="">
                                    <Alert extraClassName="py-2" variant="danger">{error}</Alert>
                                </div>}

                                <PrimaryButton label="Abrufen"
                                               onClick={loadGradeLists}></PrimaryButton>

                                {data.assessment_period && data.grade_type && <DownloadButton
                                    apiFunction={() => getGradeListDataExcel(data)}
                                    fileName={`Notenlisten.xls`}
                                    label="Excel herunterladen"/>}

                            </div>
                        </div>


                        <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">

                            <div className="overflow-scroll">
                                {gradeLists && <GradeListDetail data={gradeLists}/>}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </SimplePage>
    );
}


// TODO remove the exporting param
function GradeListDetail({data, exporting}) {

    const table = <table
        className={"min-w-full divide-y divide-gray-300 border border-gray-200" + (exporting ? "" : " mt-3 ")}>
        <thead className="bg-gray-50">

        <tr className="divide-x divide-gray-200">
            <th className={exporting ? "text-center text-xs" : "py-3.5 text-center text-sm"} key="student">
                Schüler
            </th>
            <th className={exporting ? "text-center text-xs" : "py-3.5 text-center text-sm"} key="school_class">
                Klasse
            </th>

            {data.subjects_with_marks.map((c, index) => (
                <th className={exporting ? "text-center text-xs" : "py-3.5 text-center text-sm"} key={index}>
                    {c}
                </th>
            ))}

            <th className={exporting ? "text-center text-xs" : "py-3.5 text-center text-sm"} key="space">
                &Oslash;
            </th>
            <th className={exporting ? "text-center text-xs" : "py-3.5 text-center text-sm"} key="student2">
                Schüler
            </th>

        </tr>

        </thead>

        <tbody>

        {data.students.map((student, studentIndex) => (

            <tr key={studentIndex} className="divide-x divide-y divide-gray-200">

                <td className={exporting ? "divide-y divide-gray-200 text-xs" : "divide-y divide-gray-200 py-4 pl-4 pr-3 text-sm"}
                    key="student">{student.last_name}, {student.first_name}</td>

                <td className={exporting ? "text-xs" : "py-4 pl-4 pr-3 text-sm"}
                    key="schoolClass">{student.school_class}</td>

                {data.subjects_with_marks.map((subject, index) => (
                    <td className="py-4 pl-4 pr-3 text-sm" key={index}>
                        {student.subjects[subject]}
                    </td>
                ))}

                <td className={exporting ? "divide-y divide-gray-200 text-xs" : "divide-y divide-gray-200 py-4 pl-4 pr-3 text-sm"}
                    key="avg"
                >{student.avg}</td>
                <td className={exporting ? "divide-y divide-gray-200 text-xs" : "divide-y divide-gray-200 py-4 pl-4 pr-3 text-sm"}
                    key="student"
                >{student.last_name}, {student.first_name}</td>

            </tr>
        ))}
        </tbody>

    </table>

    return table
}
