import React from "react";
import {useParams} from "react-router-dom";
import {
    getParentConferenceDay,
    getStudentAppointmentList,
    getStudentAppointmentListPDF
} from "../../../api/parent_conference";
import {SimplePage} from "../../elements/Pages";
import {DownloadButton, PrimaryButton} from "../../elements/Buttons";
import {useQuery} from "@tanstack/react-query";


export default function StudentAppointmentList() {

    const {studentId, conferenceDayId} = useParams()

    const { isLoading, isError, isSuccess, data, error, refetch } = useQuery(
        ["student_appointment_list", conferenceDayId, studentId],
        () => getStudentAppointmentList(conferenceDayId, studentId),
        {}
    )

    const {data: conferenceDay} = useQuery(
        ["parent_conference_day", conferenceDayId],
        () => getParentConferenceDay(conferenceDayId),
        {refetchInterval: 1000 * 60 * 60}
    )

    if (!data) return <></>;
    return (
        <>
            <SimplePage>
                <div className="px-4 sm:px-6 lg:px-8">
                    <div className="sm:flex sm:items-center">
                        <div className="sm:flex-auto">
                            <h3 className="text-lg leading-6 font-medium text-gray-900">
                                {data.conference_day.title} - Terminübersicht für {data.student.full_name}
                            </h3>
                        </div>
                        <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none gap-1 flex">
                            {conferenceDay.booking_open && <PrimaryButton link={`/parent-conference/${conferenceDayId}/students/${studentId}/`} label="Termine buchen" />}

                            <DownloadButton
                                apiFunction={() => getStudentAppointmentListPDF(conferenceDayId, studentId)}
                                fileName={`Termine ${data.student.full_name}.pdf`}
                                label="PDF herunterladen" />
                        </div>
                    </div>
                    <div className="flex flex-col mt-3 mb-3 scrollbarHeight">
                        <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
                            <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">

                                <div className="overflow-hidden">
                                    <table className="divide-y divide-gray-300 mt-3" style={{ borderSpacing: 0 }}>
                                        <thead className="bg-imsblue-900 text-white text-sm">
                                            <tr>
                                                <td className="text-md py-2 px-2">Uhrzeit</td>
                                                {data.siblings.map((sibling, index) => (
                                                    <td className="text-md py-2 px-2" key={index}>
                                                        {sibling.first_name}
                                                    </td>
                                                ))}
                                            </tr>

                                        </thead>

                                        <tbody>

                                        {data.days.map((day, dayIndex) => (

                                            day.slots.map((slot, index) => (

                                            <tr key={index}>
                                                <td className="py-1 px-1 border-b border-gray-200" key="slotTimeLeft">{slot.start_time}</td>
                                                {slot.sibling_slots.map((siblingSlot, siblingSlotIndex) => (
                                                    <ListSlot slot={siblingSlot}
                                                          key={siblingSlotIndex}
                                                    />
                                                ))}
                                            </tr>
                                            ))

                                        ))}

                                        </tbody>

                                    </table>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </SimplePage>
        </>
    );
}

function ListSlot({slot}) {

    var slotContent

    if (slot.status === 'blocked' || slot.blocked) {
        slotContent =
            <>
                <p className="text-sm">
                    {slot.title}
                </p>
            </>

    } else if (slot.status === 'booked') {
            slotContent =
                <>
                    <p className="text-gray-900">{slot.teacher.full_name} {slot.teacher_room && <> - {slot.teacher_room.room?.name}</>}</p>
                    {slot.additional_participants &&
                        <p className="text-gray-900">{slot.additional_participants}</p>}
                </>
    }

    return <td className={"py-1 px-1 min-w-4 border-b border-gray-200"}>{slotContent}</td>
}
