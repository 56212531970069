import {SimplePage} from "../../elements/Pages";
import React from "react";

import {useQuery} from "@tanstack/react-query";
import {useAuth} from "../../../contexts/AuthContext";
import ListViewTable, {TableEditLink} from "../../elements/tables/ListViewTable";
import {PrimaryButton} from "../../elements/Buttons";
import checkPermission from "../../../utils/permissions";
import {careerModuleApi} from "../../../api/career";
import {formatDate} from "../../../utils/formatters";


export default function Modules() {


    const {permissions} = useAuth();

    const {isLoading, isSuccess, data} = useQuery(
        ["career_modules"],
        () => careerModuleApi('get'),
        {refetchInterval: 1000 * 60 * 60 * 24}
    );

    function getRowLink(row) {
        return `/career/modules/${row.original.id}`
    }

    const canAdd = checkPermission(permissions, "career.add_module")
    const canEdit = checkPermission(permissions, "career.change_module")

    let columns = [
        {
            accessorFn: (row) => `${formatDate(row.date_from)} - ${formatDate(row.date_to)}`,
            id: "date",
            header: "Datum",
            enableColumnFilter: false,
        },
        {
            accessorKey: "title",
            id: "title",
            header: "Titel",
            enableColumnFilter: false
        },
        {
            accessorKey: "category.name",
            id: "category",
            header: "Kategorie",
            enableColumnFilter: true,
            labelAttr: "name",
            filterFn: "containsString",
        },
        {
            accessorFn: (row) => (row.costs > 0 ? `${row.costs} €` : ''),
            // accessorKey: "costs",
            id: "costs",
            header: "Kosten",
            enableColumnFilter: false,
            headerClassNames: "medium-filter"
        },
        {
            accessorKey: "time_needed",
            id: "time_needed",
            header: "Zeitaufwand",
            enableColumnFilter: false,
        },
        {
            accessorKey: "credits",
            id: "credits",
            header: "Credits",
            enableColumnFilter: false,
        },
        ...canEdit ? [
            {
                accessorKey: "id",
                id: "link",
                cell: ({row, value}) => (
                    <>
                        <TableEditLink iconOnly={true} to={`/career/modules/${row.original.id}/edit`}/>
                    </>
                ),
                header: "",
                enableColumnFilter: false,
                disableRowLink: true
            }
        ] : [],
    ];

    return (
        <SimplePage>
            <div className="px-4 sm:px-6 lg:px-8">
                <div className="sm:flex sm:items-center">
                    <div className="sm:flex-auto">
                        <h3 className="text-lg leading-6 font-medium text-gray-900 mb-2">
                            Module
                        </h3>
                    </div>
                    <div className="mt-4 sm:mt-0 sm:ml-16 flex gap-2">
                        {canAdd && <PrimaryButton link={"/career/modules/new"} label="Modul hinzufügen"/>}
                    </div>
                </div>
                <div className="flex flex-col">
                    <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">

                        <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                            {isSuccess && data && (
                                <ListViewTable
                                    data={data}
                                    columns={columns}
                                    isLoading={isLoading}
                                    disableGlobalSearch={false}
                                    getRowLink={getRowLink}
                                />
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </SimplePage>
    );
}
