import React from "react";
import {useMessenger} from "../../../contexts/MessengerContext";
import {ConversationDetailHeader} from "./ConversationDetailHeader";
import {ConversationDetailBody} from "./ConversationDetailBody";
import ListItemLoader from "../../elements/ListItemLoader";
import {ConversationDetailSubHeader} from "./ConversationDetailSubHeader";
import {ConversationFooter} from "./ConversationFooter";

const ConversationDetail = () => {
  const {
    selectedConversationId,
    selectedConversationData,
    conversationList,
    isLoadedConversationData,
  } = useMessenger();

  if (!selectedConversationId) return null;

  if (isLoadedConversationData) return <ConversationDetailLoading/>;
  if (!selectedConversationData) return <ConversationDetailLoading/>;
  if (!conversationList) return <ConversationDetailLoading/>;

  return (
    <section
      aria-labelledby="message-heading"
      className="flex h-full min-w-0 flex-1 flex-col overflow-hidden xl:order-last"
    >
      <ConversationDetailHeader/>
      <ConversationDetailSubHeader/>
      <ConversationFooter/>
      <ConversationDetailBody/>
    </section>
  );
};

function ConversationDetailLoading() {
  return (
    <ul role="list" className="w-full space-y-2 py-4 sm:space-y-4 sm:px-6 md:px-8">
      {[...Array(6)].map((item, index) => (
        <li key={index}>
          <ListItemLoader profileImage={false}/>
        </li>
      ))}
    </ul>
  )
}

export default ConversationDetail;
