import {EnvelopeIcon, PhoneIcon} from "@heroicons/react/20/solid";
import {useQuery} from "@tanstack/react-query";
import React, {useState} from "react";
import {Link, useParams} from "react-router-dom";
import {getStaffInfo} from "../../../api/staff";
import {formatDate} from "../../../utils/formatters";
import {classNames} from "../../../utils/misc";
import {useAuth} from "../../../contexts/AuthContext";
import checkPermission from "../../../utils/permissions";
import ListViewTable, {TableEditLink} from "../../elements/tables/ListViewTable";
import {useUserInfo} from "../../../contexts/UserInfoContext";


const StaffDetail = () => {

    const {permissions} = useAuth();
    const {staffId} = useParams();
    const {renderUserImage} = useUserInfo();
    const [currentTab, setCurrentTab] = useState("profile")
    const {isLoading, isError, isSuccess, data, error} = useQuery(
        ["staff", staffId],
        () => getStaffInfo(staffId),
        {refetchInterval: 1000 * 60 * 60 * 24}
    )

    const canViewStaffComplete = checkPermission(permissions, "staff.view_staff_complete")
    const canViewExtended = checkPermission(permissions, "staff.view_staff_extended")
    const canIssueKeys = checkPermission(permissions, "organizational.add_keyissue")

    let tabs = [
        {name: "Profil", value: "profile"},
    ]

    if (canViewStaffComplete) {
        tabs.push({name: "Schlüssel", value: "keys"})
    }

    const keyColumns = [
        {
            accessorKey: "key_number",
            id: "key_number",
            header: "Schlüssel",
            enableColumnFilter: false,
            headerClassNames: "medium-filter",
        },
        {
            accessorKey: "issue_date",
            id: "issue_date",
            header: "Ausgegeben am",
            columnType: "date",
            enableColumnFilter: false,
        },
        {
            accessorKey: "return_date",
            id: "return_date",
            header: "Zurückgegeben am",
            columnType: "date",
            enableColumnFilter: false,
        },
        {
            accessorKey: "created_by_full_name",
            id: "created_by_full_name",
            header: "Ausgegeben von",
            enableColumnFilter: false
        }
    ]

    function getRowLink(row) {
        return canIssueKeys ? `/service/key-issues/${row.original.id}/edit` : null
    }

    if (!data) return <></>
    return (
        <article>
            <div>
                <div className="h-24 w-full object-cover">
                </div>
                <div className="mx-auto max-w-5xl px-4 sm:px-6 lg:px-8">
                    <div className="-mt-12 sm:-mt-16 sm:flex sm:items-end sm:space-x-5">
                        <div className="flex">
                            {renderUserImage(data?.user_id, 120)}
                        </div>
                        <div
                            className="mt-6 sm:flex sm:min-w-0 sm:flex-1 sm:items-center sm:justify-end sm:space-x-6 sm:pb-1">
                            <div className="mt-6 min-w-0 flex-1 sm:hidden 2xl:block">
                                <h1 className="truncate text-2xl font-bold text-gray-900">
                                    {data?.last_name}, {data?.first_name}
                                </h1>
                            </div>
                            <div
                                className="justify-stretch mt-6 flex flex-col space-y-3 sm:flex-row sm:space-y-0 sm:space-x-4">

                                {data?.email_work && <a href={`mailto:${data?.email_work}`}>
                                    <button
                                        type="button"
                                        className="inline-flex justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-imsblue-500 focus:ring-offset-2"
                                    >
                                        <EnvelopeIcon
                                            className="-ml-1 mr-2 h-5 w-5 text-gray-400"
                                            aria-hidden="true"
                                        />
                                        <span>Email</span>
                                    </button>
                                </a>}

                                {data.user_id && <Link to={`/messenger/new?user_id=${data.user_id}`}>
                                    <button type="button"
                                            className={"cursor-pointer inline-flex items-center " +
                                                "justify-center px-4 py-2 border border-transparent text-sm font-medium " +
                                                "rounded-md shadow-sm text-white bg-blue-600 hover:bg-blue-700 " +
                                                "focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-blue-500"}
                                    ><EnvelopeIcon className="h-5 w-5 mr-2"/> Nachricht senden
                                    </button>
                                </Link>}

                            </div>
                        </div>
                    </div>
                    <div className="mt-6 hidden min-w-0 flex-1 sm:block 2xl:hidden">
                        <h1 className="truncate text-2xl font-bold text-gray-900">
                            {data?.last_name}, {data?.first_name}
                        </h1>
                    </div>
                </div>
            </div>

            {/* Tabs */}
            <div className="mt-6 sm:mt-2 2xl:mt-5">
                <div className="border-b border-gray-200">
                    <div className="mx-auto max-w-5xl px-4 sm:px-6 lg:px-8">
                        <nav className="-mb-px flex space-x-8" aria-label="Tabs">
                            {tabs.map((tab) => (
                                <a
                                    key={tab.name}
                                    href="#"
                                    onClick={() => setCurrentTab(tab.value)}
                                    className={classNames(
                                        currentTab === tab.value
                                            ? "border-imsblue-500 text-gray-900"
                                            : "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300",
                                        "whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm"
                                    )}
                                    aria-current={currentTab === tab.value ? "page" : undefined}
                                >
                                    {tab.name}
                                </a>
                            ))}
                        </nav>
                    </div>
                </div>
            </div>



            {currentTab === "profile" && <div className="tabContent">


                <div className="mx-auto mt-6 max-w-5xl px-4 sm:px-6 lg:px-8 pb-6">
                    <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">


                        <div className="sm:col-span-2">
                            <dt className="text-sm font-medium text-gray-500">Rollen</dt>
                            <dd className="mt-1 text-sm text-gray-900">{data.current_roles}</dd>
                        </div>

                        {data.subjects &&
                            <div className="sm:col-span-1">
                                <dt className="text-sm font-medium text-gray-500">Fächer</dt>
                                <dd className="mt-1 text-sm text-gray-900">{data.subjects}</dd>
                            </div>}

                        {data.houses &&
                            <div className="sm:col-span-1">
                                <dt className="text-sm font-medium text-gray-500">Mentorat</dt>
                                <dd className="mt-1 text-sm text-gray-900">{data.houses}</dd>
                            </div>}

                        {data.projects &&
                            <div className="sm:col-span-1">
                                <dt className="text-sm font-medium text-gray-500">Projekte</dt>
                                <dd className="mt-1 text-sm text-gray-900">{data.projects}</dd>
                            </div>}


                        {/*{Object.keys(data.fields).map((field) => (*/}
                        {/*  <div key={field} className="sm:col-span-1">*/}
                        {/*    <dt className="text-sm font-medium text-gray-500">{field}</dt>*/}
                        {/*    <dd className="mt-1 text-sm text-gray-900">*/}
                        {/*      {data !== undefined ? (*/}
                        {/*        <>*/}
                        {/*          {field !== "birth_date"*/}
                        {/*            ? data[field]*/}
                        {/*            : formatDate(data[field])}*/}
                        {/*        </>*/}
                        {/*      ) : (*/}
                        {/*        ""*/}
                        {/*      )}*/}
                        {/*    </dd>*/}
                        {/*  </div>*/}
                        {/*))}*/}

                        {/*<div className="sm:col-span-2">*/}
                        {/*  <dt className="text-sm font-medium text-gray-500">About</dt>*/}
                        {/*  <dd*/}
                        {/*    className="mt-1 max-w-prose space-y-5 text-sm text-gray-900"*/}
                        {/*    dangerouslySetInnerHTML={{ __html: profile.about }}*/}
                        {/*  />*/}
                        {/*</div>*/}

                    </dl>


                </div>


                <div className="mx-auto mt-6 max-w-5xl px-4 sm:px-6 lg:px-8">

                    <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-3">
                        {canViewStaffComplete &&
                            <>
                                <div className="sm:col-span-1">
                                    <dt className="text-sm font-medium text-gray-500">Personalnummer</dt>
                                    <dd className="mt-1 text-sm text-gray-900">{data.staff_number}</dd>
                                </div>
                                <div className="sm:col-span-1">
                                    <dt className="text-sm font-medium text-gray-500">Adresse</dt>
                                    <dd className="mt-1 text-sm text-gray-900">
                                        {data.street} <br/>
                                        {data.zip_code} {data.city}
                                    </dd>
                                </div>
                                <div className="sm:col-span-1">
                                    <dt className="text-sm font-medium text-gray-500">MAC-Adresse</dt>
                                    <dd className="mt-1 text-sm text-gray-900">{data.mac_address}</dd>
                                </div>
                            </>
                        }

                        {canViewExtended &&
                            <>
                                <div className="sm:col-span-1">
                                    <dt className="text-sm font-medium text-gray-500">Geburtsdatum</dt>
                                    <dd className="mt-1 text-sm text-gray-900">{formatDate(data.birth_date)}</dd>
                                </div>
                            </>
                        }

                    </dl>
                </div>

                {(canViewExtended || canViewStaffComplete) && <div className="mx-auto mt-6 max-w-5xl px-4 sm:px-6 lg:px-8">

                    <h2 className="text-lg font-medium text-gray-900 mb-2">Kontakt</h2>
                    <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-3">
                        {canViewExtended &&
                            <>
                                <div className="sm:col-span-1">
                                    <dt className="text-sm font-medium text-gray-500">Telefon</dt>
                                    <dd className="mt-1 text-sm text-gray-900">{data.phone}</dd>
                                </div>
                                <div className="sm:col-span-1">
                                    <dt className="text-sm font-medium text-gray-500">Telefon dienstlich</dt>
                                    <dd className="mt-1 text-sm text-gray-900">{data.phone_work}</dd>
                                </div>
                                <div className="sm:col-span-1">
                                    <dt className="text-sm font-medium text-gray-500">Handy</dt>
                                    <dd className="mt-1 text-sm text-gray-900">{data.mobile}</dd>
                                </div>
                            </>
                        }

                        {canViewStaffComplete &&
                            <>
                                <div className="sm:col-span-1">
                                    <dt className="text-sm font-medium text-gray-500">Email privat</dt>
                                    <dd className="mt-1 text-sm text-gray-900">{data.email_personal}</dd>
                                </div>
                                <div className="sm:col-span-1">
                                    <dt className="text-sm font-medium text-gray-500">Email geschäftlich</dt>
                                    <dd className="mt-1 text-sm text-gray-900">{data.email_work}</dd>
                                </div>
                                <div className="sm:col-span-1">
                                    <dt className="text-sm font-medium text-gray-500">Fax</dt>
                                    <dd className="mt-1 text-sm text-gray-900">{data.fax}</dd>
                                </div>
                            </>
                        }

                    </dl>
                </div>}

                {canViewStaffComplete && <div className="mx-auto mt-6 max-w-5xl px-4 sm:px-6 lg:px-8">

                    <h2 className="text-lg font-medium text-gray-900 mb-2">Personaldatensatz</h2>
                    <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">

                        <div className="sm:col-span-1">
                            <dt className="text-sm font-medium text-gray-500">Führungszeugnis</dt>
                            <dd className="mt-1 text-sm text-gray-900">{formatDate(data.police_record)}</dd>
                        </div>
                        <div className="sm:col-span-1">
                            <dt className="text-sm font-medium text-gray-500">Führerschein</dt>
                            <dd className="mt-1 text-sm text-gray-900">{formatDate(data.driving_license)}</dd>
                        </div>

                        <div className="sm:col-span-1">
                            <dt className="text-sm font-medium text-gray-500">Masernimpfung</dt>
                            <dd className="mt-1 text-sm text-gray-900">{formatDate(data.measles_vaccine)}</dd>
                        </div>
                        <div className="sm:col-span-1">
                            <dt className="text-sm font-medium text-gray-500">COVID-Schutz</dt>
                            <dd className="mt-1 text-sm text-gray-900">
                                Basisschutz: {formatDate(data.covid_vaccine)} <br/>
                                Booster: {formatDate(data.covid_booster)}
                            </dd>
                        </div>

                        <div className="sm:col-span-1">
                            <dt className="text-sm font-medium text-gray-500">Eintrittsdatum</dt>
                            <dd className="mt-1 text-sm text-gray-900">{formatDate(data.entry_date)}</dd>
                        </div>
                        <div className="sm:col-span-1">
                            <dt className="text-sm font-medium text-gray-500">Austrittsdatum</dt>
                            <dd className="mt-1 text-sm text-gray-900">{formatDate(data.exit_date)}</dd>
                        </div>

                        <div className="sm:col-span-1">
                            <dt className="text-sm font-medium text-gray-500">Stundensatz</dt>
                            <dd className="mt-1 text-sm text-gray-900">{data.hourly_rate}</dd>
                        </div>
                        <div className="sm:col-span-1">
                            <dt className="text-sm font-medium text-gray-500">Art der Anstellung</dt>
                            <dd className="mt-1 text-sm text-gray-900">{data.type_of_employment}</dd>
                        </div>

                    </dl>


                </div>}

            </div>}

            {currentTab === "keys" && <div className="tabContent">

                {canViewStaffComplete && <div className="mx-auto mt-6 max-w-5xl px-4 sm:px-6 lg:px-8">

                    <h2 className="text-lg font-medium text-gray-900 mb-2">Aktuell geführte Schlüsssel</h2>

                    <div className="flex flex-col">
                        <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
                            <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                                {isSuccess && data && data.key_issues?.length > 0 && (
                                    <ListViewTable
                                        noTopSpace={true}
                                        data={data.key_issues}
                                        columns={keyColumns}
                                        isLoading={isLoading}
                                        disableGlobalSearch={true}
                                        getRowLink={getRowLink}
                                    />
                                )}
                            </div>
                        </div>
                    </div>

                </div>}

            </div>}




        </article>
    );
};

export default StaffDetail;
