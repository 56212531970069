import {NarrowPage} from "../../elements/Pages";
import {React, useEffect, useState} from "react";
import {Checkbox, CheckboxGroup, DateInput, Input, RadioGroup, TextArea} from "../../elements/forms/Inputs"
import {FormSectionContent} from "../../elements/forms/FormLayout"
import Form, {baseHandleDateListChange, baseHandleListChange} from "../../elements/forms/Form"
import {useNavigate, useParams} from "react-router-dom";
import {toast} from "react-toastify";
import {
    addFileToStepEntry,
    deleteEntry,
    findEntry,
    getEntry,
    getEntryOptions,
    patchEntry
} from "../../../api/questionnaires";
import {PrimaryButton, SecondaryButton} from "../../elements/Buttons";
import Alert from "../../elements/Alert";


export default function EditEntry() {

    const {entryId} = useParams()

    const [options, setOptions] = useState()
    const [data, setData] = useState()
    const [errors, setErrors] = useState({}) // validation errors
    const [listErrors, setListErrors] = useState([])

    const [selectedFile, setSelectedFile] = useState([]);
    const [isFilePicked, setIsFilePicked] = useState([]);

    const [currentStep, setCurrentStep] = useState(0)
    const [stepHistory, setStepHistory] = useState([])

    const [stepError, setStepError] = useState()

    let navigate = useNavigate();

    useEffect(() => {
        loadData()
    }, [entryId])

    async function loadData() {

        if (!entryId) return

        const newOptions = await getEntryOptions(entryId)
        setOptions(newOptions)
        // setCurrentStep(newOptions.steps[0].id) // TODO verify

        const newEntry = await getEntry(entryId)

        // pre-load the steps
        let entrySteps = []
        for (let i = 0; i < newOptions.steps.length; i++) {
            let existingStep = newEntry.step_entries.find(e => e.step === newOptions.steps[i].id)

            if (existingStep) {
                entrySteps.push(existingStep)
            } else {
                entrySteps.push({
                    step: newOptions.steps[i].id,
                    id: `N${newOptions.steps[i].id}`
                })
            }
        }
        newEntry.step_entries = entrySteps
        setData(newEntry)

        setSelectedFile(newOptions.steps.map(s => null))
        setIsFilePicked(newOptions.steps.map(s => false))
    }

    const schema = {}

    const successMessage = "Antwort erfasst. Vielen Dank."

    async function onSave(resultStepId) {
        // saving the instance first
        const tmpData = {...data, result: resultStepId}
        const newData = await patchEntry(entryId, tmpData)

        // add files
        for (let i = 0; i < selectedFile.length; i++) {
            if (selectedFile[i]) {
                const stepEntryId = newData.step_entries[i].id
                const formData = new FormData()
                formData.append('file', selectedFile[i])
                await addFileToStepEntry(formData, stepEntryId)
            }
        }

        toast.success(successMessage)
        navigate(`/questionnaires/entries/${newData.id}/result`)
    }

    function setStepEntries(newStepEntries) {
        setData({...data, step_entries: newStepEntries})
    }

    function handleListChange(evt, rowId) {
        baseHandleListChange(rowId, evt, data.step_entries, setStepEntries, listErrors, setListErrors, {})
    }

    function handleDateListChange(date, path, rowId) {
        baseHandleDateListChange(rowId, date, path, data.step_entries, setStepEntries, listErrors, setListErrors, schema, false)
    }

    function handleChangeFile(event, rowId) {

        const index = data.step_entries.findIndex(e => e.id === rowId)

        const newSelectedFile = [...selectedFile]
        newSelectedFile[index] = event.target.files[0]
        setSelectedFile(newSelectedFile)

        const newIsFilePicked = [...isFilePicked]
        newIsFilePicked[index] = true
        setIsFilePicked(newIsFilePicked)
    }

    function nextStep() {
        // validate that step filled in if required and other validations
        if (!validateCurrentStep()) {
            setStepError("Bitte beantworten Sie diese Frage")
            return
        } else setStepError(null)

        const nextStepIndex = getNextStepIndex()
        setStepHistory([...stepHistory, currentStep])
        setCurrentStep(nextStepIndex)
    }

    function validateCurrentStep() {

        const currentStepObj = options.steps[currentStep]
        const currentStepEntry = data.step_entries[currentStep]

        // is the step required and filled in?
        if (currentStepObj.required) {
            switch (currentStepObj.type) {
                case "TEXT":
                    if (!currentStepEntry.value_text) return false
                    break
                case "TEXTAREA":
                    if (!currentStepEntry.value_textarea) return false
                    break
                case "CONFIRMATION":
                    if (!currentStepEntry.value_confirmation) return false
                    break
                case "CHOICE":
                    if (!currentStepEntry.value_option.length > 0) return false
                    break
                case "MULTIPLE_CHOICE":
                    if (!currentStepEntry.value_option.length > 0) return false
                    break
                case "DATE":
                    if (!currentStepEntry.value_date) return false
                    break
                case "FILE":
                    if (!isFilePicked[currentStep]) return false
                    break
                case "ADDRESS":
                    if (!(currentStepEntry.value_address_1
                        && currentStepEntry.value_first_name && currentStepEntry.value_last_name
                        && currentStepEntry.value_city
                    )) return false
                    break
            }
        }

        // extra fields active and filled in?
        if (currentStepObj.type === 'CHOICE') {
            if (!currentStepEntry.value_option || !currentStepEntry.value_option.length > 0) return false
            const selectedOption = currentStepObj.options.find(o => o.id === currentStepEntry.value_option[0])
            if (selectedOption.show_text_field && !currentStepEntry.value_text) {
                return false
            }
            if (selectedOption.show_date_field && !currentStepEntry.value_date) {
                return false
            }
        }
        return true
    }

    function previousStep() {
        const previousStep = stepHistory.pop()
        if (previousStep !== undefined) setCurrentStep(previousStep)
    }

    async function restart(entryId) {
        // delete the entry and then re-create
        try {
            await deleteEntry(entryId)
        } catch (e) {}
        const entry = await findEntry(data.questionnaire_id, data.staff || data.student || data.alumnus);
        if (entry) {
            // navigate(`/questionnaires/entries/${entry.id}/edit`);
            // loadData()
            // setCurrentStep(0)
            // force a reload
            window.location.href = `/questionnaires/entries/${entry.id}/edit`
        }
    }

    function getNextStepIndex() {

        // const currentStepIndex = options.steps.findIndex(s => s.id === currentStep)
        const currentStepObj = options.steps[currentStep]
        let nextStepIndex = currentStep + 1

        // selected options ?
        if (currentStepObj.type === 'CHOICE') {
            const currentStepEntry = data.step_entries[currentStep]
            const selectedOption = currentStepObj.options.find(o => o.id === currentStepEntry.value_option[0])
            if (selectedOption.next_step) {
                nextStepIndex = options.steps.findIndex(s => s.id === selectedOption.next_step)
            }
        }

        const nextStep = options.steps[nextStepIndex]
        if (nextStep.type === 'RESULT') {
            onSave(nextStep.id)
        } else {
            return nextStepIndex;
        }

    }

    if (!options || !data) return <></>

    return (

        <NarrowPage title={options.questionnaire.title}>

            <Form id="questionnaire" onSave={onSave}
                  onDelete={null}
                  data={data} setData={setData}
                  errors={errors} setErrors={setErrors}
                  successMessage={successMessage}
                  hideSubmitButton={true}
                  schema={schema}>

                <FormSectionContent>

                    {/*currentStep: {currentStep}*/}

                    {options.steps.map((step, index) => {

                        const rowId = data.step_entries.find(e => (e.id === `N${step.id}`) || e.step === step.id)?.id

                        return (
                            <div key={index} id={"step_" + step.id}
                                 className={"mb-4 sm:col-span-6 " + (currentStep === index ? "" : "hidden")}
                                 data-is-required={step.is_required}
                                 data-step-type={step.type}
                                 data-error-message={step.required_error_message || "Bitte beantworten Sie diese Frage"}
                            >

                                {step.type !== 'CONFIRMATION' && step.type !== 'RESULT' &&
                                    <h3 className="questionnaireStepContent text-md font-bold text-gray-900"
                                        dangerouslySetInnerHTML={{__html: step.content}}></h3>}

                                {step.type === 'RESULT' &&
                                    <h3 className="text-md font-bold text-gray-900">Ergebnis</h3>}


                                {step.type === 'TEXT' && <Input
                                    key={index}
                                    path="value_text"
                                    type="text"
                                    label=""
                                    className="sm:col-span-6 hide-label"
                                    onChange={handleListChange}
                                    errors={listErrors}
                                    data={data.step_entries}
                                    rowId={rowId}
                                />}


                                {step.type === 'TEXTAREA' && <TextArea
                                    key={index + "textarea"}
                                    path="value_textarea"
                                    label=""
                                    className="sm:col-span-6 hide-label"
                                    onChange={handleListChange}
                                    errors={listErrors}
                                    data={data.step_entries}
                                    rowId={rowId}
                                    rows={5}
                                />}

                                {step.type === 'CONFIRMATION' && <Checkbox
                                    key={index}
                                    path="value_confirmation"
                                    label={step.content}
                                    className="sm:col-span-6"
                                    onChange={handleListChange}
                                    errors={listErrors}
                                    data={data.step_entries}
                                    rowId={rowId}
                                />}

                                {step.type === 'CHOICE' &&

                                    <div key={index}>
                                        <RadioGroup
                                            path="value_option"
                                            label=""
                                            className="sm:col-span-6"
                                            onChange={handleListChange}
                                            errors={listErrors}
                                            data={data.step_entries}
                                            rowId={rowId}
                                            options={step.options}
                                            valueAttr={"id"}
                                            labelAttr={"title"}
                                            valueOnly={true}
                                            singleValueAsList={true}
                                        />

                                        {step.options.map((option, optionIndex) => {

                                            const selectedOption = data.step_entries?.find(se => se.step === step.id)?.value_option?.find(o => o === option.id)
                                            // console.log('selectedOption', selectedOption)
                                            if (!selectedOption) return <div key={optionIndex}></div>

                                            if (option.show_text_field) {
                                                return <Input
                                                    key={optionIndex + "extra_text_field"}
                                                    path="value_text"
                                                    type="text"
                                                    label={option.text_field_title}
                                                    className="mt-4 sm:col-span-6"
                                                    onChange={handleListChange}
                                                    errors={listErrors}
                                                    data={data.step_entries}
                                                    rowId={rowId}
                                                />
                                            }

                                            if (option.show_date_field) {
                                                return <DateInput
                                                    key={optionIndex + "extra_date_field"}
                                                    className="col-span-3"
                                                    path="value_date"
                                                    label={option.date_field_title}
                                                    onChange={(date, path, rowId) => handleDateListChange(date, path, rowId)}
                                                    errors={listErrors}
                                                    data={data.step_entries}
                                                    rowId={rowId}
                                                />
                                            }

                                        })}

                                    </div>
                                }

                                {step.type === 'MULTIPLE_CHOICE' &&

                                    <div key={index}>
                                        <CheckboxGroup
                                            path="value_option"
                                            label=""
                                            className="sm:col-span-6"
                                            onChange={handleListChange}
                                            errors={listErrors}
                                            data={data.step_entries}
                                            rowId={rowId}
                                            options={step.options}
                                            valueAttr={"id"}
                                            labelAttr={"title"}
                                        />

                                        {step.options.map((option, optionIndex) => {

                                            const selectedOption = data.step_entries?.find(se => se.step === step.id)?.value_option?.find(o => o === option.id)
                                            if (!selectedOption) return <div key={optionIndex}></div>

                                            if (option.show_text_field) {
                                                return <Input
                                                    key={optionIndex + "extra_text_field"}
                                                    path="value_text"
                                                    type="text"
                                                    label={option.text_field_title}
                                                    className="mt-4 sm:col-span-6"
                                                    onChange={handleListChange}
                                                    errors={listErrors}
                                                    data={data.step_entries}
                                                    rowId={rowId}
                                                />
                                            }

                                            if (option.show_date_field) {
                                                return <DateInput
                                                    key={optionIndex + "extra_date_field"}
                                                    className="col-span-3"
                                                    path="value_date"
                                                    label={option.date_field_title}
                                                    onChange={(date, path, rowId) => handleDateListChange(date, path, rowId)}
                                                    errors={listErrors}
                                                    data={data.step_entries}
                                                    rowId={rowId}
                                                />
                                            }

                                        })}

                                    </div>
                                }

                                {step.type === 'DATE' && <DateInput
                                    key={index}
                                    className="col-span-3 hide-label"
                                    path="value_date"
                                    label={step.content}
                                    onChange={(date, path, rowId) => handleDateListChange(date, path, rowId)}
                                    errors={listErrors}
                                    data={data.step_entries}
                                    rowId={rowId}
                                />}

                                {step.type === 'FILE' &&

                                    <div className="sm:col-span-6 text-sm" key={index}>
                                        <input type="file" name={index + "_attachment"}
                                               onChange={(event) => handleChangeFile(event, rowId)}/> <br/>
                                        <div>
                                            {data.step_entries.find(se => se.step === step.id) &&
                                                <p className="py-1">Bisherige
                                                    Datei: {data.step_entries.find(se => se.step === step.id).value_text}</p>}
                                        </div>
                                    </div>}


                                {step.type === 'ADDRESS' &&

                                    <div key={index} className="grid gap-2 grid-cols-2">
                                        <Input
                                            path="value_first_name"
                                            type="text"
                                            label="Vorname"
                                            className="sm:col-span-1"
                                            onChange={handleListChange}
                                            errors={listErrors}
                                            data={data.step_entries}
                                            rowId={rowId}
                                        />
                                        <Input
                                            path="value_last_name"
                                            type="text"
                                            label="Nachname"
                                            className="sm:col-span-1"
                                            onChange={handleListChange}
                                            errors={listErrors}
                                            data={data.step_entries}
                                            rowId={rowId}
                                        />

                                        <Input
                                            path="value_for_address_1"
                                            type="text"
                                            label="Adresse"
                                            className="sm:col-span-2"
                                            onChange={handleListChange}
                                            errors={listErrors}
                                            data={data.step_entries}
                                            rowId={rowId}
                                        />

                                        <Input
                                            path="value_for_zip"
                                            type="text"
                                            label="PLZ"
                                            className="sm:col-span-1"
                                            onChange={handleListChange}
                                            errors={listErrors}
                                            data={data.step_entries}
                                            rowId={rowId}
                                        />

                                        <Input
                                            path="value_for_city"
                                            type="text"
                                            label="Ort"
                                            className="sm:col-span-1"
                                            onChange={handleListChange}
                                            errors={listErrors}
                                            data={data.step_entries}
                                            rowId={rowId}
                                        />

                                        <Input
                                            path="value_phone"
                                            type="text"
                                            label="Telefonnummer"
                                            className="sm:col-span-1"
                                            onChange={handleListChange}
                                            errors={listErrors}
                                            data={data.step_entries}
                                            rowId={rowId}
                                        />

                                    </div>

                                }


                                {step.type === 'RESULT' && <div key={index} className="sm:col-span-6">
                                    <p className="text-md text-gray-900">{step.content}</p>
                                </div>}


                                {step.additional_remarks_field && <TextArea
                                    key={index + "remarks"}
                                    path="remarks"
                                    label={step.additional_remarks_field_title}
                                    className="mt-4 sm:col-span-6"
                                    onChange={handleListChange}
                                    errors={listErrors}
                                    data={data.step_entries}
                                    rowId={rowId}
                                    rows={5}
                                />}


                                {stepError && <Alert extraClassName="mt-4" variant="warning">{stepError}</Alert>}

                            </div>
                        )
                    })}

                </FormSectionContent>

            </Form>

            <div className="flex justify-between">
                <div>
                    {/*{currentStep > 0 && <SecondaryButton onClick={previousStep} label="Zurück"/>}*/}
                    {currentStep > 0 && <SecondaryButton onClick={() => restart(entryId)}
                                                         label="Neu starten"/>}
                </div>
                <PrimaryButton onClick={nextStep} label="Weiter"/>
            </div>

        </NarrowPage>
    )
}
