import {SimplePage} from "../../elements/Pages";
import React from "react";

import {useQuery} from "@tanstack/react-query";
import ListViewTable, {TableDownloadLink, TableLink} from "../../elements/tables/ListViewTable";
import {DownloadButton, PrimaryButton} from "../../elements/Buttons";
import {facharbeitApi, getFacharbeitExcel, getFacharbeitList, getFacharbeitPDF} from "../../../api/school";
import checkPermission from "../../../utils/permissions";
import {useAuth} from "../../../contexts/AuthContext";


export default function FacharbeitList() {

    const { permissions } = useAuth();
    const search = window.location.search;
    const params = new URLSearchParams(search);
    const canEditSpecialGrade = checkPermission(permissions, 'grades.change_specialgrade')
    const canAddFacharbeit = checkPermission(permissions, 'school.add_facharbeit')

    const columns = [
        {
            accessorFn: (row) => `${row.student.last_name}, ${row.student.first_name} ${row.student.student_number}`,
            id: "student_search",
            header: "SchülerIn",
            hide: true,
            enableColumnFilter: false,
        },
        {
            accessorKey: "student",
            id: "student",
            header: "SchülerIn",
            columnType: "student",
            enableColumnFilter: false,
            sortingFn: "lastNameSorting",
        },
        {
            accessorKey: "student.school_class",
            id: "school_class",
            header: "Klasse",
            enableColumnFilter: true,
            headerClassNames: "narrow-filter",
        },
        {
            accessorKey: "course",
            id: "course",
            header: "Kurs",
            enableColumnFilter: false,
            headerClassNames: "narrow-filter",
            cell: ({row, value}) => (
                <TableLink label={`${row.original.course.course_number}`} to={`/courses/${row.original.course.id}`}/>
            ),
            disableRowLink: true,
        },
        {
            accessorKey: "course.school_subject",
            id: "school_subject",
            header: "Fach",
            enableColumnFilter: true,
            headerClassNames: "narrow-filter",
        },
        {
            accessorKey: "topic",
            id: "topic",
            header: "Thema",
            enableColumnFilter: false,
        },
        {
            accessorKey: "course.teacher.full_name",
            id: "teacher",
            header: "Lehrer",
            enableColumnFilter: true,
            headerClassNames: "narrow-filter",
        },
        {
            accessorKey: "supervisor.full_name",
            id: "supervisor",
            header: "Betreut von",
            enableColumnFilter: true,
            headerClassNames: "narrow-filter",
        },
        {
            accessorKey: "mark",
            id: "mark",
            header: "Note",
            enableColumnFilter: false,
            headerClassNames: "narrow-filter",
            cell: ({row, value}) => (
                row.original.mark && (canEditSpecialGrade ?
                    <TableLink label={`${row.original.mark.mark}`} to={`/grades/special-grades/${row.original.mark.id}/edit`}/>
                    : <>{row.original.mark.mark}</>)
            ),
            disableRowLink: true,
        },
        {
            accessorKey: "id",
            id: "link",
            cell: ({value, row}) => (
                <TableDownloadLink
                    label="PDF"
                    fileName={`Facharbeit ${row.original.student.full_name}.pdf`}
                    apiFunction={() => getFacharbeitPDF(row.original.id)}/>
            ),
            disableRowLink: true,
            header: "Download",
            enableColumnFilter: false,
        },
    ]

    const {isLoading, isError, isSuccess, data, error, refetch} = useQuery(
        ["facharbeit", params.get('student_id')],
        () => getFacharbeitList(params.get('student_id')),
        {refetchInterval: 1000 * 60 * 60 * 24}
    )

    function getRowLink(row) {
        return `/school/facharbeit/${row.original.id}/`;
    }

    return (
        <SimplePage>
            {/*  Simple striped table */}
            <div className="px-4 sm:px-6 lg:px-8">
                <div className="sm:flex sm:items-center">
                    <div className="sm:flex-auto">
                        <h3 className="text-lg leading-6 font-medium text-gray-900 mb-2">
                            Facharbeit
                        </h3>
                    </div>
                    <div className="mt-4 sm:mt-0 sm:ml-16 flex gap-2">
                        <DownloadButton
                            apiFunction={() => getFacharbeitExcel()}
                            fileName="Facharbeiten.xls"
                            label="Liste herunterladen"/>

                        {canAddFacharbeit && <PrimaryButton link={`/school/facharbeit/new`} label="Facharbeit eintragen" />}
                    </div>
                </div>
                <div className="flex flex-col">
                    <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">

                        <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                            {isSuccess && data && (
                                <ListViewTable
                                    data={data}
                                    columns={columns}
                                    isLoading={isLoading}
                                    disableGlobalSearch={false}
                                    rowSelection={null}
                                    setRowSelection={null}
                                    getRowLink={getRowLink}
                                />
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </SimplePage>
    );
}
