import {SimplePage} from "../../elements/Pages";
import React from 'react';

import {useQuery} from '@tanstack/react-query'
import {teamApi} from "../../../api/boarding_school";
import {Link} from "react-router-dom";

export default function Teams() {

    const {isLoading, isError, isSuccess, data, error, refetch} = useQuery(['teams'], () => teamApi('get'), {refetchInterval: 1000 * 60 * 60 * 24})

    return (

        <SimplePage>
            <div className="px-4 sm:px-6 lg:px-8">
                <div className="sm:flex sm:items-center">
                    <div className="sm:flex-auto">
                        <h3 className="text-lg leading-6 font-medium text-gray-900">Teams</h3>
                    </div>
                </div>
                <div className="flex flex-col mt-4">
                    <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">

                        <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">

                            <ul role="list"
                                className="grid grid-cols-1 gap-6 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-2">
                                {data && data.map((team, index) => (
                                    <Link key={team.id} to={`/boarding-school/teams/${team.id}`}>
                                        <li className="col-span-1 flex flex-col divide-y divide-gray-200 rounded-lg bg-white text-center shadow">
                                            <div className="flex flex-1 flex-col p-8">
                                                <img className="mx-auto max-h-60 max-w-60 rounded-full" src={team.logo}
                                                     alt={team.name} />
                                                <h3 className="mt-6 text-sm font-medium text-gray-900">{team.name}</h3>
                                            </div>
                                        </li>
                                    </Link>
                                ))}
                            </ul>

                        </div>
                    </div>
                </div>
            </div>
        </SimplePage>
    )
}
