import React from 'react';
import {Route, Routes} from "react-router-dom";
import CancellationCreateEdit from "./CancellationCreateEdit";
import RoomChangeCreateEdit from "./RoomChangeCreateEdit";
import SubstitutionCreateEdit from "./SubstitutionCreateEdit";
import TimetableChanges from "./TimetableChanges";
import MyTimetableChanges from "./MyTimetableChanges";
import SubstitutionDetail from "./SubstitutionDetail";


function TimetablesRouter() {

    return (
        <Routes>

            {/* List view for all of them */}
            <Route index element={<TimetableChanges/>}/>
            <Route path="/my" element={<MyTimetableChanges/>}/>

            {/* Cancellations */}
            <Route path="/cancellations/new" element={<CancellationCreateEdit/>}/>
            <Route path="/cancellations/:id/edit" element={<CancellationCreateEdit/>}/>

            {/* Room changes */}
            <Route path="/room-changes/new" element={<RoomChangeCreateEdit/>}/>
            <Route path="/room-changes/:id/edit" element={<RoomChangeCreateEdit/>}/>

            {/* Substitutions */}
            <Route path="/substitutions/new" element={<SubstitutionCreateEdit/>}/>
            <Route path="/substitutions/:id/edit" element={<SubstitutionCreateEdit/>}/>
            <Route path="/substitutions/:id" element={<SubstitutionDetail/>}/>

        </Routes>
    )
}


export default function Timetables() {
    return <TimetablesRouter/>
}
