import http from "../services/httpService";

export async function getGalleryList(schoolYearId) {
    const resp = await http.get(`/gallery/?school_year_id=${schoolYearId}`)
    return resp.data
}

export async function getGalleryDetail(id) {
    const resp = await http.get(`/gallery/${id}/`)
    return resp.data
}
