import {SimplePage} from "../../elements/Pages";
import React, {useState} from "react";

import {getLogbookEntries} from "../../../api/logbook";

import {useQuery, refetch} from "@tanstack/react-query";
import {useAuth} from "../../../contexts/AuthContext";
import ListViewTable, {TableDownloadLink, TableLink} from "../../elements/tables/ListViewTable";
import checkPermission from "../../../utils/permissions";
import moment from "moment";
import {Link, useParams} from "react-router-dom";
import SchoolYearFilter from "../../elements/SchoolYearFilter";
import {useEffect} from "react";
import {useSchoolYears} from "../../../contexts/SchoolYearContext";
import {
    getParticipantListPDF, getParticipantsExcel,
    getProject, getProjectListProjectLeader,
    getProjectParticipationExcel,
    getProjects,
    getProjectsExcel
} from "../../../api/projects";
import {getTeacherAppointmentListPDF} from "../../../api/parent_conference";
import {CalendarIcon, DocumentArrowDownIcon, DocumentCheckIcon, ListBulletIcon} from "@heroicons/react/20/solid";
import {getAbsencesReportExcel} from "../../../api/reports";
import {DownloadButton} from "../../elements/Buttons";

const columns = [
    {
        accessorKey: "id",
        id: "particpants_list",
        cell: (info) => (
            <TableDownloadLink
                fileName={`Teilnehmerliste.pdf`}
                apiFunction={() => getParticipantListPDF(info.getValue())}/>
        ),
        header: "",
        enableColumnFilter: false,
        disableRowLink: true,
    },
    {
        accessorKey: "id",
        id: "view_attendance",
        cell: (info) => (
            <TableLink
                label={
                    <>
                        <CalendarIcon
                            className="inline align-middle h-5 w-5"
                            aria-hidden="true"
                        />
                    </>
                }

                to={`/projects/attendance/projects/${info.getValue()}`}
            />
        ),
        header: "",
        enableColumnFilter: false,
        disableRowLink: true,
    },
    {
        accessorKey: "id",
        id: "create_attendance",
        cell: (info) => (

            <TableLink
                label={
                    <>
                        <DocumentCheckIcon
                            className="inline align-middle h-5 w-5"
                            aria-hidden="true"
                        />
                    </>
                }
                to={`/projects/attendance/new/${info.getValue()}/`}
            />
        ),
        header: "",
        enableColumnFilter: false,
        disableRowLink: true,
    },
    {
        accessorKey: "name",
        id: "name",
        header: "Name",
        classNames: "font-semibold text-gray-900",
        enableColumnFilter: false
    },
    {
        accessorKey: "categories",
        id: "categories",
        header: "Kategorien",
        columnType: "m2m",
        labelAttr: "name",
        enableColumnFilter: true,
        headerClassNames: "medium-filter",
    },
    {
        accessorKey: "project_leaders",
        id: "project_leaders",
        header: "Projektleiter",
        columnType: "m2m",
        labelAttr: "full_name",
        enableColumnFilter: true,
        headerClassNames: "medium-filter",
    },
    {
        accessorKey: "get_project_times_str",
        id: "get_project_times_str",
        header: "Zeit",
        headerClassNames: "medium-filter",
    },
    {
        accessorKey: "active_participants",
        id: "active_participants",
        header: "Teilnehmer",
        columnType: "m2m",
        separator: " <br/>",
        labelAttr: "full_name",
        enableColumnFilter: false
    },
];

export default function MyProjectsProjectLeader() {
    const {currentUser} = useAuth();

    const {isLoading, isError, isSuccess, data, error, refetch} = useQuery(
        ["projects_project_leader", currentUser.id],
        () => getProjectListProjectLeader(),
        {refetchInterval: 1000 * 60 * 60 * 24}
    )

    function getRowLink(row) {
        return `/projects/${row.original.id}/`;
    }

    // if (!data) return <></>
    return (
        <SimplePage>
            {/*  Simple striped table */}
            <div className="px-4 sm:px-6 lg:px-8">
                <div className="sm:flex sm:items-center">
                    <div className="sm:flex-auto">
                        <h3 className="text-lg leading-6 font-medium text-gray-900">
                            Meine Projekte
                        </h3>
                    </div>
                </div>
                <div className="flex flex-col">
                    <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
                        <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                            {isSuccess && data && (
                                <ListViewTable
                                    data={data}
                                    columns={columns}
                                    isLoading={isLoading}
                                    disableGlobalSearch={true}
                                    getRowLink={getRowLink}
                                />
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </SimplePage>
    );
}
