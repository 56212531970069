import {NarrowPage} from "../../elements/Pages";
import {React, useEffect, useState} from "react";
import Joi from "joi"
import {useQuery} from '@tanstack/react-query'
import {DateInput, Input, Select} from "../../elements/forms/Inputs"
import {FormSectionContent} from "../../elements/forms/FormLayout"
import {getStudentInfo} from "../../../api/school";
import Form, {baseHandleChange, baseHandleDateChange} from "../../elements/forms/Form"
import {useNavigate, useParams} from "react-router-dom";
import {toast} from "react-toastify";
import {getCoursesForStudent} from "../../../api/courses";
import {getResultMarks, getSpecialGradeDefinitions, specialGradesApi} from "../../../api/grades";

export default function SpecialGradeCreateEdit() {

    const {id, studentId} = useParams()
    const [resultMarks, setResultMarks] = useState([])
    const [data, setData] = useState({
        id: null,
        student: {id: parseInt(studentId) || null},
        course: null,
        topic: null,
        mark: null,
        date: null,
        absolute_bonus: null,
        weight_in_course: null
    })

    const schema = {
        student: Joi.object().required(),
        course: Joi.object().required(),
        // mark: Joi.object().required(),
        topic: Joi.string().required(),
        date: Joi.date().required()
    }

    const {data: student} = useQuery(["student_info", data.student.id],
        () =>
        getStudentInfo(data.student.id),
        {
            enabled: !!data.student.id, refetchInterval: 1000 * 60 * 60 * 24
        }
    )
    const {data: courses} = useQuery(
        ["courses_for_student", data.student.id],
        () => getCoursesForStudent(data.student.id),
        {
            enabled: !!data.student.id, refetchInterval: 1000 * 60 * 60
        }
    )
    const {data: specialGradeDefinitions} = useQuery(
        ["special_grade_definitions"], getSpecialGradeDefinitions, {refetchInterval: 1000 * 60 * 60 * 24}
    )

    const [errors, setErrors] = useState({}) // validation errors
    let navigate = useNavigate();

    useEffect(() => {
        loadData()
    }, [id])

    async function loadData() {
        if (!id) return
        const newData = await specialGradesApi('get', id)
        setData(newData)
    }

    useEffect(() => {
        loadResultMarks()
    }, [student])

    async function loadResultMarks() {
        if (!student || !student.grade) return
        const newData = await getResultMarks(student.grade)
        setResultMarks(newData)
    }

    const title = (id || data.id) ? "Sondernote bearbeiten" : "Neue Sondernote eintragen"
    const successMessage = (id || data.id) ? "Sondernote aktualisiert" : "Sondernote eingetragen"

    async function onSave() {
        // validation happens in Form component
        let newData
        if (!data.id) newData = await specialGradesApi('post', null, data)
        else newData = await specialGradesApi('patch', null, data)
        setData(newData)
        toast.success(successMessage)
        navigate(`/grades/students/${data.student.id}/special-grades`)
    }

    async function onDelete() {
        await specialGradesApi('delete', data.id)
        toast.success("Sondernote gelöscht.")
        navigate(`/grades/students/${data.student.id}/special-grades`)
    }

    function handleChange(evt) {
        baseHandleChange(evt, data, setData, errors, setErrors, schema)
    }

    function handleDateChange(date, path) {
        baseHandleDateChange(date, path, data, setData, errors, setErrors, schema, false)
    }

    return (

        <NarrowPage title={title}>

            <Form id="createSpecialGrade" onSave={onSave}
                  onDelete={onDelete}
                  data={data} setData={setData}
                  errors={errors} setErrors={setErrors}
                  successMessage={successMessage}
                  schema={schema}>

                <FormSectionContent>

                    <p className="text-lg sm:col-span-6">{student?.full_name}</p>

                    <Select
                        path="special_grade_definition"
                        label="Sondernote"
                        className="sm:col-span-3"
                        options={specialGradeDefinitions}
                        valueAttr="id"
                        labelAttr="name"
                        onChange={handleChange}
                        errors={errors}
                        data={data}
                        isClearable={false}
                        valueOnly={false}
                    />

                    <Select
                        path="course"
                        label="Kurs"
                        className="sm:col-span-3"
                        options={courses}
                        valueAttr="id"
                        labelAttr="course_number"
                        onChange={handleChange}
                        errors={errors}
                        data={data}
                        isClearable={false}
                        valueOnly={false}
                    />

                    <Input
                        path="topic"
                        type="text"
                        label="Thema"
                        className="sm:col-span-6"
                        onChange={handleChange}
                        errors={errors}
                        data={data}
                    />

                    <DateInput
                        className="col-span-3"
                        path="date"
                        label="Datum"
                        onChange={handleDateChange}
                        errors={errors}
                        data={data}
                        todayButton={true}
                        tomorrowButton={false}
                    />

                    <Select
                        path="mark"
                        label="Note"
                        className="sm:col-span-3"
                        options={resultMarks}
                        valueAttr="id"
                        labelAttr="display"
                        onChange={handleChange}
                        errors={errors}
                        data={data}
                        isClearable={false}
                        valueOnly={false}
                    />

                    <Input
                        path="absolute_bonus"
                        type="decimal"
                        label="Absoluter Bonus auf Endnote:"
                        className="sm:col-span-3"
                        onChange={handleChange}
                        errors={errors}
                        data={data}
                    />
                    <Input
                        path="weight_in_course"
                        type="number"
                        label="Gewichtung im Kurs"
                        className="sm:col-span-3"
                        onChange={handleChange}
                        errors={errors}
                        data={data}
                    />

                </FormSectionContent>

            </Form>

            <span onClick={() => navigate(-1)}
                  className="cursor-pointer float-right mt-2 font-medium text-imsblue-600">Zurück</span>

        </NarrowPage>
    )
}
