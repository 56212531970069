import {useNavigate, useParams} from "react-router-dom";
import {React, useEffect, useState} from "react";
import {DangerButton, PrimaryButton} from "../../elements/Buttons";
import {confirmTutoring, getTutoringWithSecret, rejectTutoring} from "../../../api/tutoring";
import {toast} from "react-toastify";
import {useAuth} from "../../../contexts/AuthContext";
import {confirmEntry, sendConfirmationEmail} from "../../../api/questionnaires";
import {CheckCircleIcon, XMarkIcon} from "@heroicons/react/20/solid";


export default function ConfirmEntry() {
    const {id, secret} = useParams();
    const {currentUser} = useAuth();
    const navigate = useNavigate();

    const [data, setData] = useState()

    useEffect(() => {
        doConfirm()
    }, [id, secret])

    async function doConfirm() {
        const newData = await confirmEntry(id, secret)
        setData(newData)
        if (newData.success) {
            toast.success("Eintrag bestätigt")
            if (currentUser) navigate(`/questionnaires/entries/${id}/result`)
        } else {
            toast.error("Der Eintrag konnte nicht bestätigt werden")
        }
    }

    const confirmed = data?.result?.entry.confirmed || false

    return (
        <>
            <main className="py-10 h-full bg-gray-100">
                {/* Page header */}
                <div
                    className="max-w-3xl mx-auto px-4 sm:px-6 md:flex md:items-center md:justify-between md:space-x-5 lg:max-w-7xl lg:px-8">
                    <div className="flex items-center space-x-5">
                        <div>
                            <h1 className="text-2xl font-bold text-gray-900">
                                {data?.result && data.result.questionnaire.title}
                            </h1>
                        </div>
                    </div>
                    <div
                        className="mt-6 flex flex-col-reverse justify-stretch space-y-4 space-y-reverse sm:flex-row-reverse sm:justify-end sm:space-x-reverse sm:space-y-0 sm:space-x-3 md:mt-0 md:flex-row md:space-x-3">
                    </div>
                </div>

                <div
                    className="mt-8 max-w-3xl mx-auto grid grid-cols-1 gap-6 sm:px-6 lg:max-w-7xl lg:grid-flow-col-dense lg:grid-cols-3">
                    <div className="space-y-6 lg:col-start-1 lg:col-span-2">

                        <section aria-labelledby="applicant-information-title">
                            <div className="bg-white shadow sm:rounded-lg">
                                <div className="border-t border-gray-200 px-4 py-5 sm:px-6">

                                    {!confirmed &&
                                        <div className="flex align-middle content-center text-red-400">
                                            <XMarkIcon className="h-5 w-5  mr-1" aria-hidden="true"/>
                                            Nicht bestätigt
                                        </div>}

                                    {confirmed &&
                                        <div className="flex">
                                            <CheckCircleIcon className="h-5 w-5 text-green-400 mr-1"
                                                             aria-hidden="true"/>
                                            Bestätigt
                                        </div>}

                                    <div className="mt-4">
                                        <PrimaryButton link={`/questionnaires/entries/${id}/result`} label="Einloggen & Ergebnis ansehen" />
                                    </div>
                                </div>
                            </div>
                        </section>

                    </div>

                    <section aria-labelledby="timeline-title" className="lg:col-span-1 lg:col-start-3">
                    </section>
                </div>

            </main>
        </>
    );
}
