import React from "react";

import {getAbsenceNoticesForStudent, getAbsenceNoticeSummary,} from "../../../api/logbook";

import {useQuery} from "@tanstack/react-query";
import {useAuth} from "../../../contexts/AuthContext";
import ListViewTable from "../../elements/tables/ListViewTable";
import checkPermission from "../../../utils/permissions";
import {useParams} from "react-router-dom";
import {PrimaryButton, SecondaryButton} from "../../elements/Buttons";
import {getStudentInfo} from "../../../api/school";
import {useUserInfo} from "../../../contexts/UserInfoContext";

const columns = [
  {
    accessorKey: "start",
    id: "start",
    header: "Von",
    columnType: "date_time",
    enableColumnFilter: false,
    // classNames: 'font-medium text-gray-900'
    classNames: "text-gray-500",
  },
  {
    accessorKey: "end",
    id: "end",
    header: "Bis",
    columnType: "date_time",
    enableColumnFilter: false,
    // classNames: 'font-medium text-gray-900'
    classNames: "text-gray-500",
  },
  {
    accessorFn: (row) => (row.rule ? `${row.rule.name}` : ""),
    id: "rule",
    header: "Wiederholung",
    enableColumnFilter: false,
    // classNames: 'font-medium text-gray-900'
    classNames: "text-gray-500",
  },
  {
    accessorFn: (row) => (row.reason ? `${row.reason.name}` : ""),
    id: "reason",
    header: "Grund",
    cell: (info) => info.getValue(),
    enableColumnFilter: false,
    headerClassNames: "medium-filter",
  },
  {
    accessorKey: "notes",
    id: "notes",
    header: "Anmerkungen",
    cell: (info) => info.getValue(),
    enableColumnFilter: false,
    classNames: "max-w-md text-gray-500",
    headerClassNames: "max-w-md",
  },
  // {
  //     accessorKey: 'id',
  //     id: 'link',
  //     cell: info => <TableLink label="Bearbeiten" to={`/logbook/absence-notices/${info.getValue()}/edit`} />,
  //     header: 'Bearbeiten',
  //     enableColumnFilter: false,
  // }
];

export default function AbsenceNoticeStudentDetail() {
  const { studentId } = useParams();
  const { permissions } = useAuth();
  const {renderUserImage} = useUserInfo();

  const { isLoading, isError, isSuccess, data, error, refetch } = useQuery(
    ["absence_notices", studentId],
    () => getAbsenceNoticesForStudent(studentId),
    {}
  );
  const { data: summary } = useQuery(
    ["absence_notice_summary", studentId],
    () => getAbsenceNoticeSummary(studentId),
    {}
  );
  const { data: student } = useQuery(["student_info", studentId], () =>
    getStudentInfo(studentId), {refetchInterval: 1000 * 60 * 60 * 24}
  );

  if (!data || !student) return <></>;

  return (
    <>
      <main className="py-10 h-full bg-gray-100">
        {/* Page header */}
        <div className="max-w-3xl mx-auto px-4 sm:px-6 md:flex md:items-center md:justify-between md:space-x-5 lg:max-w-7xl lg:px-8">
          <div className="flex items-center space-x-5">
            <div className="flex-shrink-0">
              <div className="relative">
                {renderUserImage(student.user_id, 80)}
                <span
                    className="absolute inset-0 shadow-inner rounded-full"
                    aria-hidden="true"
                />
              </div>
            </div>
            <div>
              <h1 className="text-2xl font-bold text-gray-900">
                {student.full_name}
              </h1>
              <p className="text-sm font-medium text-gray-500">
                {student.student_number}
              </p>
            </div>
          </div>
          <div className="mt-6 flex flex-col-reverse justify-stretch space-y-4 space-y-reverse sm:flex-row-reverse sm:justify-end sm:space-x-reverse sm:space-y-0 sm:space-x-3 md:mt-0 md:flex-row md:space-x-3">
            <SecondaryButton
              label="Schülerblatt"
              link={`/school/students/${studentId}`}
            />

            {checkPermission(
              permissions,
              "student_logbook.add_absencenotice"
            ) && (
              <PrimaryButton
                link={`/logbook/absence-notices/new/${studentId}`}
                label="Neue Abwesenheitsmeldung"
              />
            )}
          </div>
        </div>

        <div className="mt-8 max-w-3xl mx-auto grid grid-cols-1 gap-6 sm:px-6 lg:max-w-7xl lg:grid-flow-col-dense lg:grid-cols-3">
          <div className="space-y-6 lg:col-start-1 lg:col-span-2">
            {/* Abwesenheitsmeldungen */}

            <div className="bg-white shadow sm:rounded-lg sm:overflow-hidden">
              <div className="divide-y divide-gray-200">
                <div className="px-4 py-5 sm:px-6">
                  <h2
                    id="notes-title"
                    className="text-lg font-medium text-gray-900"
                  >
                    Abwesenheitsmeldungen
                  </h2>
                </div>
                <div className="">
                  {isSuccess && data && (
                    <ListViewTable
                      data={data}
                      columns={columns}
                      isLoading={isLoading}
                      disableGlobalSearch={true}
                      getRowLink={null}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>

          <section
            aria-labelledby="timeline-title"
            className="lg:col-start-3 lg:col-span-1"
          >
            {summary && (
              <section aria-labelledby="applicant-information-title">
                <div className="bg-white shadow overflow-hidden sm:rounded-lg">
                  <div className="px-4 py-5 sm:px-6">
                    <h3 className="text-lg leading-6 font-medium text-gray-900">
                      Fehltage in diesem Schuljahr
                    </h3>
                  </div>
                  <div className="border-t border-gray-200 px-4 py-5 sm:p-0">
                    <dl className="sm:divide-y sm:divide-gray-200">
                      <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                        <dt className="text-sm font-medium text-gray-500">
                          Gesamt
                        </dt>
                        <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                          {summary.number_of_absences}
                        </dd>
                      </div>
                      <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                        <dt className="text-sm font-medium text-gray-500">
                          Entschuldigt
                        </dt>
                        <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                          {summary.number_of_excused_absences}
                        </dd>
                      </div>
                      <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                        <dt className="text-sm font-medium text-gray-500">
                          Unentschuldigt
                        </dt>
                        <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                          {summary.number_of_unexcused_absences}
                        </dd>
                      </div>
                    </dl>
                  </div>
                </div>
              </section>
            )}
          </section>
        </div>
      </main>
    </>
  );
}
