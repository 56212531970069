import {NarrowPage} from "../../elements/Pages";
import {React, useEffect, useState} from "react";
import Joi from "joi"

import {Link, useNavigate, useParams} from 'react-router-dom'
import {deletePeExemption} from '../../../api/logbook'
import {Input, RadioGroup, TextArea} from "../../elements/forms/Inputs"
import {FormSectionContent} from "../../elements/forms/FormLayout"
import {addFileToStudentDocument, getStudentInfo, studentDocumentApi} from "../../../api/school";
import Form, {baseHandleChange} from "../../elements/forms/Form"
import {toast} from "react-toastify";

export default function StudentDocumentCreateEdit() {

    let navigate = useNavigate();
    const {id, studentId} = useParams()
    const [student, setStudent] = useState()
    const [data, setData] = useState({
        id: null,
        student: {
            id: parseInt(studentId) || null
        },
        title: "",
        description: ""
    })
    const [selectedFile, setSelectedFile] = useState();
    const [isFilePicked, setIsFilePicked] = useState(false);

    const visibilityOptions = [
        {
            value: 'ALL',
            name: 'Eltern, Schüler, Team'
        },
        {
            value: 'STAFF',
            name: 'Team'
        },
        {
            value: 'MANAGEMENT',
            name: 'nur Leitung'
        },
    ]

    useEffect(() => {
        loadData()
    }, [id])

    async function loadData() {
        let newData
        let student
        if (id) {
            newData = await studentDocumentApi("get", id)
            student = await getStudentInfo(newData.student.id)
        } else if (studentId) {
            student = await getStudentInfo(studentId)
            newData = {...data}
        }
        setData(newData)
        setStudent(student)
    }

    const [errors, setErrors] = useState({}) // validation errors

    const schema = {
        title: Joi.string().required()
    }

    async function onSave() {

        // validation happens in Form component
        let newData
        if (!data.id) newData = await studentDocumentApi("post", null, data)
        else newData = await studentDocumentApi("patch", null, data)

        // then adding the file
        if (isFilePicked) {
            const formData = new FormData();
            formData.append('file', selectedFile);
            newData = await addFileToStudentDocument(formData, newData.id)
        }

        setData(newData)
        toast.success("Schülerdokument aktualisiert.")
        navigate(`/school/students/${data.student.id}`)
    }

    async function onDelete() {
        await deletePeExemption(data.id)
        toast.success("Schülerdokument gelöscht.")
        navigate(`/school/students/${data.student.id}`)
    }

    function handleChange(evt) {
        baseHandleChange(evt, data, setData, errors, setErrors, schema)
    }

    const handleChangeFile = (event) => {
        setSelectedFile(event.target.files[0])
        setIsFilePicked(true)
    }

    const title = (id || data.id) ? "Schülerdokument bearbeiten" : "Neues Schülerdokument hochladen"
    const successMessage = (id || data.id) ? "Schülerdokument aktualisiert" : "Neues Schülerdokument hochgeladen"

    return (

        <NarrowPage title={title}>

            <h3 className="text-md font-medium text-gray-900">
                für {student?.full_name}
            </h3>

            <Form id="createEditStudentDocument" onSave={onSave}
                  onDelete={onDelete}
                  data={data} setData={setData}
                  className=""
                  errors={errors} setErrors={setErrors}
                  successMessage={successMessage}
                  schema={schema}>

                <FormSectionContent>
                    <Input
                        path="title"
                        type="text"
                        label="Titel"
                        className="sm:col-span-6"
                        onChange={handleChange}
                        errors={errors}
                        data={data}
                    />

                    <TextArea
                        path="description"
                        label="Beschreibung"
                        onChange={handleChange}
                        errors={errors}
                        data={data}
                        rows={5}
                        className="sm:col-span-6"
                    />

                    <RadioGroup
                        path="visibility"
                        label="Sichtbar für"
                        className="sm:col-span-3"
                        options={visibilityOptions}
                        valueAttr="value"
                        labelAttr="name"
                        onChange={handleChange}
                        errors={errors}
                        data={data}
                    />

                    <div className="sm:col-span-6 text-sm">
                        <input type="file" name="file" onChange={handleChangeFile}/> <br/>

                        <div>
                            {/*{selectedFile && <p className="py-1">Ausgewählt: {selectedFile.name}<br/></p>}*/}
                            {data.file && <p className="py-1">Bisherige Datei: {data.file}</p>}
                        </div>
                    </div>

                </FormSectionContent>

            </Form>

            <Link to={`/school/students/${data.student.id}`} className="float-right mt-2 font-medium text-imsblue-600">Zurück
                zum Schülerblatt</Link>

        </NarrowPage>
    )
}
