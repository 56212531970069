import {Link, useParams} from "react-router-dom";
import {React, useEffect, useState} from "react";
import {PrimaryButton, SecondaryButton} from "../../elements/Buttons";
import {useAuth} from "../../../contexts/AuthContext";
import {toast} from "react-toastify";
import {formatDate} from "../../../utils/formatters";
import {confirmSubstitution, getSubstitutionBySecret, substitutionApi} from "../../../api/timetables";


export default function SubstitutionDetail() {

    const {id, secret} = useParams()
    const {permissions} = useAuth()
    const [substitution, setSubstitution] = useState()

    useEffect(() => {
        loadData()
    }, [id])

    async function loadData() {
        let newData
        if (secret) {
            newData = await getSubstitutionBySecret(id, secret)
            setSubstitution(newData)
        } else {
            newData = await substitutionApi('get', id)
            setSubstitution(newData)
        }
    }

    async function handleConfirm() {
        try {
            const newData = await confirmSubstitution(substitution)
            setSubstitution(newData)
            toast.success("Vertretung bestätigt")
        } catch (e) {
            toast.error("Beim Speichern ist ein Fehler aufgetreten.")
        }
    }

    if (!substitution) return <></>;

    const canConfirm = substitution.can_confirm

    return (
        <>
            <main className="py-10 h-full bg-gray-100">

                <div
                    className="max-w-3xl mx-auto px-4 sm:px-6 md:flex md:items-center md:justify-between md:space-x-5 lg:max-w-7xl lg:px-8">
                    <div className="flex items-center space-x-5">
                        <div>
                            <h1 className="text-2xl font-bold text-gray-900">
                                Vertretung
                                am {formatDate(substitution.date)} für {substitution.replaced_teacher.full_name}
                            </h1>
                        </div>
                    </div>
                    <div
                        className="mt-6 flex flex-col-reverse justify-stretch space-y-4 space-y-reverse sm:flex-row-reverse sm:justify-end sm:space-x-reverse sm:space-y-0 sm:space-x-3 md:mt-0 md:flex-row md:space-x-3">
                        <SecondaryButton label="Klassenbuch"
                                         link={`/class-register/courses/${substitution.course.id}`}/>
                    </div>
                </div>

                <div
                    className="mt-4 max-w-3xl mx-auto grid grid-cols-1 gap-6 sm:px-6 lg:max-w-7xl lg:grid-flow-col-dense lg:grid-cols-3">
                    <div className="space-y-6 lg:col-start-1 lg:col-span-2">

                        <section aria-labelledby="applicant-information-title">
                            <div className="bg-white shadow sm:rounded-lg">
                                <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
                                    <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">

                                        <div className="sm:col-span-1">
                                            <dt className="text-sm font-medium text-gray-500">Kurs</dt>
                                            <dd className="mt-1 text-sm text-gray-900">
                                                <Link className="font-medium text-imsblue-600"
                                                      to={`/courses/${substitution.course.id}`}>{substitution.course.course_number}</Link>
                                            </dd>
                                        </div>
                                        <div className="sm:col-span-1">
                                            <dt className="text-sm font-medium text-gray-500">Fach</dt>
                                            <dd className="mt-1 text-sm text-gray-900">{substitution.course.school_subject}</dd>
                                        </div>


                                        <div className="sm:col-span-1">
                                            <dt className="text-sm font-medium text-gray-500">Es fehlt</dt>
                                            <dd className="mt-1 text-sm text-gray-900">
                                                {substitution.replaced_teacher &&
                                                    <Link className="font-medium text-imsblue-600"
                                                          to={`/staff/${substitution.replaced_teacher.id}`}>{substitution.replaced_teacher.full_name}</Link>}
                                            </dd>
                                        </div>
                                        <div className="sm:col-span-1">
                                            <dt className="text-sm font-medium text-gray-500">Vertretung durch</dt>
                                            <dd className="mt-1 text-sm text-gray-900">
                                                {substitution.replacing_teacher &&
                                                    <Link className="font-medium text-imsblue-600"
                                                          to={`/staff/${substitution.replacing_teacher.id}`}>{substitution.replacing_teacher.full_name}</Link>}</dd>
                                        </div>

                                        <div className="sm:col-span-1">
                                            <dt className="text-sm font-medium text-gray-500">Raum</dt>
                                            <dd className="mt-1 text-sm text-gray-900">{substitution.room?.name}</dd>
                                        </div>

                                        <div className="sm:col-span-2">
                                            <dt className="text-sm font-medium text-gray-500">Anmerkungen</dt>
                                            <dd className="mt-1 text-sm text-gray-900  whitespace-pre-wrap">{substitution.remarks}</dd>
                                        </div>

                                        <div className="sm:col-span-2">
                                            <dt className="text-sm font-medium text-gray-500">Kursbucheintrag</dt>
                                            <dd className="mt-1 text-sm text-gray-900">
                                                {substitution.class_register_entry_id ?
                                                <Link className="font-medium text-imsblue-600"
                                                      to={`/class-register/entries/${substitution.class_register_entry_id}`}>Ansehen</Link>
                                                :
                                                <Link className="font-medium text-imsblue-600"
                                                      to={`/class-register/entries/new/${substitution.course.id}`}>Erstellen</Link>}
                                            </dd>
                                        </div>

                                        <div className="sm:col-span-2">
                                            <dt className="text-sm font-medium text-gray-500">Bestätigt</dt>
                                            <dd className="mt-1 text-sm text-gray-900 flex gap-2">
                                                {substitution.confirmed && <span>Bestätigt</span>}

                                                {!substitution.confirmed && !(canConfirm || secret) && <span>Nein</span>}

                                                {!substitution.confirmed && (canConfirm || secret) &&
                                                    <PrimaryButton onClick={handleConfirm} label="Bestätigen"/>}
                                            </dd>
                                        </div>

                                    </dl>
                                </div>
                            </div>
                        </section>

                    </div>


                </div>

            </main>
        </>
    );
}

