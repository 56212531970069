import {NarrowPage} from "../../elements/Pages";
import {React, useEffect, useState} from "react";
import Joi from "joi"

import {Link, useNavigate, useParams} from 'react-router-dom'
import {useQuery} from '@tanstack/react-query'
import {DateInput, Input, MultiSelect, Select} from "../../elements/forms/Inputs"
import {FormSectionContent} from "../../elements/forms/FormLayout"
import {getStudentInfo} from "../../../api/school";
import Form, {baseHandleChange, baseHandleDateChange} from "../../elements/forms/Form"
import {toast} from "react-toastify";
import {
    deleteProjectParticipation,
    getProjectList,
    getProjectParticipation,
    patchProjectParticipation,
    postProjectParticipation
} from "../../../api/projects";
import {AddButton} from "../../elements/Buttons";
import {DeleteButton} from "../../elements/DeleteButton";
import {getCurrentHalfYear, getWeekdayOptions} from "../../../api/calendar";
import {useSchoolYears} from "../../../contexts/SchoolYearContext";


export default function ParticipationCreateEdit() {

    const {id, studentId} = useParams()
    const [newRowId, setNewRowId] = useState(1)
    const navigate = useNavigate()

    const {data: projects} = useQuery(['project_list'], getProjectList, {refetchInterval: 1000 * 60 * 60 * 24})
    const {data: weekdays} = useQuery(['weekdays'], getWeekdayOptions, {refetchInterval: 1000 * 60 * 60 * 24})
    const {data: currentHalfYear} = useQuery(['current_half_year'], getCurrentHalfYear, {refetchInterval: 1000 * 60 * 60 * 24})
    const {currentSchoolYear} = useSchoolYears();

    const [data, setData] = useState({
        id: null,
        student: {id: parseInt(studentId) || null},
        project: null,
        selectedProjects: [],
        start_date: null,
        end_date: null,
        project_student_times: []
    })

    useEffect(() => {
        loadData()
    }, [id, studentId, currentSchoolYear])

    async function loadData() {

        let newData = {...data}
        if (id) {
            newData = await getProjectParticipation(id)
            setData(newData)
            return
        }
        if (studentId) {
            const student = await getStudentInfo(studentId)
            newData.student = {...student}
        }
        if (currentSchoolYear) {
            newData.start_date = currentSchoolYear.start_date
            newData.end_date = currentSchoolYear.end_date
        }
        if (currentHalfYear) {
            newData.start_date = currentHalfYear.start
        }
        setData(newData)
    }

    const [errors, setErrors] = useState({})
    const [projectStudentTimeErrors, setProjectStudentTimeErrors] = useState([])

    const schema = {
        student: Joi.object().required(),
        start_date: Joi.date().required(),
        end_date: Joi.date().required()
    };

    async function onSave() {

        let newStudentTimes = data.project_student_times.map((t, index) => {
            if (t.id.toString().startsWith("N")) {
                return {...t, id: null}
            }
            return {...t}
        })

        let tmpdata = {...data, project_student_times: newStudentTimes}

        let newData
        if (!data.id) {
            // newData = await postProjectParticipation(tmpdata)

            // for each project in selectedProjects, create a new project_student_time
            for (let i = 0; i < data.selectedProjects.length; i++) {
                tmpdata = {...data, project_student_times: newStudentTimes, project: data.selectedProjects[i]}
                newData = await postProjectParticipation(tmpdata)
                toast.success("Projektbelegung gespeichert.")
                navigate(`/projects/students/${data.student.id}`)
            }
        }
        else {
            newData = await patchProjectParticipation(tmpdata)
            setData(newData)
            toast.success("Projektbelegung aktualisiert.")
        }
    }

    async function onDelete() {
        await deleteProjectParticipation(data.id)
        toast.success("Projektbelegung gelöscht.")
        navigate(`/projects/students/${data.student.id}`)
    }

    function handleChange(evt) {
        baseHandleChange(evt, data, setData, errors, setErrors, schema)
    }

    function handleProjectChange(selectedOptions) {
        let newData = {...data}
        newData["selectedProjects"] = selectedOptions
        setData(newData)
    }

    function handleDateChange(date, path) {
        baseHandleDateChange(date, path, data, setData, errors, setErrors, schema)
    }

    function handleProjectStudentTimeChange(evt, rowId) {

        const newStudentTimes = data.project_student_times.map(row => {
            if (row.id === rowId) {
                var value = evt.target.value
                if (evt.currentTarget && evt.currentTarget.type === 'checkbox') {
                    value = evt.currentTarget.checked
                }
                return {...row, [evt.target.name]: value}
            }
            return row
        })

        setData({...data, project_student_times: newStudentTimes})
    }

    function handleStudentTimeDelete(studentTimeId) {
        const newStudentTimes = data.project_student_times.filter(row => row.id !== studentTimeId)
        setData({...data, project_student_times: newStudentTimes})
    }

    function addRow() {

        const newRow = {
            id: `N${newRowId}`,
            weekday: "Mo",
            start_time: "14:00",
            end_time: "15:00"
        }

        let newStudentTimes = data.project_student_times ? [...data.project_student_times] : []
        newStudentTimes.push(newRow)

        const newData = {...data, project_student_times: newStudentTimes}
        setNewRowId(newRowId + 1)
        setData(newData)
    }

    const title = (id || data.id) ? "Projektbelegung bearbeiten" : "Neue Projektbelegung eintragen"
    const successMessage = (id || data.id) ? "Projektbelegung aktualisiert" : "Neue Projektbelegung eingetragen"

    return (

        <NarrowPage title={title}>

            <Form id="createProjectParticipation" onSave={onSave}
                  onDelete={onDelete}
                  data={data} setData={setData}
                  className=""
                  errors={errors} setErrors={setErrors}
                  successMessage={successMessage}
                  schema={schema}>

                <FormSectionContent>

                    <div className="sm:col-span-6">
                        <fieldset className="mt-6">
                            <label className="block text-sm font-medium text-gray-900">
                                SchülerIn: {data.student.full_name}
                            </label>
                        </fieldset>
                    </div>

                    {id && <Select
                        path="project"
                        label="Projekt"
                        className="sm:col-span-6"
                        options={projects}
                        valueAttr="id"
                        labelAttr="name"
                        onChange={handleChange}
                        errors={errors}
                        data={data}
                        isClearable={false}
                        valueOnly={false}
                    />}

                    {!id && <MultiSelect
                        path="selectedProjects"
                        label="Projekte"
                        className="sm:col-span-6"
                        options={projects}
                        valueAttr="id"
                        labelAttr="name"
                        // onChange={handleChange}
                        onChange={(selectedOptions) => handleProjectChange(selectedOptions)}
                        errors={errors}
                        data={data}
                        isClearable={true}
                        valueOnly={false}
                    />}

                    <DateInput
                        className="col-span-3"
                        path="start_date"
                        label="Von"
                        onChange={handleDateChange}
                        errors={errors}
                        data={data}
                        todayButton={true}
                        tomorrowButton={false}
                    />

                    <DateInput
                        className="col-span-3"
                        path="end_date"
                        label="Bis"
                        onChange={handleDateChange}
                        errors={errors}
                        data={data}
                        todayButton={false}
                        tomorrowButton={false}
                    />

                    <div className="col-span-6 divide-y divide-gray-200">
                        <div className="py-5 flex justify-between">
                            <h2 id="notes-title" className="text-lg font-medium text-gray-900">
                                Einzelzeiten
                            </h2>
                        </div>
                        <div className="">

                            {data.project_student_times &&
                                <div className="shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
                                    <table className="min-w-full divide-y divide-gray-300">
                                        <tbody>
                                        {data.project_student_times.map((student_time, index) => (
                                            <tr key={index}
                                                className={(index % 2 === 0 ? undefined : "bg-gray-50")}
                                            >
                                                <td className="py-4 pl-4 pr-3 text-sm">
                                                    <Select
                                                        path="weekday"
                                                        label="Wochentag"
                                                        className="sm:col-span-6 z-50"
                                                        onChange={handleProjectStudentTimeChange}
                                                        errors={projectStudentTimeErrors}
                                                        options={weekdays}
                                                        labelAttr="label"
                                                        valueAttr="value"
                                                        valueOnly={true}
                                                        data={data.project_student_times}
                                                        rowId={student_time.id}
                                                    />
                                                </td>

                                                <td className="py-4 pl-4 pr-3 text-sm">
                                                    <Input
                                                        className="col-span-2"
                                                        path="start_time"
                                                        label="Von"
                                                        onChange={handleProjectStudentTimeChange}
                                                        errors={projectStudentTimeErrors}
                                                        data={data.project_student_times}
                                                        rowId={student_time.id}
                                                    />
                                                </td>

                                                <td className="py-4 pl-4 pr-3 text-sm">
                                                    <Input
                                                        className="col-span-2"
                                                        path="end_time"
                                                        label="Bis"
                                                        onChange={handleProjectStudentTimeChange}
                                                        errors={projectStudentTimeErrors}
                                                        data={data.project_student_times}
                                                        rowId={student_time.id}
                                                    />
                                                </td>


                                                {/*<td className="break-word py-4 pl-4 pr-3 text-sm">*/}
                                                {/*    <TextArea*/}
                                                {/*        path="notes"*/}
                                                {/*        label="Anmerkungen"*/}
                                                {/*        className="sm:col-span-6"*/}
                                                {/*        onChange={handleChange}*/}
                                                {/*        errors={errors}*/}
                                                {/*        data={lessons}*/}
                                                {/*        rowId={lesson.id}*/}
                                                {/*    />*/}
                                                {/*</td>*/}

                                                {/*<td className="break-word py-4 pl-4 pr-3 text-sm">*/}
                                                {/*    {lesson.invoice ?*/}
                                                {/*        <TableDownloadLink*/}
                                                {/*            apiFunction={() => getTutoringInvoicePDF(lesson.invoice)}*/}
                                                {/*            fileName={`Rechnung ${lesson.id}.pdf`}*/}
                                                {/*            label="Rechnung PDF"/> : ""}*/}
                                                {/*</td>*/}


                                                <td className="break-word py-4 pl-4 pr-3 text-sm">
                                                    <DeleteButton
                                                        onDelete={() => handleStudentTimeDelete(student_time.id)}/>
                                                </td>

                                            </tr>
                                        ))}

                                        </tbody>
                                    </table>
                                </div>}

                            <div className="flex justify-between">
                                <div className="my-4 ml-4">
                                    <AddButton onClick={addRow} label="Einzelzeit hinzufügen"/>
                                </div>
                            </div>


                        </div>
                    </div>


                </FormSectionContent>

            </Form>

            <Link to={`/projects/students/${data.student.id}`}
                  className="float-right mt-2 font-medium text-imsblue-600">Zurück zur
                Liste</Link>

        </NarrowPage>
    )
}
