import {SimplePage} from "../../elements/Pages";
import React, {useEffect, useState} from "react";
import {useAuth} from "../../../contexts/AuthContext";
import ListViewTable, {TableLink} from "../../elements/tables/ListViewTable";
import SchoolYearFilter from "../../elements/SchoolYearFilter";
import {useSchoolYears} from "../../../contexts/SchoolYearContext";
import {getMyTeacherCourses, getMyTeacherCoursesExcel} from "../../../api/courses";
import {DownloadButton} from "../../elements/Buttons";

const columns = [
    {
        accessorKey: "course_number",
        id: "course_number",
        header: "Nr.",
        enableColumnFilter: false,
    },
    {
        accessorKey: "school_subject.name",
        id: "school_subject",
        header: "Fach",
        enableColumnFilter: true,
        filterFn: "containsString",
    },
    {
        accessorKey: "taught_in",
        id: "taught_in",
        header: "Unterricht in",
        enableColumnFilter: false,
    },
    {
        accessorKey: "lessons_per_week",
        id: "lessons_per_week",
        header: "Wochenstd.",
        enableColumnFilter: false,
    },
    {
        accessorKey: "id",
        id: "link",
        cell: (info) => (
            <TableLink
                label="Kursbuch"
                to={`/class-register/courses/${info.getValue()}`}
            />
        ),
        disableRowLink: true,
        header: "Kursbuch",
        enableColumnFilter: false,
    },
    {
        accessorKey: "id",
        id: "hw",
        cell: (info) => (
            <TableLink
                label="HA & Klausuren"
                to={`/class-register/courses/${info.getValue()}/homework`}
            />
        ),
        disableRowLink: true,
        header: "Hausaufgaben",
        enableColumnFilter: false,
    },
    {
        accessorKey: "id",
        id: "special_assignment",
        cell: (info) => (
            <TableLink
                label="Sonderaufgabe"
                to={`/class-register/homework/new/${info.getValue()}`}
            />
        ),
        disableRowLink: true,
        header: "Sonderaufgabe",
        enableColumnFilter: false,
    },
    {
        accessorKey: "id",
        id: "nb",
        cell: (info) => (
            <TableLink
                label="Notenblatt"
                to={`/grades/courses/${info.getValue()}/notenblatt`}
            />
        ),
        disableRowLink: true,
        header: "Notenblatt",
        enableColumnFilter: false,
    },
    {
        accessorKey: "id",
        id: "mark_definitions",
        cell: (info) => (
            <TableLink
                label="Noten eintragen"
                to={`/grades/courses/${info.getValue()}`}
            />
        ),
        disableRowLink: true,
        header: "Noten",
        enableColumnFilter: false,
    },
];

export default function MyCoursesTeacher() {

    const {permissions} = useAuth();
    const {schoolYears, currentSchoolYear} = useSchoolYears();
    const [rowSelection, setRowSelection] = useState([]);
    const [schoolYearId, setSchoolYearId] = useState();
    const [data, setData] = useState();

    useEffect(() => {
        if (currentSchoolYear) setSchoolYearId(currentSchoolYear["id"]);
    }, [currentSchoolYear])

    useEffect(() => {
        loadData()
    }, [schoolYearId])

    async function loadData() {
        if (!schoolYearId) return
        const newData = await getMyTeacherCourses(schoolYearId)
        setData(newData)
    }

    function getRowLink(row) {
        return `/courses/${row.original.id}/`;
    }

    return (
        <SimplePage>
            {/*  Simple striped table */}
            <div className="px-4 sm:px-6 lg:px-8">
                <div className="sm:flex sm:items-center">
                    <div className="sm:flex-auto">
                        <h3 className="text-lg leading-6 font-medium text-gray-900 mb-2">
                            Meine Kurse
                        </h3>
                    </div>
                    <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
                        <DownloadButton
                            apiFunction={() => getMyTeacherCoursesExcel()}
                            fileName="Kursliste.xls"
                            label="Kursliste herunterladen"/>
                    </div>
                </div>
                <div className="flex flex-col">
                    <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
                        <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                            <SchoolYearFilter
                                schoolYears={schoolYears}
                                schoolYearId={schoolYearId}
                                setSchoolYearId={setSchoolYearId}
                            />
                        </div>

                        <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                            {data && (
                                <ListViewTable
                                    data={data}
                                    columns={columns}
                                    isLoading={false}
                                    disableGlobalSearch={false}
                                    rowSelection={rowSelection}
                                    setRowSelection={setRowSelection}
                                    getRowLink={getRowLink}
                                />
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </SimplePage>
    );
}
