import React from "react";

import {useQuery} from "@tanstack/react-query";
import {useParams} from "react-router-dom";
import {DownloadButton} from "../../elements/Buttons";
import {
    getBoardingSchoolAssessmentsForMentor,
    getBoardingSchoolAssessmentsForMentorTextOnlyPDF
} from "../../../api/boarding_school";
import BoardingSchoolAssessmentDetail from "./BoardingSchoolAssessmentDetail";
import {useUserInfo} from "../../../contexts/UserInfoContext";


export default function MentorDetail() {

    const {renderUserImage} = useUserInfo();
    const {assessmentPeriodId, staffId} = useParams()

    const {isLoading, isError, isSuccess, data, error, refetch} = useQuery(
        ["mentor_boarding_school_assessments", assessmentPeriodId, staffId],
        () => getBoardingSchoolAssessmentsForMentor(assessmentPeriodId, staffId),
    )

    if (!data) return <></>

    return (

        <>
            <main className="py-10 h-full bg-gray-100">
                {/* Page header */}
                <div
                    className="max-w-3xl mx-auto px-4 sm:px-6 md:flex md:items-center md:justify-between md:space-x-5 lg:max-w-7xl lg:px-8">
                    <div className="flex items-center space-x-5">
                        <div className="flex-shrink-0">
                            <div className="relative">
                                {renderUserImage(data.staff.user_id, 80)}
                                <span
                                    className="absolute inset-0 shadow-inner rounded-full"
                                    aria-hidden="true"
                                />
                            </div>
                        </div>
                        <div>
                            <h1 className="text-2xl font-bold text-gray-900">
                                Internatsbeurteilungen von {data.staff.full_name}: {data.assessment_period.name}
                            </h1>
                        </div>
                    </div>
                    <div
                        className="mt-6 flex flex-col-reverse justify-stretch space-y-4 space-y-reverse sm:flex-row-reverse sm:justify-end sm:space-x-reverse sm:space-y-0 sm:space-x-3 md:mt-0 md:flex-row md:space-x-3">

                        <DownloadButton
                            apiFunction={() => getBoardingSchoolAssessmentsForMentorTextOnlyPDF(assessmentPeriodId, staffId)}
                            fileName={`Beurteilungen ${data.staff.full_name}.pdf`}
                            label="PDF herunterladen" />
                    </div>
                </div>

                <div
                    className="max-w-3xl mx-auto grid grid-cols-1 gap-6 sm:px-6 lg:max-w-7xl lg:grid-flow-col-dense lg:grid-cols-3">
                    <div className="space-y-6 lg:col-start-1 lg:col-span-3">

                        <div className="bg-gray-100 pt-6 px-4 sm:px-6 lg:px-8">
                            <div className="flex flex-col">
                                <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
                                    <div className="inline-block py-2 align-middle md:px-6 lg:px-8 max-w-6xl">
                                        {data && data.assessments.map((a, index) => (
                                            <BoardingSchoolAssessmentDetail assessment={a}
                                                              inStudent={false} key={index}
                                                              canEdit={data.can_edit_assessments}/>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>

            </main>

        </>
    );
}
