import {useParams} from "react-router-dom";
import {React, useEffect, useState} from "react";
import {useQuery} from "@tanstack/react-query";
import {AddButton, DangerButton, EditButton, PrimaryButton, SecondaryButton} from "../../elements/Buttons";
import {DeleteButton} from "../../elements/DeleteButton";
import {getAllCoursesWithSchoolYear} from "../../../api/courses";
import {Input, Select} from "../../elements/forms/Inputs";
import {baseHandleChange, baseHandleListChange} from "../../elements/forms/Form";
import {toast} from "react-toastify";
import {
    copyMarkDefinitions,
    deleteMarkDefinitions,
    getCourseGradesDetails,
    getCourseResults,
    patchCourseGradesDetails
} from "../../../api/grades";
import ListViewTable, {TableEditLink, TableLink} from "../../elements/tables/ListViewTable";
import Joi from "joi";
import {validate} from "../../../utils/validation";


const typeOptions = [
    {"value": "schriftlich"},
    {"value": "sonstige"},
]

export default function CourseGrades() {

    const {courseId} = useParams();
    const [data, setData] = useState([])
    const [errors, setErrors] = useState([]) // validation errors
    const [error, setError] = useState("") // Form validation error
    const [markDefinitionErrors, setMarkDefinitionErrors] = useState([]) // validation errors
    const [editingMode, setEditingMode] = useState(false)
    const [newRowId, setNewRowId] = useState(1)

    const [copyMarkDefinitionsData, setCopyMarkDefinitionsData] = useState({
        from_course_id: null,
        to_course_id: courseId
    })

    const {data: courses} = useQuery(["courses_with_school_year"], getAllCoursesWithSchoolYear, {refetchInterval: 1000 * 60 * 60 * 24})
    const {data: results, refetch} = useQuery(["course_results", courseId], () => getCourseResults(courseId))

    let markDefinitionSchema = Joi.object().keys({
        title: Joi.string().required(),
        weight: Joi.number().required(),
        type: Joi.string().required()
    })

    const schema = {
        written_weight: Joi.number().required(),
        other_weight: Joi.number().required(),
        mark_definitions: Joi.array().items(markDefinitionSchema),
    }

    useEffect(() => {
        loadData()
    }, [courseId])

    async function loadData() {
        const newData = await getCourseGradesDetails(courseId)
        setData(newData)
    }

    function addRow() {
        const newRow = {
            id: `N${newRowId}`,
            title: null,
            weight: null,
            type: null
        }
        setNewRowId(newRowId + 1)
        setMarkDefinitions([...data.mark_definitions, newRow])
        setEditingMode(true)
    }

    async function handleSave() {

        // validate
        const validationErrors = validate(schema, data)
        setErrors(validationErrors)
        if (validationErrors) {
            setError("Bitte korrigiere die markierten Felder.")
            return
        } else {
            setError("")
        }

        try {
            const newData = await patchCourseGradesDetails(courseId, data)
            setData(newData)
            setEditingMode(false)
            toast.success("Notendefinitionen gespeichert")
        } catch (e) {
            toast.error("Beim Speichern ist ein Fehler aufgetreten.")
        }
    }

    async function handleCopyFromCourse() {
        const newData = await copyMarkDefinitions(copyMarkDefinitionsData)
        setMarkDefinitions(newData)
        toast.success("Notendefinitionen kopiert.")
    }

    function setMarkDefinitions(newMarkDefinitions) {
        setData({...data, mark_definitions: newMarkDefinitions})
    }

    function handleChange(evt) {
        baseHandleChange(evt, data, setData, errors, setErrors, schema)
    }

    function handleCourseSelectionChange(evt) {
        baseHandleChange(evt, copyMarkDefinitionsData, setCopyMarkDefinitionsData, errors, setErrors, {})
    }

    function handleListChange(evt, rowId) {
        baseHandleListChange(rowId, evt, data.mark_definitions, setMarkDefinitions, markDefinitionErrors, setMarkDefinitionErrors, schema)
    }

    async function handleDelete(markDefinitionId) {
        if (! markDefinitionId.toString().startsWith("N")) {
            try {
                await deleteMarkDefinitions(markDefinitionId)
                loadData()
                toast.success("Notendefinition gelöscht")
            } catch (e) {
                toast.error("Beim Speichern ist ein Fehler aufgetreten.")
            }
        }
        const newMds = data.mark_definitions.filter(row => row.id !== markDefinitionId)
        setMarkDefinitions(newMds)
    }


    function getRowLink(row) {
        return `/grades/results/${row.original.id}`
    }

    if (!data) return <></>

    const markDefinitionColumns = [
        {
            accessorKey: "type",
            id: "type",
            header: "Art",
            enableColumnFilter: false
        },
        {
            accessorKey: "title",
            id: "title",
            header: "Titel",
            enableColumnFilter: false
        },
        {
            accessorKey: "weight",
            id: "weight",
            header: "Gewichtung",
            enableColumnFilter: false
        },
        {
            accessorKey: "result_line_count",
            id: "result_line_count",
            header: "Anzahl Einzelergeb.",
            enableColumnFilter: false,
        },
        // {
        //     accessorKey: "id",
        //     id: "link",
        //     cell: (info) => (
        //         <TableLink label="Ergebnisse" to={`/grades/mark-definitions/${info.getValue()}/results`} />
        //     ),
        //     header: "Ergebnisse",
        //     enableColumnFilter: false,
            // disableRowLink: true
        // },
        {
            accessorKey: "id",
            id: "add_result",
            cell: (info) => (
                <TableLink label="Eintragen" to={`/grades/results/new/${info.getValue()}`} />
            ),
            header: "Eintragen",
            enableColumnFilter: false,
            disableRowLink: true
        }
    ]

    let resultColumns = [
        {
            accessorKey: "date",
            id: "date",
            columnType: "date",
            header: "Datum",
            enableColumnFilter: false
        },
        {
            accessorKey: "mark_definition.type",
            id: "type",
            header: "Art",
            enableColumnFilter: false
        },
        {
            accessorKey: "mark_definition.title",
            id: "title",
            header: "Notendefinition",
            enableColumnFilter: false
        },
        {
            accessorKey: "topic",
            id: "topic",
            header: "Thema",
            enableColumnFilter: false
        }
    ]

    if (data.can_edit) {
        resultColumns.push({
            accessorKey: "id",
            id: "result_edit",
            cell: (info) => (
                <TableEditLink
                    iconOnly={true}
                    label="Bearbeiten"
                    to={`/grades/results/${info.getValue()}/edit`}
                />
            ),
            header: "",
            disableRowLink: true,
            enableColumnFilter: false,
        })
    }

    return (
        <>
            <main className="py-10 h-full bg-gray-100">
                {/* Page header */}
                <div
                    className="max-w-3xl mx-auto px-4 sm:px-6 md:flex md:items-center md:justify-between md:space-x-5 lg:max-w-7xl lg:px-8">
                    <div className="flex items-center space-x-5">
                        <div>
                            <h1 className="text-2xl font-bold text-gray-900">
                                Notendefinitionen & Ergebnisse {data.course_number} {data.school_subject}
                            </h1>
                            <p className="text-sm font-medium text-gray-500">
                                {data.teacher?.full_name}
                            </p>
                        </div>
                    </div>
                    <div
                        className="mt-6 flex flex-col-reverse justify-stretch space-y-4 space-y-reverse sm:flex-row-reverse sm:justify-end sm:space-x-reverse sm:space-y-0 sm:space-x-3 md:mt-0 md:flex-row md:space-x-3">

                        {!editingMode && <EditButton iconOnly={false}
                                                     onClick={() => setEditingMode(true)} label="Bearbeiten"/>}

                        <PrimaryButton label="Kurs ansehen" link={`/courses/${courseId}`} />
                        <PrimaryButton label="Notenblatt" link={`/grades/courses/${courseId}/notenblatt`} />

                    </div>
                </div>

                <div
                    className="mt-8 max-w-3xl mx-auto grid grid-cols-1 gap-6 sm:px-6 lg:max-w-7xl lg:grid-flow-col-dense lg:grid-cols-3">
                    <div className="space-y-6 lg:col-start-1 lg:col-span-2">

                        {data.mark_definitions &&
                            <section aria-labelledby="applicant-information-title">

                                <div className="bg-white shadow overflow-hidden sm:rounded-lg mb-4">

                                    <div className="border-t border-gray-200 px-4 py-5">

                                        <div className="flex justify-between">
                                            <h2 className="text-lg font-medium text-gray-900">Notendefinitionen</h2>

                                        </div>

                                        {data.can_edit && editingMode &&

                                            <div
                                                className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg mt-4">
                                                <table className="min-w-full divide-y divide-gray-300">
                                                    <tbody>
                                                    {data.mark_definitions.map((md, index) => (
                                                        <tr key={index}
                                                            className={(index % 2 === 0 ? undefined : "bg-gray-50")}
                                                        >
                                                            <td className="break-word py-4 pl-4 pr-3 text-sm">
                                                                <Select
                                                                    path="type"
                                                                    label="Art"
                                                                    className="sm:col-span-6"
                                                                    options={typeOptions}
                                                                    valueAttr="value"
                                                                    labelAttr="value"
                                                                    onChange={handleListChange}
                                                                    isClearable={false}
                                                                    valueOnly={true}
                                                                    errors={markDefinitionErrors}
                                                                    data={data.mark_definitions}
                                                                    rowId={md.id}
                                                                />
                                                            </td>
                                                            <td className="break-word py-4 pl-4 pr-3 text-sm">
                                                                <Input
                                                                    path="title"
                                                                    label="Titel"
                                                                    className="sm:col-span-6"
                                                                    onChange={handleListChange}
                                                                    errors={markDefinitionErrors}
                                                                    data={data.mark_definitions}
                                                                    rowId={md.id}
                                                                />
                                                            </td>

                                                            <td className="break-word py-4 pl-4 pr-3 text-sm">
                                                                <Input
                                                                    path="weight"
                                                                    label="Gewichtung"
                                                                    type="number"
                                                                    className="sm:col-span-6"
                                                                    onChange={handleListChange}
                                                                    errors={markDefinitionErrors}
                                                                    data={data.mark_definitions}
                                                                    rowId={md.id}
                                                                />
                                                            </td>
                                                            <td className="break-word py-4 pl-4 pr-3 text-sm">
                                                                <DeleteButton onDelete={() => handleDelete(md.id)}/>
                                                            </td>

                                                        </tr>
                                                    ))}

                                                    </tbody>
                                                </table>
                                            </div>}


                                        {!editingMode && data.mark_definitions &&
                                            <ListViewTable data={data.mark_definitions}
                                                           disableGlobalSearch={true}
                                                           columns={markDefinitionColumns}/>}


                                        {data.can_edit && editingMode &&
                                            <>
                                            <div className="flex justify-between">
                                                <div className="my-4 ml-4">
                                                    <AddButton onClick={addRow} label="Hinzufügen"/>
                                                </div>
                                                <div className="flex justify-end gap-2">



                                                    <SecondaryButton
                                                        onClick={() => setEditingMode(false)}
                                                        classNameIn="my-4" label="Abbrechen"/>
                                                    <PrimaryButton
                                                        classNameIn="my-4"
                                                        disabled={!data}
                                                        onClick={handleSave} label="Speichern"/>


                                                </div>
                                            </div>
                                             {error && <div className="text-right text-sm font-medium text-red-500">{error}</div>}
                                            </>
                                        }

                                    </div>
                                </div>

                            </section>}

                    </div>

                    <section aria-labelledby="timeline-title" className="lg:col-start-3 lg:col-span-1 mt-0">

                        {data &&
                            <section className="" aria-labelledby="applicant-information-title">

                                <div className="bg-white shadow overflow-hidden sm:rounded-lg">

                                    <div className="px-4 py-5 sm:px-6">
                                        <h3 className="text-lg leading-6 font-medium text-gray-900">
                                            Gesamtgewichtung im Kurs
                                        </h3>
                                    </div>

                                    <div className="border-t border-gray-200 py-5">
                                        {!editingMode && <dl className="sm:divide-y sm:divide-gray-200">
                                            <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6">
                                                <dt className="text-sm font-medium text-gray-500">Schriftlich:</dt>
                                                <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                                    {data.written_weight}
                                                </dd>
                                            </div>
                                            <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6">
                                                <dt className="text-sm font-medium text-gray-500">Sonstige:</dt>
                                                <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                                    {data.other_weight}
                                                </dd>
                                            </div>
                                        </dl>}

                                        {editingMode && <dl className="sm:divide-y sm:divide-gray-200">
                                            <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6">
                                                <Input
                                                    path="written_weight"
                                                    label="Schritflich"
                                                    type="number"
                                                    className="sm:col-span-6"
                                                    onChange={handleChange}
                                                    errors={errors}
                                                    data={data}
                                                />
                                            </div>
                                            <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6">
                                                <Input
                                                    path="other_weight"
                                                    label="Sonstige"
                                                    type="number"
                                                    className="sm:col-span-6"
                                                    onChange={handleChange}
                                                    errors={errors}
                                                    data={data}
                                                />
                                            </div>
                                            <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6">
                                                <PrimaryButton
                                                    classNameIn="my-4"
                                                    disabled={!data}
                                                    onClick={handleSave} label="Speichern"/>
                                            </div>
                                        </dl>}
                                    </div>
                                </div>


                                {editingMode && <div className="bg-white shadow overflow-hidden sm:rounded-lg mt-4">

                                    <div className="px-4 py-5 sm:px-6">
                                        <h3 className="text-lg leading-6 font-medium text-gray-900">
                                            Notendefinitionen von einem anderen Kurs kopieren
                                        </h3>
                                        <p className="text-sm font-medium text-gray-900 pt-2">
                                            Alle Notendefinitionen des ausgewählten Kurses werden übernommen.
                                            Bestehende Einträge im Kurs {data.course_number} bleiben
                                            unberührt.
                                        </p>
                                    </div>

                                    <div className="border-t border-gray-200 px-4 py-5">
                                        <Select
                                            path="from_course_id"
                                            label="Kurs"
                                            className="sm:col-span-6 hide-label"
                                            options={courses}
                                            valueAttr="id"
                                            labelAttr="full_name"
                                            onChange={handleCourseSelectionChange}
                                            data={copyMarkDefinitionsData}
                                            isClearable={false}
                                            valueOnly={true}
                                        />

                                        <DangerButton
                                            classNameIn="mt-2 "
                                            label="Notendefinitionen kopieren"
                                            onClick={() => { if (window.confirm('Notendefinition wirklich kopieren?')) handleCopyFromCourse() }}
                                            />
                                    </div>
                                </div>}

                            </section>}

                    </section>


                </div>


                <div
                    className="mt-8 max-w-3xl mx-auto grid grid-cols-1 gap-6 sm:px-6 lg:max-w-7xl lg:grid-flow-col-dense lg:grid-cols-3">
                    <div className="space-y-6 lg:col-start-1 lg:col-span-3">
                        <section aria-labelledby="applicant-information-title">

                            <div className="bg-white shadow overflow-hidden sm:rounded-lg mb-4">

                                <div className="border-t border-gray-200 px-4 py-5">

                                    <div className="flex justify-between">
                                        <h2 className="text-lg font-medium text-gray-900">Alle Kursergebnisse</h2>
                                    </div>

                                    {results &&
                                        <ListViewTable data={results}
                                                       disableGlobalSearch={true}
                                                       getRowLink={getRowLink}
                                                       columns={resultColumns}/>}

                                </div>
                            </div>

                        </section>

                    </div>
                </div>

            </main>
        </>
    )
}
