import {NarrowPage} from "../../elements/Pages";
import {React, useEffect, useState} from "react";
import {useQuery, useQueryClient} from '@tanstack/react-query'
import {Input, Select, TextArea} from "../../elements/forms/Inputs"
import {FormSectionContent} from "../../elements/forms/FormLayout"
import Form, {baseHandleChange} from "../../elements/forms/Form"
import {useNavigate, useParams} from "react-router-dom";
import {toast} from "react-toastify";
import {
    approveFlextimeEntry,
    deleteFlextimeEntry,
    getFlextimeCategories,
    getFlextimeEntry, getStaffList,
    patchFlextimeEntry,
    postFlextimeEntry,
    rejectFlextimeEntry
} from "../../../api/staff";
import {useAuth} from "../../../contexts/AuthContext";
import checkPermission from "../../../utils/permissions";
import {DangerButton, SuccessButton} from "../../elements/Buttons";
import Joi from "joi";


export default function FlextimeEntryCreateEdit() {

    const { permissions } = useAuth()
    const queryClient = useQueryClient()
    const {id, staffId} = useParams()
    const [data, setData] = useState({
        id: null,
        staff: {id: parseInt(staffId) || null},
        entry_type: null,
        staff_role: "Lehrer",
        category: null,
        title: null,
        value: null,
        year: new Date().getFullYear(),
        period: null,
        remarks: null
    })

    const staffRoles = [
        {'value': 'Lehrer', 'name': 'Lehrer'},
        {'value': 'Mentor', 'name': 'Mentor'}
    ]

    const entryTypes = [
        {'value': 'Erfassung', 'name': 'Erfassung'},
        {'value': 'Einlösung', 'name': 'Einlösung'}
    ]

    // const {data: students} = useQuery(['student_info'], getStudentInfoAll)
    const {data: categories} = useQuery(['flextime_categories'], getFlextimeCategories, {refetchInterval: 1000 * 60 * 60 * 24})
    const {data: staff} = useQuery(['staff'], getStaffList, {refetchInterval: 1000 * 60 * 60 * 24})

    const [errors, setErrors] = useState({}) // validation errors

    let navigate = useNavigate();

    useEffect(() => {
        loadData()
    }, [id])

    async function loadData() {
        if (!id) return
        const newData = await getFlextimeEntry(id)
        setData(newData)
    }

    const schema = {
        title: Joi.string().required().min(3),
        entry_type: Joi.string().required(),
        category: Joi.object().required(),
        period: Joi.string().required().min(1),
        value: Joi.number().required(),
        remarks: Joi.string().required().min(3),
    };

    const title = (id || data.id) ? "Flextime-Eintrag bearbeiten" : "Neuen Flextime-Eintrag erfassen"
    const successMessage = (id || data.id) ? "Flextime-Eintrag aktualisiert" : "Neuen Flextime-Eintrag erfasst"

    async function onSave() {
        let newData
        if (!data.id) newData = await postFlextimeEntry(data)
        else newData = await patchFlextimeEntry(data)
        setData(newData)
        toast.success(successMessage)
        queryClient.invalidateQueries({ queryKey: ['my_flextime_entries'] })
        queryClient.invalidateQueries({ queryKey: ['flextime_entries'] })
        navigate(-1)
    }

    async function onDelete() {
        await deleteFlextimeEntry(data.id)
        toast.success("Flextime-Eintrag gelöscht.")
        navigate(-1)
    }

    async function handleApprove() {
        await approveFlextimeEntry(data.id)
        toast.success("Flextime-Eintrag genehmigt.")
        navigate(-1)
    }

    async function handleReject() {
        await rejectFlextimeEntry(data.id)
        toast.success("Flextime-Eintrag abgelehnt.")
        navigate(-1)
    }

    function handleChange(evt) {
        baseHandleChange(evt, data, setData, errors, setErrors, schema)
    }

    const canApproveOrReject = checkPermission(permissions, "staff.change_flextimeentry") ? true : false;

    return (

        <NarrowPage title={title}>

            <Form id="createPenalty" onSave={onSave}
                  onDelete={onDelete}
                  data={data} setData={setData}
                  errors={errors} setErrors={setErrors}
                  successMessage={successMessage}
                  schema={schema}>

                <FormSectionContent>
                    <Select
                        path="staff"
                        label="MitarbeiterIn"
                        className={"sm:col-span-6" + (staffId > 0 ? " hidden" : "")}
                        options={staff}
                        valueAttr="id"
                        labelAttr="full_name"
                        onChange={handleChange}
                        errors={errors}
                        data={data}
                        isClearable={false}
                        valueOnly={false}
                    />

                    <Select
                        path="entry_type"
                        label="Art"
                        className="sm:col-span-6"
                        options={entryTypes}
                        valueAttr="value"
                        labelAttr="name"
                        onChange={handleChange}
                        errors={errors}
                        data={data}
                        isClearable={false}
                        valueOnly={true}
                    />

                    <Select
                        path="category"
                        label="Kategorie"
                        className="sm:col-span-6"
                        options={categories}
                        valueAttr="id"
                        labelAttr="title"
                        onChange={handleChange}
                        errors={errors}
                        data={data}
                        isClearable={false}
                        valueOnly={false}
                    />

                    <Input
                        path="title"
                        type="text"
                        label="Titel"
                        onChange={handleChange}
                        className="sm:col-span-6"
                        errors={errors}
                        data={data}
                    />

                    <Input
                        path="year"
                        type="number"
                        label="Jahr"
                        onChange={handleChange}
                        className="sm:col-span-2"
                        errors={errors}
                        data={data}
                    />

                    <Input
                        path="period"
                        type="text"
                        label="Zeitraum/Umrechnung"
                        onChange={handleChange}
                        className="sm:col-span-2"
                        errors={errors}
                        data={data}
                    />

                    <Input
                        path="value"
                        type="number"
                        label="Anzahl Flexstunden"
                        onChange={handleChange}
                        className="sm:col-span-2"
                        errors={errors}
                        data={data}
                    />


                    <TextArea
                        path="remarks"
                        type="text"
                        label="Anmerkungen"
                        className="sm:col-span-6"
                        onChange={handleChange}
                        errors={errors}
                        data={data}
                        rows={5}
                    />

                </FormSectionContent>

            </Form>

            {canApproveOrReject &&
            <div className="approveRejectButtons justify-end flex gap-2 mt-4 mb-2">
                <SuccessButton
                    onClick={handleApprove}
                    label="Genehmigen"
                />
                <DangerButton
                    onClick={handleReject}
                    label="Ablehnen"
                />
            </div>}


            <span onClick={() => navigate(-1)}
                  className="cursor-pointer float-right mt-2 font-medium text-imsblue-600">Zurück</span>

        </NarrowPage>
    )
}
