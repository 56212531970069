import {SimplePage} from "../elements/Pages";
import React from "react";

import {useQuery} from "@tanstack/react-query";
import {useAuth} from "../../contexts/AuthContext";
import ListViewTable from "../elements/tables/ListViewTable";
import {CalendarButton, EditButton, PrimaryButton} from "../elements/Buttons";
import checkPermission from "../../utils/permissions";
import {schoolEventApi} from "../../api/calendar";

const columns = [
    {
        accessorKey: "title",
        id: "title",
        header: "Titel",
        enableColumnFilter: false,
    },
    {
        accessorKey: "start",
        id: "start",
        header: "Von",
        columnType: "dateWithWeekday",
        enableColumnFilter: false,
    },
    {
        accessorKey: "end",
        id: "end",
        header: "Bis",
        columnType: "dateWithWeekday",
        enableColumnFilter: false,
    },
    {
        accessorKey: "category.name",
        id: "category",
        header: "Kategorie",
        enableColumnFilter: false,
    },
    {
        accessorKey: "location",
        id: "location",
        header: "Ort",
        enableColumnFilter: false,
    },
    {
        accessorKey: "target_groups",
        id: "target_groups",
        header: "Zielgruppen",
        columnType: "m2m",
        labelAttr: "name",
        enableColumnFilter: true,
        headerClassNames: "medium-filter",
        filterFn: "containsString",
    },
    {
        accessorKey: "created_by_full_name",
        id: "created_by_full_name",
        header: "Erstellt von",
        enableColumnFilter: false,
    },
    {
        accessorKey: "id",
        id: "link",
        cell: ({row, value}) => (
            <>
                <EditButton iconOnly={true} link={`/calendar/events/${row.original.id}/edit`}/>
            </>
        ),
        header: "Bearbeiten",
        enableColumnFilter: false,
        disableRowLink: true
    }
];

export default function SchoolEventList() {

    const {permissions} = useAuth();

    const {isLoading, isSuccess, data} = useQuery(
        ["school_events"],
        () => schoolEventApi('get'),
        {refetchInterval: 1000 * 60 * 60}
    )

    function getRowLink(row) {
        return `/calendar?event_id=${row.original.id}`;
    }

    const canAddEvent = checkPermission(permissions, "calendar_app.add_schoolevent")

    return (
        <SimplePage>
            <div className="px-4 sm:px-6 lg:px-8">
                <div className="sm:flex sm:items-center">
                    <div className="sm:flex-auto">
                        <h3 className="text-lg leading-6 font-medium text-gray-900 mb-2">
                            Termine
                        </h3>
                    </div>
                    <div className="mt-4 sm:mt-0 sm:ml-16 flex gap-2">

                        {/*<DownloadButton*/}
                        {/*    apiFunction={() => getExamsExcel()}*/}
                        {/*    fileName="Klausuren.xls"*/}
                        {/*    label="Liste herunterladen"/>*/}

                        <CalendarButton link={"/calendar"} label="Kalender"/>

                        {canAddEvent && <PrimaryButton link={"/calendar/events/new"} label="Termin eintragen"/>}

                    </div>
                </div>
                <div className="flex flex-col">
                    <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">

                        <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                            {isSuccess && data && (
                                <ListViewTable
                                    data={data}
                                    columns={columns}
                                    isLoading={isLoading}
                                    disableGlobalSearch={false}
                                    getRowLink={getRowLink}
                                />
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </SimplePage>
    );
}
