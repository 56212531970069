import React from "react";
import {SimplePage} from "../../elements/Pages";
import {useQuery} from "@tanstack/react-query";
import {ExportPDFButton, PrimaryButton} from "../../elements/Buttons";
import {getCourseNotenblatt, startCourseNotenblattUpdate, startStudentNotenblattUpdate} from "../../../api/grades";
import {Link, useParams} from "react-router-dom";
import {toast} from "react-toastify";
import {formatDate, formatDateTime} from "../../../utils/formatters";
import {ArrowPathIcon} from "@heroicons/react/20/solid";


export function AsyncUpdateButton({label, apiFunction, classNameIn, ...rest}) {

    async function handleStart() {
        try {
            const responseData = await apiFunction()
            toast.success("Gestartet. Die Aktualisierung kann ca. 30-90 Sekunden dauern. Bitte laden Sie dann diese Seite erneut")
        } catch (e) {
            toast.error("Es ist ein Fehler aufgetreten")
        }
    }

    const button = <button onClick={handleStart}
                           type="button"
                           title={label}
                           className="cursor-pointer inline-flex items-center justify-center px-4 py-2
                           border border-gray-300 shadow-sm text-sm font-medium rounded-md
                           text-gray-700 bg-white hover:bg-gray-50 focus:outline-none
                           focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100
                           focus:ring-blue-500"
                           {...rest}
    ><ArrowPathIcon className="h-5 w-5" aria-hidden="true"/>
    </button>

    return button
}


export default function CourseNotenblatt() {

    const {courseId} = useParams()

    const {isLoading, isError, isSuccess, data, error, refetch} = useQuery(
        ["course_notenblatt", courseId],
        () => getCourseNotenblatt(courseId)
    );

    if (!data) return <></>
    return (
        <>
            <SimplePage>
                <div className="px-4 sm:px-6 lg:px-8">
                    <div className="sm:flex sm:items-center">
                        <div className="sm:flex-auto">
                            <h3 className="text-lg leading-6 font-medium text-gray-900">
                                Notenblatt für {data.notenblatt_data.course.course_number} {data.notenblatt_data.course.school_subject}, {data.notenblatt_data.course.teacher?.full_name} (Stand {formatDateTime(data.last_update_date)})
                            </h3>
                        </div>
                        <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none flex gap-1">

                            <AsyncUpdateButton
                                apiFunction={() => startCourseNotenblattUpdate(courseId)}
                                label="Notenblatt aktualisieren" />

                            <ExportPDFButton
                                portrait={false}
                                data={
                                    <div>
                                        <CourseNotenblattDetail
                                            tableData={data.notenblatt_data.first_table}
                                            exporting={true}
                                            course={data.notenblatt_data.course}
                                            lastUpdateDate={data.last_update_date}/>

                                        <div className={"page-break"}/>
                                        <CourseNotenblattDetail
                                            tableData={data.notenblatt_data.second_table}
                                            exporting={true}
                                            course={data.notenblatt_data.course}
                                            lastUpdateDate={data.last_update_date}/>
                                    </div>}
                                fileName={`Notenblatt ${data.notenblatt_data.course.course_number}.pdf`}
                                label="PDF exportieren"
                            />

                            <PrimaryButton link={`/courses/${courseId}`} label="Kurs" />

                        </div>
                    </div>

                    <div className="flex flex-col mt-3 mb-3 scrollbarHeight">
                        <div className="-my-2 -mx-4 sm:-mx-6 lg:-mx-8">

                            <div className="py-2 md:mx-6 lg:mx-8 align-middle overflow-scroll">

                                <CourseNotenblattDetail
                                    tableData={data.notenblatt_data.total_table}
                                    exporting={false}
                                    course={data.course}
                                    lastUpdateDate={data.last_update_date}/>

                            </div>
                        </div>
                    </div>
                </div>
            </SimplePage>

        </>
    );
}

function CourseNotenblattDetail({tableData, exporting, course, lastUpdateDate}) {

    const header = exporting ? <h3 className="text-sm">
        Notenblatt für {course.course_number} {course.school_subject}, {course.teacher?.full_name} (Stand {formatDate(lastUpdateDate)})
    </h3> : <></>
    const table = <table className={"w-full divide-y divide-gray-300 border border-gray-200 kursnotenblatt" + (exporting ? "" : " mt-3 sticky-first-column")}>
        <thead className="bg-gray-50">

        {/* Top level: top columns */}
        <tr className="divide-x divide-gray-200">
            {tableData.top_columns.map((c, index) => (
                <th className={exporting ? "text-center text-xs" : "py-3.5 text-center text-xs bg-gray-50"} key={index}
                    colSpan={c.colspan}>
                    {c.header}
                </th>
            ))}
        </tr>

        <tr className="divide-x divide-y divide-gray-200">
            {tableData.second_columns.map((c, index) => (
                <th className={exporting ? "text-center text-xs" : "py-3.5 text-center font-normal text-xs bg-gray-50"}
                    key={index}
                    rowSpan={c.rowspan}
                    dangerouslySetInnerHTML={{__html: c.header}}>
                </th>
            ))}
        </tr>
        <tr className="divide-x divide-y divide-gray-200">
            {tableData.third_columns.map((c, index) => (
                <th className={exporting ? "text-center text-xs" : "py-3.5 text-center font-normal text-xs bg-gray-50"}
                    key={index}
                    rowSpan={c.rowspan}
                    dangerouslySetInnerHTML={{__html: c.header}}>
                </th>
            ))}
        </tr>
        <tr className="divide-x divide-y divide-gray-200">
            {tableData.fourth_columns.map((c, index) => (
                <th className={exporting ? "text-center text-xs" : "py-3.5 text-center font-normal text-xs bg-gray-50"}
                    key={index}
                    rowSpan={c.rowspan}
                    dangerouslySetInnerHTML={{__html: c.header}}>
                </th>
            ))}
        </tr>

        </thead>

        <tbody>

        {tableData.rows.map((studentRow, studentIndex) => {
            return <tr key={studentIndex} className="divide-x divide-y divide-gray-200">

                <td className={exporting ? "text-xs border-b" : "py-4 pl-4 pr-3 text-sm border-b bg-white"}
                    key="student">
                    <Link className="text-imsblue-600" to={`/school/students/${studentRow.student.id}`}>
                        {studentRow.student.full_name}</Link></td>

                {studentRow.student_rows.map((r, rowIndex) => (
                    <Slot slot={r}
                          key={rowIndex}
                          exporting={exporting}
                          rowSpan={1}
                    />
                ))}
            </tr>
        })}
        </tbody>

    </table>

    return <>{header} {table}</>
}


function Slot({slot, slotIndex, rowSpan, exporting}) {

    if (slot && typeof slot === 'object') {
        return <td key={slotIndex} className={exporting ? "text-xs" : "py-4 pl-4 pr-3 text-sm"}>
            {slot?.results?.map((result, resultIndex) => (
                <span key={resultIndex}>
                    {(resultIndex > 0 ? ', ' : '')}
                    <Link className={exporting ? "" : "text-imsblue-600"} to={`/grades/results/${result.result_id}`}>{result.mark_display}</Link>
                </span>
            ))}
        </td>
    }

    else {
        // averages only for the first row in this mark definition
        return <td key={slotIndex} rowSpan={rowSpan} className={exporting ? "text-xs" : "py-4 pl-4 pr-3 text-sm"}
                   dangerouslySetInnerHTML={{__html: slot}}
        ></td>
    }

}
