import {Link, Outlet} from "react-router-dom";
import {Fragment, useState} from "react";
import {Dialog, Transition} from "@headlessui/react";
import {
    BellIcon,
    Bars3BottomLeftIcon,
    XMarkIcon,
    EnvelopeIcon,
} from "@heroicons/react/24/outline";
import {MagnifyingGlassIcon, ShoppingBagIcon} from "@heroicons/react/20/solid";
import Footer from "./Footer";
import Logo from "./Logo";
import {Navigation, UserNavigation} from "./Navigation";
import {useShopCart} from "../../contexts/ShopCartContext";
import {useMessenger} from "../../contexts/MessengerContext";
import {SimplePage} from "../elements/Pages";
import {ModuleWithTabs} from "./ModuleWithTabs";
import {useAuth} from "../../contexts/AuthContext";
import {getCurrentDate} from "../../api/calendar";
import {useQuery} from "@tanstack/react-query";
import {getStudentDetailV2} from "../../api/plupus";
import {formatDate, formatDateTime, formatDateWithWeekday} from "../../utils/formatters";

export default function MainLayout({hideSecondaryNav}) {
    const [sidebarOpen, setSidebarOpen] = useState(false);

    const {data: currentDate} = useQuery(
        ["current_date"],
        () => getCurrentDate(),
        {refetchInterval: 1000 * 60 * 60}
    );

    const showCurrentDate = currentDate && currentDate?.override_enabled;

    const {
        cartList,
    } = useShopCart();

    const {
        newMessagesCount
    } = useMessenger()

    const {currentMainNavItem, setCurrentMainNavItem} = useAuth()

    return (
        <>
            <div className="">
                <Transition.Root show={sidebarOpen} as={Fragment}>
                    <Dialog
                        as="div"
                        className="relative z-40 xl:hidden"
                        onClose={setSidebarOpen}
                    >
                        <Transition.Child
                            as={Fragment}
                            enter="transition-opacity ease-linear duration-300"
                            enterFrom="opacity-0"
                            enterTo="opacity-100"
                            leave="transition-opacity ease-linear duration-300"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                        >
                            <div className="fixed inset-0 bg-gray-600 bg-opacity-75"/>
                        </Transition.Child>

                        <div className="fixed inset-0 flex z-40">
                            <Transition.Child
                                as={Fragment}
                                enter="transition ease-in-out duration-300 transform"
                                enterFrom="-translate-x-full"
                                enterTo="translate-x-0"
                                leave="transition ease-in-out duration-300 transform"
                                leaveFrom="translate-x-0"
                                leaveTo="-translate-x-full"
                            >
                                <Dialog.Panel
                                    className="relative flex-1 flex flex-col max-w-xs w-full pt-5 pb-4 bg-imsblue">
                                    <Transition.Child
                                        as={Fragment}
                                        enter="ease-in-out duration-300"
                                        enterFrom="opacity-0"
                                        enterTo="opacity-100"
                                        leave="ease-in-out duration-300"
                                        leaveFrom="opacity-100"
                                        leaveTo="opacity-0"
                                    >
                                        <div className="absolute top-0 right-0 -mr-12 pt-2">
                                            <button
                                                type="button"
                                                className="ml-1 flex items-center justify-center h-10 w-10 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                                                onClick={() => setSidebarOpen(false)}
                                            >
                                                <span className="sr-only">Close sidebar</span>
                                                <XMarkIcon
                                                    className="h-6 w-6 text-white"
                                                    aria-hidden="true"
                                                />
                                            </button>
                                        </div>
                                    </Transition.Child>
                                    <div className="flex-shrink-0 flex items-center px-4">
                                        <Logo/>
                                    </div>
                                    <div className="mt-5 flex-1 h-0 overflow-y-auto">
                                        <Navigation
                                            navClass="px-2 space-y-1"
                                            linkClass="group flex items-center px-2 py-2 text-base font-medium rounded-md"
                                            itemClass="mr-4 flex-shrink-0 h-6 w-6 text-imsblue-300"
                                        />
                                    </div>
                                </Dialog.Panel>
                            </Transition.Child>
                            <div className="flex-shrink-0 w-14" aria-hidden="true">
                                {/* Dummy element to force sidebar to shrink to fit close icon */}
                            </div>
                        </div>
                    </Dialog>
                </Transition.Root>

                {/* Static sidebar for desktop */}
                <div className="hidden xl:flex xl:w-64 xl:flex-col xl:fixed xl:inset-y-0">
                    {/* Sidebar component, swap this element with another sidebar if you like */}
                    <div className="flex flex-col flex-grow pt-5 bg-imsblue overflow-y-auto">
                        <div className="flex items-center">
                            <Logo/>
                        </div>
                        <div className="mt-5 flex-1 flex flex-col">
                            <Navigation
                                navClass="flex-1 px-2 pb-4 space-y-1"
                                linkClass="group flex items-center px-2 py-2 text-sm font-medium rounded-md"
                                itemClass="mr-3 flex-shrink-0 h-6 w-6 text-imsblue-300"
                            />
                        </div>
                    </div>
                </div>
                <div className="xl:pl-64 flex flex-col flex-1">
                    <div className="sticky top-0 z-10 flex-shrink-0 flex h-16 bg-white shadow">
                        <button
                            type="button"
                            className="px-4 border-r border-gray-200 text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-imsblue-500 xl:hidden"
                            onClick={() => setSidebarOpen(true)}
                        >
                            <span className="sr-only">Open sidebar</span>
                            <Bars3BottomLeftIcon className="h-6 w-6" aria-hidden="true"/>
                        </button>
                        <div className="flex-1 px-4 flex justify-between">
                            <div className="flex-1 flex">
                                {/*<form className="w-full flex md:ml-0" action="#" method="GET">*/}
                                {/*    <label htmlFor="search-field" className="sr-only">*/}
                                {/*        Search*/}
                                {/*    </label>*/}
                                {/*    <div className="relative w-full text-gray-400 focus-within:text-gray-600">*/}
                                {/*        <div*/}
                                {/*            className="absolute inset-y-0 left-0 flex items-center pointer-events-none">*/}
                                {/*            <MagnifyingGlassIcon*/}
                                {/*                className="h-5 w-5"*/}
                                {/*                aria-hidden="true"*/}
                                {/*            />*/}
                                {/*        </div>*/}
                                {/*        <input*/}
                                {/*            id="search-field"*/}
                                {/*            className="block w-full h-full pl-8 pr-3 py-2 border-transparent text-gray-900 placeholder-gray-500 focus:outline-none focus:placeholder-gray-400 focus:ring-0 focus:border-transparent sm:text-sm"*/}
                                {/*            placeholder="Search"*/}
                                {/*            type="search"*/}
                                {/*            name="search"*/}
                                {/*        />*/}
                                {/*    </div>*/}
                                {/*</form>*/}
                            </div>
                            <div className="ml-4 flex items-center md:ml-6 gap-x-2">
                                {/*<button*/}
                                {/*  type="button"*/}
                                {/*  className="bg-white p-1 rounded-full text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-imsblue-500"*/}
                                {/*>*/}
                                {/*  <span className="sr-only">View notifications</span>*/}
                                {/*  <BellIcon className="h-6 w-6" aria-hidden="true" />*/}
                                {/*</button>*/}

                                {showCurrentDate && <span>Systemzeit: {formatDateTime(currentDate?.now)}</span>}

                                {!!cartList.length && <Link className="ml-2" to="/shop/cart">
                                    <button
                                        type="button"
                                        className="relative bg-white p-1 rounded-full text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-imsblue-500"
                                    >
                                        <span className="sr-only">View cart</span>
                                        <ShoppingBagIcon className="h-6 w-6" aria-hidden="true"/>
                                        <div
                                            className="absolute inline-flex items-center justify-center w-6 h-6 text-xs font-bold text-white bg-red-500 border-2 border-white rounded-full -top-2 -right-2 dark:border-gray-900">
                                            {cartList.length}
                                        </div>
                                    </button>
                                </Link>}

                                <Link className="ml-2" to="/messenger" onClick={() => setCurrentMainNavItem(null)}>
                                    <button
                                        type="button"
                                        className="relative bg-white p-1 rounded-full text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-imsblue-500"
                                    >
                                        <span className="sr-only">Messenger</span>
                                        <EnvelopeIcon className="h-6 w-6" aria-hidden="true"/>
                                        {newMessagesCount > 0 && <div
                                            className="absolute inline-flex items-center justify-center w-6 h-6 text-xs font-bold text-white bg-red-500 border-2 border-white rounded-full -top-2 -right-2 dark:border-gray-900">
                                            {newMessagesCount}
                                        </div>}
                                    </button>
                                </Link>

                                <UserNavigation
                                    navClass="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
                                    itemClass="block px-4 py-2 text-sm text-gray-700"
                                />
                            </div>
                        </div>
                    </div>

                    <main>
                        <div className="main-content-height">
                            {hideSecondaryNav ? <Outlet/> :
                                <SimplePage><ModuleWithTabs tabs={currentMainNavItem?.sub_items}
                                                            title={currentMainNavItem?.title}>
                                    <Outlet/>
                                </ModuleWithTabs></SimplePage>}
                        </div>
                    </main>

                    <Footer/>
                </div>
            </div>
        </>
    );
}
