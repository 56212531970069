import {SimplePage} from "../../elements/Pages";
import React, {useEffect, useState} from "react";
import ListViewTable, {TableLink} from "../../elements/tables/ListViewTable";
import {getCourseMaterialReport, getCourseMaterialReportExcel} from "../../../api/reports";
import {DownloadButton} from "../../elements/Buttons";
import {useSchoolYears} from "../../../contexts/SchoolYearContext";
import SchoolYearFilter from "../../elements/SchoolYearFilter";


export default function CourseMaterialReport() {

    const {schoolYears, currentSchoolYear} = useSchoolYears();
    const [schoolYearId, setSchoolYearId] = useState();

    const [data, setData] = useState([])

    useEffect(() => {
        loadData()
    }, [schoolYearId])

    async function loadData() {
        if (!schoolYearId) return
        const newData = await getCourseMaterialReport(schoolYearId)
        setData(newData)
    }

    useEffect(() => {
        if (currentSchoolYear) setSchoolYearId(currentSchoolYear["id"]);
    }, [currentSchoolYear]);

    let columns = [
        {
            accessorKey: "id",
            id: "nb",
            cell: ({row, value}) => (
                <TableLink
                    label={`${row.original.course_number}`}
                    to={`/courses/${row.original.id}`}
                />
            ),
            disableRowLink: true,
            header: "Kurs",
            enableColumnFilter: false,
        },
        {
            accessorKey: "school_subject",
            id: "school_subject",
            header: "Fach",
            headerClassNames: "medium-filter",
            enableColumnFilter: true,
        },
        {
            accessorFn: (row) =>
                `${row.last_name}, ${row.first_name}`,
            id: "teacher",
            header: "LehrerIn",
            enableColumnFilter: false,
        },
        {
            accessorKey: "num_cm",
            id: "num_cm",
            header: "Kursmat.",
            headerClassNames: "narrow-filter",
            enableColumnFilter: false,
        },
        {
            accessorKey: "num_hwm",
            id: "num_hwm",
            header: "HA-Mat.",
            headerClassNames: "narrow-filter",
            enableColumnFilter: false,
        },
        {
            accessorKey: "total",
            id: "total",
            header: "Gesamt",
            headerClassNames: "narrow-filter",
            enableColumnFilter: false,
        }
    ]

    function getRowLink(row) {
        return `/courses/${row.original.id}`;
    }

    return (
        <SimplePage>
            {/*  Simple striped table */}
            <div className="px-4 sm:px-6 lg:px-8">
                <div className="sm:flex sm:items-center">
                    <div className="sm:flex-auto">
                        <h3 className="text-lg leading-6 font-medium text-gray-900">
                            Kursmaterialien nach Kursen/Lehrern
                        </h3>
                    </div>
                    <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
                        <DownloadButton
                            apiFunction={() => getCourseMaterialReportExcel(schoolYearId)}
                            fileName="Kursmaterialien.xls"
                            label="Excel herunterladen"/>

                    </div>
                </div>
                <div className="flex flex-col">
                    <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">

                        <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                            <SchoolYearFilter
                                schoolYears={schoolYears}
                                schoolYearId={schoolYearId}
                                setSchoolYearId={setSchoolYearId}
                            />
                        </div>

                        <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                            {data && (
                                <ListViewTable
                                    data={data}
                                    columns={columns}
                                    isLoading={false}
                                    disableGlobalSearch={false}
                                    getRowLink={getRowLink}
                                />
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </SimplePage>
    );
}
