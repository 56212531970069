import {useEffect, useState} from "react";
import {useSearchParams} from "react-router-dom";
import {useDeviceBookings} from "../../../contexts/DeviceBookingsContext";
import {daysOfWeek} from "../../calendar/calendarUtils";
import {
    addDaysToDate,
    checkIfDateFallsBetween,
    // getDateOfWeek,
    getDaysInMonth,
    getFormattedTime,
    getMonthOfWeek,
    getNumberArrayWithRange,
    today,
} from "../../../utils/helpers";
import Month from "./../../calendar/Month";
import Week from "./../../calendar/Week";
import DeviceBookingCalendarContainer from "./DeviceBookingCalendarContainer";

export default function DeviceBookingCalendar() {
    const {
        selectedDate,
        setSelectedDate,
        view,
        setView,
        fetchedEventsMonth,
        fetchedEventsWeek,
        openEvent,
    } = useDeviceBookings();
    const search = window.location.search;
    const params = new URLSearchParams(search);
    const event_id = params.get("event_id");
    const year = params.get("year");
    const month = params.get("month");
    const week = params.get("week");
    const date = params.get("date");
    const [days, setDays] = useState([]);
    const [weekDays, setWeekDays] = useState([]);
    const nextPrev = (action, value) => {
        switch (action) {
            case "next":
                if (view === "month")
                    setSelectedDate({
                        year:
                            selectedDate.month === 12
                                ? selectedDate.year + 1
                                : selectedDate.year,
                        month: selectedDate.month === 12 ? 1 : selectedDate.month + 1,
                        date: selectedDate.date,
                    });
                else {
                    const currentDate = new Date(selectedDate.year, selectedDate.month-1, selectedDate.date)
                    const newDate = addDaysToDate(currentDate, 7);

                    setSelectedDate({
                        year: newDate.getFullYear(),
                        month: newDate.getMonth() + 1,
                        date: newDate.getDate(),
                    });
                }
                break;

            case "prev":
                if (view === "month")
                    setSelectedDate({
                        year:
                            selectedDate.month === 1
                                ? selectedDate.year - 1
                                : selectedDate.year,
                        month: selectedDate.month === 1 ? 12 : selectedDate.month - 1,
                        date: selectedDate.date,
                    });
                else {
                    const currentDate = new Date(selectedDate.year, selectedDate.month-1, selectedDate.date)
                    const newDate = addDaysToDate(currentDate, -7);

                    setSelectedDate({
                        year: newDate.getFullYear(),
                        month: newDate.getMonth() + 1,
                        date: newDate.getDate(),
                    });
                }
                break;

            case "curr":
                setSelectedDate({
                    year: today.getFullYear(),
                    month: today.getMonth() + 1,
                    date: today.getDate(),
                });
                break;
            case "value":
                if (view === "month")
                    setSelectedDate({
                        ...value,
                    });
                else {
                    const currentDate = `${value.year}-${value.month}-${value.date}`;
                    const newDate = addDaysToDate(currentDate, -7);

                    setSelectedDate({
                        year: newDate.getFullYear(),
                        month: newDate.getMonth() + 1,
                        date: newDate.getDate(),
                    });
                }
                break;

            default:
                break;
        }
    };

    const setDaysForWeek = () => {
        const dateObj = new Date(selectedDate.year, selectedDate.month - 1, selectedDate.date)
        var dayOfWeek = dateObj.getDay() === 0 ? 7 : dateObj.getDay()
        const startOfWeek = addDaysToDate(dateObj, -dayOfWeek)

        const newWeekDays = daysOfWeek.map((day, index) => {
            const date = addDaysToDate(startOfWeek, index + 1);
            return {
                ...day,
                date,
                isToday:
                    `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}` ===
                    `${today.getFullYear()}-${today.getMonth() + 1}-${today.getDate()}`,
            };
        });
        setWeekDays(newWeekDays);
    };
    // useEffect(() => {
    //     if (view === "month") setDaysForMonth();
    // }, [selectedDate, fetchedEventsMonth]);
    useEffect(() => {
        // console.log("selectedDate", selectedDate);
        if (view === "week") setDaysForWeek();
    }, [selectedDate]);

    useEffect(() => {
        if (week) {
            setView("week");
            const month = getMonthOfWeek(parseInt(week), parseInt(year));
            // const date = getDateOfWeek(parseInt(week), parseInt(year));
            setSelectedDate({
                year: parseInt(year),
                month,
                date: parseInt(date),
            });
        } else if (month) {
            setView("month");
            setSelectedDate({
                year: parseInt(year),
                month,
                date: today.getDate(),
            });
        }
    }, [year, month, week, date]);
    useEffect(() => {
        if (view === "week" && event_id && fetchedEventsWeek?.length) {
            const selectedEvent = fetchedEventsWeek.find(
                (i) => i.event_id === parseInt(event_id)
            );
            if (selectedEvent) openEvent(selectedEvent);
        }
    }, [event_id, fetchedEventsWeek]);

    return (
        <DeviceBookingCalendarContainer
            setView={setView}
            view={view}
            nextPrev={nextPrev}
            selectedDate={selectedDate}
        >
            <Week providerFunction={useDeviceBookings} days={weekDays}/>
        </DeviceBookingCalendarContainer>
    );
}
