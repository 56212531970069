import {NarrowPage} from "../../elements/Pages";
import {React, useEffect, useState} from "react";
import Joi from "joi"

import {Link, useNavigate, useParams} from 'react-router-dom'
import {useQuery} from '@tanstack/react-query'
import {Input, Select} from "../../elements/forms/Inputs"
import {FormSectionContent} from "../../elements/forms/FormLayout"
import {getStudentInfo} from "../../../api/school";
import Form, {baseHandleChange} from "../../elements/forms/Form"
import {toast} from "react-toastify";
import {getWeekdayOptions} from "../../../api/calendar";
import {getRooms} from "../../../api/organizational";
import {getStudierzeitTimesListOnly, studierzeitChangeApi} from "../../../api/studierzeit";


export default function StudierzeitChangeCreateEdit() {

    const {id, studentId} = useParams()

    const [errors, setErrors] = useState({})
    const [data, setData] = useState({
        id: null,
        student: {id: parseInt(studentId) || null}
    })

    let navigate = useNavigate();

    const {data: weekdays} = useQuery(['weekdays'], getWeekdayOptions, {refetchInterval: 1000 * 60 * 60 * 24})
    const {data: rooms} = useQuery(["rooms"], getRooms, {refetchInterval: 1000 * 60 * 60 * 24});
    const {data: studierzeitTimes} = useQuery(['studierzeit_times', 'show_in_list'], getStudierzeitTimesListOnly, {refetchInterval: 1000 * 60 * 60 * 24})

    const schema = {
        weekday: Joi.string().required(),
    };

    useEffect(() => {
        loadData()
    }, [id, studentId])

    async function loadData() {

        if (studentId) {
            const student = await getStudentInfo(studentId)
            const newData = {...data, student: {...student}}
            setData(newData)
            return
        }

        if (!id) return
        const newData = await studierzeitChangeApi('get', id)
        setData(newData)
    }

    async function onSave() {
        let newData
        if (!data.id) newData = await studierzeitChangeApi('post', null, data)
        else newData = await studierzeitChangeApi('patch', null, data)
        setData(newData)
        toast.success("Studierzeitwechsel aktualisiert.")
        navigate(`/studierzeit/students/${data.student.id}`)
    }

    async function onDelete() {
        await studierzeitChangeApi('delete', data.id)
        toast.success("Studierzeitwechsel gelöscht.")
        navigate(`/studierzeit/students/${data.student.id}`)
    }

    function handleChange(evt) {
        baseHandleChange(evt, data, setData, errors, setErrors, schema)
    }

    const title = (id || data.id) ? "Studierzeitwechsel bearbeiten" : "Studierzeitwechsel eintragen"
    const successMessage = (id || data.id) ? "Studierzeitwechsel aktualisiert" : "Studierzeitwechsel eingetragen"

    return (

        <NarrowPage title={title}>

            <Form id="createStudierzeitChange" onSave={onSave}
                  onDelete={onDelete}
                  data={data} setData={setData}
                  className=""
                  errors={errors} setErrors={setErrors}
                  successMessage={successMessage}
                  schema={schema}>

                <FormSectionContent>

                    <div className="sm:col-span-6">
                        <fieldset className="mt-0">
                            <label className="block text-sm font-medium text-gray-900">
                                SchülerIn: {data.student.full_name}
                            </label>
                        </fieldset>
                    </div>

                    <Select
                        path="weekday"
                        label="Wochentag"
                        className="sm:col-span-6 z-50"
                        onChange={handleChange}
                        errors={errors}
                        options={weekdays}
                        labelAttr="label"
                        valueAttr="value"
                        valueOnly={true}
                        data={data}
                    />

                    <Select
                        path="from_studierzeit_time"
                        label="Bisherige Studierzeit"
                        className="sm:col-span-3"
                        options={studierzeitTimes}
                        valueAttr="id"
                        labelAttr="__str__"
                        onChange={handleChange}
                        errors={errors}
                        data={data}
                        isClearable={true}
                        valueOnly={false}
                    />
                    <Select
                        path="new_studierzeit_time"
                        label="Neue Studierzeit"
                        className="sm:col-span-3"
                        options={studierzeitTimes}
                        valueAttr="id"
                        labelAttr="__str__"
                        onChange={handleChange}
                        errors={errors}
                        data={data}
                        isClearable={true}
                        valueOnly={false}
                    />

                    <Select
                        path="room"
                        label="Raum"
                        className="sm:col-span-6"
                        options={rooms}
                        valueAttr="id"
                        labelAttr="name"
                        onChange={handleChange}
                        errors={errors}
                        data={data}
                        isClearable={true}
                        valueOnly={false}
                    />

                    <Input
                        path="reason"
                        type="text"
                        label="Grund"
                        className="sm:col-span-6"
                        onChange={handleChange}
                        errors={errors}
                        data={data}
                    />

                </FormSectionContent>

            </Form>

            <Link to={`/studierzeit/students/${data.student.id}`}
                  className="float-right mt-2 font-medium text-imsblue-600">Zurück zur
                Übersicht</Link>

        </NarrowPage>
    )
}
