import React from "react";
import {useAuth} from "../../../contexts/AuthContext";
import {useParams} from "react-router-dom";
import {getTeacherAppointmentList, getTeacherAppointmentListPDF} from "../../../api/parent_conference";
import {SimplePage} from "../../elements/Pages";
import {DownloadButton} from "../../elements/Buttons";
import {useQuery} from "@tanstack/react-query";


export default function TeacherAppointmentList() {

    const {teacherId, conferenceDayId} = useParams();
    const {permissions} = useAuth();

    const {isLoading, isError, isSuccess, data, error, refetch} = useQuery(
        ["teacher_appointment_list", conferenceDayId, teacherId],
        () => getTeacherAppointmentList(conferenceDayId, teacherId),
        {}
    )

    if (!data) return <></>;
    return (
        <>
            <SimplePage>
                <div className="px-4 sm:px-6 lg:px-8">
                    <div className="sm:flex sm:items-center">
                        <div className="sm:flex-auto">
                            <h3 className="text-lg leading-6 font-medium text-gray-900">
                                {data.conference_day.title} - Terminübersicht für {data.teacher.full_name}
                            </h3>
                        </div>
                        <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none gap-1 flex">
                            {/*<PrimaryButton link={`/parent-conference/${conferenceDayId}/teachers/${teacherId}/`} label="Tabellenansicht" />*/}

                            <DownloadButton
                                apiFunction={() => getTeacherAppointmentListPDF(conferenceDayId, teacherId)}
                                fileName={`Termine ${data.teacher.full_name}.pdf`}
                                label="PDF herunterladen"/>
                        </div>
                    </div>
                    <div className="flex flex-col mt-3 mb-3 scrollbarHeight">
                        <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
                            <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">

                                <div className="overflow-hidden">
                                    <table className="divide-y divide-gray-300 mt-3" style={{borderSpacing: 0}}>
                                        <thead className="bg-imsblue-900 text-white text-sm">
                                        <tr>
                                            <th className="text-md py-2 px-2">Uhrzeit</th>
                                            <th className="text-md py-2 px-2">Termin</th>
                                        </tr>
                                        </thead>
                                        <tbody>

                                        {data.days.map((day, dayIndex) => (

                                            day.slots.map((slot, index) => (

                                                <tr key={index}>
                                                    <td className="py-1 px-1 border-b border-gray-200"
                                                        key="slotTimeLeft">{slot.start_time}</td>
                                                    <ListSlot slot={slot} key={index}/>
                                                </tr>
                                            ))

                                        ))}

                                        </tbody>

                                    </table>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </SimplePage>
        </>
    );
}

function ListSlot({slot}) {
    var slotContent
    if (slot.status === 'blocked' || slot.blocked) {
        slotContent =
            <>
                <p className="text-sm">
                    {slot.title}
                </p>
            </>

    } else if (slot.status === 'booked') {
        slotContent =
            <>
                <p className="text-gray-900">{slot.student.full_name}</p>
                {slot.additional_participants &&
                    <p className="text-gray-900">{slot.additional_participants}</p>}
                {slot.remarks &&
                    <p className="text-gray-700">{slot.remarks}</p>}
            </>
    }

    return <td className={"py-1 px-1 min-w-4 border-b border-gray-200"}>{slotContent}</td>
}
