import {DetailPage} from "../../elements/Pages";
import {React} from 'react'

import {useQuery} from '@tanstack/react-query'
import {useAuth} from "../../../contexts/AuthContext";
import checkPermission from "../../../utils/permissions";
import {Link, useParams} from "react-router-dom";
import {CheckCircleIcon} from "@heroicons/react/20/solid";
import {DangerButton, EditButton, SuccessButton} from "../../elements/Buttons";
import {approveExcursion, excursionApi, rejectExcursion} from "../../../api/school";
import {formatDate, formatDateTime} from "../../../utils/formatters";
import {toast} from "react-toastify";
import {getKey} from "../../../api/organizational";
import ListViewTable, {TableEditLink} from "../../elements/tables/ListViewTable";


export default function KeyDetail() {

    const {id} = useParams()
    const {permissions} = useAuth()
    const {isLoading, isError, refetch, data} = useQuery(['keys', id], () => getKey(id), {})
    const canIssueKeys = checkPermission(permissions, "organizational.add_keyissue")

    const columns = [
        {
            accessorKey: "user.full_name",
            id: "user",
            header: "Ausgabe an",
            enableColumnFilter: false,
            headerClassNames: "medium-filter"
        },
        {
            accessorKey: "issue_date",
            id: "issue_date",
            header: "Ausgegeben am",
            columnType: "date",
            enableColumnFilter: false,
        },
        {
            accessorKey: "return_date",
            id: "return_date",
            header: "Zurückgegeben am",
            columnType: "date",
            enableColumnFilter: false,
        },
        {
            accessorKey: "created_by_full_name",
            id: "created_by_full_name",
            header: "Ausgegeben von",
            enableColumnFilter: false
        },
        ...canIssueKeys ? [ {
            accessorKey: "id",
            id: "edit",
            header: "Bearbeiten",
            enableColumnFilter: false,
            disableRowLink: true,
            cell: ({row, value}) => (
                <TableEditLink iconOnly={true} to={`/service/key-issues/${row.original.id}/edit`}/>
            )
        }] : [],
    ]


    function getRowLink(row) {
        return canIssueKeys ? `/service/key-issues/${row.original.id}/edit` : null;
    }

    if (!data) return <></>
    return (

        <DetailPage>
            <div
                className="max-w-3xl mx-auto px-4 sm:px-6 md:flex md:items-center md:justify-between md:space-x-5 lg:max-w-7xl lg:px-8">
                <div className="flex items-center space-x-5">
                    <h1 className="text-2xl font-bold text-gray-900">Schlüssel {data.key_number}</h1>
                </div>
                <div
                    className="mt-6 flex flex-col-reverse justify-stretch space-y-4 space-y-reverse sm:flex-row-reverse sm:justify-end sm:space-x-reverse sm:space-y-0 sm:space-x-3 md:mt-0 md:flex-row md:space-x-3">

                    {canIssueKeys &&
                        <EditButton
                            iconOnly={false}
                            link={`/service/key-issues/new/${data.id}`}
                            label="Neue Ausgabe"
                        />}
                </div>
            </div>

            <div
                className="mt-8 max-w-3xl mx-auto grid grid-cols-1 gap-6 sm:px-6 lg:max-w-7xl lg:grid-flow-col-dense lg:grid-cols-3">
                <div className="space-y-6 lg:col-start-1 lg:col-span-2">

                    <section aria-labelledby="applicant-information-title">
                        <div className="bg-white shadow overflow-hidden sm:rounded-lg">

                            <div className="border-t border-gray-200 px-4 py-5 sm:p-0">
                                <dl className="sm:divide-y sm:divide-gray-200">
                                    <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                        <dt className="text-sm font-medium text-gray-500">Schließsystem</dt>
                                        <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                            {data.key_system.name}
                                        </dd>
                                    </div>
                                    <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                        <dt className="text-sm font-medium text-gray-500">Bemerkungen</dt>
                                        <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                            {data.remarks}
                                        </dd>
                                    </div>
                                </dl>
                            </div>
                        </div>
                    </section>

                </div>


            </div>

            <div className="mt-8 max-w-3xl mx-auto grid grid-cols-1 sm:px-6 lg:max-w-7xl lg:grid-flow-col-dense">

                <section aria-labelledby="notes-title">
                    <div className="bg-white shadow sm:rounded-lg sm:overflow-hidden">
                        <div className="divide-y divide-gray-200">
                            <div className="px-4 py-5 sm:px-6">
                                <h2 id="notes-title" className="text-lg font-medium text-gray-900">
                                    Ausgaben
                                </h2>
                            </div>
                            <div className="">
                                {data.key_issues && (
                                    <ListViewTable
                                        noTopSpace={true}
                                        data={data.key_issues}
                                        columns={columns}
                                        disableGlobalSearch={true}
                                        getRowLink={getRowLink}
                                    />
                                )}
                            </div>
                        </div>
                    </div>
                </section>
            </div>

        </DetailPage>
    )
}
