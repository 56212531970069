import React from 'react';
import {Route, Routes} from "react-router-dom";
import StudierzeitOverview from "./StudierzeitOverview";
import AttendanceCreateEdit from "../studierzeit/AttendanceCreateEdit";
import StudierzeitDetail from "./StudierzeitDetail";
import AttendanceList from "./AttendanceList";
import StudentList from "./StudentList";
import StudentDetail from "./StudentDetail";
import StudierzeitChangeCreateEdit from "./StudierzeitChangeCreateEdit";
import AttendanceDetailWithHomework from "./AttendanceDetailWithHomework";
import AttendanceDetail from "./AttendanceDetail";
import StudierzeitChangeList from "./StudierzeitChangeList";


function StudierzeitRouter() {

    return (
        <Routes>

            <Route path="list" element={<StudierzeitOverview/>}/>

            <Route path="attendance/:id/edit" element={<AttendanceCreateEdit/>}/>
            <Route path="attendance/new" element={<AttendanceCreateEdit/>}/>
            <Route path="attendance/" element={<AttendanceList/>}/>
            <Route path="attendance/:id" element={<AttendanceDetail/>}/>
            <Route path="attendance/:id/homework" element={<AttendanceDetailWithHomework/>}/>

            <Route path="detail" element={<StudierzeitDetail/>}/>

            <Route path="students/" element={<StudentList/>}/>
            {/*<Route path="students/:studentId" element={<StudentDetail />} />*/}
            <Route path="students/:studentId" element={<StudentDetail/>}/>

            <Route path="changes" element={<StudierzeitChangeList />}/>
            <Route path="changes/:id/edit" element={<StudierzeitChangeCreateEdit/>}/>
            <Route path="changes/new/:studentId" element={<StudierzeitChangeCreateEdit/>}/>

        </Routes>
    )
}


export default function Studierzeit() {
    return <StudierzeitRouter/>
}
