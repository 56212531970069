import {SimplePage} from "../../elements/Pages";
import React from "react";
import ListViewTable, {TableLink} from "../../elements/tables/ListViewTable";
import {useParams} from "react-router-dom";
import {getClassTeachers} from "../../../api/certificates";
import {useQuery} from "@tanstack/react-query";
import {assessmentPeriodApi} from "../../../api/assessments";

const columns = [
    {
        accessorFn: (row) => row,
        id: "staff",
        header: "Mitarbeiter",
        columnType: "staff",
        enableColumnFilter: false,
    },
    {
        accessorKey: "school_class",
        id: "school_class",
        sortingFn: "schoolClassSorting",
        header: "Klasse",
        enableColumnFilter: false,
    },
    // {
    //     accessorKey: "id",
    //     id: "pdf",
    //     header: "PDF",
    //     enableColumnFilter: false,
    //     disableRowLink: true,
    //     cell: ({row, value}) => (
    //         <>
    //             // TODO table download link ?
    //             <TableLink
    //                 label="PDF"
    //                 to={`/certificates/assessment-periods/assPId/class-teachers/`} // TODO Link
    //             />
    //         </>
    //     ),
    // }
];

export default function ClassTeachers() {
    const {assessmentPeriodId} = useParams()

    const {data: assessmentPeriod} = useQuery(
        ["assessment_periods", assessmentPeriodId],
        () => assessmentPeriodApi('get', assessmentPeriodId),
        {refetchInterval: 1000 * 60 * 60 * 24}
    );

    const {data: classTeachers} = useQuery(
        ["class_teachers", assessmentPeriodId],
        () => getClassTeachers(assessmentPeriodId),
        {refetchInterval: 1000 * 60 * 60 * 24}
    );

    function getRowLink(row) {
        return `/certificates/assessment-periods/${assessmentPeriodId}/class-teachers/${row.original.id}/`;
    }
    return (
        <SimplePage>
            <div className="px-4 sm:px-6 lg:px-8">
                <div className="sm:flex sm:items-center xl:col-span-3">
                    <div className="sm:flex-auto">
                        <h3 className="text-lg leading-6 font-medium text-gray-900">
                            Kopfnoten & Zeugnissätze nach Klassenlehrern {assessmentPeriod?.name}
                        </h3>
                    </div>
                    <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
                    </div>
                </div>
                <div className="mt-4 flex flex-col xl:col-span-2">
                    <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
                        <div className="inline-block py-2 align-middle md:px-6 lg:px-8">
                            {classTeachers &&
                                <ListViewTable data={classTeachers}
                                               getRowLink={getRowLink}
                                               columns={columns}/>}

                        </div>
                    </div>
                </div>
            </div>
        </SimplePage>
    );
}
