import React, {useEffect} from 'react';
import {classNames} from "../../utils/misc";


export default function SchoolYearFilter({schoolYears, schoolYearId, setSchoolYearId, minSchoolYearId}) {

    useEffect(() => {
        if (!schoolYearId && schoolYears) setSchoolYearId(schoolYears[0].id)
    }, [])

    if (!schoolYears) return <></>

    if (minSchoolYearId) {
        schoolYears = schoolYears.filter((year) => year.id >= minSchoolYearId)
    }

    return (

        <div>
            <div className="sm:hidden">
                <label htmlFor="tabs" className="sr-only">
                    Schuljahr auswählen
                </label>
                {/* Use an "onChange" listener to redirect the user to the selected tab URL. */}
                <select
                    id="tabs"
                    name="tabs"
                    className="block w-full rounded-md border-gray-300 focus:border-imsblue-500 focus:ring-imsblue-500"
                    defaultValue={schoolYears.find((year) => year.id).name}
                >
                    {schoolYears.map((year) => (
                        <option key={year.id}>{year.name}</option>
                    ))}
                </select>
            </div>
            <div className="hidden sm:block">
                <nav className="flex space-x-4" aria-label="Tabs">
                    {schoolYears.map((year) => (
                        <a
                            key={year.id}
                            onClick={() => setSchoolYearId(year.id)}
                            className={classNames(
                                year.id === schoolYearId ? 'bg-imsblue-100 text-imsblue-700' : 'text-gray-500 hover:text-gray-700',
                                'px-3 py-2 font-medium text-sm rounded-md cursor-pointer'
                            )}
                            aria-current={year.id === schoolYearId ? 'page' : undefined}
                        >
                            {year.name}
                        </a>
                    ))}
                </nav>
            </div>
        </div>
    )
}
