import {React, useState} from "react";
import {Link} from "react-router-dom";
import {useShopCart} from "../../../contexts/ShopCartContext";
import {createOrder} from "../../../api/shop";
import {formatCurrency} from "../../../utils/formatters";


export default function ProductCart() {

    const [remark, setRemark] = useState("");
    const [isConfirmed, setIsConfirmed] = useState(false)
    const [error, setError] = useState(null)

    const {
        removeAllProductsFromCart,
        cartList,
    } = useShopCart();

    async function confirmOrder(e) {
        e.preventDefault()

        const payload = {
            data: cartList,
            remark,
        }

        try {
            await createOrder(payload)
        } catch (e) {
            setError(e.data.detail)
        }

        removeAllProductsFromCart()
        setIsConfirmed(true)
    }

    if (isConfirmed) return (
        <ConfirmedOrderCart error={error}/>
    )

    if (!cartList.length) return (
        <CartEmpty />
    )

    return (
        <div className="bg-white">
            <div className="mx-auto max-w-4xl py-16 px-4 sm:py-24 sm:px-6 lg:px-8">
                <h1 className="text-3xl font-bold tracking-tight text-gray-900">Warenkorb</h1>
                <form className="mt-12">
                    <div>
                        <h2 className="sr-only">Items in your shopping cart</h2>
                        <ul role="list" className="divide-y divide-gray-200 border-t border-b border-gray-200">
                            {cartList.map(product => <CartItem product={product}/>)}
                        </ul>
                    </div>

                    <div className="">
                        <CartComment value={remark} setValue={setRemark}/>
                        <OrderSummary confirmOrder={confirmOrder}/>
                    </div>
                </form>
            </div>
        </div>
    )
}

function CartEmpty () {

    return (
        <div className="min-h-full bg-white sm:py-24 grid place-items-center">
            <label htmlFor="comment" className="block text-2xl font-medium text-gray-800 my-6">
                Es befinden sich keine Artikel im Warenkorb
            </label>
            <Link to="/shop" className="font-medium text-imsblue-600 hover:text-imsblue-500">
                Produkte ansehen
            </Link>
        </div>
    )
}

function ConfirmedOrderCart ({error}) {
        return (
            <div className="min-h-full bg-white sm:py-24 grid place-items-center">
                <label htmlFor="comment" className="block text-2xl font-medium text-gray-800 my-6">
                    {error ? error : "Wir haben Ihre Bestellung erhalten"}
                </label>
                <Link to="/shop" className="font-medium text-imsblue-600 hover:text-imsblue-500">
                    Zurück zu den Produkten
                </Link>
            </div>

        )
}

function CartComment ({value, setValue}) {
    return (
        <div>
            <div>
                <h2 className="mt-10 text-sm font-large text-gray-600">Anmerkung hinzufügen</h2>
            </div>
            <div className="mt-1">
                <textarea
                    rows={4}
                    value={value}
                    onChange={e => setValue(e.target.value)}
                    name="comment"
                    id="comment"
                    className="block w-full rounded-md border-gray-300"
                    defaultValue={''}/>
            </div>
        </div>
    )
}

function OrderSummary ({confirmOrder}) {

    const {
        totalPrice
    } = useShopCart();


    return (
        <div className="mt-10 sm:ml-32 sm:pl-6">
            <div className="rounded-lg bg-gray-50 px-4 py-6 sm:p-6 lg:p-8">
                <h2 className="sr-only">Bestellung</h2>
                <div className="flow-root">
                    <dl className="-my-4 divide-y divide-gray-200 text-sm">
                        {/*<div className="flex items-center justify-between py-4">*/}
                        {/*    <dt className="text-gray-600">Artikel Zwischensumme</dt>*/}
                        {/*    <dd className="font-medium text-gray-900">€ {`${totalPrice}.00`}</dd>*/}
                        {/*</div>*/}
                        {/*<div className="flex items-center justify-between py-4">*/}
                        {/*    <dt className="text-gray-600">Standardlieferung</dt>*/}
                        {/*    <dd className="font-medium text-gray-900">0.0</dd>*/}
                        {/*</div>*/}
                        {/*<div className="flex items-center justify-between py-4">*/}
                        {/*    <dt className="text-gray-600">Steuer</dt>*/}
                        {/*    <dd className="font-medium text-gray-900">0.0</dd>*/}
                        {/*</div>*/}
                        <div className="flex items-center justify-between py-4">
                            <dt className="text-base font-medium text-gray-900">Gesamtsumme</dt>
                            <dd className="text-base font-medium text-gray-900">{`${formatCurrency(totalPrice)}`}</dd>
                        </div>
                    </dl>
                </div>
            </div>
            <div className="mt-10">
                <button
                    onClick={e => confirmOrder(e)}
                    className="w-full rounded-md border border-transparent bg-imsblue-600 py-3 px-4 text-base font-medium text-white shadow-sm hover:bg-imsblue-700 focus:outline-none focus:ring-2 focus:ring-imsblue-500 focus:ring-offset-2 focus:ring-offset-gray-50"
                >
                    Bestellen
                </button>
            </div>
            <div className="mt-6 text-center text-sm text-gray-500">
                <p>
                    <Link to="/shop" className="font-medium text-imsblue-600 hover:text-imsblue-500">
                        Zurück zum Shop
                    </Link>
                </p>
            </div>
        </div>
    )
}

function CartItem ({product}) {

    const {
        removeProductFromCart,
    } = useShopCart();

    return (
        <li key={product.product.id} className="flex py-6 sm:py-10">
            <div className="flex-shrink-0">
                <img
                    src={product.product.images.thumbnail[0]}
                    className="h-24 w-24 rounded-lg object-cover object-center sm:h-32 sm:w-32"
                    alt="product"
                />
            </div>

            <div className="relative ml-4 flex flex-1 flex-col justify-between sm:ml-6">
                <div>
                    <div className="flex justify-between sm:grid sm:grid-cols-2">
                        <div className="pr-6">
                            <h3 className="text-sm font-medium text-gray-700 hover:text-gray-800">
                                <Link to={`/shop/product/${product.product.id}`}
                                      className="font-medium text-gray-700 hover:text-gray-800">
                                    {product.product.title}
                                </Link>
                            </h3>
                            {product.productSize.size &&
                                <p className="mt-1 text-sm text-gray-500">{product.productSize.size}</p>}
                            {product.custom_text &&
                                <p className="font-medium mt-3 text-sm text-gray-900">{product.custom_text}</p>}
                        </div>
                        <p className="text-right text-sm font-medium text-gray-900">{formatCurrency(product.product.price)}</p>
                    </div>

                    <div className="mt-4 flex items-center sm:absolute sm:top-0 sm:left-1/2 sm:mt-0 sm:block">
                        <button
                            type="button"
                            onClick={() => removeProductFromCart(product)}
                            className="ml-4 text-sm font-medium text-imsblue-600 hover:text-imsblue-500 sm:ml-0 sm:mt-3"
                        >
                            <span>Entfernen</span>
                        </button>
                    </div>
                </div>
            </div>
        </li>
    )
}