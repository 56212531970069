import {SimplePage} from "../../elements/Pages";
import React, {useEffect, useState} from "react";
import ListViewTable, {TableEditLink} from "../../elements/tables/ListViewTable";
import {useParams} from "react-router-dom";
import {baseHandleListChange} from "../../elements/forms/Form";
import {toast} from "react-toastify";
import {getCertificateRemarks, updateMultipleCertificateRemarks} from "../../../api/certificates";
import {TextArea} from "../../elements/forms/Inputs";
import {EditButton, PrimaryButton} from "../../elements/Buttons";


export default function CertificateRemarks() {
    const {assessmentPeriodId} = useParams()

    const schema = {}
    const [errors, setErrors] = useState([]) // validation errors

    const [editing, setEditing] = useState(false)
    const [data, setData] = useState()

    useEffect(() => {
        loadData()
    }, [])

    async function loadData() {
        const newData = await getCertificateRemarks(assessmentPeriodId)
        setData(newData)
    }

    // todo will have to fix this
    function setCertificateRemarks(newCrs) {
        // console.log(newCrs)
        setData({...data, students: newCrs})
    }

    const columnsViewOnly = [
        {
            accessorFn: (row) => `${row.student.last_name}, ${row.student.first_name} ${row.student.student_number}`,
            id: "student",
            header: "SchülerIn",
            enableColumnFilter: false,
        },
        {
            accessorKey: "student.school_class",
            id: "school_class",
            header: "Klasse",
            enableColumnFilter: true,
            classNames: "text-gray-500",
            headerClassNames: "narrow-filter"
        },
        {
            accessorKey: "class_teacher",
            id: "class_teacher",
            header: "Klassenlehrer",
            enableColumnFilter: true,
            classNames: "text-gray-500",
            headerClassNames: "medium-filter"
        },
        {
            accessorKey: "remarks",
            id: "remarks",
            header: "Zeugnissbemerkung",
            enableColumnFilter: false,
            classNames: "max-w-md"
        }
    ]

    if (data) {
        // add conduct grade definitions to the columns
        for (var i = 0; i < data.conduct_grade_definitions.length; i++) {
            columnsViewOnly.push({
                accessorKey: "final_conduct_grades_list." + data.conduct_grade_definitions[i].id,
                id: data.conduct_grade_definitions[i].id,
                header: data.conduct_grade_definitions[i].name,
                enableColumnFilter: false,
            })
        }
    }

    columnsViewOnly.push({
        accessorKey: "student.id",
        id: "link",
        cell: (info) => (
            <TableEditLink
                iconOnly={true}
                label="Bearbeiten"
                to={`/certificates/assessment-periods/${assessmentPeriodId}/certificate-remarks/students/${info.getValue()}/edit`}
            />
        ),
        header: "",
        disableRowLink: true,
        enableColumnFilter: false,
    })


    function handleChange(evt, rowId) {
        baseHandleListChange(rowId, evt, data.students, setCertificateRemarks, errors, setErrors, schema)
    }

    async function handleSave() {
        try {
            await updateMultipleCertificateRemarks(data.students)
            loadData()
            toast.success("Zeugnissätze gespeichert")
        } catch (e) {
            toast.error("Beim Speichern ist ein Fehler aufgetreten.")
        }
    }

    return (
        <SimplePage>
            <div className="px-4 sm:px-6 lg:px-8">
                <div className="sm:flex sm:items-center xl:col-span-3">
                    <div className="sm:flex-auto">
                        <h3 className="text-lg leading-6 font-medium text-gray-900">
                            Kopfnoten & Zeugnissätze {data?.assessment_period?.name}
                        </h3>
                    </div>
                    <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
                        <EditButton label="Alle Zeugnissätze bearbeiten" onClick={() => setEditing(!editing)} />
                    </div>
                </div>
                <div className="mt-4 flex flex-col xl:col-span-2">
                    <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
                        <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">

                            {data && editing &&
                                <>
                                    <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
                                        <table className="min-w-full divide-y divide-gray-300">
                                            <tbody>
                                            {data.students?.map((c, index) => (
                                                <tr key={index}
                                                    className={(index % 2 === 0 ? undefined : "bg-gray-50")}
                                                >
                                                    <td className="py-4 pl-4 pr-3 text-sm">
                                                        {c.student.full_name}
                                                    </td>
                                                    <td className="py-4 pl-4 pr-3 text-sm">

                                                        <TextArea
                                                            path="remarks"
                                                            label="Zeugnisbemerkungen"
                                                            className="hide-label"
                                                            onChange={handleChange}
                                                            errors={errors}
                                                            data={data.students}
                                                            rowId={c.id}
                                                            rows={2}
                                                        />
                                                    </td>
                                                </tr>
                                            ))}

                                            </tbody>
                                        </table>
                                    </div>

                                    <div className="flex justify-between col-span-2">
                                        <PrimaryButton
                                            classNameIn="my-4 mr-4"
                                            onClick={handleSave} label="Zeugnissätze speichern"/>
                                    </div>
                                </>
                            }

                            {!editing && data &&
                                <ListViewTable data={data.students}
                                               columns={columnsViewOnly}/>}

                        </div>
                    </div>
                </div>
            </div>
        </SimplePage>
    );
}
