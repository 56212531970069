import {SimplePage} from "../../elements/Pages";
import React, {useEffect} from 'react';

import {useQuery} from '@tanstack/react-query'
import {useNavigate} from "react-router-dom";
import {getMyStudentsInclAlumni} from "../../../api/school";
import {SecondaryButton} from "../../elements/Buttons";
import {useAuth} from "../../../contexts/AuthContext";
import {useUserInfo} from "../../../contexts/UserInfoContext";

export default function MyAssessmentsAndGrades() {

    const {currentUser} = useAuth()
    const {renderUserImage} = useUserInfo();
    const navigate = useNavigate()
    const {data, isSuccess} = useQuery(['my_students', currentUser.user_id],
        getMyStudentsInclAlumni, {refetchInterval: 1000 * 60 * 60 * 24})

    useEffect(() => {
        if (data && data.length === 1 && currentUser.student_id === data[0].id) {
            navigate(`/assessments/students/${data[0].id}`)
        }
    }, [data])

    return (
        <SimplePage>
            <div className="px-4 sm:px-6 lg:px-8">
                <div className="sm:flex sm:items-center">
                    <div className="sm:flex-auto">
                        <h3 className="text-lg leading-6 font-medium text-gray-900">Meine Noten & Beurteilungen</h3>
                    </div>
                </div>
                <div className="flex flex-col">
                    <ul role="list" className="divide-y divide-gray-200">
                        {isSuccess && data && data.map((student) => (
                            <li key={student.id} className="flex gap-4 py-4 items-center">
                                <div className="flex">
                                    {renderUserImage(student.user_id)}
                                    <div className="ml-3">
                                        <p className="text-sm font-medium text-gray-900">{student.full_name}</p>
                                        <p className="text-sm text-gray-500">{student.student_number}</p>
                                    </div>
                                </div>

                                <SecondaryButton link={`/assessments/students/${student.id}`} label="Beurteilungen"/>
                                {/*<SecondaryButton link={`/grades/students/${student.id}/notenblatt`} label="Notenblatt"/>*/}
                                {/*<SecondaryButton link={`/grades/students/${student.id}/special-grades`}*/}
                                {/*                 label="Sondernoten"/>*/}
                            </li>
                        ))}
                    </ul>
                </div>
            </div>
        </SimplePage>
    )
}
