import React from 'react';
import {Route, Routes} from "react-router-dom";
import AbsencesReport from "./AbsencesReport";
import PenaltyEntriesReport from "./PenaltyEntriesReport";
import ProjectsCancelledReport from "./ProjectsCancelledReport";
import ProjectAbsencesReport from "./ProjectAbsencesReport";
import ProjectsReport from "./ProjectsReport";
import CourseMaterialReport from "./CourseMaterialReport";
import StudierzeitAbsencesReport from "./StudierzeitAbsencesReport";
import GivenHomeworkByTeacherReport from "./GivenHomeworkByTeacherReport";
import CheckedHomeworkByStudentReport from "./CheckedHomeworkByStudentReport";
import MissingHomeworkAndMaterialsByStudentReport from "./MissingHomeworkAndMaterialsByStudentReport";
import MissingHomeworkAndMaterialsByStudentAndTeacherReport
    from "./MissingHomeworkAndMaterialsByStudentAndTeacherReport";
import MissingHomeworkAndMaterialsByTeacherReport from "./MissingHomeworkAndMaterialsByTeacherReport";
import CheckedHomeworkByTeacherReport from "./CheckedHomeworkByTeacherReport";
import StudentsWithTutoringRecommendation from "./StudentsWithTutoringRecommendation";
import ReportsIndex from "./ReportsIndex";
import LogbookIpadReport from "./LogbookIpadReport";
import GradesEnteredReport from "./GradesEnteredReport";

function ReportsRouter() {

    return (
        <Routes>
            <Route index element=<ReportsIndex/> />
            <Route path="/absences" element={<AbsencesReport/>}/>
            <Route path="/penalty-entries" element={<PenaltyEntriesReport/>}/>

            {/* Projects */}
            <Route path="/projects/cancelled-projects" element={<ProjectsCancelledReport/>}/>
            <Route path="/projects/project-absences" element={<ProjectAbsencesReport/>}/>
            <Route path="/projects/projects" element={<ProjectsReport/>}/>

            <Route path="/school/course-material" element={<CourseMaterialReport/>}/>

            <Route path="/studierzeit/studierzeit-absences" element={<StudierzeitAbsencesReport/>}/>

            <Route path="/grades/grades-entered" element={<GradesEnteredReport/>}/>

            <Route path="/assessments/tutoring-recommendations" element={<StudentsWithTutoringRecommendation/>}/>

            <Route path="/class-register/given-homework-by-teacher" element={<GivenHomeworkByTeacherReport/>}/>
            <Route path="/class-register/checked-homework-by-student" element={<CheckedHomeworkByStudentReport/>}/>
            <Route path="/class-register/checked-homework-by-teacher" element={<CheckedHomeworkByTeacherReport/>}/>

            <Route path="/class-register/missing-homework-or-materials-students"
                   element={<MissingHomeworkAndMaterialsByStudentReport/>}/>
            <Route path="/class-register/missing-homework-or-materials-students-teachers"
                   element={<MissingHomeworkAndMaterialsByStudentAndTeacherReport/>}/>
            <Route path="/class-register/missing-homework-or-materials-teachers"
                   element={<MissingHomeworkAndMaterialsByTeacherReport/>}/>


            <Route path="/logbook/ipad" element={<LogbookIpadReport/>}/>
        </Routes>
    )
}

export default function Reports() {
    return <ReportsRouter/>
}
