import React from "react";

import {useQuery} from "@tanstack/react-query";
import {DownloadButton, PrimaryButton, AsyncDownloadButton} from "../../elements/Buttons";
import {
    getParentConferenceDays,
    getRegistrationExport, getTeacherAppointmentListPDF,
    startAllAppointmentListGeneration, startAllParentAppointmentListGeneration
} from "../../../api/parent_conference";
import {useAuth} from "../../../contexts/AuthContext";
import checkPermission from "../../../utils/permissions";
import {Select} from "../../elements/forms/Inputs";
import ListViewTable, {TableDownloadLink, TableLink} from "../../elements/tables/ListViewTable";
import {SimplePage} from "../../elements/Pages";




export default function TeacherMyParentConferenceDays() {

    const {currentUser} = useAuth()

    const { isLoading, isError, isSuccess, data, error, refetch } = useQuery(
        ["parent_conference_day"],
        () => getParentConferenceDays(),
        {refetchInterval: 1000 * 60 * 60 * 24}
    )

    const columns = [
        {
            accessorKey: 'title',
            id: 'title',
            header: 'Elternsprechtag',
            hide: false,
            enableColumnFilter: false,
        },
        {
            accessorKey: 'get_times',
            id: 'get_times',
            header: 'Datum',
            columnType: 'string',
            enableColumnFilter: false
        },
        {
            accessorKey: 'id',
            id: 'link',
            cell: info => <TableLink label="Übersicht Mitarbeiter"
                                     to={`/parent-conference/${info.getValue()}/teachers`}/>,
            header: 'Übersicht Mitarbeiter',
            enableColumnFilter: false,
            disableRowLink: true
        },
        {
            accessorKey: 'id',
            id: 'link',
            cell: info => <TableLink label="Mein Terminplan"
                                     to={`/parent-conference/${info.getValue()}/teachers/${currentUser.staff_id}`}/>,
            header: 'Terminplan',
            enableColumnFilter: false,
            disableRowLink: true
        },
        {
            accessorKey: 'id',
            id: 'pdf',
            cell: info => <TableDownloadLink
                label="PDF"
                fileName={`Terminplan.pdf`}
                apiFunction={() => getTeacherAppointmentListPDF(info.getValue(), currentUser.staff_id)}/>,
            header: 'PDF',
            enableColumnFilter: false,
            disableRowLink: true
        }
    ]

    return (
        <>
            <SimplePage>
                <div className="px-4 sm:px-6 lg:px-8">
                    <div className="sm:flex sm:items-center">
                        <div className="sm:flex-auto">
                            <h3 className="text-lg leading-6 font-medium text-gray-900">
                                Elternsprechtage
                            </h3>
                        </div>
                        <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none gap-1 flex">
                        </div>
                    </div>
                    <div className="flex flex-col mt-3 mb-3">
                        <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
                            <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">

                                {isLoading && <>Lädt...</>}

                                {!isLoading && data && <ListViewTable data={data}
                                                        columns={columns}
                                                        disableGlobalSearch={false}
                                />}

                            </div>
                        </div>
                    </div>
                </div>
            </SimplePage>
        </>
    );
}
