import {React, useState} from "react";
import {SimplePage} from "../../elements/Pages";
import ListViewTable from "../../elements/tables/ListViewTable";
import {getOrderListExcel, getOrders} from "../../../api/shop";
import {useQuery} from "@tanstack/react-query";
import {DownloadButton} from "../../elements/Buttons";
import {getStudentListExcel} from "../../../api/courses";
import checkPermission from "../../../utils/permissions";
import {useAuth} from "../../../contexts/AuthContext";

const columns = [
    {
        accessorKey: "id",
        id: "id",
        header: "Nr.",
        enableColumnFilter: false,
    },
    {
        accessorKey: "created_by.full_name",
        id: "created_by",
        header: "Bestellt von",
        enableColumnFilter: false,
    },
    {
        accessorKey: "creation_date",
        columnType: "date_time",
        id: "creation_date",
        header: "Datum",
        enableColumnFilter: false,
    },
    {
        accessorKey: "remarks",
        id: "remarks",
        header: "Anmerkungen",
        enableColumnFilter: false,
    },
    {
        accessorKey: "total_price",
        columnType: "price",
        id: "total_price",
        header: "Summe",
        enableColumnFilter: false,
    },
]


export default function OrderList() {

    const { permissions } = useAuth()
    const [ rowSelection, setRowSelection] = useState([]);
    const {isLoading, isError, isSuccess, data, error, refetch} = useQuery(
        ["orders"],
        () => getOrders(),
        {refetchInterval: 1000 * 60 * 60}
    )
    function getRowLink(row) {
        return `/shop/orders/${row.id}`;
    }

    const canViewOrders = checkPermission(permissions, 'shop.view_order')

    if (!data) return <></>

    return (

        <SimplePage>
            <div className="px-4 sm:px-6 lg:px-8">
                <div className="sm:flex sm:items-center">
                    <div className="sm:flex-auto">
                        <h3 className="text-lg leading-6 font-medium text-gray-900">
                            Meine Bestellungen
                        </h3>
                    </div>
                    <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
                        {canViewOrders && <DownloadButton
                            apiFunction={() => getOrderListExcel()}
                            fileName={`Bestellungen.xls`}
                            label="Liste herunterladen"/>}
                    </div>
                </div>
                <div className="flex flex-col">
                    <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">

                        <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                            {isSuccess && data && (
                                <ListViewTable
                                    data={data}
                                    columns={columns}
                                    isLoading={isLoading}
                                    disableGlobalSearch={true}
                                    rowSelection={rowSelection}
                                    setRowSelection={setRowSelection}
                                    getRowLink={getRowLink}
                                />
                            )}
                        </div>
                    </div>

                </div>
            </div>
        </SimplePage>
    )
}