import React from 'react';
import {Route, Routes} from "react-router-dom";
import LogbookEntries from "./LogbookEntries";
import LogbookEntryDetail from "./LogbookEntryDetail";
import Students from "./Students";
import StudentDetail from "./StudentDetail";
import MyLogbook from "./MyLogbook";
import LogbookEntryCreateEdit from "./LogbookEntryCreateEdit";
import {RouteWithPermissionCheck} from "../../../routing/router";
import PeExemptions from "./PeExemptions";
import PeExemptionCreateEdit from "./PeExemptionCreateEdit";
import PeExemptionsMy from "./PeExemptionsMy";
import AbsenceNotices from "./AbsenceNotices";
import AbsenceNoticeCreateEdit from "./AbsenceNoticeCreateEdit";
import AbsenceNoticeStudentDetail from "./AbsenceNoticeStudentDetail";
import AbsenceNoticesMy from "./AbsenceNoticesMy";
import Penalties from "./Penalties";
import PenaltyCreateEdit from "./PenaltyCreateEdit";
import Watchlist from "./Watchlist";
import WatchlistCreateEdit from "./WatchlistCreateEdit";
import WatchlistEntryDetail from "./WatchlistEntryDetail";
import LogbookEntriesUnapproved from "./LogbookEntriesUnapproved";


function LogbookRouter() {

    return (
        <Routes>

            <Route path="/entries" element={<LogbookEntries/>}/>
            <Route path="/entries/unapproved" element={<LogbookEntriesUnapproved />}/>

            {/* Logbook entries */}
            <Route path="/entries/new" element={<LogbookEntryCreateEdit/>}/>
            <Route path="/entries/new/:studentId" element={<LogbookEntryCreateEdit/>}/>
            <Route path="/entries/:id/edit" element={<LogbookEntryCreateEdit/>}/>
            <Route path="/entries/:id" element={<LogbookEntryDetail/>}/>
            <Route path="/students" element={<Students/>}/>
            <Route path="/students/:id" element={<StudentDetail/>}/>
            <Route path="/students/:id/:categoryId" element={<StudentDetail/>}/>
            <Route path="/my" element={<MyLogbook/>}/>

            {/* Absence notices */}
            <Route path="/absence-notices/new" element={<AbsenceNoticeCreateEdit/>}/>
            <Route path="/absence-notices/new/:studentId" element={<AbsenceNoticeCreateEdit/>}/>
            <Route path="/absence-notices/:id/edit" element={<AbsenceNoticeCreateEdit/>}/>

            <Route path="/absence-notices/my" element={<AbsenceNoticesMy/>}/>
            <Route path="/absence-notices/students/:studentId" element={<AbsenceNoticeStudentDetail/>}/>
            <Route path="/absence-notices" element={<AbsenceNotices/>}/>

            <Route path="/penalties" element={<Penalties/>}/>
            <Route path="/penalties/new" element={<PenaltyCreateEdit/>}/>
            <Route path="/penalties/new/:studentId" element={<PenaltyCreateEdit/>}/>
            <Route path="/penalties/:id/edit" element={<PenaltyCreateEdit/>}/>
            <Route path="/penalties/:penaltyName" element={<Penalties/>}/>
            x
            <Route path="/watchlist" element={<Watchlist/>}/>
            <Route path="/watchlist/new" element={<WatchlistCreateEdit/>}/>
            <Route path="/watchlist/:id" element={<WatchlistEntryDetail/>}/>
            <Route path="/watchlist/:id/edit" element={<WatchlistCreateEdit/>}/>

            {/*<Route path="/absence" element={<Students />} />*/}

            {/* Pe Exemptions */}
            <Route element={<RouteWithPermissionCheck requiredPermission={'student_logbook.view_peexemption'}/>}>
                <Route path="/pe-exemptions/:id/edit" element={<PeExemptionCreateEdit/>}/>
                <Route path="/pe-exemptions/new/:studentId" element={<PeExemptionCreateEdit/>}/>
                <Route path="/pe-exemptions/new" element={<PeExemptionCreateEdit/>}/>
                <Route path="/pe-exemptions/:studentId" element={<PeExemptions/>}/>
                <Route path="/pe-exemptions" element={<PeExemptions/>}/>
            </Route>
            <Route path="/pe-exemptions/my" element={<PeExemptionsMy/>}/>

        </Routes>
    )
}


export default function Logbook() {
    return <LogbookRouter/>
}
