import {Link, useParams} from "react-router-dom";
import {React, useEffect, useState} from "react";
import {EditButton, PrimaryButton} from "../../elements/Buttons";
import checkPermission from "../../../utils/permissions";
import {useAuth} from "../../../contexts/AuthContext";
import {DetailPage} from "../../elements/Pages";
import {formatDate} from "../../../utils/formatters";
import {toast} from "react-toastify";
import {
    markRepairOrderCompleted,
    markRepairOrderIncomplete,
    markRepairOrderInProgress,
    repairOrderApi
} from "../../../api/maintenance";
import {TextArea} from "../../elements/forms/Inputs";
import {baseHandleChange} from "../../elements/forms/Form";


export default function RepairOrderDetail() {

    const {id} = useParams();
    const {permissions} = useAuth();
    const [data, setData] = useState({});
    const [errors, setErrors] = useState({})
    const schema = {}
    const [completeFormOpen, setCompleteFormOpen] = useState(false);

    useEffect(() => {
        loadData();
    }, [id]);

    async function loadData() {
        if (!id) return;
        const newData = await repairOrderApi("get", id);
        setData(newData);
    }

    async function handleMarkCompleted() {
        try {
            const newData = await markRepairOrderCompleted(id, data);
            setData(newData);
            toast.success("Reparaturauftrag erledigt.")
            setCompleteFormOpen(false)
        } catch (e) {
            toast.error("Beim Speichern ist ein Fehler aufgetreten.")
        }
    }

    async function handleMarkIncomplete() {
        try {
            const newData = await markRepairOrderIncomplete(id);
            setData(newData);
            toast.success("Reparaturauftrag wiedereröffnet.")
        } catch (e) {
            toast.error("Beim Speichern ist ein Fehler aufgetreten.")
        }
    }

    async function handleMarkInProgress() {
        try {
            const newData = await markRepairOrderInProgress(id);
            setData(newData);
            toast.success("Bearbeitung gestartet.")
        } catch (e) {
            toast.error("Beim Speichern ist ein Fehler aufgetreten.")
        }
    }

    function handleChange(evt) {
        baseHandleChange(evt, data, setData, errors, setErrors, schema)
    }

    if (!data) return <></>;

    const canProcess = checkPermission(permissions, "maintenance.change_repairorder")
    const canEdit = data.can_edit

    return (
        <DetailPage>

            {/* Page header */}
            <div
                className="max-w-3xl mx-auto px-4 sm:px-6 md:flex md:items-center md:justify-between md:space-x-5 lg:max-w-7xl lg:px-8">
                <div className="flex items-center space-x-5">
                    <div>
                        <h1 className="text-2xl font-bold text-gray-900">{data.title}</h1>
                    </div>
                </div>
                <div
                    className="mt-6 flex flex-col-reverse justify-stretch space-y-4 space-y-reverse sm:flex-row-reverse sm:justify-end sm:space-x-reverse sm:space-y-0 sm:space-x-3 md:mt-0 md:flex-row md:space-x-3">
                    {canEdit && <EditButton
                        link={`/maintenance/repair-orders/${id}/edit`}
                        label="Bearbeiten"
                    />}
                </div>
            </div>

            <div
                className="mt-8 max-w-3xl mx-auto grid grid-cols-1 gap-6 sm:px-6 lg:max-w-7xl lg:grid-flow-col-dense lg:grid-cols-3">
                <div className="space-y-6 lg:col-start-1 lg:col-span-2">

                    <section aria-labelledby="applicant-information-title">
                        <div className="bg-white shadow overflow-hidden sm:rounded-lg">

                            <div className="border-t border-gray-200 px-4 py-5 sm:p-0">
                                <dl className="sm:divide-y sm:divide-gray-200">
                                    <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                        <dt className="text-sm font-medium text-gray-500">Kategorie</dt>
                                        <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{data.category?.name}
                                        </dd>
                                    </div>
                                    <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                        <dt className="text-sm font-medium text-gray-500">Ort</dt>
                                        <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{data.location}
                                        </dd>
                                    </div>
                                    <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                        <dt className="text-sm font-medium text-gray-500">Auftrag</dt>
                                        <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2 whitespace-pre-wrap">
                                            {data.order}
                                        </dd>
                                    </div>
                                    <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                        <dt className="text-sm font-medium text-gray-500">Erstellt von</dt>
                                        <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                            {data.created_by_full_name}
                                            {/*<Link className="font-imsblue-600" to={``}>*/}
                                            {/*    {data.created_by_full_name}</Link>*/}
                                        </dd>
                                    </div>
                                    <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                        <dt className="text-sm font-medium text-gray-500">Erstellt am</dt>
                                        <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{formatDate(data.creation_date)}
                                        </dd>
                                    </div>
                                    <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                        <dt className="text-sm font-medium text-gray-500">Zugewiesen an</dt>
                                        <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                            {data.assigned_to?.map(a => a.full_name).join(', ')}
                                        </dd>
                                    </div>
                                    <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                        <dt className="text-sm font-medium text-gray-500">Status</dt>
                                        <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                            {data.completed_date
                                                ? `Erledigt am ${formatDate(data.completed_date)}`
                                                : data.in_progress_since ? `In Bearbeitung seit ${formatDate(data.in_progress_since)}` : 'Offen'}
                                        </dd>
                                    </div>
                                    <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                        <dt className="text-sm font-medium text-gray-500">Bemerkungen</dt>
                                        <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2 whitespace-pre-wrap">
                                            {data.completed_remarks}
                                        </dd>
                                    </div>

                                </dl>
                            </div>
                        </div>
                    </section>
                    <div className="space-y-6 lg:col-start-1 lg:col-span-3"></div>
                </div>

                {canProcess && <section aria-labelledby="timeline-title" className="lg:col-span-1">
                    <section aria-labelledby="applicant-information-title">
                        <div className="bg-white shadow overflow-hidden sm:rounded-lg">

                            <div className="px-4 py-5 sm:px-6">
                                <h3 className="text-lg leading-6 font-medium text-gray-900">
                                    Status
                                </h3>
                            </div>

                            <div className="space-y-6 lg:col-span-1 px-4 py-5 sm:px-6">

                                {completeFormOpen &&

                                    <div className="space-y-2">
                                        <TextArea
                                            path="completed_remarks"
                                            type="text"
                                            label="Anmerkungen"
                                            className="sm:col-span-6"
                                            onChange={handleChange}
                                            errors={errors}
                                            data={data}
                                            rows={5}
                                        />

                                        <div className="flex flex-col gap-2 items-center">
                                            <PrimaryButton onClick={() => handleMarkCompleted()}
                                                           label="Speichern & erledigen"/>

                                            <a className="text-imsblue-600 font-xs cursor-pointer"
                                               onClick={() => setCompleteFormOpen(false)}>Abbrechen</a>
                                        </div>
                                    </div>}

                                {!completeFormOpen && <div>
                                    {canProcess && <div className="flex gap-2 items-center">
                                        {!data.in_progress_since && !data.completed_date &&
                                            <PrimaryButton onClick={() => handleMarkInProgress()}
                                                           label="In Bearbeitung"/>}
                                        {!data.completed_date &&
                                            <PrimaryButton onClick={() => setCompleteFormOpen(true)} label="Erledigt"/>}

                                        {data.completed_date &&
                                            <PrimaryButton onClick={() => handleMarkIncomplete()}
                                                           label="Wiedereröffnen"/>}


                                    </div>}

                                </div>}
                            </div>

                        </div>

                    </section>
                </section>}

            </div>

        </DetailPage>
    );
}
