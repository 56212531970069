import {SimplePage} from "../../elements/Pages";
import React from "react";

import {useQuery} from "@tanstack/react-query";
import ListViewTable from "../../elements/tables/ListViewTable";
import {getPenaltyEntryReport, getPenaltyEntryReportExcel} from "../../../api/reports";
import {DownloadButton} from "../../elements/Buttons";
import env from "react-dotenv";


var columns = [
    {
        accessorFn: (row) =>
            `${row.last_name}, ${row.first_name} ${row.student_number}`,
        id: "student_search",
        header: "SchülerIn",
        hide: true,
        enableColumnFilter: false,
    },
    {
        accessorFn: (row) => row,
        id: "student",
        header: "SchülerIn",
        columnType: "student_no_image",
        enableColumnFilter: false,
        sortingFn: "lastNameSorting",
    },
    {
        accessorKey: "school_class",
        id: "school_class",
        header: "Klasse",
        sortingFn: "schoolClassSorting",
        headerClassNames: "narrow-filter",
    },
    {
        accessorKey: "house",
        id: "house",
        header: "Mentorat",
        enableColumnFilter: true,
        headerClassNames: "narrow-filter",
    },
    {
        accessorKey: "main_mentor",
        id: "main_mentor",
        header: "Stammmentor",
        enableColumnFilter: true,
    },
    {
        accessorKey: "number_of_penalties",
        id: "number_of_penalties",
        columnType: "number",
        header: "Strafeinträge",
        enableColumnFilter: false,
        sortingFn: "basic"
    },
    {
        accessorKey: "num_penalties_compensated",
        id: "num_penalties_compensated",
        header: "abgegolten",
        columnType: "number",
        enableColumnFilter: false,
        sortingFn: "basic"
    },
    {
        accessorKey: "num_penalties_time_barred",
        id: "num_penalties_time_barred",
        header: "verjährt",
        columnType: "number",
        enableColumnFilter: false,
        sortingFn: "basic"
    },
    {
        accessorKey: "num_penalties_open",
        id: "num_penalties_open",
        columnType: "number",
        header: "offen",
        enableColumnFilter: false,
        sortingFn: "basic"
    }
]

if (env.SCHOOL === 'KPI') {
    columns.push({
        accessorKey: "count_bonus_entries",
        id: "count_bonus_entries",
        header: "Bes. schul. Würdigung",
        enableColumnFilter: false
    })
    columns.push({
        accessorKey: "total",
        id: "total",
        header: "Saldo",
        enableColumnFilter: false
    })
}

export default function PenaltyEntriesReport() {

    const {isLoading, isError, isSuccess, data, error, refetch} = useQuery(
        ["penalty_entries_report"],
        getPenaltyEntryReport,
        {refetchInterval: 1000 * 60 * 60}
    )

    function getRowLink(row) {
        return `/logbook/students/${row.original.id}`;
    }

    return (
        <SimplePage>
            <div className="px-4 sm:px-6 lg:px-8">
                <div className="sm:flex sm:items-center">
                    <div className="sm:flex-auto">
                        <h3 className="text-lg leading-6 font-medium text-gray-900">
                            Strafeinträge
                        </h3>
                    </div>
                    <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
                        <DownloadButton
                            apiFunction={() => getPenaltyEntryReportExcel()}
                            fileName="Strafeinträge.xls"
                            label="Excel herunterladen"/>

                    </div>
                </div>
                <div className="flex flex-col">
                    <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
                        <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                            {isSuccess && data && (
                                <ListViewTable
                                    data={data}
                                    columns={columns}
                                    isLoading={isLoading}
                                    disableGlobalSearch={false}
                                    getRowLink={getRowLink}
                                />
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </SimplePage>
    );
}
