import axios from "axios";
import {toast} from "react-toastify";
import config from "../constants";
import httpService from "./httpService";

const refreshUrl = '/accounts/token/refresh/';
let retries = 0;
const maxRetries = 3;

const axiosInstance = axios.create({
    baseURL: config.API_URL,
    timeout: 50000,
    responseType: 'blob',
    headers: {
        'Authorization': "Bearer " + localStorage.getItem('access_token'),
        'Content-Type': 'application/json',
    }
});

axiosInstance.interceptors.response.use(success => {
    retries = 0 // reset retries on success
    return Promise.resolve(success)
}, async error => {

    if (error.message === 'Network Error') {
        // this occurs when the API isn't online/reachable - how to handle?
        toast.error("Es ist ein Fehler aufgetreten.");
        return Promise.reject(error);
    }
    // if unauthorized, refresh the token
    if (error.response.status === 401) {
        retries++;
        if (retries < maxRetries) {
            const originalRequest = error.config;
            if (originalRequest['url'] !== refreshUrl) {
                await httpService.refreshToken(error) // refresh token with the regular axios instance
                // then redo the original request
                if (originalRequest['url']) {
                    originalRequest.headers['Authorization'] = "Bearer " + localStorage.getItem('access_token');
                    return axiosInstance(originalRequest);
                }
            } else {
                // force logout if still not authenticated
                localStorage.removeItem('access_token');
                localStorage.removeItem('refresh_token');
            }
        }
    } else {
        throw error.response
    }

    const expectedError =
        error.response &&
        error.response.status >= 400 &&
        error.response.status < 500;

    if (!expectedError) {
        // logger.log(error);
        toast.error("Es ist ein Fehler aufgetreten.");
    }

    return Promise.reject(error);
});

const httpServiceDownloads = {
    get: axiosInstance.get,
    post: axiosInstance.post
}

export default httpServiceDownloads;
