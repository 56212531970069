import http from "../services/httpService";

export async function getNotificationDefinitions() {
    const resp = await http.get(`/notification_unsubscribes/`);
    return resp.data;
}

export async function saveNotificationUnsubscribes(data) {
    const resp = await http.post(`/notification_unsubscribes/`, data);
    return resp.data;
}