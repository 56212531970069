import React, {useContext} from "react"
import {getCurrentDate} from "../api/calendar";
import {useQuery} from "@tanstack/react-query";

const AdjustedDateContext = React.createContext()

export function useAdjustedDate() {
    return useContext(AdjustedDateContext)
}

export function AdjustedDateProvider({children}) {

    const {data: currentDateData} = useQuery(
        ["current_date"],
        () => getCurrentDate(),
        {refetchInterval: 1000 * 60 * 60}
    );

    const currentDate = currentDateData?.override_enabled ? new Date(currentDateData.now) : new Date()

    const value = {
        currentDateData,
        currentDate
    }

    return (
        <AdjustedDateContext.Provider value={value}>
            {children}
        </AdjustedDateContext.Provider>
    )
}
