import {SimplePage} from "../../elements/Pages";
import React, {useEffect, useState} from "react";

import {useQuery} from "@tanstack/react-query";
import {useAuth} from "../../../contexts/AuthContext";
import ListViewTable, {TableLink,} from "../../elements/tables/ListViewTable";
import {getAssessmentPeriods, getAssessmentPermissions} from "../../../api/assessments";
import {useSchoolYears} from "../../../contexts/SchoolYearContext";
import env from "react-dotenv";
import SchoolYearFilter from "../../elements/SchoolYearFilter";


export default function AssessmentPeriods() {
    const {currentUser} = useAuth();

    const {schoolYears, currentSchoolYear} = useSchoolYears();
    const [schoolYearId, setSchoolYearId] = useState();

    const {isLoading, isSuccess, data} = useQuery(
        ["assessment_periods", schoolYearId],
        () => getAssessmentPeriods(schoolYearId),
        {
            enabled: !!schoolYearId, refetchInterval: 1000 * 60 * 60 * 24
        }
    )

    useEffect(() => {
        if (currentSchoolYear) setSchoolYearId(currentSchoolYear["id"]);
    }, [currentSchoolYear]);

    const {data: assessmentsPermissions} = useQuery(['assessment_permissions', currentUser.id], getAssessmentPermissions)

    const isManagement = assessmentsPermissions?.is_management
    const isTeacher = assessmentsPermissions?.is_teacher
    const isClassTeacher = assessmentsPermissions?.is_class_teacher

    const columns = [
        {
            accessorKey: "name",
            id: "name",
            header: "Bezeichnung",
            enableColumnFilter: false,
        },
        {
            accessorKey: "grades_and_school_classes",
            id: "grades_and_school_classes",
            header: "Klassen",
            enableColumnFilter: false,
        },
        ...env.SCHOOL === 'KPI' ? [
            {
                accessorKey: "school_types",
                id: "school_types",
                header: "Schulart",
                enableColumnFilter: false,
            },
        ] : [],
        {
            accessorKey: "entry_start_date",
            id: "entry_start_date",
            header: "Beginn Eingabe",
            columnType: "date",
            enableColumnFilter: false,
        },
        {
            accessorKey: "entry_end_date",
            id: "entry_end_date",
            header: "Ende Eingabe",
            columnType: "date",
            enableColumnFilter: false,
        },
        {
            accessorKey: "marks_end_date",
            id: "marks_end_date",
            header: "Noten bis",
            columnType: "date",
            enableColumnFilter: false,
        },
        {
            accessorKey: "viewing_start_date",
            id: "viewing_start_date",
            header: "Ansicht ab",
            columnType: "date",
            enableColumnFilter: false,
        },
        {
            accessorKey: "id",
            id: "link",
            cell: (info) => (
                <div className="flex gap-2 justify-between">

                    {isManagement && <>
                        <TableLink
                            label="Kurse"
                            to={`/assessments/assessment-periods/${info.getValue()}/courses`}
                        />
                        <TableLink
                            label="Schüler"
                            to={`/assessments/assessment-periods/${info.getValue()}/students`}
                        />
                        <TableLink
                            label="Lehrer"
                            to={`/assessments/assessment-periods/${info.getValue()}/teachers`}
                        />
                        <TableLink
                            label="Offene"
                            to={`/assessments/assessment-periods/${info.getValue()}/open`}
                        />
                        <TableLink
                            label="Fehlende"
                            to={`/assessments/assessment-periods/${info.getValue()}/missing`}
                        /></>}

                    {isTeacher && <TableLink
                        label="Meine Kurse"
                        to={`/assessments/assessment-periods/${info.getValue()}/courses/my`}
                    />}

                    {isTeacher && <TableLink
                        label="Alle Beurteilungen"
                        to={`/assessments/assessment-periods/${info.getValue()}/teachers/${currentUser.staff_id}`}
                    />}
                </div>

            ),
            header: "Beurteilungen",
            enableColumnFilter: false,
            disableRowLink: true
        },
        {
            accessorKey: "id",
            id: "certificate_entry_links",
            cell: ({row, value}) => (
                <>
                    {row.original.is_final_assessment ?
                        <div className="flex gap-2 justify-between">

                            {isManagement && row.original.final_conduct_grades_and_remarks && <TableLink
                                label="Eingeben"
                                to={`/certificates/assessment-periods/${row.original.id}/certificate-remarks`}
                            />}

                            {isManagement && <TableLink
                                label="Klassenlehrer"
                                to={`/certificates/assessment-periods/${row.original.id}/class-teachers`}
                            />}

                            {isClassTeacher && row.original.final_conduct_grades_and_remarks && <TableLink
                                label="Meine Klasse"
                                to={`/certificates/assessment-periods/${row.original.id}/class-teachers/${currentUser.staff_id}`}
                            />}

                        </div> : <></>
                    }
                </>
            ),
            header: "Kopfnoten/Zeugnisse",
            enableColumnFilter: false,
            disableRowLink: true
        },
        {
            accessorKey: "id",
            id: "certificate_links",
            cell: ({row, value}) => (
                <>
                    {row.original.is_final_assessment ?
                        <div className="flex gap-2 justify-between">

                            {isManagement && <TableLink
                                label="Klassen"
                                to={`/certificates/assessment-periods/${row.original.id}/certificates/by-school-class`}
                            />}

                            {isManagement && <TableLink
                                label="Zeugnisse"
                                to={`/certificates/assessment-periods/${row.original.id}/certificates`}
                            />}

                        </div> : <></>
                    }
                </>
            ),
            header: "Zeugnisse",
            enableColumnFilter: false,
            disableRowLink: true
        }
    ]

    function getTrClassNames(row, index) {
        return row.original.is_active ? 'bg-green-50' : (index % 2 === 0 ? undefined : "bg-gray-50 ")
    }

    return (
        <SimplePage>
            <div className="px-4 sm:px-6 lg:px-8">
                <div className="sm:flex sm:items-center">
                    <div className="sm:flex-auto">
                        <h3 className="text-lg leading-6 font-medium text-gray-900">
                            Beurteilungszeiträume
                        </h3>
                    </div>
                    <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
                    </div>
                </div>
                <div className="flex flex-col">
                    <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">

                        <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                            <SchoolYearFilter
                                schoolYears={schoolYears}
                                schoolYearId={schoolYearId}
                                setSchoolYearId={setSchoolYearId}
                            />
                        </div>

                        <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                            {isSuccess && data && (
                                <ListViewTable
                                    data={data}
                                    columns={columns}
                                    isLoading={isLoading}
                                    disableGlobalSearch={false}
                                    getRowLink={null}
                                    getTrClassNames={getTrClassNames}
                                />
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </SimplePage>
    );
}
