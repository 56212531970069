import {FullWidthPage} from "../../elements/Pages";
import {React, useEffect, useState} from "react";
import env from "react-dotenv";
import {Link, useNavigate, useParams} from 'react-router-dom'
import {useQuery, useQueryClient} from '@tanstack/react-query'
import {Checkbox, Input, Select} from "../../elements/forms/Inputs"
import {FormSectionContent} from "../../elements/forms/FormLayout"
import Form, {baseHandleListChange} from "../../elements/forms/Form"
import {toast} from "react-toastify";
import {
    getArrivalOptions,
    getDepartureList,
    getDepartureOptions,
    getDepartureWeekend,
    updateMultipleDepartureListItems
} from "../../../api/boarding_school";
import {formatDate} from "../../../utils/formatters";


export default function DepartureListEdit() {

    const {weekendId} = useParams()
    const queryClient = useQueryClient()

    const {data: weekend} = useQuery(
        ["departure_weekend", weekendId],
        () => getDepartureWeekend(weekendId),
        {}
    )

    const {data: departureOptions} = useQuery(['departure_options'], getDepartureOptions, {refetchInterval: 1000 * 60 * 60 * 24})
    const {data: arrivalOptions} = useQuery(['arrival_options'], getArrivalOptions, {refetchInterval: 1000 * 60 * 60 * 24})
    const [errors, setErrors] = useState({})
    const [listErrors, setListErrors] = useState([])
    const [data, setData] = useState()

    let navigate = useNavigate()

    useEffect(() => {
        loadData()
    }, [weekendId])

    async function loadData() {
        const newData = await getDepartureList(weekendId)
        setData(newData)
    }

    const schema = {}

    async function onSave() {
        await updateMultipleDepartureListItems(data)
        queryClient.invalidateQueries(['departure_list_items', `${weekendId}`] )
        loadData()
        toast.success("Heimfahrtsliste aktualisiert.")
        navigate(`/boarding-school/departure-lists/${weekendId}`)
    }

    function handleListChange(evt, rowId) {
        baseHandleListChange(rowId, evt, data, setData, listErrors, setListErrors, {}, false)
    }

    if (!data || !weekend) return <></>

    const title = "Heimfahrtliste erfassen"
    const successMessage = "Heimfahrtliste aktualisiert"

    return (

        <FullWidthPage title={title}>

            <div
                className="max-w-full mx-auto md:flex md:items-center md:justify-between">
                <div className="flex items-center space-x-5">
                    <div className="flex-shrink-0">
                        <div className="relative">
                            Heimfahrliste erfassen - Abreise {formatDate(weekend.departure_date)},
                            Anreise {formatDate(weekend.arrival_date)}
                        </div>
                    </div>
                </div>
            </div>

            <div
                className="max-w-full mx-auto grid grid-cols-1 gap-6 lg:grid-flow-col-dense lg:grid-cols-3">

                <div className="space-y-6 lg:col-span-3">

                    <section aria-labelledby="notes-title">

                        <Form id="createDepartureList" onSave={onSave}
                              hasTopSubmitButton={true}
                              data={data} setData={setData}
                              className=""
                              errors={errors} setErrors={setErrors}
                              successMessage={successMessage}
                              schema={schema}>

                            <FormSectionContent>

                                <div className="col-span-6 divide-y divide-gray-200">


                                    <div className="">

                                        {data &&
                                            <div
                                                className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
                                                <table className="min-w-full divide-y divide-gray-300">
                                                    <tbody>
                                                    {data.map((l, index) => (
                                                        <tr key={index}
                                                            className={(index % 2 === 0 ? undefined : "bg-gray-50")}
                                                        >
                                                            <td className="py-4 pl-4 pr-3 text-sm">
                                                                {l.student.full_name}
                                                            </td>

                                                            <td className="py-4 pl-4 pr-3 text-sm">
                                                                <Checkbox
                                                                    path="stays"
                                                                    label="Bleibt"
                                                                    className="sm:col-span-6 z-50 hide-label"
                                                                    onChange={handleListChange}
                                                                    data={data}
                                                                    rowId={l.id}
                                                                    errors={listErrors}
                                                                    useStudentId={false}
                                                                />

                                                            </td>

                                                            {env.SCHOOL === 'KPI' &&
                                                                <td className="py-4 pl-4 pr-3 text-sm">
                                                                    <Checkbox
                                                                        path="penalty"
                                                                        label="Strafe/Silentium"
                                                                        className="sm:col-span-6 z-50 hide-label"
                                                                        onChange={handleListChange}
                                                                        data={data}
                                                                        rowId={l.id}
                                                                        errors={listErrors}
                                                                        useStudentId={false}
                                                                    />
                                                                </td>}

                                                            {env.SCHOOL === 'ST' &&

                                                                <>
                                                                    <td className="py-4 pl-4 pr-3 text-sm">{l.travel_subscription ? `Abo ${l.travel_subscription}` : ''}</td>

                                                                    <td className="py-4 pl-4 pr-3 text-sm">
                                                                        <Select
                                                                            path="option_departure_id"
                                                                            label="Abreise"
                                                                            className="sm:col-span-6 z-50"
                                                                            onChange={handleListChange}
                                                                            options={departureOptions}
                                                                            labelAttr="name"
                                                                            valueAttr="id"
                                                                            valueOnly={true}
                                                                            data={data}
                                                                            rowId={l.id}
                                                                            errors={listErrors}
                                                                            disabled={l.stays}
                                                                            useStudentId={false}
                                                                        />
                                                                    </td>

                                                                    <td className="py-4 pl-4 pr-3 text-sm">
                                                                        <Select
                                                                            path="option_arrival_id"
                                                                            label="Anreise"
                                                                            className="sm:col-span-6 z-50"
                                                                            onChange={handleListChange}
                                                                            options={arrivalOptions}
                                                                            labelAttr="name"
                                                                            valueAttr="id"
                                                                            valueOnly={true}
                                                                            data={data}
                                                                            rowId={l.id}
                                                                            disabled={l.stays}
                                                                        />
                                                                    </td>

                                                                    <td className="py-4 pl-4 pr-3 text-sm">
                                                                        <Input
                                                                            path="departure_time"
                                                                            type="text"
                                                                            label="Fr Bhf"
                                                                            onChange={handleListChange}
                                                                            errors={listErrors}
                                                                            data={data}
                                                                            rowId={l.id}
                                                                            useStudentId={false}
                                                                            disabled={l.stays}
                                                                        />
                                                                    </td>

                                                                    <td className="py-4 pl-4 pr-3 text-sm">
                                                                        <Input
                                                                            path="arrival_time"
                                                                            type="text"
                                                                            label="So Taxi"
                                                                            onChange={handleListChange}
                                                                            errors={listErrors}
                                                                            data={data}
                                                                            rowId={l.id}
                                                                            useStudentId={false}
                                                                            disabled={l.stays}
                                                                        />
                                                                    </td>

                                                                    <td className="py-4 pl-4 pr-3 text-sm">
                                                                        <Input
                                                                            path="remarks"
                                                                            type="text"
                                                                            label="Bemerkungen"
                                                                            onChange={handleListChange}
                                                                            errors={listErrors}
                                                                            data={data}
                                                                            rowId={l.id}
                                                                            useStudentId={false}
                                                                        />
                                                                    </td>
                                                                </>}

                                                        </tr>
                                                    ))}

                                                    </tbody>
                                                </table>
                                            </div>}

                                    </div>

                                </div>

                            </FormSectionContent>

                        </Form>

                        <Link to={`/boarding-school/departure-lists`}
                              className="float-right mt-2 font-medium text-imsblue-600">Zurück zur
                            Liste</Link>
                    </section>

                </div>


            </div>


        </FullWidthPage>
    )
}
