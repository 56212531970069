import {SimplePage} from "../../elements/Pages";
import React from 'react';
import {ModuleWithTabs} from "../../layout/ModuleWithTabs";
import {Route, Routes} from "react-router-dom";
import AlumniStaffList from "./AlumniStaffList";
import AlumniStaffDetail from "./AlumniStaffDetail";
import MyProfile from "./MyProfile";
import AlumniStaffEdit from "./AlumniStaffEdit";
import {useAuth} from "../../../contexts/AuthContext";


function AlumniStaffRouter() {

    return (
        <Routes>
            <Route path="/" element={<AlumniStaffList/>}/>
            <Route path="/:id" element={<AlumniStaffDetail/>}/>
            <Route path="/:id/edit" element={<AlumniStaffEdit/>}/>
            <Route path="/my" element={<MyProfile/>}/>
        </Routes>
    )
}


export default function AlumniStaff() {
    return <AlumniStaffRouter/>
}
