import {genericApi} from "./generic";
import http from "../services/httpService";
import httpServiceDownloads from "../services/httpServiceDownloads";

export async function getStudierzeitTypes() {
    const resp = await http.get(`/studierzeit_types/`)
    return resp.data
}

export async function getStudierzeitTimes() {
    const resp = await http.get(`/studierzeit_times/`)
    return resp.data
}

export async function getStudierzeitTimesListOnly() {
    const resp = await http.get(`/studierzeit_times/?show_in_list=true`)
    return resp.data
}

export async function getStudierzeitStudentList() {
    const resp = await http.get(`/studierzeit/students/`)
    return resp.data
}

export async function getStudierzeitList() {
    const resp = await http.get(`/studierzeit/list/`)
    return resp.data
}

export async function getStudierzeitAttendances() {
    const resp = await http.get(`/studierzeit_attendance/`)
    return resp.data
}

export async function getStudierzeitAttendance(id) {
    const resp = await http.get(`/studierzeit_attendance/${id}/`)
    return resp.data
}

export async function postStudierzeitAttendance(data) {
    const resp = await http.post(`/studierzeit_attendance/`, data)
    return resp.data
}

export async function patchStudierzeitAttendance(data) {
    const resp = await http.patch(`/studierzeit_attendance/${data.id}/`, data)
    return resp.data
}

export async function deleteStudierzeitAttendance(id) {
    const resp = await http.delete(`/studierzeit_attendance/${id}/`)
    return resp.data
}

export async function deleteStudierzeitChangesForStudents(data) {
    const resp = await http.post(`/studierzeit_changes/delete_multiple/`, data)
    return resp.data
}

export async function getStudierzeitChanges() {
    const resp = await http.get(`/studierzeit_changes/`)
    return resp.data
}

export async function getStudentStudierzeitChanges(studentId) {
    const resp = await http.get(`/studierzeit_changes/?student_id=${studentId}`)
    return resp.data
}

export async function studierzeitChangeApi(method, id, data) {
    return genericApi('/studierzeit_changes/', method, id, data)
}

export async function startStudierzeitListDownloadGeneration() {
    const resp = await http.get(`/studierzeit/download/`);
    return resp.data;
}

export async function getDefaultStudierzeitTimes(studentId) {
    const resp = await http.get(`/studierzeit_times/get_default_studierzeit_info_for_student/?student_id=${studentId}`)
    return resp.data
}

export async function getStudentsForStudierzeitParams(data) {
    const resp = await http.get(`/studierzeit/get_students/?date=${data.date}`
        + (data.studierzeit_time.id ? `&studierzeit_time=${data.studierzeit_time.id}` : '')
        + (data.studierzeit_type.id ? `&studierzeit_type=${data.studierzeit_type.id}` : '')
        + (data.room?.id ? `&room=${data.room.id}` : '')
    )
    return resp.data
}

export async function getAbsenceNotices(studentIds, date) {
    const resp = await http.get(`/studierzeit/absence_notices/?date=${date}&student_ids=${studentIds}`)
    return resp.data
}

export async function getStudentListForStudierzeit(params) {
    if (!(params.get('studierzeit_time') && params.get('weekday') && params.get('room'))) return []
    const resp = await http.get(`/studierzeit/details/` +
        `?studierzeit_time=${params.get('studierzeit_time')}&weekday=${params.get('weekday')}&room=${params.get('room')}`)
    return resp.data
}

// reports
export async function getStudierzeitAbsenceReasons() {
    const resp = await http.get('/studierzeit_absence_reasons/')
    return resp.data
}

export async function getStudierzeitAbsencesReport() {
    const resp = await http.get(`/studierzeit/reports/studierzeit_absences/`)
    return resp.data
}

export async function getStudierzeitAbsencesReportExcel() {
    const resp = await httpServiceDownloads.get(`/studierzeit/reports/studierzeit_absences/excel/`)
    return resp.data
}

// Assessments
export async function getStudierzeitAssessmentsForCreator(assessmentPeriodId) {
    const resp = await http.get(`/studierzeit/assessments/${assessmentPeriodId}/for_creators/`)
    return resp.data
}

export async function updateStudierzeitAssessmentsForCreator(assessmentPeriodId, data) {
    const resp = await http.post(`/studierzeit/assessments/${assessmentPeriodId}/for_creators/`, data)
    return resp.data
}

export async function getStudierzeitAssessmentsStudents(assessmentPeriodId) {
    const resp = await http.get(`/studierzeit/assessments/${assessmentPeriodId}/students/`)
    return resp.data
}

export async function getStudierzeitAssessmentsForStudent(assessmentPeriodId, studentId) {
    const resp = await http.get(`/studierzeit/assessments/${assessmentPeriodId}/students/${studentId}/`)
    return resp.data
}

export async function getHomeworkDataForStudierzeitAttendance(attendanceId) {
    const resp = await http.get(`/studierzeit/studierzeit_attendance_homework/${attendanceId}/`)
    return resp.data
}