import {SimplePage} from "../../elements/Pages";
import React, {useEffect, useState} from "react";
import ListViewTable, {TableLink} from "../../elements/tables/ListViewTable";
import {DownloadButton} from "../../elements/Buttons";
import {useSchoolYears} from "../../../contexts/SchoolYearContext";
import SchoolYearFilter from "../../elements/SchoolYearFilter";
import {
    getStudentsWithTutoringRecommendationsReport,
    getStudentsWithTutoringRecommendationsReportExcel
} from "../../../api/reports";
import {useQuery} from "@tanstack/react-query";


const columns = [
    {
        accessorFn: (row) => `${row.last_name}, ${row.first_name} ${row.student_number}`,
        id: "student_search",
        header: "SchülerIn",
        hide: false,
        enableColumnFilter: false,
    },
    {
        accessorKey: "school_class",
        id: "school_class",
        header: "Klasse",
        sortingFn: "schoolClassSorting",
        enableColumnFilter: false
    },
    {
        accessorKey: "subject_name",
        id: "subject_name",
        header: "Fach",
        enableColumnFilter: false
    },
    {
        accessorKey: "course_teacher_name",
        id: "course_teacher",
        header: "Lehrer",
        enableColumnFilter: false
    },
    {
        accessorKey: "time_period_name",
        id: "time_period_name",
        header: "Zeitraum",
        enableColumnFilter: false
    },
    {
        accessorKey: "id",
        id: "link",
        cell: ({row, value}) => (
            <>
            {row.original.assessment_id ?
                <TableLink label="Beurteilung" to={`/assessments/${row.original.assessment_id}`}/>
                : ''}
            </>
        ),
        header: "Beurteilung",
        enableColumnFilter: false,
        disableRowLink: true
    },
    {
        accessorKey: "id",
        id: "link",
        cell: ({row, value}) => (
            <>
                {row.original.tutoring_id ?
                    <TableLink label="Fördermaßnahme" to={`/tutoring/${row.original.tutoring_id}`}/>
                    : ''}
            </>
        ),
        header: "Fördermaßnahme",
        enableColumnFilter: false,
        disableRowLink: true
    },
    {
        accessorKey: "tutoring_teacher",
        id: "tutoring_teacher",
        header: "Nachhilfelehrer",
        enableColumnFilter: false
    },
]

export default function StudentsWithTutoringRecommendation() {

    const {schoolYears, currentSchoolYear} = useSchoolYears();
    const [schoolYearId, setSchoolYearId] = useState();

    const {isLoading, isError, isSuccess, data, error, refetch} = useQuery(
        ["students_with_tutoring_recommendation", schoolYearId],
        () => getStudentsWithTutoringRecommendationsReport(schoolYearId),
        {
            enabled: !!schoolYearId, refetchInterval: 1000 * 60 * 60
        }
    )

    useEffect(() => {
        if (currentSchoolYear) setSchoolYearId(currentSchoolYear["id"]);
    }, [currentSchoolYear])

    return (
        <SimplePage>
            <div className="px-4 sm:px-6 lg:px-8">
                <div className="sm:flex sm:items-center">
                    <div className="sm:flex-auto">
                        <h3 className="text-lg leading-6 font-medium text-gray-900">
                            Schüler mit Nachhilfeempfehlung
                        </h3>
                    </div>
                    <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
                        <DownloadButton
                            apiFunction={() => getStudentsWithTutoringRecommendationsReportExcel(schoolYearId)}
                            fileName="Nachhilfeempfehlungen.xls"
                            label="Excel herunterladen"/>

                    </div>
                </div>
                <div className="flex flex-col">
                    <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">

                        <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                            <SchoolYearFilter
                                schoolYears={schoolYears}
                                schoolYearId={schoolYearId}
                                setSchoolYearId={setSchoolYearId}
                            />
                        </div>

                        <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                            {data && (
                                <ListViewTable
                                    data={data}
                                    columns={columns}
                                    isLoading={false}
                                    disableGlobalSearch={false}
                                    getRowLink={null}
                                />
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </SimplePage>
    );
}
