import {SimplePage} from "../../elements/Pages";
import React from "react";
import ListViewTable from "../../elements/tables/ListViewTable";
import {useParams} from "react-router-dom";
import {getStudierzeitAssessmentsStudents} from "../../../api/studierzeit";
import {useQuery} from "@tanstack/react-query";


export default function StudierzeitAssessmentsStudents() {

    const {assessmentPeriodId} = useParams()
    const {data} = useQuery(['studierzeit_assessments_students', assessmentPeriodId], () => getStudierzeitAssessmentsStudents(assessmentPeriodId))

    const columnsViewOnly = [
        // {
        //     accessorFn: (row) => `${row.student.last_name}, ${row.student.first_name} ${row.student.student_number}`,
        //     id: "student",
        //     header: "SchülerIn",
        //     enableColumnFilter: false,
        // },
        {
            accessorFn: (row) =>
                `${row.student.last_name}, ${row.student.first_name} ${row.student.student_number}`,
            id: "student_search",
            header: "SchülerIn",
            hide: true,
            enableColumnFilter: false,
        },
        {
            accessorKey: "student",
            id: "student",
            header: "SchülerIn",
            columnType: "student",
            enableColumnFilter: false,
            sortingFn: "lastNameSorting",
        },
        {
            accessorKey: "student.school_class",
            id: "school_class",
            header: "Klasse",
            enableColumnFilter: true,
            classNames: "text-gray-500",
            headerClassNames: "narrow-filter"
        },
        {
            accessorKey: "attendances",
            id: "attendances",
            header: "Anzahl",
            enableColumnFilter: false
        },
        {
            accessorKey: "mark_attitude",
            id: "mark_attitude",
            header: "Arbeitsauffassung",
            enableColumnFilter: false,
            cell: ({row, value}) => (
                <>
                    {row.original.assessments.map((a, index) => (
                        <span key={index}>{index > 0 ? ',' : ''} {a.mark_attitude}</span>
                    ))}
                </>
            ),
        },
        {
            accessorKey: "mark_behavior",
            id: "mark_behavior",
            header: "Verhalten",
            enableColumnFilter: false,
            cell: ({row, value}) => (
                <>
                    {row.original.assessments.map((a, index) => (
                        <span key={index}>{index > 0 ? ',' : ''} {a.mark_behavior}</span>
                    ))}
                </>
            ),
        }
    ]

    function getRowLink(row) {
        return `/assessments/studierzeit/${assessmentPeriodId}/students/${row.original.student.id}`;
    }

    return (
        <SimplePage>
            <div className="px-4 sm:px-6 lg:px-8">
                <div className="sm:flex sm:items-center xl:col-span-3">
                    <div className="sm:flex-auto">
                        <h3 className="text-lg leading-6 font-medium text-gray-900">
                            Studierzeitbeurteilungen nach Schülern {data?.assessment_period?.name}
                        </h3>
                    </div>
                    <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
                    </div>
                </div>
                <div className="mt-4 flex flex-col xl:col-span-2">
                    <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
                        <div className="inline-block py-2 align-middle md:px-6 lg:px-8">

                            {data &&
                                <ListViewTable data={data.students}
                                               columns={columnsViewOnly}
                                               getRowLink={getRowLink}
                                />}

                        </div>
                    </div>
                </div>
            </div>
        </SimplePage>
    );
}
