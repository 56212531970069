import {SimplePage} from "../../elements/Pages";
import React from "react";

import {useQuery} from "@tanstack/react-query";
import {useAuth} from "../../../contexts/AuthContext";
import ListViewTable from "../../elements/tables/ListViewTable";
import {getQuestionnaireList} from "../../../api/questionnaires";


let columns = [
    {
        accessorKey: "title",
        id: "title",
        header: "Titel",
        enableColumnFilter: false,
    },
    {
        accessorKey: "get_target_display",
        id: "get_target_display",
        header: "Basis",
        headerClassNames: "medium-filter",
        enableColumnFilter: true
    },
    {
        id: "target_groups",
        accessorKey: "target_groups",
        header: "Zielgruppen",
        columnType: "m2m",
        labelAttr: "name",
        enableColumnFilter: true,
        headerClassNames: "medium-filter",
        filterFn: "containsString",
    },
    {
        id: "target_school_classes",
        accessorKey: "target_school_classes",
        header: "Klassen",
        columnType: "m2m",
        labelAttr: "name",
        enableColumnFilter: true,
        headerClassNames: "medium-filter",
        filterFn: "containsString",
    },
    {
        accessorKey: "open_from",
        id: "open_from",
        header: "Geöffnet von",
        columnType: "date",
        enableColumnFilter: false,
        classNames: "text-gray-500",
        headerClassNames: "narrow-filter"
    },
    {
        accessorKey: "open_to",
        id: "open_to",
        header: "Geöffnet bis",
        columnType: "date",
        enableColumnFilter: false,
        classNames: "text-gray-500",
        headerClassNames: "narrow-filter"
    }
]

export default function QuestionnaireList() {

    const {currentUser} = useAuth();
    const {data: questionnaires, isLoading} = useQuery(["questionnaires", currentUser.user_id], getQuestionnaireList)

    function getRowLink(row) {
        return `/questionnaires/${row.original.id}/`;
    }

    return (
        <SimplePage>
            {/*  Simple striped table */}
            <div className="px-4 sm:px-6 lg:px-8">
                <div className="sm:flex sm:items-center">
                    <div className="sm:flex-auto">
                        <h3 className="text-lg leading-6 font-medium text-gray-900">
                            Rückmeldungen
                        </h3>
                    </div>
                    <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
                    </div>
                </div>
                <div className="flex flex-col">
                    <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">

                        <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                            {questionnaires && (
                                    <ListViewTable
                                        data={questionnaires}
                                        columns={columns}
                                        isLoading={isLoading}
                                        disableGlobalSearch={false}
                                        rowSelection={null}
                                        setRowSelection={null}
                                        getRowLink={getRowLink}
                                        onDelete={null}
                                    />
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </SimplePage>
    );
}
