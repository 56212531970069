import {NarrowPage} from "../../elements/Pages";
import {React, useEffect, useState} from "react";
import Joi from "joi"

import {getPenaltyCategories, penaltiesApi} from '../../../api/logbook'
import {useQuery, useQueryClient} from '@tanstack/react-query'
import {Checkbox, DateInput, MultiSelect, Select, TextArea} from "../../elements/forms/Inputs"
import {FormSectionContent} from "../../elements/forms/FormLayout"
import {getStudentInfoAll} from "../../../api/school";
import Form, {baseHandleChange, baseHandleDateChange} from "../../elements/forms/Form"
import {useNavigate, useParams} from "react-router-dom";
import {toast} from "react-toastify";
import {getStaffList} from "../../../api/staff";


export default function PenaltyCreateEdit() {
    const queryClient = useQueryClient()
    const {id, studentId} = useParams()
    const [data, setData] = useState({
        id: null,
        student_id: parseInt(studentId) || null,
        remarks: "",
        penalty: null,
        date_from: null,
        date_to: null
    })

    const {data: students} = useQuery(['student_info'], getStudentInfoAll, {refetchInterval: 1000 * 60 * 60 * 24})
    const {data: penalties} = useQuery(['penalty_categories'], getPenaltyCategories, {refetchInterval: 1000 * 60 * 60 * 24})
    const {data: staff} = useQuery(['staff'], getStaffList, {refetchInterval: 1000 * 60 * 60 * 24})

    const [errors, setErrors] = useState({}) // validation errors

    let navigate = useNavigate();

    useEffect(() => {
        loadData()
    }, [id])

    async function loadData() {
        if (!id) return
        const newData = await penaltiesApi('get', id)
        setData(newData)
    }

    const schema = {
        student_id: Joi.any().required(),
        remarks: Joi.string().allow("", null),
        penalty: Joi.object().required(),
        date_from: Joi.date().required()
    };

    const title = (id || data.id) ? "Strafe bearbeiten" : "Neue Strafe eintragen"
    const successMessage = (id || data.id) ? "Strafe aktualisiert" : "Neue Strafe eingetragen"

    async function onSave() {
        let newData
        if (!data.id) newData = await penaltiesApi('post', null, data)
        else newData = await penaltiesApi('patch', data.id, data)
        setData(newData)
        queryClient.invalidateQueries({ queryKey: ['penalties'] })
        toast.success(successMessage)
        navigate(`/logbook/penalties`)
    }

    async function onDelete() {
        await penaltiesApi('delete', data.id)
        toast.success("Strafe gelöscht.")
        navigate(`/logbook/penalties`)
    }

    function handleChange(evt) {
        baseHandleChange(evt, data, setData, errors, setErrors, schema)
    }

    function handleDateChange(date, path) {
        baseHandleDateChange(date, path, data, setData, errors, setErrors, schema, false)
    }

    function handleMultiSelect(selectedOptions, path) {
        let newData = {...data}
        newData[path] = selectedOptions
        setData(newData)
    }

    return (

        <NarrowPage title={title}>

            <Form id="createPenalty" onSave={onSave}
                  onDelete={onDelete}
                  data={data} setData={setData}
                  errors={errors} setErrors={setErrors}
                  successMessage={successMessage}
                  schema={schema}>

                <FormSectionContent>

                    <Select
                        path="student_id"
                        label="SchülerIn"
                        className="sm:col-span-6"
                        options={students}
                        valueAttr="id"
                        labelAttr="full_name"
                        onChange={handleChange}
                        errors={errors}
                        data={data}
                        isClearable={false}
                        valueOnly={true}
                    />

                    <Select
                        path="penalty"
                        label="Strafe"
                        className="sm:col-span-6"
                        options={penalties}
                        valueAttr="id"
                        labelAttr="name"
                        onChange={handleChange}
                        errors={errors}
                        data={data}
                        isClearable={false}
                        valueOnly={false}
                    />

                    <DateInput
                        className="col-span-3"
                        path="date_from"
                        label="Abzuleisten von"
                        onChange={handleDateChange}
                        errors={errors}
                        data={data}
                        todayButton={true}
                        tomorrowButton={true}
                    />

                    <DateInput
                        className="col-span-3"
                        path="date_to"
                        label="Bis"
                        onChange={handleDateChange}
                        errors={errors}
                        data={data}
                        todayButton={true}
                        tomorrowButton={true}
                        minDate={new Date(data.date_from) || null}
                    />

                    <MultiSelect
                        path="responsible_staff"
                        label="Verantwortlich"
                        emptyLabel="Verantwortlich"
                        className="col-span-6"
                        options={staff}
                        valueAttr="id"
                        labelAttr="full_name"
                        onChange={(selectedOptions) => handleMultiSelect(selectedOptions, 'responsible_staff')}
                        data={data}
                        isClearable={true}
                        valueOnly={false}
                    />

                    <TextArea
                        path="remarks"
                        type="text"
                        label="Anmerkungen"
                        className="sm:col-span-6"
                        onChange={handleChange}
                        errors={errors}
                        data={data}
                    />

                    <Checkbox
                        path="compensated"
                        label="Abgeleistet"
                        className="sm:col-span-6 z-50 hide-label"
                        onChange={handleChange}
                        errors={errors}
                        data={data}
                    />

                </FormSectionContent>

            </Form>

            <span onClick={() => navigate(-1)}
                  className="cursor-pointer float-right mt-2 font-medium text-imsblue-600">Zurück</span>

        </NarrowPage>
    )
}
