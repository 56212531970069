import {useParams} from "react-router-dom";
import {React} from "react";
import {useQuery} from "@tanstack/react-query";
import {PrimaryButton, SecondaryButton} from "../../elements/Buttons";
import checkPermission from "../../../utils/permissions";
import {useAuth} from "../../../contexts/AuthContext";
import ListViewTable from "../../elements/tables/ListViewTable";
import {getAttendanceLinesForStudent, getAttendanceStudent} from "../../../api/projects";


export default function AttendanceStudentDetail() {

    const columns = [
        {
            accessorKey: "project_attendance.date",
            id: "date",
            header: "Datum",
            columnType: "date",
            enableColumnFilter: false,
            classNames: "text-gray-500",
        },
        {
            accessorKey: "project_attendance.project.name",
            id: "project",
            header: "Projekt",
            enableColumnFilter: true,
        },
        {
            accessorKey: "project_attendance.project.project_leaders",
            id: "project_leaders",
            header: "Projektleiter",
            columnType: "m2m",
            labelAttr: "full_name",
            enableColumnFilter: true,
            headerClassNames: "medium-filter",
        },
        {
            accessorKey: "attended.name",
            id: "attended",
            header: "Anwesenheit",
            enableColumnFilter: true,
            headerClassNames: "narrow-filter",
        }
    ]

    const {studentId, music} = useParams();
    const {permissions} = useAuth();

    const {data: studentInfo} = useQuery(["attendance_students", studentId], () =>
        getAttendanceStudent(studentId)
    )

    const {data} = useQuery(["project_attendance_lines", studentId, music], () =>
        getAttendanceLinesForStudent(studentId, music)
    )

    function getRowLink(row) {
        return `/projects/attendance/projects/${row.original.project_attendance.project.id}/`;
    }

    if (!studentInfo) return <></>;

    return (
        <>
            <main className="py-10 h-full bg-gray-100">
                {/* Page header */}
                <div
                    className="max-w-3xl mx-auto px-4 sm:px-6 md:flex md:items-center md:justify-between md:space-x-5 lg:max-w-7xl lg:px-8">
                    <div className="flex items-center space-x-5">
                        {/*<div className="flex-shrink-0">*/}
                        {/*    <div className="relative">*/}
                        {/*        <img*/}
                        {/*            className="h-16 w-16 rounded-full"*/}
                        {/*            src={studentInfo.student.image?.detail}*/}
                        {/*            alt={studentInfo.student.full_name}*/}
                        {/*        />*/}
                        {/*        <span*/}
                        {/*            className="absolute inset-0 shadow-inner rounded-full"*/}
                        {/*            aria-hidden="true"*/}
                        {/*        />*/}
                        {/*    </div>*/}
                        {/*</div>*/}
                        <div>
                            <h1 className="text-2xl font-bold text-gray-900">
                                {studentInfo.student.full_name}
                            </h1>
                            <p className="text-sm font-medium text-gray-500">
                                {studentInfo.student.student_number}
                            </p>
                        </div>
                    </div>
                    <div
                        className="mt-6 flex flex-col-reverse justify-stretch space-y-4 space-y-reverse sm:flex-row-reverse sm:justify-end sm:space-x-reverse sm:space-y-0 sm:space-x-3 md:mt-0 md:flex-row md:space-x-3">
                        <SecondaryButton label="Schülerblatt" link={`/school/students/${studentId}`}/>

                        <SecondaryButton label="Projektwahl" link={`/projects/students/${studentId}`}/>

                    </div>
                </div>

                <div
                    className="mt-8 max-w-3xl mx-auto grid grid-cols-1 gap-6 sm:px-6 lg:max-w-7xl lg:grid-flow-col-dense lg:grid-cols-3">

                    <div className="space-y-6 lg:col-start-1 lg:col-span-2">

                        <section aria-labelledby="notes-title">

                            {data &&
                                <ListViewTable
                                    data={data}
                                    columns={columns}
                                    disableGlobalSearch={true}
                                    getRowLink={getRowLink}
                                />}
                        </section>

                        {/* Table */}
                        <section aria-labelledby="applicant-information-title"></section>
                    </div>

                    <section
                        aria-labelledby="timeline-title"
                        className="lg:col-start-3 lg:col-span-1 mt-6"
                    >
                        <section aria-labelledby="applicant-information-title">

                            {!music && <div className="bg-white shadow overflow-hidden sm:rounded-lg">
                                <div className="px-4 py-5 sm:px-6">
                                    <h3 className="text-lg leading-6 font-medium text-gray-900">
                                        Zusammenfassung
                                    </h3>
                                </div>
                                <div className="border-t border-gray-200 px-4 py-5 sm:p-0">
                                    <dl className="sm:divide-y sm:divide-gray-200">
                                        <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                            <dt className="text-sm font-medium text-gray-500">Teilgenommen</dt>
                                            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                                {studentInfo.num_attended}
                                            </dd>
                                        </div>
                                        <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                            <dt className="text-sm font-medium text-gray-500">Unentschuldigt</dt>
                                            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                                {studentInfo.num_unexcused}
                                            </dd>
                                        </div>
                                        <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                            <dt className="text-sm font-medium text-gray-500">Sonstige</dt>
                                            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                                {studentInfo.num_other}
                                            </dd>
                                        </div>
                                    </dl>
                                </div>
                            </div>}

                        </section>
                    </section>
                </div>

            </main>
        </>
    )
}

