import http from "../services/httpService";


export async function getSchoolYearChange(schoolYearId) {
    const resp = await http.get(`/school_year_change/` + (schoolYearId ? `${schoolYearId}/` : ''))
    return resp.data
}

export async function startArchiving(schoolYearId) {
    const resp = await http.post(`/school_year_change/${schoolYearId}/start_archiving/`)
    return resp.data
}

export async function confirmArchiving(schoolYearId) {
    const resp = await http.post(`/school_year_change/${schoolYearId}/confirm_archiving/`)
    return resp.data
}

export async function startDeletion(schoolYearId) {
    const resp = await http.post(`/school_year_change/${schoolYearId}/start_deletion/`)
    return resp.data
}
export async function completeSchoolYearChange(schoolYearId) {
    const resp = await http.post(`/school_year_change/${schoolYearId}/complete/`)
    return resp.data
}
