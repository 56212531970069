import {SimplePage} from "../../elements/Pages";
import React from "react";

import {useQuery} from "@tanstack/react-query";
import ListViewTable, {TableEditLink} from "../../elements/tables/ListViewTable";
import {getStudierzeitChanges} from "../../../api/studierzeit";
import checkPermission from "../../../utils/permissions";
import {useAuth} from "../../../contexts/AuthContext";


export default function StudierzeitChangeList() {

    const {permissions} = useAuth()
    const {isLoading, isError, isSuccess, data, error, refetch} = useQuery(
        ["studierzeit_changes"],
        () => getStudierzeitChanges()
    )

    function getRowLink(row) {
        return `/studierzeit/students/${row.original.student.id}/`
    }

    const canEdit = checkPermission(permissions, "studierzeit.change_changedstudierzeit");


    const columns = [
        {
            accessorFn: (row) =>
                `${row.student.last_name}, ${row.student.first_name} ${row.student.student_number}`,
            id: "student_search",
            header: "SchülerIn",
            hide: true,
            enableColumnFilter: false,
        },
        {
            accessorKey: 'student',
            id: 'student',
            header: 'SchülerIn',
            columnType: 'student',
            enableColumnFilter: false,
            sortingFn: "lastNameSorting",
        },
        {
            accessorKey: "weekday",
            id: "weekday",
            header: "Tag",
            enableColumnFilter: false,
        },
        {
            accessorKey: "from_studierzeit_time",
            id: "from_studierzeit_time",
            header: "Von",
            enableColumnFilter: false,
            cell: ({row, value}) => (
                <>
                    {row.original.from_studierzeit_time?.name} <br/>
                    {row.original.from_studierzeit_time?.period?.name}
                </>
            ),
        },
        {
            accessorKey: "new_studierzeit_time",
            id: "new_studierzeit_time",
            header: "Zu",
            cell: ({row, value}) => (
                <>
                    {row.original.new_studierzeit_time?.name} <br/>
                    {row.original.new_studierzeit_time?.period?.name} <br/>
                    {row.original.room?.name}
                </>
            ),
            enableColumnFilter: false,
            // classNames: "text-gray-500",
        },
        {
            accessorKey: "reason",
            id: "reason",
            // columnType: "m2m",
            header: "Grund",
            enableColumnFilter: false,
        },
        ...canEdit ? [{
            accessorKey: "id",
            id: "link",
            cell: ({row}) => (
                <>
                    <TableEditLink
                        iconOnly={true}
                        label="Bearbeiten"
                        to={`/studierzeit/changes/${row.original.id}/edit`}
                    />
                </>
            ),
            header: "",
            disableRowLink: true,
            enableColumnFilter: false,
        }] : []
    ];

    return (
        <SimplePage>
            <div className="px-4 sm:px-6 lg:px-8">
                <div className="sm:flex sm:items-center">
                    <div className="sm:flex-auto">
                        <h3 className="text-lg leading-6 font-medium text-gray-900">
                            Studierzeitwechsel
                        </h3>
                    </div>
                    <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
                    </div>
                </div>
                <div className="flex flex-col">
                    <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
                        <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                            {isSuccess && data && (
                                <ListViewTable
                                    data={data}
                                    columns={columns}
                                    isLoading={isLoading}
                                    disableGlobalSearch={false}
                                    getRowLink={getRowLink}
                                />
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </SimplePage>
    );
}
