
export const deErrors = {
    "root": "value",
    "key": "{{!key}} ",
    // "messages": {
    //     "wrapArrays": true
    // },

    "any.unknown": "ist nicht erlaubt",
    "any.invalid": "beinhaltet einen ungültigen Wert",
    "any.empty": "darf nicht leer sein",
    "any.required": "darf nicht leer sein",
    "any.allowOnly": "muss eins von {{valids}} sein",
    "any.default": "beim Ausführen der Standardmethode ist ein Fehler aufgetreten",

    "alternatives.base": "stimmt nicht mit einer der zulässigen Alternativen überein",

    "array.base": "muss eine Liste sein",
    "array.includes":
        "an der Position {{pos}} passt es nicht zu den zulässigen Typen",
    "array.includesSingle":
        "Einzelwert von \"{{!key}}\" passt nicht zu den zulässigen Typen",
    "array.includesOne": "an der Position {{pos}} scheitert weil {{reason}}",
    "array.includesOneSingle":
        "Einzelwert von \"{{!key}}\" scheitert weil {{reason}}",
    "array.includesRequiredUnknowns":
        "beinhaltet keine {{unknownMisses}} erforderliche Wert(e)",
    "array.includesRequiredKnowns": "beinhaltet nicht {{knownMisses}}",
    "array.includesRequiredBoth":
        "beinhaltet nicht {{knownMisses}} und {{unknownMisses}} weitere erforderliche Wert(e)",
    "array.excludes": "an der Position {{pos}} enthält einen ausgeschlossenen Wert",
    "array.excludesSingle":
        "Einzelwert von \"{{!key}}\" enthält einen ausgeschlossenen Wert",
    "array.min": "Bitte mindestens {{#limit}} auswählten",
    "array.max": "muss weniger als oder gleich {{#limit}} Elemente enthalten",
    "array.length": "muss {{#limit}} Elemente enthalten",
    "array.ordered": "an der Position {{pos}} schlägt es fehl, weil {{reason}}",
    "array.orderedLength":
        "an der Position {{pos}} schlägt es fehl, weil das Array höchstens {{#limit}} Elemente enthalten darf",
    "array.sparse": "darf kein Array mit Nullwerten sein",
    "array.unique": "die Position {{pos}} enthält einen doppelten Wert",

    "boolean.base": "muss ein Boolean sein",

    "binary.base": "muss ein Buffer oder String sein",
    "binary.min": "muss mindestes {{#limit}} Bytes sein",
    "binary.max": "muss weniger oder gleich {{#limit}} Bytes sein",
    "binary.length": "muss {{#limit}} Bytes sein",

    "date.base": "muss ein gültiges Datum sein",
    "datemin": "muss größer oder gleich \"{{#limit}}\" sein",
    "date.max": "must be less than or equal to \"{{#limit}}\"",
    "date.isoDate": "muss ein gültiges ISO 8601-Datum sein",
    "date.ref": "referenziert \"{{ref}}\" was kein Datum ist",

    "function.base": "muss eine Function sein",

    "object.base": "bitte eine Option auswählen",
    "object.child": "Kind \"{{!key}}\" scheitert weil {{reason}}",
    "object.min": "muss mindestens {{#limit}} Kinder haben",
    "object.max": "muss weniger oder gleich {{#limit}} Kinder enthalten",
    "object.length": "muss {{#limit}} Kinder enthalten",
    "object.allowUnknown": "ist nicht erlaubt",
    "object.with": "fehlender erforderlicher Peer \"{{peer}}\"",
    "object.without": "Konflikt mit verbotenen Peer \"{{peer}}\"",
    "object.missing": "muss mindestens einen von {{peers}} enthalten",
    "object.xor": "enthält einen Konflikt zwischen exklusiven Peers {{peers}}",
    "object.or": "muss mindestens einen von {{peers}} enthalten",
    "object.and": "enthält {{present}} ohne folgende erforderlichen Peers {{missing}}",
    "object.nand": "!!\"{{main}}\" darf nicht gleichzeitig mit {{peers}} existieren",
    "object.assert":
        "!!\"{{ref}}\" Validierung fehlgeschlagen, weil \"{{ref}}\" {{message}} fehlgeschlagen ist",

    "object.type": "muss ein \"{{type}}\" sein",

    "number.base": "bitte eine Zahl eingeben",
    "number.min": "muss größer oder gleich {{#limit}} sein",
    "number.max": "muss kleiner oder gleich {{#limit}} sein",
    "number.less": "muss kleiner als {{#limit}} sein",
    "number.greater": "muss größer als {{#limit}} sein",
    "number.float": "muss ein Float oder Double sein",
    "number.integer": "muss ein Integer sein",
    "number.negative": "muss eine negative Zahl sein",
    "number.positive": "muss eine positive Zahl sein",
    "number.precision": "darf nicht mehr als {{#limit}} Dezimalstellen haben",
    "number.ref": "referenziert \"{{ref}}\" was keine Number ist",
    "number.multiple": "muss ein Vielfaches von {{multiple}} sein",

    "string.min": "Länge muss mindestens {{#limit}} Zeichen lang sein",
    "string.base": "darf nicht leer sein",
    "string.max": "Länge muss weniger oder gleich {{#limit}} Zeichen lang sein",
    "string.length": "Länge muss {{#limit}} Zeichen lang sein",
    "string.alphanum": "darf nur alphanumerische Zeichen enthalten",
    "string.token": "darf nur alphanumerische Zeichen und Unterstriche enthalten",
    "string.required": "darf nicht leer sein",
    "string.empty": "darf nicht leer sein",
    // "regex": {
    //     "base":
    //         "mit Wert \"{{!value}}\" stimt es nicht mit dem erforderlichen Muster überein: {{pattern}}",
    //     "name":
    //         "mit Wert \"{{!value}}\" stimt es nicht mit dem {{name}} Muster überein"
    // },

    // "string.email.required": "ist obligatorisch",
    "string.email": "muss eine gültige Email-Adresse sein",
    // "string.email.base": "muss eine gültige Email-Adresse sein",
    "string.uri": "muss eine valide URI sein",
    "string.uriCustomScheme":
        "muss eine valide URI sein mit einem Schema, dass mit dem {{scheme}} Muster übereinstimmt",
    "string.isoDate": "muss ein gültiges ISO 8601-Datum sein",
    "string.guid": "muss eine valide GUID sein",
    "string.hex": "darf nur hexadezimale Zeichen enthalten",
    "string.hostname": "muss ein gültiger Hostname sein",
    "string.lowercase": "darf nur Kleinbuchstaben enthalten",
    "string.uppercase": "darf nur Großbuchstaben enthalten",
    "string.trim": "darf keine führenden oder nachgestellten Leerzeichen enthalten",
    "string.creditCard": "muss eine Kreditkarte sein",
    "string.ref": "Referenziert \"{{ref}}\", was keine Nummer ist",

    "string.ip": "muss eine gültige IP-Adresse mit einer {{cidr}} CIDR sein",
    "string.ipVersion":
        "muss eine gültige IP-Adresse sein die mit einer der folgenden Versionen {{version}} und {{cidr}} CIDR übereinstimmt"

};
//
// export const deErrors = {
//     "root": "value",
//     "key": "{{!key}} ",
//     // "messages": {
//     //     "wrapArrays": true
//     // },
//     'string.empty': 'Non può essere vuoto',
//     "string.min": "Muss mindestens {{#limit}} Zeichen lang sein",
//     'number.base': 'Deve essere un numero',
//     'any.invalid': 'Valore non valido',
//     'domain.invalid': 'Dominio non valido',
//     'email.invalid': 'Email non valida',
//     'phoneNumber.invalid': 'Numero di telefono non valido',
//     'array.unique': 'Valore duplicato',
//     'custom.max11char': 'Massimo 11 caratteri alfanumerici. Iniziare con una lettera'
// };