import http from "./../services/httpService";

export async function getMessgRecepients() {
  const resp = await http.get(`/messenger/messenger_detail_recipients/`);
  return resp.data;
}

export async function getInbox(page) {
  const resp = await http.get(`/simple_messages/inbox/` + (page > 1 ? `?page=${page}` : ''));
  return resp.data;
}

export async function getUnreadMessages() {
    const resp = await http.get(`/simple_messages/unread`)
    return resp.data
}

export async function getArchived(page) {
  const resp = await http.get(`/simple_messages/archived/` + (page > 1 ? `?page=${page}` : ''));
  return resp.data;
}

export async function getSent(page) {
  const resp = await http.get(`/simple_messages/sent/` + (page > 1 ? `?page=${page}` : ''));
  return resp.data;
}

export async function getTrashed(page) {
  const resp = await http.get(`/simple_messages/trashed/` + (page > 1 ? `?page=${page}` : ''));
  return resp.data;
}

export async function getDrafted(page) {
  const resp = await http.get(`/simple_messages/drafts/` + (page > 1 ? `?page=${page}` : ''));
  return resp.data;
}

// CRUD individual conversations/messages
export async function getConversationById(parentMessageId) {
  const resp = await http.get(`/simple_messages/${parentMessageId}/conversation_detail/`);
  return resp.data;
}

export async function createMessage(payload) {
  const resp = await http.post(`/simple_messages/`, payload);
  return resp.data;
}
export async function updateMessage(messageId, payload) {
  const resp = await http.patch(`/simple_messages/${messageId}/`, payload);
  return resp.data;
}

export async function deleteMessage(id) {
  const resp = await http.delete(`/simple_messages/${id}/`)
  return resp.data;
}

export async function sendMessageAPI(id) {
  const resp = await http.post(`/simple_messages/${id}/send/`)
  return resp.data;
}

export async function changeStatus(parentMessageId, status) {
  const resp = await http.post(`/simple_messages/${parentMessageId}/change_status/`, {
    status,
  });
  return resp.data;
}

export async function readConversation(id) {
  const resp = await http.post(`/simple_messages/${id}/read/`);
  return resp.data;
}

export async function emptyTrash() {
  const resp = await http.post(`/simple_messages/empty_trash/`)
  return resp.data
}


// recipients & permissions

export async function getMessengerPermissions() {
  const resp = await http.get(`/messenger/messenger_permissions/`);
  return resp.data;
}

export async function getRecipientsByFilter(payload) {
  const resp = await http.post(`/messenger/get_recipients/`, payload);
  return resp.data;
}

export async function uploadFileForMessage(formData, onUploadProgress) {
  const resp = await http.post(`/message_attachments/`, formData, {
    onUploadProgress,
  });
  return resp.data;
}

export async function deleteAttachment(id) {
  const resp = await http.delete(`/message_attachments/${id}/`)
  return resp.data;
}
