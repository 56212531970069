import {SimplePage} from "../../elements/Pages";
import React from "react";

import {useQuery} from "@tanstack/react-query";
import ListViewTable from "../../elements/tables/ListViewTable";
import {getLogbookIpadReport, getLogbookIpadReportExcel} from "../../../api/reports";
import {DownloadButton} from "../../elements/Buttons";


var columns = [
    {
        accessorFn: (row) =>
            `${row.last_name}, ${row.first_name} ${row.student_number}`,
        id: "student_search",
        header: "SchülerIn",
        hide: true,
        enableColumnFilter: false,
    },
    {
        accessorFn: (row) => row,
        id: "student",
        header: "SchülerIn",
        columnType: "student_no_image",
        enableColumnFilter: false,
        sortingFn: "lastNameSorting",
    },
    {
        accessorKey: "school_class",
        id: "school_class",
        header: "Klasse",
        sortingFn: "schoolClassSorting",
        headerClassNames: "narrow-filter",
    },
    {
        accessorKey: "house",
        id: "house",
        header: "Mentorat",
        enableColumnFilter: true,
        headerClassNames: "narrow-filter",
    },
    {
        accessorKey: "number_of_violations",
        id: "number_of_violations",
        columnType: "number",
        header: "Verstöße",
        enableColumnFilter: false
    }
]


export default function LogbookIpadReport() {

    const {isLoading, isError, isSuccess, data, error, refetch} = useQuery(
        ["ipad_violations_report"],
        getLogbookIpadReport,
        {refetchInterval: 1000 * 60 * 60}
    )

    function getRowLink(row) {
        return `/logbook/students/${row.original.id}/${decodeURI("iPad Verstoß")}`;
    }

    return (
        <SimplePage>
            <div className="px-4 sm:px-6 lg:px-8">
                <div className="sm:flex sm:items-center">
                    <div className="sm:flex-auto">
                        <h3 className="text-lg leading-6 font-medium text-gray-900">
                            iPad-Verstöße
                        </h3>
                    </div>
                    <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
                        <DownloadButton
                            apiFunction={() => getLogbookIpadReportExcel()}
                            fileName="iPad-Verstöße.xls"
                            label="Excel herunterladen"/>

                    </div>
                </div>
                <div className="flex flex-col">
                    <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
                        <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                            {isSuccess && data && (
                                <ListViewTable
                                    data={data}
                                    columns={columns}
                                    isLoading={isLoading}
                                    disableGlobalSearch={false}
                                    getRowLink={getRowLink}
                                />
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </SimplePage>
    );
}
