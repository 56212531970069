import {NarrowPage, Page} from "../../elements/Pages";
import {React, useEffect, useState} from "react";
import Joi from "joi"

import {useParams, Link, useNavigate} from 'react-router-dom'
import {getPeExemption, patchPeExemption, postPeExemption, deletePeExemption} from '../../../api/logbook'
import {useQuery} from '@tanstack/react-query'
import {DateInput, Input, Select, TextArea} from "../../elements/forms/Inputs"
import {FormRow, FormSectionContent} from "../../elements/forms/FormLayout"
import {getStudentInfoAll} from "../../../api/school";
import Form, {baseHandleChange, baseHandleDateChange} from "../../elements/forms/Form"
import {toast} from "react-toastify";

export default function PeExemptionCreateEdit() {

    const {id, studentId} = useParams()
    const [data, setData] = useState({
        id: null,
        student_id: parseInt(studentId) || null,
        reason: "",
        notes: "",
        date_from: null,
        date_to: null,
    })

    let navigate = useNavigate();

    useEffect(() => {
        loadData()
    }, [id])

    async function loadData() {
        if (!id) return
        const newData = await getPeExemption(id)
        setData(newData)
    }

    const {data: students} = useQuery(['student_info'], getStudentInfoAll, {refetchInterval: 1000 * 60 * 60 * 24})

    const [errors, setErrors] = useState({}) // validation errors

    const schema = {
        student_id: Joi.any().required(),
        reason: Joi.string().required().min(3),
        date_from: Joi.date().required(),
        date_to: Joi.date().required()
    };

    async function onSave() {
        // validation happens in Form component
        let newData
        if (!data.id) newData = await postPeExemption(data)
        else newData = await patchPeExemption(data)
        setData(newData)
        toast.success("Sportbefreiung aktualisiert.")
        navigate(`/logbook/pe-exemptions`)
    }

    async function onDelete() {
        await deletePeExemption(data.id)
        toast.success("Sportbefreiung gelöscht.")
        navigate(`/logbook/pe-exemptions`)
    }

    function handleChange(evt) {
        baseHandleChange(evt, data, setData, errors, setErrors, schema)
    }

    function handleDateChange(date, path) {
        baseHandleDateChange(date, path, data, setData, errors, setErrors, schema)
    }

    const title = (id || data.id) ? "Sportbefreiung bearbeiten" : "Neue Sportbefreiung eintragen"
    const successMessage = (id || data.id) ? "Sportbefreiung aktualisiert" : "Neue Sportbefreiung eingetragen"

    return (

        <NarrowPage title={title}>

            <Form id="createEditPeExemption" onSave={onSave}
                  onDelete={onDelete}
                  data={data} setData={setData}
                  className=""
                  errors={errors} setErrors={setErrors}
                  successMessage={successMessage}
                  schema={schema}>

                <FormSectionContent>

                    <Select
                        path="student_id"
                        label="SchülerIn"
                        className="sm:col-span-6"
                        options={students}
                        valueAttr="id"
                        labelAttr="full_name"
                        onChange={handleChange}
                        errors={errors}
                        data={data}
                        isClearable={false}
                        valueOnly={true}
                    />

                    <Input
                        path="reason"
                        type="text"
                        label="Grund"
                        className="sm:col-span-6"
                        onChange={handleChange}
                        errors={errors}
                        data={data}
                    />

                    <TextArea
                        path="notes"
                        label="Bemerkungen"
                        onChange={handleChange}
                        errors={errors}
                        data={data}
                        rows={5}
                        className="sm:col-span-6"
                        helpText="Bemerkungen zur Sportbefreiung"
                    />

                    <DateInput
                        className="col-span-2"
                        path="date_from"
                        label="Von"
                        onChange={handleDateChange}
                        errors={errors}
                        data={data}
                        todayButton={true}
                        tomorrowButton={false}
                    />

                    <DateInput
                        className="col-span-2"
                        path="date_to"
                        label="Bis"
                        onChange={handleDateChange}
                        errors={errors}
                        data={data}
                        todayButton={false}
                        tomorrowButton={false}
                    />

                </FormSectionContent>


            </Form>


            <Link to="/logbook/pe-exemptions" className="float-right mt-2 font-medium text-imsblue-600">Zurück zur
                Liste</Link>

        </NarrowPage>
    )
}
