import {useState} from "react";
import {TrashIcon} from "@heroicons/react/20/solid";


export function ConfirmButton ({onConfirm, label, icon, confirmPrompt}) {

    const [open, setOpen] = useState(false)
    const IconComponent = icon

    return (
        <>
            {open && <div className="text-red-700">
                <span>{confirmPrompt}</span>
                <div className="text-red-600 inline-flex gap-2">
                    <button type="button" className="inline-flex w-full justify-center rounded-md border border-transparent bg-red-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-red-700 sm:ml-3 sm:w-auto sm:text-sm"
                            onClick={onConfirm}>Ja</button>
                    <button type="button"
                            className="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-imsblue-500 focus:ring-offset-2 sm:mt-0 sm:w-auto sm:text-sm"
                            onClick={() => setOpen(false)}>Abbrechen</button>
                </div>
            </div>}

            {!open && <button
                type="button"
                className={"inline-flex px-4 py-2 " +
                "border border-transparent text-sm font-medium rounded-md shadow-sm " +
                "text-white bg-red-700 " +
                "hover:bg-red-900 focus:outline-none focus:ring-2 focus:ring-offset-2 " +
                "focus:ring-offset-gray-100 focus:ring-blue-500"}
                onClick={() => setOpen(true)}>
                {icon && <IconComponent className="h-5 w-5" />} {label}
            </button>}

        </>
    )

}

