import {SimplePage} from "../../elements/Pages";
import React from "react";

import {useQuery} from "@tanstack/react-query";
import ListViewTable, {TableLink} from "../../elements/tables/ListViewTable";
import {useParams} from "react-router-dom";
import {EditButton, SecondaryButton} from "../../elements/Buttons";
import {getStudierzeitAttendance} from "../../../api/studierzeit";
import {formatDate} from "../../../utils/formatters";


const columns = [
    {
        accessorFn: (row) =>
            `${row.student.last_name}, ${row.student.first_name} ${row.student.student_number}`,
        id: "student",
        header: "SchülerIn",
        enableColumnFilter: false,
    },
    {
        accessorKey: "attended.name",
        id: "attendad",
        header: "Anwesend",
        enableColumnFilter: true,
    }
]

export default function AttendanceDetail() {

    const {id} = useParams()

    const {isLoading, isError, isSuccess, data, error, refetch} = useQuery(
        ["studierzeit_attendance", id],
        () => getStudierzeitAttendance(id)
    )

    if (!data) return <></>

    return (
        <SimplePage>
            <div className="px-4 sm:px-6 lg:px-8">
                <div className="sm:flex sm:items-center">
                    <div className="sm:flex-auto">
                        <h3 className="text-lg leading-6 font-medium text-gray-900">
                            Studierzeitanwesenheit {data.studierzeit_type.name} {data.room?.name} {formatDate(data.date)}
                        </h3>
                    </div>
                    <div className="my-4 sm:mt-0 sm:ml-16 flex gap-2">
                        <SecondaryButton link={`/studierzeit/attendance/${id}/homework`}
                                    label="Hausaufgaben"/>

                        <EditButton link={`/studierzeit/attendance/${id}/edit`}
                                    label="Bearbeiten"/>
                    </div>
                </div>
                <div className="flex flex-col">
                    <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
                        <div className="inline-block py-2 align-middle md:px-6 lg:px-8">
                            {isSuccess && data && (
                                <ListViewTable
                                    noTopSpace={true}
                                    data={data.studierzeit_attendance_lines}
                                    columns={columns}
                                    isLoading={isLoading}
                                    disableGlobalSearch={true}
                                    getRowLink={null}
                                />
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </SimplePage>
    );
}