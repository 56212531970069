import React from "react";
import {SimplePage} from "../../elements/Pages";
import {useQuery} from "@tanstack/react-query";
import {
    deleteStudierzeitChangesForStudents,
    getStudierzeitList,
    getStudierzeitStudentList, startStudierzeitListDownloadGeneration
} from "../../../api/studierzeit";
import {AddButton, AsyncDownloadButton, EditButton, ListButton} from "../../elements/Buttons";
import {Link} from "react-router-dom";
import ListViewTable, {
    IndeterminateCheckbox,
    TableDownloadLink,
    TableEditLink,
    TableLink
} from "../../elements/tables/ListViewTable";
import {useState} from "react";
import {getParticipantListPDF} from "../../../api/projects";
import {CalendarIcon, DocumentCheckIcon} from "@heroicons/react/20/solid";

import env from "react-dotenv";
import {startAllAppointmentListGeneration} from "../../../api/parent_conference";

const columns = [
    {
        accessorKey: "student.id",
        id: "select",
        columnType: "select",
    },
    {
        accessorFn: (row) =>
            `${row.student.last_name}, ${row.student.first_name} ${row.student.student_number}`,
        id: "student_search",
        header: "SchülerIn",
        hide: true,
        enableColumnFilter: false,
    },
    {
        accessorKey: 'student',
        id: 'student',
        header: 'SchülerIn',
        columnType: 'student',
        enableColumnFilter: false,
        sortingFn: "lastNameSorting",
    },
    {
        accessorKey: "student.school_class",
        id: "school_class",
        header: "Klasse",
        enableColumnFilter: true,
        headerClassNames: "narrow-filter",
    },
    {
        accessorKey: "student.house",
        id: "house",
        header: "Mentorat",
        enableColumnFilter: true,
        headerClassNames: "narrow-filter",
    },
    {
        accessorKey: "weekdays.Mo",
        id: "monday",
        header: "Mo",
        cell: ({row, value}) => (<GetWeekdayCell row={row} weekday='Mo'/>),
        labelAttr: "name",
        headerClassNames: "medium-filter",
        enableColumnFilter: false,
    },
    {
        accessorKey: "weekdays.Di",
        id: "tuesday",
        header: "Di",
        cell: ({row, value}) => (<GetWeekdayCell row={row} weekday='Di'/>),
        labelAttr: "name",
        headerClassNames: "medium-filter",
        enableColumnFilter: false,
    },
    {
        accessorKey: "weekdays.Mi",
        id: "wednesday",
        header: "Mi",
        cell: ({row, value}) => (<GetWeekdayCell row={row} weekday='Mi'/>),
        labelAttr: "name",
        headerClassNames: "medium-filter",
        enableColumnFilter: false,
    },
    {
        accessorKey: "weekdays.Do",
        id: "thursday",
        header: "Do",
        cell: ({row, value}) => (<GetWeekdayCell row={row} weekday='Do'/>),
        labelAttr: "name",
        headerClassNames: "medium-filter",
        enableColumnFilter: false,
    },
    ...env.SCHOOL === 'KPI' ? [
        {
            accessorKey: "weekdays.Fr",
            id: "friday",
            header: "Fr",
            cell: ({row, value}) => (<GetWeekdayCell row={row} weekday='Fr'/>),
            labelAttr: "name",
            headerClassNames: "medium-filter",
            enableColumnFilter: false,
        }
    ] : [],
    // {
    //     accessorKey: "student.id",
    //     id: "link",
    //     cell: (info) => (
    //         <>
    //             <TableEditLink iconOnly={true}
    //                 label="Bearbeiten"
    //                 to={`/studierzeit/students/${info.getValue()}/edit`}
    //             />
    //         </>
    //     ),
    //     header: "",
    //     headerClassNames: "narrow-filter",
    //     enableColumnFilter: false
    // },
];


function GetWeekdayCell({row, weekday}) {
    return <>
        {row.original.weekdays[weekday] && row.original.weekdays[weekday].map((entry, entryIndex) => (
            <div key={entryIndex} className="my-2">
                <Link className="font-medium text-imsblue-600"
                      to={`/studierzeit/detail/?studierzeit_time=${entry.studierzeit_time.id}&weekday=${weekday}&room=${entry.room.id}`}>
                    {entry.studierzeit_time.__str__} <br/>
                    {entry.room?.name}
                </Link>
            </div>
        ))}</>
}


export default function StudentList() {

    const {
        isLoading,
        isSuccess,
        data,
        refetch
    } = useQuery(["studierzeit_student_list"], getStudierzeitStudentList, {refetchInterval: 1000 * 60 * 60 * 24})

    const [rowSelection, setRowSelection] = useState([]);

    function handleDeleteChangesForSelectedStudents() {
        const keys = Object.keys(rowSelection)
        const selectedRowsIds = keys.map((key) => {
            return data.rows[parseInt(key)].student.id
        })
        deleteStudierzeitChangesForStudents({student_ids: selectedRowsIds})
        refetch()
    }

    function getRowLink(row) {
        return `/studierzeit/students/${row.original.student.id}`
    }

    return (
        <>
            <SimplePage>
                <div className="px-4 sm:px-6 lg:px-8">
                    <div className="sm:flex sm:items-center">
                        <div className="sm:flex-auto">
                            <h3 className="text-lg leading-6 font-medium text-gray-900">
                                Studierzeit nach SchülerInnen
                            </h3>
                        </div>
                        <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none flex gap-1">
                            <AsyncDownloadButton
                                apiFunction={() => startStudierzeitListDownloadGeneration()}
                                label="Aushangliste herunterladen"/>
                        </div>
                    </div>

                    <div className="flex flex-col mt-3 mb-3">
                        <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
                            <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">

                                {isSuccess && data && (
                                    <ListViewTable
                                        data={data.rows}
                                        columns={columns}
                                        isLoading={isLoading}
                                        disableGlobalSearch={false}
                                        getRowLink={getRowLink}
                                        rowSelection={rowSelection}
                                        setRowSelection={setRowSelection}
                                        onDelete={handleDeleteChangesForSelectedStudents}
                                    />
                                )}


                                {/*<div className="overflow-hidden">*/}

                                {/*    {data && <table*/}
                                {/*        className="min-w-full divide-y divide-gray-300 mt-3 border border-gray-200">*/}
                                {/*        <thead className="bg-gray-50">*/}

                                {/*        /!* Top level: weekday *!/*/}
                                {/*        <tr className="divide-x divide-gray-200">*/}
                                {/*            /!*<th*!/*/}
                                {/*            /!*    key={header.id}*!/*/}
                                {/*            /!*    colSpan={header.colSpan}*!/*/}
                                {/*            /!*    className="py-3.5 text-center text-sm"*!/*/}
                                {/*            /!*>*!/*/}
                                {/*            /!*    {header.column.columnDef.columnType !== "select" && (*!/*/}
                                {/*            /!*        <IndeterminateCheckbox*!/*/}
                                {/*            /!*            {...{*!/*/}
                                {/*            /!*                checked: table.getIsAllRowsSelected(),*!/*/}
                                {/*            /!*                indeterminate: table.getIsSomeRowsSelected(),*!/*/}
                                {/*            /!*                onChange: table.getToggleAllRowsSelectedHandler(),*!/*/}
                                {/*            /!*            }}*!/*/}
                                {/*            /!*        />*!/*/}
                                {/*            /!*    )}*!/*/}
                                {/*            /!*</th>*!/*/}

                                {/*            <th className="py-3.5 text-center text-sm" key="student">SchülerIn</th>*/}
                                {/*            <th className="py-3.5 text-center text-sm" key="house">Haus</th>*/}
                                {/*            <th className="py-3.5 text-center text-sm" key="school_class">Klasse</th>*/}
                                {/*            {data.weekdays.map((weekday, index) => (*/}
                                {/*                <th className="py-3.5 text-center text-sm" key={index}>*/}
                                {/*                    {weekday}*/}
                                {/*                </th>*/}
                                {/*            ))}*/}
                                {/*            <th className="py-3.5 text-center text-sm" key="edit">Bearbeiten</th>*/}
                                {/*        </tr>*/}
                                {/*        </thead>*/}

                                {/*        <tbody>*/}

                                {/*        {data.rows.map((row, rowIndex) => (*/}
                                {/*            <tr key={rowIndex}*/}
                                {/*                className={"divide-x divide-gray-200 " + (rowIndex % 2 === 0 ? "" : 'bg-gray-50')}>*/}

                                {/*                /!*<td*!/*/}
                                {/*                /!*    key={cell.id}*!/*/}
                                {/*                /!*    className={`py-4 pl-1 text-sm text-center font-medium text-gray-900`}*!/*/}
                                {/*                /!*>*!/*/}
                                {/*                /!*    <div className="px-1">*!/*/}
                                {/*                /!*        <IndeterminateCheckbox*!/*/}
                                {/*                /!*            {...{*!/*/}
                                {/*                /!*                checked: row.getIsSelected(),*!/*/}
                                {/*                /!*                indeterminate: row.getIsSomeSelected(),*!/*/}
                                {/*                /!*                onChange: row.getToggleSelectedHandler(),*!/*/}
                                {/*                /!*            }}*!/*/}
                                {/*                /!*        />*!/*/}
                                {/*                /!*    </div>*!/*/}
                                {/*                /!*</td>*!/*/}

                                {/*                <td className="py-4 pl-4 pr-3 text-sm" key="student">*/}
                                {/*                    <Link className="font-medium text-imsblue-600" to={`/school/students/${row.student.id}`}>{row.student.last_name}, {row.student.first_name}</Link></td>*/}
                                {/*                <td className="py-4 pl-4 pr-3 text-sm"*/}
                                {/*                    key="house">{row.student.house}</td>*/}
                                {/*                <td className="py-4 pl-4 pr-3 text-sm"*/}
                                {/*                    key="school_class">{row.student.school_class}</td>*/}

                                {/*                {row.weekdays.map((weekday, weekdayIndex) => (*/}
                                {/*                    <td className="py-4 pl-4 pr-3 text-sm" key={weekdayIndex}>*/}

                                {/*                        {weekday.weekday_entries.map((entry, entryIndex) => (*/}
                                {/*                            <div key={entryIndex} className="my-2">*/}

                                {/*                                <Link className="font-medium text-imsblue-600"*/}
                                {/*                                    to={`/studierzeit/detail/?studierzeit_time=${entry.studierzeit_time.id}&weekday=${weekday.weekday}&room=${entry.room.id}`}>*/}
                                {/*                                    {entry.studierzeit_time.__str__} <br/>*/}
                                {/*                                    {entry.room.name}*/}
                                {/*                                </Link>*/}
                                {/*                            </div>*/}

                                {/*                        ))}*/}
                                {/*                    </td>))}*/}

                                {/*                <td className="py-4 pl-4 pr-3 text-sm" key="edit">*/}
                                {/*                    <EditButton iconOnly={true}*/}
                                {/*                                link={`/studierzeit/students/${row.student.id}/edit`}/>*/}
                                {/*                </td>*/}
                                {/*            </tr>*/}
                                {/*        ))}*/}

                                {/*        </tbody>*/}

                                {/*    </table>}*/}
                                {/*</div>*/}

                            </div>
                        </div>
                    </div>
                </div>
            </SimplePage>

        </>
    );
}

function Slot({slot}) {
    let tdClassNames = ""
    return <td className={"py-4 pl-4 pr-3 text-sm " + tdClassNames}>

        <div className="flex gap-2">

            <ListButton
                link={`/studierzeit/detail/?studierzeit_time=${slot.studierzeit_time_id}&period=${slot.period_id}&weekday=${slot.weekday}&room=${slot.room_id}`}
                iconOnly={true} rounded={true}/>

            {slot.attendance_entry_id ?
                <EditButton link={`/studierzeit/attendance/${slot.attendance_entry_id}/edit`} iconOnly={true}
                            withBackground={true}/>
                : <AddButton
                    link={`/studierzeit/attendance/new/?studierzeit_time=${slot.studierzeit_time_id}&period=${slot.period_id}&date=${slot.date}&room=${slot.room_id}`}/>}

        </div>

        {slot.occupied_seats} belegt

        {slot.available_seats !== null &&
            <div className="mt-2 text-sm text-center text-gray-500">{slot.available_seats} freie Pl.</div>}

    </td>
}
