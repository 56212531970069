import {SimplePage} from "../../elements/Pages";
import React from "react";

import {useQuery} from "@tanstack/react-query";
import {useAuth} from "../../../contexts/AuthContext";
import ListViewTable, {TableDownloadLink, TableLink,} from "../../elements/tables/ListViewTable";
import checkPermission from "../../../utils/permissions";
import {
    getParticipantListPDF,
    getParticipantsExcel,
    getProjectParticipationExcel,
    getProjects,
    getProjectsExcel,
} from "../../../api/projects";
import {CalendarIcon, DocumentCheckIcon,} from "@heroicons/react/20/solid";
import {DownloadButton} from "../../elements/Buttons";

const linkColumns = [
    {
        accessorKey: "id",
        id: "particpants_list",
        cell: (info) => (
            <TableDownloadLink
                fileName={`Teilnehmerliste.pdf`}
                apiFunction={() => getParticipantListPDF(info.getValue())}
            />
        ),
        header: "",
        enableColumnFilter: false,
        disableRowLink: true,
    },
    {
        accessorKey: "id",
        id: "view_attendance",
        cell: (info) => (
            <TableLink
                label={
                    <>
                        <CalendarIcon
                            className="inline align-middle h-5 w-5"
                            aria-hidden="true"
                        />
                    </>
                }
                to={`/projects/attendance/projects/${info.getValue()}`}
            />
        ),
        header: "",
        enableColumnFilter: false,
        disableRowLink: true,
    },
    {
        accessorKey: "id",
        id: "create_attendance",
        cell: (info) => (
            <TableLink
                label={
                    <>
                        <DocumentCheckIcon
                            className="inline align-middle h-5 w-5"
                            aria-hidden="true"
                        />
                    </>
                }
                to={`/projects/attendance/new/${info.getValue()}/`}
            />
        ),
        header: "",
        enableColumnFilter: false,
        disableRowLink: true,
    },
]
const columns = [
    {
        accessorKey: "name",
        id: "name",
        header: "Name",
        classNames: "font-semibold text-gray-900",
        enableColumnFilter: false,
    },
    {
        accessorKey: "categories",
        id: "categories",
        header: "Kategorien",
        columnType: "m2m",
        labelAttr: "name",
        enableColumnFilter: true,
        headerClassNames: "medium-filter",
        filterFn: "containsString",
    },
    {
        accessorKey: "project_leaders",
        id: "project_leaders",
        header: "Projektleiter",
        columnType: "m2m",
        labelAttr: "full_name",
        enableColumnFilter: true,
        headerClassNames: "medium-filter",
        filterFn: "containsString",
    },
    {
        accessorKey: "grades",
        id: "grades",
        header: "Klassen",
        columnType: "m2m",
        labelAttr: "name",
        enableColumnFilter: true,
        headerClassNames: "narrow-filter",
        filterFn: "containsString",
    },
    {
        accessorKey: "get_project_times_str",
        id: "get_project_times_str",
        header: "Zeit",
        headerClassNames: "medium-filter",
        enableColumnFilter: false,
    }
];

const participantsColumn = {
    accessorKey: "active_participants",
    id: "active_participants",
    header: "Teilnehmer",
    columnType: "m2m",
    separator: " <br/>",
    labelAttr: "full_name",
    enableColumnFilter: false,
}

export default function ProjectList() {
    const {permissions} = useAuth();

    const canViewParticipation = checkPermission(permissions, "projects.view_projectparticipation");

    const {isLoading, isError, isSuccess, data, error, refetch} = useQuery(
        ["projects"],
        () => getProjects(),
        {refetchInterval: 1000 * 60 * 60 * 24}
    );

    function getRowLink(row) {
        return `/projects/${row.original.id}/`;
    }

    let tableColumns
    if (canViewParticipation) {
        tableColumns = [...linkColumns, ...columns, participantsColumn]
    } else {
        tableColumns = [...columns]
    }

    return (
        <SimplePage>
            <div className="px-4 sm:px-6 lg:px-8">
                <div className="sm:flex sm:items-center">
                    <div className="sm:flex-auto">
                        <h3 className="text-lg leading-6 font-medium text-gray-900">
                            Projekte
                        </h3>
                    </div>
                    <div className="mt-4 sm:mt-0 sm:ml-16 flex sm:flex-none gap-2">
                        {canViewParticipation && <DownloadButton
                            apiFunction={() => getProjectsExcel()}
                            fileName="Projekte.xls"
                            label="Projektliste exportieren"
                        />}

                        {canViewParticipation && <DownloadButton
                            apiFunction={() => getProjectParticipationExcel()}
                            fileName="Projekte mit Teilnehmern.xls"
                            label="Projekte mit Teilnehmern"
                        />}

                        {canViewParticipation && <DownloadButton
                            apiFunction={() => getParticipantsExcel()}
                            fileName="Projekteteilnahme.xls"
                            label="Teilnahme exportieren"
                        />}
                    </div>
                </div>
                <div className="flex flex-col">
                    <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
                        <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                            {isSuccess && data && (
                                <ListViewTable
                                    data={data}
                                    columns={tableColumns}
                                    isLoading={isLoading}
                                    disableGlobalSearch={false}
                                    getRowLink={getRowLink}
                                />
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </SimplePage>
    );
}
