import React from 'react';
import {Route, Routes} from "react-router-dom";
import CertificateRemarks from "./CertificateRemarks";
import GenerateCertificates from "./GenerateCertificates";
import EditCertificateRemarksAndConductGrades from "./EditCertificateRemarksAndConductGrades";
import ClassTeacherDetail from "./ClassTeacherDetail";
import ClassTeachers from "./ClassTeachers";
import CertificatesSchoolClassList from "./CertificatesSchoolClassList";


function CertificatesRouter() {

    return (
        <Routes>
            
            <Route path="/assessment-periods/:assessmentPeriodId/certificate-remarks" element={<CertificateRemarks />} />
            <Route path="/assessment-periods/:assessmentPeriodId/certificate-remarks/students/:studentId/edit" element={<EditCertificateRemarksAndConductGrades />} />

            <Route path="/assessment-periods/:assessmentPeriodId/class-teachers" element={<ClassTeachers />} />
            <Route path="/assessment-periods/:assessmentPeriodId/class-teachers/:classTeacherId" element={<ClassTeacherDetail />} />

            <Route path="/assessment-periods/:assessmentPeriodId/certificates" element={<GenerateCertificates />} />

            <Route path="/assessment-periods/:assessmentPeriodId/certificates/by-school-class" element={<CertificatesSchoolClassList />} />
            <Route path="/assessment-periods/:assessmentPeriodId/certificates/:schoolClassId" element={<GenerateCertificates />} />

        </Routes>
    )
}


export default function Certificates() {
    return <CertificatesRouter />
}
