import {SimplePage} from "../../elements/Pages";
import React from "react";

import {useQuery} from "@tanstack/react-query";
import {useParams} from "react-router-dom";
import {assessmentApi} from "../../../api/assessments";
import AssessmentDetail from "./AssessmentDetail";
import {SecondaryButton} from "../../elements/Buttons";


export default function SingleAssessmentDetail() {

    const {assessmentId} = useParams()
    const {isLoading, isError, isSuccess, data: assessment, error, refetch} = useQuery(
        ["assessment_detail", assessmentId],
        () => assessmentApi('get', assessmentId),
    )

    if (!assessment) return <></>

    return (
        <SimplePage>
            <div className="px-4 sm:px-6 lg:px-8">
                <div className="sm:flex sm:items-center">
                    <div className="sm:flex-auto">
                        <h3 className="text-lg leading-6 font-medium text-gray-900">
                            Beurteilung {assessment.assessment_period.time_period.label}
                        </h3>
                    </div>
                    <div className="mt-4 sm:mt-0 sm:ml-16 flex gap-2">
                        <SecondaryButton link={`/assessments/assessment-periods/${assessment.assessment_period.id}/courses/${assessment.course.id}/courses`} label="Kursansicht" />
                    </div>
                </div>
                <div className="px-2">
                    <div className="flex flex-col">
                        <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
                            <div className="py-2 align-middle md:px-6 lg:px-8 max-w-6xl">
                                {assessment &&
                                    <AssessmentDetail assessment={assessment} inCourse={true} inStudent={true} canEdit={assessment.can_edit} />
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </SimplePage>
    );
}
