import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/20/solid'

export default function Pagination({ page, setPage, totalResults, pageSize, compact }) {
    const totalPages = Math.ceil(totalResults / pageSize)

    if (totalPages <= 1 || !totalResults) {
        return <></>
    }

    return (
        <div className="flex items-center justify-between border-t border-gray-200 bg-white py-3">
            <div className="flex flex-1 justify-between sm:hidden">
                <a
                    href="#"
                    className="relative inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50"
                >
                    Zurück
                </a>
                <a
                    href="#"
                    className="relative ml-3 inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50"
                >
                    Weiter
                </a>
            </div>

            <div className={"hidden sm:flex sm:flex-1 sm:items-center " + (compact ? "sm:justify-around" : "sm:justify-between")}>
                {!compact && <div>
                    <p className="text-sm text-gray-700">
                        Zeige <span className="font-medium">{pageSize * (page -1) + 1}</span> bis <span className="font-medium">{Math.min(totalResults, pageSize * (page))}</span> von{' '}
                        <span className="font-medium">{totalResults}</span> Einträgen
                    </p>
                </div>}
                <div>
                    <nav className="isolate inline-flex -space-x-px rounded-md shadow-sm" aria-label="Pagination">
                        <a
                            href="#"
                            onClick={() => {if (page > 1) setPage(page - 1)}}
                            className="relative inline-flex items-center rounded-l-md px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0"
                        >
                            <span className="sr-only">Zurück</span>
                            <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
                        </a>

                        {/* First page */}
                        {/* Current: "z-10 bg-imsblue-600 text-white focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-imsblue-600", Default: "text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:outline-offset-0" */}
                        <a
                            href="#"
                            onClick={() => setPage(1)}
                            aria-current={page === 1 ? "page" : ""}
                            className={page === 1 ? "relative z-10 inline-flex items-center bg-imsblue-600 px-4 py-2 text-sm font-semibold text-white focus:z-20 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-imsblue-600"
                                : "relative inline-flex items-center px-4 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0"}
                        >
                            1
                        </a>

                        {/* dots left of current page */}
                        {page > 3 && totalPages > 3 && <a
                            key="dots1"
                            href="#"
                            aria-current=""
                            className="cursor-default relative inline-flex items-center px-4 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0"
                        >
                            ...
                        </a>}

                        {/* One link per page between 1 and totalPages */}
                        {[-1, 0, 1, 2].map((x, i) => {

                                if (page + x > 1 && page + x < totalPages) {
                                    return <a
                                        key={i}
                                        href="#"
                                        onClick={() => setPage(x + page)}
                                        aria-current={0 === x ? "page" : ""}
                                        className={0 === x ? "relative z-10 inline-flex items-center bg-imsblue-600 px-4 py-2 text-sm font-semibold text-white focus:z-20 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-imsblue-600"
                                            : "relative inline-flex items-center px-4 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0"}
                                    >
                                        {page + x}
                                    </a>
                                }
                            }
                        )}

                        {/* dots right of current page */}
                        {page < totalPages - 1 && totalPages > 3 && <a
                            key="dots2"
                            href="#"
                            aria-current=""
                            className="cursor-default relative inline-flex items-center px-4 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0"
                        >
                            ...
                        </a>}

                        <a
                            href="#"
                            onClick={() => setPage(totalPages)}
                            aria-current={page === totalPages ? "page" : ""}
                            className={page === totalPages ? "relative z-10 inline-flex items-center bg-imsblue-600 px-4 py-2 text-sm font-semibold text-white focus:z-20 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-imsblue-600"
                                : "relative inline-flex items-center px-4 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0"}
                        >
                            {totalPages}
                        </a>
                        <a
                            href="#"
                            onClick={() => {if (page < totalPages) setPage(page + 1)}}
                            className="relative inline-flex items-center rounded-r-md px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0"
                        >
                            <span className="sr-only">Weiter</span>
                            <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
                        </a>
                    </nav>
                </div>
            </div>
        </div>
    )
}