import http from "./../services/httpService";


export async function getAttendedOptions() {
    const resp = await http.get('/attended_options/')
    return resp.data
}

export async function getMaritalStatusOptions() {
    const resp = await http.get('/utils/marital_status_options/')
    return resp.data
}

export function getFiltersString(filters) {
    return filters.map(el => `${el.id}=${el.value}`).join('&')
}

const filtersMap = {
    categories: 'categories__name',
    house: 'student__house',
    school_class: 'student__school_class',
    get_visibility_display: 'visibility',
}

export function getRealFiltersName(filters) {
    return filters.map(el => ({id: filtersMap[el.id], value: el.value}))
}


const downloadFiltersMap = {
    categories: 'categories__name',
    target_groups: 'target_groups__name',
    created_by_full_name: 'created_by__id'
}

export function getDownloadFiltersName(filters) {
    return filters.map(el => ({id: downloadFiltersMap[el.id], value: el.value}))
}
