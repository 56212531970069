import {SimplePage} from "../../elements/Pages";
import React from "react";

import {useQuery} from "@tanstack/react-query";
import {useAuth} from "../../../contexts/AuthContext";
import ListViewTable from "../../elements/tables/ListViewTable";
import {getAmbassadors} from "../../../api/alumni";

const columns = [
    {
        accessorFn: (row) => `${row.student.last_name}, ${row.student.first_name} ${row.student.student_number}`,
        id: "student_search",
        header: "Name",
        hide: true,
        enableColumnFilter: false,
    },
    {
        accessorKey: 'student',
        id: 'student',
        header: 'Name',
        columnType: 'alumni_student',
        enableColumnFilter: false,
        sortingFn: "lastNameSorting",
    },
    {
        accessorKey: "ambassador_for",
        id: "ambassador_for",
        header: "Stadt",
        enableColumnFilter: false,
    }
];

export default function Ambassadors() {

    const {permissions} = useAuth();

    const {isLoading, isError, isSuccess, data, error, refetch} = useQuery(
        ["alumni_ambassadors"],
        () => getAmbassadors(),
        {refetchInterval: 1000 * 60 * 60}
    )

    function getRowLink(row) {
        return `/alumni/${row.original.id}`;
    }

    if (!data) return <></>
    return (
        <SimplePage>
            <div className="px-4 sm:px-6 lg:px-8">
                <div className="sm:flex sm:items-center">
                    <div className="sm:flex-auto">
                        <h3 className="text-lg leading-6 font-medium text-gray-900">
                            Botschafter
                        </h3>
                        <p className="text-sm text-gray-600">Das sind die Botschafter unseres Ehemaligennetzwerks. Wenn
                            ihr in ihre Stadt kommt, könnt ihr sie kontaktieren, um wichtige Tipps zur Stadt, zur Uni
                            oder zu den Chancen auf eine gute Wohnung zu erhalten. Stay connected und treffe deinen
                            Botschafter beim nächsten Stammtisch!</p>
                        <p className="text-sm text-gray-600">Deine Stadt fehlt hier? Werde auch du Botschafter und sei
                            ein Teil unseres starken Netzwerks. Jetzt gleich eine E-Mail schreiben an
                            m.lehmann@schlosstorgelow.de. Herzlichen Dank!

                        </p>
                    </div>
                    <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
                    </div>
                </div>
                <div className="flex flex-col">
                    <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
                        <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                            {isSuccess && data && (
                                <ListViewTable
                                    data={data}
                                    columns={columns}
                                    isLoading={isLoading}
                                    disableGlobalSearch={false}
                                    getRowLink={getRowLink}
                                />
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </SimplePage>
    );
}
