import React, { useState } from "react";
import { classNames } from "../../../utils/misc";

const Accordion = ({ title, id, children }) => {
  const [expanded, setExpanded] = useState(false);
  return (
    <div id="accordion-collapse" data-accordion="collapse" className="w-full">
      <h2 id={`accordion-collapse-heading-${id}`}>
        <button
          type="button"
          className="flex items-center justify-between w-full p-3 font-medium text-left text-gray-500 border  border-gray-200 rounded-xl dark:border-gray-700 dark:text-gray-400"
          data-accordion-target={`accordion-collapse-body-${id}`}
          aria-controls={`accordion-collapse-body-${id}`}
          onClick={() => setExpanded(!expanded)}
        >
          <span className="capitalize">{title}</span>
          <svg
            data-accordion-icon
            className={classNames(
              "w-6 h-6 shrink-0",
              expanded ? "rotate-180" : ""
            )}
            fill="currentColor"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
              clipRule="evenodd"
            ></path>
          </svg>
        </button>
      </h2>
      <div
        id={`accordion-collapse-body-${id}`}
        className={classNames(expanded ? "block" : "hidden", "p-3")}
        aria-labelledby={`accordion-collapse-heading-${id}`}
      >
        {children}
      </div>
    </div>
  );
};

export default Accordion;
