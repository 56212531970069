import {SimplePage} from "../../elements/Pages";
import React from 'react';

import {useQuery} from '@tanstack/react-query'
import ListViewTable, {TableDownloadLink, TableLink} from "../../elements/tables/ListViewTable";
import {getFlextimeEntriesPDF, getFlextimeStaffList} from "../../../api/staff";

import config from "../../../constants";
import checkPermission from "../../../utils/permissions";
import {Link} from "react-router-dom";
import {useAuth} from "../../../contexts/AuthContext";

const columns = [
    {
        accessorKey: 'full_name',
        id: 'staff_search',
        header: 'Mitarbeiter',
        hide: true,
        enableColumnFilter: false,
    },
    {
        accessorFn: row => row,
        id: 'staff',
        header: 'Mitarbeiter',
        columnType: 'staff',
        enableColumnFilter: false,
    },
    {
        accessorKey: 'get_flextime_role',
        id: 'get_flextime_role',
        header: 'Funktion',
        enableColumnFilter: true,
        headerClassNames: "medium-filter",
        filterFn: "containsString",
    },
    {
        accessorKey: 'get_flextime_balance',
        id: 'get_flextime_balance',
        header: 'Kontostand',
        enableColumnFilter: false,
    },
    {
        accessorKey: 'id',
        id: 'pdf',
        disableRowLink: true,
        cell: info => <TableDownloadLink
            label="PDF"
            fileName={`Flextime.pdf`}
            apiFunction={() => getFlextimeEntriesPDF(info.getValue())} />,
        header: 'PDF', // TODO doesn't work because we have a row link so that gets clicked
        enableColumnFilter: false,
    }
]


export default function FlextimeStaffList() {

    const {permissions} = useAuth()
    const {
        isLoading,
        isError,
        isSuccess,
        data,
        error,
        refetch
    } = useQuery(['flextime_staff'], getFlextimeStaffList, {refetchInterval: 1000 * 60 * 60 * 24})

    function getRowLink(row) {
        return `/staff/flextime/${row.original.id}/`
    }

    return (

        <SimplePage>
            <div className="px-4 sm:px-6 lg:px-8">
                <div className="sm:flex sm:items-center">
                    <div className="sm:flex-auto">
                        <h3 className="text-lg leading-6 font-medium text-gray-900">Flextime</h3>
                    </div>
                    <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
                        {checkPermission(permissions, "staff.add_flextimeentry") && (
                            <Link to="/staff/flextime/entries/new">
                                <button
                                    type="button"
                                    className="inline-flex items-center justify-center rounded-md border border-transparent bg-imsblue-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-imsblue-700 focus:outline-none focus:ring-2 focus:ring-imsblue-500 focus:ring-offset-2 sm:w-auto"
                                >
                                    Neuer Eintrag
                                </button>
                            </Link>
                        )}
                    </div>
                </div>
                <div className="flex flex-col">
                    <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">

                        <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                            {isSuccess && data && <ListViewTable data={data}
                                                                 columns={columns}
                                                                 isLoading={isLoading}
                                                                 disableGlobalSearch={false}
                                                                 getRowLink={getRowLink}
                            />}
                        </div>
                    </div>
                </div>
            </div>
        </SimplePage>
    )
}
