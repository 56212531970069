import React from "react";
import { SimplePage } from "../../elements/Pages";

import { useQuery } from "@tanstack/react-query";
import {
    getStudentListCurrentWithNewGrades, getStudentListCurrentWithNewGradesExcel
} from "../../../api/plupus";
import { useAuth } from "../../../contexts/AuthContext";
import { DownloadButton } from "../../elements/Buttons";
import ListViewTable from "../../elements/tables/ListViewTable";


const columns = [
    {
        accessorKey: "points_rank",
        id: "points_rank",
        header: "Ranking",
        enableColumnFilter: false,
    },
    {
        accessorFn: (row) =>
            `${row.last_name}, ${row.first_name} ${row.student_number}`,
        id: "student_search",
        header: "SchülerIn",
        hide: false,
        enableColumnFilter: false,
    },
    {
        accessorKey: "school_class",
        id: "school_class",
        header: "Klasse",
        enableColumnFilter: true,
        headerClassNames: "narrow-filter",
        sortingFn: "schoolClassSorting",
        filterFn: "containsString"
    },
    {
        accessorKey: "grade",
        id: "grade",
        header: "Stufe",
        enableColumnFilter: true,
        headerClassNames: "narrow-filter",
        filterFn: "containsString"
    },
    {
        accessorKey: "house",
        id: "house",
        header: "Mentorat",
        enableColumnFilter: true,
        headerClassNames: "narrow-filter",
        filterFn: "containsString"
    },
    {
        accessorKey: "points_actual",
        id: "points_actual",
        header: "Punkte",
        sortingFn: "basic",
        enableColumnFilter: false,
    },
    {
        accessorKey: "rating_actual",
        id: "rating_actual",
        header: "Stufe",
        enableColumnFilter: false
    },
    {
        accessorKey: "points_forecast_with_grades",
        id: "points_forecast_with_grades",
        sortingFn: "basic",
        header: "Punkte (Prognose mit Noten)",
        enableColumnFilter: false,
    },
    {
        accessorKey: "rating_forecast_with_grades",
        id: "rating_forecast_with_grades",
        header: "Status (Prognose mit Noten)",
        enableColumnFilter: false,
    }
];

export default function StudentsCurrentWithNewGrades() {
    const {permissions} = useAuth();

    const {isLoading, isError, isSuccess, data, error, refetch} = useQuery(
        ["plupus_current_with_new_grades"],
        () => getStudentListCurrentWithNewGrades()
    );

    function getRowLink(row) {
        return `/plupus/v2/students/${row.original.id}/`;
    }

    function getTrClassNames(row, index) {
        return row.original.rating_actual == "Grün" ? 'bg-green-50' : row.original.rating_actual == "Orange" ? 'bg-yellow-50' : 'bg-blue-50'
    }

    return (
        <SimplePage>
            <div className="px-4 sm:px-6 lg:px-8">
                <div className="sm:flex sm:items-center">
                    <div className="sm:flex-auto">
                        <h3 className="text-lg leading-6 font-medium text-gray-900">
                            Aktuelle Plupus nach Schülern (auf Basis bereits erteilter Noten)
                        </h3>
                    </div>
                    <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">

                        <DownloadButton
                            apiFunction={() => getStudentListCurrentWithNewGradesExcel()}
                            fileName={`Plupus.xls`}
                            label="Liste herunterladen"/>
                    </div>
                </div>
                <div className="flex flex-col">
                    <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">

                        <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                            {isSuccess && data && (
                                <ListViewTable
                                    data={data}
                                    columns={columns}
                                    isLoading={isLoading}
                                    disableGlobalSearch={false}
                                    getTrClassNames={getTrClassNames}
                                    getRowLink={getRowLink}
                                />
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </SimplePage>
    );
}
