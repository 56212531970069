import http from "./../services/httpService";
import {genericApi} from "./generic";
import httpServiceDownloads from "../services/httpServiceDownloads";


export async function getCareerModuleCategories() {
    const resp = await http.get(`/career_module_categories/`);
    return resp.data;
}

export async function careerModuleApi(method, id, data) {
    return genericApi('/career_modules/', method, id, data)
}

export async function attendanceApi(method, id, data) {
    return genericApi('/career_attendances/', method, id, data)
}

export async function updateCareerModuleAttendances(id, data) {
    const resp = await http.post(`/career_modules/${id}/update_attendances/`, data);
    return resp.data;
}

export async function getCareerStudents() {
    const resp = await http.get(`/career/students/`);
    return resp.data;
}

export async function getCareerStudentsExcel() {
    const resp = await httpServiceDownloads.get(`/career/students/excel/`);
    return resp.data;
}

export async function getCareerStudentDetail(studentId) {
    const resp = await http.get(`/career/students/${studentId}/`);
    return resp.data;
}
