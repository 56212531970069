import {genericApi} from "./generic";
import http from "../services/httpService";
import httpServiceDownloads from "../services/httpServiceDownloads";

export async function assessmentPeriodApi(method, id, data) {
    return genericApi('/assessment_periods/', method, id, data)
}

export async function assessmentApi(method, id, data) {
    return genericApi('/assessments_vs/', method, id, data)
}

export async function getAssessmentOptions(id) {
    const resp = await http.get(`/assessments_edit/${id}/options/`)
    return resp.data
}

export async function getAssessmentForEdit(id) {
    const resp = await http.get(`/assessments_edit/${id}/`)
    return resp.data
}

export async function patchAssessment(id, data) {
    const resp = await http.patch(`/assessments_edit/${id}/`, data)
    return resp.data
}

export async function getAssessmentPeriods(schoolYearId) {
    const resp = await http.get(`/assessment_periods/` + (schoolYearId ? `?school_year_id=${schoolYearId}` : ''))
    return resp.data
}

export async function getOpenAssessments(assessmentPeriodId) {
    const resp = await http.get(`/assessment_periods/${assessmentPeriodId}/open_assessments/`)
    return resp.data
}

export async function finalizeOpenAssessments(assessmentPeriodId) {
    const resp = await http.post(`/assessment_periods/${assessmentPeriodId}/finalize_all/`)
    return resp.data
}

export async function getCoursesForAssessmentPeriod(assessmentPeriodId) {
    const resp = await http.get(`/assessment_periods/${assessmentPeriodId}/courses/`)
    return resp.data
}

export async function getMyCoursesForAssessmentPeriod(assessmentPeriodId) {
    const resp = await http.get(`/assessment_periods/${assessmentPeriodId}/my_courses/`)
    return resp.data
}

export async function getAndGenerateAssessments(assessmentPeriodId, courseId, generate, mini) {
    const resp = await http.get(`/assessments/get_and_generate_assessments/?course_id=${courseId}&assessment_period_id=${assessmentPeriodId}&generate=${generate}&mini=${mini}`)
    return resp.data
}

export async function bulkInsertTextIntoAssessments(assessmentPeriodId, courseId, data) {
    const resp = await http.post(`/assessments/bulk_insert_text/?course_id=${courseId}&assessment_period_id=${assessmentPeriodId}`, data)
    return resp.data
}

export async function getAssessmentsForTeacher(assessmentPeriodId, staffId) {
    const resp = await http.get(`/assessments/assessments_for_teacher/?staff_id=${staffId}&assessment_period_id=${assessmentPeriodId}`)
    return resp.data
}
export async function getAssessmentsForTeacherTextOnlyPDF(assessmentPeriodId, staffId) {
    const resp = await httpServiceDownloads.get(`/assessments/assessments_for_teacher_text_only_pdf/?staff_id=${staffId}&assessment_period_id=${assessmentPeriodId}`)
    return resp.data
}

export async function getStudentsForAssessmentPeriod(assessmentPeriodId) {
    const resp = await http.get(`/assessment_periods/${assessmentPeriodId}/students/`)
    return resp.data
}

export async function getStudentsForAssessmentPeriodPost(assessmentPeriodId, schoolClassId) {
    const resp = await http.get(`/assessment_periods/${assessmentPeriodId}/students_post/` + (schoolClassId ? `?school_class_id=${schoolClassId}` : ''))
    return resp.data
}

export async function sendForSelectedStudents(assessmentPeriodId, data) {
    const resp = await http.post(`/assessment_periods/${assessmentPeriodId}/send_for_selected_students/`, data)
    return resp.data
}

export async function sendForAllStudents(assessmentPeriodId, data) {
    const resp = await http.post(`/assessment_periods/${assessmentPeriodId}/send_for_all_students/`, data)
    return resp.data
}

export async function getAssessmentsForStudent(assessmentPeriodId, studentId) {
    const resp = await http.get(`/assessments_vs/?student_id=${studentId}&assessment_period_id=${assessmentPeriodId}`)
    return resp.data
}

export async function getAssessmentPermissions() {
    const resp = await http.get(`/assessments/assessment_permissions/`)
    return resp.data
}

export async function getStudentAssessmentPeriods(studentId) {
    const resp = await http.get(`/assessments/assessment_periods_for_student/${studentId}/`)
    return resp.data
}

export async function getConductGradeValues() {
    const resp = await http.get(`/conduct_grade_values/`)
    return resp.data
}

export async function getConductGradeListDataExcel(data) {
    const resp = await httpServiceDownloads.post(`/assessments/kopfnotenlisten/`, data)
    return resp.data
}

export async function getAssessmentWidgetData() {
    const resp = await http.get(`/assessments/assessment_widget_data/`)
    return resp.data
}