import {useParams} from "react-router-dom";
import {React} from "react";
import {useQuery} from "@tanstack/react-query";
import {extraExamApi, getExtraExamPDF} from "../../../api/school";
import {DownloadButton, EditButton, SecondaryButton} from "../../elements/Buttons";
import ListViewTable, {TableLink} from "../../elements/tables/ListViewTable";
import {DetailPage} from "../../elements/Pages";
import {formatDate} from "../../../utils/formatters";
import {getStudentListExcel} from "../../../api/courses";


let columns = [
    {
        accessorFn: (row) =>
            `${row.student.last_name}, ${row.student.first_name} ${row.student.student_number}`,
        id: "student_search",
        header: "SchülerIn",
        hide: true,
        enableColumnFilter: false,
    },
    {
        accessorFn: (row) => row.student,
        id: "student",
        header: "SchülerIn",
        columnType: "student",
        enableColumnFilter: false,
        sortingFn: "lastNameSorting",
    },
    {
        accessorKey: "course",
        id: "course",
        header: "Kurs",
        enableColumnFilter: false,
        cell: ({row, value}) => (
            <TableLink
                label={row.original.course.course_number}
                to={`/courses/${row.original.course.id}`}
            />
        ),
        disableRowLink: true
    },
    {
        accessorKey: "course.school_subject",
        id: "subject",
        header: "Fach",
        enableColumnFilter: false,
    },
    {
        accessorKey: "topic",
        id: "topic",
        header: "Thema",
        enableColumnFilter: false,
    },
    {
        accessorKey: "work_time",
        id: "work_time",
        header: "Arbeitszeit",
        enableColumnFilter: false,
    },
    {
        accessorKey: "teacher",
        id: "teacher",
        header: "FachlehrerIn",
        cell: (cell) => (
            <>{cell.getValue() &&
                <TableLink
                    label={cell.getValue().full_name}
                    to={`/staff/${cell.getValue().id}`}
                />}
            </>
        ),
        enableColumnFilter: false,
        disableRowLink: true
    }
];

export default function ExtraExamDetail() {

    const {id} = useParams()

    const {data: extraExam} = useQuery(
        ["extra_exams", id],
        () => extraExamApi('get', id)
    )

    if (!extraExam) return <></>;
    return (
        <DetailPage>

            {/* Page header */}
            <div
                className="max-w-3xl mx-auto px-4 sm:px-6 md:flex md:items-center md:justify-between md:space-x-5 lg:max-w-7xl lg:px-8">
                <div className="flex items-center space-x-5">
                    <div>
                        <h1 className="text-2xl font-bold text-gray-900">Nachholklausur {formatDate(extraExam.date)}</h1>
                    </div>
                </div>
                <div
                    className="mt-6 flex flex-col-reverse justify-stretch space-y-4 space-y-reverse sm:flex-row-reverse sm:justify-end sm:space-x-reverse sm:space-y-0 sm:space-x-3 md:mt-0 md:flex-row md:space-x-3">

                    <DownloadButton
                        apiFunction={() => getExtraExamPDF(id)}
                        fileName={`Nachholklausur.pdf`}
                        label="PDF herunterladen"/>

                    <EditButton
                        link={`/class-register/extra-exams/${id}/edit`}
                        label="Bearbeiten"
                    />

                </div>
            </div>

            <div
                className="mt-8 max-w-3xl mx-auto grid grid-cols-1 gap-6 sm:px-6 lg:max-w-7xl lg:grid-flow-col-dense lg:grid-cols-3">
                <div className="space-y-6 lg:col-start-1 lg:col-span-3">

                    <section aria-labelledby="applicant-information-title">
                        <div className="bg-white overflow-hidden">
                            {/* Schüler */}
                            {extraExam?.extra_exam_lines &&
                                <ListViewTable
                                    data={extraExam.extra_exam_lines}
                                    columns={columns}
                                    disableGlobalSearch={true}
                                    getRowLink={null}
                                />}
                        </div>
                    </section>

                </div>

            </div>

        </DetailPage>
    )
}