import {NarrowPage} from "../../elements/Pages";
import {React, useEffect, useState} from "react";
import Joi from "joi"
import {Checkbox, DateInput, Input, MultiSelect, TextArea} from "../../elements/forms/Inputs"
import {FormSectionContent} from "../../elements/forms/FormLayout"
import Form, {baseHandleChange, baseHandleDateChange} from "../../elements/forms/Form"
import {useNavigate, useParams} from "react-router-dom";
import {toast} from "react-toastify";
import {
    addFileToCourseMaterial,
    deleteCourseMaterial,
    deleteCourseMaterialFile,
    getCourse,
    getCourseMaterial,
    patchCourseMaterial,
    postCourseMaterial
} from "../../../api/courses";
import {DeleteButton} from "../../elements/DeleteButton";
import {useQueryClient} from "@tanstack/react-query";
import moment from "moment/moment";


export default function CourseMaterialCreateEdit() {

    const queryClient = useQueryClient()
    const {id, courseId} = useParams()
    const [data, setData] = useState({
        id: null,
        title: "",
        description: null,
        visible_from: moment(new Date()).format("YYYY-MM-DD"),
        visible_for_all_students: true,
        target_students: [],
        course: {id: parseInt(courseId) || null},
        course_material_files: []
    })
    const [course, setCourse] = useState()

    const [selectedFiles, setSelectedFiles] = useState([]);
    const [isFilePicked, setIsFilePicked] = useState(false);

    const [errors, setErrors] = useState({}) // validation errors

    let navigate = useNavigate();

    useEffect(() => {
        loadData()
    }, [id])

    async function loadData() {

        let newData
        let course
        if (id) {
            newData = await getCourseMaterial(id)
            course = await getCourse(newData.course.id)
        } else if (courseId) {
            course = await getCourse(courseId)
            newData = {...data}
        }

        setData(newData)
        setCourse(course)
    }

    const schema = {
        title: Joi.string().min(3)
    }

    const title = (id || data.id) ? "Kursmaterial bearbeiten" : "Kursmaterial hochladen"
    const successMessage = (id || data.id) ? "Kursmaterial aktualisiert" : "Kursmaterial hochgeladen"

    async function onSave() {

        // saving the instance first
        let newData
        if (!data.id) newData = await postCourseMaterial(data)
        else newData = await patchCourseMaterial(data)

        queryClient.invalidateQueries(['course_materials_and_homework', `${data.course.id}`] )

        // then add the files
        if (isFilePicked) {
            for (let i = 0; i < selectedFiles.length; i++) {
                const formData = new FormData();
                formData.append('file', selectedFiles[i]);
                const newCourseMaterialFile = await addFileToCourseMaterial(formData, newData.id)
                newData.course_material_files.push(newCourseMaterialFile)
            }
            setIsFilePicked(false)
            setSelectedFiles([])
        }
        setData(newData)
        toast.success(successMessage)
        navigate(`/courses/${data.course.id}`)
    }

    async function onDelete() {
        await deleteCourseMaterial(data.id)
        toast.success("Kursmaterial gelöscht.")
        navigate(`/courses/${data.course.id}`)
    }

    function handleChange(evt) {
        baseHandleChange(evt, data, setData, errors, setErrors, schema)
    }

    function handleDateChange(date, path) {
        baseHandleDateChange(date, path, data, setData, errors, setErrors, schema)
    }

    const handleChangeFile = (event) => {
        setSelectedFiles(event.target.files)
        setIsFilePicked(true)
    }

    function handleMultiSelect(selectedOptions) {
        setData({...data, target_students: selectedOptions})
    }

    async function handleCourseMaterialFileDelete(courseMaterialFileId) {
        await deleteCourseMaterialFile(courseMaterialFileId)
        const newData = {...data}
        newData.course_material_files = newData.course_material_files.filter(m => m.id != courseMaterialFileId)
        setData(newData)
        toast.success("Datei gelöscht.")

    }

    return (

        <NarrowPage title={title}>

            {course &&
                <h2 className="text-md font-bold text-gray-900">{course.course_number} {course.school_subject.name}</h2>}

            <Form id="createCourseInstruction" onSave={onSave}
                  onDelete={onDelete}
                  data={data} setData={setData}
                  errors={errors} setErrors={setErrors}
                  successMessage={successMessage}
                  schema={schema}>

                <FormSectionContent>

                    <Input
                        path="title"
                        type="text"
                        label="Titel"
                        className="sm:col-span-6"
                        onChange={handleChange}
                        errors={errors}
                        data={data}
                    />

                    <TextArea
                        path="description"
                        type="text"
                        label="Beschreibung"
                        className="sm:col-span-6"
                        onChange={handleChange}
                        errors={errors}
                        data={data}
                        rows={3}
                    />

                    <DateInput
                        className="col-span-3"
                        path="visible_from"
                        label="Sichtbar ab"
                        onChange={handleDateChange}
                        errors={errors}
                        data={data}
                        todayButton={true}
                        tomorrowButton={true}
                    />

                    <Checkbox
                        path="visible_for_all_students"
                        label="Für alle Schüler im Kurs sichtbar"
                        className="sm:col-span-6"
                        onChange={handleChange}
                        errors={errors}
                        data={data}/>

                    {course && <MultiSelect
                        path="target_students"
                        label="Sichtbar für Schüler"
                        className="sm:col-span-6"
                        options={course.students}
                        valueAttr="id"
                        labelAttr="full_name"
                        onChange={handleMultiSelect}
                        data={data}
                        isClearable={true}
                        valueOnly={false}
                    />}

                    <div className="sm:col-span-6 text-sm">
                        <label className="mb-2 block text-sm font-medium text-gray-900">
                            Dateien hochladen
                        </label>
                        <input multiple={true} type="file" name="file" onChange={handleChangeFile}/> <br/>
                    </div>

                    {data.course_material_files.length > 0 && <div className="sm:col-span-6 text-sm">

                        <label className="mb-2 block text-sm font-medium text-gray-900">
                            Bisherige Dateien
                        </label>

                        <ul role="list">
                            {data.course_material_files.map((mf, index) => {
                                return <li className="py-1 divide-gray-200" key={index}>
                                    {mf.get_file_name} <DeleteButton iconOnly={true}
                                                                     onDelete={() => handleCourseMaterialFileDelete(mf.id)}/>
                                </li>
                            })
                            }
                        </ul>

                    </div>}

                </FormSectionContent>

            </Form>

            <span onClick={() => navigate(`/courses/${data.course.id}`)}
                  className="cursor-pointer float-right mt-2 font-medium text-imsblue-600">Zurück zum Kurs</span>

        </NarrowPage>
    )
}
