import {Page} from "../elements/Pages";
import {React, useEffect, useState} from "react";
import Joi from "joi"
import {useQuery, useQueryClient} from '@tanstack/react-query'
import {DateTimeInput, Input, MultiSelect, Select, TextArea} from "../elements/forms/Inputs"
import {FormSectionContent} from "../elements/forms/FormLayout"
import {getSchoolClasses} from "../../api/school";
import Form, {baseHandleChange, baseHandleDateChange, baseHandleListChange} from "../elements/forms/Form"
import {Link, useNavigate, useParams} from "react-router-dom";
import {toast} from "react-toastify";
import {
    addImageToSchoolEvent,
    cancelSchoolOccurrence,
    getSchoolEventCategories,
    schoolEventApi,
    uncancelSchoolEventOccurrence
} from "../../api/calendar";
import {getRecurringRules} from "../../api/schedule";
import {getActiveStaffAndStudentUsers, getGroups} from "../../api/accounts";
import {getBookableRooms, roomBookingApi} from "../../api/organizational";

import {AvailableRooms, FindRoom} from "../modules/organizational/FindRoom";
import {formatDateTime} from "../../utils/formatters";
import {ArrowTopRightOnSquareIcon, CheckCircleIcon, XCircleIcon} from "@heroicons/react/20/solid";
import {DeleteButton} from "../elements/DeleteButton";
import {AddButton} from "../elements/Buttons";

export default function SchoolEventCreateEdit() {
    const queryClient = useQueryClient()
    const {id} = useParams()

    const [data, setData] = useState({
        id: null,
        title: null,
        start: null,
        end: null,
        end_recurring_period: null,
        rule: null,
        room: null,
        room_bookings: []
    })

    const {data: users} = useQuery(['active_staff_and_students'], getActiveStaffAndStudentUsers, {refetchInterval: 1000 * 60 * 60 * 24})
    const {data: categories} = useQuery(['school_event_categories'], getSchoolEventCategories, {refetchInterval: 1000 * 60 * 60 * 24})
    const {data: rules} = useQuery(['recurring_rules'], getRecurringRules, {refetchInterval: 1000 * 60 * 60 * 24})
    const {data: groups} = useQuery(['groups'], getGroups, {refetchInterval: 1000 * 60 * 60 * 24})
    const {data: schoolClasses} = useQuery(['school_classes'], getSchoolClasses, {refetchInterval: 1000 * 60 * 60 * 24})

    const [availableRooms, setAvailableRooms] = useState([])
    const [selectedRowId, setSelectedRowId] = useState(null)
    const [newRowId, setNewRowId] = useState(1)
    const {data: rooms} = useQuery(["bookable_rooms"], getBookableRooms, {refetchInterval: 1000 * 60 * 60 * 24});

    const [selectedFile, setSelectedFile] = useState();
    const [isFilePicked, setIsFilePicked] = useState(false);

    const [errors, setErrors] = useState({})
    const [roomBookingsErrors, setRoomBookingsErrors] = useState([])
    const [bookingValidationErrors, setBookingValidationErrors] = useState([])

    let navigate = useNavigate();

    useEffect(() => {
        loadData()
    }, [id])

    async function loadData() {
        if (!id) return
        const newData = await schoolEventApi('get', id)
        setData(newData)
    }

    const schema = {
        // student: Joi.object().required(),
        title: Joi.string().required(),
        start: Joi.date().required(),
        end: Joi.date().required().min(
            Joi.ref("start", {
                adjust: (value) => (value ? value.setSeconds(1) : new Date(1970, 1, 1)),
            })
        ).messages({"date.min": "Ende muss nach dem Start liegen"}),
        category: Joi.object().required()
    };

    const title = (id || data.id) ? "Termin bearbeiten" : "Termin eintragen"
    const successMessage = (id || data.id) ? "Termin aktualisiert" : "Termin eingetragen"

    async function onSave() {
        // saving the instance first
        try {
            let newData
            if (!data.id) newData = await schoolEventApi('post', null, data)
            else newData = await schoolEventApi('patch', null, data)
            setBookingValidationErrors([])

            // then add the file
            if (isFilePicked) {
                const formData = new FormData();
                formData.append('file', selectedFile);
                newData = await addImageToSchoolEvent(formData, newData.id)
            }

            setData(newData)
            queryClient.invalidateQueries({ queryKey: ['events_month'] })
            queryClient.invalidateQueries({ queryKey: ['events_week'] })
            toast.success(successMessage)
            navigate(`/calendar?event_id=${newData.id}`)
        } catch (e) {
            // console.log(e.data)
            if (e.data.room_booking_validation_errors) {
                setBookingValidationErrors(e.data.room_booking_validation_errors)
            }
            throw(e)
        }
    }

    async function onDelete() {
        await schoolEventApi('delete', data.id)
        toast.success("Termin gelöscht.")
        navigate(`/calendar`)
    }

    function handleChange(evt) {
        baseHandleChange(evt, data, setData, errors, setErrors, schema)
    }

    function handleDateChange(date, path) {
        baseHandleDateChange(date, path, data, setData, errors, setErrors, schema, true)
    }

    const handleChangeFile = (event) => {
        setSelectedFile(event.target.files[0])
        setIsFilePicked(true)
    }

    function handleMultiSelect(selectedOptions, path) {
        let newData = {...data}
        newData[path] = selectedOptions
        setData(newData)
    }

    function addRow(room) {
        const newRow = {
            id: `N${newRowId}`,
            room: room
        }
        setSelectedRowId(`N${newRowId}`)
        setNewRowId(newRowId + 1)
        setData({
            ...data,
            room_bookings: [...data.room_bookings, newRow]
        })
    }

    function setRoomBookings(roomBookingsData) {
        setData({...data, room_bookings: roomBookingsData})
    }

    function handleRoomChange(evt, rowId) {
        baseHandleListChange(rowId, evt, data.room_bookings, setRoomBookings, roomBookingsErrors, setRoomBookingsErrors, {})
    }

    function handleSelectRoom(room) {

        let selectedRowIdR = selectedRowId
        if (data.room_bookings.length === 0) {
            addRow(room)
            return
        }
        if (data.room_bookings.length === 1) {
            selectedRowIdR = data.room_bookings[0].id
        }

        const newRoomBookings = data.room_bookings.map(row => {
            if (row.id === selectedRowIdR) {
                return {...row, room: room}
            }
            return row
        })

        setData({...data, room_bookings: newRoomBookings})
    }

    async function handleRoomBookingDelete(roomBookingId) {
        if (roomBookingId.toString().startsWith("N")) {
            const newRoomBookings = data.room_bookings.filter(row => row.id !== roomBookingId)
            setData({...data, room_bookings: newRoomBookings})
        } else {
            try {
                await roomBookingApi("delete", roomBookingId)
                const newRoomBookings = data.room_bookings.filter(row => row.id !== roomBookingId)
                setData({...data, room_bookings: newRoomBookings})
                // loadData()
                toast.success("Raumbuchung gelöscht")
            } catch (e) {
                toast.error("Beim Speichern ist ein Fehler aufgetreten.")
            }
        }
    }

    function handelCancelOccurrence(occ) {
        cancelSchoolOccurrence(data.id, occ);
        loadData();
    }

    function handelUncancelOccurrence(occ) {
        uncancelSchoolEventOccurrence(data.id, occ);
        loadData();
    }


    return (

        <Page title={title}>

            <div
                className="max-w-3xl mx-auto grid grid-cols-1 gap-6  lg:max-w-7xl lg:grid-flow-col-dense lg:grid-cols-6">
                <div className="space-y-6 lg:col-span-4">

                    <Form id="createSupportPlanMeeting" onSave={onSave}
                          onDelete={onDelete}
                          data={data} setData={setData}
                          errors={errors} setErrors={setErrors}
                          successMessage={successMessage}
                          schema={schema}>

                        <FormSectionContent>

                            <Input
                                path="title"
                                type="text"
                                label="Titel"
                                className="sm:col-span-6"
                                onChange={handleChange}
                                errors={errors}
                                data={data}
                            />

                            <DateTimeInput
                                className="col-span-3"
                                path="start"
                                label="Von"
                                onChange={handleDateChange}
                                errors={errors}
                                data={data}
                                todayButton={true}
                                tomorrowButton={true}
                                inSevenDaysButton={true}
                                isDayPeriods={true}
                                typeDayPeriod="start_time"
                            />

                            <DateTimeInput
                                className="col-span-3"
                                path="end"
                                label="Bis"
                                onChange={handleDateChange}
                                errors={errors}
                                data={data}
                                todayButton={true}
                                tomorrowButton={true}
                                isDayPeriods={true}
                                typeDayPeriod="end_time"
                                minDate={new Date(data.start) || null}
                            />

                            <Select
                                path="rule"
                                label="Wiederholung"
                                className="sm:col-span-3"
                                options={rules}
                                valueAttr="id"
                                labelAttr="name"
                                onChange={handleChange}
                                errors={errors}
                                data={data}
                                isClearable={true}
                                valueOnly={false}
                            />

                            <DateTimeInput
                                className="col-span-3"
                                path="end_recurring_period"
                                label="Ende Wiederholung"
                                onChange={handleDateChange}
                                errors={errors}
                                data={data}
                                todayButton={false}
                                tomorrowButton={false}
                                minDate={new Date(data.start) || null}
                            />


                            <div className="sm:col-span-6 py-4 border-y divide-gray-100">

                                <h3 className="text-lg leading-6 font-medium text-gray-900">Raum buchen</h3>

                                {bookingValidationErrors &&
                                    <div className="my-2 px-2 bg-red-50 text-red-700 sm:col-span-6">
                                        <ul role="list" className="space-y-1">
                                            {bookingValidationErrors.map((error, index) => (

                                                <li key={index} className="flex py-2">{error.error}: &nbsp;
                                                    <Link className="font-medium underline"
                                                          target={"_blank"}
                                                          to={`/service/room-bookings/calendar/?event_id=${error.room_booking.id}`}>
                                                        {error.room_booking?.id} von {error.room_booking?.created_by_full_name} <ArrowTopRightOnSquareIcon className="h-4 w-4 inline-flex align-middle"/>
                                                    </Link>
                                                </li>
                                            ))}
                                        </ul>

                                    </div>}

                                {data && data.room_bookings?.map((roomBooking, index) => (
                                    <div key={index} className="flex justify-between my-4">
                                        <Select
                                            path="room"
                                            label="Raum"
                                            className="sm:col-span-5 flex-grow"
                                            options={rooms}
                                            valueAttr="id"
                                            labelAttr="name"
                                            onChange={handleRoomChange}
                                            data={data.room_bookings}
                                            isClearable={true}
                                            valueOnly={false}
                                            rowId={roomBooking.id}
                                        />

                                        <div className="flex justify-start items-end ml-4">
                                            <DeleteButton iconOnly={true}
                                                          onDelete={() => handleRoomBookingDelete(roomBooking.id)}/>
                                        </div>

                                    </div>))}

                                <div className="flex justify-start gap-4 items-end sm:col-span-2">
                                    <FindRoom data={data}
                                              setAvailableRooms={setAvailableRooms}
                                              errors={errors}
                                              setErrors={setErrors}/>

                                    <AddButton onClick={addRow} label="Raum hinzufügen"/>
                                </div>

                            </div>

                            <TextArea
                                path="description"
                                type="text"
                                label="Beschreibung"
                                className="sm:col-span-6"
                                onChange={handleChange}
                                errors={errors}
                                data={data}
                            />

                            <Input
                                path="location"
                                type="text"
                                label="Ort"
                                className="sm:col-span-6"
                                onChange={handleChange}
                                errors={errors}
                                data={data}
                            />


                            <Select
                                path="category"
                                label="Kategorie"
                                className="sm:col-span-6"
                                options={categories}
                                valueAttr="id"
                                labelAttr="name"
                                onChange={handleChange}
                                errors={errors}
                                data={data}
                                isClearable={false}
                                valueOnly={false}
                            />

                            <MultiSelect
                                path="organizers"
                                label="Verantwortliche"
                                className="sm:col-span-6"
                                options={users}
                                valueAttr="id"
                                labelAttr="full_name"
                                onChange={(selectedOptions) => handleMultiSelect(selectedOptions, 'organizers')}
                                errors={errors}
                                data={data}
                                isClearable={true}
                                valueOnly={false}
                            />

                            <MultiSelect
                                path="target_groups"
                                label="Zielgruppen"
                                className="sm:col-span-3"
                                options={groups}
                                valueAttr="id"
                                labelAttr="name"
                                onChange={(selectedOptions) => handleMultiSelect(selectedOptions, 'target_groups')}
                                errors={errors}
                                data={data}
                                isClearable={true}
                                valueOnly={false}
                            />

                            <MultiSelect
                                path="target_school_classes"
                                label="Klassen"
                                className="sm:col-span-3"
                                options={schoolClasses}
                                valueAttr="id"
                                labelAttr="group"
                                onChange={(selectedOptions) => handleMultiSelect(selectedOptions, 'target_school_classes')}
                                errors={errors}
                                data={data}
                                isClearable={true}
                                valueOnly={false}
                            />

                            <div className="sm:col-span-6 text-sm">
                                <h4 className="text-md leading-6 font-medium text-gray-900">Bild</h4>
                                <input type="file" name="handout" onChange={handleChangeFile}/> <br/>

                                <div>
                                    {data.image && <img src={data.image} className=""/>}
                                </div>
                            </div>

                        </FormSectionContent>

                    </Form>

                    <span onClick={() => navigate(-1)}
                          className="cursor-pointer float-right mt-2 font-medium text-imsblue-600">Zurück</span>

                </div>

                <div className="lg:col-span-2 pl-3 sm:max-h-[80%] min-h-0 flex-1 overflow-y-auto">

                    <div className="relative mb-8">
                        <AvailableRooms availableRooms={availableRooms}
                                        msg="Start- und Endzeit auswählen und 'Raum finden' klicken, um hier verfügbare Räume anzuzeigen."
                                        onRoomSelect={handleSelectRoom}/>
                    </div>

                    {data.id && data.rule?.id && (
                        <div className="relative">
                            <h3 className="text-lg leading-6 font-medium text-gray-900">
                                Wiederholungen
                            </h3>
                            <p className="mt-1 text-gray-500 py-2">
                                Einzelne Wiederholungen absagen oder wiederherstellen:
                            </p>

                            <ul role="list" className="divide-y space-y-1">
                                {data.future_occurrences &&
                                    data.future_occurrences.map((occ, index) => (
                                        <li key={index} className="flex py-2">
                                            <div
                                                className={
                                                    "text-sm font-medium " +
                                                    (occ.cancelled ? "text-gray-400" : "text-gray-900")
                                                }
                                            >
                                                {occ.cancelled && <span>(Abgesagt) </span>}
                                                {formatDateTime(occ.start)} - {formatDateTime(occ.end)}
                                                {!occ.cancelled && (
                                                    <XCircleIcon
                                                        className="h-5 w-5 text-red-600 inline-flex ml-2 align-middle"
                                                        onClick={() => handelCancelOccurrence(occ)}
                                                    />
                                                )}
                                                {occ.cancelled && (
                                                    <CheckCircleIcon
                                                        className="h-5 w-5 text-green-800 inline-flex ml-2 align-middle"
                                                        onClick={() => handelUncancelOccurrence(occ)}
                                                    />
                                                )}
                                            </div>
                                        </li>
                                    ))}
                            </ul>
                        </div>
                    )}
                </div>
            </div>

        </Page>
    )
}
