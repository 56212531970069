import {Outlet} from "react-router-dom";


export default function NoSidebarLayout() {

    return (
        <>
            <Outlet />
        </>
    )
}
