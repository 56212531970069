import Logo from "../layout/Logo";
import {useRef, useState} from 'react'
import {Link, useNavigate} from 'react-router-dom'
import {useAuth} from "./../../contexts/AuthContext"
import Alert from './../elements/Alert'

export default function Forbidden() {

    return (
        <>
            <div className="bg-white shadow sm:rounded-lg">
                <div className="px-4 py-5 sm:p-6">
                    <h3 className="text-lg leading-6 font-medium text-gray-900">Zugriff verweigert</h3>
                    <div className="mt-2 max-w-xl text-sm text-gray-500">
                        <p>
                            Du hast leider keinen Zugriff auf diese Seite.
                        </p>
                    </div>
                    <div className="mt-3 text-sm">
                        <Link to="/" className="font-medium text-imsblue-600 hover:text-imsblue-500">
                            {' '}
                            Zurück zur Startseite <span aria-hidden="true">&rarr;</span>
                        </Link>
                    </div>
                </div>
            </div>
        </>
    )
}
