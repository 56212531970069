import React from "react";

export function SimplePage({title, children}) {
    return (
        <div className="pt-6 bg-white">{children}</div>
    );
}

export function Page({title, subtitle, children}) {
    return (
        <div className="pt-6 bg-white">
            <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8 bg-white">
                <h1 className="text-2xl font-semibold text-gray-900">{title}</h1>
                {subtitle}
            </div>
            <div className="max-w-7xl mx-auto px-4 py-4 sm:px-6 md:px-8">
                {children}
            </div>
        </div>
    );
}

export function NarrowPage({title, subtitle, children}) {
    return (
        <div className="pt-6 bg-white">
            <div className="max-w-3xl mx-auto px-4 sm:px-6 md:px-8 bg-white">
                <h1 className="text-2xl font-semibold text-gray-900">{title}</h1>
                {subtitle}
            </div>
            <div className="max-w-3xl mx-auto px-4 py-4 sm:px-6 md:px-8">
                {children}
            </div>
        </div>
    );
}

export function FullWidthPage({title, subtitle, children}) {
    return (
        <div className="pt-6 bg-white">
            <div className="max-w-full mx-auto px-4 sm:px-6 md:px-8 bg-white">
                <h1 className="text-2xl font-semibold text-gray-900">{title}</h1>
                {subtitle}
            </div>
            <div className="max-w-full mx-auto px-4 py-4 sm:px-6 md:px-8">
                {children}
            </div>
        </div>
    );
}

export function ModulePage({title, indexButton, children}) {
    return (
        <>
            <div className="sm:flex pb-3 px-4 sm:px-6 lg:px-8 border-b border-gray-200 ">
                <div className="sm:flex-auto">
                    <h1 className="text-xl font-semibold text-gray-900">{title}</h1>
                </div>
                <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">{indexButton}</div>
            </div>

            <div className="w-full">{children}</div>
        </>
    );
}

export function DetailPage({children}) {
    return <main className="py-10 h-full bg-gray-100">{children}</main>;

}

export function NoPaddingPage({children}) {
    return <main className="bg-white">{children}</main>;

}
