import http from "../services/httpService";
import httpServiceDownloads from "../services/httpServiceDownloads";

// export async function getStudentList(schoolYearId) {
//     const resp = await http.get(`/plupus/students/?school_year_id=${schoolYearId}`)
//     return resp.data
// }
//
// export async function getStudentDetail(schoolYearId, studentId) {
//     const resp = await http.get(`/plupus/students/${studentId}/?school_year_id=${schoolYearId}`)
//     return resp.data
// }
//
// export async function getPlupusExcel(schoolYearId) {
//     const resp = await httpServiceDownloads.get(`/plupus/students/excel/`
//         + (schoolYearId ? `?school_year_id=${schoolYearId}` : '')
//     )
//     return resp.data
// }

// V2
export async function getStudentListCurrentV2() {
    const resp = await http.get(`/plupus/v2/students/current/`)
    return resp.data
}
export async function getStudentListCurrentV2Excel() {
    const resp = await httpServiceDownloads.get(`/plupus/v2/students/current/excel/`)
    return resp.data
}

// with new grades
export async function getStudentListCurrentWithNewGrades() {
    const resp = await http.get(`/plupus/v2/students/current_with_new_grades/`)
    return resp.data
}
export async function getStudentListCurrentWithNewGradesExcel() {
    const resp = await httpServiceDownloads.get(`/plupus/v2/students/current_with_new_grades/excel/`)
    return resp.data
}

export async function getStudentListV2(schoolYearId) {
    const resp = await http.get(`/plupus/v2/students/?school_year_id=${schoolYearId}`)
    return resp.data
}


export async function getStudentListV2Excel(schoolYearId) {
    const resp = await httpServiceDownloads.get(`/plupus/v2/students/excel/`
        + (schoolYearId ? `?school_year_id=${schoolYearId}` : '')
    )
    return resp.data
}

export async function getStudentDetailV2(studentId) {
    const resp = await http.get(`/plupus/v2/students/${studentId}/`)
    return resp.data
}

export async function getStatusSummaryData() {
    const resp = await http.get(`/plupus/v2/students/summary/`)
    return resp.data
}


export async function getPlupuStaffList(schoolYearId) {
    const resp = await http.get(`/plupus/v2/staff/?school_year_id=${schoolYearId}`)
    return resp.data
}

export async function getPlupuStaffListExcel(schoolYearId) {
    const resp = await httpServiceDownloads.get(`/plupus/v2/staff/excel/`
        + (schoolYearId ? `?school_year_id=${schoolYearId}` : '')
    )
    return resp.data
}
