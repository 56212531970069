import React, {useEffect, useState} from "react";
import {useMessenger} from "../../../contexts/MessengerContext";
import CheckboxTree from "react-checkbox-tree-duplicate-value";
import DebouncedInput from "../../elements/forms/DebouncedInput";
import {ColumnWithImage, ColumnWithoutImage} from "../../elements/tables/ListViewTable";
import {capitalizeFirstLetterAndSplit} from "../../../utils/helpers";
import {SecondaryButton} from "../../elements/Buttons";
import {QuestionMarkCircleIcon} from "@heroicons/react/20/solid";

export function RecipientSelectorBody({
                                        subGroups,
                                        setSubGroups,
                                        groupsState,
                                        setGroupsState,
                                        detailGroupState,
                                        setDetailGroupState,
                                      }) {

  const {
    recipients,
    setSelectedRecipients,
    selectedRecipients,
    permissions,
    searchLoading,
    fetchRecipientsByFilter
  } = useMessenger();

  const [groupNodes, setGroupNodes] = useState([]);
  const [detailGroupNodes, setDetailGroupNodes] = useState([]);
  const [subGroupNodes, setSubGroupNodes] = useState([]);

  const [searchSubGroupValue, setSearchSubGroupValue] = useState("");

  function getCategoryLabel(category) {
    var categoryLabel = capitalizeFirstLetterAndSplit(category, "_")
    switch (category) {
      case "school_classes":
        categoryLabel = "Klassen"
        break
      case "courses":
        categoryLabel = "Kurse"
        break
      case "grades":
        categoryLabel = "Klassenstufen"
        break
      case "projects":
        categoryLabel = "Projekte"
        break
      case "departments":
        categoryLabel = "Fachschaften"
        break
      case "houses":
        categoryLabel = "Mentorate"
        break
      case "boarding_school_students":
        categoryLabel = "Intern/extern"
        break
    }
    return categoryLabel
  }

  //for all 3 columns
  useEffect(() => {
    if (recipients) {
      const {detail_select_groups, detail_select_subgroups, groups} = recipients;

      const newGroupNodes = [
        ...groups?.map((group) => {
          return {
            value: group.name,
            label: group.name,
            children: group.users.map((user) => {
              return {
                value: user.id,
                label: user.full_name,
              };
            }),
          };
        }),
      ];
      setGroupNodes(newGroupNodes);

      const newDetailGroupNodes = detail_select_groups?.map((group, index) => {
        return {
          value: group,
          label: group
          //label: group.label ? group.label : capitalizeFirstLetterAndSplit(group, "_"),
        };
      });
      setDetailGroupNodes(newDetailGroupNodes);

      const newSubGroupNodes = Object.keys(detail_select_subgroups)?.map(
        (category) => {
          return {
            value: category,
            label: getCategoryLabel(category),
            children:
              detail_select_subgroups[category].map((subCategory) => {
                return {
                  value: `${category}_${subCategory.id}`,
                  label:
                    subCategory.name ||
                    subCategory.course_number ||
                    subCategory.group,
                };
              }) || [],
          };
        }
      );
      setSubGroupNodes(newSubGroupNodes);
    }
  }, [recipients]);

  //for add select user
  useEffect(() => {
    setGroupsState({
      ...groupsState,
      checked: selectedRecipients.map(i => i.toString()),
    });
  }, [selectedRecipients]);

  //for Sub Groups 3 column
  useEffect(() => {
    if (recipients) {
      const {detail_select_subgroups} = recipients;

      const newSubGroupNodes = Object.keys(detail_select_subgroups)?.map(
        (category) => {
          var categoryLabel = getCategoryLabel(category)
          return {
            value: category,
            label: categoryLabel,
            children: detail_select_subgroups[category]
              .map((subCategory) => {
                return {
                  value: `${category}_${subCategory.id}`,
                  label:
                    subCategory.name ||
                    subCategory.course_number ||
                    subCategory.group,
                };
              })
              .filter((i) => i.label.includes(searchSubGroupValue)),
          };
        }
      );

      //for extend exists list
      if (!!searchSubGroupValue) {
        const expanded = []
        newSubGroupNodes.map(item => {
          if (!!item.children.length) {
            expanded.push(item.value)
          }
        })
        setSubGroups({...subGroups, expanded})
      } else {
        setSubGroups({...subGroups, expanded: []})
      }

      setSubGroupNodes(newSubGroupNodes);
    }
  }, [searchSubGroupValue]);

  //for remove select user
  const handleClearUser = (recipient) => {
    const newSelectedRecipients = selectedRecipients.filter(i => i !== recipient.id);
    setSelectedRecipients(newSelectedRecipients);
  }

  const searchHandle = () => {
    let obj = {};
    const {detail_select_subgroups} = recipients;
    Object.keys(detail_select_subgroups).forEach((i) => {
      const filteredSelectedValue = subGroups.checked.filter(
          (j) => j.includes(i)
      );
      if (filteredSelectedValue.length)
        obj[i] = filteredSelectedValue.map((i) => {
          const value = i.split("_");
          return parseInt(value[value.length - 1]);
        });
    });
    fetchRecipientsByFilter(
        detailGroupState.checked.length
            ? {detail_select_groups: detailGroupState.checked, ...obj,}
            : obj
    );
  }

  return (
    <>
      <div className="w-full mb-3 text-center">
        <h3 className="text-lg leading-6 font-medium text-gray-900">Empfänger auswählen</h3>
      </div>
      <div
        className="grid grid-cols-1 gap-2 md:grid-cols-3 md:gap-4 lg:grid-cols-3 lg:gap-6"
      >

        <RecipientSelectorBodyColumn title="Gruppen"
          tooltip={<>Hier können einzelne Empfänger oder ganze Gruppen ausgewählt
            werden.
            Z.B. "Eltern" -> alle Eltern werden ausgewählt. Diese Auswahl ist <strong>zusätzlich</strong> zu den in der
            nächsten Spalte ausgewählten Benutzern.</>}
        >
          <CheckboxTree
            nodes={groupNodes}
            checked={groupsState.checked}
            expanded={groupsState.expanded}
            onCheck={(checked) => setSelectedRecipients([...checked.map((i) => parseInt(i))])}
            onExpand={(expanded) => setGroupsState({...groupsState, expanded})}
            showNodeIcon={false}
          />
        </RecipientSelectorBodyColumn>

        <RecipientSelectorBodyColumn
            title="Detaillierte Gruppen"
            tooltip={<>Hier kann detaillierter gesucht werden.
              Z.B. für die Eltern der Schüler der Klasse 5a:
              <ul role="list" className="space-y-1 list-disc pl-6">
                <li>oben "Eltern" anklicken</li>
                <li>unten die Klasse 5a anklicken</li>
                <li>"Suchen" klicken</li>
              </ul>

              Diese Auswahl ist <strong>zusätzlich</strong> zu den in der ersten Spalte ausgewählten Benutzern. Es sollte für dieses Beispiel (Eltern der 5a) also z.B. dort <strong>nicht</strong> "Eltern" angeklickt werden.
            </>}
        >

          <CheckboxTree
            nodes={detailGroupNodes}
            checked={detailGroupState.checked}
            expanded={detailGroupState.expanded}
            onCheck={(checked) =>
              setDetailGroupState({...detailGroupState, checked})
            }
            onExpand={(expanded) =>
              setDetailGroupState({...detailGroupState, expanded})
            }
            showNodeIcon={false}
          />

          <p className="font-medium">von</p>
          <DebouncedInput
              value={searchSubGroupValue}
              onChange={(val) => setSearchSubGroupValue(val)}
              placeholder="Nach Klassenstufe, Klasse, Mentorat usw. filtern..."
          />
          <CheckboxTree
              nodes={subGroupNodes}
              checked={subGroups.checked}
              expanded={subGroups.expanded}
              onCheck={(checked) => setSubGroups({...subGroups, checked})}
              onExpand={(expanded) => setSubGroups({...subGroups, expanded})}
              showNodeIcon={false}
          />

          <div className="absolute bottom-[100px] right-[40%]">
          {!!detailGroupState.checked.length && !!subGroups.checked.length &&
              <SecondaryButton onClick={searchHandle}
                             disabled={searchLoading}
                             label={!searchLoading ? "Suchen" : "Läuft..."} />}
          </div>
        </RecipientSelectorBodyColumn>

        <RecipientSelectorBodyColumn
            title="Ausgewählte Empfänger"
            tooltip={<>Die hier angezeigten Empfänger sind das Ergebnis der Auswahl. Einzelne Benutzer können nach Bedarf entfernt werden.</>}
        >
          {permissions?.users
            ?.filter((aRecip) => selectedRecipients.includes(aRecip.id))
            ?.map((recipient) => (
              <ColumnWithImage
                key={recipient.id}
                userId={recipient.user_id}
                line1={recipient.full_name}
                line2={recipient.student_number}
                clearable
                clearAction={() => handleClearUser(recipient)}
              />
            ))}
        </RecipientSelectorBodyColumn>
      </div>
    </>
  )
}

function RecipientSelectorBodyColumn({title, tooltip, children}) {
  return (
    <div className="col-span-1 rounded-lg bg-white shadow overflow-hidden">

      <div className="bg-gray-50 py-2 px-4 flex justify-between">
        <h4 className="text-md text-gray-900 font-medium">{title}</h4>
        {tooltip && <div className="">
          <a
              href="#"
              className="group flex items-center space-x-2.5 text-sm text-gray-500 hover:text-gray-900"
          >
            <QuestionMarkCircleIcon
                className="h-5 w-5 text-gray-400 group-hover:text-gray-500"
                aria-hidden="true"
            />
            <span>Erklärung</span>
            <span className="text-sm text-yellow-600 bg-yellow-50
            group-hover:opacity-100 group-hover:max-w-full transition-opacity z-10
            p-3 rounded-md
             absolute
             left-1 md:left-1/2
             -translate-x-1/4 md:-translate-x-1/2
             translate-y-1/2 md:translate-y-full
            opacity-0 max-w-0 mx-auto">{tooltip}</span>
          </a>
        </div>}
      </div>
      {/*<h4 className="text-md text-gray-900 font-medium bg-gray-50 py-2 px-4">{title}</h4>*/}



      <div className="flex w-full space-y-2 p-2 flex-col h-[400px] overflow-y-auto px-4">
        {children}
      </div>
    </div>
  )
}

function SearchIcon() {
  return (
      <div role="status">
        <svg
            className="inline w-4 h-4 text-white animate-spin fill-blue-600 ml-3"
            viewBox="0 0 100 101"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
          <path
              d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
              fill="currentColor"
          />
          <path
              d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
              fill="currentFill"
          />
        </svg>
        <span className="sr-only">Lädt...</span>
      </div>
  )
}