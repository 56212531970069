import {NavLink, useNavigate} from "react-router-dom";
import React from 'react';

import {classNames} from "../../utils/misc";

export function ModuleWithTabs({title, tabs, children}) {
    const navigate = useNavigate()

    function handleChange(event) {
        if (event.target.value) navigate(event.target.value)
    }

    return (
        <>
            <div className="pb-5 px-4 sm:px-6 lg:px-8 border-b border-gray-200 sm:pb-0">
                <h1 className="text-xl font-semibold text-gray-900">{title}</h1>
                <div className="mt-3 sm:mt-4">
                    <div className="sm:hidden">
                        <label htmlFor="current-tab" className="sr-only">
                            Auswählen
                        </label>

                        <select
                            onChange={handleChange}
                            id="current-tab"
                            name="current-tab"
                            className="block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-imsblue-500 focus:border-imsblue-500 sm:text-sm rounded-md"
                        >
                            <option value={null}>Tab auswählen</option>
                            {tabs && tabs.map((tab, index) => (
                                <option key={tab.pk || index} value={tab.path || tab.to}>
                                        {tab.title}
                                </option>
                            ))}
                        </select>
                    </div>
                    <div className="hidden sm:block overflow-y-hidden overflow-x-auto">
                        <nav className="-mb-px flex space-x-8">
                            {tabs && tabs.map((tab, index) => (
                                <NavLink
                                    end={tab.end}
                                    key={tab.pk || index}
                                    to={tab.path || tab.to}
                                    className={({isActive}) => {
                                        return classNames(
                                            isActive ? 'border-imsblue-500 text-imsblue-600' : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300',
                                            'whitespace-nowrap pb-4 px-1 border-b-2 font-medium text-sm'
                                        )
                                    }}>
                                    {tab.title}
                                </NavLink>
                            ))}
                        </nav>
                    </div>
                </div>
            </div>

            <div className="w-full">
                {children}
            </div>
        </>
    )
}