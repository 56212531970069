import {SimplePage} from "../../elements/Pages";
import React from "react";
import {useQuery} from "@tanstack/react-query";
import {useParams} from "react-router-dom";
import {assessmentApi} from "../../../api/assessments";
import AssessmentEdit from "./AssessmentEdit";


export default function SingleAssessmentEdit() {

    const {assessmentId} = useParams()
    const {isLoading, isError, isSuccess, data: assessment, error, refetch} = useQuery(
        ["assessment_detail", assessmentId],
        () => assessmentApi('get', assessmentId),
    )

    if (!assessment) return <></>

    return (
        <SimplePage>
            <div className="px-4 sm:px-6 lg:px-8">
                <div className="sm:flex sm:items-center">
                    <div className="sm:flex-auto">
                        <h3 className="text-lg leading-6 font-medium text-gray-900">
                            Beurteilung
                        </h3>
                    </div>
                </div>
                <div className="bg-gray-100 pt-6 mt-8 px-4 sm:px-6 lg:px-8">
                    <div className="flex flex-col ">
                        <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
                            <div className="py-2 align-middle md:px-6 lg:px-8 max-w-6xl">
                                {assessment &&
                                    <AssessmentEdit assessmentId={assessmentId} inCourse={true} inStudent={true}/>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </SimplePage>
    );
}
