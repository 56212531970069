import React, {useEffect, useState} from "react";
import SchoolYearFilter from "../../elements/SchoolYearFilter";
import {useSchoolYears} from "../../../contexts/SchoolYearContext";
import {PrimaryButton} from "../../elements/Buttons";
import {
    completeSchoolYearChange,
    confirmArchiving,
    getSchoolYearChange,
    startArchiving,
    startDeletion
} from "../../../api/school_year_change";
import {toast} from "react-toastify";
import {Link} from "react-router-dom";
import {formatDate, formatDateTime} from "../../../utils/formatters";
import {ChevronDownIcon, ChevronUpIcon} from "@heroicons/react/20/solid";


export default function SchoolYearChangeDetail() {

    const {schoolYears, currentSchoolYear} = useSchoolYears();
    const [schoolYear, setSchoolYear] = useState();
    const [schoolYearId, setSchoolYearId] = useState();
    const [showDeletionDetails, setShowDeletionDetails] = useState(false);

    useEffect(() => {
        loadData()
    }, [schoolYearId])

    async function loadData() {
        if (!schoolYearId) return
        const newData = await getSchoolYearChange(schoolYearId)
        setSchoolYear(newData)
    }

    useEffect(() => {
        if (currentSchoolYear) setSchoolYearId(currentSchoolYear["id"]);
    }, [currentSchoolYear]);

    async function handleStartArchiving() {
        try {
            const newData = await startArchiving(schoolYearId);
            setSchoolYear(newData)
            toast.success("Archivierung gestartet")
        } catch (e) {
            toast.error("Es ist ein Fehler aufgetreten.")
        }
    }

    async function handleConfirmArchiving() {
        try {
            const newData = await confirmArchiving(schoolYearId);
            setSchoolYear(newData)
            toast.success("Archivierung bestätigt")
        } catch (e) {
            toast.error("Es ist ein Fehler aufgetreten.")
        }
    }

    async function handleStartDeletion() {
        try {
            const newData = await startDeletion(schoolYearId);
            setSchoolYear(newData)
            toast.success("Löschen gestartet")
        } catch (e) {
            toast.error("Es ist ein Fehler aufgetreten.")
        }
    }
    async function handleCompleteSchoolYearChange() {
        try {
            const newData = await completeSchoolYearChange(schoolYearId);
            setSchoolYear(newData)
            toast.success("Schuljahreswechsel abgeschlossen")
        } catch (e) {
            toast.error("Es ist ein Fehler aufgetreten.")
        }
    }

    return (

        <main className="pt-2 pb-10 h-full bg-gray-100">
            <div className="px-4 sm:px-6 lg:px-8">

                <div className="flex flex-col">
                    <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
                        <div className="bg-white inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                            <SchoolYearFilter
                                schoolYears={schoolYears}
                                schoolYearId={schoolYearId}
                                setSchoolYearId={setSchoolYearId}
                            />
                        </div>
                    </div>
                </div>

                {schoolYear && <div
                    className="pt-8 max-w-3xl mx-auto px-4 sm:px-6 md:flex md:items-center md:justify-between md:space-x-5 lg:max-w-7xl lg:px-8">
                    <div className="flex items-center space-x-5">
                        <div>
                            <h1 className="text-2xl font-bold text-gray-900">Abzuschließendes
                                Schuljahr: {schoolYear.name}</h1>
                            <p className="text-sm font-medium text-gray-500">
                                {formatDate(schoolYear.start_date)} - {formatDate(schoolYear.end_date)}
                            </p>
                        </div>
                    </div>
                    <div
                        className="mt-6 flex flex-col-reverse justify-stretch space-y-4 space-y-reverse sm:flex-row-reverse sm:justify-end sm:space-x-reverse sm:space-y-0 sm:space-x-3 md:mt-0 md:flex-row md:space-x-3">
                    </div>
                </div>}

                {schoolYear &&

                    <>
                        <div
                            className="mt-4 max-w-3xl mx-auto grid grid-cols-1 gap-6 sm:px-6 lg:max-w-7xl lg:grid-flow-col-dense lg:grid-cols-3">
                            <div className="space-y-6 lg:col-start-1 lg:col-span-3">
                                <section aria-labelledby="applicant-information-title">
                                    <div className="bg-white shadow overflow-hidden sm:rounded-lg">
                                        <div className="border-t border-gray-200 px-4 py-5 sm:p-0">
                                            <dl className="sm:divide-y sm:divide-gray-200">
                                                <div className="py-4 sm:py-5 sm:grid sm:grid-cols-4 sm:gap-4 sm:px-6">
                                                    <dt className="text-sm font-medium text-gray-500">Status</dt>
                                                    <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:grid-cols-3">{schoolYear.get_school_year_change_status}
                                                    </dd>
                                                </div>
                                            </dl>
                                        </div>
                                    </div>
                                </section>

                            </div>
                        </div>

                        <div
                            className="mt-4 max-w-3xl mx-auto grid grid-cols-1 gap-6 sm:px-6 lg:max-w-7xl lg:grid-flow-col-dense lg:grid-cols-3">
                            <div className="space-y-6 lg:col-start-1 lg:col-span-3">
                                <section aria-labelledby="applicant-information-title">
                                    <div className="bg-white shadow overflow-hidden sm:rounded-lg">
                                        <div className="px-4 py-5 sm:px-6">
                                            <h3 className="text-lg leading-6 font-medium text-gray-900">1.
                                                Automatische Archivierung</h3>
                                            <p className="mt-1 max-w-2xl text-sm text-gray-500">In diesem Schritt werden
                                                Exportlisten erstellt und sollten im
                                                Anschluss heruntergeladen und archiviert werden. Nach Download und
                                                Prüfung aller Dateien kann die Archivierung bestätigt werden.</p>
                                        </div>
                                        <div className="border-t border-gray-200 px-4 py-5 sm:p-0">
                                            <dl className="sm:divide-y sm:divide-gray-200">
                                                <div className="py-4 sm:py-5 sm:grid sm:grid-cols-4 sm:gap-4 sm:px-6">
                                                    <dt className="text-sm font-medium text-gray-500">Status</dt>
                                                    <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-3">{schoolYear.get_archiving_status_display}
                                                    </dd>
                                                </div>
                                                 <div
                                                    className="py-4 sm:py-5 sm:grid sm:grid-cols-4 sm:gap-4 sm:px-6">
                                                    <dt className="text-sm font-medium text-gray-500">Zuletzt gestartet am:</dt>
                                                    <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-3">
                                                        {schoolYear.last_archive_date && formatDateTime(schoolYear.last_archive_date)}
                                                    </dd>
                                                </div>

                                                <div className="py-4 sm:py-5 sm:grid sm:grid-cols-4 sm:gap-4 sm:px-6">
                                                    <dt className="text-sm font-medium text-gray-500">Nächster Schritt</dt>
                                                    <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-3 flex gap-2">
                                                        {schoolYear.archiving_status !== "CONFIRMED" && <PrimaryButton onClick={handleStartArchiving} label="Archivierung starten" />}
                                                        {schoolYear.archiving_status === "COMPLETED" && <PrimaryButton onClick={handleConfirmArchiving} label="Archivierung bestätigen" />}
                                                    </dd>
                                                </div>

                                                <div className="py-4 sm:py-5 sm:grid sm:grid-cols-4 sm:gap-4 sm:px-6">
                                                    <dt className="text-sm font-medium text-gray-500">Protokoll</dt>
                                                    <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-3 whitespace-pre-wrap">
                                                        {schoolYear.archive_protocol}
                                                    </dd>
                                                </div>
                                            </dl>
                                        </div>
                                    </div>
                                </section>

                            </div>
                        </div>

                        <div
                            className="mt-4 max-w-3xl mx-auto grid grid-cols-1 gap-6 sm:px-6 lg:max-w-7xl lg:grid-flow-col-dense lg:grid-cols-3">
                            <div className="space-y-6 lg:col-start-1 lg:col-span-3">
                                <section aria-labelledby="applicant-information-title">
                                    <div className="bg-white shadow overflow-hidden sm:rounded-lg">
                                        <div className="px-4 py-5 sm:px-6">
                                            <h3 className="text-lg leading-6 font-medium text-gray-900">2.
                                                Manuelle Archivierung</h3>
                                            <p className="mt-1 max-w-2xl text-sm text-gray-500"></p>
                                        </div>
                                        <div className="border-t border-gray-200 px-4 py-5 sm:p-0">
                                            <dl className="sm:divide-y sm:divide-gray-200">
                                                <div className="py-4 sm:py-5 sm:grid sm:grid-cols-4 sm:gap-4 sm:px-6">
                                                    <dt className="text-sm font-medium text-gray-500">Zeugnisse</dt>
                                                    <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-3">
                                                        Alle Zeugnisse sollten heruntergeladen und archiviert werden: <Link className="text-imsblue-600" to="/assessments/assessment-periods">Zeugnisse</Link>
                                                    </dd>
                                                </div>
                                                <div
                                                    className="py-4 sm:py-5 sm:grid sm:grid-cols-4 sm:gap-4 sm:px-6">
                                                    <dt className="text-sm font-medium text-gray-500">Backup der Datenbank</dt>
                                                    <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-3">
                                                        Hierzu eine kurze Email an <a className="text-imsblue-600" href="mailto:mail@michelleretzlaff.com">mail@michelleretzlaff.com</a> senden und ihre Bestätiung abwarten.
                                                    </dd>
                                                </div>
                                            </dl>
                                        </div>
                                    </div>
                                </section>

                            </div>
                        </div>

                        <div
                            className="mt-4 max-w-3xl mx-auto grid grid-cols-1 gap-6 sm:px-6 lg:max-w-7xl lg:grid-flow-col-dense lg:grid-cols-3">
                            <div className="space-y-6 lg:col-start-1 lg:col-span-3">
                                <section aria-labelledby="applicant-information-title">
                                    <div className="bg-white shadow overflow-hidden sm:rounded-lg">
                                        <div className="px-4 py-5 sm:px-6">
                                            <h3 className="text-lg leading-6 font-medium text-gray-900">
                                                3. Datenlöschung</h3>
                                            <p className="mt-1 max-w-2xl text-sm text-gray-500">Nach erfolgter und bestätigter Archivierung werden einige Daten aus dem IMS gelöscht.</p>



                                            {!showDeletionDetails && <span onClick={() => setShowDeletionDetails(true)} className="cursor-pointer text-imsblue-600">
                                                Details <ChevronDownIcon
                                                className="inline align-middle h-3 w-3"
                                                aria-hidden="true"
                                            /></span>}
                                            {showDeletionDetails && <span href="#" onClick={() => setShowDeletionDetails(false)} className="cursor-pointer text-imsblue-600">
                                                Verbergen <ChevronUpIcon
                                                className="inline align-middle h-3 w-3"
                                                aria-hidden="true"
                                            />
                                            </span>}

                                            {showDeletionDetails &&
                                            <div>

                                                <h4 className="text-md leading-6 font-medium text-gray-900">Zu jedem Schuljahreswechsel</h4>
                                                <ul role="list" className="space-y-1 text-sm text-gray-500 list-disc pl-6">
                                                    <li>Kursmaterialien (außer K1)</li>
                                                    <li>Kursbelehrungen</li>
                                                    <li>Klausuren</li>
                                                    <li>Nachschreibeklausuren</li>
                                                    <li>Vertretungen</li>
                                                    <li>Ausfälle</li>
                                                    <li>Projektbelegungen</li>
                                                    <li>Projektanwesenheiten</li>
                                                    <li>Watchlisteinträge</li>
                                                    <li>Studierzeitwechsel</li>
                                                    <li>Raumbuchungen (nur vergangene)</li>
                                                    <li>Gerätebuchungen (nur vergangene)</li>
                                                </ul>

                                                <h4 className="mt-4 text-md leading-6 font-medium text-gray-900">Beim folgenden Schuljahreswechsel</h4>
                                                <ul role="list" className="space-y-1 text-sm text-gray-500 list-disc pl-6">
                                                    <li>Themenverteilungspläne</li>
                                                    <li>Notendefinitionen</li>
                                                </ul>

                                                <h4 className="mt-4 text-md leading-6 font-medium text-gray-900">1 Jahr nach Austritt des (letzten) Schülers</h4>
                                                <ul role="list" className="space-y-1 text-sm text-gray-500 list-disc pl-6">
                                                    <li>Kurse</li>
                                                    <li>Schülerdokumente</li>
                                                    <li>Fördermaßnahmen, inkl. -stunden und -abrechnungen</li>
                                                    <li>Abwesenheitsmeldungen</li>
                                                    <li>Sportbefreiungen</li>
                                                    <li>Logbucheinträge</li>
                                                    <li>Strafen</li>
                                                    <li>Strafeinträge</li>
                                                    <li>Beurteilungen</li>
                                                    <li>Endkopfnoten</li>
                                                    <li>Kopfnoten</li>
                                                    <li>Internatsbeurteilungen</li>
                                                    <li>Studierzeitbeurteilungen</li>
                                                    <li>Ergebnisse</li>
                                                    <li>Sondernoten</li>
                                                    <li>Zeugnisbemerkungen</li>
                                                    <li>GFS</li>
                                                    <li>PluPus</li>
                                                </ul>

                                                <h4 className="mt-4 text-md leading-6 font-medium text-gray-900">3 Jahre nach Erstellung</h4>
                                                <ul role="list" className="space-y-1 text-sm text-gray-500 list-disc pl-6">
                                                    <li>Reparaturauftrag (3 Jahre nach Erledigung)</li>
                                                    <li>Tweets (3 Jahre nach Erstellung)</li>
                                                    <li>Klassenbucheinträge inkl. Hausaufgaben und Versuchsbelehrungen</li>
                                                </ul>

                                                <h4 className="mt-4 text-md leading-6 font-medium text-gray-900">10 Jahre nach Schuljahresende</h4>
                                                <ul role="list" className="space-y-1 text-sm text-gray-500 list-disc pl-6">
                                                    <li>Zeiträume/Epochen</li>
                                                    <li>Beurteilungszeiträume</li>
                                                </ul>

                                            </div>}
                                        </div>
                                        <div className="border-t border-gray-200 px-4 py-5 sm:p-0">
                                            <dl className="sm:divide-y sm:divide-gray-200">
                                                <div className="py-4 sm:py-5 sm:grid sm:grid-cols-4 sm:gap-4 sm:px-6">
                                                    <dt className="text-sm font-medium text-gray-500">Status</dt>
                                                    <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-3">{schoolYear.get_deletion_status_display}
                                                    </dd>
                                                </div>
                                                <div className="py-4 sm:py-5 sm:grid sm:grid-cols-4 sm:gap-4 sm:px-6">
                                                    <dt className="text-sm font-medium text-gray-500">Nächster Schritt</dt>
                                                    <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-3 flex gap-2">
                                                        <PrimaryButton onClick={handleStartDeletion} label="Datenlöschung starten"
                                                            disabled={schoolYear.archiving_status !== 'CONFIRMED'
                                                                || schoolYear.school_year_change_completed
                                                                || schoolYear.deletion_status === 'COMPLETED'}
                                                        />
                                                    </dd>
                                                </div>

                                            </dl>
                                        </div>
                                    </div>
                                </section>
                            </div>
                        </div>

                        <div
                            className="mt-4 max-w-3xl mx-auto grid grid-cols-1 gap-6 sm:px-6 lg:max-w-7xl lg:grid-flow-col-dense lg:grid-cols-3">
                            <div className="space-y-6 lg:col-start-1 lg:col-span-3">
                                <section aria-labelledby="applicant-information-title">
                                    <div className="bg-white shadow overflow-hidden sm:rounded-lg">
                                        <div className="px-4 py-5 sm:px-6">
                                            <h3 className="text-lg leading-6 font-medium text-gray-900">
                                                4. Schuljahreswechsel abschließen</h3>
                                            <p className="mt-1 max-w-2xl text-sm text-gray-500">


                                            </p>
                                        </div>
                                        <div className="border-t border-gray-200 px-4 py-5 sm:p-0">
                                            <dl className="sm:divide-y sm:divide-gray-200">
                                                <div
                                                    className="py-4 sm:py-5 sm:grid sm:grid-cols-4 sm:gap-4 sm:px-6">
                                                    <dt className="text-sm font-medium text-gray-500">Abschließen</dt>
                                                    <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-3 flex gap-2">
                                                        {!schoolYear.school_year_change_completed && <PrimaryButton onClick={handleCompleteSchoolYearChange}
                                                                       label="Schuljahreswechsel abschließen"
                                                                       disabled={schoolYear.archiving_status !== 'CONFIRMED' || schoolYear.deletion_status !== 'COMPLETED'}
                                                        />}

                                                        {schoolYear.school_year_change_completed && <>Abgeschlossen</>}
                                                    </dd>
                                                </div>
                                            </dl>
                                        </div>
                                        <div className="border-t border-gray-200 px-4 py-5 sm:p-0">
                                            <div className="px-4 py-5 sm:px-6 sm:col-span-4">
                                                <p className="mt-1 max-w-2xl text-sm text-gray-500 whitespace-pre-wrap">
                                                    Nach Abschluss des alten Schuljahres können nun die Daten des neuen Schuljahres eingepflegt werden.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </section>

                            </div>
                        </div>


                    </>
                }

            </div>
        </main>
    );
}
