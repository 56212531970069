import {SimplePage} from "../../elements/Pages";
import React, {useEffect, useState} from "react";
import ListViewTable from "../../elements/tables/ListViewTable";
import {DownloadButton} from "../../elements/Buttons";
import {useSchoolYears} from "../../../contexts/SchoolYearContext";
import SchoolYearFilter from "../../elements/SchoolYearFilter";
import {
    getMissingHomeworkOrMaterialsByStudentAndTeacherReport, getMissingHomeworkOrMaterialsByStudentAndTeacherReportExcel,
    getMissingHomeworkOrMaterialsByStudentReport,
    getMissingHomeworkOrMaterialsByStudentReportExcel
} from "../../../api/reports";
import {useQuery} from "@tanstack/react-query";


const columns = [
    {
        accessorFn: (row) => `${row.last_name}, ${row.first_name} ${row.student_number}`,
        id: "student_search",
        header: "SchülerIn",
        hide: false,
        enableColumnFilter: false,
    },
    {
        accessorKey: "school_class",
        id: "school_class",
        header: "Klasse",
        sortingFn: "schoolClassSorting",
        enableColumnFilter: false
    },
    {
        accessorKey: "house",
        id: "house",
        header: "Mentorat",
        enableColumnFilter: false
    },
    {
        accessorFn: (row) => `${row.staff_last_name}, ${row.staff_first_name}`,
        id: "staff",
        header: "LehrerIn",
        hide: false,
        enableColumnFilter: false,
    },
    {
        accessorKey: "missing_homework",
        id: "missing_homework",
        header: "Fehl. HA",
        enableColumnFilter: false
    },
    {
        accessorKey: "missing_materials",
        id: "missing_materials",
        header: "Fehl. Mat.",
        enableColumnFilter: false
    }
]

export default function MissingHomeworkAndMaterialsByStudentAndTeacherReport() {

    const {schoolYears, currentSchoolYear} = useSchoolYears();
    const [schoolYearId, setSchoolYearId] = useState();

    const {isLoading, isError, isSuccess, data, error, refetch} = useQuery(
        ["missing_homework_or_materials_by_student_and_teacher", schoolYearId],
        () => getMissingHomeworkOrMaterialsByStudentAndTeacherReport(schoolYearId),
        {
            enabled: !!schoolYearId, refetchInterval: 1000 * 60 * 60
        }
    )


    useEffect(() => {
        if (currentSchoolYear) setSchoolYearId(currentSchoolYear["id"]);
    }, [currentSchoolYear])

    return (
        <SimplePage>
            <div className="px-4 sm:px-6 lg:px-8">
                <div className="sm:flex sm:items-center">
                    <div className="sm:flex-auto">
                        <h3 className="text-lg leading-6 font-medium text-gray-900">
                            Fehlende Hausaufgaben/Materialien nach Schülern und Lehrern
                        </h3>
                    </div>
                    <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
                        <DownloadButton
                            apiFunction={() => getMissingHomeworkOrMaterialsByStudentAndTeacherReportExcel(schoolYearId)}
                            fileName="Fehl. HA/Mat nach Schülern/Lehrern.xls"
                            label="Excel herunterladen"/>

                    </div>
                </div>
                <div className="flex flex-col">
                    <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">

                        <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                            <SchoolYearFilter
                                schoolYears={schoolYears}
                                schoolYearId={schoolYearId}
                                setSchoolYearId={setSchoolYearId}
                            />
                        </div>

                        <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                            {data && (
                                <ListViewTable
                                    data={data}
                                    columns={columns}
                                    isLoading={false}
                                    disableGlobalSearch={false}
                                    getRowLink={null}
                                />
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </SimplePage>
    );
}
