import http from "../services/httpService";
import httpServiceDownloads from "../services/httpServiceDownloads";


export async function getAllCoursesWithSchoolYear() {
    const resp = await http.get(`/course_list/all_courses_with_school_year/`)
    return resp.data
}

export async function getCourseList() {
    const resp = await http.get(`/course_list/`)
    return resp.data
}

export async function getCourses(schoolYearId) {
    const resp = await http.get(`/courses/` + (schoolYearId ? `?school_year_id=${schoolYearId}` : ''))
    return resp.data
}

export async function getMyTeacherCourses(schoolYearId) {
    const resp = await http.get(`/courses/my_teacher_courses/` + (schoolYearId ? `?school_year_id=${schoolYearId}` : ''))
    return resp.data
}

export async function getMyStudentCourses(studentId, schoolYearId) {
    const resp = await http.get(`/courses/my_student_courses/?students__id=${studentId}` + (schoolYearId ? `&school_year_id=${schoolYearId}` : ''))
    return resp.data
}

export async function getMyTeacherCoursesExcel(schoolYearId) {
    const resp = await httpServiceDownloads.get(`/courses/export_my_teacher_coursers/`)
    return resp.data
}

export async function getCoursesForStudent(studentId, schoolYearId) {
    const resp = await http.get(`/courses/?students__id=${studentId}` + (schoolYearId ? `&school_year_id=${schoolYearId}` : ''))
    return resp.data
}

export async function getCoursesForTeacher(staffId) {
    const resp = await http.get(`/courses/?teacher_id=${staffId}`)
    return resp.data
}

export async function getCourse(id) {
    const resp = await http.get(`/courses/${id}/`)
    return resp.data
}

export async function getCourseInfo(id) {
    const resp = await http.get(`/courses/${id}/mini/`)
    return resp.data
}

export async function getCourseMaterialsAndHomework(id) {
    if (!id) return {}
    const resp = await http.get(`/courses/${id}/materials_and_homework/`)
    return resp.data
}

export async function patchCourse(data) {
    const resp = await http.patch(`/courses/${data.id}/` , data)
    return resp.data
}

// course instructions
export async function getCourseInstructions(id) {
    const resp = await http.get(`/course_instructions/${id}/`)
    return resp.data
}

export async function postCourseInstructions(data) {
    const resp = await http.post(`/course_instructions/`, data)
    return resp.data
}

export async function patchCourseInstructions(data) {
    const resp = await http.patch(`/course_instructions/${data.id}/` , data)
    return resp.data
}

export async function addFileToCourseInstructions(formData, id) {
    const resp = await http.post(`/course_instructions/${id}/add_file/`, formData)
    return resp.data
}
export async function deleteCourseInstructions(id) {
    const resp = await http.delete(`/course_instructions/${id}/`)
    return resp.data
}

// course material

// course instructions
export async function getCourseMaterial(id) {
    const resp = await http.get(`/course_material/${id}/`)
    return resp.data
}

export async function postCourseMaterial(data) {
    const resp = await http.post(`/course_material/`, data)
    return resp.data
}

export async function patchCourseMaterial(data) {
    const resp = await http.patch(`/course_material/${data.id}/` , data)
    return resp.data
}

export async function addFileToCourseMaterial(formData, id) {
    const resp = await http.post(`/course_material/${id}/add_material_file/`, formData)
    return resp.data
}
export async function deleteCourseMaterial(id) {
    const resp = await http.delete(`/course_material/${id}/`)
    return resp.data
}

export async function deleteCourseMaterialFile(id) {
    const resp = await http.delete(`/course_material_files/${id}/`)
    return resp.data
}
// Exports

export async function getCourseListExcel(schoolYearId) {
    const resp = await httpServiceDownloads.get(`/courses/excel/?school_year_id=${schoolYearId}`)
    return resp.data
}

export async function getStudentListExcel(courseId) {
    const resp = await httpServiceDownloads.get(`/courses/${courseId}/student_list_excel/`)
    return resp.data
}

// Student courses
export async function getStudentCourses() {
    const resp = await http.get(`/student_courses/student_courses_and_subjects/`)
    return resp.data
}

export async function getStudentCourseDetail(studentId) {
    const resp = await http.get(`/student_courses/${studentId}/`)
    return resp.data
}

export async function removeStudentCourse(studentId, courseId) {
    const data = {
        course_id: courseId
    }
    const resp = await http.post(`/student_courses/${studentId}/delete_student_course/`, data)
    return resp.data
}
export async function addStudentCourses(studentId, courseIds) {
    const data = {
        course_ids: courseIds
    }
    const resp = await http.post(`/student_courses/${studentId}/add_student_courses/`, data)
    return resp.data
}

export async function getStudentCoursesExcel() {
    const resp = await httpServiceDownloads.get(`/student_courses/excel/`)
    return resp.data
}

export async function doStudentCourseChange(studentId, data) {
    const resp = await http.post(`/student_courses/${studentId}/change_student_course/`, data)
    return resp.data
}

// get suggested courses for school class change
export async function getSuggestedCoursesForSchoolClassChange(data) {
    const resp = await http.post(`/school/school_class_change_suggested_courses/`, data)
    return resp.data
}

// do school class change
export async function doSchoolClassChange(studentId, newSchoolClassId, data) {
    const resp = await http.post(`/school/school_class_change_do/${studentId}/${newSchoolClassId}/`, data)
    return resp.data
}

// Teacher courses
export async function getTeacherCourses() {
    const resp = await http.get(`/teacher_courses/`)
    return resp.data
}
export async function getTeacherCoursesExcel() {
    const resp = await httpServiceDownloads.get(`/teacher_courses/excel/`)
    return resp.data
}

// Course topics
export async function getCourseTopicsCourseList(schoolYearId) {
    const resp = await http.get(`/course_topics/course_list/` + (schoolYearId ? `?school_year_id=${schoolYearId}` : ''))
    return resp.data
}

export async function getCourseTopics(courseId) {
    const resp = await http.get(`/course_topics/?course_id=${courseId}`)
    return resp.data
}
export async function getCourseTopicsPDF(courseId) {
    const resp = await httpServiceDownloads.get(`/course_topics/pdf/?course_id=${courseId}`)
    return resp.data
}

export async function deleteCourseTopic(id) {
    const resp = await http.delete(`/course_topics/${id}/`)
    return resp.data
}

export async function copyCourseTopics(data) {
    const resp = await http.post(`/course_topics/copy/`, data)
    return resp.data
}
export async function createOrUpdateMultipleCourseTopics(data) {
    const resp = await http.post('/course_topics/create_or_update_multiple/', data)
    return resp.data
}
