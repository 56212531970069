import {SimplePage} from "../../elements/Pages";
import React, {useEffect, useState} from "react";
import ListViewTable from "../../elements/tables/ListViewTable";
import {DownloadButton, PrimaryButton} from "../../elements/Buttons";
import {useSchoolYears} from "../../../contexts/SchoolYearContext";
import SchoolYearFilter from "../../elements/SchoolYearFilter";
import {
    getCheckedHomeworkByStudentsReport,
    getCheckedHomeworkByStudentsReportExcel,
    getCheckedHomeworkByTeacherReport, getCheckedHomeworkByTeacherReportExcel
} from "../../../api/reports";
import {DateInput} from "../../elements/forms/Inputs";
import {baseHandleDateChange} from "../../elements/forms/Form";


const columns = [
    {
        accessorFn: (row) => `${row.last_name}, ${row.first_name}`,
        id: "student_search",
        header: "SchülerIn",
        hide: false,
        enableColumnFilter: false,
    },
    {
        accessorKey: "attendances_created",
        id: "attendances_created",
        header: "Betr. Stuzi",
        enableColumnFilter: false
    },
    {
        accessorKey: "homework_checked",
        id: "homework_checked",
        header: "Geprüfte HA",
        enableColumnFilter: false
    },
    {
        accessorKey: "checked_per_attendance",
        id: "checked_per_attendance",
        header: "Kontrollen/Stuzi",
        enableColumnFilter: false
    }
]

export default function CheckedHomeworkByTeacherReport() {

    const [errors, setErrors] = useState([]) // validation errors
    const {schoolYears, currentSchoolYear} = useSchoolYears();
    const [schoolYearId, setSchoolYearId] = useState();
    const [data, setData] = useState()

    const [dateData, setDateData] = useState({
        date_from: null,
        date_to: null
    })
    const schema = {}

    useEffect(() => {
        if (currentSchoolYear) setSchoolYearId(currentSchoolYear["id"]);
    }, [currentSchoolYear])

    useEffect(() => {
        loadData()
    }, [schoolYearId]);

    async function loadData() {
        if (schoolYearId) {
            const newData = await getCheckedHomeworkByTeacherReport(schoolYearId, dateData.date_from, dateData.date_to)
            setData(newData)
        }
    }

    function handleDateChange(date, path) {
        baseHandleDateChange(date, path, dateData, setDateData, errors, setErrors, schema, false)
    }

    async function handleFilter() {
        const newData = await getCheckedHomeworkByTeacherReport(schoolYearId, dateData.date_from, dateData.date_to)
        setData(newData)
    }

    return (
        <SimplePage>
            <div className="px-4 sm:px-6 lg:px-8">
                <div className="sm:flex sm:items-center">
                    <div className="sm:flex-auto">
                        <h3 className="text-lg leading-6 font-medium text-gray-900">
                            Kontrollierte Hausaufgaben nach Schülern
                        </h3>
                    </div>
                    <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
                        <DownloadButton
                            apiFunction={() => getCheckedHomeworkByTeacherReportExcel(schoolYearId)}
                            fileName="Kontr Hausaufgaben nach Studierzeitaufsicht.xls"
                            label="Excel herunterladen"/>

                    </div>
                </div>
                <div className="flex flex-col">
                    <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">

                        <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                            <SchoolYearFilter
                                schoolYears={schoolYears}
                                schoolYearId={schoolYearId}
                                setSchoolYearId={setSchoolYearId}
                            />

                            <div className=" pt-2 gap-x-4 flex justify-start items-end">

                                <DateInput
                                    className=""
                                    path="date_from"
                                    label="Datum von"
                                    onChange={handleDateChange}
                                    errors={errors}
                                    data={dateData}
                                    todayButton={true}
                                    tomorrowButton={false}
                                />
                                <DateInput
                                    className=""
                                    path="date_to"
                                    label="Bis"
                                    onChange={handleDateChange}
                                    errors={errors}
                                    data={dateData}
                                    todayButton={false}
                                    tomorrowButton={false}
                                />

                                <PrimaryButton label="Filtern"
                                               onClick={handleFilter}></PrimaryButton>

                            </div>
                        </div>


                        <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                            {data && (
                                <ListViewTable
                                    data={data}
                                    columns={columns}
                                    isLoading={false}
                                    disableGlobalSearch={false}
                                    getRowLink={null}
                                />
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </SimplePage>
    );
}
