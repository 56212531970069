import {SimplePage} from "../../elements/Pages";
import React from "react";

import {useQuery} from "@tanstack/react-query";
import ListViewTable from "../../elements/tables/ListViewTable";
import {assessmentPeriodApi} from "../../../api/assessments";
import {useParams} from "react-router-dom";
import {getTeacherList} from "../../../api/staff";


export default function Teachers() {

    const {assessmentPeriodId} = useParams()

    const {data: assessmentPeriod, isLoading, isSuccess} = useQuery(
        ["assessment_periods", assessmentPeriodId],
        () => assessmentPeriodApi('get', assessmentPeriodId),
        {refetchInterval: 1000 * 60 * 60 * 24}
    );

    const {data: teachers} = useQuery(["teachers"], getTeacherList, {refetchInterval: 1000 * 60 * 60 * 24})

    function getRowLink(row) {
        return `/assessments/assessment-periods/${assessmentPeriodId}/teachers/${row.original.id}/`
    }

    const columns = [
        {
            accessorFn: (row) =>
                `${row.last_name}, ${row.first_name}`,
            id: "teacher",
            header: "Lehrer",
            enableColumnFilter: false,
        }
    ];

    return (
        <SimplePage>
            <div className="px-4 sm:px-6 lg:px-8">
                <div className="sm:flex sm:items-center">
                    <div className="sm:flex-auto">
                        <h3 className="text-lg leading-6 font-medium text-gray-900">
                            Beurteilungen nach Lehrern für {assessmentPeriod?.name}
                        </h3>
                    </div>
                    <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
                    </div>
                </div>
                <div className="flex flex-col">
                    <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">

                        <div className="inline-block py-2 align-middle md:px-6 lg:px-8">
                            {isSuccess && teachers && (
                                <ListViewTable
                                    data={teachers}
                                    columns={columns}
                                    isLoading={isLoading}
                                    disableGlobalSearch={false}
                                    getRowLink={getRowLink}
                                />
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </SimplePage>
    );
}
