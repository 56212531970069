import {SimplePage} from "../../elements/Pages";
import React, {useEffect, useState} from "react";
import ListViewTable, {ColumnWithImage} from "../../elements/tables/ListViewTable";
import {useParams} from "react-router-dom";
import {baseHandleListChange} from "../../elements/forms/Form";
import {toast} from "react-toastify";
import {Select} from "../../elements/forms/Inputs";
import {EditButton, PrimaryButton} from "../../elements/Buttons";
import {getStudierzeitAssessmentsForCreator, updateStudierzeitAssessmentsForCreator} from "../../../api/studierzeit";


export default function StudierzeitAssessments() {

    const {assessmentPeriodId} = useParams()
    const [errors, setErrors] = useState([])

    const [editing, setEditing] = useState(false)
    const [data, setData] = useState()

    useEffect(() => {
        loadData()
    }, [])

    async function loadData() {
        const newData = await getStudierzeitAssessmentsForCreator(assessmentPeriodId)
        setData(newData)
    }

    const columnsViewOnly = [
        // {
        //     accessorFn: (row) => `${row.student.last_name}, ${row.student.first_name} ${row.student.student_number}`,
        //     id: "student",
        //     header: "SchülerIn",
        //     enableColumnFilter: false,
        // },
        {
            accessorFn: (row) =>
                `${row.student.last_name}, ${row.student.first_name} ${row.student.student_number}`,
            id: "student_search",
            header: "SchülerIn",
            hide: true,
            enableColumnFilter: false,
        },
        {
            accessorKey: "student",
            id: "student",
            header: "SchülerIn",
            columnType: "student",
            enableColumnFilter: false,
            sortingFn: "lastNameSorting",
        },
        {
            accessorKey: "student.school_class",
            id: "school_class",
            header: "Klasse",
            enableColumnFilter: true,
            classNames: "text-gray-500",
            headerClassNames: "narrow-filter"
        },
        {
            accessorKey: "attendances",
            id: "attendances",
            header: "Anzahl",
            enableColumnFilter: false
        },
        {
            accessorKey: "mark_attitude",
            id: "mark_attitude",
            header: "Arbeitsauffassung",
            enableColumnFilter: false
        },
        {
            accessorKey: "mark_behavior",
            id: "mark_behavior",
            header: "Verhalten",
            enableColumnFilter: false
        }
    ]

    function handleChange(evt, rowId) {
        baseHandleListChange(rowId, evt, data.students, setStudents, errors, setErrors, {})
    }

    function setStudents(newStudents) {
        setData({...data, students: newStudents})
    }

    async function handleSave() {
        try {
            const newData = await updateStudierzeitAssessmentsForCreator(assessmentPeriodId, data)
            setData(newData)
            toast.success("Studierzeitbeurteilungen gespeichert")
        } catch (e) {
            toast.error("Beim Speichern ist ein Fehler aufgetreten.")
        }
    }

    return (
        <SimplePage>
            <div className="px-4 sm:px-6 lg:px-8">
                <div className="sm:flex sm:items-center xl:col-span-3">
                    <div className="sm:flex-auto">
                        <h3 className="text-lg leading-6 font-medium text-gray-900">
                            Studierzeitbeurteilungen eingeben {data?.assessment_period?.name}
                        </h3>
                    </div>
                    <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
                        <EditButton label="Alle bearbeiten" onClick={() => setEditing(!editing)} />
                    </div>
                </div>
                <div className="mt-4 flex flex-col xl:col-span-2">
                    <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
                        <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">

                            {data && editing &&
                                <>
                                    <div className="shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
                                        <table className="divide-y divide-gray-300">
                                            <tbody>
                                            {data.students?.map((c, index) => (
                                                <tr key={index}
                                                    className={(index % 2 === 0 ? undefined : "bg-gray-50")}
                                                >
                                                    <td className="py-4 pl-4 pr-3 text-sm">
                                                        <ColumnWithImage
                                                            userId={c.student.user_id}
                                                            line1={`${c.student.last_name}, ${
                                                                c.student.first_name
                                                            }`}
                                                            line2={c.student.student_number}
                                                        />
                                                    </td>
                                                    <td className="py-4 pl-4 pr-3 text-sm">
                                                        <Select
                                                            path="mark_attitude"
                                                            label="Arbeitsauffassung"
                                                            options={c.mark_options}
                                                            valueAttr="value"
                                                            labelAttr="label"
                                                            onChange={handleChange}
                                                            errors={errors}
                                                            data={data.students}
                                                            isClearable={true}
                                                            valueOnly={true}
                                                            rowId={c.id}
                                                        />
                                                    </td>

                                                    <td className="py-4 pl-4 pr-3 text-sm">
                                                        <Select
                                                            path="mark_behavior"
                                                            label="Verhalten"
                                                            options={c.mark_options}
                                                            valueAttr="value"
                                                            labelAttr="label"
                                                            onChange={handleChange}
                                                            errors={errors}
                                                            data={data.students}
                                                            isClearable={true}
                                                            valueOnly={true}
                                                            rowId={c.id}
                                                        />
                                                    </td>
                                                </tr>
                                            ))}

                                            </tbody>
                                        </table>
                                    </div>

                                    <div className="flex justify-between col-span-2">
                                        <PrimaryButton
                                            classNameIn="my-4 mr-4"
                                            onClick={handleSave} label="Studierzeitbeurteilungen speichern"/>
                                    </div>
                                </>
                            }

                            {!editing && data &&
                                <ListViewTable data={data.students}
                                               columns={columnsViewOnly}/>}

                        </div>
                    </div>
                </div>
            </div>
        </SimplePage>
    );
}
