import React from "react";
import {Link} from "react-router-dom";
import {SimplePage} from "../../elements/Pages";
import {DownloadButton, EditButton} from "../../elements/Buttons";
import {useQuery} from "@tanstack/react-query";

import {getStudentCourses, getStudentCoursesExcel} from "../../../api/courses";

export default function StudentCourseList() {

    const {isLoading, isError, isSuccess, data, error, refetch} = useQuery(["student_course_list"], getStudentCourses, {refetchInterval: 1000 * 60 * 60 * 24})
    if (!data) return <></>;
    const {students, subjects} = data

    return (
        <>
            <SimplePage>
                <div className="px-4 sm:px-6 lg:px-8">
                    <div className="sm:flex sm:items-center">
                        <div className="sm:flex-auto">
                            <h3 className="text-lg leading-6 font-medium text-gray-900">
                                Schülerkurse
                            </h3>
                        </div>
                        <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none gap-1 flex">
                            <DownloadButton
                                apiFunction={() => getStudentCoursesExcel()}
                                fileName={`Schülerkurse.xls`}
                                label="Excel herunterladen"/>
                        </div>
                    </div>
                    <div className="flex flex-col mt-3 mb-3 scrollbarHeight">
                        <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
                            <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">

                                <div className="overflow-hidden">
                                    <table className="divide-y divide-gray-300 mt-3" style={{borderSpacing: 0}}>
                                        <thead className="bg-imsblue-900 text-white text-sm">
                                        <tr>
                                            <td className="text-md py-2 px-2"></td>
                                            <td className="text-md py-2 px-2">SchülerIn</td>
                                            <td className="text-md py-2 px-2">Klasse</td>
                                            {subjects.map((subject, index) => (
                                                <td className="text-md py-2 px-2" key={index}>
                                                    {subject.name}
                                                </td>
                                            ))}
                                        </tr>

                                        </thead>

                                        <tbody>

                                        {students.map((student, index) => (

                                            <tr key={index} className="divide-x divide-gray-200 text-center">
                                                <td className="py-1 px-1 border-b border-gray-200"
                                                    key="edit">
                                                    <EditButton iconOnly={true}
                                                                link={`/courses/student-courses/${student.id}`}></EditButton>
                                                </td>
                                                <td className="py-1 px-1 border-b border-gray-200 text-left"
                                                    key="studentName">
                                                    <div
                                                        className="whitespace-nowrap break-inside-avoid">{student.last_name}, {student.first_name}</div>
                                                    <div className="text-gray-500">{student.student_number}</div>
                                                </td>

                                                <td className="py-1 px-1 border-b border-gray-200"
                                                    key="school_class">{student.school_class}</td>

                                                {subjects.map((subject, index) => (
                                                    <td className="py-1 px-1 border-b border-gray-200" key={index}>
                                                        {student.courses?.filter(c => c.school_subject === subject.name).map((course, courseIndex) => (
                                                            <span key={courseIndex}>
                                                                {courseIndex > 0 ? <br/> : ""}
                                                                <Link className="font-medium text-imsblue-600"
                                                                      to={`/courses/${course.id}`}>{course.course_number}</Link>
                                                            </span>
                                                        ))}
                                                    </td>
                                                ))}
                                            </tr>

                                        ))}

                                        </tbody>

                                    </table>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </SimplePage>
        </>
    );
}

