import {SimplePage} from "../../elements/Pages";
import React from 'react';

import {useQuery} from '@tanstack/react-query'
import ListViewTable, {TableLink} from "../../elements/tables/ListViewTable";
import {getSchoolClassesFull} from "../../../api/school";
import {assessmentPeriodApi, getAssessmentPermissions} from "../../../api/assessments";

import {useParams} from "react-router-dom";
import {useAuth} from "../../../contexts/AuthContext";
import {getSchoolClassesForCertificates} from "../../../api/certificates";


export default function CertificatesSchoolClassList() {

    const {assessmentPeriodId} = useParams()
    const {currentUser} = useAuth();
    const {isLoading, isError, isSuccess, data, error, refetch} = useQuery(
        ['school_classes_for_assessment_period', assessmentPeriodId],
        () => getSchoolClassesForCertificates(assessmentPeriodId),
        {refetchInterval: 1000 * 60 * 60 * 24})
    const {data: assessmentsPermissions} = useQuery(['assessment_permissions', currentUser.id], getAssessmentPermissions)
    const {data: assessmentPeriod} = useQuery(['assessment_period', assessmentPeriodId], () => assessmentPeriodApi('get', assessmentPeriodId))

    const isManagement = assessmentsPermissions?.is_management

    function getRowLink(row) {
        return `/certificates/assessment-periods/${assessmentPeriodId}/certificates/${row.id}`
    }

    const columns = [
        {
            accessorKey: 'group',
            id: 'group',
            header: 'Klasse',
            enableColumnFilter: false,
            headerClassNames: "narrow-filter"
        },
        {
            accessorKey: "students",
            id: "students",
            header: "SchülerInnen",
            enableColumnFilter: false,
            columnType: "m2mLinked",
            separator: "<br/>",
            getItemLink: (item) => {
                return `/school/students/${item.id}/`
            },
            labelAttr: "full_name",
            headerClassNames: "narrow-filter",
        },
        {
            accessorKey: "class_teacher",
            id: "class_teacher",
            header: "KlassenlehrerIn",
            columnType: "staff",
            enableColumnFilter: false
        },
        {
            accessorKey: "id",
            id: "certificates",
            header: "Zeugnisse",
            enableColumnFilter: false,
            disableRowLink: true,
            cell: ({row, value}) => (
                <>
                    {assessmentPeriod?.is_final_assessment ?
                        <div className="flex gap-2 justify-between">

                            {isManagement && <TableLink
                                label="Zeugnisse"
                                to={`/certificates/assessment-periods/${assessmentPeriodId}/certificates/${row.original.id}`}
                            />}

                        </div> : <></>
                    }
                </>
            ),
        }
    ]

    return (

        <SimplePage>
            <div className="px-4 sm:px-6 lg:px-8">
                <div className="sm:flex sm:items-center">
                    <div className="sm:flex-auto">
                        <h3 className="text-lg leading-6 font-medium text-gray-900">Zeugnisse nach Klassen</h3>
                    </div>
                </div>
                <div className="flex flex-col">
                    <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">

                        <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                            {isSuccess && data && <ListViewTable data={data}
                                                                 columns={columns}
                                                                 isLoading={isLoading}
                                                                 disableGlobalSearch={false}
                                                                 getRowLink={getRowLink}
                            />}
                        </div>
                    </div>
                </div>
            </div>
        </SimplePage>
    )
}
