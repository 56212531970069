import {DetailPage} from "../../elements/Pages";
import {React} from 'react'
import {useQuery} from '@tanstack/react-query'
import ListViewTable, {TwoLineColumnWithLink} from "../../elements/tables/ListViewTable";
import {Link, useParams} from "react-router-dom";
import {EditButton, PrimaryButton} from "../../elements/Buttons";
import {homeworkApi} from "../../../api/class_register";
import {formatDate} from "../../../utils/formatters";

const columns = [
    {
        accessorKey: "get_file_name",
        id: "get_file_name",
        header: "Titel",
        cell: ({row, value}) => (

            row.original.file ? <TwoLineColumnWithLink
                link={`${row.original.file}`}
                line1={`${row.original.title}`}
                line2={`${row.original.get_file_name}`}
            /> : ""
        ),
        enableColumnFilter: false,
        classNames: "max-w-xs text-gray-500",
        // headerClassNames: "max-w-md",
    },
    {
        accessorKey: "creation_date",
        id: "creation_date",
        header: "Datum",
        columnType: "date",
        enableColumnFilter: false,
        // classNames: 'font-medium text-gray-900'
        classNames: "text-gray-500",
    },
    {
        accessorKey: "created_by_full_name",
        id: "created_by_full_name",
        header: "Erstellt von",
        enableColumnFilter: false,
    },
    {
        accessorKey: "description",
        id: "description",
        header: "Beschreibung",
        enableColumnFilter: false,
        classNames: "max-w-md",
    }
];

export default function HomeworkDetail() {

    const {id} = useParams()
    const {data} = useQuery(['homework_detail', id], () => homeworkApi('get', id), {})

    if (!data) return <></>

    return (

        <DetailPage>

            <div
                className="max-w-3xl mx-auto px-4 sm:px-6 md:flex md:items-center md:justify-between md:space-x-5 lg:max-w-7xl lg:px-8">
                <div className="flex items-center space-x-5">
                    <div>
                        <h1 className="text-2xl font-bold text-gray-900">{data.type} für {data.course.course_number} {data.course.school_subject}</h1>
                    </div>
                </div>
                <div
                    className="mt-6 flex flex-col-reverse justify-stretch space-y-4 space-y-reverse sm:flex-row-reverse sm:justify-end sm:space-x-reverse sm:space-y-0 sm:space-x-3 md:mt-0 md:flex-row md:space-x-3">

                    {data.can_edit &&
                        <EditButton iconOnly={true} withBackground={true}
                                    link={`/class-register/homework/${data.id}/edit`}
                                    label="Bearbeiten"
                        />}

                    <PrimaryButton
                        link={`/class-register/courses/${data.course.id}`}
                        label="Kursbuch"
                    />
                </div>
            </div>

            <div
                className="mt-8 max-w-3xl mx-auto grid grid-cols-1 gap-6 sm:px-6 lg:max-w-7xl lg:grid-flow-col-dense lg:grid-cols-3">
                <div className="space-y-6 lg:col-start-1 lg:col-span-2">

                    <section aria-labelledby="applicant-information-title">
                        <div className="bg-white shadow overflow-hidden sm:rounded-lg">
                            <div className="border-t border-gray-200 px-4 py-5 sm:p-0">
                                <dl className="sm:divide-y sm:divide-gray-200">
                                    <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                        <dt className="text-sm font-medium text-gray-500">Zu erledigen bis</dt>
                                        <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                            {formatDate(data.due_date)}
                                        </dd>
                                    </div>
                                    <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                        <dt className="text-sm font-medium text-gray-500">Aufgabe</dt>
                                        <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                            {data.title && <p>{data.title}</p>}
                                            <p className="whitespace-pre-wrap text-gray-500">{data.task}</p>
                                        </dd>
                                    </div>
                                    {data.students.length > 0 &&
                                        <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                            <dt className="text-sm font-medium text-gray-500">SchülerInnen</dt>
                                            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                                {data.students.map(u => u.full_name).join(', ')}
                                            </dd>
                                        </div>}
                                    {data.class_register_entry &&
                                        <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                            <dt className="text-sm font-medium text-gray-500">Kursbucheintrag</dt>
                                            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                                <Link to={`/class-register/entries/${data.class_register_entry.id}`}
                                                      className="text-imsblue-600">
                                                    {formatDate(data.class_register_entry.date)}</Link>
                                            </dd>
                                        </div>}
                                </dl>
                            </div>
                        </div>
                    </section>
                </div>

                {data.homework_materials.length > 0 &&
                    <div className="space-y-6 lg:col-start-1 lg:col-span-3">
                        <div className="bg-white shadow sm:rounded-lg sm:overflow-hidden">
                            <div className="divide-y divide-gray-200">
                                <div className="px-4 py-5 sm:px-6">
                                    <h2 id="notes-title" className="text-lg font-medium text-gray-900">
                                        Materialien
                                    </h2>
                                </div>
                                <div className="">
                                    <ListViewTable
                                        data={data.homework_materials}
                                        columns={columns}
                                        disableGlobalSearch={true}
                                        getRowLink={null}
                                    />
                                </div>
                            </div>
                        </div>

                    </div>}

            </div>

        </DetailPage>
    )
}
