import http from "./../services/httpService";


export async function getQuizOptions() {
    const resp = await http.get('/user_quiz/quiz_options/')
    return resp.data
}

export async function getQuizBase() {
    const resp = await http.get('/user_quiz/quiz_base/')
    return resp.data
}

export async function postQuizResult(data) {
    const resp = await http.post('/user_quiz/save_result/', data)
    return resp.data
}