import {React} from "react";
import {findRoom, getBookableRooms} from "../../../api/organizational";
import {SecondaryButton} from "../../elements/Buttons";
import {ArrowPathIcon, CheckCircleIcon} from "@heroicons/react/20/solid";
import {Select} from "../../elements/forms/Inputs";
import {baseHandleChange} from "../../elements/forms/Form";
import {useState} from "react";
import {useQuery} from "@tanstack/react-query";
import {useSchoolYears} from "../../../contexts/SchoolYearContext";
import {max, min} from "moment";
import {toast} from "react-toastify";

const moment = require('moment');

export function FindRoom({
                             data,
                             setAvailableRooms,
                             errors,
                             setErrors
                         }) {
    const [loading, setLoading] = useState(false)

    async function handleFindRoom() {
        setLoading(true)

        try {
            // validate that start and end time are selected
            if ((!data.start || !data.end) && !data.time) {
                const newErrors = {
                    ...errors,
                    start: "Bitte Start- und Endzeit auswählen, um einen verfügbaren Raum zu finden."
                }
                setErrors(newErrors)
                return
            }

            // make request and display available rooms or filter the rooms options
            const findRoomData = {
                start: data.start,
                end: data.end,
                rule_id: data.rule?.id || null,
                end_recurring_period: data.end_recurring_period || null,
                seats: data.seats || null,
                day_periods: data.time || null,
                date: data.date || null
            }
            const availableRooms = await findRoom(findRoomData)
            setAvailableRooms(availableRooms) // set to a separate state variable to display and so other rooms can still be selected
        } catch (e) {
            toast.error("Fehler beim Suchen nach einem Raum.")
        } finally {
            setLoading(false)
        }

    }

    const label = loading ? <><ArrowPathIcon className="h-5 w-5 mr-2" /> Suche Räume...</> : "Raum finden"
    return <SecondaryButton onClick={handleFindRoom} label={label}/>
}

export function AvailableRooms({availableRooms, onRoomSelect, msg}) {

    return <>
        <h3 className="text-lg leading-6 font-medium text-gray-900">
            Verfügbare Räume
        </h3>

        <p className="mt-1 text-gray-500 py-2">
            {availableRooms.length > 0 ? "Folgende Räume sind verfügbar für den ausgewählten Zeitraum:"
                : (msg ? msg : "Links Start- und Endzeit und optional die Anzahl Sitzplätze auswählen, um verfügbare Räume zu finden.")}
        </p>
        <ul role="list" className="divide-y space-y-1">
            {availableRooms.map((room, index) => (
                <li key={index} className="flex py-2">
                    <div className="text-sm font-medium text-gray-900">
                        {room.name} {room.seats ? `(${room.seats} Plätze insgesamt)` : ""}

                        <a href="#" onClick={() => onRoomSelect(room)}>
                            <CheckCircleIcon
                                className="h-5 w-5 text-green-700 inline-flex ml-2 align-middle"
                            /> Auswählen</a>
                    </div>
                </li>
            ))}
        </ul>
    </>
}

export function FindProjectRoom({
                             projectTime,
                            setSelectedRowId,
                             setAvailableRooms,
                             errors,
                             setErrors,
    setFindRoomData
                         }) {

    const {currentSchoolYear} = useSchoolYears();
    const [loading, setLoading] = useState(false);

    async function handleFindRoom() {

        setSelectedRowId(projectTime.id)

        if (!currentSchoolYear) return

        setAvailableRooms([])
        setLoading(true)

        // validate that start and end time are selected
        if (!projectTime.start_time || !projectTime.end_time || !projectTime.weekday) {
            const newErrors = {
                ...errors,
                start_time: "Bitte Wochentag, Start- und Endzeit auswählen, um einen verfügbaren Raum zu finden."
            }
            setErrors(newErrors)
            return
        }

        // make request and display available rooms or filter the rooms options
        const findRoomData = getProjectTimeFindRoomData(projectTime, currentSchoolYear)
        setFindRoomData(findRoomData)
        const availableRooms = await findRoom(findRoomData)
        setAvailableRooms(availableRooms) // set to a separate state variable to display and so other rooms can still be selected
        setLoading(false)
    }

    const label = loading ? <><ArrowPathIcon className="h-5 w-5 mr-2" /> Suche Räume...</> : "Raum finden"
    return <SecondaryButton onClick={handleFindRoom}
                            label={label} />
}


export function getProjectTimeFindRoomData(projectTime, currentSchoolYear) {


    function getNextWeekdayInstance(weekday, startDate) {

        var weekdayEnglish
        switch (weekday) {
            case "Mo":
                weekdayEnglish = "Monday"
                break;
            case "Di":
                weekdayEnglish = "Tuesday"
                break;
            case "Mi":
                weekdayEnglish = "Wednesday"
                break;
            case "Do":
                weekdayEnglish = "Thursday"
                break;
            case "Fr":
                weekdayEnglish = "Friday"
                break;
            case "Sa":
                weekdayEnglish = "Saturday"
                break;
            case "So":
                weekdayEnglish = "Sunday"
                break;
        }

        var weekDayToFind = moment().day(weekdayEnglish).weekday(); //change to searched day name
        var searchDate = startDate; //now or change to any date
        while (searchDate.weekday() !== weekDayToFind){
            searchDate.add(1, 'day');
        }
        return searchDate;
    }

    const minStartDate = max([currentSchoolYear.start_date, moment()])
    const firstDate = getNextWeekdayInstance(projectTime.weekday, minStartDate)

    const findRoomData = {
        start: firstDate.format("YYYY-MM-DD") + "T" + projectTime.start_time,
        end: firstDate.format("YYYY-MM-DD") + "T" + projectTime.end_time,
        rule: {id: 1}, // weekly TODO query rather than hard code
        end_recurring_period: currentSchoolYear.end_date + "T" + projectTime.end_time,
        seats: null,
        day_periods: null,
        all_rooms: true
    }
    return findRoomData

}