import React, {useEffect, useState} from "react";
import {useAuth} from "../../../contexts/AuthContext";
import checkPermission from "../../../utils/permissions";
import {Link, useParams} from "react-router-dom";
import {
    createBooking,
    deleteBooking, getParentConferenceDay,
    getRegistrationExport,
    getStudentAppointmentList, getTeacherAppointmentListPDF, getTeacherRooms, updateTeacherRooms
} from "../../../api/parent_conference";
import {SimplePage} from "../../elements/Pages";
import {PlusIcon, TrashIcon} from "@heroicons/react/20/solid";
import {toast} from "react-toastify";
import config from "../../../constants";
import {DownloadButton, PrimaryButton} from "../../elements/Buttons";
import {useQuery} from "@tanstack/react-query";
import {getRooms} from "../../../api/organizational";
import ListViewTable, {ColumnWithImage, TableDownloadLink, TableLink} from "../../elements/tables/ListViewTable";
import {getFlextimeEntriesPDF, getFlextimeEntry} from "../../../api/staff";
import {Select} from "../../elements/forms/Inputs";
import {baseHandleChange, baseHandleListChange} from "../../elements/forms/Form";


export default function TeacherRooms() {

    const {conferenceDayId} = useParams();
    const {permissions} = useAuth();
    const [isLoading, setIsLoading] = useState(false)
    const [errors, setErrors] = useState([]) // validation errors
    const [data, setData] = useState()

    const canChangeConferenceDay = checkPermission(permissions, "parent_conference.change_conferenceday")

    useEffect(() => {
        loadData()
    }, [])

    async function loadData() {
        setIsLoading(true)
        const newData = await getTeacherRooms(conferenceDayId)
        setData(newData)
        setIsLoading(false)
    }

    const {data: conferenceDay} = useQuery(
        ["parent_conference_day", conferenceDayId],
        () => getParentConferenceDay(conferenceDayId),
        {refetchInterval: 1000 * 60 * 60 * 24}
    )

    const {data: rooms} = useQuery(
        ["rooms"], getRooms, {refetchInterval: 1000 * 60 * 60 * 24}
    )

    function handleChange(evt, rowId) {
        baseHandleListChange(rowId, evt, data, setData, errors, setErrors, {})
    }

    async function handleSave() {
        try {
            const newData = await updateTeacherRooms(conferenceDayId, data)
            setData(newData)
            toast.success("Räume gespeichert")
        } catch (e) {
            toast.error("Beim Speichern ist ein Fehler aufgetreten.")
        }
    }

    const columns = [
        {
            accessorKey: 'full_name',
            id: 'staff_search',
            header: 'Mitarbeiter',
            hide: true,
            enableColumnFilter: false,
        },
        {
            accessorFn: row => row,
            id: 'staff',
            header: 'Mitarbeiter',
            columnType: 'staff',
            enableColumnFilter: false
        },
        {
            accessorKey: 'id',
            id: 'room',
            header: 'Raum',
            enableColumnFilter: false,
            cell: info => <Select
                path="room"
                label="Raum"
                className="sm:col-span-6"
                options={rooms}
                valueAttr="id"
                labelAttr="name"
                onChange={handleChange}
                errors={errors}
                data={data}
                rowId={info.getValue()}
                isClearable={false}
                valueOnly={false}
                disabled={!canChangeConferenceDay}
            />
        },
        {
            accessorKey: 'id',
            id: 'link',
            cell: info => <TableLink label="Terminplan"
                                     to={`/parent-conference/${conferenceDayId}/teachers/${info.getValue()}`}/>,
            header: 'Terminplan',
            enableColumnFilter: false,
            disableRowLink: true
        },
        {
            accessorKey: 'id',
            id: 'pdf',
            cell: info => <TableDownloadLink
                label="PDF"
                fileName={`Terminplan.pdf`}
                apiFunction={() => getTeacherAppointmentListPDF(conferenceDayId, info.getValue())}/>,
            header: 'PDF',
            enableColumnFilter: false,
            disableRowLink: true
        }
    ]

    if (!conferenceDay) return <></>;
    return (
        <>
            <SimplePage>
                <div className="px-4 sm:px-6 lg:px-8">
                    <div className="sm:flex sm:items-center">
                        <div className="sm:flex-auto">
                            <h3 className="text-lg leading-6 font-medium text-gray-900">
                                {conferenceDay.title} - Lehrer-Räume-Zuordnung
                            </h3>
                        </div>
                        <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none gap-1 flex">
                        </div>
                    </div>
                    <div className="flex flex-col mt-3 mb-3">
                        <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
                            <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">

                                {isLoading && <>Lädt...</>}

                                {canChangeConferenceDay && <PrimaryButton
                                    classNameIn="float-right"
                                    onClick={handleSave} label="Speichern"/>}

                                {data && <ListViewTable data={data}
                                                        columns={columns}
                                                        disableGlobalSearch={false}
                                />}

                                {canChangeConferenceDay && <PrimaryButton
                                    classNameIn="float-right mt-4"
                                    onClick={handleSave} label="Speichern"/>}
                            </div>
                        </div>
                    </div>
                </div>
            </SimplePage>
        </>
    );
}
