import {SimplePage} from "../../elements/Pages";
import React from "react";

import {useQuery} from "@tanstack/react-query";
import {useAuth} from "../../../contexts/AuthContext";
import ListViewTable, {TableEditLink, TableLink} from "../../elements/tables/ListViewTable";
import {DownloadButton, PrimaryButton} from "../../elements/Buttons";
import {getStudentLeadershipRoles, getStudents, getStudentsBasicExcel} from "../../../api/school";
import env from "react-dotenv";
import checkPermission from "../../../utils/permissions";


export default function StudentLeadershipRoles() {
    const {permissions} = useAuth()
    const canEdit = checkPermission(permissions, "school.change_student")

    const columns = [
        {
            accessorFn: (row) => `${row.last_name}, ${row.first_name} ${row.student_number}`,
            id: "student_search",
            header: "SchülerIn",
            hide: true,
            enableColumnFilter: false,
        },
        {
            accessorFn: (row) => row,
            id: "student",
            header: "SchülerIn",
            columnType: "student",
            enableColumnFilter: false,
            sortingFn: "lastNameSorting",
        },
        {
            accessorKey: "house",
            id: "house",
            header: "Mentorat",
            enableColumnFilter: true,
            headerClassNames: "narrow-filter",
        },
        {
            accessorKey: "grade",
            id: "grade",
            header: "Stufe",
            enableColumnFilter: true,
            headerClassNames: "narrow-filter",
            filterFn: "containsString",
        },
        {
            accessorKey: "school_class",
            id: "school_class",
            header: "Klasse",
            enableColumnFilter: true,
            headerClassNames: "narrow-filter",
            sortingFn: "schoolClassSorting",
            filterFn: "containsString",
        },
        {
            accessorKey: "leadership_roles",
            id: "leadership_roles",
            header: "Amt",
            columnType: "m2m",
            labelAttr: "name",
            enableColumnFilter: true,
            filterFn: "containsString",
        },
        {
            accessorKey: "id",
            id: "link",
            cell: ({row, value}) => (
                <>{canEdit && <TableEditLink
                    iconOnly={true}
                    label="Bearbeiten"
                    to={`/school/students/leadership-roles/edit/${row.id}`}
                />}</>
            ),
            header: "",
            enableColumnFilter: false,
            disableRowLink: true
        },
    ]

    const {isLoading, isError, isSuccess, data, error, refetch} = useQuery(
        ["student_leadership_roles"],
        getStudentLeadershipRoles,
        {refetchInterval: 1000 * 60 * 60 * 24}
    )

    function getRowLink(row) {
        return `/school/students/${row.original.id}/`
    }

    return (
        <SimplePage>
            {/*  Simple striped table */}
            <div className="px-4 sm:px-6 lg:px-8">
                <div className="sm:flex sm:items-center">
                    <div className="sm:flex-auto">
                        <h3 className="text-lg leading-6 font-medium text-gray-900 mb-2">
                            Schülerämter
                        </h3>
                    </div>
                    <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
                        {canEdit && <PrimaryButton
                            label="Amt eintragen"
                            link={`/school/students/leadership-roles/new`}
                        />}
                    </div>
                </div>
                <div className="flex flex-col">
                    <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">

                        <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                            {isSuccess && data && (
                                <ListViewTable
                                    data={data}
                                    columns={columns}
                                    isLoading={isLoading}
                                    disableGlobalSearch={false}
                                    rowSelection={null}
                                    setRowSelection={null}
                                    getRowLink={getRowLink}
                                />
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </SimplePage>
    );
}
