import {ClockIcon, FolderIcon} from "@heroicons/react/20/solid";
import React, {useState, useEffect} from "react";

import {formatDateTime, formatTimeFromDateTime} from "../../utils/formatters";
import checkPermission from "../../utils/permissions";
import {useAuth} from "../../contexts/AuthContext";
import {EditButton} from "../elements/Buttons";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function Month({ days, providerFunction, expanded = false }) {

  const { openEvent, selectedDate } = providerFunction();

  const {permissions, currentUser} = useAuth();
  const [selectedDayState, setSelectedDayState] = useState(selectedDate.date)
  const [selectedDay, setSelectedDay] = useState(null)

  useEffect(() => {
    setSelectedDay(days.find((day) => day.date === selectedDayState))
  }, [selectedDayState])

  return (
    <>
      <div className="shadow ring-1 ring-black ring-opacity-5 lg:flex lg:flex-auto lg:flex-col">
        <div className="grid grid-cols-7 gap-px border-b border-gray-300 bg-gray-200 text-center text-xs font-semibold leading-6 text-gray-700 lg:flex-none">
          <div className="bg-white py-2">Mo</div>
          <div className="bg-white py-2">Di</div>
          <div className="bg-white py-2">Mi</div>
          <div className="bg-white py-2">Do</div>
          <div className="bg-white py-2">Fr</div>
          <div className="bg-white py-2">Sa</div>
          <div className="bg-white py-2">So</div>
        </div>
        <div className="flex bg-gray-200 text-xs leading-6 text-gray-700 lg:flex-auto">
          <div className="hidden w-full lg:grid lg:grid-cols-7 lg:grid-rows-6 lg:gap-px">
            {days.map((day) => (
              <div
                key={day.date}
                className={classNames(
                  day.isCurrentMonth ? "bg-white" : "bg-gray-50 text-gray-500",
                  "relative py-2 px-3"
                )}
              >
                <time
                  dateTime={day.date}
                  className={
                    day.isToday
                      ? "flex h-6 w-6 items-center justify-center rounded-full bg-imsblue-600 font-semibold text-white"
                      : undefined
                  }
                >
                  {day.date.split("-").pop().replace(/^0/, "")}
                </time>
                {day.events.length > 0 && (
                  <ol className="mt-2">
                    {day.events.map((event) => (
                        <li key={event.event_id} onClick={() => openEvent(event)}>
                          <a href={event.href} className="group flex items-center">

                            <div className="mr-1">
                              <div
                                   style={{
                                     backgroundColor: event.event.category?.color || "gray",
                                     width: '12px',
                                     height: '12px',
                                     borderRadius: '50%'
                                   }}
                              ></div>
                            </div>

                            <p className="flex-auto truncate font-medium text-gray-900 group-hover:text-imsblue-600">
                              {event.name}
                            </p>
                            <time
                                dateTime={event.datetime}
                                className="ml-3 hidden flex-none text-gray-500 group-hover:text-imsblue-600 xl:block"
                            >
                              {formatTimeFromDateTime(event.datetime)}
                            </time>
                          </a>
                        </li>
                    ))}
                  </ol>
                )}
              </div>
            ))}
          </div>
          <div className="isolate grid w-full grid-cols-7 grid-rows-6 gap-px lg:hidden">
            {days.map((day) => (
              <button
                key={day.date}
                type="button"
                className={classNames(
                  day.isCurrentMonth ? "bg-white" : "bg-gray-50",
                  (day.isSelected || day.isToday) && "font-semibold",
                  day.isSelected && "text-white",
                  !day.isSelected && day.isToday && "text-imsblue-600",
                  !day.isSelected &&
                    day.isCurrentMonth &&
                    !day.isToday &&
                    "text-gray-900",
                  !day.isSelected &&
                    !day.isCurrentMonth &&
                    !day.isToday &&
                    "text-gray-500",
                  "flex h-14 flex-col py-1 px-1 hover:bg-gray-100 focus:z-10 wrap"
                )}
                onClick={() => {
                  setSelectedDayState(day.date)
                }}
              >
                <time
                  dateTime={day.date}
                  className={classNames(
                    day.isSelected &&
                      "flex h-6 w-6 items-center justify-center rounded-full",
                    day.isSelected && day.isToday && "bg-imsblue-600",
                    day.isSelected && !day.isToday && "bg-gray-900",
                    "ml-auto"
                  )}
                >
                  {day.date.split("-").pop().replace(/^0/, "")}
                </time>
                <span className="sr-only">{day.events.length} Termine</span>
                {day.events.length > 0 && (
                  <span className="flex flex-wrap-reverse items-start">
                    {day.events.map((event) => (
                      <span
                        key={event.id}
                        className="mobile-calendar-item mb-0.5 break-all"
                      >{event.name}</span>
                    ))}
                  </span>
                )}
              </button>
            ))}
          </div>
        </div>
      </div>

      {selectedDay?.events.length > 0 && (
        <div className="py-6 px-4 sm:px-6 lg:hidden">
          <ol className="divide-y divide-gray-100 overflow-hidden rounded-lg bg-white text-sm shadow ring-1 ring-black ring-opacity-5">
            {selectedDay.events.map((event) => {

              const canEditEvent = checkPermission(permissions, "calendar_app.change_schoolevent") || event.event.created_by === currentUser.user_id;
              return <li
                  onClick={() => openEvent(event)}
                key={event.id}
                className="group p-4 pr-6 focus-within:bg-gray-50 hover:bg-gray-50"
              >

                <div className="flex justify-between">
                  <p className="font-semibold text-gray-900">{event.event.title}</p>
                  {canEditEvent && <EditButton iconOnly={true} label="Bearbeiten" link={`/calendar/events/${event.event?.id}/edit`} />}
                </div>

                <div className="">
                  <span className="flex items-center text-gray-700"
                  >
                    <ClockIcon
                      className="mr-2 h-5 w-5 text-gray-400"
                      aria-hidden="true"
                    />
                    {formatDateTime(event.start)} bis {formatDateTime(event.end)}
                  </span>
                  <span className="mt-2 flex items-center text-gray-700"
                  >
                  <FolderIcon
                      className="mr-2 h-5 w-5 text-gray-400"
                      aria-hidden="true"
                  />
                  {event.event.category?.name}
                  </span>
                </div>

              </li>
            })}
          </ol>
        </div>
      )}
    </>
  );
}
