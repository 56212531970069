import {SimplePage} from "../../elements/Pages";
import React from 'react';
import {Link} from "react-router-dom";
import {useAuth} from "../../../contexts/AuthContext";

export default function ReportsIndex() {
    const {navigation} = useAuth()

    const tabs = navigation?.tabs_by_module?.reports
    return (

        <SimplePage>

            <div className="px-4 sm:px-6 lg:px-8">
                <div className="sm:flex sm:items-center">
                    <div className="sm:flex-auto">
                        <h3 className="text-lg leading-6 font-medium text-gray-900">Reports</h3>
                    </div>
                    <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
                    </div>
                </div>
                <div className="mt-8 flex flex-col">
                    <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
                        <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                            <div className="overflow-hidden">
                                <ul role="list" className="gap-y-2 flex flex-col">
                                    {tabs && tabs.map((t, index) => (
                                        <li key={index}>
                                            <Link className="text-imsblue-600" to={t.path}>{t.title}</Link>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


        </SimplePage>
    )
}
