import {SimplePage} from "../../elements/Pages";
import React, {useEffect, useState} from "react";

import {useQuery} from "@tanstack/react-query";
import ListViewTable, {TableEditLink, TableLink, TwoLineColumn} from "../../elements/tables/ListViewTable";
import {useParams} from "react-router-dom";
import {getClassRegisterEntriesForCourse, getClassRegisterExcelForCourse} from "../../../api/class_register";
import {formatDate} from "../../../utils/formatters";
import {getCourse} from "../../../api/courses";
import {DownloadButton, PrimaryButton, SecondaryButton} from "../../elements/Buttons";
import Pagination from "../../elements/Pagination";
import checkPermission from "../../../utils/permissions";
import {useAuth} from "../../../contexts/AuthContext";

const columns = [
    {
        accessorKey: "date",
        id: "date",
        header: "Datum",
        columnType: "date",
        enableColumnFilter: false,
        // classNames: 'font-medium text-gray-900'
        classNames: "text-gray-500",
    },
    {
        accessorKey: "course",
        id: "course",
        header: "Kurs",
        enableColumnFilter: false,
        cell: ({row, value}) => (
            <TableLink
                label={row.original.course.course_number}
                to={`/courses/${row.original.course.id}`}
            />
        ),
        disableRowLink: true
    },
    {
        accessorKey: "day_period",
        id: "day_period",
        header: "Zeit",
        columnType: "m2m",
        labelAttr: "name",
        enableColumnFilter: false
    },
    {
        accessorKey: "created_by_full_name",
        id: "created_by_full_name",
        header: "Erstellt von",
        // cell: (info) => <UserName link={true} userId={info.getValue()} />,
        enableColumnFilter: false,
    },
    {
        accessorKey: "cancelled",
        id: "cancelled",
        header: "Ausfall",
        // columnType: "yesno",
        cell: ({row, value}) => (
            <TwoLineColumn
                line1={row.original.cancelled ? "Ja" : "Nein"}
                line2={row.original.cancelled ? `${row.original.cancel_reason}` : ''}
            />),
        enableColumnFilter: false,
        headerClassNames: "narrow-filter",
    },
    {
        accessorKey: "lesson_contents",
        id: "lesson_contents",
        header: "Unterrichtsinhalt",

        cell: ({row, value}) => (
            <div className="font-grey-500 whitespace-pre-wrap">
                {row.original.lesson_contents}
            </div>
        ),
        enableColumnFilter: false,
        headerClassNames: "medium-filter",
        classNames: "max-w-md text-gray-500",
    },
    {
        accessorKey: "homework",
        id: "homework",
        header: "Hausaufgaben",
        enableColumnFilter: false,
        cell: ({row, value}) => (
            <ul>
                {row.original.homework.map((hw, index) => (
                    <li key={index}>{hw.task} {hw.due_date && <span>(zum {formatDate(hw.due_date)})</span>}</li>
                ))}
            </ul>),
        headerClassNames: "medium-filter",
        classNames: "max-w-md text-gray-500",
    }
];

const editColumns = [{
    accessorKey: "id",
    id: "link",
    cell: (info) => (
        <TableEditLink
            iconOnly={true}
            label="Bearbeiten"
            to={`/class-register/entries/${info.getValue()}/edit`}
        />
    ),
    header: "",
    disableRowLink: true,
    enableColumnFilter: false,
},]

export default function CourseDetail() {

    const {permissions} = useAuth()
    const {courseId} = useParams()
    const [rowSelection, setRowSelection] = useState([]);
    const [page, setPage] = useState(1);
    const [data, setData] = useState([])
    const [searchQuery, setSearchQuery] = useState("")
    const [totalCount, setTotalCount] = useState(0)

    const {data: course} = useQuery(
        ["course_detail", courseId],
        () => getCourse(courseId)
    )

    const {isLoading, isError, isSuccess, data: rawData, error, refetch} = useQuery(
        ["class_register_entries", courseId, page, searchQuery],
        () => getClassRegisterEntriesForCourse(courseId, page, searchQuery),
        {
            keepPreviousData : true
        }
    )

    useEffect(() => {
        setData(rawData?.results.length ? rawData.results : [])
        setTotalCount(rawData?.count)
    }, [rawData])

    useEffect(() => {
        refetch()
    }, [page, searchQuery])

    useEffect(() => {
        setPage(1) // page needs to be reset when search or filters change
    }, [searchQuery])

    function getRowLink(row) {
        return `/class-register/entries/${row.original.id}/`;
    }

    if (!course) return <></>
    const useColumns = course.can_create_class_register_entry ? [...columns, ...editColumns] : columns

    return (
        <SimplePage>
            <div className="px-4 sm:px-6 lg:px-8">
                <div className="sm:flex sm:items-center">
                    <div className="sm:flex-auto">
                        <h3 className="text-lg leading-6 font-medium text-gray-900">
                            Kursbucheinträge für {course.course_number} {course.school_subject.name}
                            {course.school_year.is_current_school_year ? '' : ` (${course.school_year.name})`}
                        </h3>
                    </div>
                    <div className="mt-4 sm:mt-0 sm:ml-16 flex gap-2">

                        <DownloadButton
                            apiFunction={() => getClassRegisterExcelForCourse(course.id)}
                            fileName={`Kursbuch ${course.course_number}.xls`}
                            label="Kursbuch herunterladen" />

                        {course.previous_course_id &&
                            <SecondaryButton link={`/class-register/courses/${course.previous_course_id}`}
                                             label="Vorjahreskursbuch"/>}

                        <SecondaryButton link={`/class-register/courses/${courseId}/homework`}
                                         label="Hausaufgaben & Klausuren"/>

                        {course.can_create_class_register_entry && (
                            <PrimaryButton link={`/class-register/entries/new/${courseId}`} label="Neuer Eintrag"/>
                        )}
                    </div>
                </div>
                <div className="flex flex-col">
                    <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">

                        <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                            <Pagination
                                page={page} setPage={setPage}
                                pageSize={50} totalResults={totalCount}
                            />
                        </div>

                        <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                            {isSuccess && data && (
                                <ListViewTable
                                    filterMode={"api"}
                                    data={data}
                                    columns={useColumns}
                                    isLoading={isLoading}
                                    disableGlobalSearch={false}
                                    rowSelection={rowSelection}
                                    setRowSelection={setRowSelection}
                                    getRowLink={getRowLink}
                                    searchQuery={searchQuery}
                                    setSearchQuery={setSearchQuery}
                                />
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </SimplePage>
    );
}
