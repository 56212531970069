import {useMessenger} from "../../../contexts/MessengerContext";
import ConversationNew from "./ConversationNew";
import ConversationDetail from "./ConversationDetail";
import {MessengerMainSideBar} from "./MessengerMainSideBar";

export function MessengerMain() {

  const {
    currentTab,
  } = useMessenger();

  return (
    <main className="min-w-0 flex-1 border-t border-gray-200 flex">
      {currentTab === "Entwürfe"
        ? <ConversationNew/>
        : <ConversationDetail/>
      }
      <MessengerMainSideBar/>
    </main>
  )
}

