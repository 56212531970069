import {SimplePage} from "../../elements/Pages";
import React, {useState} from "react";
import {useQuery, useQueryClient} from "@tanstack/react-query";
import ListViewTable, {TableEditLink} from "../../elements/tables/ListViewTable";
import {useParams} from "react-router-dom";
import {getCourse} from "../../../api/courses";
import {PrimaryButton, SecondaryButton} from "../../elements/Buttons";
import {bulkInsertTextIntoAssessments, getAndGenerateAssessments} from "../../../api/assessments";
import AssessmentDetail from "./AssessmentDetail";
import {WrenchScrewdriverIcon} from "@heroicons/react/24/outline";
import {Select, TextArea} from "../../elements/forms/Inputs";
import {baseHandleChange} from "../../elements/forms/Form";
import {toast} from "react-toastify";


export default function CourseDetail() {

    const queryClient = useQueryClient()

    const {assessmentPeriodId, courseId} = useParams()

    const [toolsOpen, setToolsOpen] = useState(false)
    const [bulkEditingData, setBulkEditingData] = useState({
        position: 'before',
        content: ''
    })
    const [errors, setErrors] = useState({})

    const textInsertPositions = [
        {value: 'before', name: 'am Anfang einfügen'},
        {value: 'after', name: 'am Ende einfügen'},
        {value: 'replace', name: 'alles überschreiben'},
    ]

    const {data: course} = useQuery(
        ["course_detail", courseId],
        () => getCourse(courseId)
    )

    const {isLoading, isError, isSuccess, data, error, refetch} = useQuery(
        ["assessments", assessmentPeriodId, courseId],
        () => getAndGenerateAssessments(assessmentPeriodId, courseId, 1, 0),
        {refetchInterval: 1000 * 60 * 60}
    )

    function getRowLink(row) {
        return `/assessments/assessment-periods/${assessmentPeriodId}/courses/${courseId}/edit/${row.index}`;
    }

    const columns = [
        {
            accessorFn: (row) => `${row.student.last_name}, ${row.student.first_name}`,
            id: "student_search",
            header: "SchülerIn",
            enableColumnFilter: false,
        },
        {
            accessorFn: (row) => (row.finalized ? 'abgeschlossen' : 'ausstehend'),
            id: "status",
            header: "Status",
            enableColumnFilter: false,
        },
        {
            accessorKey: "id",
            id: "link",
            cell: ({row, value}) => (
                <TableEditLink
                    iconOnly={true}
                    label="Bearbeiten"
                    to={`/assessments/assessment-periods/${assessmentPeriodId}/courses/${courseId}/edit/${row.index}`}
                />
            ),
            header: "",
            disableRowLink: true,
            enableColumnFilter: false,
        },
    ];

    function handleChange(evt) {
        baseHandleChange(evt, bulkEditingData, setBulkEditingData, errors, setErrors, {})
    }

    async function doBulkInsert() {
        try {
            const resp = await bulkInsertTextIntoAssessments(assessmentPeriodId, courseId, bulkEditingData)
            if (resp.success) {
                queryClient.invalidateQueries({queryKey: ["assessments", assessmentPeriodId, courseId]})
                refetch() // refetch assessments
                toast.success(resp.message)
            } else {
                toast.error(resp.message)
            }
        } catch (e) {
            console.log(e.data)
            throw (e)
        }
    }

    if (!course) return <></>

    return (
        <SimplePage>
            <div className="px-4 sm:px-6 lg:px-8">
                <div className="sm:flex sm:items-center">
                    <div className="sm:flex-auto">
                        <h3 className="text-lg leading-6 font-medium text-gray-900">
                            Beurteilungen für {course.course_number} {course.school_subject.name}
                            {course.school_year.is_current_school_year ? '' : ` (${course.school_year.name})`}
                        </h3>
                    </div>
                    <div className="mt-4 sm:mt-0 sm:ml-16 flex gap-2">

                        <SecondaryButton link={`/courses/${courseId}`} label="Kursansicht"/>

                        {data?.can_edit_assessments && (
                            <>
                                <SecondaryButton onClick={() => setToolsOpen(!toolsOpen)} label="Werkzeuge"
                                                 icon={<WrenchScrewdriverIcon className="-mr-1 ml-2 h-5 w-5"
                                                                              aria-hidden="true"/>}
                                />
                                <PrimaryButton
                                    link={`/assessments/assessment-periods/${assessmentPeriodId}/courses/${courseId}/edit`}
                                    label="Beurteilungen eingeben"/>
                            </>

                        )}
                    </div>
                </div>
                <div className="flex gap-2 justify-between">

                    <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">

                        <div className="inline-block py-2 align-middle md:px-6 lg:px-8">
                            {isSuccess && data && (
                                <ListViewTable
                                    data={data.assessments}
                                    columns={columns}
                                    isLoading={isLoading}
                                    disableGlobalSearch={true}
                                    rowSelection={null}
                                    setRowSelection={null}
                                    getRowLink={getRowLink}
                                />
                            )}
                        </div>
                    </div>

                    <div className="-mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">

                        {toolsOpen && <div className="max-w-lg inline-block py-6 align-middle md:px-6 lg:px-8">

                            <h4 className="text-md leading-6 font-medium text-gray-900 mb-2">
                                Sammelbearbeitung
                            </h4>

                            <p className="text-sm text-gray-500">Hier können Textabschnitte in alle noch nicht abgeschlossenen Beurteilungen des
                                Kurses eingefügt werden.</p>

                            <Select
                                path="position"
                                label="Position"
                                options={textInsertPositions}
                                valueAttr="value"
                                labelAttr="name"
                                className="mt-2"
                                onChange={handleChange}
                                errors={errors}
                                data={bulkEditingData}
                                isClearable={false}
                                valueOnly={true}
                            />
                            <TextArea
                                path="content"
                                label="Text"
                                className="mt-2"
                                onChange={handleChange}
                                errors={errors}
                                data={bulkEditingData}
                                rows={5}
                            />

                            <PrimaryButton classNameIn="mt-2" label="Text einfügen" onClick={(e) => doBulkInsert(e)}/>


                        </div>}
                    </div>
                </div>
            </div>


            <div className="bg-gray-100 pt-6 mt-8 px-4 sm:px-6 lg:px-8">
                <div className="sm:flex sm:items-center">
                    <div className="sm:flex-auto">
                        <h3 className="text-lg leading-6 font-medium text-gray-900">
                            Ansicht
                        </h3>
                    </div>
                </div>
                <div className="flex flex-col">
                    <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">

                        <div className="inline-block py-2 align-middle md:px-6 lg:px-8 max-w-6xl">
                            {data && data.assessments.map((a, index) => (
                                <AssessmentDetail assessment={a} inCourse={true} key={index} index={index}
                                                  canEdit={data.can_edit_assessments}/>
                            ))}

                        </div>
                    </div>
                </div>
            </div>
        </SimplePage>
    );
}
