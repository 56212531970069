import {ChevronLeftIcon, FunnelIcon, MagnifyingGlassIcon,} from "@heroicons/react/20/solid";
import {useQuery} from "@tanstack/react-query";
import React, {useEffect, useState} from "react";
import {NavLink, useParams} from "react-router-dom";
import {getStaffExport, getStaffExportComplete, getStaffList} from "../../../api/staff";
import {useAuth} from "../../../contexts/AuthContext";
import {containsStringNoCase, stringContainsItemFromArr,} from "../../../utils/helpers";
import ClearableBadge from "../../elements/forms/ClearableBadge";
import Dropdown from "../../elements/forms/Dropdown";
import StaffDetail from "./StaffDetail";
import {DownloadButton} from "../../elements/Buttons";
import checkPermission from "../../../utils/permissions";
import {LazyLoadImage} from "react-lazy-load-image-component";
import {useUserInfo} from "../../../contexts/UserInfoContext";

export default function StaffList() {
    const [searchKeyword, setSearchKeyword] = useState("");
    const [filteredData, setFilteredData] = useState([]);
    const [allRoles, setAllRoles] = useState([]);
    const [rolesToDisplay, setRolesToDisplay] = useState([]);
    const [selectedRoles, setSelectedRoles] = useState([]);
    const {permissions} = useAuth();
    const {staffId} = useParams();
    const {renderUserImage} = useUserInfo();

    const {isLoading, isError, isSuccess, data, error} = useQuery(
        ["staff"],
        getStaffList,
        {refetchInterval: 1000 * 60 * 60 * 24}
    )

    const canViewStaffComplete = checkPermission(permissions, "staff.view_staff_complete")
    const canViewExtended = checkPermission(permissions, "staff.view_staff_extended")

    useEffect(() => {
        if (data) {
            if (searchKeyword || selectedRoles.length) {
                if (searchKeyword && !selectedRoles.length) {
                    const newFilteredData = data.filter(
                        (i) =>
                            containsStringNoCase(i.last_name, searchKeyword) ||
                            containsStringNoCase(i.first_name, searchKeyword) ||
                            containsStringNoCase(i.current_roles, searchKeyword)
                    );
                    setFilteredData(newFilteredData);
                } else if (!searchKeyword && selectedRoles.length) {
                    const newFilteredData = data.filter((i) =>
                        stringContainsItemFromArr(i.current_roles, selectedRoles)
                    );
                    setFilteredData(newFilteredData);
                } else {
                    const newFilteredData = data.filter(
                        (i) =>
                            (containsStringNoCase(i.last_name, searchKeyword) ||
                                containsStringNoCase(i.first_name, searchKeyword) ||
                                containsStringNoCase(i.current_roles, searchKeyword)) &&
                            stringContainsItemFromArr(i.current_roles, selectedRoles)
                    );
                    setFilteredData(newFilteredData);
                }
            } else setFilteredData(data);
        }
    }, [data, searchKeyword, selectedRoles]);
    useEffect(() => {
        if (data) {
            const roles = data
                .reduce((partialSum, a) => {
                    return partialSum === ""
                        ? partialSum + a.current_roles
                        : partialSum + "," + a.current_roles;
                }, "")
                .replaceAll(" ", "")
                .split(",");
            setAllRoles([...new Set(roles)]);
        }
    }, [data]);
    useEffect(() => {
        if (allRoles.length) {
            const newRolesToDisplay = allRoles.filter(
                (i) => !selectedRoles.includes(i)
            );
            setRolesToDisplay(newRolesToDisplay);
        }
    }, [allRoles, selectedRoles]);

    if (!data) return <></>;
    return (
        <div
            className="relative z-0 flex flex-1 overflow-hidden"
            style={{
                maxHeight: "calc(100vh - 180px)",
            }}
        >

            <main
                className={`${
                    staffId ? "" : "hidden"
                } relative z-0 flex-1 overflow-y-auto focus:outline-none lg:order-last`}
            >
                {/* Breadcrumb */}
                <nav
                    className={`${
                        staffId ? "flex" : "hidden"
                    } items-start px-4 py-3 sm:px-6 lg:px-8 lg:hidden`}
                    aria-label="Breadcrumb"
                >
                    <NavLink
                        // href="#"
                        to={"/staff"}
                        className="inline-flex items-center space-x-3 text-sm font-medium text-gray-900"
                    >
                        <ChevronLeftIcon
                            className="-ml-2 h-5 w-5 text-gray-400"
                            aria-hidden="true"
                        />
                        <span>Staff List</span>
                    </NavLink>
                </nav>
                {staffId ? <StaffDetail/> : <></>}
            </main>
            <aside
                className={`${
                    staffId ? "hidden" : "flex flex-col"
                } w-96 flex-shrink-0 border-r border-gray-200 lg:order-first lg:flex lg:flex-col`}
            >
                <div className="px-6 pt-6 pb-4">
                    <h2 className="text-lg font-medium text-gray-900">Mitarbeiter</h2>

                    <div className="flex gap-2 justify-start mb-2">
                        {canViewExtended && <DownloadButton
                            apiFunction={() => getStaffExport()}
                            fileName={`Mitarbeiter.xls`}
                            label="Excel"/>}

                        {canViewStaffComplete && <DownloadButton
                            apiFunction={() => getStaffExportComplete()}
                            fileName={`Mitarbeiter.xls`}
                            label="Excel (komplett)"/>}
                    </div>

                    {selectedRoles.length ? (
                        <div className="flex flex-wrap items-center">
                            <h2 className="text-md font-medium text-gray-900">Rollen: </h2>
                            {selectedRoles.map((role, index) => (
                                <ClearableBadge
                                    title={role}
                                    action={() =>
                                        setSelectedRoles(selectedRoles.filter((i) => i !== role))
                                    }
                                    clearable={true}
                                    key={index}
                                />
                            ))}
                            <ClearableBadge
                                title={"Filter aufheben"}
                                action={() => setSelectedRoles([])}
                            />
                        </div>
                    ) : (
                        ""
                    )}
                    {/*<p className="mt-1 text-sm text-gray-600">*/}
                    {/*  {data?.length} Mitarbeiter*/}
                    {/*</p>*/}
                    <form className="mt-4 flex space-x-4" action="#">
                        <div className="min-w-0 flex-1">
                            <label htmlFor="search" className="sr-only">
                                Search
                            </label>
                            <div className="relative rounded-md shadow-sm">
                                <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                                    <MagnifyingGlassIcon
                                        className="h-5 w-5 text-gray-400"
                                        aria-hidden="true"
                                    />
                                </div>
                                <input
                                    type="search"
                                    name="search"
                                    id="search"
                                    className="block w-full rounded-md border-gray-300 pl-10 focus:border-imsblue-500 focus:ring-imsblue-500 sm:text-sm"
                                    placeholder="Suche"
                                    onChange={(e) => setSearchKeyword(e.target.value)}
                                />
                            </div>
                        </div>
                        <Dropdown
                            title={
                                <FunnelIcon
                                    className="h-5 w-5 text-gray-400"
                                    aria-hidden="true"
                                />
                            }
                            options={rolesToDisplay}
                            onClick={(e) => {
                                // console.log(e);
                                setSelectedRoles([...selectedRoles, e]);
                            }}
                        />
                        {/* <button
              type="submit"
              className="inline-flex justify-center rounded-md border border-gray-300 bg-white px-3.5 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-imsblue-500 focus:ring-offset-2"
            >
              <span className="sr-only">Search</span>
            </button> */}
                    </form>
                </div>
                {/* Directory list */}
                <nav className="min-h-0 flex-1 overflow-y-auto" aria-label="Directory">
                    <div className="relative">
                        <ul role="list" className="relative z-0 divide-y divide-gray-200">
                            {filteredData.map((person) => (
                                <NavLink key={person.id} to={`/staff/${person.id}`}>
                                    <li>
                                        <div
                                            className="relative flex items-center space-x-3 px-6 py-5 focus-within:ring-2 focus-within:ring-inset focus-within:ring-imsblue-500 hover:bg-gray-50">
                                            <div className="flex-shrink-0">
                                                {renderUserImage(person.user_id)}

                                                {/*<LazyLoadImage*/}
                                                {/*    className="h-10 w-10 rounded-full"*/}
                                                {/*    src={person.image.thumbnail}*/}
                                                {/*    alt=""*/}
                                                {/*/>*/}
                                            </div>
                                            <div className="min-w-0 flex-1">
                                                <div className="focus:outline-none">
                                                    {/* Extend touch target to entire panel */}
                                                    <span
                                                        className="absolute inset-0"
                                                        aria-hidden="true"
                                                    />
                                                    <p className="text-sm font-medium text-gray-900">
                                                        {person.last_name}, {person.first_name}
                                                    </p>
                                                    <p className="truncate text-sm text-gray-500">
                                                        {person.current_roles}
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                </NavLink>
                            ))}
                        </ul>
                    </div>
                </nav>
            </aside>
        </div>
    );
    // return (

    //     <SimplePage>
    //         <ul>
    //         {data.map((staff_member) => (
    //             <li>{staff_member.full_name}</li>
    //         ))}
    //         </ul>
    //     </SimplePage>
    // )
}
