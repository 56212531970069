import React, {useContext, useState, useEffect} from "react"

import jwtDecode from "jwt-decode";
import http from "./../services/httpService";
import httpNoAuth from "./../services/httpNoAuthService";
import config from "../constants";
import {getPermissionsAndNavigation} from "../api/accounts";

const apiEndpoint = config.API_URL + "/accounts/";

const AuthContext = React.createContext()

export function useAuth() {
    return useContext(AuthContext)
}

export function AuthProvider({children}) {

    const [currentUser, setCurrentUser] = useState()
    const [currentUserId, setCurrentUserId] = useState()
    const [loading, setLoading] = useState(true)
    const [permissions, setPermissions] = useState([])
    const [navigation, setNavigation] = useState([])
    const [currentMainNavItem, setCurrentMainNavItem] = useState()

    async function login(email, password) {
        const response = await httpNoAuth.post(apiEndpoint + "token/", {"username": email, "password": password});
        http.setJwt(response.data.access);
        const jwtDecoded = jwtDecode(response.data.access)
        setCurrentUser(jwtDecoded)
        setCurrentUserId(jwtDecoded.user_id)
        localStorage.setItem('access_token', response.data.access);
        localStorage.setItem('refresh_token', response.data.refresh);
        localStorage.setItem('cartList', []);
    }

    function logout() {
        setLoading(true)
        http.post(apiEndpoint + "token/logout/", {
            "refresh_token": localStorage.getItem("refresh_token")
        });
        localStorage.removeItem('access_token');
        localStorage.removeItem('refresh_token');
        localStorage.removeItem('cartList');
        http.removeJwt();
        setLoading(false)
        return
    }

    async function loginAs(userId) {
        const response = await http.post(config.API_URL + `/users_for_admin/${userId}/token/`);
        const jwtDecoded = jwtDecode(response.data.access)
        setCurrentUser(jwtDecoded)
        setCurrentUserId(jwtDecoded.user_id)
        http.setJwt(response.data.access);
        localStorage.setItem('access_token', response.data.access);
        localStorage.setItem('refresh_token', response.data.refresh);
        localStorage.setItem('cartList', []);
    }

    useEffect(() => {

        try {
            const accessToken = localStorage.getItem('access_token')
            setCurrentUser(jwtDecode(accessToken))
            setCurrentUserId(jwtDecode(accessToken).user_id)
        } catch (e) {}

        setLoading(false)

    }, [])

    useEffect(() => {
        if (currentUser) {
            getPermissionsAndNavigation().then((r) => {
                setPermissions(r.permissions)
                setNavigation(r.navigation)
                if (!currentMainNavItem) {
                    const path = window.location.pathname
                    const navItem = r.navigation.main_navigation.find((item) => item.path === path || item.sub_items.find((subItem) => path.startsWith(subItem.path)))
                    setCurrentMainNavItem(navItem)
                }
            }).catch(() => {})
        }
    }, [currentUser])

    const value = {
        currentUser,
        currentUserId,
        permissions,
        navigation,
        currentMainNavItem,
        setCurrentMainNavItem,
        login,
        logout,
        loginAs
    }

    return (
        <AuthContext.Provider value={value}>
            {!loading && children}
        </AuthContext.Provider>
    )
}
