import {Link, NavLink} from "react-router-dom";
import React, {Fragment} from 'react';
import {Menu, Transition} from "@headlessui/react";
import {useAuth} from "../../contexts/AuthContext";
import config from "../../constants";
import {IconComponents} from "../../routing/main_navigation"
import {user_navigation} from "../../routing/user_navigation"
import {classNames, clearBackendCache} from "../../utils/misc";

export function Navigation({navClass, linkClass, itemClass}) {

    const {navigation, setCurrentMainNavItem, currentMainNavItem} = useAuth();

    function handleChange(item) {
        setCurrentMainNavItem(item)
    }

    if (!navigation.main_navigation) return <></>
    return (
        <nav className={navClass}>

            {navigation.main_navigation.map((item) =>

                <NavLink
                    onClick={() => handleChange(item)}
                    key={item.pk}
                    to={item.sub_items.length > 0 ? item.sub_items[0].path : item.path}
                    className={() => {
                        return classNames(
                            currentMainNavItem?.pk === item.pk ? 'bg-imsblue-600 text-white border-white' : 'text-imsblue-100 hover:bg-imsblue-600',
                            linkClass
                        )
                    }}>

                    {item.icon ? (React.createElement(IconComponents[item.icon], {
                        className: itemClass,
                        'aria-hidden': true
                    })) : null}

                    {item.title}
                </NavLink>
            )}
        </nav>
    )
}

export function UserNavigation() {

    const {currentUser} = useAuth()

    return (
        <Menu as="div" className="ml-3 relative">
            <div>
                <Menu.Button
                    className="max-w-xs bg-white flex items-center text-sm rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-imsblue-500">
                    <span className="sr-only">Benutzermenü öffnen</span>
                    <img
                        className="h-8 w-8 rounded-full"
                        src={currentUser.avatar || "/img/avatar.png"}
                        alt="Profil"
                    />
                </Menu.Button>
            </div>
            <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
            >
                <Menu.Items
                    className="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">

                    {/* Username */}
                    {currentUser &&
                        <Menu.Item key="username">
                            <a href="#" className={classNames('block border-b px-4 py-2 text-sm text-gray-700 break-words')}>
                                Angemeldet als {currentUser.email}
                            </a>
                        </Menu.Item>}

                    {user_navigation.map((item) => (
                        <Menu.Item key={item.name}>
                            {({active}) => (
                                <Link
                                    to={item.href}
                                    className={classNames(
                                        active ? 'bg-gray-100' : '',
                                        'block px-4 py-2 text-sm text-gray-700'
                                    )}
                                >
                                    {item.name}
                                </Link>
                            )}
                        </Menu.Item>
                    ))}

                    {/* Backend */}
                    {currentUser && currentUser.is_superuser &&
                        <>
                        <Menu.Item key="admin">

                            <a href={config.ADMIN_URL}
                               className={classNames(
                                   'block px-4 py-2 text-sm text-gray-700'
                               )}
                            >
                                Backend
                            </a>
                        </Menu.Item>
                            <Menu.Item key="admin-login-as">
                                <Link
                                    to="/users"
                                    className='block px-4 py-2 text-sm text-gray-700'
                                >
                                    Anmelden als
                                </Link>
                            </Menu.Item>
                            <Menu.Item key="clear-cache">
                                <button
                                    onClick={() => clearBackendCache()}
                                    className='block px-4 py-2 text-sm text-gray-700'
                                >
                                    Backend-Cache leeren
                                </button>
                            </Menu.Item>
                        </>
                    }

                    {/* Logout */}
                    <Menu.Item key="logout">
                        {({active}) => (
                            <Link
                                to="/logout"
                                className={classNames('border-t block px-4 py-2 text-sm text-gray-700')}
                            >
                                Abmelden
                            </Link>
                        )}
                    </Menu.Item>


                </Menu.Items>
            </Transition>
        </Menu>
    )
}