import {Link} from "react-router-dom";
import React from "react";
import {EditButton} from "../../elements/Buttons";
import env from "react-dotenv";

export default function AssessmentDetail({assessment, inCourse, inStudent, inTeacher, canEdit, index}) {

    const assessmentEditLink = !inStudent ? `/assessments/assessment-periods/${assessment.assessment_period.id}/courses/${assessment.course.id}/edit/${index}`
        : `/assessments/${assessment.id}/edit`

    return (
        <div
            className="py-6 max-w-3xl mx-auto grid grid-cols-1 gap-6 lg:max-w-7xl lg:grid-flow-col-dense lg:grid-cols-4">
            <div className="bg-white space-y-6 lg:col-start-1 lg:col-span-4">

                <section aria-labelledby="applicant-information-title">
                    <div className="shadow overflow-hidden sm:rounded-lg">

                        {canEdit && <div className="px-4 py-5 float-right">
                            <EditButton iconOnly={true} link={assessmentEditLink} />
                        </div>}

                        {inCourse && <div className="px-4 py-5 sm:px-6">
                            <h3 className="text-lg leading-6 font-medium text-gray-900">
                                <Link to={`/school/students/${assessment.student.id}`}
                                      className="text-gray-900">{assessment.student.full_name}</Link>
                                , {assessment.student.student_number}
                            </h3>
                            <p className="mt-1 max-w-2xl text-sm text-gray-500">
                                {assessment.student.school_class}</p>
                        </div>}
                        {inStudent && <div className="px-4 py-5 sm:px-6">
                            <h3 className="text-lg leading-6 font-medium text-gray-900">
                                <Link to={`/courses/${assessment.course.id}`}
                                      className="text-gray-900">{assessment.course.course_number} {assessment.course.school_subject}</Link>
                            </h3>
                            <p className="mt-1 max-w-2xl text-sm text-gray-500">
                               FachlehrerIn: {assessment.course.teacher?.full_name}</p>
                        </div>}

                        {inTeacher &&
                                <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
                                    {/* Noten */}
                                    <dl className="grid grid-cols-2 gap-x-4 gap-y-8 sm:grid-cols-4">

                                        <div className="sm:col-span-2">
                                            <dt className="text-sm font-medium text-gray-500">SchülerIn</dt>
                                            <dd className="mt-1 text-sm text-gray-900">
                                                <Link to={`/school/students/${assessment.student.id}`}
                                                      className="text-gray-900">{assessment.student.full_name}</Link>
                                                , {assessment.student.student_number}
                                            </dd>
                                        </div>
                                        <div className="sm:col-span-2">
                                            <dt className="text-sm font-medium text-gray-500">Kurs</dt>
                                            <dd className="mt-1 text-sm text-gray-900">
                                                <Link to={`/courses/${assessment.course.id}`}
                                                      className="text-gray-900">{assessment.course.course_number} {assessment.course.school_subject}</Link>
                                            </dd>
                                        </div>

                                    </dl>
                                </div>
                        }

                        {/* Sondernoten und Koptnoten */}
                        {(assessment.conduct_grades.length + assessment.special_grades.length) > 0 && <div className="border-t border-gray-200 px-4 py-5 sm:px-6">

                            {/* Kopfnoten */}
                            {assessment.conduct_grades.length > 0 && <dl className="grid grid-cols-2 gap-x-4 gap-y-8 sm:grid-cols-4">
                                {assessment.conduct_grades.map((cg, index) => (
                                    <div key={index} className="sm:col-span-1">
                                        <dt className="text-sm font-medium text-gray-500">{cg.conduct_grade_definition_name}</dt>
                                        <dd className="mt-1 text-sm text-gray-900">
                                            {cg.mark}
                                        </dd>
                                    </div>
                                ))}
                            </dl>}

                            {/* Sondernoten */}
                            {assessment.special_grades.length > 0 && <dl className="grid grid-cols-2 gap-x-4 gap-y-8 sm:grid-cols-4">
                                {assessment.special_grades.map((cg, index) => (
                                    <div key={index} className="sm:col-span-1">
                                        <dt className="text-sm font-medium text-gray-500">{cg.special_grade_definition_name}</dt>
                                        <dd className="mt-1 text-sm text-gray-900">
                                            {cg.mark_display}
                                        </dd>
                                    </div>
                                ))}
                            </dl>}
                        </div>}

                        <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
                            {/* Noten */}
                            <dl className="grid grid-cols-2 gap-x-4 gap-y-8 sm:grid-cols-4">

                                <div className="sm:col-span-1">
                                    <dt className="text-sm font-medium text-gray-500">Durchschnitt {assessment.assessment_period.time_period.name}</dt>
                                    <dd className="mt-1 text-sm text-gray-900">
                                        {assessment.epoch_mark}
                                    </dd>
                                </div>

                                {assessment.final_mark && <div className="sm:col-span-1">
                                    <dt className="text-sm font-medium text-gray-500">{assessment.assessment_period.grade_name}</dt>
                                    <dd className="mt-1 text-sm text-gray-900">{assessment.final_mark}</dd>
                                </div>}

                                {assessment.final_avg && <div className="sm:col-span-1">
                                    <dt className="text-sm font-medium text-gray-500">{assessment.assessment_period.average_name}</dt>
                                    <dd className="mt-1 text-sm text-gray-900">{assessment.final_avg}</dd>
                                </div>}

                            </dl>
                        </div>

                        {/* Schriftlich / sonstige (nur KPI) */}
                        {env.SCHOOL === 'KPI' && <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
                            <dl className="grid grid-cols-2 gap-x-4 gap-y-8 sm:grid-cols-4">
                                 <div className="sm:col-span-1">
                                    <dt className="text-sm font-medium text-gray-500">Schriftlich {assessment.assessment_period.time_period.name}</dt>
                                    <dd className="mt-1 text-sm text-gray-900">{assessment.written_avg}</dd>
                                </div>

                                <div className="sm:col-span-1">
                                    <dt className="text-sm font-medium text-gray-500">Sonstige {assessment.assessment_period.time_period.name}</dt>
                                    <dd className="mt-1 text-sm text-gray-900">{assessment.other_avg}</dd>
                                </div>
                            </dl>
                        </div>}

                        <div className="border-t border-gray-200 px-4 py-5 sm:p-0">
                            <dl className="sm:divide-y sm:divide-gray-200">

                                {!inTeacher && <div className="py-4 sm:py-5 sm:grid sm:grid-cols-4 sm:gap-4 sm:px-6">
                                    <dt className="text-sm font-medium text-gray-500">FachlehrerIn</dt>
                                    <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-3">
                                        {assessment.course.teacher?.full_name}
                                    </dd>
                                </div>}
                                <div className="py-4 sm:py-5 sm:grid sm:grid-cols-4 sm:gap-4 sm:px-6">
                                    <dt className="text-sm font-medium text-gray-500">Nachhilfeempfehlung</dt>
                                    <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-3">
                                        {assessment.tutoring_recommendation}
                                    </dd>
                                </div>
                                <div className="py-4 sm:py-5 sm:grid sm:grid-cols-4 sm:gap-4 sm:px-6">
                                    <dt className="text-sm font-medium text-gray-500">Bemerkungen</dt>
                                    <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-3 whitespace-pre-wrap">
                                        {assessment.assessment}

                                        {assessment.course.is_epochal && <p className="mt-2">
                                            Das Fach {assessment.course.school_subject} wird epochal unterrichtet.
                                        </p>}
                                    </dd>
                                </div>
                            </dl>
                        </div>
                    </div>
                </section>

            </div>

        </div>

    )
}