import {useParams} from "react-router-dom";
import {React, useEffect, useState} from "react";
import {useQuery} from "@tanstack/react-query";
import {EditButton, PrimaryButton, SecondaryButton} from "../../elements/Buttons";
import checkPermission from "../../../utils/permissions";
import {useAuth} from "../../../contexts/AuthContext";
import ListViewTable, {TableDownloadLink} from "../../elements/tables/ListViewTable";
import {
    deleteProjectParticipation,
    endProjectParticipation,
    getProjectParticipationStudentDetail,
    getProjects,
    getProjectStudent,
    restartProjectParticipation
} from "../../../api/projects";
import {toast} from "react-toastify";
import {ArrowUturnUpIcon, CheckCircleIcon, XMarkIcon} from "@heroicons/react/20/solid";
import {DeleteButton} from "../../elements/DeleteButton";
import {getTutoringInvoicePDF} from "../../../api/tutoring";


export default function StudentDetail() {

    const {studentId} = useParams();
    const {permissions} = useAuth();

    const canAddParticipation = checkPermission(permissions, "projects.add_projectparticipation")
    const canEditParticipation = checkPermission(permissions, "projects.change_projectparticipation")

    let columns = [
        {
            accessorKey: "project.name",
            id: "project",
            header: "Projekt",
            enableColumnFilter: false,
        },
        {
            // accessorFn: (row) => `${row.project_times} \n ${row.project_student_times}`,
            accessorKey: "project_times",
            id: "project_times",
            header: "Zeit",
            enableColumnFilter: false,
            cell: ({row, value}) => (
                 <>
                    {row.original.project_times && <p dangerouslySetInnerHTML={{__html: row.original.project_times}}></p>}
                    {row.original.get_project_student_times_str && <p>{row.original.get_project_student_times_str}</p>}
                </>
            )
        },
        {
            accessorKey: "project.categories",
            id: "categories",
            columnType: "m2m",
            labelAttr: "name",
            header: "Kategorie",
            enableColumnFilter: false,
        },
        {
            accessorKey: "start_date",
            id: "start_date",
            header: "Beginn",
            columnType: "date",
            enableColumnFilter: false,
            classNames: "text-gray-500",
        },
        {
            accessorKey: "end_date",
            id: "end_date",
            header: "Ende",
            columnType: "date",
            enableColumnFilter: false,
            classNames: "text-gray-500",
        }
    ];

    function getRowLink(row) {
        return `/projects/${row.original.project.id}`;
    }

    if (canEditParticipation) {
        columns.push({
            accessorKey: "id",
            id: "link",
            cell: ({row, value}) => (
                <>
                    {row.original.is_current && <button title="Beenden" type="button"

                                                        className="text-imsblue-700 inline-flex py-2 mx-2"
                                                        onClick={() => handleEnd(row.original.id)}>
                        <XMarkIcon className="h-5 w-5" aria-hidden="true"/>
                    </button>}
                    {!row.original.is_current && <button title="Wiederaufnehmen" type="button"
                                                         className="text-imsblue-700 inline-flex py-2 mx-2"
                                                         onClick={() => handleRestart(row.original.id)}>
                        <ArrowUturnUpIcon className="h-5 w-5" aria-hidden="true"/>
                    </button>}

                    <EditButton iconOnly={true} link={`/projects/participation/${row.original.id}/edit`}/>
                    <DeleteButton onDelete={() => handleDelete(row.original.id)} iconOnly={true}/>
                </>
            ),
            disableRowLink: true,
            header: "Bearbeiten",
            enableColumnFilter: false,
        },)
    }

    const [errors, setErrors] = useState({}) // validation errors
    const [data, setData] = useState([])

    const {data: studentInfo} = useQuery(["project_students", studentId], () =>
        getProjectStudent(studentId), {refetchInterval: 1000 * 60 * 60}
    )

    // const {data: projects} = useQuery(["projects"],
    //     getProjects, {refetchInterval: 1000 * 60 * 60 * 24}
    // )

    useEffect(() => {
        loadData()
    }, [studentId])

    async function loadData() {
        const newData = await getProjectParticipationStudentDetail(studentId)
        setData(newData)
    }

    async function handleEnd(id) {
        try {
            const newParticipation = await endProjectParticipation(id)
            const newData = data.map(row => {
                if (row.id === id) return newParticipation
                return row
            })
            setData(newData)
            toast.success("Projektteilnahme beendet")
        } catch (e) {
            toast.error("Beim Speichern ist ein Fehler aufgetreten.")
        }
    }

    async function handleRestart(id) {
        try {
            const newParticipation = await restartProjectParticipation(id)
            const newData = data.map(row => {
                if (row.id === id) return newParticipation
                return row
            })
            setData(newData)
            toast.success("Projektteilnahme wieder aufgenommen")
        } catch (e) {
            toast.error("Beim Speichern ist ein Fehler aufgetreten.")
        }
    }

    async function handleDelete(id) {
        try {
            await deleteProjectParticipation(id)
            const newData = data.filter(row => row.id !== id)
            setData(newData)
            toast.success("Projektteilnahme gelöscht")
        } catch (e) {
            toast.error("Beim Speichern ist ein Fehler aufgetreten.")
        }
    }


    if (!studentInfo || !studentInfo.student) return <></>;


    return (
        <>
            <main className="py-10 h-full bg-gray-100">
                {/* Page header */}
                <div
                    className="max-w-3xl mx-auto px-4 sm:px-6 md:flex md:items-center md:justify-between md:space-x-5 lg:max-w-7xl lg:px-8">
                    <div className="flex items-center space-x-5">
                        {/*<div className="flex-shrink-0">*/}
                            {/*<div className="relative">*/}
                            {/*    <img*/}
                            {/*        className="h-16 w-16 rounded-full"*/}
                            {/*        src={studentInfo.student.image.detail}*/}
                            {/*        alt={studentInfo.student.full_name}*/}
                            {/*    />*/}
                            {/*    <span*/}
                            {/*        className="absolute inset-0 shadow-inner rounded-full"*/}
                            {/*        aria-hidden="true"*/}
                            {/*    />*/}
                            {/*</div>*/}
                        {/*</div>*/}
                        <div>
                            <h1 className="text-2xl font-bold text-gray-900">
                                {studentInfo.student.full_name}
                            </h1>
                            <p className="text-sm font-medium text-gray-500">
                                {studentInfo.student.student_number}
                            </p>
                        </div>
                    </div>
                    <div
                        className="mt-6 flex flex-col-reverse justify-stretch space-y-4 space-y-reverse sm:flex-row-reverse sm:justify-end sm:space-x-reverse sm:space-y-0 sm:space-x-3 md:mt-0 md:flex-row md:space-x-3">
                        <SecondaryButton label="Schülerblatt" link={`/school/students/${studentId}`}/>

                        <SecondaryButton label="Projektanwesenheit" link={`/projects/attendance/students/${studentId}`}/>

                        {canAddParticipation && (
                            <PrimaryButton
                                link={`/projects/participation/new/${studentId}`}
                                label="Projekt hinzufügen"
                            />
                        )}
                    </div>
                </div>

                <div
                    className="mt-2 max-w-3xl mx-auto grid grid-cols-1 gap-6 sm:px-6 lg:max-w-7xl lg:grid-flow-col-dense lg:grid-cols-3">

                    <div className="space-y-6 lg:col-start-1 lg:col-span-2">

                        <section aria-labelledby="notes-title">

                            {data &&
                                <ListViewTable
                                    data={data}
                                    columns={columns}
                                    disableGlobalSearch={true}
                                    getRowLink={getRowLink}
                                />}
                        </section>

                        {/* Table */}
                        <section aria-labelledby="applicant-information-title"></section>
                    </div>

                    <section
                        aria-labelledby="timeline-title"
                        className="lg:col-start-3 lg:col-span-1 mt-6"
                    >
                        <section aria-labelledby="applicant-information-title">
                            <div className="bg-white shadow overflow-hidden sm:rounded-lg">

                                <div className="px-4 py-5 sm:px-6">
                                    <h3 className="text-lg leading-6 font-medium text-gray-900">
                                        Projektverpflichtung
                                    </h3>
                                </div>
                                <div className="border-t border-gray-200 px-4 py-5">

                                    {studentInfo.has_project_requirements_fulfilled &&
                                        <div className="flex content-center">
                                            <CheckCircleIcon className="h-5 w-5 text-green-400 mr-1"
                                                             aria-hidden="true"/>
                                            Projektverpflichtung erfüllt
                                        </div>}

                                    {!studentInfo.has_project_requirements_fulfilled &&
                                        <div className="flex align-middle content-center text-red-400">
                                            <XMarkIcon className="h-5 w-5  mr-1" aria-hidden="true"/>
                                            Projektverpflichtung nicht erfüllt
                                        </div>}

                                    {studentInfo.is_projects_validated &&
                                        <div className="flex">
                                            <CheckCircleIcon className="h-5 w-5 text-green-400 mr-1"
                                                             aria-hidden="true"/>
                                            Projektbelegung validiert
                                        </div>}

                                    {!studentInfo.is_projects_validated &&
                                        <div className="flex text-red-500 ">
                                            <XMarkIcon className="h-5 w-5mr-1" aria-hidden="true"/>
                                            Projektbelegung hat Konflikte
                                        </div>}

                                    {/*<PrimaryButton label="Validierung starten" />*/}

                                </div>
                            </div>
                        </section>
                    </section>
                </div>

            </main>
        </>
    )
}

