import {NarrowPage} from "../../elements/Pages";
import {React, useEffect, useState} from "react";
import {Checkbox, DateInput, Input, RadioGroup, TextArea} from "../../elements/forms/Inputs"
import {FormSectionContent} from "../../elements/forms/FormLayout"
import Form, {baseHandleDateListChange, baseHandleListChange} from "../../elements/forms/Form"
import {Link, useNavigate, useParams} from "react-router-dom";
import {toast} from "react-toastify";
import {
    addFileToStepEntry,
    getEntry,
    getEntryOptions,
    getEntryResult,
    patchEntry,
    sendConfirmationEmail
} from "../../../api/questionnaires";
import {DownloadButton, ExportPDFButton, PrimaryButton, SecondaryButton} from "../../elements/Buttons";
import Alert from "../../elements/Alert";
import ListViewTable from "../../elements/tables/ListViewTable";
import {formatDate} from "../../../utils/formatters";
import {CheckCircleIcon, XMarkIcon} from "@heroicons/react/20/solid";


export default function EntryResult() {

    const {entryId} = useParams()

    const [options, setOptions] = useState()
    const [data, setData] = useState()
    // const [errors, setErrors] = useState({}) // validation errors
    // const [listErrors, setListErrors] = useState([])
    //
    // const [selectedFile, setSelectedFile] = useState([]);
    // const [isFilePicked, setIsFilePicked] = useState([]);
    //
    // const [currentStep, setCurrentStep] = useState(0)
    // const [stepHistory, setStepHistory] = useState([])
    //
    // const [stepError, setStepError] = useState()

    let navigate = useNavigate();

    useEffect(() => {
        loadData()
    }, [entryId])

    async function loadData() {
        const newData = await getEntryResult(entryId)
        setData(newData)
    }

    async function onConfirmation() {
        const reponse = await sendConfirmationEmail(entryId)
        toast.success("Bestätigungslink versendet.")
    }

    if (!data) return <></>

    const result = data.steps.find(s => s.id == data.entry.result)

    return (

        <>
            <main className="py-10 h-full bg-gray-100">
                <div
                    className="max-w-3xl mx-auto px-4 sm:px-6 md:flex md:items-center md:justify-between md:space-x-5 lg:max-w-7xl lg:px-8">
                    <div className="flex items-center space-x-5">
                        <div>
                            <h1 className="text-2xl font-bold text-gray-900">
                                {data.questionnaire.title}
                            </h1>
                            {/*<p className="text-sm font-medium text-gray-500">*/}
                            {/*    {tutoring.student.student_number}, Klasse {tutoring.student.school_class}*/}
                            {/*</p>*/}
                        </div>
                    </div>
                    <div
                        className="mt-6 flex flex-col-reverse justify-stretch space-y-4 space-y-reverse sm:flex-row-reverse sm:justify-end sm:space-x-reverse sm:space-y-0 sm:space-x-3 md:mt-0 md:flex-row md:space-x-3">
                        <ExportPDFButton
                            data={<div>
                                <div className="px-4 py-5">
                                    <h1 className="text-lg font-bold text-gray-900">
                                        {data.questionnaire.title}
                                    </h1>
                                </div>
                                <Content data={data} result={result} exporting={true} />
                            </div>}
                            portrait={true}
                            fileName={`${data.questionnaire.title}.pdf`}
                            label="PDF herunterladen"
                        />
                    </div>
                </div>

                <div
                    className="mt-4 max-w-3xl mx-auto grid grid-cols-1 gap-6 sm:px-6 lg:max-w-7xl lg:grid-flow-col-dense lg:grid-cols-3">
                    <div className="space-y-6 lg:col-start-1 lg:col-span-2">
                        <Content data={data} result={result} exporting={false} />
                    </div>

                    <section aria-labelledby="right-section" className="lg:col-span-1 lg:col-start-3">
                        <section aria-labelledby="entry-list">

                            {data.questionnaire.entry_needs_confirmation &&
                                <div className="space-y-6 px-4 py-4 bg-white shadow overflow-hidden sm:rounded-lg">
                                    <div className="px-4 py-5 sm:p-0 bg-white">

                                        {!data.entry.confirmed &&
                                            <div className="flex align-middle content-center text-red-400">
                                                <XMarkIcon className="h-5 w-5  mr-1" aria-hidden="true"/>
                                                Nicht bestätigt
                                            </div>}

                                        {data.entry.confirmed &&
                                            <div className="flex">
                                                <CheckCircleIcon className="h-5 w-5 text-green-400 mr-1"
                                                                 aria-hidden="true"/>
                                                Bestätigt
                                            </div>}
                                        <div className="mt-4">
                                            <PrimaryButton onClick={onConfirmation} label="Bestätigungslink zusenden"/>
                                        </div>
                                    </div>
                                </div>}
                        </section>
                    </section>
                </div>
            </main>
        </>

    )
}

function Content({data, result, exporting}) {
    return (
        <>
            <section aria-labelledby="entry-list">
                <div className={exporting ? "" : "bg-white shadow sm:rounded-lg"}>
                    <div className={exporting ? "" : "border-t border-gray-200"}>

                        <div className="px-4 py-5 sm:px-6">
                            <h2 id="applicant-information-title"
                                className="text-lg font-medium leading-6 text-gray-900">
                                Ihre Antworten
                            </h2>
                        </div>

                        <div className="px-4 py-5 sm:px-6">
                            <EntryAnswers data={data}/>
                        </div>

                    </div>
                </div>
            </section>

            <section aria-labelledby="entry-list">
                <div className={exporting ? "" : "bg-white shadow sm:rounded-lg"}>
                    <div className={exporting ? "" : "border-t border-gray-200"}>

                        <div className="px-4 py-5 sm:px-6">
                            <h2 id="applicant-information-title"
                                className="text-lg font-medium leading-6 text-gray-900">
                                Ergebnis
                            </h2>
                        </div>

                        {result && <div className="px-4 py-5 sm:px-6">
                            <p className="text-md text-gray-900">{result.content}</p>
                        </div>}
                    </div>

                </div>
            </section>
        </>
    )
}

function EntryAnswers({data}) {

    return (

        <div>
            {data.steps.map((step, index) => {
                return (
                    <div key={index} id={"step_" + step.id}
                         className={"mb-4 sm:col-span-6"}>

                        {step.type !== 'CONFIRMATION' && step.type !== 'RESULT' &&
                            <h3 className="text-md font-bold text-gray-900">{step.content}</h3>}

                        {step.type === 'TEXT' && <div>{step.step_entry.value_text}</div>}

                        {step.type === 'TEXTAREA' &&
                            <div className="whitespace-pre-wrap">{step.step_entry.value_textarea}</div>}

                        {step.type === 'CONFIRMATION' &&
                            <div>
                                <span
                                    className="text-bold">{step.content}</span>: {step.step_entry.value_confirmation ? "Ja" : "Nein"}
                            </div>}

                        {step.type === 'CHOICE' && step.step_entry.value_option.length > 0 && <>
                            <div>{step.step_entry.value_option[0].title}</div>

                            {step.step_entry.value_option[0].show_text_field && <div className="mt-2"><span
                                className="font-medium">{step.step_entry.value_option[0].text_field_title}</span>: {step.step_entry.value_text}
                            </div>}
                            {step.step_entry.value_option[0].show_date_field && <div className="mt-2"><span
                                className="font-medium">{step.step_entry.value_option[0].date_field_title}:</span> {formatDate(step.step_entry.value_date)}
                            </div>}
                        </>}

                        {step.type === 'DATE' && <div>{formatDate(step.step_entry.value_date)}</div>}

                        {step.type === 'FILE' && <div>{step.step_entry.value_file}</div>}


                        {step.type === 'ADDRESS' &&
                            <div key={index} className="grid gap-2 grid-cols-2">

                                <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
                                    <div className="sm:col-span-1">
                                        <dt className="text-sm font-medium text-gray-500">Vorname</dt>
                                        <dd className="mt-1 text-sm text-gray-900">
                                            {step.step_entry.value_first_name}
                                        </dd>
                                    </div>

                                    <div className="sm:col-span-1">
                                        <dt className="text-sm font-medium text-gray-500">Nachname</dt>
                                        <dd className="mt-1 text-sm text-gray-900">
                                            {step.step_entry.value_last_name}
                                        </dd>
                                    </div>

                                    <div className="sm:col-span-2">
                                        <dt className="text-sm font-medium text-gray-500">Adresse</dt>
                                        <dd className="mt-1 text-sm text-gray-900">
                                            {step.step_entry.value_address_1}
                                        </dd>
                                    </div>

                                    <div className="sm:col-span-1">
                                        <dt className="text-sm font-medium text-gray-500">PLZ</dt>
                                        <dd className="mt-1 text-sm text-gray-900">
                                            {step.step_entry.value_zip}
                                        </dd>
                                    </div>

                                    <div className="sm:col-span-1">
                                        <dt className="text-sm font-medium text-gray-500">Ort</dt>
                                        <dd className="mt-1 text-sm text-gray-900">
                                            {step.step_entry.value_city}
                                        </dd>
                                    </div>

                                    <div className="sm:col-span-1">
                                        <dt className="text-sm font-medium text-gray-500">Telefonnumer</dt>
                                        <dd className="mt-1 text-sm text-gray-900">
                                            {step.step_entry.value_phone}
                                        </dd>
                                    </div>
                                </dl>
                            </div>}

                        {step.additional_remarks_field &&
                            <div className="whitespace-pre-wrap">{step.step_entry.remarks}</div>}

                    </div>
                )
            })}
        </div>

    )
}
