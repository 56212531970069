import { useEffect, useRef } from "react";
import { useEvents } from "../../contexts/EventsContext";
import { getDayBetweenDates, getFormattedTime } from "../../utils/helpers";
import {min} from "moment";
import {formatDate, formatTimeFromDateTime} from "../../utils/formatters";
import moment from "moment";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}
const hours = [
  // "00:00",
  // "01:00",
  // "02:00",
  // "03:00",
  // "04:00",
  // "05:00",
  "06:00",
  "07:00",
  "08:00",
  "09:00",
  "10:00",
  "11:00",
  "12:00",
  "13:00",
  "14:00",
  "15:00",
  "16:00",
  "17:00",
  "18:00",
  "19:00",
  "20:00",
  "21:00",
  "22:00",
  "23:00",
];
const getGridValue = (event) => {
  const startDate = new Date(event.start)
  const endDate = new Date(event.end)
  const lengthInMinutes = (endDate - startDate) / (1000 * 60)
  const rowSpan = Math.round(lengthInMinutes / 5)
  const startGridRow = Math.round(((startDate.getHours() - 6) * 60 + startDate.getMinutes()) / 5) + 2
  return `${startGridRow} / span ${rowSpan}`;
};
const getMultiDayGridValue = (event, day, days, currentWeekDays) => {

  const startedBeforeThisWeek =  new Date(event.start) < currentWeekDays[0]?.date
  const endsAfterThisWeek = new Date(event.end) > currentWeekDays[currentWeekDays.length - 1]?.date

  if (day === 0 && !startedBeforeThisWeek) {
    // first day
    const startDate = new Date(event.start)
    const endDate = moment(event.start).set('hours', 23).set('minutes', 59).toDate() // end of that first day
    const lengthInMinutes = (endDate - startDate) / (1000 * 60)
    const rowSpan = Math.round(lengthInMinutes / 5)
    const startGridRow = Math.max(1, Math.round(((startDate.getHours() - 6) * 60 + startDate.getMinutes()) / 5) + 2)
    return `${startGridRow} / span ${rowSpan}`;
  }

  // last day or last visible day of that week
  else if (day === days - 1 && !endsAfterThisWeek) {
    const startDate = moment(event.end).set('hours', 6).set('minutes', 0).toDate() // use the start of the end day
    const endDate = new Date(event.end)
    const lengthInMinutes = (endDate - startDate) / (1000 * 60) //
    const rowSpan = Math.round(lengthInMinutes / 5)
    const startGridRow = 1;
    return `${startGridRow} / span ${rowSpan}`;
  }

  else {
    // it's just the entire day
    return `${1} / span ${504}`;
  }
};
const getColValue = (event) => {
  const startDateObj = new Date(event.start);
  const weekDayNum = startDateObj.getDay() === 0 ? 7 : startDateObj.getDay()
  return `col-start-${weekDayNum}`;
};
const getColMultiDay = (event, index, currentWeekDays) => {
  const firstDayOfCurrentWeek = currentWeekDays[0]?.date
  const eventStartDate = new Date(event.start)
  const startDateObj = eventStartDate < firstDayOfCurrentWeek ? firstDayOfCurrentWeek : eventStartDate
  const weekDayNum = startDateObj.getDay() === 0 ? 7 : startDateObj.getDay()
  const colStart = Math.min(weekDayNum + index, 8)
  return `col-start-${colStart}`;
};
export default function Week({ days, providerFunction }) {
  const container = useRef(null);
  const containerNav = useRef(null);
  const containerOffset = useRef(null);
  const { fetchedEventsWeek, openEvent, loadingWeek } = providerFunction();

  useEffect(() => {
    // Set the container scroll position based on the current time.
    const currentMinute = new Date().getHours() * 60;
    container.current.scrollTop =
      ((container.current.scrollHeight -
        containerNav.current.offsetHeight -
        containerOffset.current.offsetHeight) *
        currentMinute) /
      1440;
  }, []);
  const checkSingleDayEvent = (event) => {
    const startDateObj = new Date(event.start);
    const endDateObj = new Date(event.end);
    if (startDateObj.getDate() !== endDateObj.getDate()) return false;
    else if (startDateObj.getMonth() !== endDateObj.getMonth()) return false;
    else if (startDateObj.getFullYear() !== endDateObj.getFullYear())
      return false;
    return true;
  };
  return (
    <div
      ref={container}
      className="isolate flex flex-auto flex-col overflow-auto bg-white"
    >
      <div
        style={{ width: "165%" }}
        className="flex max-w-full flex-none flex-col sm:max-w-none md:max-w-full"
      >
        <div
          ref={containerNav}
          className="sticky top-0 z-30 flex-none bg-white shadow ring-1 ring-black ring-opacity-5 sm:pr-8"
        >
          <div className="grid grid-cols-7 text-sm leading-6 text-gray-500 sm:hidden">
            {days?.map((day, index) =>
              !day.isToday ? (
                <button
                  type="button"
                  className="flex flex-col items-center pt-2 pb-3"
                  key={index}
                >
                  {day.shortName}{" "}
                  <span className="mt-1 flex h-8 w-8 items-center justify-center font-semibold text-gray-900">
                    {day.date.getDate()}
                  </span>
                </button>
              ) : (
                <button
                  type="button"
                  className="flex flex-col items-center pt-2 pb-3"
                  key={index}
                >
                  {day.shortName}{" "}
                  <span className="mt-1 flex h-8 w-8 items-center justify-center rounded-full bg-imsblue-600 font-semibold text-white">
                    {day.date.getDate()}
                  </span>
                </button>
              )
            )}
          </div>
          {loadingWeek ? (
            <div role="status" className="flex justify-center w-100 py-3">
              <svg
                aria-hidden="true"
                className="mr-2 w-8 h-8 text-gray-200 animate-spin fill-imsblue-600"
                viewBox="0 0 100 101"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                  fill="currentColor"
                />
                <path
                  d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                  fill="currentFill"
                />
              </svg>
              <span className="sr-only">Lädt...</span>
            </div>
          ) : (
            ""
          )}
          <div className="-mr-px hidden grid-cols-7 divide-x divide-gray-100 border-r border-gray-100 text-sm leading-6 text-gray-500 sm:grid">
            <div className="col-end-1 w-14" />
            {days?.map((day, index) =>
              !day.isToday ? (
                <div
                  className="flex items-center justify-center py-3"
                  key={index}
                >
                  <span>
                    {day.name}{" "}
                    <span className="items-center justify-center font-semibold text-gray-900">
                      {day.date.getDate()}
                    </span>
                  </span>
                </div>
              ) : (
                <div
                  className="flex items-center justify-center py-3"
                  key={index}
                >
                  <span className="flex items-baseline">
                    {day.name}{" "}
                    <span className="ml-1.5 flex h-8 w-8 items-center justify-center rounded-full bg-imsblue-600 font-semibold text-white">
                      {day.date.getDate()}
                    </span>
                  </span>
                </div>
              )
            )}
          </div>
        </div>
        <div className="flex flex-auto">
          <div className="sticky left-0 z-10 w-14 flex-none bg-white ring-1 ring-gray-100" />
          <div className="grid flex-auto grid-cols-1 grid-rows-1">
            {/* Horizontal lines */}
            <div
              className="col-start-1 col-end-2 row-start-1 grid divide-y divide-gray-100"
              style={{ gridTemplateRows: "repeat(18, minmax(6rem, 1fr))" }}
            >
              <div ref={containerOffset} className="row-end-1 h-7"></div>
              {hours.map((hour, index) => (
                <div key={index}>
                  <div className="sticky left-0 z-20 -mt-2.5 -ml-14 w-14 pr-2 text-right text-xs leading-5 text-gray-400">
                    {hour}
                  </div>
                </div>
              ))}
              <div />
            </div>

            {/* Vertical lines */}
            <div className="col-start-1 col-end-2 row-start-1 hidden grid-cols-7 grid-rows-1 divide-x divide-gray-100 sm:grid sm:grid-cols-7">
              <div className="col-start-1 row-span-full" />
              <div className="col-start-2 row-span-full" />
              <div className="col-start-3 row-span-full" />
              <div className="col-start-4 row-span-full" />
              <div className="col-start-5 row-span-full" />
              <div className="col-start-6 row-span-full" />
              <div className="col-start-7 row-span-full" />
              <div className="col-start-8 row-span-full w-8" />
            </div>

            {/* Events */}
            <ol
              className="col-start-1 col-end-2 row-start-1 grid grid-cols-1 sm:grid-cols-7 sm:pr-8"
              style={{
                gridTemplateRows: "1.75rem repeat(216, minmax(0, 1fr)) auto",
              }}
            >
              {fetchedEventsWeek?.map((event, index) => {
                return checkSingleDayEvent(event) ? (
                  <li
                    className={`singleDayEvent relative mt-px flex ${getColValue(event)}`}
                    style={{
                      gridRow: getGridValue(event),
                      // "74 / span 12"
                    }}
                    key={index}
                    onClick={() => openEvent(event)}
                  >
                    <a
                      style={{
                        backgroundColor: event.event.category?.color ? event.event.category?.color : "rgb(149, 163, 179)",
                      }}
                      className={`group absolute inset-1 flex flex-col border-2 border-gray-200 overflow-y-auto rounded-lg p-2 text-xs leading-5 cursor-pointer overflow-visible`}
                    >
                      <p className={`order-1 font-semibold text-white`}>
                        {event.event.device ? <>{event.event.device.name}</> : (event.event.room ? <>{event.event.room.name}</> : <>{event.event.title}</> )} {(event.event.created_by_full_name ? <>- {event.event.created_by_full_name}</> : <></>)}
                        {/*{event.event.title ? event.event.title + " -" : ""}*/}
                      </p>
                      <p className={`text-gray-100 group-hover:text-gray-200`}>
                        <time dateTime={event.start}>
                          {formatTimeFromDateTime(event.start)}
                        </time>
                      </p>
                    </a>
                  </li>
                ) : (
                  <MultiDayEvent
                    event={event}
                    openEvent={openEvent}
                    key={index}
                    currentWeekDays={days}
                  />
                );
              })}
            </ol>
          </div>
        </div>
      </div>
    </div>
  );
}
const MultiDayEvent = ({ event, openEvent, currentWeekDays }) => {
  const firstDayOfCurrentWeek = currentWeekDays[0]?.date
  const eventStartDate = new Date(event.start)
  const startDate = eventStartDate < firstDayOfCurrentWeek ? firstDayOfCurrentWeek : eventStartDate
  const days = getDayBetweenDates(startDate, event.end);
  if (days < 0) return <></>
  return (
    <>
      {[...Array(days)].map((day, index) => (
        <li
          className={`relative mt-px flex ${getColMultiDay(event, index, currentWeekDays)}`}
          style={{
            // gridRow: "0 / span 6",
            gridRow: getMultiDayGridValue(event, index, days, currentWeekDays)
          }}
          key={index}
          onClick={() => openEvent(event)}
        >
          <a
            // className={`group absolute inset-1 flex flex-col overflow-y-auto rounded-lg p-2 text-xs leading-5 ${getBgValue(
            //   index
            // )}`}
            style={{
              backgroundColor: event.event.category?.color ? event.event.category?.color : "rgb(149, 163, 179)",
            }}
            className={`group absolute inset-1 flex flex-col overflow-y-auto border-2 border-gray-200 rounded-lg p-2 text-xs leading-5 cursor-pointer test`}
          >
            <p className={`order-1 font-semibold text-white`}>
              {event.event.device ? <>{event.event.device.name}</> : (event.event.room ? <>{event.event.room.name}</> : <>{event.event.title}</> )}
            </p>
          </a>
        </li>
      ))}
    </>
  );
};
