import Joi from "joi";
import {React, useEffect, useState} from "react";
import {Page} from "../../elements/Pages";
import {useNavigate, useParams} from "react-router-dom";
import {toast} from "react-toastify";
import Form, {baseHandleChange, baseHandleDateChange,} from "../../elements/forms/Form";
import {FormSectionContent} from "../../elements/forms/FormLayout";
import {Checkbox, DateTimeInput, Input, MultiSelect, Select, TextArea,} from "../../elements/forms/Inputs";
import {DangerButton, SuccessButton} from "../../elements/Buttons";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import checkPermission from "../../../utils/permissions";
import {useAuth} from "../../../contexts/AuthContext";
import {formatDate} from "../../../utils/formatters";
import {
    excursionApi,
    approveExcursion,
    rejectExcursion,
    getStudentInfoAll,
    getStudentInfoGrouped,
    getStudentInfoGroupedCourse
} from "../../../api/school";
import {useQuery, useQueryClient} from "@tanstack/react-query";
import StudentSelector from "../student_logbook/StudentSelector";
import {getStaffList} from "../../../api/staff";


export default function ExcursionCreateEdit() {
    const queryClient = useQueryClient()
    const {id} = useParams();

    const [options, setOptions] = useState()
    const [data, setData] = useState({
        id: null,
        students: []
    });
    const [errors, setErrors] = useState({}); // validation errors
    const [studentSelectorOpen, setStudentSelectorOpen] = useState(false);

    const {data: staff} = useQuery(['staff'], getStaffList, {refetchInterval: 1000 * 60 * 60 * 24})
    const {data: students} = useQuery(["student_info"], getStudentInfoAll, {refetchInterval: 1000 * 60 * 60 * 24});
    const {data: groups} = useQuery(["student_info_grouped"], getStudentInfoGrouped, {refetchInterval: 1000 * 60 * 60 * 24})
    const { data: courses } = useQuery(["student_info_grouped_course"], getStudentInfoGroupedCourse,{refetchInterval: 1000 * 60 * 60 * 24})

    const {permissions, currentUser} = useAuth()
    const canChangePurchaseRequest = checkPermission(permissions, "school.change_excursion")

    let navigate = useNavigate();

    useEffect(() => {
        loadData();
    }, [id]);


    useEffect(() => {
        if (!data.end) {
            setData({...data, end: data.start})
        }
    }, [data.start]);

    async function loadData() {
        // get options
        const newOptions = await excursionApi('options')
        setOptions(newOptions)

        if (!id) return
        const newData = await excursionApi("get", id)
        setData(newData)
    }

    const schema = {
        title: Joi.string().required(),
        excursion_type: Joi.string().required(),
        start: Joi.date().required(),
        end: Joi.date().required().min(
            Joi.ref("start", {
                adjust: (value) => (value ? value.setSeconds(1) : new Date(1970, 1, 1)),
            })
        ).messages({"date.min": "Ende muss nach dem Start liegen"}),
    }

    const title = id || data.id ? "Schulveranstaltung bearbeiten" : "Schulveranstaltung erstellen";
    let successMessage = "Schulveranstaltung gespeichert"

    async function onSave() {
        let newData
        try {
            if (!data.id) newData = await excursionApi("post", null, data); else newData = await excursionApi("patch", null, data);
            setData(newData);
            toast.success(successMessage)
            queryClient.invalidateQueries({ queryKey: ['excursions'] })
            navigate(`/school/excursions`)
        } catch (e) {
            toast.error(e)
        }
    }

    async function onDelete() {
        await excursionApi("delete", data.id);
        toast.success("Schulveranstaltung gelöscht.");
        navigate(`/school/excursions`)
    }

    async function onApprove() {
        try {
            const newData = await approveExcursion(data.id);
            setData(newData);
            toast.success("Schulveranstaltung genehmigt.");
            navigate(`/school/excursions`)
        } catch (e) {
            toast.error(e)
        }
    }

    async function onReject() {
        try {
            const newData = await rejectExcursion(data.id);
            setData(newData);
            toast.success("Schulveranstaltung abgelehnt.");
            navigate(`/school/excursions`)
        } catch (e) {
            toast.error(e)
        }
    }

    function handleChange(evt) {
        baseHandleChange(evt, data, setData, errors, setErrors, schema);
    }

    function handleDateChange(date, path) {
        baseHandleDateChange(date, path, data, setData, errors, setErrors, schema, true);
    }

    function handleMultiSelect(selectedOptions, path) {
        let newData = {...data}
        newData[path] = selectedOptions
        setData(newData)
    }

    if (!data || !options) return <></>
    return (<>
            <StudentSelector
                open={studentSelectorOpen}
                setOpen={setStudentSelectorOpen}
                allStudents={students}
                setData={setData}
                data={data}
                path="students"
                modalTitle={"SchülerInnen auswählen"}
                filters={{
                    ...groups,
                    ...courses,
                }}
                parentLabelAttr={{
                    courses: "course_number", houses: "name", school_classes: "group", grades: "name"
                }}
                selectFromAllStudents={true}
            />

            <Page title={title}>
                <div
                    className="max-w-3xl mx-auto grid grid-cols-1 gap-6 lg:max-w-7xl lg:grid-flow-col-dense lg:grid-cols-6">
                    <div className="space-y-6 lg:col-span-4">
                        <Form
                            id="createExcursion"
                            onSave={onSave}
                            onDelete={onDelete}
                            data={data}
                            setData={setData}
                            errors={errors}
                            setErrors={setErrors}
                            successMessage={successMessage}
                            schema={schema}
                        >
                            <FormSectionContent>

                                <Input
                                    path="title"
                                    type="text"
                                    label="Titel"
                                    className="sm:col-span-6"
                                    onChange={handleChange}
                                    errors={errors}
                                    data={data}
                                />

                                <TextArea
                                    path="description"
                                    label="Beschreibung"
                                    className="sm:col-span-6"
                                    onChange={handleChange}
                                    errors={errors}
                                    data={data}
                                    rows={3}
                                />

                                <DateTimeInput
                                    className="col-span-3"
                                    path="start"
                                    label="Von"
                                    onChange={handleDateChange}
                                    errors={errors}
                                    data={data}
                                />
                                <DateTimeInput
                                    className="col-span-3"
                                    path="end"
                                    label="Bis"
                                    onChange={handleDateChange}
                                    errors={errors}
                                    data={data}
                                />

                                <MultiSelect
                                    path="students"
                                    label="SchülerIn"
                                    className="sm:col-span-6"
                                    options={students}
                                    valueAttr="id"
                                    labelAttr="full_name"
                                    onChange={(selectedOptions) => handleMultiSelect(selectedOptions, "students")}
                                    errors={errors}
                                    data={data}
                                    isClearable={false}
                                    valueOnly={true}
                                />

                                <div className="sm:col-span-6 flex">
                                    <button
                                        type="button"
                                        onClick={() => setStudentSelectorOpen(true)}
                                        className="flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-imsblue-600 hover:bg-imsblue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-imsblue-500 mr-3"
                                    >
                                        SchülerInnen auswählen
                                    </button>
                                    {data.students.length ? (<span
                                            className="bg-imsblue-100 text-imsblue-700 px-3 py-2 font-medium text-sm rounded-md"
                                        >
                      <span className="inline-flex justify-center items-center
                      mr-2 w-4 h-4 text-xs font-semibold text-blue-800 bg-blue-200 rounded-full">
                        {data.students.length}
                      </span>
                      SchülerInnen ausgewählt
                    </span>) : ("")}
                                </div>

                                <MultiSelect
                                    path="staff"
                                    label="Begleitet von"
                                    className="sm:col-span-6"
                                    options={staff}
                                    valueAttr="id"
                                    labelAttr="full_name"
                                    onChange={(selectedOptions) => handleMultiSelect(selectedOptions, 'staff')}
                                    errors={errors}
                                    data={data}
                                    isClearable={true}
                                    valueOnly={false}
                                />

                                <Select
                                    path="excursion_type"
                                    label="Kategorie"
                                    className="sm:col-span-3"
                                    options={options.excursion_type.choices}
                                    valueAttr="value"
                                    labelAttr="display_name"
                                    onChange={handleChange}
                                    errors={errors}
                                    data={data}
                                    isClearable={false}
                                    valueOnly={true}
                                />

                                <Checkbox
                                    path="absent_for_lunch"
                                    label="SchülerInnen abwesend zum Mittagessen"
                                    className="sm:col-span-6"
                                    onChange={handleChange}
                                    errors={errors}
                                    hideLegend={true}
                                    data={data}/>

                                <Checkbox
                                    path="absent_for_dinner"
                                    label="SchülerInnen abwesend zum Abendessen"
                                    className="sm:col-span-6"
                                    onChange={handleChange}
                                    errors={errors}
                                    hideLegend={true}
                                    data={data}/>

                                <Checkbox
                                    path="packed_lunch"
                                    label="Lunchpaket gewünscht"
                                    className="sm:col-span-6"
                                    onChange={handleChange}
                                    errors={errors}
                                    hideLegend={true}
                                    data={data}/>

                                {data.created_by && <p className="mt-4 text-md sm:col-span-6">
                                    Beantragt von {data.created_by_full_name} am {formatDate(data.creation_date)}
                                </p>}

                            </FormSectionContent>


                        </Form>


                        {canChangePurchaseRequest && data.id &&
                            <div className="approveRejectButtons justify-end flex gap-2 mt-4 mb-2">

                                {data.status !== 'APPROVED' && <SuccessButton
                                    label={"Antrag genehmigen"}
                                    onClick={onApprove}
                                />}
                                {data.status !== 'REJECTED' && <DangerButton
                                    onClick={onReject}
                                    label="Antrag ablehnen"
                                />}
                            </div>}
                        <span
                            onClick={() => navigate(-1)}
                            className="cursor-pointer float-right mt-2 font-medium text-imsblue-600"
                        >Zurück</span>
                    </div>

                    <div className="lg:col-span-2 pl-3 sm:max-h-[80%] min-h-0 flex-1 overflow-y-auto">
                    </div>

                </div>
            </Page>
        </>);
}

