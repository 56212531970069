import {SimplePage} from "../../elements/Pages";
import React from "react";

import {useQuery} from "@tanstack/react-query";
import ListViewTable, {TableLink} from "../../elements/tables/ListViewTable";
import {getProjectStudents} from "../../../api/projects";
import {getCareerStudents, getCareerStudentsExcel} from "../../../api/career";
import {getConductGradeListDataExcel} from "../../../api/assessments";
import {DownloadButton} from "../../elements/Buttons";

const columns = [
    {
        accessorFn: (row) =>
            `${row.student.last_name}, ${row.student.first_name} ${row.student.student_number}`,
        id: "student_search",
        header: "SchülerIn",
        hide: true,
        enableColumnFilter: false,
    },
    {
        accessorKey: 'student',
        id: 'student',
        header: 'SchülerIn',
        columnType: 'student',
        enableColumnFilter: false,
        sortingFn: "lastNameSorting",
    },
    {
        accessorKey: "student.school_class",
        id: "school_class",
        header: "Klasse",
        enableColumnFilter: true,
        headerClassNames: "narrow-filter",
    },
    {
        accessorKey: "student.house",
        id: "house",
        header: "Mentorat",
        enableColumnFilter: true,
        headerClassNames: "narrow-filter",
    },
    {
        accessorKey: "credits",
        id: "credits",
        header: "Credits",
        enableColumnFilter: false,
        headerClassNames: "narrow-filter",
    }
];

export default function Students() {

    const {isLoading, isError, isSuccess, data, error, refetch} = useQuery(
        ["career_student_list"],
        () => getCareerStudents(),
        {refetchInterval: 1000 * 60 * 60 * 24}
    );

    function getRowLink(row) {
        return `/career/students/${row.original.student.id}/`;
    }

    return (
        <SimplePage>
            <div className="px-4 sm:px-6 lg:px-8">
                <div className="sm:flex sm:items-center">
                    <div className="sm:flex-auto">
                        <h3 className="text-lg leading-6 font-medium text-gray-900">
                            Studien- & Berufsvorbereitung nach Schülern
                        </h3>
                    </div>
                    <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
                        <DownloadButton
                            apiFunction={() => getCareerStudentsExcel()}
                            fileName={`Berufsvorbereitung.xls`}
                            label="Excel herunterladen" />
                    </div>
                </div>
                <div className="flex flex-col">
                    <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
                        <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                            {isSuccess && data && (
                                <ListViewTable
                                    data={data}
                                    columns={columns}
                                    isLoading={isLoading}
                                    disableGlobalSearch={false}
                                    getRowLink={getRowLink}
                                />
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </SimplePage>
    );
}
