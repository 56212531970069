import {SimplePage} from "../../elements/Pages";
import React, {useEffect} from 'react';

import {useQuery} from '@tanstack/react-query'
import {Link, useNavigate} from "react-router-dom";
import {getMyStudents} from "../../../api/school";
import {useAuth} from "../../../contexts/AuthContext";
import {useUserInfo} from "../../../contexts/UserInfoContext";


export default function MyBookCodes() {

    const navigate = useNavigate()
    const {currentUser} = useAuth()
    const {renderUserImage} = useUserInfo();
    const {
        data,
        isSuccess
    } = useQuery(['my_students', currentUser.user_id], getMyStudents, {refetchInterval: 1000 * 60 * 60 * 24})

    function getLink(studentId) {
        return `/school/book-codes/${studentId}/`
    }

    useEffect(() => {
        if (isSuccess && data.length === 1) {
            navigate(getLink(data[0].id))
        }
    }, [data])

    return (
        <SimplePage>
            <div className="px-4 sm:px-6 lg:px-8">
                <div className="sm:flex sm:items-center">
                    <div className="sm:flex-auto">
                        <h3 className="text-lg leading-6 font-medium text-gray-900">Meine Bücher-Codes</h3>
                    </div>
                </div>
                <div className="flex flex-col max-w-sm">
                    <ul role="list" className="divide-y divide-gray-200">
                        {isSuccess && data && data.map((student) => (
                            <li key={student.id} className="flex py-4">
                                <Link key={student.id} to={getLink(student.id)} className="flex">
                                    {renderUserImage(student.user_id)}
                                    <div className="ml-3">
                                        <p className="text-sm font-medium text-gray-900">{student.full_name}</p>
                                        <p className="text-sm text-gray-500">{student.student_number}</p>
                                    </div>
                                </Link>
                            </li>
                        ))}
                    </ul>
                </div>
            </div>
        </SimplePage>
    )
}
