import {React, useEffect, useState} from "react";
import {getNewStudents, getNewStudentsPDF} from "../../../api/school";
import {DownloadButton, ExportPDFButton, PrimaryButton} from "../../elements/Buttons";
import {useSchoolYears} from "../../../contexts/SchoolYearContext";
import {baseHandleDateChange} from "../../elements/forms/Form";
import {DateInput} from "../../elements/forms/Inputs";
import NewStudentDetail from "./NewStudentDetail";
import {getCareerStudentsExcel} from "../../../api/career";


export default function NewStudents() {

    const [errors, setErrors] = useState([]) // validation errors
    const {currentSchoolYear} = useSchoolYears();
    const [students, setStudents] = useState([])

    const [entryDateData, setEntryDateData] = useState({
        entry_date_from: null,
        entry_date_to: null
    })
    const schema = {}

    function handleDateChange(date, path) {
        baseHandleDateChange(date, path, entryDateData, setEntryDateData, errors, setErrors, schema, false)
    }

    useEffect(() => {
        loadData()
    }, [currentSchoolYear]);

    async function loadData() {

        if (currentSchoolYear) {

            const dates = {
                entry_date_from: currentSchoolYear.start_date,
                entry_date_to: currentSchoolYear.end_date
            }

            setEntryDateData(dates)

            const newData = await getNewStudents(dates.entry_date_from, dates.entry_date_to)
            setStudents(newData)
        }

    }

    async function handleFilter() {
        const newData = await getNewStudents(entryDateData.entry_date_from, entryDateData.entry_date_to)
        setStudents(newData)
    }

    const exportPDFData = students ? students.map((student, index) => (
        <NewStudentDetail key={index} student={student} isCompact={true} downloading={true} />
    )) : []

    if (!students) return <></>;

    return (
        <>
            <main className="py-10 h-full bg-gray-100">
                {/* Page header */}
                <div
                    className="max-w-3xl mx-auto px-4 sm:px-6 md:flex md:items-center md:justify-between md:space-x-5 lg:max-w-7xl lg:px-8">
                    <div className="flex items-center space-x-5">
                        <div>
                            <h1 className="text-2xl font-bold text-gray-900">
                                Neue Schüler
                            </h1>
                        </div>
                    </div>
                    <div
                        className="mt-6 flex flex-col-reverse justify-stretch space-y-4 space-y-reverse sm:flex-row-reverse sm:justify-end sm:space-x-reverse sm:space-y-0 sm:space-x-3 md:mt-0 md:flex-row md:space-x-3">
                    </div>
                </div>

                <div
                    className="mt-8 max-w-3xl mx-auto grid grid-cols-1 gap-6 sm:px-6 lg:max-w-7xl lg:grid-flow-col-dense lg:grid-cols-3">
                    <div className="space-y-6 lg:col-start-1 lg:col-span-3">

                        <section
                            aria-labelledby="timeline-title"
                            className="lg:col-start-3 lg:col-span-1"
                        >

                            <section aria-labelledby="applicant-information-title">
                                <div className="bg-white shadow overflow-hidden sm:rounded-lg">
                                    <div className="px-4 py-4 gap-x-4 flex flex-wrap justify-start items-end">

                                        <DateInput
                                            className="min-w-[30%] md:min-w-0"
                                            path="entry_date_from"
                                            label="Datum von"
                                            onChange={handleDateChange}
                                            errors={errors}
                                            data={entryDateData}
                                            todayButton={true}
                                            tomorrowButton={false}
                                        />
                                        <DateInput
                                            className="min-w-[30%] md:min-w-0"
                                            path="entry_date_to"
                                            label="Bis"
                                            onChange={handleDateChange}
                                            errors={errors}
                                            data={entryDateData}
                                            todayButton={false}
                                            tomorrowButton={false}
                                        />

                                        <PrimaryButton label="Filtern"
                                                       onClick={handleFilter}></PrimaryButton>

                                        <div className="mt-2 md:mt-0">

                                            <DownloadButton
                                                apiFunction={() => getNewStudentsPDF(entryDateData.entry_date_from, entryDateData.entry_date_to)}
                                                fileName={`Neue Schüler.pdf`}
                                                label="PDF herunterladen" />

                                        {/*<ExportPDFButton*/}
                                        {/*    data={exportPDFData}*/}
                                        {/*    fileName={`Neue Schüler - ${entryDateData.entry_date_from} - ${entryDateData.entry_date_to}.pdf`}*/}
                                        {/*    label="PDF exportieren"*/}
                                        {/*/>*/}
                                        </div>
                                    </div>

                                </div>
                            </section>

                        </section>


                        <section aria-labelledby="applicant-information-title">
                            {students && students.map((student, index) => (
                                <NewStudentDetail key={index} student={student} isCompact={true} downloading={false} />
                            ))}
                        </section>

                    </div>


                </div>

            </main>
        </>
    );
}
