import React from 'react';
import {Route, Routes} from "react-router-dom";
import DownloadList from "./DownloadList";
import DownloadCreateEdit from "./DownloadsCreateEdit"


function DownloadRouter() {

    return (
        <Routes>
            <Route index element={<DownloadList/>}/>
            <Route path="/new" element={<DownloadCreateEdit/>}/>
            <Route path="/:id/edit" element={<DownloadCreateEdit/>}/>
        </Routes>
    )
}


export default function Downloads() {

    // const button = checkPermission(permissions, 'downloads.add_download') ?
    //     <Link to="/downloads/new">
    //         <button
    //             type="button"
    //             className="inline-flex items-center justify-center rounded-md border border-transparent bg-imsblue-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-imsblue-700 focus:outline-none focus:ring-2 focus:ring-imsblue-500 focus:ring-offset-2 sm:w-auto"
    //         >Neue Datei hochladen
    //         </button>
    //     </Link> : <></>

    return (
        <DownloadRouter/>

        // <SimplePage>
        //     <ModulePage title="Downloads">
        //         <DownloadRouter/>
        //     </ModulePage>
        // </SimplePage>
    )
}
