import {SimplePage} from "../../elements/Pages";
import React, {useState} from "react";

import {useQuery} from "@tanstack/react-query";
import {useAuth} from "../../../contexts/AuthContext";
import ListViewTable, {TableLink, TwoLineColumnWithLink,} from "../../elements/tables/ListViewTable";
import checkPermission from "../../../utils/permissions";
import {Link} from "react-router-dom";
import {deleteSupportPlanMeetings, supportPlanMeetingApi} from "../../../api/boarding_school";

const columns = [
    {
        accessorKey: "id",
        id: "select",
        columnType: "select",
    },
    {
        accessorFn: (row) =>
            `${row.student.last_name}, ${row.student.first_name} ${row.student.student_number}`,
        id: "student_search",
        header: "SchülerIn",
        hide: true,
        enableColumnFilter: false,
    },
    {
        accessorKey: "student",
        id: "student",
        header: "SchülerIn",
        columnType: "student",
        enableColumnFilter: false,
        sortingFn: "lastNameSorting",
    },
    {
        accessorKey: "student.house",
        id: "house",
        header: "Mentorat",
        enableColumnFilter: true,
        headerClassNames: "narrow-filter",
    },
    {
        accessorKey: "student.school_class",
        id: "school_class",
        header: "Klasse",
        headerClassNames: "narrow-filter",
    },
    {
        accessorKey: "start",
        id: "start",
        header: "Termin",
        columnType: "date_time",
        enableColumnFilter: false,
        // classNames: 'font-medium text-gray-900'
        classNames: "text-gray-500",
    },
    {
        accessorFn: (row) => `${row.student.main_mentor}`,
        id: "main_mentor",
        header: "Stammmentor",
        enableColumnFilter: false,
        sortingFn: "lastNameSorting",
    },
    {
        accessorKey: "additional_participants",
        id: "additional_participants",
        header: "Weitere Teilnehmer",
        columnType: "m2m",
        labelAttr: "full_name",
        enableColumnFilter: false,
        enableSorting: false,
    },
    {
        // accessorFn: (row) => <TwoLineColumn line1={row.handout_required ? "Erforderlich" : "Nicht erforderlich"}
        //                                     line2={"line 2"} />,
        accessorKey: "handout_required",
        id: "handout_required",
        header: "Tischvorlage",
        enableColumnFilter: false,
        enableSorting: false,
        disableRowLink: true,
        cell: ({row, value}) => (
            <TwoLineColumnWithLink
                link={row.original.handout}
                line1={row.original.handout_required ? "Erforderlich" : "Nicht erforderlich"}
                line2={`${row.original.get_file_name}`}
            />)
    },
    {
        accessorKey: "id",
        id: "link",
        cell: (info) => (
            <TableLink
                label="Bearbeiten"
                to={`/boarding-school/support-plan-meetings/${info.getValue()}/edit`}
            />
        ),
        header: "Bearbeiten",
        enableColumnFilter: false,
        disableRowLink: true
    },
];

export default function SupportPlanMeetings() {
    const {permissions, currentUser} = useAuth();
    const [rowSelection, setRowSelection] = useState({});

    const {
        isLoading,
        isError,
        isSuccess,
        data,
        error,
        refetch
    } = useQuery(['support_plan_meetings', currentUser], () => supportPlanMeetingApi('get'), {})

    async function handleDelete() {
        await deleteSupportPlanMeetings(rowSelection)
        refetch()
    }

    function getRowLink(row) {
        return `/boarding-school/support-plan-meetings/${row.original.id}/edit`;
    }

    return (
        <SimplePage>
            <div className="px-4 sm:px-6 lg:px-8">
                <div className="sm:flex sm:items-center">
                    <div className="sm:flex-auto">
                        <h3 className="text-lg leading-6 font-medium text-gray-900">
                            Hilfeplangespräche
                        </h3>
                    </div>
                    <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
                        {checkPermission(
                            permissions,
                            "boarding_school.add_supportplanmeeting"
                        ) && (
                            <Link to="/boarding-school/support-plan-meetings/new">
                                <button
                                    type="button"
                                    className="inline-flex items-center justify-center rounded-md border border-transparent bg-imsblue-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-imsblue-700 focus:outline-none focus:ring-2 focus:ring-imsblue-500 focus:ring-offset-2 sm:w-auto"
                                >
                                    Neuer Eintrag
                                </button>
                            </Link>
                        )}
                    </div>
                </div>
                <div className="flex flex-col">
                    <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">

                        <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                            {isSuccess && data && (
                                <ListViewTable
                                    data={data}
                                    columns={columns}
                                    isLoading={isLoading}
                                    disableGlobalSearch={false}
                                    rowSelection={rowSelection}
                                    setRowSelection={setRowSelection}
                                    getRowLink={getRowLink}
                                    onDelete={handleDelete}
                                />
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </SimplePage>
    );
}
