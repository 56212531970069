import React, {useContext, useEffect, useMemo, useState} from "react";
import {useQuery} from "@tanstack/react-query";
import {getDeviceBookingsByWeek, getDevices} from "../api/organizational";
import {getWeekNumber} from "../utils/helpers";
import {useNavigate} from "react-router-dom";

const keys = ["id"];
const DeviceBookingContext = React.createContext();
const today = new Date();

export function useDeviceBookings() {
    return useContext(DeviceBookingContext);
}

export function DeviceBookingProvider({children}) {
    const [view, setView] = useState("week");
    const [selectedDate, setSelectedDate] = useState({
        year: today.getFullYear(),
        month: today.getMonth() + 1,
        date: today.getDate(),
    });
    const [selectedDateWeek, setSelectedDateWeek] = useState(
        getWeekNumber(today)
    );
    const [filter, setFilter] = useState({
        device: null,
        groups: [],
    });
    const navigate = useNavigate();
    const [selectedEvent, setSelectedEvent] = useState(null);
    const [eventDetailOpen, setEventDetailOpen] = useState(false);
    const [eventtargetGroupsMonth, setEventtargetGroupsMonth] = useState([]);
    const [eventtargetGroupsWeek, setEventtargetGroupsWeek] = useState([]);
    const [loaded, setLoaded] = useState(false);

    const loadingMonth = false

    const {
        data: eventsByWeek,
        loading: loadingWeek,
        isRefetching: isRefetchingWeek,
    } = useQuery(["device_bookings", selectedDateWeek], () =>
            getDeviceBookingsByWeek(selectedDateWeek[0], selectedDateWeek[1]),
        {refetchInterval: 1000 * 60 * 60}
    );

    const {data: devices} = useQuery(["devices"], getDevices, {refetchInterval: 1000 * 60 * 60 * 24})

    const openEvent = (event) => {
        setSelectedEvent(event)
        setEventDetailOpen(true);
        if (view === "month")
            navigate(
                `/service/device-bookings/calendar?event_id=${event.event_id}&year=${selectedDate.year}&month=${selectedDate.month}`
            );
        else
            navigate(
                `/service/device-bookings/calendar?event_id=${event.event_id}&year=${selectedDate.year}&week=${selectedDateWeek[1]+1}&date=${selectedDate.date}`
            );
    };
    useEffect(() => {
        const weekNumber = getWeekNumber(new Date(selectedDate.year, selectedDate.month-1, selectedDate.date));
        setSelectedDateWeek(weekNumber);
    }, [selectedDate]);
    useEffect(() => {
        // todo probably make sure we maintain the selected device
        if (!eventDetailOpen && loaded) navigate("/service/device-bookings/calendar");
        else setLoaded(true);
    }, [eventDetailOpen]);

    const fetchedEventsMonth = []

    const fetchedEventsWeek = useMemo(() => {
        if (eventsByWeek) {
            if (filter.device) {
                let filteredEvents = [];
                filteredEvents = [
                    ...filteredEvents,
                    ...eventsByWeek.filter((i) =>
                        filter.device === i.event.device.id
                    ),
                ];
                return filteredEvents;
            }
            return eventsByWeek;
        }
        return null;
    }, [eventsByWeek, filter]);

    const value = {
        selectedDate,
        setSelectedDate,
        fetchedEventsMonth,
        fetchedEventsWeek,
        view,
        setView,
        selectedEvent,
        openEvent,
        eventDetailOpen,
        setEventDetailOpen,
        devices,
        eventtargetGroupsMonth,
        eventtargetGroupsWeek,
        filter,
        setFilter,
        loadingWeek: loadingWeek || isRefetchingWeek,
    };

    return (
        <DeviceBookingContext.Provider value={value}>
            {!loadingWeek && !loadingMonth && children}
        </DeviceBookingContext.Provider>
    );
}
