import React from 'react';
import {Route, Routes} from "react-router-dom";
import TutoringList from "./TutoringList";
import TutoringDetail from "./TutoringDetail";
import TutoringCreateMultiple from "./TutoringsCreateMultiple";
import MaterialCreateEdit from "./MaterialCreateEdit";
import TutoringRequest from "./TutoringRequest";
import TutoringEdit from "./TutoringEdit";
import MyTutorings from "./MyTutorings";

function TutoringRouter() {

    return (
        <Routes>

            <Route path="list" element={<TutoringList/>}/>
            <Route path="my" element={<MyTutorings/>}/>
            <Route path="students/:studentId" element={<TutoringList/>}/>

            <Route path=":id" element={<TutoringDetail/>}/>
            <Route path=":id/edit" element={<TutoringEdit/>}/>
            <Route path="new" element={<TutoringCreateMultiple/>}/>
            <Route path="request" element={<TutoringRequest/>}/>

            <Route path=":tutoringId/material/new" element={<MaterialCreateEdit/>}/>
            <Route path="material/:id/edit" element={<MaterialCreateEdit/>}/>
        </Routes>
    )
}


export default function Tutoring() {
    return <TutoringRouter/>
}
