import {NarrowPage} from "../../elements/Pages";
import {React, useEffect, useState} from "react";
import Joi from "joi"

import {watchlistApi} from '../../../api/logbook'
import {useQuery} from '@tanstack/react-query'
import {Select, TextArea} from "../../elements/forms/Inputs"
import {FormSectionContent} from "../../elements/forms/FormLayout"
import {getStudentInfoAll} from "../../../api/school";
import Form, {baseHandleChange} from "../../elements/forms/Form"
import {useNavigate, useParams} from "react-router-dom";
import {toast} from "react-toastify";
import {getCurrentTimePeriods} from "../../../api/calendar";


export default function WatchlistCreateEdit() {

    const {id, studentId} = useParams()
    const [data, setData] = useState({
        id: null,
        student_id: parseInt(studentId) || null,
        notes: "",
        intervention_protocol: null,
        epoch: null
    })

    const {data: students} = useQuery(['student_info'], getStudentInfoAll, {refetchInterval: 1000 * 60 * 60 * 24})
    const {data: timePeriods} = useQuery(['current_time_periods'], getCurrentTimePeriods, {refetchInterval: 1000 * 60 * 60})

    const [errors, setErrors] = useState({}) // validation errors

    let navigate = useNavigate();

    useEffect(() => {
        loadData()
    }, [id])

    async function loadData() {
        if (!id) return
        const newData = await watchlistApi('get', id)
        setData(newData)
    }

    const schema = {
        student_id: Joi.any().required(),
        notes: Joi.string().allow("", null),
        epoch: Joi.object().required()
    };

    const title = (id || data.id) ? "Watchlisteintrag bearbeiten" : "Neuen Watchlisteintrag erstellen"
    const successMessage = (id || data.id) ? "Watchlisteintrag aktualisiert" : "Neuen Watchlisteintrag erstellen"

    async function onSave() {
        let newData
        if (!data.id) newData = await watchlistApi('post', null, data)
        else newData = await watchlistApi('patch', data.id, data)
        setData(newData)
        toast.success(successMessage)
        // navigate(-1) // TODO
    }

    async function onDelete() {
        await watchlistApi('delete', data.id)
        toast.success("Watchlisteintrag gelöscht.")
        navigate(`/logbook/watchlist`)
    }

    function handleChange(evt) {
        baseHandleChange(evt, data, setData, errors, setErrors, schema)
    }

    return (

        <NarrowPage title={title}>

            <Form id="createPenalty" onSave={onSave}
                  onDelete={onDelete}
                  data={data} setData={setData}
                  errors={errors} setErrors={setErrors}
                  successMessage={successMessage}
                  schema={schema}>

                <FormSectionContent>

                    <Select
                        path="student_id"
                        label="SchülerIn"
                        className="sm:col-span-6"
                        options={students}
                        valueAttr="id"
                        labelAttr="full_name"
                        onChange={handleChange}
                        errors={errors}
                        data={data}
                        isClearable={false}
                        valueOnly={true}
                    />

                    <Select
                        path="epoch"
                        label="Epoche"
                        className="sm:col-span-6"
                        options={timePeriods}
                        valueAttr="id"
                        labelAttr="name"
                        onChange={handleChange}
                        errors={errors}
                        data={data}
                        isClearable={false}
                        valueOnly={false}
                    />

                    <TextArea
                        path="notes"
                        type="text"
                        label="Anmerkungen"
                        className="sm:col-span-6"
                        onChange={handleChange}
                        errors={errors}
                        data={data}
                    />

                    <TextArea
                        path="intervention_protocol"
                        type="text"
                        label="Protokoll Interventionsgespräch"
                        className="sm:col-span-6"
                        onChange={handleChange}
                        errors={errors}
                        data={data}
                        rows={20}
                    />

                </FormSectionContent>

            </Form>

            <span onClick={() => navigate(-1)}
                  className="cursor-pointer float-right mt-2 font-medium text-imsblue-600">Zurück</span>

        </NarrowPage>
    )
}
