import React from 'react';
import {Navigate, Route, Routes} from "react-router-dom";
import AssessmentPeriods from "./AssessmentPeriods";
import Courses from "./Courses";
import CourseDetail from "./CourseDetail";
import CourseAssessmentsEdit from "./CourseAssessmentsEdit";
import Students from "./Students";
import StudentDetail from "./StudentDetail";
import Teachers from "./Teachers";
import TeacherDetail from "./TeacherDetail";
import SingleAssessmentDetail from "./SingleAssessmentDetail";
import SingleAssessmentEdit from "./SingleAssessmentEdit";
import MyCourses from "./MyCourses";
import MyAssessmentsAndGrades from "./MyAssessmentsAndGrades";
import StudentAssessmentPeriods from "./StudentAssessmentPeriods";
import ConductGradeLists from "./ConductGradeLists";
import OpenAssessments from "./OpenAssessments";
import StudierzeitAssessmentPeriods from "./StudierzeitAssessmentPeriods";
import StudierzeitAssessments from "./StudierzeitAssessments";
import StudierzeitAssessmentsStudentDetail from "./StudierzeitAssessmentsStudentDetail";
import StudierzeitAssessmentsStudents from "./StudierzeitAssessmentsStudents";
import BoardingSchoolAssessmentPeriods from "./BoardingSchoolAssessmentPeriods";
import BoardingSchoolAssessmentHouses from "./BoardingSchoolAssessmentHouses";
import BoardingSchoolAssessmentHouseDetail from "./BoardingSchoolAssessmentHouseDetail";
import BoardingSchoolAssessmentEdit from "./BoardingSchoolAssessmentEdit";
import BoardingSchoolSingleAssessmentDetail from "./BoardingSchoolSingleAssessmentDetail";
import BoardingSchoolStudentDetail from "./BoardingSchoolStudentDetail";
import Mentors from "./Mentors";
import MentorDetail from "./MentorDetail";
import BoardingSchoolAssessmentsOpen from "./BoardingSchoolAssessmentsOpen";


function AssessmentsRouter() {

    return (
        <Routes>
            <Route path="*" element={<Navigate to="/assessments/assessment-periods" replace/>}/>

            <Route path="/assessment-periods" element={<AssessmentPeriods/>}/>

            <Route path="/assessment-periods/:assessmentPeriodId/open" element={<OpenAssessments/>}/>

            <Route path="/assessment-periods/:assessmentPeriodId/courses" element={<Courses/>}/>
            <Route path="/assessment-periods/:assessmentPeriodId/courses/:courseId" element={<CourseDetail/>}/>
            <Route path="/assessment-periods/:assessmentPeriodId/courses/my" element={<MyCourses/>}/>

            <Route path="/assessment-periods/:assessmentPeriodId/courses/:courseId/edit"
                   element={<CourseAssessmentsEdit/>}/>
            <Route path="/assessment-periods/:assessmentPeriodId/courses/:courseId/edit/:index"
                   element={<CourseAssessmentsEdit/>}/>

            <Route path="/:assessmentId/edit" element={<SingleAssessmentEdit/>}/>
            <Route path="/:assessmentId" element={<SingleAssessmentDetail/>}/>

            <Route path="/assessment-periods/:assessmentPeriodId/students" element={<Students/>}/>
            <Route path="/assessment-periods/:assessmentPeriodId/students/:studentId" element={<StudentDetail/>}/>

            <Route path="/assessment-periods/:assessmentPeriodId/teachers" element={<Teachers/>}/>
            <Route path="/assessment-periods/:assessmentPeriodId/teachers/:staffId" element={<TeacherDetail/>}/>

            <Route path="/my" element={<MyAssessmentsAndGrades/>}/>
            <Route path="/students/:studentId" element={<StudentAssessmentPeriods/>}/>

            <Route path="/conduct-grade-lists" element={<ConductGradeLists/>}/>

            {/* Studierzeit assessments */}
            <Route path="/studierzeit" element={<StudierzeitAssessmentPeriods/>}/>
            <Route path="/studierzeit/:assessmentPeriodId/entry" element={<StudierzeitAssessments/>}/>
            <Route path="/studierzeit/:assessmentPeriodId/students/" element={<StudierzeitAssessmentsStudents/>}/>
            <Route path="/studierzeit/:assessmentPeriodId/students/:studentId"
                   element={<StudierzeitAssessmentsStudentDetail/>}/>

            {/* Boarding school assessments */}
            <Route path="/boarding-school" element={<BoardingSchoolAssessmentPeriods/>}/>
            <Route path="/boarding-school/:assessmentPeriodId/houses" element={<BoardingSchoolAssessmentHouses/>}/>
            <Route path="/boarding-school/:assessmentPeriodId/houses/:houseId"
                   element={<BoardingSchoolAssessmentHouseDetail/>}/>

            <Route path="/boarding-school/:assessmentPeriodId/open" element={<BoardingSchoolAssessmentsOpen/>}/>

            <Route path="/boarding-school/:id" element={<BoardingSchoolSingleAssessmentDetail/>}/>
            <Route path="/boarding-school/:id/edit" element={<BoardingSchoolAssessmentEdit/>}/>

            <Route path="/boarding-school/:assessmentPeriodId/students/:studentId"
                   element={<BoardingSchoolStudentDetail/>}/>

            <Route path="/boarding-school/:assessmentPeriodId/mentors" element={<Mentors/>}/>
            <Route path="/boarding-school/:assessmentPeriodId/mentors/:staffId" element={<MentorDetail/>}/>

        </Routes>
    )
}


export default function Assessments() {
    return <AssessmentsRouter/>
}
