import {React, useEffect, useState} from "react";
import Joi from "joi"
import {useQuery, useQueryClient} from '@tanstack/react-query'
import {Checkbox, DateInput, Input, Select, TextArea} from "../../elements/forms/Inputs"
import Form, {baseHandleChange, baseHandleDateListChange, baseHandleListChange} from "../../elements/forms/Form"
import {Link, useNavigate, useParams} from "react-router-dom";
import {toast} from "react-toastify";
import {addImageToAlumnus, alumniApi, getAmbassadors} from "../../../api/alumni";
import {getMaritalStatusOptions} from "../../../api/utils";
import {DeleteButton} from "../../elements/DeleteButton";
import {AddButton} from "../../elements/Buttons";
import checkPermission from "../../../utils/permissions";
import {useAuth} from "../../../contexts/AuthContext";


export default function AlumnusEdit() {

    const {id} = useParams()
    const [alumnus, setAlumnus] = useState()
    const {permissions} = useAuth()
    const queryClient = useQueryClient()

    const {data: maritalStatusOptions} = useQuery(['marital_status_options'], getMaritalStatusOptions, {refetchInterval: 1000 * 60 * 60 * 24})

    const [selectedFile, setSelectedFile] = useState();
    const [isFilePicked, setIsFilePicked] = useState(false);

    const [errors, setErrors] = useState({}) // validation errors

    let navigate = useNavigate();

    useEffect(() => {
        loadData()
    }, [id])

    async function loadData() {
        if (!id) return
        const newData = await alumniApi('get', id)
        setAlumnus(newData)
    }

    const schema = {
        email: Joi.string().email({tlds: {allow: false}}).allow('', null).label("Email"),
        email_work: Joi.string().email({tlds: {allow: false}}).allow('', null).label("Email geschäftlich"),
        facebook: Joi.string().uri().allow('', null).label("Facebook"),
        instagram: Joi.string().uri().allow('', null).label("Instagram"),
        linkedin: Joi.string().uri().allow('', null).label("LinkedIn"),
        website: Joi.string().uri().allow('', null).label("Website"),
    };

    const successMessage = "Profil aktualisiert"

    async function onSave() {

        let newEducation = alumnus.education.map((t, index) => {
            if (t.id.toString().startsWith("N")) {
                return {...t, id: null}
            }
            return {...t}
        })

        let newProfessionalExperience = alumnus.professional_experiences.map((t, index) => {
            if (t.id.toString().startsWith("N")) {
                return {...t, id: null}
            }
            return {...t}
        })
        const tmpdata = {...alumnus, education: newEducation, professional_experiences: newProfessionalExperience}

        // saving the instance first
        let newData = await alumniApi('patch', null, tmpdata)

        // then adding the file
        if (isFilePicked) {
            const formData = new FormData();
            formData.append('file', selectedFile);
            newData = await addImageToAlumnus(formData, newData.id)
        }

        setAlumnus(newData)

        queryClient.invalidateQueries({ queryKey: ['alumni_ambassadors'] })
        queryClient.invalidateQueries({ queryKey: ['alumni'] })

        toast.success(successMessage)
    }

    function handleChange(evt) {
        baseHandleChange(evt, alumnus, setAlumnus, errors, setErrors, schema)
    }

    const handleChangeFile = (event) => {
        setSelectedFile(event.target.files[0])
        setIsFilePicked(true)
    }

    // Professional experience
    const [professionalExperienceErrors, setProfessionalExperienceErrors] = useState([])
    const [newProfessionalExperienceRowId, setNewProfessionalExperienceRowId] = useState(1)

    function addProfessionalExperienceRow() {
        const newRow = {
            id: `N${newProfessionalExperienceRowId}`
        }
        setNewProfessionalExperienceRowId(newProfessionalExperienceRowId + 1)
        setProfessionalExperience([...alumnus.professional_experiences, newRow])
    }

    function handleProfessionalExperienceListChange(evt, rowId) {
        baseHandleListChange(rowId, evt, alumnus.professional_experiences, setProfessionalExperience, professionalExperienceErrors, setProfessionalExperienceErrors, {})
    }

    function handleProfessionalExperienceDateListChange(date, path, rowId) {
        baseHandleDateListChange(rowId, date, path, alumnus.professional_experiences, setProfessionalExperience, professionalExperienceErrors, setProfessionalExperienceErrors, {}, false)
    }

    function setProfessionalExperience(newProfessionalExperience) {
        setAlumnus({...alumnus, professional_experiences: newProfessionalExperience})
    }

    async function handleProfessionalExperienceDelete(professionalExperienceId) {
        const newProfessionalExperience = [...alumnus.professional_experiences.filter(e => e.id !== professionalExperienceId)]
        setProfessionalExperience(newProfessionalExperience)
    }

    // Education
    const [educationErrors, setEducationErrors] = useState([])
    const [newEducationRowId, setNewEducationRowId] = useState(1)

    function addEducationRow() {
        const newRow = {
            id: `N${newEducationRowId}`
        }
        setNewEducationRowId(newEducationRowId + 1)
        setEducation([...alumnus.education, newRow])
    }

    function handleEducationListChange(evt, rowId) {
        baseHandleListChange(rowId, evt, alumnus.education, setEducation, educationErrors, setEducationErrors, {})
    }

    function handleEducationDateListChange(date, path, rowId) {
        baseHandleDateListChange(rowId, date, path, alumnus.education, setEducation, educationErrors, setEducationErrors, {}, false)
    }

    function setEducation(newEducation) {
        setAlumnus({...alumnus, education: newEducation})
    }

    async function handleEducationDelete(educationId) {
        const newEducation = [...alumnus.education.filter(e => e.id !== educationId)]
        setEducation(newEducation)
    }

    if (!alumnus || !alumnus.can_edit) return <></>
    const student = alumnus.student

    return (

        <main className="py-10 h-full bg-gray-100">

            <div
                className="max-w-3xl mx-auto px-4 sm:px-6 md:flex md:items-center md:justify-between md:space-x-5 lg:max-w-7xl lg:px-8">
                <div className="flex items-center space-x-5">
                    <div>
                        <h1 className="text-2xl font-bold text-gray-900">
                            Ehemaligenprofil bearbeiten
                        </h1>
                    </div>
                </div>
            </div>

            <div
                className="max-w-7xl mx-auto grid grid-cols-1 gap-6 lg:max-w-7xl lg:grid-flow-col-dense lg:grid-cols-1">
                <div className="space-y-6 lg:col-span-4">

                    <Form id="updateAlumniProfile" onSave={onSave}
                          onDelete={null}
                          data={alumnus} setData={setAlumnus}
                          errors={errors} setErrors={setErrors}
                          successMessage={successMessage}
                          schema={schema}>

                        <div
                            className="mt-8 max-w-3xl mx-auto grid grid-cols-1 gap-6 sm:px-6 lg:max-w-7xl lg:grid-flow-col-dense lg:grid-cols-3">
                            <div className="space-y-6 lg:col-start-1 lg:col-span-2">

                                <section aria-labelledby="applicant-information-title">
                                    <div className="bg-white shadow sm:rounded-lg">

                                        <div className="px-4 py-5 sm:px-6">
                                            <h2 id="applicant-information-title"
                                                className="text-lg font-medium leading-6 text-gray-900">
                                                Profil
                                            </h2>
                                        </div>
                                        <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
                                            <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">

                                                <div className="sm:col-span-2">
                                                    <div className="flex items-center space-x-5">
                                                        <div className="flex-shrink-0">
                                                            <div className="relative">
                                                                {alumnus.student.image?.detail && <img
                                                                    className="h-32 w-32 rounded-full"
                                                                    src={student.image?.detail}
                                                                    alt={student.full_name}
                                                                />}
                                                                <span
                                                                    className="absolute inset-0 shadow-inner rounded-full"
                                                                    aria-hidden="true"
                                                                />
                                                            </div>
                                                        </div>
                                                        <div>
                                                            <p>Profilbild</p>
                                                            <input type="file" name="profile_image"
                                                                   onChange={handleChangeFile}/>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="sm:col-span-1">
                                                    <dt className="text-sm font-medium text-gray-500">Nachname</dt>
                                                    <dd className="mt-1 text-sm text-gray-900">
                                                        <Input
                                                            path="student.last_name"
                                                            type="text"
                                                            label="Nachname"
                                                            className="sm:col-span-6 hide-label"
                                                            onChange={handleChange}
                                                            errors={errors}
                                                            data={alumnus}
                                                        />
                                                    </dd>
                                                </div>

                                                <div className="sm:col-span-1">
                                                    <dt className="text-sm font-medium text-gray-500">Früherer Name</dt>
                                                    <dd className="mt-1 text-sm text-gray-900">
                                                        <Input
                                                            path="former_last_name"
                                                            type="text"
                                                            label="Frühere Nachname"
                                                            className="sm:col-span-6 hide-label"
                                                            onChange={handleChange}
                                                            errors={errors}
                                                            data={alumnus}
                                                        />
                                                    </dd>
                                                </div>

                                                <div className="sm:col-span-1">
                                                    <dt className="text-sm font-medium text-gray-500">Familienstand</dt>
                                                    <dd className="mt-1 text-sm text-gray-900">
                                                        <Select
                                                            path="marital_status"
                                                            label="Familienstand"
                                                            className="sm:col-span-6 hide-label"
                                                            options={maritalStatusOptions}
                                                            valueAttr="value"
                                                            labelAttr="label"
                                                            onChange={handleChange}
                                                            errors={errors}
                                                            data={alumnus}
                                                            isClearable={true}
                                                            valueOnly={true}
                                                        />
                                                    </dd>
                                                </div>
                                                <div className="sm:col-span-1">
                                                    <dt className="text-sm font-medium text-gray-500">Kinder</dt>
                                                    <dd className="mt-1 text-sm text-gray-900">
                                                        <Input
                                                            path="children"
                                                            type="text"
                                                            label="Kinder"
                                                            className="sm:col-span-6 hide-label"
                                                            onChange={handleChange}
                                                            errors={errors}
                                                            data={alumnus}
                                                        />
                                                    </dd>
                                                </div>

                                                <div className="sm:col-span-1">
                                                    <dt className="text-sm font-medium text-gray-500">Email</dt>
                                                    <dd className="mt-1 text-sm text-gray-900">
                                                        <Input
                                                            path="student.email"
                                                            type="email"
                                                            label="Email"
                                                            className="sm:col-span-6 hide-label"
                                                            onChange={handleChange}
                                                            errors={errors}
                                                            data={alumnus}
                                                        />
                                                    </dd>
                                                </div>

                                                <div className="sm:col-span-1">
                                                    <dt className="text-sm font-medium text-gray-500">Passwort</dt>
                                                    <dd className="mt-1 text-sm text-gray-900">
                                                        <Link className="text-imsblue-600"
                                                              to={`/settings/change-password`}>Passwort ändern</Link>
                                                    </dd>
                                                </div>

                                                <div className="sm:col-span-1">
                                                    <dt className="text-sm font-medium text-gray-500">Botschafter</dt>
                                                    <dd className="mt-1 text-sm text-gray-900">
                                                        <Input
                                                            path="ambassador_for"
                                                            type="text"
                                                            label="Botschafter für"
                                                            className="sm:col-span-6 hide-label"
                                                            onChange={handleChange}
                                                            errors={errors}
                                                            data={alumnus}
                                                        />
                                                    </dd>
                                                </div>

                                            </dl>
                                        </div>
                                    </div>
                                </section>

                                <section aria-labelledby="applicant-information-title">
                                    <div className="bg-white shadow sm:rounded-lg">

                                        <div className="px-4 py-5 sm:px-6">
                                            <h2 id="applicant-information-title"
                                                className="text-lg font-medium leading-6 text-gray-900">
                                                Erweitertetes Profil
                                            </h2>
                                            <p></p>
                                        </div>
                                        <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
                                            <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">

                                                <div className="sm:col-span-2">

                                                    <p className="text-sm text-gray-700">Die folgenden Sektionen werden
                                                        nur im
                                                        Profil angezeigt, wenn du diese Option aktivierst.</p>

                                                    <Checkbox
                                                        path="has_enabled_extended_profile"
                                                        label="Erweitertes Profil anzeigen"
                                                        className="sm:col-span-6"
                                                        onChange={handleChange}
                                                        errors={errors}
                                                        hideLegend={true}
                                                        data={alumnus}/>
                                                </div>
                                            </dl>
                                        </div>
                                    </div>
                                </section>

                                {alumnus.has_enabled_extended_profile &&
                                    <section aria-labelledby="professional-experience">
                                        <div className="bg-white pb-1  shadow sm:rounded-lg">

                                            <div className="px-4 py-5 sm:px-6">
                                                <h2 id="applicant-information-title"
                                                    className="text-lg font-medium leading-6 text-gray-900">
                                                    Berufliche Stationen
                                                </h2>
                                            </div>
                                            <div className="border-t">

                                                <div className="overflow-hidden bg-white shadow sm:rounded-md">
                                                    <ul role="list" className="divide-y divide-gray-200">
                                                        {alumnus.professional_experiences.map((position) => (

                                                            <li key={position.id}>
                                                                <div className="block hover:bg-gray-50">
                                                                    <div className="px-4 py-4 sm:px-6">
                                                                        <div className="">
                                                                            <div className="gap-6 grid lg:grid-cols-6">

                                                                                <Input
                                                                                    path="position"
                                                                                    type="text"
                                                                                    label="Position"
                                                                                    className="sm:col-span-3"
                                                                                    onChange={handleProfessionalExperienceListChange}
                                                                                    errors={professionalExperienceErrors}
                                                                                    data={alumnus.professional_experiences}
                                                                                    rowId={position.id}
                                                                                    rows={3}
                                                                                />

                                                                                <Input
                                                                                    path="employment_type"
                                                                                    type="text"
                                                                                    label="Beschäftigungsart"
                                                                                    className="sm:col-span-3"
                                                                                    onChange={handleProfessionalExperienceListChange}
                                                                                    errors={professionalExperienceErrors}
                                                                                    data={alumnus.professional_experiences}
                                                                                    rowId={position.id}
                                                                                    rows={3}
                                                                                />

                                                                                <Input
                                                                                    path="company"
                                                                                    type="text"
                                                                                    label="Firma"
                                                                                    className="sm:col-span-3"
                                                                                    onChange={handleProfessionalExperienceListChange}
                                                                                    errors={professionalExperienceErrors}
                                                                                    data={alumnus.professional_experiences}
                                                                                    rowId={position.id}
                                                                                    rows={3}
                                                                                />

                                                                                <Input
                                                                                    path="industry"
                                                                                    type="text"
                                                                                    label="Branche"
                                                                                    className="sm:col-span-3"
                                                                                    onChange={handleProfessionalExperienceListChange}
                                                                                    errors={professionalExperienceErrors}
                                                                                    data={alumnus.professional_experiences}
                                                                                    rowId={position.id}
                                                                                    rows={3}
                                                                                />

                                                                                <Input
                                                                                    path="country"
                                                                                    type="text"
                                                                                    label="Land"
                                                                                    className="sm:col-span-3"
                                                                                    onChange={handleProfessionalExperienceListChange}
                                                                                    errors={professionalExperienceErrors}
                                                                                    data={alumnus.professional_experiences}
                                                                                    rowId={position.id}
                                                                                    rows={3}
                                                                                />

                                                                                <div className="sm:col-span-3"></div>

                                                                                <DateInput
                                                                                    className="col-span-3"
                                                                                    path="date_from"
                                                                                    label="Von"
                                                                                    onChange={handleProfessionalExperienceDateListChange}
                                                                                    errors={professionalExperienceErrors}
                                                                                    data={alumnus.professional_experiences}
                                                                                    rowId={position.id}
                                                                                />

                                                                                <DateInput
                                                                                    className="col-span-3"
                                                                                    path="date_to"
                                                                                    label="Bis"
                                                                                    onChange={handleProfessionalExperienceDateListChange}
                                                                                    errors={professionalExperienceErrors}
                                                                                    data={alumnus.professional_experiences}
                                                                                    rowId={position.id}
                                                                                />

                                                                                <TextArea
                                                                                    path="description"
                                                                                    label="Beschreibung"
                                                                                    className="sm:col-span-6"
                                                                                    onChange={handleProfessionalExperienceListChange}
                                                                                    errors={professionalExperienceErrors}
                                                                                    data={alumnus.professional_experiences}
                                                                                    rowId={position.id}
                                                                                    rows={3}
                                                                                />
                                                                            </div>

                                                                        </div>

                                                                        <div className="mt-2">
                                                                            <DeleteButton
                                                                                onDelete={() => handleProfessionalExperienceDelete(position.id)}/>
                                                                        </div>

                                                                    </div>
                                                                </div>
                                                            </li>
                                                        ))}
                                                    </ul>
                                                </div>

                                                <div className="my-4 ml-4">
                                                    <AddButton onClick={addProfessionalExperienceRow}
                                                               label="Hinzufügen"/>
                                                </div>

                                            </div>
                                        </div>
                                    </section>}

                                {alumnus.has_enabled_extended_profile &&
                                    <section aria-labelledby="applicant-information-title">
                                        <div className="pb-1 bg-white shadow sm:rounded-lg">

                                            <div className="px-4 py-5 sm:px-6">
                                                <h2 id="applicant-information-title"
                                                    className="text-lg font-medium leading-6 text-gray-900">
                                                    Ausbildung
                                                </h2>
                                            </div>
                                            <div className="border-t">

                                                <div className="overflow-hidden bg-white shadow sm:rounded-md">
                                                    <ul role="list" className="divide-y divide-gray-200">
                                                        {alumnus.education.map((position) => (
                                                            <li key={position.id}>
                                                                <div className="block hover:bg-gray-50">
                                                                    <div className="px-4 py-4 sm:px-6">
                                                                        <div className="">
                                                                            <div className="gap-6 grid lg:grid-cols-6">

                                                                                <Input
                                                                                    path="course"
                                                                                    type="text"
                                                                                    label="Studiengang/Ausbildung"
                                                                                    className="sm:col-span-3"
                                                                                    onChange={handleEducationListChange}
                                                                                    errors={educationErrors}
                                                                                    data={alumnus.education}
                                                                                    rowId={position.id}
                                                                                    rows={3}
                                                                                />

                                                                                <Input
                                                                                    path="school"
                                                                                    type="text"
                                                                                    label="Hochschule/Ausbildungsbetrieb"
                                                                                    className="sm:col-span-3"
                                                                                    onChange={handleEducationListChange}
                                                                                    errors={educationErrors}
                                                                                    data={alumnus.education}
                                                                                    rowId={position.id}
                                                                                    rows={3}
                                                                                />

                                                                                <Input
                                                                                    path="city"
                                                                                    type="text"
                                                                                    label="Ort"
                                                                                    className="sm:col-span-3"
                                                                                    onChange={handleEducationListChange}
                                                                                    errors={educationErrors}
                                                                                    data={alumnus.education}
                                                                                    rowId={position.id}
                                                                                    rows={3}
                                                                                />

                                                                                <Input
                                                                                    path="country"
                                                                                    type="text"
                                                                                    label="Land"
                                                                                    className="sm:col-span-3"
                                                                                    onChange={handleEducationListChange}
                                                                                    errors={educationErrors}
                                                                                    data={alumnus.education}
                                                                                    rowId={position.id}
                                                                                    rows={3}
                                                                                />

                                                                                <DateInput
                                                                                    className="col-span-3"
                                                                                    path="date_from"
                                                                                    label="Von"
                                                                                    onChange={handleEducationDateListChange}
                                                                                    errors={educationErrors}
                                                                                    data={alumnus.education}
                                                                                    rowId={position.id}
                                                                                />

                                                                                <DateInput
                                                                                    className="col-span-3"
                                                                                    path="date_to"
                                                                                    label="Bis"
                                                                                    onChange={handleEducationDateListChange}
                                                                                    errors={educationErrors}
                                                                                    data={alumnus.education}
                                                                                    rowId={position.id}
                                                                                />

                                                                                <TextArea
                                                                                    path="description"
                                                                                    label="Beschreibung"
                                                                                    className="sm:col-span-6"
                                                                                    onChange={handleEducationListChange}
                                                                                    errors={educationErrors}
                                                                                    data={alumnus.education}
                                                                                    rowId={position.id}
                                                                                    rows={3}
                                                                                />
                                                                            </div>

                                                                        </div>

                                                                        <div className="mt-2">
                                                                            <DeleteButton
                                                                                onDelete={() => handleEducationDelete(position.id)}/>
                                                                        </div>

                                                                    </div>
                                                                </div>
                                                            </li>
                                                        ))}
                                                    </ul>
                                                </div>

                                                <div className="my-4 ml-4">
                                                    <AddButton onClick={addEducationRow} label="Hinzufügen"/>
                                                </div>

                                            </div>
                                        </div>
                                    </section>}

                            </div>

                            <section
                                aria-labelledby="timeline-title"
                                className="lg:col-start-3 lg:col-span-1"
                            >
                                {alumnus.has_enabled_extended_profile &&
                                    <section aria-labelledby="student-links">
                                        <div className="bg-white shadow overflow-hidden sm:rounded-lg mt-0">
                                            <div className="px-4 py-5 sm:px-6">
                                                <h3 className="text-lg leading-6 font-medium text-gray-900">
                                                    Private Kontaktdaten
                                                </h3>
                                            </div>
                                            <div className="border-t border-gray-200 px-4 py-5 sm:p-4">
                                                <dl className={"grid grid-cols-1 gap-x-4 gap-y-4 sm:grid-cols-2 "}>
                                                    <div className="sm:col-span-2">
                                                        <dt className="text-sm font-medium text-gray-500">Adresse</dt>
                                                        <dd className="mt-1 text-sm text-gray-900">

                                                            <div
                                                                className={"grid grid-cols-1 gap-x-4 gap-y-4 sm:grid-cols-6"}>
                                                                <Input
                                                                    path="street"
                                                                    type="text"
                                                                    label="Straße"
                                                                    className="sm:col-span-6"
                                                                    onChange={handleChange}
                                                                    errors={errors}
                                                                    data={alumnus}
                                                                />

                                                                <Input
                                                                    path="zip_code"
                                                                    type="text"
                                                                    label="PLZ"
                                                                    className="sm:col-span-3"
                                                                    onChange={handleChange}
                                                                    errors={errors}
                                                                    data={alumnus}
                                                                />
                                                                <Input
                                                                    path="city"
                                                                    type="text"
                                                                    label="Ort"
                                                                    className="sm:col-span-3"
                                                                    onChange={handleChange}
                                                                    errors={errors}
                                                                    data={alumnus}
                                                                />

                                                                <Input
                                                                    path="country"
                                                                    type="text"
                                                                    label="Land"
                                                                    className="sm:col-span-6"
                                                                    onChange={handleChange}
                                                                    errors={errors}
                                                                    data={alumnus}
                                                                />
                                                            </div>

                                                        </dd>
                                                    </div>
                                                    <div className="sm:col-span-1">
                                                        <dt className="text-sm font-medium text-gray-500">Telefon</dt>
                                                        <dd className="mt-1 text-sm text-gray-900">
                                                            <Input
                                                                path="phone_private"
                                                                type="text"
                                                                label="Telefon"
                                                                className="sm:col-span-6 hide-label"
                                                                onChange={handleChange}
                                                                errors={errors}
                                                                data={alumnus}
                                                            />
                                                        </dd>
                                                    </div>
                                                    <div className="sm:col-span-1">
                                                        <dt className="text-sm font-medium text-gray-500">Handy</dt>
                                                        <dd className="mt-1 text-sm text-gray-900">
                                                            <Input
                                                                path="mobile"
                                                                type="text"
                                                                label="Handy"
                                                                className="sm:col-span-6 hide-label"
                                                                onChange={handleChange}
                                                                errors={errors}
                                                                data={alumnus}
                                                            />
                                                        </dd>
                                                    </div>
                                                    <div className="sm:col-span-2">
                                                        <dt className="text-sm font-medium text-gray-500">Weitere
                                                            Email
                                                        </dt>
                                                        <dd className="mt-1 text-sm text-gray-900">
                                                            <Input
                                                                path="additional_email"
                                                                type="email"
                                                                label="Weitere Email"
                                                                className="sm:col-span-6 hide-label"
                                                                onChange={handleChange}
                                                                errors={errors}
                                                                data={alumnus}
                                                            />
                                                        </dd>
                                                    </div>
                                                    <div className="sm:col-span-2">
                                                        <dt className="text-sm font-medium text-gray-500">Soziale
                                                            Medien
                                                        </dt>
                                                        <dd className="mt-1 text-sm text-gray-900 flex flex-col gap-4">

                                                            <Input
                                                                path="facebook"
                                                                type="text"
                                                                label="Facebook"
                                                                className="sm:col-span-6"
                                                                onChange={handleChange}
                                                                errors={errors}
                                                                data={alumnus}
                                                            />

                                                            <Input
                                                                path="instagram"
                                                                type="text"
                                                                label="Instagram"
                                                                className="sm:col-span-6"
                                                                onChange={handleChange}
                                                                errors={errors}
                                                                data={alumnus}
                                                            />
                                                            <Input
                                                                path="linkedin"
                                                                type="text"
                                                                label="LinkedIn"
                                                                className="sm:col-span-6"
                                                                onChange={handleChange}
                                                                errors={errors}
                                                                data={alumnus}
                                                            />
                                                        </dd>
                                                    </div>
                                                    <div className="sm:col-span-2">
                                                        <dt className="text-sm font-medium text-gray-500">Website</dt>
                                                        <dd className="mt-1 text-sm text-gray-900">
                                                            <Input
                                                                path="website"
                                                                type="text"
                                                                label="Website"
                                                                className="sm:col-span-6 hide-label"
                                                                onChange={handleChange}
                                                                errors={errors}
                                                                data={alumnus}
                                                            />
                                                        </dd>
                                                    </div>
                                                </dl>
                                            </div>
                                        </div>
                                    </section>}

                                {alumnus.has_enabled_extended_profile &&
                                    <section aria-labelledby="student-links">
                                        <div className="bg-white shadow overflow-hidden sm:rounded-lg mt-5">
                                            <div className="px-4 py-5 sm:px-6">
                                                <h3 className="text-lg leading-6 font-medium text-gray-900">
                                                    Geschäftliche Kontaktdaten
                                                </h3>
                                            </div>
                                            <div className="border-t border-gray-200 px-4 py-5 sm:p-4">
                                                <dl className={"grid grid-cols-1 gap-x-4 gap-y-4 sm:grid-cols-2 "}>
                                                    <div className="sm:col-span-2">
                                                        <dt className="text-sm font-medium text-gray-500">Firma</dt>
                                                        <dd className="mt-1 text-sm text-gray-900">
                                                            <Input
                                                                path="company"
                                                                type="text"
                                                                label="Firma"
                                                                className="sm:col-span-6 hide-label"
                                                                onChange={handleChange}
                                                                errors={errors}
                                                                data={alumnus}
                                                            />
                                                        </dd>
                                                    </div>
                                                    <div className="sm:col-span-2">
                                                        <dt className="text-sm font-medium text-gray-500">Branche</dt>
                                                        <dd className="mt-1 text-sm text-gray-900">
                                                            <Input
                                                                path="company_industry"
                                                                type="text"
                                                                label="Branche"
                                                                className="sm:col-span-6 hide-label"
                                                                onChange={handleChange}
                                                                errors={errors}
                                                                data={alumnus}
                                                            />
                                                        </dd>
                                                    </div>
                                                    <div className="sm:col-span-2">
                                                        <dt className="text-sm font-medium text-gray-500">Adresse</dt>
                                                        <dd className="mt-1 text-sm text-gray-900">

                                                            <div
                                                                className={"grid grid-cols-1 gap-x-4 gap-y-4 sm:grid-cols-6"}>
                                                                <Input
                                                                    path="street_work"
                                                                    type="text"
                                                                    label="Straße"
                                                                    className="sm:col-span-6"
                                                                    onChange={handleChange}
                                                                    errors={errors}
                                                                    data={alumnus}
                                                                />

                                                                <Input
                                                                    path="zip_code_work"
                                                                    type="text"
                                                                    label="PLZ"
                                                                    className="sm:col-span-3"
                                                                    onChange={handleChange}
                                                                    errors={errors}
                                                                    data={alumnus}
                                                                />
                                                                <Input
                                                                    path="city_work"
                                                                    type="text"
                                                                    label="Ort"
                                                                    className="sm:col-span-3"
                                                                    onChange={handleChange}
                                                                    errors={errors}
                                                                    data={alumnus}
                                                                />

                                                                <Input
                                                                    path="country_work"
                                                                    type="text"
                                                                    label="Land"
                                                                    className="sm:col-span-6"
                                                                    onChange={handleChange}
                                                                    errors={errors}
                                                                    data={alumnus}
                                                                />
                                                            </div>
                                                        </dd>
                                                    </div>
                                                    <div className="sm:col-span-1">
                                                        <dt className="text-sm font-medium text-gray-500">Telefon</dt>
                                                        <dd className="mt-1 text-sm text-gray-900">
                                                            <Input
                                                                path="phone_work"
                                                                type="text"
                                                                label="Telefon"
                                                                className="sm:col-span-6 hide-label"
                                                                onChange={handleChange}
                                                                errors={errors}
                                                                data={alumnus}
                                                            />
                                                        </dd>
                                                    </div>
                                                    <div className="sm:col-span-1">
                                                        <dt className="text-sm font-medium text-gray-500">Handy</dt>
                                                        <dd className="mt-1 text-sm text-gray-900">
                                                            <Input
                                                                path="mobile_work"
                                                                type="text"
                                                                label="Handy"
                                                                className="sm:col-span-6 hide-label"
                                                                onChange={handleChange}
                                                                errors={errors}
                                                                data={alumnus}
                                                            />
                                                        </dd>
                                                    </div>
                                                    <div className="sm:col-span-2">
                                                        <dt className="text-sm font-medium text-gray-500">Email</dt>
                                                        <dd className="mt-1 text-sm text-gray-900">
                                                            <Input
                                                                path="email_work"
                                                                type="email"
                                                                label="Email"
                                                                className="sm:col-span-6 hide-label"
                                                                onChange={handleChange}
                                                                errors={errors}
                                                                data={alumnus}
                                                            />
                                                        </dd>
                                                    </div>
                                                </dl>
                                            </div>
                                        </div>
                                    </section>}

                            </section>
                        </div>

                    </Form>

                    <span onClick={() => navigate(-1)}
                          className="cursor-pointer float-right mt-2 font-medium text-imsblue-600">Zurück</span>

                </div>

            </div>
        </main>
    )
}
