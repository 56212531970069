import React from "react";
import {Route, Routes} from "react-router-dom";
import QuestionnaireIndex from "./QuestionnaireIndex";
import EditEntry from "./EditEntry";
import QuestionnaireList from "./QuestionnaireList";
import EntryResult from "./EntryResult";


function QuestionnairesRouter() {
    return (
        <Routes>
            <Route index element={<QuestionnaireList/>}/>
            <Route path=":questionnaireId" element={<QuestionnaireIndex/>}/>
            <Route path="entries/:entryId/edit" element={<EditEntry/>}/>
            <Route path="entries/:entryId/result" element={<EntryResult/>}/>
        </Routes>
    );
}

export default function Questionnaires() {

    return <QuestionnairesRouter/>
}
