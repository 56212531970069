import {SimplePage} from "../../elements/Pages";
import React, {useState} from "react";

import {useQuery} from "@tanstack/react-query";
import {useAuth} from "../../../contexts/AuthContext";
import ListViewTable, {TableEditLink, TableLink} from "../../elements/tables/ListViewTable";
import checkPermission from "../../../utils/permissions";
import {classNames} from "../../../utils/misc";
import {PrimaryButton} from "../../elements/Buttons";
import {getAnnouncements} from "../../../api/organizational";
import {PaperClipIcon} from "@heroicons/react/20/solid";
import {LazyLoadImage} from "react-lazy-load-image-component";


export default function Announcements() {

    const { permissions, currentUser } = useAuth();
    const [recentOrAll, setRecentOrAll] = useState("recent");

    const { isLoading, isError, isSuccess, data, error, refetch } = useQuery(
        ["announcements", recentOrAll],
        () => getAnnouncements(recentOrAll),
        {}
    )

    const canAdd = checkPermission(permissions, "organizational.add_announcement")
    const canModerate = checkPermission(permissions, "organizational.moderate_announcements")
    const canChangeAnnouncements = checkPermission(permissions, "organizational.change_announcement")

    const baseColumns = [
        {
            accessorKey: "title",
            id: "title",
            header: "Titel",
            enableColumnFilter: false,
            cell: ({ row, value }) => (
                <div className="whitespace-pre-wrap">
                    <div className="text-gray-900">{row.original.title}</div>
                    <div className="text-gray-500" dangerouslySetInnerHTML={{__html: row.original.content}}></div>
                    {row.original.image && <div
                        className="mt-2 space-y-4 text-sm text-gray-700"
                    ><LazyLoadImage src={row.original.image} alt={row.original.title}/>

                    </div>}
                    <div>
                        {row.original.downloads.map((download, index) => {
                            if (download.file) {
                                return <a key={index} href={download.file} className="flex text-sm"
                                          target="_blank">
                                    <PaperClipIcon
                                        className="cursor-pointer mr-3 h-5 w-5"
                                    />
                                    {download.get_file_name}
                                </a>
                            }
                        })}
                    </div>
                </div>),
            headerClassNames: "medium-filter",
            classNames: "max-w-md",
        },
        {
            accessorKey: "content",
            id: "content",
            header: "Inhalt",
            hide: true,
            enableColumnFilter: false
        },
        {
            accessorKey: "category.title",
            id: "category",
            header: "Kategorie",
            enableColumnFilter: true,
            filterFn: "containsString",
            headerClassNames: "narrow-filter",
            labelAttr: "title",
        },
        {
            accessorKey: "created_by_full_name",
            id: "created_by_full_name",
            header: "Erstellt von",
            enableColumnFilter: true,
            headerClassNames: "medium-filter",
            filterFn: "containsString",
        },
        {
            accessorKey: "date_published",
            id: "date_published",
            header: "Datum",
            columnType: "date",
            enableColumnFilter: false,
        },
        {
            accessorKey: "id",
            id: "link",
            cell: ({row, value}) => {
                if (canChangeAnnouncements || row.original.created_by === currentUser.user_id) {
                   return <TableEditLink iconOnly={true} label="Bearbeiten" to={`/service/announcements/${row.original.id}/edit`} />
                }
                return <></>
            },
            header: "",
            enableColumnFilter: false,
            disableRowLink: true
        },
    ]

    let columns = [...baseColumns]
    if (canChangeAnnouncements || canModerate) {
        columns.push({
                accessorKey: "is_approved",
                id: "is_approved",
                header: "Freigeschaltet",
                columnType: "yesno",
                filterFn: "boolean",
                enableColumnFilter: true,
                headerClassNames: "narrow-filter",
            },
            {
                accessorKey: "is_published",
                id: "is_published",
                header: "Veröffentlicht",
                columnType: "yesno",
                filterFn: "boolean",
                enableColumnFilter: true,
                headerClassNames: "narrow-filter",
            },)
    }

    return (
        <SimplePage title="Tweets">

            <div className="px-4 sm:px-6 lg:px-8">
                <div className="sm:flex sm:items-center">
                    <div className="sm:flex-auto">
                        <h3 className="text-lg leading-6 font-medium text-gray-900">
                            Tweets
                        </h3>
                    </div>
                    <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
                        {canAdd && <PrimaryButton
                            label="Tweet erstellen"
                            link={`/service/announcements/new`}
                        />}
                    </div>
                </div>
                <div className="flex flex-col">
                    <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
                        <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                            <nav className="flex space-x-4 mt-2" aria-label="Tabs">
                                <a
                                    key="recent"
                                    onClick={() => setRecentOrAll("recent")}
                                    className={classNames(
                                        recentOrAll === "recent"
                                            ? "bg-imsblue-100 text-imsblue-700"
                                            : "text-gray-500 hover:text-gray-700",
                                        "px-3 py-2 font-medium text-sm rounded-md cursor-pointer"
                                    )}
                                    aria-current={recentOrAll === "recent" ? "page" : undefined}
                                >
                                    Aktuell
                                </a>
                                <a
                                    key="today"
                                    onClick={() => setRecentOrAll("all")}
                                    className={classNames(
                                        recentOrAll === "all"
                                            ? "bg-imsblue-100 text-imsblue-700"
                                            : "text-gray-500 hover:text-gray-700",
                                        "px-3 py-2 font-medium text-sm rounded-md cursor-pointer"
                                    )}
                                    aria-current={recentOrAll === "all" ? "page" : undefined}
                                >
                                    Alle
                                </a>
                            </nav>
                        </div>


                        <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                            {isSuccess && data && data?.length > 0 && (
                                <ListViewTable
                                    data={data}
                                    columns={columns}
                                    isLoading={isLoading}
                                    disableGlobalSearch={false}
                                />
                            )}
                        </div>


                    </div>
                </div>
            </div>
        </SimplePage>
    )
}
