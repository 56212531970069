import {NarrowPage} from "../../elements/Pages";
import {React, useEffect, useState} from "react";
import Joi from "joi"
import {useQuery} from '@tanstack/react-query'
import {DateInput, Input, MultiSelect, Select, TextArea} from "../../elements/forms/Inputs"
import {FormSectionContent} from "../../elements/forms/FormLayout"
import Form, {baseHandleChange, baseHandleDateChange} from "../../elements/forms/Form"
import {useNavigate, useParams} from "react-router-dom";
import {toast} from "react-toastify";
import {careerModuleApi, getCareerModuleCategories} from "../../../api/career";
import {getRepairOrderAssignees, getRepairOrderCategories, repairOrderApi} from "../../../api/maintenance";
import checkPermission from "../../../utils/permissions";
import {useAuth} from "../../../contexts/AuthContext";

export default function RepairOrderCreateEdit() {

    const {id} = useParams()
    const {permissions} = useAuth();
    const [data, setData] = useState({})
    const [errors, setErrors] = useState({})

    const schema = {
        title: Joi.string().required(),
        category: Joi.object().required(),
        order: Joi.string().allow('', null)
    }

    const {data: categories} = useQuery(["repair_order_categories"], getRepairOrderCategories, {refetchInterval: 1000 * 60 * 60 * 24})
    const {data: repairOrderAssignees} = useQuery(["repair_order_assignees"], getRepairOrderAssignees, {refetchInterval: 1000 * 60 * 60 * 24})

    const canProcess = checkPermission(permissions, "maintenance.change_repairorder")

    let navigate = useNavigate();

    useEffect(() => {
        loadData()
    }, [id])

    async function loadData() {
        if (!id) return
        const newData = await repairOrderApi('get', id)
        setData(newData)
    }

    const title = (id || data.id) ? "Reparaturauftrag bearbeiten" : "Neuen Reparaturauftrag anlegen"
    const successMessage = (id || data.id) ? "Reparaturauftrag aktualisiert" : "Reparaturauftrag erstellt"

    async function onSave() {
        let newData
        if (!data.id) newData = await repairOrderApi('post', null, data)
        else newData = await repairOrderApi('patch', null, data)
        setData(newData)
        toast.success(successMessage)
        navigate(`/maintenance/repair-orders/${newData.id}`)
    }

    async function onDelete() {
        await repairOrderApi('delete', data.id)
        toast.success("Reparaturauftrag gelöscht.")
        navigate(`/maintenance/repair-orders/my`)
    }

    function handleChange(evt) {
        baseHandleChange(evt, data, setData, errors, setErrors, schema)
    }

    function handleMultiSelect(selectedOptions, path) {
        let newData = {...data}
        newData[path] = selectedOptions
        setData(newData)
    }

    return (
        <NarrowPage title={title}>

            <Form id="createRepairOrder" onSave={onSave}
                  onDelete={onDelete}
                  data={data} setData={setData}
                  errors={errors} setErrors={setErrors}
                  successMessage={successMessage}
                  schema={schema}>

                <FormSectionContent>

                    <Input
                        className="col-span-6"
                        path="title"
                        label="Titel"
                        type="text"
                        onChange={handleChange}
                        errors={errors}
                        data={data}
                    />

                    <TextArea
                        path="order"
                        type="text"
                        label="Auftrag"
                        className="sm:col-span-6"
                        onChange={handleChange}
                        errors={errors}
                        data={data}
                        rows={10}
                    />

                    <Input
                        className="col-span-6"
                        path="location"
                        label="Ort"
                        type="text"
                        onChange={handleChange}
                        errors={errors}
                        data={data}
                    />

                    <Select
                        path="category"
                        label="Kategorie"
                        className="sm:col-span-6"
                        options={categories}
                        valueAttr="id"
                        labelAttr="name"
                        onChange={handleChange}
                        errors={errors}
                        data={data}
                        isClearable={false}
                        valueOnly={false}
                    />

                    {canProcess && <>
                        <MultiSelect
                            path="assigned_to"
                            label="Zugewiesen an"
                            className="sm:col-span-6"
                            options={repairOrderAssignees}
                            valueAttr="id"
                            labelAttr="full_name"
                            onChange={(selectedOptions) => handleMultiSelect(selectedOptions, 'assigned_to')}
                            errors={errors}
                            data={data}
                            isClearable={true}
                            valueOnly={false}
                        />

                        <TextArea
                            path="completed_remarks"
                            type="text"
                            label="Bemerkungen"
                            className="sm:col-span-6"
                            onChange={handleChange}
                            errors={errors}
                            data={data}
                            rows={5}
                        />
                    </>}

                </FormSectionContent>

            </Form>

            <span onClick={() => navigate(-1)}
                  className="cursor-pointer float-right mt-2 font-medium text-imsblue-600">Zurück</span>

        </NarrowPage>
    )
}
