import {NarrowPage} from "../../elements/Pages";
import {React, useEffect, useState} from "react";
import Joi from "joi"
import {useQuery} from '@tanstack/react-query'
import {DateInput, Input, Select, TextArea} from "../../elements/forms/Inputs"
import {FormSectionContent} from "../../elements/forms/FormLayout"
import Form, {baseHandleChange, baseHandleDateChange} from "../../elements/forms/Form"
import {useNavigate, useParams} from "react-router-dom";
import {toast} from "react-toastify";
import {careerModuleApi, getCareerModuleCategories} from "../../../api/career";

export default function ModuleCreateEdit() {

    const {id} = useParams()

    const [data, setData] = useState({})
    const [errors, setErrors] = useState({}) // validation errors

    const schema = {
        title: Joi.string().required(),
        category: Joi.object().required(),
        credits: Joi.number().required(),
        costs: Joi.number().required(),
        time_needed: Joi.string().required()
    }

    const {data: categories} = useQuery(["career_module_categories"], getCareerModuleCategories, {refetchInterval: 1000 * 60 * 60 * 24})

    let navigate = useNavigate();

    useEffect(() => {
        loadData()
    }, [id])

    async function loadData() {
        if (!id) return
        const newData = await careerModuleApi('get', id)
        setData(newData)
    }

    const title = (id || data.id) ? "Modul bearbeiten" : "Neues Modul anlegen"
    const successMessage = (id || data.id) ? "Modul aktualisiert" : "Modul erstellt"

    async function onSave() {
        let newData
        if (!data.id) newData = await careerModuleApi('post', null, data)
        else newData = await careerModuleApi('patch', null, data)
        setData(newData)
        toast.success(successMessage)
        navigate(`/career/modules/${newData.id}`)
    }

    async function onDelete() {
        await careerModuleApi('delete', data.id)
        toast.success("Modul gelöscht.")
        navigate(`/career/modules`)
    }

    function handleChange(evt) {
        baseHandleChange(evt, data, setData, errors, setErrors, schema)
    }

    function handleDateChange(date, path) {
        baseHandleDateChange(date, path, data, setData, errors, setErrors, schema, false)
    }

    return (
        <NarrowPage title={title}>

            <Form id="createCareerModule" onSave={onSave}
                  onDelete={onDelete}
                  data={data} setData={setData}
                  errors={errors} setErrors={setErrors}
                  successMessage={successMessage}
                  schema={schema}>

                <FormSectionContent>

                    <Input
                        className="col-span-6"
                        path="title"
                        label="Titel"
                        type="text"
                        onChange={handleChange}
                        errors={errors}
                        data={data}
                    />

                    <Select
                        path="category"
                        label="Kategorie"
                        className="sm:col-span-6"
                        options={categories}
                        valueAttr="id"
                        labelAttr="name"
                        onChange={handleChange}
                        errors={errors}
                        data={data}
                        isClearable={false}
                        valueOnly={false}
                    />

                    <DateInput
                        className="col-span-3"
                        path="date_from"
                        label="Datum von"
                        onChange={handleDateChange}
                        errors={errors}
                        data={data}
                    />

                    <DateInput
                        className="col-span-3"
                        path="date_to"
                        label="Datum bis"
                        onChange={handleDateChange}
                        errors={errors}
                        data={data}
                    />

                    <Input
                        className="col-span-3"
                        path="credits"
                        label="Credits"
                        type="number"
                        onChange={handleChange}
                        errors={errors}
                        data={data}
                    />

                    <Input
                        className="col-span-3"
                        path="time_needed"
                        label="Zeitaufwand"
                        type="text"
                        onChange={handleChange}
                        errors={errors}
                        data={data}
                    />

                    <Input
                        className="col-span-3"
                        path="costs"
                        label="Kosten"
                        type="number"
                        onChange={handleChange}
                        errors={errors}
                        data={data}
                    />

                    <TextArea
                        path="description"
                        type="text"
                        label="Beschreibung"
                        className="sm:col-span-6"
                        onChange={handleChange}
                        errors={errors}
                        data={data}
                        rows={10}
                    />


                </FormSectionContent>

            </Form>

            <span onClick={() => navigate(-1)}
                  className="cursor-pointer float-right mt-2 font-medium text-imsblue-600">Zurück</span>

        </NarrowPage>
    )
}
