import {NarrowPage} from "../../elements/Pages";
import {React, useEffect, useState} from "react";
import Joi from "joi"
import {useQuery} from '@tanstack/react-query'
import {DateInput, Select, TextArea} from "../../elements/forms/Inputs"
import {FormSectionContent} from "../../elements/forms/FormLayout"
import Form, {baseHandleChange, baseHandleDateChange} from "../../elements/forms/Form"
import {useNavigate, useParams} from "react-router-dom";
import {toast} from "react-toastify";
import {getProjectList, projectCancellationApi} from "../../../api/projects";

export default function ProjectCancellationCreateEdit() {

    const {id} = useParams()
    const [data, setData] = useState({})

    const {data: projects} = useQuery(['projects'], getProjectList, {refetchInterval: 1000 * 60 * 60 * 24})

    const [errors, setErrors] = useState({}) // validation errors

    let navigate = useNavigate();

    useEffect(() => {
        loadData()
    }, [id])

    async function loadData() {
        if (!id) return
        const newData = await projectCancellationApi('get', id)
        setData(newData)
    }

    const schema = {
        date: Joi.date().required(),
        remarks: Joi.string().min(5),
        project: Joi.object().required()
    };

    const title = (id || data.id) ? "Projektausfall bearbeiten" : "Projektausfall eintragen"
    const successMessage = (id || data.id) ? "Projektausfall aktualisiert" : "Projektausfall eingetragen"

    async function onSave() {
        let newData
        if (!data.id) newData = await projectCancellationApi('post', null, data)
        else newData = await projectCancellationApi('patch', null, data)
        setData(newData)
        toast.success(successMessage)
        navigate(`/timetables`)
    }

    async function onDelete() {
        await projectCancellationApi('delete', data.id)
        toast.success("Projektausfall gelöscht.")
        navigate(`/timetables`)
    }

    function handleChange(evt) {
        baseHandleChange(evt, data, setData, errors, setErrors, schema)
    }

    function handleDateChange(date, path) {
        baseHandleDateChange(date, path, data, setData, errors, setErrors, schema, false)
    }
    return (

        <NarrowPage title={title}>

            <Form id="createProjectCancellation" onSave={onSave}
                  onDelete={onDelete}
                  data={data} setData={setData}
                  errors={errors} setErrors={setErrors}
                  successMessage={successMessage}
                  schema={schema}>

                <FormSectionContent>

                    <Select
                        path="project"
                        label="Projekt"
                        className="sm:col-span-6"
                        options={projects}
                        valueAttr="id"
                        labelAttr="name"
                        onChange={handleChange}
                        errors={errors}
                        data={data}
                        isClearable={false}
                        valueOnly={false}
                    />

                    <DateInput
                        path="date"
                        label="Datum"
                        className="sm:col-span-3"
                        onChange={handleDateChange}
                        errors={errors}
                        data={data}
                        todayButton={true}
                        tomorrowButton={false}
                    />

                    <TextArea
                        path="remarks"
                        label="Anmerkungen"
                        onChange={handleChange}
                        errors={errors}
                        data={data}
                        rows={4}
                        className="sm:col-span-6"
                        helpText=""
                    />
                </FormSectionContent>

            </Form>

            <span onClick={() => navigate(-1)}
                  className="cursor-pointer float-right mt-2 font-medium text-imsblue-600">Zurück</span>

        </NarrowPage>
    )
}
