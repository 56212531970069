import React from "react";

import {useQuery} from "@tanstack/react-query";
import {useParams} from "react-router-dom";
import {ExportPDFButton, PrimaryButton} from "../../elements/Buttons";
import {assessmentPeriodApi} from "../../../api/assessments";
import {getStudentInfoInclAlumni} from "../../../api/school";
import BoardingSchoolAssessmentDetail from "./BoardingSchoolAssessmentDetail";
import {getBoardingSchoolAssessmentsForStudent} from "../../../api/boarding_school";
import LogoPDF from "../../layout/LogoPDF";
import {useUserInfo} from "../../../contexts/UserInfoContext";

export default function BoardingSchoolStudentDetail() {

    const {assessmentPeriodId, studentId} = useParams()
    const {renderUserImage} = useUserInfo();

    const {data: student} = useQuery(["student_info", studentId], () =>
        getStudentInfoInclAlumni(studentId), {refetchInterval: 1000 * 60 * 60 * 24}
    )

    const {data: assessmentPeriod} = useQuery(
        ["assessment_periods", assessmentPeriodId],
        () => assessmentPeriodApi('get', assessmentPeriodId),
        {refetchInterval: 1000 * 60 * 60 * 24}
    )

    const {isLoading, isError, isSuccess, data, error, refetch} = useQuery(
        ["student_boarding_school_assessments", assessmentPeriodId, studentId],
        () => getBoardingSchoolAssessmentsForStudent(assessmentPeriodId, studentId),
    )

    if (!student || !assessmentPeriod) return <></>

    return (

        <>
            <main className="py-10 h-full bg-gray-100">
                <div
                    className="max-w-3xl mx-auto px-4 sm:px-6 md:flex md:items-center md:justify-between md:space-x-5 lg:max-w-7xl lg:px-8">
                    <div className="flex items-center space-x-5">
                        <div className="flex-shrink-0">
                            <div className="relative">
                                {renderUserImage(student.user_id, 80)}
                                <span
                                    className="absolute inset-0 shadow-inner rounded-full"
                                    aria-hidden="true"
                                />
                            </div>
                        </div>
                        <div>
                            <h1 className="text-2xl font-bold text-gray-900">
                                Internatsbeurteilung für {student.full_name}: {assessmentPeriod.name}
                            </h1>
                            <p className="text-sm font-medium text-gray-500">
                                {student.student_number}
                            </p>
                        </div>
                    </div>
                    <div
                        className="mt-6 flex flex-col-reverse justify-stretch space-y-4 space-y-reverse sm:flex-row-reverse sm:justify-end sm:space-x-reverse sm:space-y-0 sm:space-x-3 md:mt-0 md:flex-row md:space-x-3">

                        {/* TODO add logo */}
                        <ExportPDFButton
                          portrait={true}
                          data={
                              <div className="flex flex-col px-2">
                                  <BoardingSchoolAssessmentStudentDetailContent data={data}
                                                                                student={student}
                                                                                assessmentPeriod={assessmentPeriod}
                                                                                exporting={true} />
                              </div>}
                          fileName={`Internatsbeurteilung ${student.full_name}.pdf`}
                          label="PDF"
                          scale={0.5}
                        />

                        <PrimaryButton label="Schülerblatt" link={`/school/students/${studentId}`}/>
                    </div>
                </div>

               <div className="max-w-3xl mx-auto grid grid-cols-1 gap-6 sm:px-6 lg:max-w-7xl lg:grid-flow-col-dense lg:grid-cols-3">
                    <div className="space-y-6 lg:col-start-1 lg:col-span-3">

                        <div className="bg-gray-100 pt-6 px-4 sm:px-6 lg:px-8">
                            <div className="flex flex-col">
                                <BoardingSchoolAssessmentStudentDetailContent data={data}
                                                                              student={student}
                                                                              assessmentPeriod={assessmentPeriod}
                                                                              exporting={false} />
                            </div>
                        </div>

                    </div>
                </div>

            </main>

        </>
    );
}

function BoardingSchoolAssessmentStudentDetailContent ({data, student, assessmentPeriod, exporting=false}) {
    return <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div className="inline-block py-2 align-middle md:px-6 lg:px-8 max-w-6xl">
            {exporting && <div>
                <LogoPDF />
                <h1 className="text-lg font-medium">
                    Internatsbeurteilung für {student.full_name}: {assessmentPeriod.name}
                </h1>
            </div>}
            {data && data.map((a, index) => (
                <BoardingSchoolAssessmentDetail assessment={a}
                                                inStudent={true} key={index}
                                                canEdit={false} />
            ))}
        </div>
    </div>
}