import Joi from "joi";
import {React, useState} from "react";
import {Page} from "../../elements/Pages";
import {useQuery} from "@tanstack/react-query";
import {useNavigate} from "react-router-dom";
import {toast} from "react-toastify";
import {getSchoolSubjects, getStudentInfoAll} from "../../../api/school";
import Form, {baseHandleListChange,} from "../../elements/forms/Form";
import {FormSectionContent} from "../../elements/forms/FormLayout";
import {Select, TextArea,} from "../../elements/forms/Inputs";
import {createTutorings, getTutoringTeacher} from "../../../api/tutoring";
import {AddButton, RemoveButton} from "../../elements/Buttons";
import {getTeacherList} from "../../../api/staff";
import {getTutoringTeachers} from "../../../api/accounts";
import {validateProperty} from "../../../utils/validation";
import env from "react-dotenv";

export default function TutoringCreateMultiple() {

    const newTutoring = {
        id: 1,
        student: null,
        subject: null,
        teacher: null,
        tutoring_teacher: null,
        status: (env.SCHOOL === 'KPI' ? "PENDING" : "ACTIVE"),
        requested_by: "SCHOOL",
        tutoring_plan: ""
    }

    const [data, setData] = useState([{...newTutoring}]);

    const [newRowId, setNewRowId] = useState(2)
    const {data: students} = useQuery(["student_info"], getStudentInfoAll, {refetchInterval: 1000 * 60 * 60 * 24})
    const {data: schoolSubjects} = useQuery(["school_subjects"], getSchoolSubjects, {refetchInterval: 1000 * 60 * 60 * 24})
    const {data: teachers} = useQuery(["teachers"], getTeacherList, {refetchInterval: 1000 * 60 * 60 * 24})
    const {data: tutoringTeachers} = useQuery(["tutoring_teachers"], getTutoringTeachers, {refetchInterval: 1000 * 60 * 60 * 24})

    const [errors, setErrors] = useState([]); // validation errors

    let navigate = useNavigate()

    const schema = {
        student: Joi.object().required(),
        subject: Joi.object().required(),
        teacher: Joi.object().required()
    };

    const title = "Fördermaßnahmen anlegen"
    const successMessage = "Fördermaßnahmen angelegt."

    function addRow() {
        setData([...data, {...newTutoring, id: newRowId}])
        setNewRowId(newRowId + 1)
    }

    async function onSave() {
        await createTutorings(data)
        toast.success(successMessage);
        return navigate('/tutoring/list')
    }

    async function onDelete(rowId) {
        const newData = data.filter(row => row.id !== rowId)
        setData(newData)
    }

    function handleChange(evt, rowId) {
        baseHandleListChange(rowId, evt, data, setData, errors, setErrors, schema)
    }

    function handleSubjectOrStudentChange(evt, rowId) {

        const value = evt.target.value
        const row = data.find(row => row.id === rowId)

        const student = evt.target.name === 'student' ? value : row.student
        const subject = evt.target.name === 'subject' ? value : row.subject
        let subjectTeacher = row.teacher
        let newData

        // look up the course teacher for this subject and student
        if (student && subject) {
            getTutoringTeacher(student.id, subject.id).then((subjectTeacher) => {
                if (subjectTeacher.teacher) {
                    subjectTeacher = subjectTeacher.teacher
                }
                newData = data.map(row => {
                    if (row.id === rowId) {
                        return {...row, [evt.target.name]: value, teacher: subjectTeacher}
                    }
                    return row
                })
                setData(newData)
            })
        } else {
            newData = data.map(row => {
                if (row.id === rowId) {
                    return {...row, [evt.target.name]: value, teacher: subjectTeacher}
                }
                return row
            })
            setData(newData)
        }

        // field validation
        const newErrors = errors.map(row => {
            if (row.id === rowId) {
                return {...row, [evt.target.name]: validateProperty(evt.target, schema, data)}
            }
            return row
        })
        setErrors(newErrors)
    }

    return (
        <>
            <Page title={title}>
                <div
                    className="max-w-3xl mx-auto grid grid-cols-1 gap-6 lg:max-w-7xl lg:grid-flow-col-dense lg:grid-cols-6">

                    <div className="space-y-6 lg:col-span-6">
                        <Form
                            id="createTutorings"
                            onSave={onSave}
                            onDelete={onDelete}
                            data={data}
                            setData={setData}
                            errors={errors}
                            setErrors={setErrors}
                            successMessage={successMessage}
                            schema={schema}
                        >

                            {data.map((tutoring, index) => (


                                <FormSectionContent key={tutoring.id}>

                                    <div className="py-2 pt-4 space-y-6 lg:col-span-3">
                                        <Select
                                            path="student"
                                            label="SchülerIn"
                                            options={students}
                                            valueAttr="id"
                                            labelAttr="name_and_student_number"
                                            onChange={handleSubjectOrStudentChange}
                                            errors={errors}
                                            data={data}
                                            isClearable={false}
                                            valueOnly={false}
                                            rowId={tutoring.id}
                                        />

                                        <Select
                                            path="subject"
                                            label="Fach"
                                            options={schoolSubjects}
                                            valueAttr="id"
                                            labelAttr="name"
                                            onChange={handleSubjectOrStudentChange}
                                            errors={errors}
                                            data={data}
                                            isClearable={false}
                                            valueOnly={false}
                                            rowId={tutoring.id}
                                        />

                                        <Select
                                            path="teacher"
                                            label="FachlehrerIn"
                                            options={teachers}
                                            valueAttr="id"
                                            labelAttr="full_name"
                                            onChange={handleChange}
                                            errors={errors}
                                            data={data}
                                            isClearable={false}
                                            valueOnly={false}
                                            rowId={tutoring.id}
                                        />

                                        <Select
                                            path="tutoring_teacher"
                                            label="NachhilfelehrerIn"
                                            options={tutoringTeachers}
                                            valueAttr="id"
                                            labelAttr="full_name"
                                            onChange={handleChange}
                                            errors={errors}
                                            data={data}
                                            isClearable={true}
                                            valueOnly={false}
                                            rowId={tutoring.id}
                                        />

                                    </div>

                                    <div className="py-2 pt-4 space-y-6 lg:col-span-2">
                                        <TextArea
                                            path="tutoring_plan"
                                            label="Förderplan"
                                            className="sm:col-span-6"
                                            onChange={handleChange}
                                            errors={errors}
                                            data={data}
                                            rowId={tutoring.id}
                                            rows={12}
                                        />
                                    </div>

                                    <div className="py-2 pt-4 space-y-6 lg:col-span-1">
                                        <RemoveButton onClick={() => onDelete(tutoring.id)}
                                                      label="Fördermaßnahme löschen"/>
                                    </div>


                                </FormSectionContent>
                            ))}

                            <AddButton onClick={addRow} label="Fördermaßnahme hinzufügen"/>

                        </Form>

                        <span onClick={() => navigate(-1)}
                              className="cursor-pointer float-right mt-2 font-medium text-imsblue-600"
                        > Zurück</span>
                    </div>

                </div>
            </Page>
        </>
    );
}
