import http from "../services/httpService";
import httpServiceDownloads from "../services/httpServiceDownloads";
import {genericApi} from "./generic";

export async function getStudentNotenblatt(studentId, schoolYearId) {
    const resp = await http.get(`/grades/student_notenblatt/${studentId}/?school_year_id=${schoolYearId}`)
    return resp.data
}

export async function startStudentNotenblattUpdate(studentId, schoolYearId) {
    const resp = await http.get(`/grades/student_notenblatt/${studentId}/update/?school_year_id=${schoolYearId}`)
    return resp.data
}

export async function getStudents(studentId) {
    const resp = await http.get(`/grades/student_list/`)
    return resp.data
}

export async function startNotenblattGeneration() {
    const resp = await http.get(`/grades/student_notenblatt/multi/`);
    return resp.data;
}

export async function getCourseNotenblatt(courseId) {
    const resp = await http.get(`/grades/course_notenblatt/${courseId}/`)
    return resp.data
}

export async function startCourseNotenblattUpdate(courseId) {
    const resp = await http.get(`/grades/course_notenblatt/${courseId}/update/`)
    return resp.data
}

export async function getGradeListData(data) {
    const resp = await http.post(`/grades/notenlisten/`, data)
    return resp.data
}

export async function getGradeListDataExcel(data) {
    const resp = await httpServiceDownloads.post(`/grades/notenlisten/excel/`, data)
    return resp.data
}

export async function getSpecialGrades(studentId) {
    const resp = await http.get(`/special_grades/?student_id=${studentId}`)
    return resp.data
}
export async function specialGradesApi(method, id, data) {
    return genericApi('/special_grades/', method, id, data)
}

export async function getResultMarks(grade) {
    const resp = await http.get(`/result_marks/?grade=${grade}`)
    return resp.data
}

export async function getSpecialGradeDefinitions() {
    const resp = await http.get(`/special_grade_definitions/`)
    return resp.data
}

export async function getCourseGradesDetails(courseId) {
    const resp = await http.get(`/course_grades/${courseId}/`)
    return resp.data
}

export async function patchCourseGradesDetails(courseId, data) {
    const resp = await http.patch(`/course_grades/${courseId}/`, data)
    return resp.data
}

export async function getMarkDefinition(markDefinitionId) {
    const resp = await http.get(`/mark_definitions/${markDefinitionId}/`)
    return resp.data
}

export async function deleteMarkDefinitions(markDefinitionId) {
    const resp = await http.delete(`/mark_definitions/${markDefinitionId}/`)
    return resp.data
}

export async function getCourseResults(courseId) {
    const resp = await http.get(`/course_grades/${courseId}/results/`)
    return resp.data
}

export async function copyMarkDefinitions(data) {
    const resp = await http.post(`/course_grades/copy/`, data)
    return resp.data
}

export async function resultApi(method, id, data) {
    return genericApi('/results/', method, id, data)
}

export async function addFileToResult(formData, id) {
    const resp = await http.post(`/results/${id}/add_file/`, formData)
    return resp.data
}
