import React from "react";
import {SimplePage} from "../../elements/Pages";
import {useQuery} from "@tanstack/react-query";
import {getStudierzeitList} from "../../../api/studierzeit";
import {AddButton, EditButton, ListButton} from "../../elements/Buttons";

export default function StudierzeitOverview() {

    const {
        data,
    } = useQuery(["studierzeit_overview"], getStudierzeitList, {refetchInterval: 1000 * 60 * 60 * 24})

    return (
        <>
            <SimplePage>
                <div className="px-4 sm:px-6 lg:px-8">
                    <div className="sm:flex sm:items-center">
                        <div className="sm:flex-auto">
                            <h3 className="text-lg leading-6 font-medium text-gray-900">
                                Studierzeit nach Zeiten und Räumen
                            </h3>
                        </div>
                        <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none flex gap-1"></div>
                    </div>

                    <div className="flex flex-col mt-3 mb-3 scrollbarHeight">
                        <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
                            <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">

                                <div className="overflow-hidden">

                                    {data && <table
                                        className="min-w-full divide-y divide-gray-300 mt-3 border border-gray-200">
                                        <thead className="bg-gray-50">

                                        {/* Top level: weekday */}
                                        <tr className="divide-x divide-gray-200">
                                            <th className="py-3.5 text-center text-sm" key="room" rowSpan={3}>Raum</th>
                                            {data.columns.map((weekDate, index) => (
                                                <th className="py-3.5 text-center text-sm" key={index}
                                                    colSpan={data.number_of_periods_per_day}>
                                                    {weekDate.weekday}
                                                </th>
                                            ))}
                                            <th className="py-3.5 text-center text-sm" key="room2" rowSpan={3}>Raum</th>
                                        </tr>

                                        {/* 2nd level: studierzeit time */}
                                        <tr className="divide-x divide-gray-200">
                                            {data.columns.map((weekDate) => {
                                                return weekDate.studierzeit_times.map((studierzeitTime, index) => (
                                                    <th className="py-3.5 text-center text-sm" key={index} >
                                                        {studierzeitTime.name} <br/> {studierzeitTime.period_name}
                                                    </th>
                                                ))

                                            })}
                                        </tr>

                                        {/* 3rd level: period */}
                                        {/*<tr className="divide-x divide-gray-200">*/}
                                        {/*    {data.columns.map((weekDate) => {*/}
                                        {/*        return weekDate.studierzeit_times.map((studierzeitTime) => {*/}

                                        {/*            return studierzeitTime.periods.map((period, index) => (*/}
                                        {/*                <th className="py-3.5 text-center text-sm" key={index}>*/}
                                        {/*                    {period.name}*/}
                                        {/*                </th>*/}
                                        {/*            ))*/}
                                        {/*        })*/}

                                        {/*    })}*/}
                                        {/*</tr>*/}

                                        </thead>

                                        <tbody>

                                        {data.rows.map((row, rowIndex) => (
                                            <tr key={rowIndex}
                                                className={"divide-x divide-gray-200 " + (rowIndex % 2 === 0 ? "" : 'bg-gray-50')}>
                                                <td className="py-4 pl-4 pr-3 text-sm" key="room">{row.room?.name}</td>
                                                {row.cells.map((slot, slotIndex) => (
                                                    <Slot slot={slot}
                                                          key={slotIndex}
                                                    />
                                                ))}
                                                <td className="py-4 pl-4 pr-3 text-sm" key="room2">{row.room?.name}</td>
                                            </tr>
                                        ))}

                                        </tbody>

                                        <tfoot className="bg-gray-50">

                                        {/* Weekday & studierzeit time */}
                                        <tr className="divide-x divide-gray-200">
                                            <td></td>
                                            {data.columns.map((weekDate) => {
                                                return weekDate.studierzeit_times.map((studierzeitTime, index) => (
                                                    <td className="py-3.5 text-center text-sm" key={index} >
                                                        {weekDate.weekday} {studierzeitTime.name} {studierzeitTime.period_name}
                                                    </td>
                                                ))
                                            })}
                                            <td></td>
                                        </tr>

                                        </tfoot>

                                    </table>}
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </SimplePage>

        </>
    );
}

function Slot({slot}) {
    let tdClassNames = ""
    return <td className={"py-4 pl-4 pr-3 text-sm " + tdClassNames}>

        <div className="flex gap-2">

            <ListButton link={`/studierzeit/detail/?studierzeit_time=${slot.studierzeit_time_id}&weekday=${slot.weekday}&room=${slot.room_id}`}
                iconOnly={true} rounded={true}/>

            {slot.attendance_entry_id ?
                <EditButton link={`/studierzeit/attendance/${slot.attendance_entry_id}/edit`} iconOnly={true}
                            withBackground={true}/>
                : <AddButton
                    link={`/studierzeit/attendance/new/?studierzeit_time=${slot.studierzeit_time_id}&date=${slot.date}&room=${slot.room_id}`}/>}

        </div>

        {slot.occupied_seats} belegt

        {slot.available_seats !== null &&
            <div className="mt-2 text-sm text-center text-gray-500">{slot.available_seats} freie Pl.</div>}

    </td>
}
