import { today } from "../../utils/helpers";

const currentMonth = today.getMonth() + 1; // UTC months start at 0
export const months = [
  {
    name: "August",
    id: 8,
    year: currentMonth < 8 ? today.getFullYear() - 1 : today.getFullYear(),
  },
  {
    name: "September",
    id: 9,
    year: currentMonth < 8 ? today.getFullYear() - 1 : today.getFullYear(),
  },
  {
    name: "Oktober",
    id: 10,
    year: currentMonth < 8 ? today.getFullYear() - 1 : today.getFullYear(),
  },
  {
    name: "November",
    id: 11,
    year: currentMonth < 8 ? today.getFullYear() - 1 : today.getFullYear(),
  },
  {
    name: "Dezember",
    id: 12,
    year: currentMonth < 8 ? today.getFullYear() - 1 : today.getFullYear(),
  },
  {
    name: "Januar",
    id: 1,
    year: currentMonth < 8 ? today.getFullYear() : today.getFullYear() + 1,
  },
  {
    name: "Februar",
    id: 2,
    year: currentMonth < 8 ? today.getFullYear() : today.getFullYear() + 1,
  },
  {
    name: "März",
    id: 3,
    year: currentMonth < 8 ? today.getFullYear() : today.getFullYear() + 1,
  },
  {
    name: "April",
    id: 4,
    year: currentMonth < 8 ? today.getFullYear() : today.getFullYear() + 1,
  },
  {
    name: "Mai",
    id: 5,
    year: currentMonth < 8 ? today.getFullYear() : today.getFullYear() + 1,
  },
  {
    name: "Juni",
    id: 6,
    year: currentMonth < 8 ? today.getFullYear() : today.getFullYear() + 1,
  },
  {
    name: "Juli",
    id: 7,
    year: currentMonth < 8 ? today.getFullYear() : today.getFullYear() + 1,
  },
];

export const daysOfWeek = [
  { name: "Mo", shortName: "M" },
  { name: "Di", shortName: "D" },
  { name: "Mi", shortName: "M" },
  { name: "Do", shortName: "D" },
  { name: "Fr", shortName: "F" },
  { name: "Sa", shortName: "S" },
  { name: "So", shortName: "S" },
];
