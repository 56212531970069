import {PaperClipIcon, XMarkIcon} from "@heroicons/react/20/solid";
import React from "react";
import {useMessenger} from "../../../contexts/MessengerContext";
import {deleteAttachment} from "../../../api/messenger";

const FileList = () => {
    const {filesToSend, setFilesToSend} = useMessenger();

    if (!filesToSend.length) return <FileProgress/>

    return (
        <div className="w-full border-blue-500">
            <FileAttachments fileList={filesToSend} setFileList={setFilesToSend} isDelete/>
            <FileProgress/>
        </div>
    );
};

const FileProgress = () => {
    const {progress} = useMessenger();

    if (progress === 0 || progress === 100) return null

    return (
        <div className="p-2 sm:p-3">
            <div className="w-full bg-gray-200 rounded-full dark:bg-gray-700">
                <div
                    className="bg-blue-600 text-xs font-medium text-blue-100 text-center p-0.5 leading-none rounded-full"
                    style={{width: `${progress}%`}}
                >
                    {" "}
                    {progress}%
                </div>
            </div>
        </div>
    )
}

export const FileAttachments = ({fileList, setFileList, isDelete}) => {

    const handleDelete = async (file) => {
        try {
            await deleteAttachment(file.id)
        } catch (e) {
            // ignore - might have been deleted previously
            // console.log(e)
        }
        setFileList(fileList.filter((i) => i.id !== file.id))
    }

    if (!fileList.length) return null

    return (
        <ul className="w-full border border-gray-300 shadow-sm text-sm rounded-md divide-y divide-gray-300">
            {fileList.map((file, index) => (
                <FileAttachmentsItem
                    key={index}
                    file={file}
                    handleDelete={isDelete && handleDelete}
                />
            ))}
        </ul>
    )
}

const FileAttachmentsItem = ({file, handleDelete}) => {

    return (
        <li
            className="w-full inline-flex p-2 sm:p-3 justify-between font-medium focus:ring-blue-500 gap-2 sm:gap-3 items-center"
        >
            <div className="inline-flex truncate">
                <PaperClipIcon className="min-h-[1.25rem] min-w-[1.25rem] max-h-[1.25rem] max-w-[1.25rem]"
                               aria-hidden="true"/>
                <h2 className="ml-2 ">
                    {file.get_file_name}
                </h2>
            </div>
            <div className="inline-flex gap-2 sm:gap-3 items-center">
                <a href={file.file} target="_blank"><h2 className="text-imsblue-700 cursor-pointer"
                >Herunterladen
                </h2></a>
                {handleDelete && <XMarkIcon
                    className="cursor-pointer"
                    onClick={() => handleDelete(file)}
                    height={16}
                    width={16}
                />}
            </div>
        </li>
    )
}

export default FileList
