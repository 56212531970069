import http from "./../services/httpService";
import httpServiceDownloads from "./../services/httpServiceDownloads"

export async function getParentConferenceDays() {
    const resp = await http.get("/parent_conference_days/");
    return resp.data;
}

export async function getParentConferenceDay(conferenceDayId) {
    const resp = await http.get(`/parent_conference_days/${conferenceDayId}/`)
    return resp.data;
}

export async function getTeacherRooms(conferenceDayId) {
    const resp = await http.get(`/parent_conference_days/${conferenceDayId}/teacher_rooms/`)
    return resp.data;
}

export async function updateTeacherRooms(conferenceDayId, data) {
    const resp = await http.post(`/parent_conference_days/${conferenceDayId}/teacher_rooms/`, data)
    return resp.data;
}

export async function getParentConferenceRegistration() {
    const resp = await http.get("/parent_conference_registration/");
    return resp.data;
}

export async function getRegistrationExport(conferenceDayId) {
    const resp = await httpServiceDownloads.get(`/parent_conference_days/${conferenceDayId}/export/`)
    return resp.data;
}

export async function updateRegistration(conferenceDayId, data) {
    const resp = await http.patch(`/parent_conference_registration/${conferenceDayId}/`, data);
    return resp.data;
}

export async function getStudentSlots(conferenceDayId, studentId) {
    const resp = await http.get(`/parent_conference/${conferenceDayId}/students/${studentId}`);
    return resp.data;
}

export async function getBooking(bookingId) {
    const resp = await http.get(`/parent_conference_bookings/${bookingId}/`);
    return resp.data;
}

export async function createBooking(data) {
    const resp = await http.post(`/parent_conference_bookings/`, data);
    return resp.data;
}

export async function updateBooking(bookingId, data) {
    const resp = await http.patch(`/parent_conference_bookings/${bookingId}/`, data);
    return resp.data;
}

export async function deleteBooking(bookingId) {
    const resp = await http.delete(`/parent_conference_bookings/${bookingId}/`);
    return resp.data;
}

export async function getStudentAppointmentList(conferenceDayId, studentId) {
    const resp = await http.get(`/parent_conference/${conferenceDayId}/students/${studentId}/appointment_list/`);
    return resp.data;
}

export async function getStudentAppointmentListPDF(conferenceDayId, studentId) {
    const resp = await httpServiceDownloads.get(`/parent_conference/${conferenceDayId}/students/${studentId}/appointment_list_pdf/`);
    return resp.data;
}

export async function getTeacherAppointmentList(conferenceDayId, teacherId) {
    const resp = await http.get(`/parent_conference/${conferenceDayId}/teachers/${teacherId}/appointment_list/`);
    return resp.data;
}

export async function getTeacherAppointmentListPDF(conferenceDayId, teacherId) {
    const resp = await httpServiceDownloads.get(`/parent_conference/${conferenceDayId}/teachers/${teacherId}/appointment_list_pdf/`);
    return resp.data;
}

export async function startAllAppointmentListGeneration(conferenceDayId) {
    const resp = await http.get(`/parent_conference/${conferenceDayId}/appointment_lists/`);
    return resp.data;
}

export async function getStudentList(conferenceDayId) {
    const resp = await http.get(`/parent_conference_days/${conferenceDayId}/students/`);
    return resp.data;
}

export async function getTeacherSlots(conferenceDayId) {
    const resp = await http.get(`/parent_conference/${conferenceDayId}/teachers/`);
    return resp.data;
}

export async function getStaff(conferenceDayId) {
    const resp = await http.get(`/parent_conference_days/${conferenceDayId}/staff/`);
    return resp.data;
}

export async function removeBlockedSlot(blockedSlotId, studentId) {
    const resp = await http.post(`/parent_conference_blocked_slots/${blockedSlotId}/remove_for_student/`, {'student_id': studentId});
    return resp.data;
}

export async function startAllParentAppointmentListGeneration(conferenceDayId) {
    const resp = await http.get(`/parent_conference/${conferenceDayId}/parent_appointment_lists/`);
    return resp.data;
}