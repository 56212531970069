import http from "./../services/httpService";
import {genericApi} from "./generic";
import httpServiceDownloads from "../services/httpServiceDownloads";
import httpNoAuthService from "../services/httpNoAuthService";

// Tutoring

export async function getStatusOptions() {
    const resp = await http.get('/tutoring/status_options/')
    return resp.data
}

export async function getTutorings(currentOrAll, studentId) {
    const resp = await http.get('/tutorings/' + (currentOrAll === 'current' ? 'current/' : '') + (studentId ? `?student_id=${studentId}` : ''))
    return resp.data
}

export async function getMyTutorings() {
    const resp = await http.get('/tutorings/my/')
    return resp.data
}

export async function getTutoring(id) {
    const resp = await http.get(`/tutorings/${id}/`)
    return resp.data
}

export async function getTutoringWithSecret(id, secret) {
    const resp = await httpNoAuthService.get(`/tutorings/${id}/get_with_secret/?secret=${secret}`)
    return resp.data
}

export async function getTutoringPermissions(id) {
    const resp = await http.get(`/tutorings/${id}/permissions/`)
    return resp.data
}

export async function createInvoiceForTutoring(id) {
    const resp = await http.post(`/tutorings/${id}/create_invoices/`)
    return resp.data
}

export async function updateTutoringStatuses(data) {
    const resp = await http.post('/tutorings/update_status_for_multiple/', data)
    return resp.data
}

export async function patchTutoring(data) {
    const resp = await http.patch(`/tutorings/${data.id}/` , data)
    return resp.data
}

export async function cancelTutoring(id, data) {
    const resp = await http.post(`/tutorings/${id}/cancel/` , data)
    return resp.data
}

export async function confirmTutoring(tutoring) {
    const data = { secret: tutoring.secret }
    const resp = await httpNoAuthService.post(`/tutorings/${tutoring.id}/confirm/` , data)
    return resp.data
}

export async function rejectTutoring(tutoring) {
    const data = { secret: tutoring.secret }
    const resp = await httpNoAuthService.post(`/tutorings/${tutoring.id}/reject/` , data)
    return resp.data
}

export async function getTutoringTeacher(studentId, subjectId) {
    const data = {
        student_id: studentId,
        subject_id: subjectId
    }
    const resp = await http.post(`/tutoring/get_teacher/`, data)
    return resp.data
}

export async function createTutorings(data) {
    const resp = await http.post('/tutorings/create_multiple/', data)
    return resp.data
}

export async function requestTutoring(data) {
    const resp = await http.post('/tutorings/request/', data)
    return resp.data
}

// Lessons
export async function getTutoringLessons(tutoringId) {
    const resp = await http.get(`/tutoring_lessons/?tutoring_id=${tutoringId}`)
    return resp.data
}

export async function createOrUpdateMultipleTutoringLessons(data) {
    const resp = await http.post('/tutoring_lessons/create_or_update_multiple/', data)
    return resp.data
}

export async function deleteTutoringLesson(id) {
    const resp = await http.delete(`/tutoring_lessons/${id}/`)
    return resp.data
}

// Invoices

export async function getTutoringInvoices(tutoringId) {
    const resp = await http.get(`/tutoring_invoices/?tutoring_id=${tutoringId}`)
    return resp.data
}

export async function deleteTutoringInvoice(id) {
    const resp = await http.delete(`/tutoring_invoices/${id}/`)
    return resp.data
}

export async function getTutoringInvoicePDF(invoiceId) {
    const resp = await httpServiceDownloads.get(`/tutoring_invoices/${invoiceId}/pdf/`)
    return resp.data
}

// Materials

export async function getTutoringMaterials(tutoringId) {
    const resp = await http.get(`/tutoring_materials/?tutoring_id=${tutoringId}`)
    return resp.data
}

export async function getTutoringMaterial(id) {
    const resp = await http.get(`/tutoring_materials/${id}/`)
    return resp.data
}

export async function createTutoringMaterial(data) {
    const resp = await http.post(`/tutoring_materials/`, data)
    return resp.data
}

export async function updateTutoringMaterial(data) {
    const resp = await http.patch(`/tutoring_materials/${data.id}/`, data)
    return resp.data
}

export async function addFileToTutoringMaterial(formData, id) {
    const resp = await http.post(`/tutoring_materials/${id}/add_file/`, formData)
    return resp.data
}

export async function deleteTutoringMaterial(id) {
    const resp = await http.delete(`/tutoring_materials/${id}/`)
    return resp.data
}


// Tutoring dates
export async function getTutoringDates() {
    const resp = await http.get(`/tutoring_dates/`)
    return resp.data
}

export async function getCancellationDates() {
    const resp = await http.get(`/tutoring_dates/?is_cancellation_date=1`)
    return resp.data
}
