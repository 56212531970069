import {Page} from "../../elements/Pages";
import {React, useEffect, useState} from "react";
import Joi from "joi"
import {useQuery} from '@tanstack/react-query'
import {Checkbox, DateTimeInput, Input, MultiSelect, Select} from "../../elements/forms/Inputs"
import {FormSectionContent} from "../../elements/forms/FormLayout"
import {getStudentListWithMainMentor} from "../../../api/school";
import {getStaffList} from "../../../api/staff";
import Form, {baseHandleChange, baseHandleDateChange} from "../../elements/forms/Form"
import {useNavigate, useParams} from "react-router-dom";
import {toast} from "react-toastify";
import {addHandoutToSupportPlanMeeting, supportPlanMeetingApi} from "../../../api/boarding_school";


export default function SupportPlanMeetingCreateEdit() {

    const {id} = useParams()
    const [data, setData] = useState({
        id: null,
        title: null,
        student: null,
        start: null,
        end: null,
        handout: null,
        handout_required: false
    })

    const {data: students} = useQuery(['student_list_with_main_mentor'], getStudentListWithMainMentor, {refetchInterval: 1000 * 60 * 60 * 24})
    const {data: staff} = useQuery(['staff'], getStaffList, {refetchInterval: 1000 * 60 * 60 * 24})

    const [selectedFile, setSelectedFile] = useState();
    const [isFilePicked, setIsFilePicked] = useState(false);

    const [errors, setErrors] = useState({}) // validation errors

    let navigate = useNavigate();

    useEffect(() => {
        loadData()
    }, [id])

    async function loadData() {
        if (!id) return
        const newData = await supportPlanMeetingApi('get', id)
        setData(newData)
    }

    const schema = {
        student: Joi.object().required(),
        title: Joi.string().required(),
        start: Joi.date().required(),
        end: Joi.date().required().min(
            Joi.ref("start", {
                adjust: (value) => (value ? value.setSeconds(1) : new Date(1970, 1, 1)),
            })
        ).messages({"date.min": "Ende muss nach dem Start liegen"}),
    };

    const title = (id || data.id) ? "Hilfeplangespräch bearbeiten" : "Neues Hilfeplangespräch eintragen"
    const successMessage = (id || data.id) ? "Hilfeplangespräch aktualisiert" : "Neues Hilfeplangespräch eingetragen"

    async function onSave() {
        // saving the instance first
        let newData
        if (!data.id) newData = await supportPlanMeetingApi('post', null, data)
        else newData = await supportPlanMeetingApi('patch', null, data)

        // then adding the file
        if (isFilePicked) {
            const formData = new FormData();
            formData.append('file', selectedFile);
            newData = await addHandoutToSupportPlanMeeting(formData, newData.id)
        }

        setData(newData)
        toast.success(successMessage)
    }

    async function onDelete() {
        await supportPlanMeetingApi('delete', id)
        toast.success("Hilfeplangespräch gelöscht.")
        navigate(`/boarding-school/support-plan-meetings`)
    }

    function handleChange(evt) {
        baseHandleChange(evt, data, setData, errors, setErrors, schema)
    }

    function handleDateChange(date, path) {
        baseHandleDateChange(date, path, data, setData, errors, setErrors, schema, true)
    }

    const handleChangeFile = (event) => {
        setSelectedFile(event.target.files[0])
        setIsFilePicked(true)
    }

    function handleMultiSelect(selectedOptions, path) {
        let newData = {...data}
        newData[path] = selectedOptions
        setData(newData)
    }

    return (

        <Page title={title}>

            <div
                className="max-w-3xl mx-auto grid grid-cols-1 gap-6  lg:max-w-7xl lg:grid-flow-col-dense lg:grid-cols-6">
                <div className="space-y-6 lg:col-span-4">

                    <Form id="createSupportPlanMeeting" onSave={onSave}
                          onDelete={onDelete}
                          data={data} setData={setData}
                          errors={errors} setErrors={setErrors}
                          successMessage={successMessage}
                          schema={schema}>

                        <FormSectionContent>

                            <Input
                                path="title"
                                type="text"
                                label="Titel"
                                className="sm:col-span-6"
                                onChange={handleChange}
                                errors={errors}
                                data={data}
                            />

                            <Select
                                path="student"
                                label="SchülerIn"
                                className="sm:col-span-6"
                                options={students}
                                valueAttr="id"
                                labelAttr="full_name"
                                onChange={handleChange}
                                errors={errors}
                                data={data}
                                isClearable={false}
                                valueOnly={false}
                            />

                            <DateTimeInput
                                className="col-span-3"
                                path="start"
                                label="Von"
                                onChange={handleDateChange}
                                errors={errors}
                                data={data}
                                todayButton={true}
                                tomorrowButton={true}
                            />

                            <DateTimeInput
                                className="col-span-3"
                                path="end"
                                label="Bis"
                                onChange={handleDateChange}
                                errors={errors}
                                data={data}
                                todayButton={true}
                                tomorrowButton={true}
                                minDate={new Date(data.start) || null}
                            />

                            <p className="py-2 sm:col-span-2 text-sm font-medium text-gray-900">StammmentorIn: <br/>
                                <span
                                    className="text-md font-normal">{data.student && <>{data.student.main_mentor}</>}</span>
                            </p>

                            <MultiSelect
                                path="additional_participants"
                                label="Weitere Teilnehmer"
                                className="sm:col-span-4"
                                options={staff}
                                valueAttr="id"
                                labelAttr="full_name"
                                onChange={(selectedOptions) => handleMultiSelect(selectedOptions, 'additional_participants')}
                                errors={errors}
                                data={data}
                                isClearable={true}
                                valueOnly={false}
                            />

                            <Checkbox
                                path="handout_required"
                                label="Tischvorlage erforderlich"
                                className="sm:col-span-6"
                                onChange={handleChange}
                                errors={errors}
                                data={data}/>

                            <div className="sm:col-span-6 text-sm">
                                <input type="file" name="handout" onChange={handleChangeFile}/> <br/>

                                <div>
                                    {data.handout && <p className="py-1">Bisherige Datei: {data.handout}</p>}
                                </div>
                            </div>

                        </FormSectionContent>

                    </Form>

                    <span onClick={() => navigate(-1)}
                          className="cursor-pointer float-right mt-2 font-medium text-imsblue-600">Zurück</span>

                </div>

            </div>

        </Page>
    )
}
