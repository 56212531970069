import {Link} from "react-router-dom";
import React, {useEffect, useState} from "react";
import {baseHandleChange, baseHandleListChange} from "../../elements/forms/Form";
import {Select, TextArea} from "../../elements/forms/Inputs";
import {patchAssessment, getAssessmentForEdit, getAssessmentOptions} from "../../../api/assessments";
import {toast} from "react-toastify";
import env from "react-dotenv";
import Alert from "../../elements/Alert";
import {useQueryClient} from "@tanstack/react-query";
import {validate} from "../../../utils/validation";
import Joi from "joi";
import {passwordErrorMessage} from "../../../utils/regularExpressions";


export default function AssessmentEdit({assessmentId, inCourse, inStudent, index, onNext}) {

    const queryClient = useQueryClient()
    const [errors, setErrors] = useState({})
    const [assessmentErrorMessage, setAssessmentErrorMessage] = useState(null)
    const [listErrors, setListErrors] = useState([])
    const [loading, setLoading] = useState(false)
    const [finaliseError, setFinaliseError] = useState()

    const [conductGradesList, setConductGradesList] = useState([])
    const [assessment, setAssessment] = useState()
    const [options, setOptions] = useState()

    const schema = {
        final_mark: options?.show_final_grade ? Joi.string().required().messages({
            "any.empty": "Bitte eine Note eingeben",
            "string.empty": "Bitte eine Note eingeben",
            "string.base": "Bitte eine Note eingeben",
            "string.required": "Bitte eine Note eingeben",
            "any.required": "Bitte eine Note eingeben",
        }).label("Note") : Joi.any(),
    }

    function handleChange(evt) {
        baseHandleChange(evt, assessment, setAssessment, errors, setErrors, schema);
    }

    function handleConductGradesChange(evt, rowId) {
        baseHandleListChange(rowId, evt, conductGradesList, setConductGradesList, listErrors, setListErrors, {})
    }

    useEffect(() => {
        loadData()
    }, [assessmentId, index])

    async function loadData() {
        setLoading(true)

        const newOptions = await getAssessmentOptions(assessmentId)
        setOptions(newOptions)

        const newAssessment = await getAssessmentForEdit(assessmentId)
        setAssessment(newAssessment)

        let newConductGradesList = []
        if (newOptions.show_final_grade) {
            newConductGradesList = newOptions.conduct_grades_definitions.map(cgd => {
                const assessmentConductGrade = newAssessment.conduct_grades.find(acg => acg.conduct_grade_definition_id === cgd.id)
                return {
                    "id": cgd.id,
                    "conduct_grade_definition_id": cgd.id,
                    "conduct_grade_definition_name": cgd.name,
                    "mark": assessmentConductGrade?.mark,
                    "mark_id": assessmentConductGrade?.mark_id
                }
            })
            setConductGradesList(newConductGradesList)
        }

        setLoading(false)
    }

    async function handleSubmit(finalized, isNext) {
        setLoading(true)

        const payload = {...assessment, conduct_grades: conductGradesList, finalized: finalized}

        // validate first
        const validationErrors = validate(schema, payload)
        setErrors(validationErrors)
        if (validationErrors) {
            setAssessmentErrorMessage("Bitte korrigiere die markierten Felder.")
            setLoading(false)
            return
        } else {
            setAssessmentErrorMessage("")
        }

        try {
            const newData = await patchAssessment(assessmentId, payload)
            queryClient.invalidateQueries({ queryKey: ["assessments", `${assessment.assessment_period.id}`, `${newData.course.id}`] })
            setAssessment(newData)
            toast.success("Beurteilung gespeichert.")
            setFinaliseError(null)
            isNext && onNext()
        } catch (e) {
            if (e.data.message) {
                setAssessment(e.data.assessment)
                setFinaliseError(e.data.message)
            } else {
                toast.error("Beim Speichern ist ein Fehler aufgetreten.")
            }
        }
        setLoading(false)
    }

    if (!assessment || !options) return <></>

    return (
        <div className="py-6 max-w-3xl mx-auto grid grid-cols-1 gap-6 lg:max-w-7xl lg:grid-flow-col-dense lg:grid-cols-4">
            <div className="bg-white space-y-6 lg:col-start-1 lg:col-span-4">

                <div className="hidden">Assessment ID: {assessment.id}</div>

                <section aria-labelledby="assessment-edit">
                    <div className="shadow overflow-hidden sm:rounded-lg">

                        {inCourse && <div className="px-4 py-5 sm:px-6">
                            <h3 className="text-lg leading-6 font-medium text-gray-900">
                                <Link to={`/school/students/${assessment.student.id}`}
                                      className="text-gray-900">{assessment.student.full_name}</Link>
                                , {assessment.student.student_number}
                            </h3>
                            <p className="mt-1 max-w-2xl text-sm text-gray-500">
                                {assessment.student.school_class}</p>
                        </div>}

                        {inStudent && <div className="px-4 py-5 sm:px-6">
                            <h3 className="text-lg leading-6 font-medium text-gray-900">
                                <Link to={`/courses/${assessment.course.id}`}
                                      className="text-gray-900">{assessment.course.course_number} {assessment.course.school_subject}</Link>
                            </h3>
                            <p className="mt-1 max-w-2xl text-sm text-gray-500">
                                FachlehrerIn: {assessment.course.teacher?.full_name}</p>
                        </div>}

                        <div className="border-t border-b border-gray-200 px-4 py-5 sm:px-6">
                            <dl className="grid grid-cols-2 gap-x-4 gap-y-8 sm:grid-cols-4">
                                <div className="sm:col-span-1">
                                    <dt className="text-sm font-medium text-gray-500">Durchschnitt {assessment.assessment_period.time_period.name}</dt>
                                    <dd className="mt-1 text-sm text-gray-900">
                                        {options.assessment_avg}
                                    </dd>
                                </div>
                                <div className="sm:col-span-1">
                                    <dt className="text-sm font-medium text-gray-500">Epochennote (wird automatisch eingetragen)</dt>
                                    <dd className="mt-1 text-sm text-gray-900">
                                        {assessment.epoch_mark}
                                    </dd>
                                </div>

                                {options.show_final_grade && <div className="sm:col-span-1">
                                    <dt className="text-sm font-medium text-gray-500">{assessment.assessment_period.average_name}</dt>
                                    <dd className="mt-1 text-sm text-gray-900">
                                        {options.final_assessment_avg}

                                        {options.has_special_grades && <>
                                         ({options.final_avg_with_bonusses_course} inkl. Sondernoten)
                                        </>}
                                    </dd>
                                </div>}

                                {/*{env.SCHOOL === 'KPI' && <>*/}
                                {/*    <div className="sm:col-span-1">*/}
                                {/*        <dt className="text-sm font-medium text-gray-500">Schriftlich {assessment.assessment_period.time_period.name}</dt>*/}
                                {/*        <dd className="mt-1 text-sm text-gray-900">{assessment.written_avg}</dd>*/}
                                {/*    </div>*/}
                                {/*    <div className="sm:col-span-1">*/}
                                {/*        <dt className="text-sm font-medium text-gray-500">Sonstige {assessment.assessment_period.time_period.name}</dt>*/}
                                {/*        <dd className="mt-1 text-sm text-gray-900">{assessment.other_avg}</dd>*/}
                                {/*    </div>*/}
                                {/*</>}*/}

                                {options.show_final_grade &&
                                <div className="sm:col-span-1">
                                    <dt className="text-sm font-medium text-gray-500">{assessment.assessment_period.grade_name}</dt>
                                    <dd className="mt-1 text-sm text-gray-900">
                                        <Select
                                            path="final_mark"
                                            label={assessment.assessment_period.grade_name}
                                            className="sm:col-span-3 hide-label"
                                            options={options.mark_options}
                                            valueAttr="value"
                                            labelAttr="label"
                                            onChange={handleChange}
                                            errors={errors}
                                            data={assessment}
                                            disabled={!options.can_edit_marks}
                                            isClearable={true}
                                            valueOnly={true}
                                        />
                                    </dd>
                                </div>}
                            </dl>
                        </div>

                        {options.show_final_grade &&
                        <div className="border-t border-b border-gray-200 px-4 py-5 sm:px-6">
                        <div className="grid grid-cols-2 gap-x-4 gap-y-8 sm:grid-cols-4">

                            {conductGradesList.map((conductGrade, index) => (
                                <Select
                                    key={index}
                                    path="mark_id"
                                    label={conductGrade.conduct_grade_definition_name}
                                    className="sm:col-span-2 py-2"
                                    options={options.conduct_grades_value_options}
                                    valueAttr="id"
                                    labelAttr="description"
                                    onChange={handleConductGradesChange}
                                    data={conductGradesList}
                                    isClearable={true}
                                    valueOnly={true}
                                    rowId={conductGrade.id}
                                />
                            ))}
                        </div>
                        </div>}

                        <div className="flex flex-col px-4 py-5 sm:px-6 gap-4">

                            {env.SCHOOL === 'KPI' && options.tutoring_recommendation_options && <Select
                              path="tutoring_recommendation"
                              label="Nachhilfeempfehlung"
                              className="sm:col-span-3"
                              options={options.tutoring_recommendation_options}
                              valueAttr="value"
                              labelAttr="label"
                              onChange={handleChange}
                              errors={errors}
                              data={assessment}
                              isClearable={true}
                              valueOnly={true}
                            />}

                            {options.has_assessment_text && <TextArea
                                path="assessment"
                                type="text"
                                label="Beurteilung"
                                className="sm:col-span-6 pt-4"
                                onChange={handleChange}
                                errors={errors}
                                data={assessment}
                                rows={10}
                                helpText={assessment?.length}
                            />}

                            {finaliseError &&
                                <Alert variant="warning" extraClassName="sm:col-span-6">
                                    {finaliseError}
                                </Alert>}

                            <div className="pt-4 flex flex-wrap gap-2 justify-between">

                                <div className="">
                                    <div className={"inline-flex align-middle items-baseline px-2.5 py-1 rounded-full text-sm font-medium md:mt-2 lg:mt-0 " + (assessment.finalized ? "text-green-800 bg-green-50"  : "text-yellow-600 bg-yellow-50")}>
                                        {assessment.finalized ? "Abgeschlossen" : "Offen"}
                                    </div>
                                </div>

                                {assessmentErrorMessage && <div className="text-right text-sm font-medium text-red-500">{assessmentErrorMessage}</div>}

                                <div className="flex flex-wrap gap-2 justify-end">
                                {/*Save and finalize*/}
                                <button
                                    disabled={loading}
                                    onClick={() => handleSubmit(true, false)}
                                    className="cursor-pointer inline-flex items-center justify-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-blue-500 disabled:bg-gray-400 disabled:opacity-50"
                                >
                                    Speichern und abschliessen
                                </button>

                                {/*Save*/}
                                <button
                                    disabled={loading}
                                    onClick={() => handleSubmit(false, false)}
                                    className="cursor-pointer justify-center align-middle py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-imsblue-600 hover:bg-imsblue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-imsblue-500 disabled:bg-gray-400 disabled:opacity-50 disabled:text-gray-700"
                                >
                                    Speichern
                                </button>

                                {/*Save and Next*/}
                                {onNext && <button
                                  disabled={loading}
                                  onClick={() => handleSubmit(false, true)}
                                  className="cursor-pointer justify-center align-middle py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-imsblue-600 hover:bg-imsblue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-imsblue-500 disabled:bg-gray-400 disabled:opacity-50 disabled:text-gray-700"
                                >
                                    Speichern und weiter
                                </button>}

                                {/*Next*/}
                                {onNext && <button
                                    disabled={loading}
                                    onClick={() => onNext()}
                                    className="cursor-pointer inline-flex items-center justify-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-blue-500 disabled:bg-gray-400 disabled:opacity-50"
                                >
                                    Weiter
                                </button>}
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </div>
    )
}
