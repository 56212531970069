import React from 'react'
import {useAuth} from "./../../contexts/AuthContext"
import ListViewTable from "../elements/tables/ListViewTable";
import {SimplePage} from "../elements/Pages";
import {useQuery} from "@tanstack/react-query";
import {getUsersForAdmin} from "../../api/accounts";


export default function LoginAs() {

    const { loginAs } = useAuth()
    const {isLoading, isError, isSuccess, data, error, refetch} = useQuery(["users_for_admin"], getUsersForAdmin, {refetchInterval: 1000 * 60 * 60})

    async function handleLoginAs(user) {
        await loginAs(user.id);
        window.location = '/' // forces a re-load, which we need here
    }

    const columns = [
        {
            accessorFn: (row) => `${row.last_name}, ${row.first_name}`,
            id: "name",
            header: "Name",
            enableColumnFilter: false,
        },
        {
            accessorKey: "username",
            id: "username",
            header: "Benutzername",
            enableColumnFilter: false,
            filterFn: "containsString",
        },
        {
            accessorKey: "email",
            id: "email",
            header: "Email",
            enableColumnFilter: false,
            filterFn: "containsString",
        },
        {
            accessorKey: "id",
            id: "hw",
            cell: ({row, value}) => (
                <a href="#" onClick={() => handleLoginAs(row.original)}>
                    <i style={{cursor: "pointer"}}
                       className="fa fa-user"
                       aria-hidden="true"/> Anmelden als
                </a>
            ),
            disableRowLink: true,
            header: "Anmelden als",
            enableColumnFilter: false,
        },
    ];

    return (

        <SimplePage>
            <div className="px-4 sm:px-6 lg:px-8">
                <div className="sm:flex sm:items-center">
                    <div className="sm:flex-auto">
                        <h3 className="text-lg leading-6 font-medium text-gray-900 mb-2">
                            Benutzer
                        </h3>
                    </div>
                    <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">

                    </div>
                </div>
                <div className="flex flex-col">
                    <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
                        <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                            {isSuccess && data && (
                                <ListViewTable
                                    data={data}
                                    columns={columns}
                                    isLoading={isLoading}
                                    disableGlobalSearch={false}
                                />
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </SimplePage>

    )
}
