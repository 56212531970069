import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { useEvents } from "../../contexts/EventsContext";
import {
  addDaysToDate,
  checkIfDateFallsBetween,
  // getDateOfWeek,
  getDaysInMonth,
  getFormattedTime,
  getMonthOfWeek,
  getNumberArrayWithRange,
  today,
} from "../../utils/helpers";
import CalendarContainer from "./CalendarContainer";
import Month from "./Month";
import Week from "./Week";
import {daysOfWeek} from "./calendarUtils";

export default function Calendar() {
  const {
    selectedDate,
    setSelectedDate,
    view,
    setView,
    fetchedEventsMonth,
    fetchedEventsWeek,
    openEvent,
  } = useEvents();
  const search = window.location.search;
  const params = new URLSearchParams(search);
  const event_id = params.get("event_id");
  const year = params.get("year");
  const month = params.get("month");
  const week = params.get("week");
  const date = params.get("date");
  const [days, setDays] = useState([]);
  const [weekDays, setWeekDays] = useState([]);
  const nextPrev = (action, value) => {
    switch (action) {
      case "next":
        if (view === "month")
          setSelectedDate({
            year:
              selectedDate.month === 12
                ? selectedDate.year + 1
                : selectedDate.year,
            month: selectedDate.month === 12 ? 1 : selectedDate.month + 1,
            date: selectedDate.date,
          });
        else {
          const currentDate = new Date(selectedDate.year, selectedDate.month-1, selectedDate.date)
          const newDate = addDaysToDate(currentDate, 7);

          setSelectedDate({
            year: newDate.getFullYear(),
            month: newDate.getMonth() + 1,
            date: newDate.getDate(),
          });
        }
        break;

      case "prev":
        if (view === "month")
          setSelectedDate({
            year:
              selectedDate.month === 1
                ? selectedDate.year - 1
                : selectedDate.year,
            month: selectedDate.month === 1 ? 12 : selectedDate.month - 1,
            date: selectedDate.date,
          });
        else {
          const currentDate = new Date(selectedDate.year, selectedDate.month-1, selectedDate.date);
          const newDate = addDaysToDate(currentDate, -7);

          setSelectedDate({
            year: newDate.getFullYear(),
            month: newDate.getMonth() + 1,
            date: newDate.getDate(),
          });
        }
        break;

      case "curr":
        setSelectedDate({
          year: today.getFullYear(),
          month: today.getMonth() + 1,
          date: today.getDate(),
        });
        break;
      case "value":
        if (view === "month")
          setSelectedDate({
            ...value,
          });
        else {
          const currentDate = `${value.year}-${value.month}-${value.date}`;
          const newDate = addDaysToDate(currentDate, -7);

          setSelectedDate({
            year: newDate.getFullYear(),
            month: newDate.getMonth() + 1,
            date: newDate.getDate(),
          });
        }
        break;

      default:
        break;
    }
  };
  const setDaysForMonth = () => {
    const currYear = selectedDate.year;
    const currMonth = selectedDate.month
    const lastMonth = currMonth === 1 ? 12 : currMonth - 1;
    const nextMonth = currMonth === 12 ? 1 : currMonth + 1;
    const totalDaysCurrMonth = getDaysInMonth(currYear, currMonth);
    const totalDaysLastMonth = getDaysInMonth(
      currYear,
      currMonth !== 1 ? currMonth - 1 : 12
    );
    // const firstDate = new Date(`${currYear}-${currMonth}-01`)
    const firstDate = new Date(currYear, currMonth - 1, 1);
    const dayOfFirstDate = firstDate.getDay() === 0 ? 6 : firstDate.getDay() - 1;
    const lastDate = new Date(currYear, currMonth - 1, totalDaysCurrMonth);

    const dayOfLastDate = lastDate.getDay() === 0 ? 6 : lastDate.getDay() -1;
    const lastMonthYear = currMonth === 0 ? currYear - 1 : currYear;
    const nextMonthYear = currMonth === 11 ? currYear + 1 : currYear;

    const days = [
      ...getNumberArrayWithRange(
        dayOfFirstDate,
        totalDaysLastMonth + 1 - dayOfFirstDate
      ).map((date) => {
        return { date: `${lastMonthYear}-${lastMonth}-${date}`, events: [] };
      }),
      ...getNumberArrayWithRange(totalDaysCurrMonth, 1).map((date) => {
        return {
          date: `${currYear}-${currMonth}-${date}`,
          isCurrentMonth: true,
          isToday:
            `${currYear}-${currMonth}-${date}` ===
            `${today.getFullYear()}-${today.getMonth() + 1}-${today.getDate()}`,
          events: fetchedEventsMonth
            ? fetchedEventsMonth
                .filter((event) => {
                  return checkIfDateFallsBetween(
                    `${currMonth}/${date}/${currYear}`,
                    event.start,
                    event.end
                  );
                })
                .map((event) => {
                  return {
                    ...event,
                    id: event.event_id,
                    name: event.event.title,
                    time: getFormattedTime(event),
                    datetime: event.start,
                    href: "#",
                    category: event.event.category,
                  };
                })
            : [],
        };
      }),
      ...getNumberArrayWithRange(Math.max(6 - dayOfLastDate, 0), 1).map((date) => {
        return { date: `${nextMonthYear}-${nextMonth}-${date}`, events: [] };
      }),
    ];
    setDays(days);
  };

  const setDaysForWeek = () => {
    const dateObj = new Date(selectedDate.year, selectedDate.month - 1, selectedDate.date)
    var dayOfWeek = dateObj.getDay() === 0 ? 7 : dateObj.getDay()
    const startOfWeek = addDaysToDate(dateObj, -dayOfWeek)

    const newWeekDays = daysOfWeek.map((day, index) => {
      const date = addDaysToDate(startOfWeek, index + 1);
      return {
        ...day,
        date,
        isToday:
          `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}` ===
          `${today.getFullYear()}-${today.getMonth() + 1}-${today.getDate()}`,
      };
    });
    setWeekDays(newWeekDays);
  };
  useEffect(() => {
    if (view === "month") setDaysForMonth();
  }, [selectedDate, fetchedEventsMonth]);
  useEffect(() => {
    // console.log("selectedDate", selectedDate);
    if (view === "week") setDaysForWeek();
  }, [selectedDate]);

  useEffect(() => {
    if (week) {
      setView("week");
      const month = getMonthOfWeek(parseInt(week), parseInt(year));
      // const date = getDateOfWeek(parseInt(week), parseInt(year));
      setSelectedDate({
        year: parseInt(year),
        month,
        date: parseInt(date),
      });
    } else if (month) {
      setView("month");
      setSelectedDate({
        year: parseInt(year),
        month,
        date: today.getDate(),
      });
    }
  }, [year, month, week, date]);
  useEffect(() => {
    if (view === "month" && event_id && fetchedEventsMonth?.length) {
      const selectedEvent = fetchedEventsMonth.find(
        (i) => i.event_id === parseInt(event_id)
      );
      if (selectedEvent) openEvent(selectedEvent);
    } else if (view === "week" && event_id && fetchedEventsWeek?.length) {
      const selectedEvent = fetchedEventsWeek.find(
        (i) => i.event_id === parseInt(event_id)
      );
      if (selectedEvent) openEvent(selectedEvent);
    }
  }, [event_id, fetchedEventsMonth, fetchedEventsWeek]);

  return (
    <CalendarContainer
      setView={setView}
      view={view}
      nextPrev={nextPrev}
      selectedDate={selectedDate}
    >
      {view === "month" ? <Month providerFunction={useEvents} days={days} /> : <Week providerFunction={useEvents} days={weekDays} />}
    </CalendarContainer>
  );
}
