import {Link, useParams} from "react-router-dom";
import {React, useEffect, useState} from "react";
import checkPermission from "../../../utils/permissions";
import {useAuth} from "../../../contexts/AuthContext";
import {toast} from "react-toastify";
import {
    cancelProject,
    createInvoiceForProject,
    getParticipantListPDF,
    getProject,
    getProjectInvoicePDF,
    restartProject
} from "../../../api/projects";
import {DownloadButton, EditButton, PrimaryButton, SecondaryButton} from "../../elements/Buttons";
import {ConfirmButton} from "../../elements/ConfirmButton";
import ListViewTable, {TableDownloadLink} from "../../elements/tables/ListViewTable";
import {useUserInfo} from "../../../contexts/UserInfoContext";


const invoiceColumns = [
    {
        accessorKey: "creation_date",
        id: "creation_date",
        header: "Datum",
        columnType: "date",
        enableColumnFilter: false,
        classNames: "text-gray-500",
    },
    {
        accessorKey: "invoice",
        id: "invoice",
        header: "PDF",
        enableColumnFilter: false,
        classNames: "text-gray-500",
        cell: ({row, value}) => (
            row.original.id ?
                <TableDownloadLink
                    apiFunction={() => getProjectInvoicePDF(row.original.id)}
                    fileName={`Rechnung ${row.original.id}.pdf`}
                    label="PDF"/>
                : ""
        ),
        disableRowLink: true
    }
]

export default function ProjectDetail() {

    const {id} = useParams();
    const {permissions} = useAuth();
    const {renderUserImage} = useUserInfo()
    const [project, setProject] = useState()

    useEffect(() => {
        loadData()
    }, [id])

    async function loadData() {
        const newData = await getProject(id)
        setProject(newData)
    }

    async function handleCancel() {
        try {
            const newData = await cancelProject(id)
            setProject(newData)
            toast.success("Projekt abgesagt")
        } catch (e) {
            toast.error("Beim Speichern ist ein Fehler aufgetreten.")
        }
    }

    async function handleRestart() {
        try {
            const newData = await restartProject(id)
            setProject(newData)
            toast.success("Projekt wieder aufgenommen")
        } catch (e) {
            toast.error("Beim Speichern ist ein Fehler aufgetreten.")
        }
    }

    async function handleCreateInvoices() {
        try {
            await createInvoiceForProject(project.id)
            loadData()
            toast.success("Rechnung erstellt")
        } catch (e) {
            toast.error("Beim Speichern ist ein Fehler aufgetreten.")
        }
    }

    if (!project) return <></>;

    const canViewStudents = checkPermission(permissions, "school.view_student_mini")
    const canEditProject = checkPermission(permissions, "projects.change_project")
    const canCreateAttendance = checkPermission(permissions, "projects.add_projectattendance")

    return (
        <>
            <main className="py-10 h-full bg-gray-100">
                {/* Page header */}
                <div
                    className="max-w-3xl mx-auto px-4 sm:px-6 md:flex md:items-center md:justify-between md:space-x-5 lg:max-w-7xl lg:px-8">
                    <div className="flex items-center space-x-5">
                        <div>
                            <h1 className="text-2xl font-bold text-gray-900">
                                {project.name} {project.cancelled && <>(Abgesagt)</>}
                            </h1>
                            {/*<p className="text-sm font-medium text-gray-500">*/}
                            {/*    {tutoring.student.student_number}, Klasse {tutoring.student.school_class}*/}
                            {/*</p>*/}
                        </div>
                    </div>
                    <div
                        className="mt-6 flex flex-col-reverse justify-stretch space-y-4 space-y-reverse sm:flex-row-reverse sm:justify-end sm:space-x-reverse sm:space-y-0 sm:space-x-3 md:mt-0 md:flex-row md:space-x-3">

                        {canCreateAttendance && <>
                            <SecondaryButton label="Anwesenheit ansehen"
                                             link={`/projects/attendance/projects/${project.id}`}/>
                            <PrimaryButton label="Anwesenheit erfassen"
                                           link={`/projects/attendance/new/${project.id}`}/>
                            <DownloadButton
                                label={"Teilnehmerliste"}
                                fileName={`Teilnehmerliste.pdf`}
                                apiFunction={() => getParticipantListPDF(project.id)}
                            /></>
                        }
                        {canEditProject && <EditButton link={`/projects/${project.id}/edit`} label={"Bearbeiten"}/>}

                    </div>
                </div>

                <div
                    className="mt-8 max-w-3xl mx-auto grid grid-cols-1 gap-6 sm:px-6 lg:max-w-7xl lg:grid-flow-col-dense lg:grid-cols-3">
                    <div className="space-y-6 lg:col-start-1 lg:col-span-2">

                        <section aria-labelledby="applicant-information-title">
                            <div className="bg-white shadow sm:rounded-lg">
                                {/*<div className="px-4 py-5 sm:px-6">*/}
                                {/*    <h2 id="applicant-information-title" className="text-lg font-medium leading-6 text-gray-900">*/}
                                {/*        För*/}
                                {/*    </h2>*/}
                                {/*    <p className="mt-1 max-w-2xl text-sm text-gray-500">Personal details and application.</p>*/}
                                {/*</div>*/}
                                <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
                                    <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
                                        <div className="sm:col-span-1">
                                            <dt className="text-sm font-medium text-gray-500">Projektleiter</dt>
                                            <dd className="mt-1 text-sm text-gray-900">{project.project_leaders.map((c, index) => {
                                                return <Link key={index} to={`/staff/${c.id}`}>{c.full_name}</Link>
                                            })}</dd>
                                        </div>
                                        <div className="sm:col-span-1">
                                            <dt className="text-sm font-medium text-gray-500">Kategorie</dt>
                                            <dd className="mt-1 text-sm text-gray-900">{project.categories.map((c) => c.name)}</dd>
                                        </div>

                                        <div className="sm:col-span-1">
                                            <dt className="text-sm font-medium text-gray-500">Klassenstufen</dt>
                                            <dd className="mt-1 text-sm text-gray-900">{project.grades.map((grade) => grade.name).join(", ")}</dd>
                                        </div>
                                        <div className="sm:col-span-1">
                                            <dt className="text-sm font-medium text-gray-500">Zeiten</dt>
                                            <dd className="mt-1 text-sm text-gray-900">{project.get_project_times_str}</dd>
                                        </div>

                                        <div className="sm:col-span-1">
                                            <dt className="text-sm font-medium text-gray-500">Maximale Teilnehmerzahl
                                            </dt>
                                            <dd className="mt-1 text-sm text-gray-900">{project.max_participants}</dd>
                                        </div>
                                        <div className="sm:col-span-1">
                                            {project.contribution_to_costs &&
                                                <>
                                                    <dt className="text-sm font-medium text-gray-500">Kostenbeitrag im
                                                        Halbjahr
                                                    </dt>
                                                    <dd className="mt-1 text-sm text-gray-900">{project.contribution_to_costs}€</dd>
                                                </>
                                            }
                                        </div>


                                        <div className="sm:col-span-2">
                                            <dt className="text-sm font-medium text-gray-500">Beschreibung</dt>
                                            <dd className="mt-1 text-sm text-gray-900 whitespace-pre-wrap ">
                                                {project.description}
                                            </dd>
                                        </div>


                                        {canEditProject && <div className="sm:col-span-2">
                                            <dt className="text-sm font-medium text-gray-500">Aktionen</dt>
                                            <dd className="mt-1 text-sm text-gray-900 whitespace-pre-wrap ">
                                                {project.cancelled && <PrimaryButton
                                                    label={"Projekt wieder aufnehmen"}
                                                    onClick={handleRestart}
                                                />}

                                                {!project.cancelled && <ConfirmButton
                                                    onConfirm={handleCancel}
                                                    label="Projekt absagen"
                                                    confirmPrompt="Wirklich absagen und alle Belegungen löschen?"
                                                />}
                                            </dd>
                                        </div>}

                                    </dl>
                                </div>
                            </div>
                        </section>

                        {/* Table */}
                        <section aria-labelledby="applicant-information-title"></section>
                    </div>

                    <section aria-labelledby="timeline-title" className="lg:col-span-1 lg:col-start-3">


                        {canViewStudents && <section aria-labelledby="applicant-information-title">
                            <div className="bg-white shadow overflow-hidden sm:rounded-lg">
                                <div className="px-4 py-5 sm:px-6">
                                    <h3 className="text-lg leading-6 font-medium text-gray-900">
                                        Teilnehmer
                                    </h3>
                                </div>
                                <div className="border-t border-gray-200 px-4 py-5 sm:p-0">
                                    <ul role="list" className="divide-y divide-gray-200">
                                        {project.active_participants.map((student, index) => (
                                            <Link key={index} to={`/school/students/${student.id}`}>
                                                <li className="sm:px-6 flex py-2">
                                                    {renderUserImage(student.user_id)}
                                                    <div className="ml-3">
                                                        <p className="text-sm font-medium text-gray-900 py-2">
                                                            {student.full_name} {student.student_number}
                                                        </p>
                                                    </div>
                                                </li>
                                            </Link>
                                        ))}
                                    </ul>
                                </div>
                            </div>
                        </section>}

                    </section>
                </div>

                {project.can_view_invoices && project.project_leader_invoices && <div
                    className="mt-8 max-w-3xl mx-auto grid grid-cols-1 sm:px-6 lg:max-w-7xl lg:grid-flow-col-dense">
                    <div className="bg-white shadow sm:rounded-lg sm:overflow-hidden">
                        <div className="divide-y divide-gray-200">
                            <div className="px-4 py-5 sm:px-6 flex justify-between">
                                <h2 className="text-lg font-medium text-gray-900">
                                    Abrechnungen
                                </h2>
                                {project.can_invoice && <SecondaryButton
                                    onClick={handleCreateInvoices}
                                    label="Abrechnung für offene Projekttermine erstellen"/>}
                            </div>
                            <div className="">
                                {project.invoices && project.invoices.length > 0 &&
                                    <ListViewTable disableGlobalSearch={true}
                                                   data={project.invoices}
                                                   columns={invoiceColumns}/>}
                            </div>
                        </div>
                    </div>
                </div>}

            </main>
        </>
    )
}
