import {Page} from "../../elements/Pages";
import {React, useEffect, useState} from "react";
import Joi from "joi"
import {Checkbox, DateInput, Input, MultiSelect, TextArea} from "../../elements/forms/Inputs"
import {FormSectionContent} from "../../elements/forms/FormLayout"
import Form, {
    baseHandleChange,
    baseHandleDateChange,
    baseHandleDateListChange,
    baseHandleListChange
} from "../../elements/forms/Form"
import {Link, useNavigate, useParams} from "react-router-dom";
import {toast} from "react-toastify";
import {getCourse} from "../../../api/courses";
import {DeleteButton, DeleteImmediatelyButton} from "../../elements/DeleteButton";
import {
    addFileToHomework,
    classRegisterEntryApi, getAbsenceNoticesAndHomeworkForCourse,
    getExperiments,
    homeworkApi
} from "../../../api/class_register";
import {AddButton, EditButton, PrimaryButton} from "../../elements/Buttons";
import {useQuery, useQueryClient} from "@tanstack/react-query";
import {getDayPeriods} from "../../../api/calendar";
import {formatDate, formatTimeFromDateTime} from "../../../utils/formatters";
import moment from "moment";


export default function ClassRegisterEntryCreateEdit() {

    const {id, courseId} = useParams()
    const queryClient = useQueryClient()

    const [data, setData] = useState({
        id: null,
        date: moment(new Date()).format("YYYY-MM-DD"),
        day_period: [],
        lesson_contents: null,
        notable_incidents: null,
        cancelled: false,
        cancel_reason: null,
        experiment_instructions_given: [],
        students_with_missing_homework: [],
        students_with_missing_materials: [],
        course: {id: parseInt(courseId) || null},
        homework: []
    })

    const schema = {
        day_period: Joi.array().required(),
        date: Joi.date().required(),
        cancel_reason: Joi.string().max(250).allow(null, '')
    }

    const [absenceNoticesAndHomework, setAbsenceNoticesAndHomework] = useState()
    const [course, setCourse] = useState()
    const [newRowId, setNewRowId] = useState(1)
    const [errors, setErrors] = useState({}) // validation errors
    const [homeworkErrors, setHomeworkErrors] = useState([])

    const {data: dayPeriods} = useQuery(['day_periods'], getDayPeriods, {refetchInterval: 1000 * 60 * 60 * 24})
    const {data: experiments} = useQuery(['experiments'], getExperiments, {refetchInterval: 1000 * 60 * 60 * 24})


    let navigate = useNavigate();

    useEffect(() => {
        loadData()
    }, [id])

    async function loadData() {

        let newData
        let course
        if (id) {
            newData = await classRegisterEntryApi('get', id)
            course = await getCourse(newData.course.id)
        } else if (courseId) {
            course = await getCourse(courseId)
            newData = {...data}
        }

        setData(newData)
        setCourse(course)
    }

    useEffect(() => {
        loadAbsenceNoticesAndHomework(data.course.id, data.date)
    }, [data.date])

    async function loadAbsenceNoticesAndHomework() {
        if (!data.date || !data.course || data.course.id === null) return
        const a = await getAbsenceNoticesAndHomeworkForCourse(data.course.id, data.date)
        setAbsenceNoticesAndHomework(a)
    }

    const title = (id || data.id) ? `Kursbucheintrag bearbeiten` : `Kursbucheintrag eintragen`
    const successMessage = (id || data.id) ? `Kursbucheintrag aktualisiert` : `Kursbucheintrag hochgeladen`

    async function onSave() {
        // saving the instance first
        let newData
        if (!data.id) newData = await classRegisterEntryApi('post', null, data)
        else newData = await classRegisterEntryApi('patch', null, data)

        // add the files
        for (let i = 0; i < data.homework.length; i++) {
            const m = data.homework[i]
            if (m.isFilePicked) {
                for (let j = 0; j < m.selectedFiles.length; j++) {
                    const formData = new FormData();
                    formData.append('file', m.selectedFiles[j]);
                    await addFileToHomework(formData, newData.homework[i].id) // todo this might not be the same order
                }
            }
        }

        // refetch the whole thing?
        setData(newData)
        queryClient.invalidateQueries({ queryKey: ['class_register_entries'] })
        await loadData()
        toast.success(successMessage)
        navigate(`/class-register/entries/${newData.id}`)
    }

    async function onDelete() {
        await classRegisterEntryApi('delete', data.id)
        toast.success(`Kursbucheintrag gelöscht.`)
        navigate(`/class-register/courses/${data.course.id}`)
    }

    function handleChange(evt) {
        baseHandleChange(evt, data, setData, errors, setErrors, schema)
    }

    function handleMultiSelect(selectedOptions, path) {
        let newData = {...data}
        newData[path] = selectedOptions
        setData(newData)
    }

    function handleDateChange(date, path) {
        baseHandleDateChange(date, path, data, setData, errors, setErrors, schema, false)
    }

    // function handleChangeFile(event, rowId) {
    //     setHomework(data.homework.map(row => {
    //             if (row.id === rowId) {
    //                 return {...row, selectedFiles: event.target.files, isFilePicked: true}
    //             }
    //             return row
    //         }
    //     ))
    // }
    function handleChangeFile(event, rowId) {
        setHomework(data.homework.map(row => {
            if (row.id === rowId) {
                const newFilesArray = [...event.target.files];
                const existingFilesArray = row.selectedFiles ? [...row.selectedFiles] : [];

                const concatenatedFiles = [...existingFilesArray, ...newFilesArray];

                return {
                    ...row,
                    selectedFiles: concatenatedFiles,
                    isFilePicked: true
                }
            }
            return row;
        }))
    }

    function handleDeleteFile(index) {
        const newHomework = data.homework.map(row => {
            const newFilesArray = [...row.selectedFiles];
            newFilesArray.splice(index, 1);
            return {
                ...row,
                selectedFiles: newFilesArray,
                isFilePicked: newFilesArray.length > 0
            }
        })
        setHomework(newHomework)
    }

    function addRow() {
        const newRow = {
            id: `N${newRowId}`,
            title: "Hausaufgabe",
            task: null,
            // course_id: data.course.id || courseId,
            due_date: null,
            homework_materials: null,
            selectedFiles: null,
            isFilePicked: false
        }
        setNewRowId(newRowId + 1)
        setHomework([...data.homework, newRow])
    }

    function setHomework(newHomework) {
        setData({...data, homework: newHomework})
    }

    function handleHomeworkChange(evt, rowId) {
        baseHandleListChange(rowId, evt, data.homework, setHomework, homeworkErrors, setHomeworkErrors, {})
    }

    function handleHomeworkDateChange(date, path, rowId) {
        baseHandleDateListChange(rowId, date, path, data.homework, setHomework, homeworkErrors, setHomeworkErrors, {}, false)
    }

    async function handleDeleteHomework(homeworkId) {
        if (homeworkId.toString().startsWith("N")) {
            const newHomework = data.homework.filter(row => row.id !== homeworkId)
            setHomework(newHomework)
        } else {
            try {
                await homeworkApi('delete', homeworkId)
                loadData()
                toast.success("Hausaufgabe gelöscht")
            } catch (e) {
                toast.error("Beim Speichern ist ein Fehler aufgetreten.")
            }
        }
    }

    if (!course) return <></>
    return (

        <Page title={title}>

            <div
                className=" max-w-3xl mx-auto grid grid-cols-1 gap-6 lg:max-w-7xl lg:grid-flow-col-dense lg:grid-cols-3">
                <div className="space-y-6 lg:col-start-1 lg:col-span-2">

                    <section aria-labelledby="notes-title">

                        {course &&
                            <h2 className="text-md font-bold text-gray-900">{course.course_number} {course.school_subject.name}</h2>}

                        <Form id="createClassRegisterEntry" onSave={onSave}
                              onDelete={onDelete}
                              data={data} setData={setData}
                              errors={errors} setErrors={setErrors}
                              successMessage={successMessage}
                              schema={schema}>


                            <FormSectionContent>

                                <div className="sm:col-span-6">
                                    <PrimaryButton onClick={onSave} label={"Speichern"}/>
                                </div>

                                <DateInput
                                    path="date"
                                    label="Datum"
                                    className="sm:col-span-3"
                                    onChange={handleDateChange}
                                    errors={errors}
                                    data={data}
                                    todayButton={true}
                                    tomorrowButton={false}
                                />

                                <MultiSelect
                                    path="day_period"
                                    label="Stunde"
                                    className="sm:col-span-3"
                                    options={dayPeriods}
                                    valueAttr="id"
                                    labelAttr="name"
                                    onChange={(selectedOptions) => handleMultiSelect(selectedOptions, 'day_period')}
                                    errors={errors}
                                    data={data}
                                    isClearable={true}
                                    valueOnly={false}
                                />


                                <TextArea
                                    path="lesson_contents"
                                    type="text"
                                    label="Unterrichtsinhalt"
                                    className="sm:col-span-6"
                                    onChange={handleChange}
                                    errors={errors}
                                    data={data}
                                    rows={3}
                                />


                                <div className="sm:col-span-6 text-sm">
                                    <label className="mb-2 block text-lg font-medium text-gray-900">
                                        Hausaufgaben
                                    </label>

                                    {data.homework.map((m, index) => (
                                        <div key={index} className="bg-white shadow overflow-hidden sm:rounded-lg mb-4">

                                            <div className="border-t border-gray-200 px-4 py-5">

                                                <FormSectionContent>

                                                    <DateInput
                                                        path="due_date"
                                                        label="Zu erledigen bis"
                                                        className="sm:col-span-3"
                                                        onChange={(date, path, rowId) => handleHomeworkDateChange(date, path, rowId)}
                                                        errors={homeworkErrors}
                                                        data={data.homework}
                                                        todayButton={false}
                                                        tomorrowButton={true}
                                                        inSevenDaysButton={true}
                                                        rowId={m.id}
                                                    />

                                                    <TextArea
                                                        path="task"
                                                        label="Aufgabe"
                                                        className="sm:col-span-6"
                                                        onChange={handleHomeworkChange}
                                                        errors={homeworkErrors}
                                                        data={data.homework}
                                                        rowId={m.id}
                                                        rows={3}
                                                    />

                                                    <div className="sm:col-span-6 mb-0">
                                                        <label className="mb-2 block text-md font-medium text-gray-900"
                                                               htmlFor="file">
                                                            Materialien
                                                        </label>

                                                        <p className="text-sm text-gray-700">Du kannst mehrere Dateien auf einmal oder nacheinander auswählen</p>

                                                        {m.homework_materials?.length > 0 &&
                                                            <ul>
                                                                {m.homework_materials.map((hm, index) => (
                                                                    <li key={index}><a
                                                                        className="text-imsblue-600" target="_blank"
                                                                        href={hm.file}>{hm.get_file_name}</a></li>
                                                                ))
                                                                }
                                                            </ul>}

                                                        <input multiple={true}
                                                               className="mt-4 block w-full mb-5 text-sm text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50 dark:text-gray-400 focus:outline-none dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400"
                                                               type="file" name="file"
                                                               onChange={(event) => handleChangeFile(event, m.id)}/>
                                                        <br/>

                                                        {m.selectedFiles?.length > 0 &&
                                                            <><p
                                                                className="text-gray-800 font-medium">{m.selectedFiles?.length} ausgewählt: </p>

                                                                <ul>
                                                                    {[...m.selectedFiles].map((file, index) => (
                                                                        <li key={index}>{file.name} <DeleteImmediatelyButton iconOnly={true} onDelete={() => handleDeleteFile(index)} /> </li>
                                                                    ))}
                                                                </ul>
                                                            </>}
                                                    </div>


                                                    <div className="sm:col-span-6 flex justify-between">
                                                        {!m.id.toString().startsWith("N") && <EditButton iconOnly={true}
                                                                                                         link={`/class-register/homework/${m.id}/edit`}/>}

                                                        <DeleteButton
                                                            onDelete={() => handleDeleteHomework(m.id)}/>
                                                    </div>

                                                </FormSectionContent>

                                            </div>
                                        </div>))}


                                    <div className="my-4 ml-4">
                                        <AddButton onClick={addRow} label="Hausaufgabe hinzufügen"/>
                                    </div>

                                </div>


                                <hr className="sm:col-span-6"/>

                                <MultiSelect
                                    path="students_with_missing_materials"
                                    label="SchülerInnen mit fehlenden Materialien"
                                    className="sm:col-span-6"
                                    options={course.students}
                                    valueAttr="id"
                                    labelAttr="full_name"
                                    onChange={(selectedOptions) => handleMultiSelect(selectedOptions, 'students_with_missing_materials')}
                                    errors={errors}
                                    data={data}
                                    isClearable={true}
                                    valueOnly={false}
                                />

                                <MultiSelect
                                    path="students_with_missing_homework"
                                    label="SchülerInnen mit fehlenden Hausaufgaben"
                                    className="sm:col-span-6"
                                    options={course.students}
                                    valueAttr="id"
                                    labelAttr="full_name"
                                    onChange={(selectedOptions) => handleMultiSelect(selectedOptions, 'students_with_missing_homework')}
                                    errors={errors}
                                    data={data}
                                    isClearable={true}
                                    valueOnly={false}
                                />


                                <MultiSelect
                                    path="experiment_instructions_given"
                                    label="Versuchsbelehrungen erteilt"
                                    className="sm:col-span-6"
                                    options={experiments}
                                    valueAttr="id"
                                    labelAttr="name"
                                    onChange={(selectedOptions) => handleMultiSelect(selectedOptions, 'experiment_instructions_given')}
                                    errors={errors}
                                    data={data}
                                    isClearable={true}
                                    valueOnly={false}
                                />

                                <TextArea
                                    path="notable_incidents"
                                    type="text"
                                    label="Besondere Vorkommnisse"
                                    helpText="Leitungsteam wird benachrichtigt"
                                    className="sm:col-span-6"
                                    onChange={handleChange}
                                    errors={errors}
                                    data={data}
                                    rows={3}
                                />

                                <Checkbox
                                    path="cancelled"
                                    label="Ausfall"
                                    className="sm:col-span-3"
                                    onChange={handleChange}
                                    errors={errors}
                                    data={data}/>

                                <Input
                                    path="cancel_reason"
                                    type="text"
                                    label="Grund"
                                    className="sm:col-span-3"
                                    onChange={handleChange}
                                    errors={errors}
                                    data={data}
                                />


                            </FormSectionContent>

                        </Form>


                        <span onClick={() => navigate(`/class-register/courses/${data.course.id}`)}
                              className="cursor-pointer float-right mt-2 font-medium text-imsblue-600">Zurück zum Kursbuch</span>


                    </section>
                </div>


                <section aria-labelledby="timeline-title"
                         className="lg:col-start-3 lg:col-span-1 mt-6"
                >
                    <section aria-labelledby="applicant-information-title" className="bg-gray-100">

                        <div className="">
                            <div className="px-4 py-5 sm:px-6">
                                <h3 className="text-lg leading-6 font-medium text-gray-900">
                                    Abwesenheitsmeldungen
                                </h3>
                            </div>
                            <div className="border-t border-gray-200 px-4 py-5 sm:p-0">

                                {absenceNoticesAndHomework && absenceNoticesAndHomework.absence_notices &&
                                    <table className="min-w-full table-fixed divide-y divide-gray-300">
                                        <tbody className="divide-y divide-gray-200 bg-white">
                                        {absenceNoticesAndHomework.absence_notices.map((a, index) => (
                                            <tr key={index}>
                                                <td className="px-4 py-4">
                                                    {a.absence_notice.student.full_name}
                                                </td>
                                                <td className="py-4">
                                                    <>{formatTimeFromDateTime(a.occurrence.start)} - {formatTimeFromDateTime(a.occurrence.end)}</>
                                                </td>
                                            </tr>
                                        ))}
                                        </tbody>
                                    </table>}

                            </div>
                        </div>

                    </section>

                    <section aria-labelledby="applicant-information-title" className="bg-gray-100">

                        <div className="">
                            <div className="px-4 py-5 sm:px-6">
                                <h3 className="text-lg leading-6 font-medium text-gray-900">
                                    Andere aktuelle Hausaufgaben
                                </h3>
                            </div>
                            <div className="border-t border-gray-200 px-4 py-5 sm:p-0">

                                {absenceNoticesAndHomework && absenceNoticesAndHomework.homework &&
                                    <table className="min-w-full table-fixed divide-y divide-gray-300">
                                        <tbody className="divide-y divide-gray-200 bg-white">
                                        {absenceNoticesAndHomework.homework.map((h, index) => (
                                            <tr key={index}>
                                                <td className="py-4">
                                                    <>zum {formatDate(h.due_date)}</>
                                                </td>
                                                <td className="px-4 py-4">
                                                    <Link to={`/class-register/homework/${h.id}`}>
                                                        <h5 className="font-medium">{h.course?.course_number} {h.course?.school_subject}</h5>
                                                        <p className="whitespace-pre-wrap">{h.task}</p>
                                                    </Link>
                                                </td>
                                            </tr>
                                        ))}
                                        </tbody>
                                    </table>}

                            </div>
                        </div>

                    </section>

                </section>


            </div>


            {/*<div*/}
            {/*    className="mt-6 max-w-3xl mx-auto grid grid-cols-1 gap-6 lg:max-w-7xl lg:grid-flow-col-dense lg:grid-cols-3">*/}
            {/*    <div className="space-y-6 lg:col-start-1 lg:col-span-2">*/}


            {/*    </div>*/}
            {/*    </div>*/}

        </Page>
    )
}
