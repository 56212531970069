import React from "react";
import {useAuth} from "../../../contexts/AuthContext";
import {useParams} from "react-router-dom";
import {
    getParentConferenceDay,
    getStudentList,
    startAllParentAppointmentListGeneration,
} from "../../../api/parent_conference";
import {SimplePage} from "../../elements/Pages";
import {useQuery} from "@tanstack/react-query";
import ListViewTable, {TableLink} from "../../elements/tables/ListViewTable";
import {AsyncDownloadButton} from "../../elements/Buttons";

export default function Students() {
    const {conferenceDayId} = useParams();
    const {permissions} = useAuth();

    const {data: conferenceDay} = useQuery(
        ["parent_conference_day", conferenceDayId],
        () => getParentConferenceDay(conferenceDayId),
        {refetchInterval: 1000 * 60 * 60}
    );

    const {data: students, isLoading} = useQuery(
        ["parent_conference_day_students", conferenceDayId],
        () => getStudentList(conferenceDayId),
        {refetchInterval: 1000 * 60 * 60}
    );

    const columns = [
        {
            accessorKey: "full_name",
            id: "student_search",
            header: "Schüler",
            hide: true,
            enableColumnFilter: false,
        },
        {
            accessorFn: (row) => row,
            id: "student",
            header: "Schüler",
            columnType: "student",
            enableColumnFilter: false,
            sortingFn: "lastNameSorting",
        },
        {
            accessorKey: "house",
            id: "house",
            header: "Mentorat",
            enableColumnFilter: true,
            headerClassNames: "narrow-filter",
        },
        {
            accessorKey: "school_class",
            id: "school_class",
            header: "Klasse",
            enableColumnFilter: true,
            sortingFn: "schoolClassSorting",
            headerClassNames: "narrow-filter",
        },
        {
            accessorKey: "num_appointments",
            id: "num_appointments",
            header: "Termine",
            enableColumnFilter: false,
        },
        {
            accessorKey: "id",
            id: "link_booking",
            cell: (info) => (
                <TableLink
                    label="Termine buchen"
                    to={`/parent-conference/${conferenceDayId}/students/${info.getValue()}`}
                />
            ),
            header: "Termine buchen",
            enableColumnFilter: false,
            disableRowLink: true
        },
        {
            accessorKey: "id",
            id: "link_list",
            cell: (info) => (
                <TableLink
                    label="Terminliste"
                    to={`/parent-conference/${conferenceDayId}/students/${info.getValue()}/list`}
                />
            ),
            header: "Terminliste",
            enableColumnFilter: false,
            disableRowLink: true
        },
    ];

    return (
        <>
            <SimplePage>
                <div className="px-4 sm:px-6 lg:px-8">
                    <div className="sm:flex sm:items-center">
                        <div className="sm:flex-auto">
                            <h3 className="text-lg leading-6 font-medium text-gray-900">
                                {conferenceDay?.title} - Schüler
                            </h3>
                        </div>
                        <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none gap-1 flex">
                            <AsyncDownloadButton
                                apiFunction={() => startAllParentAppointmentListGeneration(conferenceDayId)}
                                label="Alle Terminlisten erstellen"/>
                        </div>
                    </div>
                    <div className="flex flex-col mt-3 mb-3">
                        <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
                            <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">

                                {isLoading && <>Lädt...</>}

                                {students && (
                                    <ListViewTable
                                        data={students}
                                        columns={columns}
                                        disableGlobalSearch={false}
                                    />
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </SimplePage>
        </>
    );
}
