import {useParams} from "react-router-dom";
import {React, useEffect, useState} from "react";
import {useQuery} from "@tanstack/react-query";
import {DownloadButton, EditButton, PrimaryButton, SecondaryButton} from "../../elements/Buttons";
import {useAuth} from "../../../contexts/AuthContext";
import {
    deleteProjectAttendance,
    getAttendanceListPDF,
    getProject,
    getProjectAttendanceList
} from "../../../api/projects";
import {SimplePage} from "../../elements/Pages";
import {formatDate} from "../../../utils/formatters";
import {DeleteButton} from "../../elements/DeleteButton";
import {toast} from "react-toastify";


export default function AttendanceProjectDetail() {

    const {projectId} = useParams();
    const {permissions} = useAuth();

    const [data, setData] = useState()

    useEffect(() => {
        loadData()
    }, [projectId])

    async function loadData() {
        const newData = await  getProjectAttendanceList(projectId)
        setData(newData)
    }

    const {data: project, refetch} = useQuery(["projects", projectId], () =>
            getProject(projectId),
    )

    async function handleDelete(attendanceId) {
        try {
            await deleteProjectAttendance(attendanceId)
            loadData()
            toast.success("Projektanwesenheit gelöscht")
        } catch (e) {
            toast.error("Beim Speichern ist ein Fehler aufgetreten.")
        }
    }

    if (!project || !data) return <></>;

    return (
        <>
            <SimplePage>
                <div className="px-4 sm:px-6 lg:px-8">
                    <div className="sm:flex sm:items-center">
                        <div className="sm:flex-auto">
                            <h3 className="text-lg leading-6 font-medium text-gray-900">
                                Anwesenheit {project.name}
                            </h3>
                        </div>
                        <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none gap-2 flex">
                            <SecondaryButton link={`/projects/${projectId}`} label="Projekt ansehen" />
                            <PrimaryButton link={`/projects/attendance/new/${projectId}`} label="Anwesenheit erfassen" />
                            <DownloadButton
                                apiFunction={() => getAttendanceListPDF(projectId)}
                                fileName={`Anwesenheit ${project.name}.pdf`}
                                label="PDF herunterladen" />
                        </div>
                    </div>
                    <div className="flex flex-col mt-3 mb-3 scrollbarHeight">
                        <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
                            <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">

                                <div className="overflow-hidden">
                                    <table className="w-full divide-y divide-gray-300" style={{borderSpacing: 0}}>
                                        <thead className="bg-gray-50 font-medium">
                                        <tr>
                                            <td className="text-md py-2 px-2">Bearbeiten</td>
                                            <td className="text-md py-2 px-2">Datum</td>
                                            <td className="text-md py-2 px-2">Ausgefallen</td>
                                            {data.students.map((student, index) => (
                                                <td className="text-md py-2 px-2" key={index}>
                                                    {student.full_name}
                                                </td>
                                            ))}
                                        </tr>

                                        </thead>

                                        <tbody>

                                        {data.rows.map((row, rowIndex) => (

                                            <tr key={rowIndex}>
                                                <td className="py-1 px-1 border-b border-gray-200" key="edit">
                                                    <DeleteButton onDelete={() => handleDelete(row.attendance.id)}
                                                                  iconOnly={true}
                                                    />
                                                    <EditButton iconOnly={true} link={`/projects/attendance/${row.attendance.id}/edit`}/>
                                                </td>
                                                <td className="py-1 px-1 border-b border-gray-200" key="date">
                                                    {formatDate(row.attendance.date)}</td>
                                                <td className="py-1 px-1 border-b border-gray-200" key="cancelled">
                                                    {row.attendance.cancelled ? "Ja" : "Nein"}
                                                </td>
                                                {row.student_lines.map((line, lineIndex) => (
                                                    <td className="py-1 px-1 border-b border-gray-200" key={lineIndex}>
                                                        {line.attended}
                                                    </td>
                                                ))}
                                            </tr>

                                        ))}

                                        </tbody>

                                    </table>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </SimplePage>
        </>
    )
}

