import {Link} from "react-router-dom";
import env from "react-dotenv";
import {formatDate} from "../../../utils/formatters";
import {React} from "react";
import {useUserInfo} from "../../../contexts/UserInfoContext";

export default function NewStudentDetail ({student, isCompact, downloading}) {

    const {renderUserImage} = useUserInfo();

    return (
        <div className="newStudentDetail page-break bg-white shadow sm:rounded-lg mb-4">

            <div className={`grid grid-cols-1 gap-x-4 gap-y-${isCompact ? 4 : 8} sm:grid-cols-4 px-4 py-5 sm:px-6`}>

                <div className={`grid grid-cols-1 gap-x-4 gap-y-${isCompact ? 2 : 4} sm:grid-cols-1 sm:col-span-1`}>
                    {renderUserImage(student.user_id, 150)}
                </div>

                <dl className={`grid grid-cols-1 gap-x-4 gap-y-${isCompact ? 4 : 8} grid-cols-2 col-span-3`}>

                    <div className="col-span-2">
                        <dt className="text-md font-medium text-gray-500">
                            <Link className="studentName" to={`/school/students/${student.id}`}>
                                {student.full_name}</Link>
                        </dt>
                        <dd className="mt-1 text-sm text-gray-900">{student.student_number}</dd>
                    </div>

                    <div className="sm:col-span-1">
                        <dt className="text-sm font-medium text-gray-500">Klasse</dt>
                        <dd className="mt-1 text-sm text-gray-900">
                            <Link className="font-medium text-imsblue-600"
                                  to={`/school/school-classes/${student.school_class?.id}`}>{student.school_class?.group}</Link>
                        </dd>
                    </div>
                    <div className="sm:col-span-1">
                        <dt className="text-sm font-medium text-gray-500">KlassenlehrerIn</dt>
                        <dd className="mt-1 text-sm text-gray-900">
                            <Link className="font-medium text-imsblue-600"
                                  to={`/staff/${student.school_class?.class_teacher?.id}`}>{student.school_class?.class_teacher?.full_name}</Link>
                        </dd>
                    </div>

                    <div className="sm:col-span-1">
                        <dt className="text-sm font-medium text-gray-500">Intern</dt>
                        <dd className="mt-1 text-sm text-gray-900">{student.boarding_school ? "Ja" : "Nein"}
                        </dd>
                    </div>
                    <div className="sm:col-span-1">
                        {env.SCHOOL === 'KPI' && <>
                            <dt className="text-sm font-medium text-gray-500">Schulart</dt>
                            <dd className="mt-1 text-sm text-gray-900">{student.school_type}</dd>
                        </>}
                    </div>

                    <div className="sm:col-span-1">
                        <dt className="text-sm font-medium text-gray-500">Mentorat</dt>
                        <dd className="mt-1 text-sm text-gray-900">
                            <Link className="font-medium text-imsblue-600"
                                  to={`/boarding-school/houses/${student.house_id}`}>{student.house}</Link>
                        </dd>
                    </div>

                    <div className="sm:col-span-1">
                        <dt className="text-sm font-medium text-gray-500">Geburtsdatum</dt>
                        <dd className="mt-1 text-sm text-gray-900">{formatDate(student.birth_date)}
                        </dd>
                    </div>
                    <div className="sm:col-span-1">
                        <dt className="text-sm font-medium text-gray-500">Wohnort</dt>
                        <dd className="mt-1 text-sm text-gray-900">{student.get_wohnort}
                        </dd>
                    </div>


                    <div className="sm:col-span-1">
                        <dt className="text-sm font-medium text-gray-500">Krankheiten/Medikamente</dt>
                        <dd className="mt-1 text-sm text-gray-900">
                            {student.medications} <br/>
                            {student.illness}
                        </dd>
                    </div>

                    <div className="sm:col-span-1">
                        <dt className="text-sm font-medium text-gray-500">Letzte Schule</dt>
                        <dd className="mt-1 text-sm text-gray-900">{student.previous_school}</dd>
                    </div>
                    <div className="sm:col-span-1">
                        <dt className="text-sm font-medium text-gray-500">Wiederholte Klassen
                        </dt>
                        <dd className="mt-1 text-sm text-gray-900">{student.repeated_grades}</dd>
                    </div>
                    <div className="sm:col-span-1">
                        <dt className="text-sm font-medium text-gray-500">Übersprungen</dt>
                        <dd className="mt-1 text-sm text-gray-900">{student.skipped_grades}</dd>
                    </div>


                    <div className="sm:col-span-1">
                        <dt className="text-sm font-medium text-gray-500">LRS/ADS</dt>
                        <dd className="mt-1 text-sm text-gray-900">{student.lrs} / {student.ads}</dd>
                    </div>


                    <div className="sm:col-span-1">
                        <dt className="text-sm font-medium text-gray-500">Fremdsprachen</dt>
                        <dd className="mt-1 text-sm text-gray-900">
                            1. {student.foreign_language_1} <br/>
                            {student.foreign_language_2 && <>2. {student.foreign_language_2}
                                <br/></>}
                            {student.foreign_language_3 && <>3. {student.foreign_language_3}
                                <br/></>}
                        </dd>
                    </div>

                    <div className="sm:col-span-1">
                        <dt className="text-sm font-medium text-gray-500">Notenschnitt</dt>
                        <dd className="mt-1 text-sm text-gray-900">{student.grade_point_average}</dd>
                    </div>
                    <div className="sm:col-span-1">
                        <dt className="text-sm font-medium text-gray-500">Vorstellungsgespräch
                            bei
                        </dt>
                        <dd className="mt-1 text-sm text-gray-900">{student.interview}</dd>
                    </div>
                    <div className="sm:col-span-1">
                        <dt className="text-sm font-medium text-gray-500">Hochbegabt</dt>
                        <dd className="mt-1 text-sm text-gray-900">{student.gifted}</dd>
                    </div>

                    <div className="col-span-2 sm:col-span-1">
                        <dt className="text-sm font-medium text-gray-500">Gutachten</dt>
                        <dd className="mt-1 text-sm text-gray-900 whitespace-pre-wrap">{student.assessment}</dd>
                    </div>

                    <div className="col-span-2 sm:col-span-1">
                        <dt className="text-sm font-medium text-gray-500">Nachteilsausgleich</dt>
                        <dd className="mt-1 text-sm text-gray-900 whitespace-pre-wrap">{student.disadvantage_compensation}</dd>
                    </div>
                    <div className="col-span-2 sm:col-span-1">
                        <dt className="text-sm font-medium text-gray-500">Schulische
                            Besonderheiten:
                        </dt>
                        <dd className="mt-1 text-sm text-gray-900 whitespace-pre-wrap">{student.school_particularities}</dd>
                    </div>
                    <div className="col-span-2 sm:col-span-1">
                        <dt className="text-sm font-medium text-gray-500">Bemerkungen</dt>
                        <dd className="mt-1 text-sm text-gray-900 whitespace-pre-wrap">{student.notes}</dd>
                    </div>

                </dl>

            </div>

        </div>
    )
}