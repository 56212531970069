import {SimplePage} from "../../elements/Pages";
import React, {useEffect, useState} from "react";
import {useQuery} from "@tanstack/react-query";
import {useNavigate, useParams} from "react-router-dom";
import {getCourse, getCourseInfo} from "../../../api/courses";
import {getAndGenerateAssessments} from "../../../api/assessments";
import AssessmentEdit from "./AssessmentEdit";
import {SecondaryButton} from "../../elements/Buttons";


export default function CourseAssessmentsEdit() {

    const {assessmentPeriodId, courseId, index} = useParams()
    const navigate = useNavigate()

    const [aIndex, setAIndex] = useState(+index || 0)

    const {isLoading, isError, isSuccess, data, error, refetch} = useQuery(
        ["assessments_mini", assessmentPeriodId, courseId],
        () => getAndGenerateAssessments(assessmentPeriodId, courseId, 0, 1),
        {refetchInterval: 1000 * 60 * 60}
    )

    function next() {
        if (data && aIndex < data.assessments.length-1) {
            setAIndex(aIndex + 1)
        } else {
            setAIndex(-1)
        }
    }

    useEffect(() => {
        aIndex === -1
            ? navigate(`/assessments/assessment-periods/${assessmentPeriodId}/courses/${courseId}/`, { replace: true })
            : +index !== aIndex
            && navigate(`/assessments/assessment-periods/${assessmentPeriodId}/courses/${courseId}/edit/${aIndex}`, { replace: true });
    }, [aIndex])

    if (!data || !data.course) return <></>

    return (
        <SimplePage>
            <div className="bg-gray-100 py-4 px-4 sm:px-6 lg:px-8">
                <div className="sm:flex sm:items-center">
                    <div className="sm:flex-auto">
                        <h3 className="text-lg leading-6 font-medium text-gray-900">
                            Beurteilungen eingeben für {data.course.course_number} {data.course.school_subject} {` (${data.course.school_year_name})`}
                        </h3>
                    </div>
                    <div>
                        <SecondaryButton
                            link={`/assessments/assessment-periods/${assessmentPeriodId}/courses/${courseId}`}
                            label="Zurück zum Kurs"
                        />
                    </div>
                </div>
                <div className="pt-0 mt-2 ">
                    <div className="flex flex-col ">
                        <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
                            {data && aIndex > -1 && <div className="py-2 align-middle md:px-6 lg:px-8 max-w-6xl">
                                <AssessmentEdit inCourse={true} assessmentId={data.assessments[aIndex].id} index={aIndex} onNext={next} />
                            </div>}
                        </div>
                    </div>
                </div>
            </div>

        </SimplePage>
    );
}
