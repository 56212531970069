import env from "react-dotenv";

export default function LogoPDF() {

    return (
        <div className="flex justify-center">
            {env.SCHOOL === 'KPI' && <img
                className="h-8 h-auto mb-2"
                width="300"
                src="/img/kurpfalz_logo_pdf.png"
                alt="Kurpfalz-Internat"
            />}
            {env.SCHOOL === 'ST' && <img
                className="h-8 h-auto mb-2"
                width="300"
                src="/img/schloss_logo_pdf.svg"
                alt="Schloss Torgelow"
            />}
        </div>
    )
}
