import React from 'react';
import {Route, Routes} from "react-router-dom";
import AlumniList from "./AlumniList";
import Ambassadors from "./Ambassadors";
import AlumnusDetail from "./AlumnusDetail";
import AlumnusEdit from "./AlumnusEdit";
import MyProfile from "./MyProfile";
import MyChildren from "./MyChildren";


function AlumniRouter() {

    return (
        <Routes>
            <Route path="/" element={<AlumniList/>}/>
            <Route path="/:id" element={<AlumnusDetail/>}/>
            <Route path="/:id/edit" element={<AlumnusEdit/>}/>
            <Route path="/ambassadors" element={<Ambassadors/>}/>
            <Route path="/my" element={<MyProfile/>}/>
            <Route path="/my-children" element={<MyChildren/>}/>
        </Routes>
    )
}


export default function Alumni() {
    return <AlumniRouter/>
}
