import {SimplePage} from "../../elements/Pages";
import React, {useState} from "react";
import {DownloadButton} from "../../elements/Buttons";
import {MultiSelect} from "../../elements/forms/Inputs";
import {useQuery} from "@tanstack/react-query";
import {getGrades, getSchoolClasses} from "../../../api/school";
import {getHouseList} from "../../../api/boarding_school";
import {assessmentPeriodApi, getConductGradeListDataExcel} from "../../../api/assessments";

export default function ConductGradeLists() {

    const [errors, setErrors] = useState([])
    const [data, setData] = useState({
        assessment_periods: [],
        grades: [],
        school_classes: [],
        houses: []
    })

    const {data: assessmentPeriods} = useQuery(["assessment_periods"], () => assessmentPeriodApi('get'), {refetchInterval: 1000 * 60 * 60 * 24});
    const {data: schoolClasses} = useQuery(["school_classes"], getSchoolClasses, {refetchInterval: 1000 * 60 * 60 * 24});
    const {data: houses} = useQuery(["house_list"], getHouseList, {refetchInterval: 1000 * 60 * 60 * 24});
    const {data: grades} = useQuery(["grades"], getGrades, {refetchInterval: 1000 * 60 * 60 * 24});

    function handleMultiSelect(selectedOptions, path) {
        let newData = {...data}
        newData[path] = selectedOptions
        setData(newData)
    }

    return (
        <SimplePage>
            <div className="px-4 sm:px-6 lg:px-8">
                <div className="sm:flex sm:items-center">
                    <div className="sm:flex-auto">
                        <h3 className="text-lg leading-6 font-medium text-gray-900">
                            Kopfnotenlisten herunterladen
                        </h3>
                    </div>
                    <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
                    </div>
                </div>
                <div className="flex flex-col">
                    <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">

                        <div className="inline-block py-2 align-middle md:px-6 lg:px-8">

                            <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-3">

                                <MultiSelect
                                    path="assessment_periods"
                                    label="Zeitraum"
                                    className="sm:col-span-6"
                                    options={assessmentPeriods}
                                    valueAttr="id"
                                    labelAttr="name"
                                    onChange={(selectedOptions) => handleMultiSelect(selectedOptions, 'assessment_periods')}
                                    errors={errors}
                                    data={data}
                                    isClearable={true}
                                    valueOnly={true}
                                />

                                <MultiSelect
                                    path="grades"
                                    label="Klassenstufe"
                                    className="sm:col-span-6"
                                    options={grades}
                                    valueAttr="id"
                                    labelAttr="name"
                                    onChange={(selectedOptions) => handleMultiSelect(selectedOptions, 'grades')}
                                    errors={errors}
                                    data={data}
                                    isClearable={true}
                                    valueOnly={true}
                                />

                                <MultiSelect
                                    path="school_classes"
                                    label="Klasse"
                                    className="sm:col-span-6"
                                    options={schoolClasses}
                                    valueAttr="id"
                                    labelAttr="group"
                                    onChange={(selectedOptions) => handleMultiSelect(selectedOptions, 'school_classes')}
                                    errors={errors}
                                    data={data}
                                    isClearable={true}
                                    valueOnly={true}
                                />

                                <MultiSelect
                                    path="houses"
                                    label="Mentorat"
                                    className="sm:col-span-6"
                                    options={houses}
                                    valueAttr="id"
                                    labelAttr="name"
                                    onChange={(selectedOptions) => handleMultiSelect(selectedOptions, 'houses')}
                                    errors={errors}
                                    data={data}
                                    isClearable={true}
                                    valueOnly={true}
                                />

                                {/*<PrimaryButton label="Abrufen"*/}
                                {/*               onClick={handleFilter}></PrimaryButton>*/}

                                <DownloadButton
                                    apiFunction={() => getConductGradeListDataExcel(data)}
                                    fileName={`Kopfnotenlisten.xls`}
                                    label="Excel herunterladen"/>

                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </SimplePage>
    );
}

