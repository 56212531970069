import { SimplePage } from "../../elements/Pages";
import React from "react";

import { watchlistApi } from "../../../api/logbook";

import { useQuery } from "@tanstack/react-query";
import { useAuth } from "../../../contexts/AuthContext";
import ListViewTable, { TableLink } from "../../elements/tables/ListViewTable";
import checkPermission from "../../../utils/permissions";
import moment from "moment";
import { Link } from "react-router-dom";

const columns = [
  {
    accessorFn: (row) =>
      `${row.student.last_name}, ${row.student.first_name} ${row.student.student_number}`,
    id: "student_search",
    header: "SchülerIn",
    hide: true,
    enableColumnFilter: false,
  },
  {
    accessorKey: "student",
    id: "student",
    header: "SchülerIn",
    columnType: "student",
    enableColumnFilter: false,
    sortingFn: "lastNameSorting",
  },
  {
    accessorKey: "student.house",
    id: "house",
    header: "Mentorat",
    enableColumnFilter: true,
    headerClassNames: "narrow-filter",
  },
  {
    accessorKey: "student.school_class",
    id: "school_class",
    header: "Klasse",
    headerClassNames: "narrow-filter",
  },
  {
    accessorKey: "epoch.name",
    id: "epoch",
    header: "Epoche",
    headerClassNames: "narrow-filter",
  },
  {
    accessorKey: "notes",
    id: "notes",
    header: "Anmerkungen",
    cell: (info) => info.getValue(),
    enableColumnFilter: false,
    classNames: "max-w-md text-gray-500",
    headerClassNames: "max-w-md",
  },
  {
    accessorKey: "internvention_protocol_filled_in",
    id: "internvention_protocol_filled_in",
    header: "Intervention",
    headerClassNames: "narrow-filter",
    columnType: "yesno",
    filterFn: "boolean",
  },
  {
    accessorKey: "creation_date",
    id: "creation_date",
    header: "Erstellt am",
    columnType: "date",
    enableColumnFilter: false,
    classNames: "text-gray-500",
  },
  {
    accessorKey: "id",
    id: "link",
    cell: (info) => (
      <TableLink
        label="Bearbeiten"
        to={`/logbook/watchlist/${info.getValue()}/edit`}
      />
    ),
    header: "Bearbeiten",
    enableColumnFilter: false,
    disableRowLink: true
  },
];

export default function Watchlist() {
  const { permissions } = useAuth();
  const { isLoading, isError, isSuccess, data, error, refetch } = useQuery(
    ["watchlist"],
    () => watchlistApi("get"),
      {refetchInterval: 1000 * 60 * 60 * 24}
  );

  function getRowLink(row) {
    return `/logbook/watchlist/${row.original.id}/`;
  }

  return (
    <SimplePage>
      {/*  Simple striped table */}
      <div className="px-4 sm:px-6 lg:px-8">
        <div className="sm:flex sm:items-center">
          <div className="sm:flex-auto">
            <h3 className="text-lg leading-6 font-medium text-gray-900">
              Watchlist
            </h3>
          </div>
          <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
            {checkPermission(
              permissions,
              "student_logbook.add_watchlistentry"
            ) && (
              <Link to="/logbook/watchlist/new">
                <button
                  type="button"
                  className="inline-flex items-center justify-center rounded-md border border-transparent bg-imsblue-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-imsblue-700 focus:outline-none focus:ring-2 focus:ring-imsblue-500 focus:ring-offset-2 sm:w-auto"
                >
                  Neuer Eintrag
                </button>
              </Link>
            )}
          </div>
        </div>
        <div className="flex flex-col">
          <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
              {isSuccess && data && (
                <ListViewTable
                  data={data}
                  columns={columns}
                  isLoading={isLoading}
                  disableGlobalSearch={false}
                  getRowLink={getRowLink}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </SimplePage>
  );
}
