import {ClipboardIcon} from "@heroicons/react/20/solid";
import React from "react";
import {formatDate} from "../../../utils/formatters";
import {copyToClipboard} from "../../../utils/helpers";
import {EditButton} from "../../elements/Buttons";
import {useExams} from "../../../contexts/ExamsContext";
import {Link, useParams} from "react-router-dom";
import {useQuery} from "@tanstack/react-query";
import {examApi} from "../../../api/school";
import {DetailPage} from "../../elements/Pages";
import checkPermission from "../../../utils/permissions";
import {useAuth} from "../../../contexts/AuthContext";


function ExamDetailContent({exam, page = false}) {

    const {currentUser, permissions} = useAuth()
    const canEdit = checkPermission(permissions, "school.change_exam") || exam.created_by === currentUser.id

    return (
        <>{!page &&
            <div className="px-4 py-5 sm:px-6 flex justify-between">
                <h3 className="text-lg font-medium leading-6 text-gray-900">
                    {exam.category}: {exam.topic}
                </h3>
                {canEdit && <EditButton iconOnly={true} link={`/class-register/exams/${exam.id}/edit`}/>}
            </div>}
            <div className="border-t border-gray-200 px-4 py-5 sm:p-0">
                <dl className="sm:divide-y sm:divide-gray-200">

                    <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6">
                        <dt className="text-sm font-medium text-gray-500">Datum</dt>
                        <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                            {formatDate(exam.date)}
                        </dd>
                    </div>
                    <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6">
                        <dt className="text-sm font-medium text-gray-500">Zeit</dt>
                        <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                            {exam.time?.map(dp => dp.name).join(", ")}
                        </dd>
                    </div>
                    <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6">
                        <dt className="text-sm font-medium text-gray-500">Kurse</dt>
                        <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                            {exam.courses.map((course, index) => (
                                <Link className="font-medium text-imsblue-600" key={index}
                                      to={`/courses/${course.id}`}>{index > 0 ? ", " : ""}
                                    {course.course_number}
                                </Link>
                            ))}
                        </dd>
                    </div>

                    <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6">
                        <dt className="text-sm font-medium text-gray-500">Beschreibung</dt>
                        <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0 whitespace-pre-wrap">
                            {exam.description}
                        </dd>
                    </div>

                    <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6">
                        <dt className="text-sm font-medium text-gray-500">Erstellt von</dt>
                        <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                            {exam.created_by_full_name}
                        </dd>
                    </div>

                    <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6">
                        <dt className="text-sm font-medium text-gray-500">Raumbuchung</dt>
                        <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                            {exam.room_bookings.map((roomBooking, index) => (
                                <span key={index}>{index > 0 ? <br/> : ""}
                                    {roomBooking.room.name}
                                </span>
                            ))}
                        </dd>
                    </div>

                    <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6">
                        <dt className="text-sm font-medium text-gray-500">Link</dt>
                        <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                            <ul
                                role="list"
                                className="divide-y divide-gray-200 rounded-md border border-gray-200"
                            >
                                <li className="flex items-center justify-between py-3 pl-3 pr-4 text-sm">
                                    <div className="flex w-0 flex-1 items-center">
                                        <span className="ml-2 w-0 flex-1 truncate">
                                          {exam.topic}
                                        </span>
                                    </div>
                                    <div
                                        className="ml-4 flex w-0 flex-1 items-center cursor-pointer"
                                        onClick={() =>
                                            copyToClipboard(window.location.href, "Link kopiert")
                                        }
                                    >
                                        <ClipboardIcon
                                            className="h-5 w-5 flex-shrink-0 text-gray-400"
                                            aria-hidden="true"
                                        />
                                        <p className="font-medium text-imsblue-600 hover:text-imsblue-500">
                                            Kopieren
                                        </p>
                                    </div>
                                </li>
                            </ul>
                        </dd>
                    </div>
                </dl>
            </div>
        </>
    );
}


const ExamDetail = () => {
    const {selectedEvent} = useExams();
    return <ExamDetailContent exam={selectedEvent}/>
};

export default ExamDetail;


export function ExamDetailPage() {
    const {examId} = useParams()
    const {currentUser, permissions} = useAuth()

    const {data: exam} = useQuery(
        ["exam_detail", examId],
        () => examApi('get', examId)
    )
    if (!exam) return <></>

    const canEdit = checkPermission(permissions, "school.change_exam") || exam.created_by === currentUser.id

    return (

        <DetailPage>

            <div
                className="max-w-3xl mx-auto px-4 sm:px-6 md:flex md:items-center md:justify-between md:space-x-5 lg:max-w-7xl lg:px-8">
                <div className="flex items-center space-x-5">
                    <div>
                        <h1 className="text-2xl font-bold text-gray-900">{exam.category}: {exam.topic}</h1>
                    </div>
                </div>
                {canEdit && <div
                    className="mt-6 flex flex-col-reverse justify-stretch
                    space-y-4 space-y-reverse sm:flex-row-reverse sm:justify-end sm:space-x-reverse sm:space-y-0 sm:space-x-3 md:mt-0 md:flex-row md:space-x-3">
                    <EditButton iconOnly={false} label={"Bearbeiten"} link={`/class-register/exams/${exam.id}/edit`}/>
                </div>}
            </div>

            <div
                className="mt-8 max-w-3xl mx-auto grid grid-cols-1 gap-6 sm:px-6 lg:max-w-7xl lg:grid-flow-col-dense lg:grid-cols-3">
                <div className="space-y-6 lg:col-start-1 lg:col-span-2 bg-white shadow overflow-hidden sm:rounded-lg">
                    <ExamDetailContent exam={exam} page={true}/>
                </div>
            </div>
        </DetailPage>

    )
}