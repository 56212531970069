import {SimplePage} from "../../elements/Pages";
import React, {useState} from "react";

import {useQuery} from "@tanstack/react-query";
import {useAuth} from "../../../contexts/AuthContext";
import ListViewTable from "../../elements/tables/ListViewTable";
import {classNames} from "../../../utils/misc";
import {PrimaryButton} from "../../elements/Buttons";
import {getApplicants} from "../../../api/applicants";

const columns = [
    {
        accessorFn: (row) => `${row.user.last_name}, ${row.user.first_name}`,
        id: "student_search",
        header: "SchülerIn",
        enableColumnFilter: false,
    },
    {
        accessorFn: (row) => `${row.applying_for_school_year?.name || ""}`,
        id: "applying_for_school_year",
        header: "Schuljahr",
        enableColumnFilter: true,
    },
    {
        accessorFn: (row) => `${row.applying_for_grade?.name || ""}`,
        id: "applying_for_grade",
        header: "Klassenstufe",
        enableColumnFilter: true,
    },
    {
        accessorKey: "interview_date",
        id: "date",
        header: "Gespräch",
        columnType: "dateWithWeekday",
        enableColumnFilter: false,
        // classNames: 'font-medium text-gray-900'
        classNames: "text-gray-500",
    },
    {
        accessorKey: "contract",
        id: "contract",
        header: "Vertrag",
        columnType: "yesno",
        enableColumnFilter: true,
        headerClassNames: "narrow-filter",
    }
];

export default function ApplicantList() {

    const { permissions } = useAuth();
    const [openOrDeclined, setOpenOrDeclined] = useState("open");

    const { isLoading, isError, isSuccess, data, error, refetch } = useQuery(
        ["applicants", openOrDeclined],
        () => getApplicants(openOrDeclined),
        {refetchInterval: 1000 * 60 * 10}
    );

    function getRowLink(row) {
        return `/applicants/${row.original.id}/`;
    }

    return (
        <SimplePage title="Vertretungen, Raumwechsel & Ausfälle">

            <div className="px-4 sm:px-6 lg:px-8">
                <div className="sm:flex sm:items-center">
                    <div className="sm:flex-auto">
                        <h3 className="text-lg leading-6 font-medium text-gray-900">
                            Bewerber
                        </h3>
                    </div>
                    <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
                        <PrimaryButton label="Bewerber erfassen" link={"/applicants/new"} />
                    </div>
                </div>
                <div className="flex flex-col">
                    <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
                    <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                            <nav className="flex space-x-4 mt-2" aria-label="Tabs">
                                <a
                                    key="open"
                                    onClick={() => setOpenOrDeclined("open")}
                                    className={classNames(
                                        openOrDeclined === "open"
                                            ? "bg-imsblue-100 text-imsblue-700"
                                            : "text-gray-500 hover:text-gray-700",
                                        "px-3 py-2 font-medium text-sm rounded-md cursor-pointer"
                                    )}
                                    aria-current={openOrDeclined === "open" ? "page" : undefined}
                                >
                                    Offen
                                </a>
                                <a
                                    key="today"
                                    onClick={() => setOpenOrDeclined("declined")}
                                    className={classNames(
                                        openOrDeclined === "declined"
                                            ? "bg-imsblue-100 text-imsblue-700"
                                            : "text-gray-500 hover:text-gray-700",
                                        "px-3 py-2 font-medium text-sm rounded-md cursor-pointer"
                                    )}
                                    aria-current={openOrDeclined === "declined" ? "page" : undefined}
                                >
                                    Abgesagt
                                </a>
                            </nav>

                            <div className="flex flex-col">
                                {isSuccess && data && (
                                    <ListViewTable
                                        data={data}
                                        columns={columns}
                                        isLoading={isLoading}
                                        disableGlobalSearch={false}
                                        getRowLink={getRowLink}
                                    />
                                )}
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </SimplePage>
    )
}
