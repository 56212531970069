import {React} from "react";
import {Link, useParams} from "react-router-dom";
import {getOrderDetail} from "../../../api/shop";
import {useQuery} from "@tanstack/react-query";

export default function OrderDetail() {

    const {id} = useParams();

    const {data: order} = useQuery(
        ["orders", id],
        () =>  getOrderDetail(id)
    )

    if (!order) return <></>;

    return (
        <div className="bg-white">
            <div className="mx-auto max-w-4xl py-10 px-4 sm:px-6 lg:px-8">
                <h1 className="text-3xl font-bold tracking-tight text-gray-900">Bestellung #{order.id}</h1>
                <div className="mt-6 divide-y divide-gray-200 border-t border-b border-gray-200">
                    {order.order_lines.map(item => <OrderInfo key={item.id}  orderItem={item}/>)}
                </div>
            </div>
            <div className="my-10 mx-20 rounded-lg bg-gray-50 px-4 py-6 sm:p-6 lg:p-8">
                <h2 className="sr-only">Order summary</h2>
                <div className="flow-root">
                    <dl className="-my-4 divide-y divide-gray-200 text-sm">
                        <div className="flex items-center justify-between py-4">
                            <dt className="text-base font-medium text-gray-900">Bestellt von</dt>
                            <dd className="text-base font-medium text-gray-900">{order.created_by.full_name}</dd>
                        </div>
                        <div className="flex items-center justify-between py-4">
                            <dt className="text-base font-medium text-gray-900">Email</dt>
                            <dd className="text-base font-medium text-gray-900">{order.created_by.email}</dd>
                        </div>
                        <div className="flex items-center justify-between py-4">
                            <dt className="text-base font-medium text-gray-900">Gesamt</dt>
                        <dd className="text-base font-medium text-gray-900">{order.total_price} €</dd>
                        </div>
                    </dl>
                </div>
            </div>
        </div>
    )
}
function OrderInfo({orderItem}) {

    return (
        <div className="flex py-6 sm:py-10">
            <div className="flex-shrink-0">
                <img
                    src={orderItem.product_size.product.images.thumbnail[0]}
                    className="h-24 w-24 rounded-lg object-cover object-center sm:h-32 sm:w-32"
                    alt="product"
                />
            </div>
            <div className="relative ml-4 flex flex-1 flex-col justify-between sm:ml-6">
                <div className="flex justify-between sm:grid sm:grid-cols-2">
                    <div className="pr-6">
                        <h3 className="text-sm font-medium text-gray-700 hover:text-gray-800">
                            <Link to={`/shop/product/${orderItem.product_size.product.id}`}
                                  className="font-medium text-gray-700 hover:text-gray-800">
                                {orderItem.product_size.product.title}
                            </Link>
                        </h3>
                        {orderItem.product_size.size &&
                            <p className="mt-1 text-sm text-gray-500">{orderItem.product_size.size }</p>}
                    </div>
                    <p className="text-right text-sm font-medium text-gray-900">{orderItem.price} €</p>
                </div>
            </div>
        </div>
    )
}