import {SimplePage} from "../../elements/Pages";
import React, {useEffect, useState} from "react";

import {useQuery} from "@tanstack/react-query";
import ListViewTable, {TableLink, TwoLineColumn, TwoLineColumnWithLink} from "../../elements/tables/ListViewTable";
import {useParams} from "react-router-dom";
import {
    checkHomework,
    getClassRegisterDataForStudent,
    getHomeworkDataForStudent,
    markHomeworkDone
} from "../../../api/class_register";
import {EditButton, PrimaryButton} from "../../elements/Buttons";
import {classNames} from "../../../utils/misc";
import {getStudentInfo} from "../../../api/school";
import {toast} from "react-toastify";


export function TableCheckbox({
                                  value,
                                  ...rest
                              }) {

    return (
        <div className="">
            <fieldset className="">
                <div className="mt-4 space-y-4">
                    <div className="flex items-center">
                        <input
                            checked={value === true}
                            type="checkbox"
                            className="focus:ring-imsblue-500 h-4 w-4 text-imsblue-600 border-gray-300"
                            {...rest}
                        />
                    </div>
                </div>
            </fieldset>
        </div>
    );
}

// const examColumns = [
//     {
//         accessorKey: "date",
//         id: "date",
//         header: "Datum",
//         columnType: "date",
//         enableColumnFilter: false,
//     },
//     {
//         accessorKey: "time",
//         id: "time",
//         header: "Zeit",
//         columnType: "m2m",
//         labelAttr: "name",
//         enableColumnFilter: false
//     },
//     {
//         accessorKey: "room_bookings",
//         id: "room_bookings",
//         header: "Raum",
//         enableColumnFilter: false,
//         cell: ({row, value}) => (
//             <>
//                 {row.original.room_bookings.map((r, index) => (
//                     <span key={index}>
//                         {index > 0 ? ", " : ""}
//                         {r.room.name}
//                     </span>
//                 ))}
//             </>
//         ),
//     },
//     {
//         accessorKey: "category",
//         id: "category",
//         header: "Art",
//         enableColumnFilter: false,
//     },
//     {
//         accessorKey: "topic",
//         id: "topic",
//         header: "Thema",
//         cell: ({row, value}) => (
//             <div className="whitespace-pre-wrap">
//                 <TwoLineColumn
//                     line1={row.original.topic}
//                     line2={row.original.description ? `${row.original.description}` : ''}
//                 /></div>),
//         enableColumnFilter: false,
//         headerClassNames: "medium-filter",
//         classNames: "max-w-md",
//     },
//     {
//         accessorKey: "teachers", // todo
//         id: "teachers",
//         header: "Lehrer",
//         columnType: "m2mLinked",
//         getItemLink: (item) => {
//             return `/staff/${item.id}/`
//         },
//         labelAttr: "full_name",
//         enableColumnFilter: false,
//         filterFn: "containsString",
//     }
// ]

const courseMaterialColumns = [
    {
        accessorKey: "get_file_name",
        id: "get_file_name",
        header: "Datei",
        cell: ({row, value}) => (

            row.original.course_material_files ?
                row.original.course_material_files.map((f, index) => {
                    return <TwoLineColumnWithLink key={index}
                                                  link={`${f.file}`}
                                                  line1={`${row.original.title}`}
                                                  line2={`${f.get_file_name}`}
                    />
                }) : ""
        ),
        enableColumnFilter: false,
        classNames: "max-w-xs text-gray-500",
    },
    {
        accessorKey: "creation_date",
        id: "creation_date",
        header: "Datum",
        columnType: "date",
        enableColumnFilter: false,
        classNames: "text-gray-500",
    },
    {
        accessorKey: "created_by_full_name",
        id: "created_by_full_name",
        header: "Erstellt von",
        enableColumnFilter: false,
    },
    {
        accessorKey: "description",
        id: "description",
        header: "Beschreibung",
        enableColumnFilter: false,
        classNames: "max-w-md",
    },

];


export function StudentHomeworkDetail({studentId, previousOrCurrent, date}) {

    const [data, setData] = useState()

    useEffect(() => {
        loadData()
    }, [previousOrCurrent, studentId])

    async function loadData() {
        const newData = await getHomeworkDataForStudent(studentId, previousOrCurrent, date)
        setData(newData)
    }

    function getRowLink(row) {
        return `/class-register/homework/${row.original.homework.id}/`;
    }

    const homeworkColumns = [
        {
            accessorKey: "homework.due_date",
            id: "due_date",
            header: "Zum",
            columnType: "dateWithWeekday",
            enableColumnFilter: false,
            classNames: "text-gray-500",
        },
        {
            accessorKey: "homework.type",
            id: "type",
            header: "Art",
            enableColumnFilter: false
        },
        {
            accessorKey: "course.school_subject",
            id: "school_subject",
            header: "Fach",
            enableColumnFilter: false
        },
        {
            accessorKey: "course",
            id: "course",
            header: "Kurs",
            cell: ({row, value}) => (
                <TableLink label={`${row.original.course.course_number}`} to={`/courses/${row.original.course.id}`}/>
            ),
            disableRowLink: true,
            enableColumnFilter: false
        },
        {
            accessorKey: "course.teacher.full_name",
            id: "teacher",
            header: "LehrerIn",
            enableColumnFilter: false,
        },
        {
            accessorKey: "homework.title",
            id: "title",
            header: "Aufgabe",
            cell: ({row, value}) => (
                <div className="whitespace-pre-wrap">
                    <TwoLineColumn
                        line1={row.original.homework.title}
                        line2={row.original.homework.task}
                    /></div>
            ),
            enableColumnFilter: false
        }
    ]

    if (data && data.can_mark_homework_done) {
        homeworkColumns.push({
            accessorKey: "done",
            id: "done",
            cell: ({row, value}) => (
                <TableCheckbox
                    key={row.original.id}
                    value={row.original.done}
                    defaultChecked={row.original.done}
                    onClick={() => handleDoneHomework(row.original.id)}
                />
            ),
            header: "Erledigt",
            disableRowLink: true,
            enableColumnFilter: false
        })
    }

    if (data && data.can_check_homework) {
        homeworkColumns.push({
            accessorKey: "checked",
            id: "checked",
            cell: ({row, value}) => (
                <>
                   <span className="hidden">{row.original.id}</span>
                    <TableCheckbox
                        key={row.original.id}
                        value={row.original.checked}
                        onChange={() => handleCheckHomework(row.original.id)}
                    />
                </>
            ),
            header: "Überprüft",
            disableRowLink: true,
            enableColumnFilter: false
        })
    } else {
        homeworkColumns.push({
            accessorKey: "checked",
            id: "checked",
            columnType: "yesno",
            header: "Überprüft",
            enableColumnFilter: false
        })
        homeworkColumns.push({
            accessorKey: "checked_by_full_name",
            id: "checked_by_full_name",
            header: "Überprüft von",
            enableColumnFilter: false
        })
    }

    async function handleCheckHomework(studentHomeworkId) {

        // update the state
        const newHomework = data.homework.map(row => {
            if (row.id === studentHomeworkId) {
                var value = !row.checked
                return {...row, ['checked']: value}
            }
            return row
        })
        setHomework(newHomework)

        // patch to API and reload
        try {
            await checkHomework(studentHomeworkId)
        } catch (e) {
            toast.error("Beim Speichern ist ein Fehler aufgetreten")
        }
    }

    async function handleDoneHomework(studentHomeworkId) {

        // update the state
        const newHomework = data.homework.map(row => {
            if (row.id === studentHomeworkId) {
                var value = !row.done
                return {...row, ['done']: value}
            }
            return row
        })
        setHomework(newHomework)

        try {
            await markHomeworkDone(studentHomeworkId)
        } catch (e) {
            toast.error("Beim Speichern ist ein Fehler aufgetreten")
        }
    }

    function setHomework(newHomework) {
        setData({...data, homework: newHomework})
    }

    if (!data) return <></>

    return (
        <div className="">
            {data &&
                <>
                    {data.homework.length > 0 ?
                        <div className=""><ListViewTable
                            data={data.homework}
                            columns={homeworkColumns}
                            disableGlobalSearch={true}
                            getRowLink={getRowLink}
                        /></div> : <p>Keine Hausaufgaben gefunden.</p>}
                </>}
        </div>
    )
}

export function StudentExams({data}) {

    function getRowLink(row) {
        return `/class-register/exams/${row.original.id}/`;
    }

    const examColumns = [
        {
            accessorKey: "date",
            id: "date",
            header: "Datum",
            columnType: "dateWithWeekday",
            enableColumnFilter: false,
        },
        {
            accessorKey: "time",
            id: "time",
            header: "Zeit",
            columnType: "m2m",
            labelAttr: "name",
            enableColumnFilter: false
        },
        {
            accessorKey: "category",
            id: "category",
            header: "Art",
            enableColumnFilter: false,
        },
        {
            accessorKey: "topic",
            id: "topic",
            header: "Thema",
            enableColumnFilter: false,
            headerClassNames: "medium-filter"
        },
        {
            accessorKey: "courses",
            id: "courses",
            header: "Kurs",
            columnType: "m2mLinked",
            getItemLink: (item) => {
                return `/courses/${item.id}/`
            },
            labelAttr: "course_number",
            enableColumnFilter: false
        },
        {
            accessorKey: "courses",
            id: "school_subject",
            header: "Fach",
            cell: ({row, value}) => (
                row.original.courses?.map((c) =>
                    c.school_subject).join(", ")
            ),
            enableColumnFilter: false,
        },
        {
            accessorKey: "teachers",
            id: "teachers",
            header: "Lehrer",
            columnType: "m2mLinked",
            getItemLink: (item) => {
                return `/staff/${item.id}/`
            },
            labelAttr: "full_name",
            enableColumnFilter: false
        }
    ]

    if (!data) return <></>

    return (
        <div className="">
            {data &&
                <>
                    {data.exams?.length > 0 &&
                        <div className="-my-4"><ListViewTable
                            data={data.exams}
                            columns={examColumns}
                            disableGlobalSearch={true}
                            getRowLink={getRowLink}
                        /></div>}
                </>}
        </div>
    )
}

export default function StudentDetail() {

    const {studentId} = useParams()
    const [previousOrCurrent, setPreviousOrCurrent] = useState('current')
    const [data, setData] = useState()

    const {data: student} = useQuery(["student_info", studentId], () =>
            getStudentInfo(studentId),
        {refetchInterval: 1000 * 60 * 60 * 24}
    )

    useEffect(() => {
        loadData()
    }, [previousOrCurrent, studentId])

    // perhaps different function here
    async function loadData() {
        const newData = await getClassRegisterDataForStudent(studentId, previousOrCurrent)
        setData(newData)
    }

    if (!student) return <></>
    return (
        <SimplePage>
            <div className="px-4 sm:px-6 lg:px-8">
                <div className="sm:flex sm:items-center">
                    <div className="sm:flex-auto">
                        <h3 className="text-lg leading-6 font-medium text-gray-900">
                            Hausaufgaben & Klausuren für {student.full_name}
                        </h3>
                    </div>
                    <div className="mt-4 sm:mt-0 sm:ml-16 flex gap-2">
                        <PrimaryButton link={`/school/students/${studentId}`}
                                       label="Schülerblatt"/>
                    </div>
                </div>
                <div className="flex flex-col">
                    <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">

                        <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                            <nav className="flex space-x-4" aria-label="Tabs">
                                <a
                                    key="current"
                                    onClick={() => setPreviousOrCurrent("current")}
                                    className={classNames(
                                        previousOrCurrent === "current"
                                            ? "bg-imsblue-100 text-imsblue-700"
                                            : "text-gray-500 hover:text-gray-700",
                                        "px-3 py-2 font-medium text-sm rounded-md cursor-pointer"
                                    )}
                                    aria-current={previousOrCurrent === "current" ? "page" : undefined}
                                >
                                    Aktuelle
                                </a>
                                <a
                                    key="previous"
                                    onClick={() => setPreviousOrCurrent("previous")}
                                    className={classNames(
                                        previousOrCurrent === "previous"
                                            ? "bg-imsblue-100 text-imsblue-700"
                                            : "text-gray-500 hover:text-gray-700",
                                        "px-3 py-2 font-medium text-sm rounded-md cursor-pointer"
                                    )}
                                    aria-current={previousOrCurrent === "previous" ? "page" : undefined}
                                >
                                    Vorherige
                                </a>
                            </nav>
                        </div>

                        <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                            <div className="">
                                <h2 id="notes-title" className="text-md font-medium text-gray-900">
                                    Hausaufgaben
                                </h2>
                                <StudentHomeworkDetail studentId={studentId} previousOrCurrent={previousOrCurrent}/>
                            </div>

                            {data && data.course_materials.length > 0 && <div className="mt-8">
                                <h2 id="notes-title" className="text-md font-medium text-gray-900">
                                    Aktuelle Kursmaterialien
                                </h2>
                                <div className="">
                                    <ListViewTable
                                        data={data.course_materials}
                                        columns={courseMaterialColumns}
                                        disableGlobalSearch={true}
                                        getRowLink={null}
                                    />
                                </div>
                            </div>}

                            <div className="mt-8">
                                <h2 id="notes-title" className="text-md font-medium text-gray-900">
                                    Klausuren & Tests
                                </h2>
                                <div className="my-4">
                                    <StudentExams key={1} data={data}  />
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </SimplePage>
    );
}
