import {SimplePage} from "../../elements/Pages";
import React from "react";

import {useQuery} from "@tanstack/react-query";
import {useAuth} from "../../../contexts/AuthContext";
import ListViewTable, {TableEditLink,} from "../../elements/tables/ListViewTable";
import checkPermission from "../../../utils/permissions";
import {PrimaryButton} from "../../elements/Buttons";
import {formatDateTime} from "../../../utils/formatters";
import {excursionApi} from "../../../api/school";

const columns = [
    {
        accessorKey: "title",
        id: "title",
        header: "Titel",
        enableColumnFilter: false,
        headerClassNames: "medium-filter",
    },
    {
        accessorFn: (row) => `${formatDateTime(row.start)} - ${formatDateTime(row.end)}`,
        id: "date",
        header: "Datum",
        enableColumnFilter: false,
    },
    {
        accessorKey: "excursion_type",
        id: "excursion_type",
        header: "Kategorie",
        enableColumnFilter: true,
    },
    {
        accessorKey: "created_by_full_name",
        id: "created_by_full_name",
        header: "Beantragt von",
        enableColumnFilter: true
    },
    {
        accessorKey: "staff",
        id: "staff",
        header: "Begleitet von",
        columnType: "m2m",
        labelAttr: "full_name",
        enableColumnFilter: true,
        headerClassNames: "medium-filter",
        filterFn: "containsString",
    },
    {
        accessorKey: "get_status_display",
        id: "get_status_display",
        header: "Status",
        enableColumnFilter: true,
        filterFn: "containsString",
        headerClassNames: "narrow-filter"
    },
]

export default function ExcursionList() {

    const {permissions, currentUser} = useAuth();

    const {isLoading, isError, isSuccess, data, error, refetch} = useQuery(
        ["excursions", currentUser.user_id],
        () => excursionApi('get')
    )

    const canAdd = checkPermission(permissions, "school.add_excursion")
    const canEdit = checkPermission(permissions, "school.change_excursion")

    function getRowLink(row) {
        return `/school/excursions/${row.original.id}`
    }

    let tableColumns = [...columns]
    if (canEdit) {
        tableColumns.push({
            id: "edit",
            header: "Bearbeiten",
            headerClassNames: "narrow-filter",
            cellClassNames: "narrow-filter",
            enableColumnFilter: false,
            cell: ({row, value}) => (
                <TableEditLink
                    iconOnly={true}
                    label="Bearbeiten"
                    to={`/school/excursions/${row.original.id}/edit`}
                />
            ),
        })
    }

    return (
        <SimplePage title="Schulveranstaltungen">

            <div className="px-4 sm:px-6 lg:px-8">
                <div className="sm:flex sm:items-center">
                    <div className="sm:flex-auto">
                        <h3 className="text-lg leading-6 font-medium text-gray-900">
                            Schulveranstaltungen/-ausflüge
                        </h3>
                    </div>
                    <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
                        {canAdd && <PrimaryButton
                            label="Neue Schulveranstaltung"
                            link={`/school/excursions/new`}
                        />}
                    </div>
                </div>
                <div className="flex flex-col">
                    <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
                        <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                            {isSuccess && data && data?.length > 0 && (
                                <ListViewTable
                                    data={data}
                                    columns={tableColumns}
                                    isLoading={isLoading}
                                    disableGlobalSearch={false}
                                    getRowLink={getRowLink}
                                />
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </SimplePage>
    )
}
