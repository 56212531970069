import {SimplePage} from "../../elements/Pages";
import React from 'react';

import {useQuery} from '@tanstack/react-query'
import ListViewTable from "../../elements/tables/ListViewTable";
import {getExamWarningsList} from "../../../api/school";
import {Link} from "react-router-dom";


const weekColumns = [
    {
        accessorFn: (row) =>
            `${row.last_name}, ${row.first_name}`,
        id: "student",
        header: "SchülerIn",
        enableColumnFilter: false,
    },
    {
        accessorKey: 'week',
        id: 'week',
        header: 'KW',
        enableColumnFilter: false,
        headerClassNames: "narrow-filter"
    },
    {
        accessorKey: 'total_exams',
        id: 'total_exams',
        header: 'Klausuren in dieser Woche',
        enableColumnFilter: false,
        headerClassNames: "narrow-filter"
    },
    {
        accessorKey: "courses",
        id: "courses",
        header: "Kurse",
        enableColumnFilter: false,
        headerClassNames: "narrow-filter",
    },
    {
        accessorKey: "exams",
        id: "exams",
        header: "Klausuren",
        enableColumnFilter: false,
        cell: ({row, value}) => (
            <>
                {row.original.exam_ids.map((examId, index) => (
                    <span key={index}>
                        {index > 0 && ", "}
                        <Link className="font-medium text-imsblue-600"
                              to={`/class-register/exams/${examId}`}>{examId}</Link>
                    </span>
                ))}
            </>
        )
    }
]

const dayColumns = [
    {
        accessorFn: (row) =>
            `${row.last_name}, ${row.first_name}`,
        id: "student",
        header: "SchülerIn",
        enableColumnFilter: false,
    },
    {
        accessorKey: 'exam_date',
        id: 'exam_date',
        header: 'Datum',
        columnType: "date",
        enableColumnFilter: false,
        headerClassNames: "narrow-filter"
    },
    {
        accessorKey: 'total_exams',
        id: 'total_exams',
        header: 'Klausuren an diesem Tag',
        enableColumnFilter: false,
        headerClassNames: "narrow-filter"
    },
    {
        accessorKey: "courses",
        id: "courses",
        header: "Kurse",
        enableColumnFilter: false,
        headerClassNames: "narrow-filter",
    },
    {
        accessorKey: "exams",
        id: "exams",
        header: "Klausuren",
        enableColumnFilter: false,
        cell: ({row, value}) => (
            <>
                {row.original.exam_ids.map((examId, index) => (
                    <span key={index} >
                        {index > 0 && ", "}
                        <Link className="font-medium text-imsblue-600"
                              to={`/class-register/exams/${examId}`}>{examId}</Link>
                    </span>
                ))}
            </>
        )
    }
]


export default function ExamWarnings() {

    const {
        isLoading,
        isError,
        isSuccess,
        data,
        error,
        refetch
    } = useQuery(['exam_warnings'], () => getExamWarningsList(), {})

    if (!data) return <></>
    return (

        <SimplePage>
            <div className="px-4 sm:px-6 lg:px-8">
                <div className="sm:flex sm:items-center">
                    <div className="sm:flex-auto">
                        <h3 className="text-lg leading-6 font-medium text-gray-900">
                            SchülerInnen mit > {data.weekly_threshold} Klausuren in einer Woche
                        </h3>
                    </div>
                </div>
                <div className="flex flex-col">
                    <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">

                        <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                            {isSuccess && data?.students_week && <ListViewTable data={data.students_week}
                                                                                columns={weekColumns}
                                                                                isLoading={isLoading}
                                                                                disableGlobalSearch={false}
                                                                                getRowLink={null}
                            />}
                        </div>
                    </div>
                </div>
            </div>

            <div className="mt-6 px-4 sm:px-6 lg:px-8">
                <div className="sm:flex sm:items-center">
                    <div className="sm:flex-auto">
                        <h3 className="text-lg leading-6 font-medium text-gray-900">
                            SchülerInnen mit > {data.daily_threshold} Klausur(en) an einem Tag
                        </h3>
                    </div>
                </div>
                <div className="flex flex-col">
                    <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">

                        <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                            {isSuccess && data?.students_day && <ListViewTable data={data.students_day}
                                                                               columns={dayColumns}
                                                                               isLoading={isLoading}
                                                                               disableGlobalSearch={false}
                                                                               getRowLink={null}
                            />}
                        </div>
                    </div>
                </div>
            </div>
        </SimplePage>
    )
}
