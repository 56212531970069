import React from 'react';

import {useQuery} from '@tanstack/react-query'
import {useAuth} from "../../../contexts/AuthContext";
import ListViewTable, {TableLink} from "../../elements/tables/ListViewTable";
import {confirmSubstitution, getMyTimetableChanges} from "../../../api/timetables";
import {toast} from "react-toastify";


const substitutionsColumns = [
    {
        accessorKey: "date",
        id: "date",
        header: "Datum",
        columnType: "date",
        enableColumnFilter: false,
        // classNames: 'font-medium text-gray-900'
        classNames: "text-gray-500",
    },
    {
        accessorKey: "course",
        id: "course",
        header: "Kurs",
        enableColumnFilter: false,
        cell: ({row, value}) => (
            <TableLink
                label={row.original.course.course_number}
                to={`/courses/${row.original.course.id}`}
            />
        ),
        disableRowLink: true
    },
    {
        accessorKey: "course.school_subject",
        id: "subject",
        header: "Fach",
        enableColumnFilter: false,
    },
    {
        accessorKey: "periods",
        id: "periods",
        header: "Zeit",
        columnType: "m2m",
        labelAttr: "name",
        enableColumnFilter: false
    },
    {
        accessorKey: "replaced_teacher.full_name",
        id: "replaced_teacher",
        header: "Es fehlt",
        enableColumnFilter: false
    },
    {
        accessorKey: "replacing_teacher",
        id: "replacing_teacher",
        header: "Vertretung durch",
        enableColumnFilter: false,
        cell: ({row, value}) => (
            <TableLink
                label={row.original.replacing_teacher.full_name}
                to={`/staff/${row.original.replacing_teacher.id}`}
            />
        ),
        disableRowLink: true
    },
    {
        accessorKey: "room.name",
        id: "room",
        header: "Raum",
        enableColumnFilter: false,
    },
    {
        accessorKey: "remarks",
        id: "remarks",
        header: "Anmerkungen",
        enableColumnFilter: false,
    }
]

const cancellationsColumns = [
    {
        accessorKey: "date",
        id: "date",
        header: "Datum",
        columnType: "date",
        enableColumnFilter: false,
        // classNames: 'font-medium text-gray-900'
        classNames: "text-gray-500",
    },
    {
        accessorKey: "course",
        id: "course",
        header: "Kurs",
        enableColumnFilter: false,
        cell: ({row, value}) => (
            <TableLink
                label={row.original.course.course_number}
                to={`/courses/${row.original.course.id}`}
            />
        ),
        disableRowLink: true
    },
    {
        accessorKey: "course.school_subject",
        id: "subject",
        header: "Fach",
        enableColumnFilter: false,
    },
    {
        accessorKey: "periods",
        id: "periods",
        header: "Zeit",
        columnType: "m2m",
        labelAttr: "name",
        enableColumnFilter: false
    },
    {
        accessorKey: "remarks",
        id: "remarks",
        header: "Anmerkungen",
        enableColumnFilter: false,
    }
]

const roomChangesColumns = [
    {
        accessorKey: "date",
        id: "date",
        header: "Datum",
        columnType: "date",
        enableColumnFilter: false,
        // classNames: 'font-medium text-gray-900'
        classNames: "text-gray-500",
    },
    {
        accessorKey: "course",
        id: "course",
        header: "Kurs",
        enableColumnFilter: false,
        cell: ({row, value}) => (
            <TableLink
                label={row.original.course.course_number}
                to={`/courses/${row.original.course.id}`}
            />
        ),
        disableRowLink: true
    },
    {
        accessorKey: "course.school_subject",
        id: "subject",
        header: "Fach",
        enableColumnFilter: false,
    },
    {
        accessorKey: "periods",
        id: "periods",
        header: "Zeit",
        columnType: "m2m",
        labelAttr: "name",
        enableColumnFilter: false
    },
    {
        accessorKey: "room.name",
        id: "room",
        header: "Raum",
        enableColumnFilter: false,
    },
    {
        accessorKey: "remarks",
        id: "remarks",
        header: "Anmerkungen",
        enableColumnFilter: false,
    }
]


const projectCancellationsColumns = [
    {
        accessorKey: "date",
        id: "date",
        header: "Datum",
        columnType: "date",
        enableColumnFilter: false,
        // classNames: 'font-medium text-gray-900'
        classNames: "text-gray-500",
    },
    {
        accessorKey: "project",
        id: "project",
        header: "Projekt",
        enableColumnFilter: false,
        cell: ({row, value}) => (
            <TableLink
                label={row.original.project.name}
                to={`/projects/${row.original.project.id}`}
            />
        ),
        disableRowLink: true
    },
    {
        accessorKey: "remarks",
        id: "remarks",
        header: "Anmerkungen",
        enableColumnFilter: false,
    }
]

export default function MyTimetableChanges({dashboard}) {


    const teacherSubstitutionsColumns = [
        {
            accessorKey: "date",
            id: "date",
            header: "Datum",
            columnType: "date",
            enableColumnFilter: false,
            // classNames: 'font-medium text-gray-900'
            classNames: "text-gray-500",
        },
        {
            accessorKey: "course",
            id: "course",
            header: "Kurs",
            enableColumnFilter: false,
            cell: ({row, value}) => (
                <TableLink
                    label={row.original.course.course_number}
                    to={`/courses/${row.original.course.id}`}
                />
            ),
            disableRowLink: true
        },
        {
            accessorKey: "course.school_subject",
            id: "subject",
            header: "Fach",
            enableColumnFilter: false,
        },
        {
            accessorKey: "periods",
            id: "periods",
            header: "Zeit",
            columnType: "m2m",
            labelAttr: "name",
            enableColumnFilter: false
        },
        {
            accessorKey: "replaced_teacher.full_name",
            id: "replaced_teacher",
            header: "Es fehlt",
            enableColumnFilter: false
        },
        {
            accessorKey: "room.name",
            id: "room",
            header: "Raum",
            enableColumnFilter: false,
        },
        {
            accessorKey: "remarks",
            id: "remarks",
            header: "Anmerkungen",
            enableColumnFilter: false,
        },
        {
            accessorKey: "confirmed",
            id: "confirmed",
            header: "Bestätigt",
            columnType: "yesno",
            enableColumnFilter: false,
        },
        {
            accessorKey: "kbe", // todo
            id: "kbe",
            header: "KBE",
            enableColumnFilter: false,
            cell: ({row, value}) => (
                row.original.class_register_entry_id ?
                        <TableLink label="Ansehen"
                              to={`/class-register/entries/${row.original.class_register_entry_id}`} />
                        :
                        <TableLink  label="Erstellen"
                              to={`/class-register/entries/new/${row.original.course.id}`} />
            ),
            disableRowLink: true
        },
        {
            accessorKey: "id",
            id: "link",
            cell: ({row, value}) => (
                <TableLink
                    label="Bestätigen"
                    to={''}
                    onClick={() => handleConfirm(row.original)}
                />
            ),
            header: "Bestätigen",
            enableColumnFilter: false,
            disableRowLink: true
        }
    ]

    const {currentUser} = useAuth()
    const {data, isSuccess, isLoading, refetch} = useQuery(['my_timetable_changes', currentUser.user_id], getMyTimetableChanges)

    async function handleConfirm(substitution) {
        try {
            await confirmSubstitution(substitution)
            await refetch()
            toast.success("Vertretung bestätigt")
        } catch (e) {
            toast.error("Beim Speichern ist ein Fehler aufgetreten.")
        }
    }

    function getSubstitutionRowLink(row) {
        return `/timetables/substitutions/${row.original.id}/`;
    }

    if (isLoading) return <></>

    return (
        <div className={dashboard ? "" : "py-6 bg-white"}>

            <div className={dashboard ? "px-4" : "px-4 sm:px-6 lg:px-8"} >
                <div className="flex flex-col">
                    <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
                        {isSuccess && data && data?.teacher_substitutions.length > 0 &&
                            <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                                <div className="sm:flex sm:items-center">
                                    <div className="sm:flex-auto">
                                        <h3 className="text-lg leading-6 font-medium text-gray-900">
                                            Meine Vertretungen
                                        </h3>
                                    </div>
                                </div>
                                <div className="flex flex-col">
                                    <ListViewTable
                                        data={data.teacher_substitutions}
                                        columns={teacherSubstitutionsColumns}
                                        isLoading={isLoading}
                                        disableGlobalSearch={true}
                                        getRowLink={getSubstitutionRowLink}
                                    />
                                </div>
                            </div>}

                        {isSuccess && data && data?.student_substitutions.length > 0 &&
                            <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                                <div className="sm:flex sm:items-center">
                                    <div className="sm:flex-auto">
                                        <h3 className="text-lg leading-6 font-medium text-gray-900">
                                            Vertretungen
                                        </h3>
                                    </div>
                                    <div className="mt-4 sm:mt-0 sm:ml-16 flex gap-2 "></div>
                                </div>
                                <div className="flex flex-col">
                                    <ListViewTable
                                        data={data.student_substitutions}
                                        columns={substitutionsColumns}
                                        isLoading={isLoading}
                                        disableGlobalSearch={true}
                                        getRowLink={null}
                                    />
                                </div>
                            </div>}

                        {isSuccess && data && data?.student_cancellations.length > 0 &&
                            <div className="mt-4 inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                                <div className="sm:flex sm:items-center">
                                    <div className="sm:flex-auto">
                                        <h3 className="text-lg leading-6 font-medium text-gray-900">
                                            Ausfall
                                        </h3>
                                    </div>
                                    <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
                                    </div>
                                </div>
                                <div className="flex flex-col">
                                    <ListViewTable
                                        data={data.student_cancellations}
                                        columns={cancellationsColumns}
                                        isLoading={isLoading}
                                        disableGlobalSearch={true}
                                        getRowLink={null}
                                    />
                                </div>
                            </div>}

                        {isSuccess && data && data?.student_room_changes.length > 0 &&
                            <div className="mt-4 inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                                <div className="sm:flex sm:items-center">
                                    <div className="sm:flex-auto">
                                        <h3 className="text-lg leading-6 font-medium text-gray-900">
                                            Raumwechsel
                                        </h3>
                                    </div>
                                    <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none"></div>
                                </div>
                                <div className="flex flex-col">
                                    <ListViewTable
                                        data={data.student_room_changes}
                                        columns={roomChangesColumns}
                                        isLoading={isLoading}
                                        disableGlobalSearch={true}
                                        getRowLink={null}
                                    />
                                </div>
                            </div>}

                        {isSuccess && data && data?.student_project_cancellations.length > 0 &&
                            <div className="mt-4 inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                                <div className="sm:flex sm:items-center">
                                    <div className="sm:flex-auto">
                                        <h3 className="text-lg leading-6 font-medium text-gray-900">
                                            Projektausfall
                                        </h3>
                                    </div>
                                    <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none"></div>
                                </div>
                                <div className="flex flex-col">
                                    <ListViewTable
                                        data={data.student_project_cancellations}
                                        columns={projectCancellationsColumns}
                                        isLoading={isLoading}
                                        disableGlobalSearch={true}
                                        getRowLink={null}
                                    />
                                </div>
                            </div>}

                    </div>
                </div>
            </div>
        </div>
    )
}
