import {NarrowPage} from "../../elements/Pages";
import {React, useEffect, useState} from "react";
import Joi from "joi"
import {useQuery, useQueryClient} from '@tanstack/react-query'
import {Checkbox, DateInput, MultiSelect, Select, TextArea} from "../../elements/forms/Inputs"
import {FormSectionContent} from "../../elements/forms/FormLayout"
import {getCourseInfoAll} from "../../../api/school";
import Form, {baseHandleChange, baseHandleDateChange} from "../../elements/forms/Form"
import {useNavigate, useParams} from "react-router-dom";
import {toast} from "react-toastify";
import {getDayPeriods} from "../../../api/calendar";
import {substitutionApi} from "../../../api/timetables";
import {getRooms} from "../../../api/organizational";
import {getTeacherAndMentorList, getTeacherList} from "../../../api/staff";
import {getCoursesForTeacher} from "../../../api/courses";

export default function SubstitutionCreateEdit() {

    const {id} = useParams()
    const [data, setData] = useState({})
    const queryClient = useQueryClient()
    const [coursesForTeacher, setCoursesForTeacher] = useState([])

    const {data: courses} = useQuery(['courses'], getCourseInfoAll, {refetchInterval: 1000 * 60 * 60 * 24})
    const {data: dayPeriods} = useQuery(['day_periods'], getDayPeriods, {refetchInterval: 1000 * 60 * 60 * 24})
    const {data: rooms} = useQuery(["rooms"], getRooms, {refetchInterval: 1000 * 60 * 60 * 24});
    const {data: teachers} = useQuery(["teachers_and_mentors"], getTeacherAndMentorList, {refetchInterval: 1000 * 60 * 60 * 24})

    const [errors, setErrors] = useState({}) // validation errors

    let navigate = useNavigate();

    useEffect(() => {
        loadData()
    }, [id])

    async function loadData() {
        if (!id) return
        const newData = await substitutionApi('get', id)
        setData(newData)
    }

    useEffect(() => {
        leadCoursesForTeacher()
    }, [data.replaced_teacher])

    async function leadCoursesForTeacher() {
        if (!data.replaced_teacher?.id) return
        const newData = await getCoursesForTeacher(data.replaced_teacher.id)
        setCoursesForTeacher(newData)
    }

    const schema = {
        date: Joi.date().required(),
        course: Joi.object().required(),
        room: Joi.object().required(),
        periods: Joi.array().min(1),
        replaced_teacher: Joi.object().allow(null),
        replacing_teacher: Joi.object().allow(null),
    };

    const title = (id || data.id) ? "Vertretung bearbeiten" : "Vertretung eintragen"
    const successMessage = (id || data.id) ? "Vertretung aktualisiert" : "Vertretung eingetragen"

    async function onSave() {
        // console.log(data)
        let newData
        if (!data.id) newData = await substitutionApi('post', null, data)
        else newData = await substitutionApi('patch', null, data)
        setData(newData)
        queryClient.invalidateQueries({ queryKey: ["timetable_changes"] })
        queryClient.invalidateQueries({ queryKey: ["timetable_changes_teachers_lounge"] })
        queryClient.invalidateQueries({ queryKey: ["timetable_changes_aula"] })
        toast.success(successMessage)
        navigate(`/timetables`)
    }

    async function onDelete() {
        await substitutionApi('delete', data.id)
        toast.success("Vertretung gelöscht.")
        navigate(`/timetables`)
    }

    function handleChange(evt) {
        baseHandleChange(evt, data, setData, errors, setErrors, schema)
    }

    function handleDateChange(date, path) {
        baseHandleDateChange(date, path, data, setData, errors, setErrors, schema, false)
    }

    function handleMultiSelect(selectedOptions, path) {
        let newData = {...data}
        newData[path] = selectedOptions
        setData(newData)
    }

    return (

        <NarrowPage title={title}>

            <Form id="createCancellation" onSave={onSave}
                  onDelete={onDelete}
                  data={data} setData={setData}
                  errors={errors} setErrors={setErrors}
                  successMessage={successMessage}
                  schema={schema}>

                <FormSectionContent>

                    <DateInput
                        path="date"
                        label="Datum"
                        className="sm:col-span-3"
                        onChange={handleDateChange}
                        errors={errors}
                        data={data}
                        todayButton={true}
                        tomorrowButton={false}
                    />

                    <MultiSelect
                        path="periods"
                        label="Stunde"
                        className="sm:col-span-3"
                        options={dayPeriods}
                        valueAttr="id"
                        labelAttr="name"
                        onChange={(selectedOptions) => handleMultiSelect(selectedOptions, 'periods')}
                        errors={errors}
                        data={data}
                        isClearable={true}
                        valueOnly={false}
                    />

                    <Select
                        path="replaced_teacher"
                        label="Es fehlt"
                        className="sm:col-span-3"
                        options={teachers}
                        valueAttr="id"
                        labelAttr="full_name"
                        onChange={handleChange}
                        errors={errors}
                        data={data}
                        isClearable={false}
                        valueOnly={false}
                    />

                    <Select
                        path="replacing_teacher"
                        label="Vertretung durch"
                        className="sm:col-span-3"
                        options={teachers}
                        valueAttr="id"
                        labelAttr="full_name"
                        onChange={handleChange}
                        errors={errors}
                        data={data}
                        isClearable={false}
                        valueOnly={false}
                    />

                    <Select
                        path="course"
                        label="Kurs"
                        className="sm:col-span-3"
                        options={coursesForTeacher || courses}
                        valueAttr="id"
                        labelAttr="course_number"
                        onChange={handleChange}
                        errors={errors}
                        data={data}
                        isClearable={false}
                        valueOnly={false}
                    />


                    <Select
                        path="room"
                        label="Raum"
                        className="sm:col-span-3"
                        options={rooms}
                        valueAttr="id"
                        labelAttr="name"
                        onChange={handleChange}
                        errors={errors}
                        data={data}
                        isClearable={false}
                        valueOnly={false}
                    />

                    <TextArea
                        path="remarks"
                        label="Anmerkungen"
                        onChange={handleChange}
                        errors={errors}
                        data={data}
                        rows={4}
                        className="sm:col-span-6"
                        helpText=""
                    />

                    <TextArea
                        path="replacement_for"
                        label="Ersatz für"
                        onChange={handleChange}
                        errors={errors}
                        data={data}
                        rows={4}
                        className="sm:col-span-6"
                        helpText=""
                    />

                    <Checkbox
                        path="confirmed"
                        label="Bestätigt"
                        className="sm:col-span-6"
                        onChange={handleChange}
                        errors={errors}
                        data={data}/>

                </FormSectionContent>

            </Form>

            <span onClick={() => navigate(-1)}
                  className="cursor-pointer float-right mt-2 font-medium text-imsblue-600">Zurück</span>

        </NarrowPage>
    )
}
