import http from "./../services/httpService";
import {genericApi} from "./generic";
import httpNoAuthService from "../services/httpNoAuthService";


export async function getWeekdayOptions() {
    const resp = await http.get('/calendar_app/weekday_options/')
    return resp.data
}

export async function getSchoolYears() {
    const resp = await http.get("/school_years/");
    return resp.data;
}

export async function getCurrentSchoolYear() {
    const resp = await http.get("/school_years/current/");
    return resp.data;
}

export async function getFutureSchoolYears() {
    const resp = await http.get("/school_years/future/");
    return resp.data;
}

export async function getDayPeriods() {
    const resp = await http.get("/day_periods/");
    return resp.data;
}

export async function getTimePeriods() {
    const resp = await http.get("/time_periods/");
    return resp.data;
}

export async function getCurrentTimePeriods() {
    const resp = await http.get("/time_periods/current/");
    return resp.data;
}

export async function getCurrentEpochs() {
    const resp = await http.get("/time_periods/current_epochs/");
    return resp.data;
}

export async function getCurrentHalfYear() {
    const resp = await http.get("/time_periods/current_half_year/");
    return resp.data;
}

export async function getEventsByMonth(year, month) {
    const resp = await http.get(
        `/school_events/month/?year=${year}&month=${month}`
    );
    return resp.data;
}

export async function getEventsByWeek(year, week) {
    const resp = await http.get(`/school_events/week/?year=${year}&week=${week}`);
    return resp.data;
}

export async function getSchoolEventCategories() {
    const resp = await http.get('/school_event_categories/')
    return resp.data
}

export async function schoolEventApi(method, id, data) {
    return genericApi('/school_events/', method, id, data)
}

export async function addImageToSchoolEvent(formData, id) {
    const resp = await http.post(`/school_events/${id}/add_file/`, formData)
    return resp.data
}

export async function cancelSchoolOccurrence(id, data) {
    const resp = await http.post(`/school_events/${id}/cancel_occurrence/`, data)
    return resp.data
}

export async function uncancelSchoolEventOccurrence(id, data) {
    const resp = await http.post(`/school_events/${id}/uncancel_occurrence/`, data)
    return resp.data
}

export async function getCurrentDate() {
    const resp = await httpNoAuthService.get('/calendar_app/current_date/')
    return resp.data
}