import http from "./../services/httpService";
import httpServiceDownloads from "../services/httpServiceDownloads";

export async function getAllProducts() {
    const resp = await http.get("/product/");
    return resp.data;
}

export async function getProductDetail(productId) {
    const resp = await http.get(`/product/${productId}/`);
    return resp.data;
}

export async function getProductsSizes(id) {
    const resp = await http.get(`/product_size/${id}/`);
    return resp.data;
}

export async function createOrder(cartItem) {
    const resp = await http.post(`/order_line/`, cartItem);
    return resp.data;
}

export async function getOrders() {
    const resp = await http.get(`/order/`);
    return resp.data;
}

export async function getOrderDetail(id) {
    const resp = await http.get(`/order/${id}/`);
    return resp.data;
}

export async function getOrderListExcel() {
    const resp = await httpServiceDownloads.get(`/order/excel/`)
    return resp.data
}
