import {SimplePage} from "../../elements/Pages";
import React from "react";

import {useQuery} from "@tanstack/react-query";
import {useAuth} from "../../../contexts/AuthContext";
import ListViewTable from "../../elements/tables/ListViewTable";
import checkPermission from "../../../utils/permissions";
import {Link} from "react-router-dom";
import {getMyTutorings, getStatusOptions} from "../../../api/tutoring";


export default function MyTutorings() {
    const {permissions, currentUser} = useAuth();

    const {data} = useQuery(
        ["my_tutorings", currentUser.user_id],
        () => getMyTutorings(),
        {refetchInterval: 1000 * 60 * 60 * 1}
    )

    const {data: statusOptions} = useQuery(
        ["tutoring_status_options"],
        () => getStatusOptions(),
        {refetchInterval: 1000 * 60 * 60 * 24}
    )

    const canChangeTutoring = checkPermission(permissions, "tutoring.change_tutoring")

    let columns = [
        {
            accessorFn: (row) =>
                `${row.student.last_name}, ${row.student.first_name} ${row.student.student_number}`,
            id: "student_search",
            header: "SchülerIn",
            hide: true,
            classNames: "min-w-lg white-space-pre-line",
            enableColumnFilter: false,
        },
        {
            accessorKey: "student",
            id: "student",
            header: "SchülerIn",
            columnType: "student",
            enableColumnFilter: false,
            sortingFn: "lastNameSorting",
        },
        {
            accessorKey: "student.school_class",
            id: "school_class",
            header: "Klasse",
            headerClassNames: "narrow-filter",
        },
        {
            accessorKey: "subject.name",
            id: "subject",
            header: "Fach",
            enableColumnFilter: true,
            classNames: "text-gray-500",
            headerClassNames: "narrow-filter",
            filterFn: "containsString",
            labelAttr: "name",
        },
        ...canChangeTutoring ? [{
            accessorKey: "jugendamt",
            id: "jugendamt",
            header: "Jugendamt",
            columnType: "yesno",
            enableColumnFilter: true,
            filterFn: "boolean",
            classNames: "text-gray-500",
            headerClassNames: "narrow-filter"
        }] : [],
        {
            accessorKey: "teacher.full_name",
            id: "teacher",
            header: "Fachlehrer",
            enableColumnFilter: true,
            classNames: "text-gray-500",
            headerClassNames: "medium-filter",
            labelAttr: "full_name",
            filterFn: "containsString"
        },
        {
            accessorKey: "tutoring_teacher.full_name",
            id: "tutoring_teacher",
            header: "Nachhilfelehrer",
            enableColumnFilter: true,
            filterFn: "containsString",
            labelAttr: "full_name",
            headerClassNames: "medium-filter",
        },
        {
            accessorKey: "tutoring_plan_filled_in",
            id: "tutoring_plan_filled_in",
            header: "Förderplan",
            enableColumnFilter: true,
            columnType: "yesno",
            filterFn: "boolean",
            // classNames: 'font-medium text-gray-900'
            classNames: "text-gray-500",
            headerClassNames: "narrow-filter",
        }
        // {
        //     accessorKey: "get_status_display",
        //     id: "status",
        //     header: "Status",
        //     enableColumnFilter: true,
        //     // classNames: 'font-medium text-gray-900'
        //     classNames: "text-gray-500",
        //     headerClassNames: "medium-filter"
        // }
    ]

    function getRowLink(row) {
        return `/tutoring/${row.original.id}/`;
    }

    return (
        <SimplePage>
            {/*  Simple striped table */}
            <div className="px-4 sm:px-6 lg:px-8">
                <div className="sm:flex sm:items-center">
                    <div className="sm:flex-auto">
                        <h3 className="text-lg leading-6 font-medium text-gray-900">
                            Meine Fördermaßnahmen
                        </h3>
                    </div>
                    <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
                        {checkPermission(
                            permissions,
                            "tutoring.add_tutoring"
                        ) && (
                            <Link to="/tutoring/new">
                                <button
                                    type="button"
                                    className="inline-flex items-center justify-center rounded-md border border-transparent bg-imsblue-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-imsblue-700 focus:outline-none focus:ring-2 focus:ring-imsblue-500 focus:ring-offset-2 sm:w-auto"
                                >
                                    Neue Fördermaßnahme
                                </button>
                            </Link>
                        )}

                    </div>
                </div>
                <div className="flex flex-col">
                    <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
                        <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                            {data && (
                                <ListViewTable
                                    data={data}
                                    columns={columns}
                                    isLoading={!data}
                                    disableGlobalSearch={false}
                                    rowSelection={null}
                                    setRowSelection={null}
                                    getRowLink={getRowLink}
                                    onDelete={null}
                                />

                            )}
                        </div>
                    </div>
                </div>
            </div>
        </SimplePage>
    );
}
