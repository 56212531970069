import React, {useEffect, useState} from "react";
import {getProductsSizes, getProductDetail} from "../../../api/shop";
import {useParams, useNavigate} from "react-router-dom";
import {RadioGroup, Tab} from '@headlessui/react'
import ProductList from "./ProductList";
import {useQuery} from "@tanstack/react-query";
import {toast} from "react-toastify";
import {useShopCart} from "../../../contexts/ShopCartContext";
import {formatCurrency} from "../../../utils/formatters";


function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

export default function ProductDetail() {

    const {id} = useParams();
    const navigate = useNavigate();

    const {
        addProductToCart,
    } = useShopCart();

    const {data: productData} = useQuery(
        ["product_detail", id],
        () => getProductDetail(id),
        {}
    )

    const [selectedSize, setSelectedSize] = useState()
    const [customText, setCustomText] = useState("")

    async function addToCart() {
        if (!selectedSize) {
            toast.error("Bitte zuerst eine Größe auswählen.");
            return null
        }

        const cartItem = {
            product: {
                ...productData
            },
            productSize: {
                ...selectedSize
            },
            custom_text: customText,
        }

        addProductToCart(cartItem)

        navigate("/shop/cart")
    }

    if (!productData) return <></>;

    return (
        <main className="bg-white mx-auto max-w-7xl sm:px-6 sm:pt-16 lg:px-8">
            <div className="mx-auto max-w-2xl lg:max-w-none">
                {/* Product */}
                <div className="lg:grid lg:grid-cols-2 lg:items-start lg:gap-x-8">
                    <ProductImageGallery productData={productData} />
                    {/* Product info */}
                    <div className="mt-10 px-4 sm:mt-16 sm:px-0 lg:mt-0">
                        <h1 className="text-3xl font-bold tracking-tight text-gray-900">{productData.title}</h1>
                        <p className="mt-3 text-3xl tracking-tight text-gray-900">{formatCurrency(productData.price)}</p>
                        <h2 className="mt-3 text-xl tracking-tight text-gray-500">{productData.description}</h2>
                        <ProductSizePicker
                            value={selectedSize}
                            setValue={setSelectedSize}
                        />
                        {productData.custom_text_label &&
                            <ProductCustomText
                                value={customText}
                                setValue={setCustomText}
                                productLabel={productData.custom_text_label}
                            /> }
                        <div className="mt-10">
                            <button
                                onClick={addToCart}
                                className="flex max-w-xs flex-1 items-center justify-center rounded-md border border-transparent bg-imsblue-600 py-3 px-8 text-base font-medium text-white hover:bg-imsblue-700 focus:outline-none focus:ring-2 focus:ring-imsblue-500 focus:ring-offset-2 focus:ring-offset-gray-50 sm:w-full"
                            >
                                Zum Warenkorb hinzufügen
                            </button>
                        </div>
                    </div>
                </div>
                <ProductFooter/>
            </div>
        </main>
    )
}

function ProductImageGallery ({productData}) {
    return (
        <Tab.Group as="div" className="flex flex-col">
            <Tab.Panels className="aspect-w-1 aspect-h-1 w-full">
                {productData.images.detail.map((image, idx) => (
                    <Tab.Panel key={idx}>
                        <img
                            src={image}
                            className="h-full w-full object-cover object-center sm:rounded-lg"
                            alt="product"
                        />
                    </Tab.Panel>
                ))}
            </Tab.Panels>
            {/*Image selector*/}
            <div className="mx-auto mt-6 hidden w-full max-w-2xl sm:block lg:max-w-none">
                <Tab.List className="grid grid-cols-4 gap-6">
                    {productData.images.detail.map((image, idx) => (
                        <Tab
                            key={idx}
                            className="relative flex h-24 cursor-pointer items-center justify-center rounded-md bg-white text-sm font-medium uppercase text-gray-900 hover:bg-gray-50 focus:outline-none focus:ring focus:ring-opacity-50 focus:ring-offset-4"
                        >
                            {({selected}) => (
                                <>
                                    <span className="sr-only">{image}</span>
                                    <span className="absolute inset-0 overflow-hidden rounded-md">
                                        <img src={image} alt="" className="h-full w-full object-cover object-center"/>
                                    </span>
                                    <span
                                        className={classNames(
                                            selected ? 'ring-imsblue-500' : 'ring-transparent',
                                            'pointer-events-none absolute inset-0 rounded-md ring-2 ring-offset-2'
                                        )}
                                        aria-hidden="true"
                                    />
                                </>
                            )}
                        </Tab>
                    ))}
                </Tab.List>
            </div>
        </Tab.Group>
    )
}

function ProductSizePicker({value, setValue}) {

    const {id} = useParams();

    const [productSizes, setProductSizes] = useState([])

    const loadProductSizes = async () => {
        try {
            const data = await getProductsSizes(id)
            setProductSizes(data)
        } catch (e) {
            setProductSizes([])
        }
    }

    useEffect(() => {
        loadProductSizes()
    }, [id])

    if (!productSizes.length) return <></>;

    return (
        <div className="mt-3">
            <div className="flex items-center justify-between">
                <div className="block text-xl font-medium text-gray-800 my-4">Größe</div>
            </div>
            <RadioGroup value={value} onChange={setValue} className="mt-2">
                <RadioGroup.Label className="sr-only">Größe auswählen</RadioGroup.Label>
                <div className="grid grid-cols-3 gap-3 sm:grid-cols-6">
                    {productSizes.map((size) => (
                        <RadioGroup.Option
                            key={size.size}
                            value={size}
                            className={({active, checked}) =>
                                classNames(
                                    size.stock ? 'cursor-pointer focus:outline-none' : 'opacity-25 cursor-not-allowed',
                                    active ? 'ring-2 ring-offset-2 ring-imsblue-500' : '',
                                    checked
                                        ? 'bg-imsblue-600 border-transparent text-white hover:bg-imsblue-700'
                                        : 'bg-white border-gray-200 text-gray-900 hover:bg-gray-50',
                                    'border rounded-md py-3 px-3 flex items-center justify-center text-sm font-medium uppercase sm:flex-1'
                                )
                            }
                            disabled={!size.stock}
                        >
                            <RadioGroup.Label as="span">{size.size}</RadioGroup.Label>
                        </RadioGroup.Option>
                    ))}
                </div>
            </RadioGroup>
        </div>
    )
}

function ProductCustomText({value, setValue, productLabel}) {
    return (
        <div>
            <label htmlFor="customText" className="block text-xl font-medium text-gray-800 my-4">
                {productLabel}
            </label>
            <div className="mt-1">
                <textarea
                    value={value}
                    onChange={e => setValue(e.target.value)}
                    rows={2}
                    name="customText"
                    id="customText"
                    className="block w-full rounded-md border-gray-300 shadow-sm focus:border-imsblue-500 focus:ring-imsblue-500 sm:text-sm"
                    defaultValue={''}
                />
            </div>
        </div>
    )
}

function ProductFooter() {
    return (
        <section aria-labelledby="related-heading"
                 className="mt-14 border-t border-gray-200 py-4 px-4 sm:px-0"
        >
            <h2 id="related-heading" className="text-xl font-bold text-gray-900">
                Andere Produkte
            </h2>
            <ProductList/>
        </section>
    )
}
