import React, {useEffect, useState} from "react";

import {useQuery} from "@tanstack/react-query";
import {PrimaryButton} from "../../elements/Buttons";
import {getMyStudents} from "../../../api/school";
import {getParentConferenceRegistration, updateRegistration} from "../../../api/parent_conference";
import {toast} from "react-toastify";
import {default as ReactSelect} from "react-select";
import {useAuth} from "../../../contexts/AuthContext";

export default function Registration() {

    const [registrationFormOpen, setRegistrationFormOpen] = useState(false)
    const [data, setData] = useState([])
    const {currentUser} = useAuth()
    const {data: students} = useQuery(['my_students', currentUser.user_id], getMyStudents, {refetchInterval: 1000 * 60 * 60 * 24})

    useEffect(() => {
        loadData()
    }, [])

    async function loadData() {
        const newData = await getParentConferenceRegistration()
        setData(newData)
    }

    async function onSave(conferenceDayId) {
        const newConferenceDay = await updateRegistration(conferenceDayId, data.find(cd => cd.id == conferenceDayId))
        const newData = data.map(cd => {
            if (cd.id == conferenceDayId) return newConferenceDay
            return cd
        })
        setData(newData)

        if (newConferenceDay.registration_completed) {
            toast.success("Anmeldung abgeschlossen. Sie können jetzt Ihre Termine buchen.")
            setRegistrationFormOpen(false)
        } else {
            toast.success("Anmeldung gespeichert. Bitte beantworten Sie alle Fragen, um die Anmeldung abzuschließen und Ihre Temrine zu buchen.")
        }
    }

    function handleChange(evt, conferenceDayId) {
        const questionId = evt.target.name
        const optionId = evt.target.value.option_id

        const newData = data.map(cd => {
            var newQuestions = cd.questions
            if (cd.id === conferenceDayId) {
                newQuestions = cd.questions.map(question => {
                    if (question.id === questionId) {
                        question['answers'] = [{
                            "option_id": optionId
                        }]
                    }
                    return question
                })

            }
            return {...cd, questions: newQuestions}
        })
        setData(newData)
    }

    if (!data || !students || data.length <= 0) return <></>;
    return (
        <>
            {data && data.map(conference_day =>
                <main key={conference_day.id} className="py-10 h-full bg-gray-100">

                    <div
                        className="max-w-3xl mx-auto px-4 sm:px-6 md:flex md:items-center md:justify-between md:space-x-5 lg:max-w-7xl lg:px-8">
                        <div className="flex items-center space-x-5">
                            <div>
                                <h1 className="text-2xl font-bold text-gray-900">{conference_day.title}</h1>
                                {/*<p className="text-sm font-medium text-gray-500">{student.student_number}</p>*/}
                                <p>{conference_day.get_times}</p>
                            </div>
                        </div>
                        <div
                            className="mt-6 flex flex-col-reverse justify-stretch space-y-4 space-y-reverse sm:flex-row-reverse sm:justify-end sm:space-x-reverse sm:space-y-0 sm:space-x-3 md:mt-0 md:flex-row md:space-x-3">
                        </div>
                    </div>

                    <div
                        className="mt-8 max-w-3xl mx-auto grid grid-cols-1 gap-6 sm:px-6 lg:max-w-7xl lg:grid-flow-col-dense lg:grid-cols-3">
                        <div className="space-y-6 lg:col-start-1 lg:col-span-2">
                            {/* Schüler */}
                            <div className="bg-white shadow sm:rounded-lg sm:overflow-hidden">
                                <div className="divide-y divide-gray-200">
                                    <div className="px-4 py-5 sm:px-6 mt-1 text-sm text-gray-700">
                                        <p className="whitespace-pre-wrap">{conference_day.registration_text}</p>
                                    </div>

                                    {conference_day.booking_open &&
                                        <div className="flex gap-1 px-4 py-5 sm:px-6 mt-1 ">
                                            <PrimaryButton label="Anmelden"
                                                           onClick={() => setRegistrationFormOpen(!registrationFormOpen)}/>

                                            {/* Students */}
                                            {conference_day.registration_completed && <>
                                                {students.map(student =>
                                                    <PrimaryButton
                                                        key={student.id}
                                                        link={`/parent-conference/${conference_day.id}/students/${student.id}`}
                                                        label={student.first_name}/>
                                                )}
                                            </>}

                                        </div>}

                                    {registrationFormOpen &&
                                        <div className="px-4 py-5 sm:px-6 mt-1 text-sm text-gray-700">

                                            <h3 className="text-lg leading-6 font-medium text-gray-900">Anmeldung</h3>

                                            {conference_day.questions.map((question, index) => (
                                                <RegistrationQuestion
                                                    key={index}
                                                    question={question}
                                                    handleChange={(evt) => handleChange(evt, conference_day.id)}
                                                />)
                                            )}

                                            <PrimaryButton label="Anmeldung speichern"
                                                           onClick={() => onSave(conference_day.id)}/>

                                            {/*TODO: Hinweis wenn nicht alle Fragen beantwortet*/}

                                        </div>}

                                    {!conference_day.booking_open &&
                                        <div className="flex gap-1 px-4 py-5 sm:px-6 mt-1 ">
                                            {/* Students */}
                                            {conference_day.registration_completed && <>
                                                {students.map(student =>
                                                    <PrimaryButton
                                                        key={student.id}
                                                        link={`/parent-conference/${conference_day.id}/students/${student.id}/list`}
                                                        label={`Termine für ${student.first_name}`}/>
                                                )}
                                            </>}

                                        </div>}

                                </div>
                            </div>
                        </div>

                    </div>
                </main>
            )}
        </>
    );
}


function CustomSelect({
                          path,
                          label,
                          options,
                          valueAttr,
                          labelAttr,
                          helpText,
                          emptyLabel,
                          className,
                          errors,
                          data,
                          onChange,
                          disabled,
                          valueOnly,
                          ...rest
                      }) {
    function handleSelect(selectedOption) {
        if (!selectedOption)
            return onChange({target: {name: path, value: null}});
        let value;
        if (valueOnly) value = selectedOption[valueAttr];
        else value = selectedOption;
        return onChange({target: {name: path, value}});
    }

    const value = data
    const selectedOption = data ?
        options &&
        (valueOnly
            ? options.find((option) => option[valueAttr] === value)
            : options.find((option) => option[valueAttr] === value[valueAttr]))
        : null
    ;

    return (
        <div className={className}>
            {label ? (
                <label
                    htmlFor={path}
                    className="block text-sm font-medium text-gray-900"
                >
                    {label}
                </label>
            ) : null}
            <ReactSelect
                options={options}
                classNamePrefix={path}
                onChange={handleSelect}
                value={selectedOption || ""}
                getOptionValue={(option) => option[valueAttr]}
                getOptionLabel={(option) => option[labelAttr]}
                placeholder={emptyLabel || "Auswählen"}
                isDisabled={disabled}
                {...rest}
            />
        </div>
    );
}

function RegistrationQuestion({question, handleChange}) {

    return (
        <div className="my-4">
            <CustomSelect
                path={question.id}
                label={question.question}
                className="sm:col-span-6"
                options={question.options}
                valueAttr="option_id"
                labelAttr="answer"
                onChange={handleChange}
                data={question.answers[0]}
                isClearable={false}
                valueOnly={false}
            />
        </div>
    )

}
