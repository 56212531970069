import {SimplePage} from "../../elements/Pages";
import React, {useState} from "react";

import {deleteAbsenceNotices, getAbsenceNotices,} from "../../../api/logbook";

import {useQuery} from "@tanstack/react-query";
import {useAuth} from "../../../contexts/AuthContext";
import ListViewTable, {TableEditLink,} from "../../elements/tables/ListViewTable";
import checkPermission from "../../../utils/permissions";
import {Link} from "react-router-dom";
import {classNames} from "../../../utils/misc";

const columns = [
  {
    accessorKey: "id",
    id: "select",
    columnType: "select",
  },
  {
    accessorFn: (row) =>
      `${row.student.last_name}, ${row.student.first_name} ${row.student.student_number}`,
    id: "student_search",
    header: "SchülerIn",
    hide: true,
    enableColumnFilter: false,
  },
  {
    accessorKey: "student",
    id: "student",
    header: "SchülerIn",
    columnType: "student",
    enableColumnFilter: false,
    sortingFn: "lastNameSorting",
  },
  {
    accessorKey: "student.house",
    id: "house",
    header: "Mentorat",
    enableColumnFilter: true,
    headerClassNames: "narrow-filter",
  },
  {
    accessorKey: "student.school_class",
    id: "school_class",
    header: "Klasse",
    headerClassNames: "narrow-filter",
  },
  {
    accessorKey: "start",
    id: "start",
    header: "Von",
    columnType: "date_time",
    enableColumnFilter: false,
    // classNames: 'font-medium text-gray-900'
    classNames: "text-gray-500",
  },
  {
    accessorKey: "end",
    id: "end",
    header: "Bis",
    columnType: "date_time",
    enableColumnFilter: false,
    // classNames: 'font-medium text-gray-900'
    classNames: "text-gray-500",
  },
  {
    accessorFn: (row) => (row.rule?.name ? `${row.rule.name}` : ""),
    id: "rule",
    header: "Wiederholung",
    enableColumnFilter: false,
    // classNames: 'font-medium text-gray-900'
    classNames: "text-gray-500",
  },
  {
    accessorFn: (row) => (row.reason ? `${row.reason.name}` : ""),
    id: "reason",
    header: "Grund",
    cell: (info) => info.getValue(),
    enableColumnFilter: true,
    headerClassNames: "medium-filter",
  },
  {
    accessorKey: "notes",
    id: "notes",
    header: "Anmerkungen",
    cell: (info) => info.getValue(),
    enableColumnFilter: false,
    classNames: "max-w-md text-gray-500",
    headerClassNames: "max-w-md",
  },
  {
    accessorKey: "created_by_full_name",
    id: "created_by_full_name",
    header: "Eingetragen von",
    enableColumnFilter: true
  },
  {
    accessorKey: "id",
    id: "link",
    cell: (info) => (
      <TableEditLink iconOnly={true}
        label="Bearbeiten"
        to={`/logbook/absence-notices/${info.getValue()}/edit`}
      />
    ),
    header: "Bearbeiten",
    enableColumnFilter: false,
    disableRowLink: true
  },
];

export default function AbsenceNotices() {
  const { permissions } = useAuth();

  const [futureOrToday, setFutureOrToday] = useState("today");
  const [rowSelection, setRowSelection] = useState([]);

  const { isLoading, isError, isSuccess, data, error, refetch } = useQuery(
    ["absence_notices", futureOrToday],
    () => getAbsenceNotices(futureOrToday),
      {refetchInterval: 1000 * 60 * 60}
  )

  async function handleDelete() {
    await deleteAbsenceNotices(rowSelection);
    refetch();
  }

  return (
    <SimplePage>
      {/*  Simple striped table */}
      <div className="px-4 sm:px-6 lg:px-8">
        <div className="sm:flex sm:items-center">
          <div className="sm:flex-auto">
            <h3 className="text-lg leading-6 font-medium text-gray-900">
              Abwesenheitsmeldungen
            </h3>
          </div>
          <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
            {checkPermission(
              permissions,
              "student_logbook.add_absencenotice"
            ) && (
              <Link to="/logbook/absence-notices/new">
                <button
                  type="button"
                  className="inline-flex items-center justify-center rounded-md border border-transparent bg-imsblue-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-imsblue-700 focus:outline-none focus:ring-2 focus:ring-imsblue-500 focus:ring-offset-2 sm:w-auto"
                >
                  Neuer Eintrag
                </button>
              </Link>
            )}
          </div>
        </div>
        <div className="flex flex-col">
          <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
              <nav className="flex space-x-4" aria-label="Tabs">
                <a
                  key="future"
                  onClick={() => setFutureOrToday("future")}
                  className={classNames(
                    futureOrToday === "future"
                      ? "bg-imsblue-100 text-imsblue-700"
                      : "text-gray-500 hover:text-gray-700",
                    "px-3 py-2 font-medium text-sm rounded-md cursor-pointer"
                  )}
                  aria-current={futureOrToday === "future" ? "page" : undefined}
                >
                  Alle zukünftigen
                </a>
                <a
                  key="today"
                  onClick={() => setFutureOrToday("today")}
                  className={classNames(
                    futureOrToday === "today"
                      ? "bg-imsblue-100 text-imsblue-700"
                      : "text-gray-500 hover:text-gray-700",
                    "px-3 py-2 font-medium text-sm rounded-md cursor-pointer"
                  )}
                  aria-current={futureOrToday === "today" ? "page" : undefined}
                >
                  Heute
                </a>
              </nav>
            </div>

            <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
              {isSuccess && data && (
                <ListViewTable
                  data={data}
                  columns={columns}
                  isLoading={isLoading}
                  disableGlobalSearch={false}
                  rowSelection={rowSelection}
                  setRowSelection={setRowSelection}
                  onDelete={handleDelete}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </SimplePage>
  );
}
