import React from "react";
import { useEvents } from "../../contexts/EventsContext";
import { useRoomBookings} from "../../contexts/RoomBookingsContext";
import { classNames } from "../../utils/misc";

const CalendarMonthFilter = ({ items, selectedValue, providerFunction }) => {

  const { view, setSelectedDate, selectedDate } = providerFunction();
  // const { view, setSelectedDate, selectedDate } = useEvents();

  return (
    <div className="py-2 pt-4 min-w-full align-middle md:px-4 ">
      <div className="sm:hidden">
        <label htmlFor="tabs" className="sr-only text-capitalize">
          {view}
        </label>
        {/* Use an "onChange" listener to redirect the user to the selected tab URL. */}
        <select
          id="tabs"
          name="tabs"
          className="block w-full rounded-md border-gray-300 focus:border-imsblue-500 focus:ring-imsblue-500"
          value={selectedValue}
          onChange={(e) => {
            const monthYear = e.target.value.split("-");
            setSelectedDate({
              ...selectedDate,
              month: parseInt(monthYear[1]),
              year: parseInt(monthYear[0]),
            });
          }}
        >
          {items.map((item) => (
            <option key={item.id} value={`${item.year}${item.id}`}>
              {item.name}
            </option>
          ))}
        </select>
      </div>
      <div className="hidden sm:block">
        <nav className="flex space-x-4 flex-wrap" aria-label="Tabs">
          {items.map((item) => (
            <a
              key={item.id}
              onClick={() =>
                setSelectedDate({
                  ...selectedDate,
                  month: item.id,
                  year: item.year,
                })
              }
              className={classNames(
                `${item.year}-${item.id}` === selectedValue
                  ? "bg-imsblue-100 text-imsblue-700"
                  : "text-gray-500 hover:text-gray-700",
                "px-3 py-2 font-medium text-sm rounded-md cursor-pointer"
              )}
              aria-current={item.id === selectedValue ? "page" : undefined}
            >
              {item.name}
            </a>
          ))}
        </nav>
      </div>
    </div>
  );
};

export default CalendarMonthFilter;
