import React, {useContext, useEffect, useState} from "react";
import {useQuery} from "@tanstack/react-query";
import {getUserImages} from "../api/accounts";
import {useAuth} from "./AuthContext";

const UserInfoContext = React.createContext();

export function useUserInfo() {
    return useContext(UserInfoContext);
}

export function UserInfoProvider({ children }) {
    const {currentUserId} = useAuth()
    const loggedIn = currentUserId ? true : false;

    const { isLoading, data: userImages, isError } =
        useQuery(['user_images'], getUserImages, {
            enabled: loggedIn,
            refetchInterval: 1000 * 60 * 60 * 24 }
        );

    // State to trigger re-render
    const [images, setImages] = useState([]);

    useEffect(() => {
        if (userImages) {
            setImages(userImages);
        }
    }, [userImages]);

    const renderUserImage = (userId, size=40) => {

        if (!images || !images.sprite_positions || !images.sprite_image) return null; // Ensure images are loaded
        const userImagePosition = images.sprite_positions[userId]; // Get the position for the userId
        if (!userImagePosition) {
            return (
                <div style={{ width: `${size}px`, height: `${size}px`, borderRadius: '50%', overflow: 'hidden', display: 'inline-block' }}>
                    <img
                        src="/img/avatar.png"
                        style={{ width: `${size}px`, height: `${size}px`, display: 'inline-block' }}
                        className="user-image"
                        alt=""
                    />
                </div>
            );

        } // Ensure the user's image position is found

        // Original width of each image in the sprite
        const imageOriginalWidth = images.sprite_info.image_width;

        // Calculate the scale factor to resize each image to the desired 'size'
        const scaleFactor = size / imageOriginalWidth;
        // Calculate the new total width of the sprite after scaling
        const scaledSpriteWidth = images.sprite_info.sprite_width * scaleFactor;
        // Calculate the scaled height assuming the sprite sheet is a single row
        const scaledSpriteHeight = size; // Since we're scaling each image to be 'size' high

        // Calculate the background position, taking into account the scaling
        const backgroundPositionX = -userImagePosition[0] * scaleFactor;
        const backgroundPositionY = userImagePosition[1]; // Assuming no vertical scaling if sprite is a single row

        return (
            <div style={{ width: `${size}px`, height: `${size}px`, borderRadius: '50%', overflow: 'hidden', display: 'inline-block' }}>
                <div
                    style={{
                        width: `${size}px`, // Display width of the individual image
                        height: `${size}px`, // Display height of the individual image
                        backgroundImage: `url(${images.sprite_image})`,
                        backgroundPosition: `${backgroundPositionX}px ${backgroundPositionY}px`,
                        backgroundSize: `${scaledSpriteWidth}px ${scaledSpriteHeight}px`,
                        display: 'inline-block',
                    }}
                    className="user-image"
                    alt=""
                />
            </div>
        );
    };

    const value = {
        renderUserImage,
        userImages: images,
        isLoading,
        isError
    };

    return (
        <UserInfoContext.Provider value={value}>
            {children}
        </UserInfoContext.Provider>
    );
}

export function UserImage({userId, size = 10}) {
    const {renderUserImage, userImages, isLoading, isError} = useUserInfo();
    if (isLoading || isError) return <></>;
    return userImages ? renderUserImage(userId, size) : null;
}