import axios from "axios";
import {toast} from "react-toastify";
import config from "../constants";
import {ref} from "joi";

const refreshUrl = '/accounts/token/refresh/';


const axiosInstance = axios.create({
    baseURL: config.API_URL,
    timeout: 50000,
    headers: {
        'Authorization': "Bearer " + localStorage.getItem('access_token'),
        'Content-Type': 'application/json',
        'accept': 'application/json'
    }
});

axiosInstance.interceptors.response.use(null, async error => {

    if (error.message === 'Network Error') {
        // this occurs when the API isn't online/reachable - how to handle?
        toast.error("Es ist ein Fehler aufgetreten.");
        return Promise.reject(error);
    }
    // if unauthorized, refresh the token
    if (error.response.status === 401) {
        // console.log('auth error')
        const originalRequest = error.config;
        if (originalRequest['url'] !== refreshUrl) {
            return await refreshToken(error, originalRequest)
        } else {
            // force logout if still not authenticated
            localStorage.removeItem('access_token');
            localStorage.removeItem('refresh_token');
        }
    } else {
        throw error.response
    }

    const expectedError =
        error.response &&
        error.response.status >= 400 &&
        error.response.status < 500;

    if (!expectedError) {
        // logger.log(error);
        toast.error("Es ist ein Fehler aufgetreten.");
    }

    return Promise.reject(error);
});

function setJwt(jwt) {
    axiosInstance.defaults.headers['Authorization'] = "Bearer " + jwt;
}

function removeJwt(jwt) {
    axiosInstance.defaults.headers['Authorization'] = null;
}

function refreshToken(error, originalRequest) {

    const refresh_token = localStorage.getItem('refresh_token');
    if (!refresh_token){
        localStorage.removeItem('access_token');
        localStorage.removeItem('refresh_token');
        removeJwt()

        if(window.location.pathname !== '/login'){
            window.location = '/login'
        }
        
        return
    }

    return axiosInstance
        .post(refreshUrl, {refresh: refresh_token})
        .then((response) => {
            localStorage.setItem('access_token', response.data.access);
            // localStorage.setItem('refresh_token', response.data.refresh); // we don't change the refresh token because it does not rotate.
            axiosInstance.defaults.headers['Authorization'] = "Bearer " + response.data.access;
            if (originalRequest) {
                originalRequest.headers['Authorization'] = "Bearer " + response.data.access;
                return axiosInstance(originalRequest);
            }
        })
        .catch(err => {
            if (err.response && (err.response.status === 401 || err.response.status === 400)) {
                removeJwt();
                localStorage.removeItem('access_token');
                localStorage.removeItem('refresh_token');
                window.location = '/login'
            }
        });
}

const httpService = {
    get: axiosInstance.get,
    post: axiosInstance.post,
    put: axiosInstance.put,
    patch: axiosInstance.patch,
    delete: axiosInstance.delete,
    options: axiosInstance.options,
    setJwt,
    removeJwt,
    refreshToken
}

export default httpService;
