import {SimplePage} from "../../elements/Pages";
import React from "react";

import {useQuery} from "@tanstack/react-query";
import {useAuth} from "../../../contexts/AuthContext";
import ListViewTable, {TableLink} from "../../elements/tables/ListViewTable";
import checkPermission from "../../../utils/permissions";
import {formatDate} from "../../../utils/formatters";
import {getWorkshopDays} from "../../../api/workshop_day";


export default function WorkshopDays() {

    const {permissions} = useAuth();

    const {isLoading, isSuccess, data} = useQuery(
        ["workshop_days"],
        getWorkshopDays,
        {refetchInterval: 1000 * 60 * 60 * 24}
    );

    function getRowLink(row) {
        return `/workshop-day/${row.original.id}/workshops`;
    }

    const canViewStudents = checkPermission(permissions, 'workshop_day.view_workshopday')

    let columns = [
        {
            accessorKey: "title",
            id: "title",
            header: "Titel",
            enableColumnFilter: false
        },
        {
            accessorFn: (row) => `${formatDate(row.datetime_from)} - ${formatDate(row.datetime_to)}`,
            id: "date",
            header: "Datum",
            enableColumnFilter: false,
        },
        {
            accessorFn: (row) => `${formatDate(row.booking_open_from)} - ${formatDate(row.booking_open_to)}`,
            id: "booking_open",
            header: "Anmeldung",
            enableColumnFilter: false,
        },
        {
            accessorKey: "id",
            id: "link",
            cell: (info) => (
                <div className="flex gap-2">
                    <TableLink
                        label="Meine Buchungen"
                        to={`/workshop-day/${info.getValue()}/my`}
                    />
                    {canViewStudents && <TableLink
                        label="Schüler"
                        to={`/workshop-day/${info.getValue()}/students`}
                    />}
                </div>

            ),
            header: "Links",
            enableColumnFilter: false,
            disableRowLink: true
        },
    ];

    return (
        <SimplePage>
            <div className="px-4 sm:px-6 lg:px-8">
                <div className="sm:flex sm:items-center">
                    <div className="sm:flex-auto">
                        <h3 className="text-lg leading-6 font-medium text-gray-900 mb-2">
                            Anstehende Workshop-Tage
                        </h3>
                    </div>
                    <div className="mt-4 sm:mt-0 sm:ml-16 flex gap-2">
                    </div>
                </div>
                <div className="flex flex-col">
                    <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">

                        <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                            {isSuccess && data && (
                                <ListViewTable
                                    data={data}
                                    columns={columns}
                                    isLoading={isLoading}
                                    disableGlobalSearch={false}
                                    getRowLink={getRowLink}
                                />
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </SimplePage>
    );
}
