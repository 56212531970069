import {Menu, Transition} from "@headlessui/react";
import {ChevronLeftIcon, ChevronRightIcon, EllipsisHorizontalIcon,} from "@heroicons/react/20/solid";
import React, {Fragment, useEffect} from "react";
import {classNames} from "../../../utils/misc";
import CalendarMonthFilter from "../../elements/CalendarMonthFilter";
import {Select} from "../../elements/forms/Inputs";
import BasicModal from "../../elements/modal";
import {PrimaryButton} from "../../elements/Buttons";
import {useDeviceBookings} from "../../../contexts/DeviceBookingsContext";
import DeviceBookingDetail from "./DeviceBookingDetail";
import {months} from "./../../calendar/calendarUtils"
import {useParams} from "react-router-dom";

const DeviceBookingCalendarContainer = ({
                                            children,
                                            setView,
                                            view,
                                            nextPrev,
                                            selectedDate,
                                        }) => {
    const {
        eventDetailOpen,
        setEventDetailOpen,
        devices,
        filter: data,
        setFilter: setData,
    } = useDeviceBookings();

    const {deviceId} = useParams();

    useEffect(() => {
        setData({
            ...data,
            device: parseInt(deviceId),
        })
    }, [deviceId])

    function handleSelect(event, key) {
        const value = event.target.value
        setData({
            ...data,
            [key]: value,
        })
    }

    return (
        <>
            <BasicModal open={eventDetailOpen} setOpen={setEventDetailOpen}>
                <DeviceBookingDetail/>
            </BasicModal>
            <div className="flex h-full flex-col">
                <CalendarMonthFilter
                    items={months}
                    selectedValue={`${selectedDate.year}-${selectedDate.month}`}
                    providerFunction={useDeviceBookings}
                />
                <header className="flex flex-none items-center justify-between border-b border-gray-200 py-4 px-6">
                    <h1 className="text-lg font-semibold text-gray-900">
                        <time dateTime="2022-01">
                            {months.find((i) => i.id === selectedDate.month)?.name}{" "}
                            {selectedDate.year}
                        </time>
                    </h1>
                    <div className="flex items-center">
                        <div className="sm:ml-4 sm:flex sm:items-center w-40">
                            <Select
                                path="device"
                                label="Gerät"
                                emptyLabel="Gerät"
                                className="w-full hide-label"
                                options={devices}
                                valueAttr="id"
                                labelAttr="name"
                                onChange={(value) => handleSelect(value, "device")}
                                data={data}
                                isClearable={true}
                                valueOnly={true}
                            />
                        </div>
                        <div className="flex gap-2 items-center rounded-md shadow-sm md:items-stretch md:ml-4">
                            <button
                                type="button"
                                onClick={() => nextPrev("prev")}
                                className="flex items-center justify-center rounded border border-gray-300 bg-white py-2 pl-3 pr-4 text-gray-400 hover:text-gray-500 focus:relative md:w-9 md:px-2 md:hover:bg-gray-50"
                            >
                                <span className="sr-only">Previous {view}</span>
                                <ChevronLeftIcon className="h-5 w-5" aria-hidden="true"/>
                            </button>
                            <button
                                type="button"
                                onClick={() => nextPrev("curr")}
                                className="hidden border rounded border-gray-300 bg-white px-3.5 text-sm font-medium text-gray-700 hover:bg-gray-50 hover:text-gray-900 focus:relative md:block"
                            >
                                Heute
                            </button>
                            <span className="relative -mx-px h-5 w-px bg-gray-300 md:hidden"/>
                            <button
                                type="button"
                                onClick={() => nextPrev("next")}
                                className="flex items-center justify-center rounded border border border-gray-300 bg-white py-2 pl-4 pr-3 text-gray-400 hover:text-gray-500 focus:relative md:w-9 md:px-2 md:hover:bg-gray-50"
                            >
                                <span className="sr-only">Next {view}</span>
                                <ChevronRightIcon className="h-5 w-5" aria-hidden="true"/>
                            </button>
                        </div>
                        <div className="hidden md:ml-4 md:flex md:items-center">
                            <div className="h-6 w-px bg-gray-300"/>
                            <PrimaryButton
                                link={`/service/device-bookings/new` + (data.device ? `/${data.device}` : '')}
                                label="Gerät buchen"/>
                        </div>
                        <Menu as="div" className="relative ml-6 md:hidden">
                            <Menu.Button
                                className="-mx-2 flex items-center rounded-full border border-transparent p-2 text-gray-400 hover:text-gray-500">
                                <span className="sr-only">Open menu</span>
                                <EllipsisHorizontalIcon
                                    className="h-5 w-5"
                                    aria-hidden="true"
                                />
                            </Menu.Button>

                            <Transition
                                as={Fragment}
                                enter="transition ease-out duration-100"
                                enterFrom="transform opacity-0 scale-95"
                                enterTo="transform opacity-100 scale-100"
                                leave="transition ease-in duration-75"
                                leaveFrom="transform opacity-100 scale-100"
                                leaveTo="transform opacity-0 scale-95"
                            >
                                <Menu.Items
                                    className="absolute right-0 z-10 mt-3 w-36 origin-top-right divide-y divide-gray-100 overflow-hidden rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                                    <div className="py-1">
                                        <Menu.Item>
                                            {({active}) => (
                                                <a
                                                    href="#"
                                                    className={classNames(
                                                        active
                                                            ? "bg-gray-100 text-gray-900"
                                                            : "text-gray-700",
                                                        "block px-4 py-2 text-sm"
                                                    )}
                                                >
                                                    Gerät buchen
                                                </a>
                                            )}
                                        </Menu.Item>
                                    </div>
                                    <div className="py-1">
                                        <Menu.Item>
                                            {({active}) => (
                                                <a
                                                    href="#"
                                                    className={classNames(
                                                        active
                                                            ? "bg-gray-100 text-gray-900"
                                                            : "text-gray-700",
                                                        "block px-4 py-2 text-sm"
                                                    )}
                                                    onClick={() => nextPrev("curr")}
                                                >
                                                    Heute
                                                </a>
                                            )}
                                        </Menu.Item>
                                    </div>
                                    <div className="py-1">
                                        <Menu.Item>
                                            {({active}) => (
                                                <a
                                                    href="#"
                                                    className={classNames(
                                                        active
                                                            ? "bg-gray-100 text-gray-900"
                                                            : "text-gray-700",
                                                        "block px-4 py-2 text-sm"
                                                    )}
                                                    onClick={() => setView("week")}
                                                >
                                                    Woche
                                                </a>
                                            )}
                                        </Menu.Item>
                                        <Menu.Item>
                                            {({active}) => (
                                                <a
                                                    href="#"
                                                    className={classNames(
                                                        active
                                                            ? "bg-gray-100 text-gray-900"
                                                            : "text-gray-700",
                                                        "block px-4 py-2 text-sm"
                                                    )}
                                                    onClick={() => setView("month")}
                                                >
                                                    Monat
                                                </a>
                                            )}
                                        </Menu.Item>
                                    </div>
                                </Menu.Items>
                            </Transition>
                        </Menu>
                    </div>
                </header>
                {children}
            </div>
        </>
    );
};

export default DeviceBookingCalendarContainer;
