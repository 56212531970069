import {Link, useParams} from "react-router-dom";
import {React} from "react";
import {useQuery} from "@tanstack/react-query";
import {getStudentInfo} from "../../../api/school";
import {getHouseStudentDetail} from "../../../api/boarding_school";
import {useUserInfo} from "../../../contexts/UserInfoContext";


export default function HouseStudentDetail() {

    const { studentId } = useParams();
    const {renderUserImage} = useUserInfo()

    const { data: student } = useQuery(["student_info", studentId], () =>
        getStudentInfo(studentId),
        {refetchInterval: 1000 * 60 * 60 * 24}
    )

    const { data: houseDetails } = useQuery(
        ["house_student_detail", studentId],
        () => getHouseStudentDetail(studentId)
    );

    if (!houseDetails || !student) return <></>;

    return (
        <>
            <main className="py-10 h-full bg-gray-100">
                {/* Page header */}
                <div
                    className="max-w-3xl mx-auto px-4 sm:px-6 md:flex md:items-center md:justify-between md:space-x-5 lg:max-w-7xl lg:px-8">
                    <div className="flex items-center space-x-5">
                        <div>
                            <h1 className="text-2xl font-bold text-gray-900">
                                Mentorat von {student.full_name} - {student.house}
                            </h1>
                        </div>
                    </div>
                    <div
                        className="mt-6 flex flex-col-reverse justify-stretch space-y-4 space-y-reverse sm:flex-row-reverse sm:justify-end sm:space-x-reverse sm:space-y-0 sm:space-x-3 md:mt-0 md:flex-row md:space-x-3">
                    </div>
                </div>

                <div
                    className="mt-8 max-w-3xl mx-auto grid grid-cols-1 gap-6 sm:px-6 lg:max-w-7xl lg:grid-flow-col-dense lg:grid-cols-3">
                    <div className="space-y-6 lg:col-start-1 lg:col-span-2">

                        <section aria-labelledby="applicant-information-title">

                            {houseDetails && houseDetails.map((mentor, index) => (

                                <Link key={index} to={`/staff/${mentor.id}`}>
                                <div  className="bg-white shadow sm:rounded-lg mb-4">

                                    <div className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-4 px-4 py-5 sm:px-6">

                                        <div className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-1 sm:col-span-1">
                                            {renderUserImage(mentor.user_id, 80)}
                                        </div>

                                        <dl className="grid grid-cols-1 gap-x-4 gap-y-4 sm:grid-cols-2 sm:col-span-3">

                                            <div className="sm:col-span-2">
                                                <dt className="text-md font-medium text-gray-500">{mentor.full_name}</dt>
                                                {mentor.is_main_mentor && <dt className="text-sm font-medium text-gray-500">
                                                    StammentorIn
                                                </dt>}
                                            </div>

                                            <div className="sm:col-span-1">
                                                <dt className="text-sm font-medium text-gray-500">Email</dt>
                                                <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                                    {mentor.email}
                                                </dd>
                                            </div>
                                            <div className="sm:col-span-1">
                                                <dt className="text-sm font-medium text-gray-500">Telefon dienstlich</dt>
                                                <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                                    {mentor.phone_work}
                                                </dd>
                                            </div>

                                        </dl>

                                    </div>

                                </div>
                                </Link>
                            ))}


                        </section>

                    </div>


                </div>

            </main>
        </>
    );
}
