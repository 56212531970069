// Validation for forms
import _ from "lodash";
import Joi from 'joi'
import {deErrors} from "./joiMessages";

const options = {
    abortEarly: false,
    errors : {
        label: false,
        language: 'de'
    },
    // locale: 'de_DE',
    allowUnknown: true,
    messages: {
        de: { ...deErrors }
    }
};

function validateItem(schema, dataItem) {
    const {error} = Joi.object(schema).validate(dataItem, options);
    if (!error) return null;
    let errors = {}
    for (let item of error.details) {
        errors[item.path[0]] = item.message
    }
    if (errors) return errors;
    return null;
}

export function validate(schema, data) {
    let errors = []
    if (Array.isArray(data)) {
        for (var i = 0; i < data.length; i++) {
            const rowErrors =  validateItem(schema, data[i])
            if (rowErrors) {
                errors.push({
                    id: data[i].id,
                    errors: rowErrors
                })
            }
        }
        if (errors.length > 0) return errors
        return null
    }

    return validateItem(schema, data)
}

// TODO might have to adjust this for lists as well
export function validateProperty(input, schema, data) {

    const {name, value} = input
    const obj = {[name]: value};

    let baseSchema;
    let propertySchema;

    baseSchema = schema;
    propertySchema = _.get(schema, name);

    // if no schema for that key, ignore
    if (!propertySchema) return null;

    const schemaTmp = {[name]: propertySchema};

    // special logic for fields with references - could probably make this generic using the actual ref
    if (name === "confirm_password") {
        obj["password"] = data["password"];
        schemaTmp["password"] = baseSchema["password"];
    }

    const {error} = Joi.object(schemaTmp).validate(obj, options);
    return error ? error.details[0].message : null;
}


export function validateDateProperty(date, path, schema, data) {

    // console.log('validateDateProperty', date, path)

    const obj = {[path]: date};

    let baseSchema;
    let propertySchema;

    baseSchema = schema;
    propertySchema = _.get(schema, path);

    // if no schema for that key, ignore
    if (!propertySchema) return null;

    const schemaTmp = {[path]: propertySchema};

    // special logic for fields with references - could probably make this generic using the actual ref
    if (path === "confirm_password") {
        obj["password"] = data["password"];
        schemaTmp["password"] = baseSchema["password"];
    }

    const {error} = Joi.object(schemaTmp).validate(obj, options);
    return error ? error.details[0].message : null;
}
