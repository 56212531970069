import {SimplePage} from "../../elements/Pages";
import React from "react";

import {useQuery} from "@tanstack/react-query";
import ListViewTable, {TableLink} from "../../elements/tables/ListViewTable";
import {getAttendanceStudents, getProjectAttendances, getProjectStudents} from "../../../api/projects";
import {DeleteButton} from "../../elements/DeleteButton";
import {EditButton} from "../../elements/Buttons";

const columns = [
    // {
    //     accessorFn: (row) =>
    //         `${row.student.last_name}, ${row.student.first_name} ${row.student.student_number}`,
    //     id: "student_search",
    //     header: "SchülerIn",
    //     hide: true,
    //     enableColumnFilter: false,
    // },
    // {
    //     // accessorFn: (row) => row,
    //     accessorKey: "student",
    //     id: 'student',
    //     header: 'SchülerIn',
    //     columnType: 'student',
    //     enableColumnFilter: false,
    //     sortingFn: "lastNameSorting",
    // },
    {
        accessorKey: "date",
        id: "date",
        header: "Datum",
        columnType: "date",
        enableColumnFilter: false,
        headerClassNames: "narrow-filter",
    },
    {
        accessorKey: "project.name",
        id: "project",
        header: "Projekt",
        enableColumnFilter: true,
        headerClassNames: "medium-filter",
    },
    {
        accessorKey: "cancelled",
        id: "cancelled",
        header: "Ausfall",
        columnType: "yesno",
        filterFn: "boolean",
        enableColumnFilter: true,
        headerClassNames: "narrow-filter",
    },

    {
        accessorKey: "project.project_leaders",
        id: "project_leaders",
        header: "Projektleiter",
        columnType: "m2m",
        labelAttr: "full_name",
        enableColumnFilter: false,
        filterFn: "containsString",
        headerClassNames: "medium-filter",
    },
    {
        accessorKey: "creator_full_name",
        id: "creator_full_name",
        header: "Erfasst von",
        enableColumnFilter: true,
        headerClassNames: "medium-filter",
    },
    {
        accessorKey: "id",
        id: "link",
        cell: ({row, value}) => (
             <EditButton iconOnly={true} link={`/projects/attendance/${row.original.id}/edit`}/>
        ),
        header: "Bearbeiten",
        enableColumnFilter: false,
        disableRowLink: true,
    }
];

export default function AttendanceList() {

    const {isLoading, isError, isSuccess, data, error, refetch} = useQuery(
        ["attendance_projects"],
        () => getProjectAttendances(),
        {refetchInterval: 1000 * 60 * 60}
    );

    function getRowLink(row) {
        return `/projects/attendance/projects/${row.original.project.id}/`;
    }

    return (
        <SimplePage>
            <div className="px-4 sm:px-6 lg:px-8">
                <div className="sm:flex sm:items-center">
                    <div className="sm:flex-auto">
                        <h3 className="text-lg leading-6 font-medium text-gray-900">
                            Projektanwesenheit nach Projekt
                        </h3>
                    </div>
                    <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
                    </div>
                </div>
                <div className="flex flex-col">
                    <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
                        <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                            {isSuccess && data && (
                                <ListViewTable
                                    data={data}
                                    columns={columns}
                                    isLoading={isLoading}
                                    disableGlobalSearch={false}
                                    getRowLink={getRowLink}
                                />
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </SimplePage>
    );
}
