import {React} from "react";

import {getUserInfo} from '../../api/accounts'
import {useQuery} from '@tanstack/react-query'
import {Link} from "react-router-dom";

import {LazyLoadImage} from "react-lazy-load-image-component";

function UserName({userId, link=false}) {

    // todo load the user info or look it up in the cache / context etc.
    const {data: user} = useQuery(['user_info', userId], () => getUserInfo(userId))

    if (!user) return <></>

    if (link) {
        return <Link className="text-imsblue-600 hover:text-imsblue-900" to={`/users/${user.id}`}>{user.full_name}</Link>
    }

    return (
        <>{user.full_name}</>
    )

}

export {
    UserName
}