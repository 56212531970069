import {NarrowPage} from "../../elements/Pages";
import {React, useEffect, useState} from "react";
import Joi from "joi"
import {useQuery} from '@tanstack/react-query'
import {DateInput, Input, MultiSelect, Select, TextArea} from "../../elements/forms/Inputs"
import {FormSectionContent} from "../../elements/forms/FormLayout"
import Form, {baseHandleChange, baseHandleDateChange} from "../../elements/forms/Form"
import {useNavigate, useParams} from "react-router-dom";
import {toast} from "react-toastify";
import {
    addFileToDownload,
    deleteDownload,
    getDownload,
    getDownloadCategories,
    patchDownload,
    postDownload
} from "../../../api/downloads";
import {getGroups} from "../../../api/accounts";
import {useSchoolYears} from "../../../contexts/SchoolYearContext";
import {
    addFileToTutoringMaterial, getTutoringMaterial, updateTutoringMaterial,
    deleteTutoringMaterial,
    createTutoringMaterial, getTutoring, patchTutoring, getStatusOptions
} from "../../../api/tutoring";


export default function TutoringEdit() {

    const {id} = useParams()
    const [data, setData] = useState()
    const [errors, setErrors] = useState({}) // validation errors

    let navigate = useNavigate();

    useEffect(() => {
        loadData()
    }, [id])

    const {data: statusOptions} = useQuery(
        ["tutoring_status_options"],
        () => getStatusOptions(),
        {refetchInterval: 1000 * 60 * 60 * 24}
    )

    async function loadData() {
        if (!id) return
        const newData = await getTutoring(id)
        setData(newData)
    }

    const schema = {}

    const title = (id || data.id) ? "Fördermaßnahme bearbeiten" : "Neue Fördermaßnahme einstellen"
    const successMessage = (id || data.id) ? "Fördermaßnahme aktualisiert" : "Neue Fördermaßnahme eingestellt"

    async function onSave() {
        const newData = await patchTutoring(data)
        setData(newData)
        toast.success(successMessage)
        navigate(`/tutoring/${newData.id}`)
    }

    async function onDelete() {
        await deleteTutoringMaterial(data.id)
        toast.success("Fördermaßnahme gelöscht.")
        navigate(`/tutoring/`)
    }

    function handleChange(evt) {
        baseHandleChange(evt, data, setData, errors, setErrors, schema)
    }

    if (!data) return <></>
    return (

        <NarrowPage title={title}>

            <Form id="editTutoring" onSave={onSave}
                  onDelete={onDelete}
                  data={data} setData={setData}
                  errors={errors} setErrors={setErrors}
                  successMessage={successMessage}
                  schema={schema}>

                <FormSectionContent>

                    <Select
                        path="status"
                        label="Status"
                        className="sm:col-span-6"
                        options={statusOptions}
                        valueAttr="value"
                        labelAttr="label"
                        onChange={handleChange}
                        errors={errors}
                        data={data}
                        isClearable={false}
                        valueOnly={true}
                    />

                    <TextArea
                        path="tutoring_plan"
                        label="Förderplan"
                        className="sm:col-span-6"
                        onChange={handleChange}
                        errors={errors}
                        data={data}
                        rows={10}
                    />

                </FormSectionContent>

            </Form>

            <span onClick={() => navigate(-1)}
                  className="cursor-pointer float-right mt-2 font-medium text-imsblue-600">Zurück zur Liste</span>

        </NarrowPage>
    )
}
