import {SimplePage} from "../../elements/Pages";
import React from "react";

import {useQuery} from "@tanstack/react-query";
import ListViewTable from "../../elements/tables/ListViewTable";
import {assessmentPeriodApi, getCoursesForAssessmentPeriod} from "../../../api/assessments";
import {useParams} from "react-router-dom";

const columns = [
    {
        accessorKey: "course_number",
        id: "course_number",
        header: "Nr.",
        enableColumnFilter: false,
        sortingFn: "schoolClassSorting",
    },
    {
        accessorKey: "school_subject",
        id: "school_subject",
        header: "Fach",
        enableColumnFilter: false,
    },
    {
        accessorFn: (row) => `${row.last_name}, ${row.first_name}`,
        id: "teacher",
        header: "LehrerIn",
        enableColumnFilter: true,
        filterFn: "containsString",
        labelAttr: "full_name",
    },
    {
        accessorKey: "finalized",
        id: "finalized",
        columnType: "number",
        header: "abgeschl.",
        enableColumnFilter: false,
    },
    {
        accessorKey: "open",
        id: "open",
        columnType: "number",
        header: "offen",
        enableColumnFilter: false
    },
    {
        accessorKey: "missing",
        id: "missing",
        columnType: "number",
        header: "fehl.",
        enableColumnFilter: false
    },
    {
        accessorKey: "num_students",
        id: "num_students",
        columnType: "number",
        header: "Schüler",
        enableColumnFilter: false,
    }
];

export default function Courses() {

    const {assessmentPeriodId} = useParams()

    const {data: assessmentPeriod} = useQuery(
        ["assessment_periods", assessmentPeriodId],
        () => assessmentPeriodApi('get', assessmentPeriodId),
        {}
    );

    const {isLoading, isError, isSuccess, data, error, refetch} = useQuery(
        ["courses_for_assessment_period", assessmentPeriodId],
        () => getCoursesForAssessmentPeriod(assessmentPeriodId),
        {refetchInterval: 1000 * 60 * 60 * 12}
    );

    function getRowLink(row) {
        return `/assessments/assessment-periods/${assessmentPeriodId}/courses/${row.original.id}/`
    }

    function getTrClassNames(row) {
        return row.original.missing > 0 ? 'bg-red-50' : row.original.open > 0 ? 'bg-yellow-50' : 'bg-green-50'
    }

    return (
        <SimplePage>
            {/*  Simple striped table */}
            <div className="px-4 sm:px-6 lg:px-8">
                <div className="sm:flex sm:items-center">
                    <div className="sm:flex-auto">
                        <h3 className="text-lg leading-6 font-medium text-gray-900 mb-2">
                            Kurse für {assessmentPeriod?.name}
                        </h3>
                    </div>
                    <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
                    </div>
                </div>
                <div className="flex flex-col">
                    <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">

                        <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                            {isSuccess && data && (
                                <ListViewTable
                                    data={data}
                                    columns={columns}
                                    isLoading={isLoading}
                                    disableGlobalSearch={false}
                                    rowSelection={null}
                                    setRowSelection={null}
                                    getRowLink={getRowLink}
                                    getTrClassNames={getTrClassNames}
                                />
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </SimplePage>
    );
}
