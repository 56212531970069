import {NarrowPage} from "../../elements/Pages";
import {React, useEffect, useState} from "react";
import {useQuery, useQueryClient} from '@tanstack/react-query'
import {MultiSelect, Select} from "../../elements/forms/Inputs"
import {FormSectionContent} from "../../elements/forms/FormLayout"
import {
    getLeadershipRoles,
    getStudentLeadershipRolesAll,
    getStudentLeadershipRoleStudentDetail,
    patchStudentLeadershipRoles
} from "../../../api/school";
import Form, {baseHandleChange} from "../../elements/forms/Form"
import {useNavigate, useParams} from "react-router-dom";
import {toast} from "react-toastify";

export default function LeadershipRoleStudentEdit() {

    const {studentId} = useParams()
    const queryClient = useQueryClient()
    let navigate = useNavigate()

    const [data, setData] = useState({
        id: null,
        leadership_roles: []
    })
    const [errors, setErrors] = useState({}) // validation errors

    const schema = {}

    const {data: students} = useQuery(["student_leadership_roles_all"], getStudentLeadershipRolesAll, {refetchInterval: 1000 * 60 * 60 * 24, enabled: !studentId})
    const {data: leadershipRoles} = useQuery(["leadership_roles"], getLeadershipRoles, {refetchInterval: 1000 * 60 * 60 * 24})

    useEffect(() => {
        loadData()
    }, [studentId])

    async function loadData() {
        if (studentId) {
            const newData = await getStudentLeadershipRoleStudentDetail(studentId)
            setData(newData)
        }
    }

    const title = (studentId || data.id) ? "Schülerämter bearbeiten" : "Schülerämter eintragen"
    const successMessage = (studentId || data.id) ? "Schülerämter aktualisiert" : "Schülerämter eingetragen"

    async function onSave() {
        const newData = await patchStudentLeadershipRoles(data.id, data)
        setData(newData)
        queryClient.invalidateQueries({ queryKey: ['student_leadership_roles'] })
        toast.success(successMessage)
        navigate(`/school/students/leadership-roles`)
    }

    function handleChange(evt) {
        baseHandleChange(evt, data, setData, errors, setErrors, schema)
    }
    function handleMultiSelect(selectedOptions, path) {
        let newData = {...data}
        newData[path] = selectedOptions
        setData(newData)
    }

    return (

        <NarrowPage title={title}>

            <Form id="studentLeadershipRoles" onSave={onSave}
                  data={data} setData={setData}
                  errors={errors} setErrors={setErrors}
                  successMessage={successMessage}
                  schema={schema}>

                <FormSectionContent>

                    {studentId && <div className="sm:col-span-6 text-lg">{data.full_name}</div>}

                    {!studentId && <Select
                        path="id"
                        label="SchülerIn"
                        className="sm:col-span-6"
                        options={students}
                        valueAttr="id"
                        labelAttr="full_name"
                        onChange={handleChange}
                        errors={errors}
                        data={data}
                        isClearable={false}
                        valueOnly={true}
                    />}

                    <MultiSelect
                        path="leadership_roles"
                        label="Amt"
                        emptyLabel="Amt"
                        className="sm:col-span-6 w-full hide-label"
                        options={leadershipRoles}
                        valueAttr="id"
                        labelAttr="name"
                        onChange={(value) => handleMultiSelect(value, "leadership_roles")}
                        data={data}
                        isClearable={true}
                        valueOnly={false}
                    />

                </FormSectionContent>

            </Form>

            <span onClick={() => navigate(-1)}
                  className="cursor-pointer float-right mt-2 font-medium text-imsblue-600">Zurück</span>

        </NarrowPage>
    )
}
