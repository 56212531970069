import {SimplePage} from "../../elements/Pages";
import React from "react";

import {useQuery} from "@tanstack/react-query";
import ListViewTable, {TableLink} from "../../elements/tables/ListViewTable";
import {DownloadButton} from "../../elements/Buttons";
import {getProjectAbsenceReasons, getProjectAbsencesReport, getProjectAbsencesReportExcel} from "../../../api/projects";


export default function ProjectAbsencesReport() {

    const {isLoading, isError, isSuccess, data, error, refetch} = useQuery(
        ["project_absences"],
        getProjectAbsencesReport, {refetchInterval: 1000 * 60 * 60}
    )

    const {data: reasons} = useQuery(
        ["project_absence_reasons"],
        getProjectAbsenceReasons,
        {refetchInterval: 1000 * 60 * 60}
    )

    let columns = [
        {
            accessorKey: "name",
            id: "student",
            header: "SchülerIn",
            // columnType: "student",
            enableColumnFilter: false,
            sortingFn: "lastNameSorting",
        },
        {
            accessorKey: "presence_count",
            id: "presence_count",
            header: "Anwesend",
            columnType: "number",
            enableColumnFilter: false,
        },
        {
            accessorKey: "absence_count",
            id: "absence_count",
            header: "Abwesend",
            columnType: "number",
            enableColumnFilter: false,
        },
        {
            accessorKey: "total_count",
            id: "total_count",
            header: "Gesamt",
            columnType: "number",
            enableColumnFilter: false,
        },
        {
            accessorKey: "unexcused_h1",
            id: "unexcused_h1",
            header: "Unent. H1",
            columnType: "number",
            enableColumnFilter: false,
        },
        {
            accessorKey: "unexcused_h2",
            id: "unexcused_h2",
            header: "Unent. H2",
            columnType: "number",
            enableColumnFilter: false,
        }
    ]

    if (!reasons) return <></>

    // add columns dynamically
    for (var i = 0; i < reasons.length; i++) {
        // console.log(reasons[i])
        columns.push({
            accessorKey: "reasons." + reasons[i].id,
            id: reasons[i].id,
            header: reasons[i].name,
            enableColumnFilter: false
        })
    }

    columns.push(
        {
            accessorKey: "pk",
                id: "attendance_link",
            cell: (info) => (
            <TableLink
                label="Details"
                to={`/projects/attendance/students/${info.getValue()}`}
            />
        ),
            header: "",
            enableColumnFilter: false,
            disableRowLink: true,
        }
    )

    function getRowLink(row) {
        return `/projects/students/${row.original.pk}`;
    }

    return (
        <SimplePage>
            {/*  Simple striped table */}
            <div className="px-4 sm:px-6 lg:px-8">
                <div className="sm:flex sm:items-center">
                    <div className="sm:flex-auto">
                        <h3 className="text-lg leading-6 font-medium text-gray-900">
                            Projektabwesenheit nach Schülern und Gründen
                        </h3>
                    </div>
                    <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
                        <DownloadButton
                            apiFunction={() => getProjectAbsencesReportExcel()}
                            fileName="Projektabwesenheiten.xls"
                            label="Excel herunterladen"/>

                    </div>
                </div>
                <div className="flex flex-col">
                    <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">

                        <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                            {isSuccess && data && (
                                <ListViewTable
                                    data={data}
                                    columns={columns}
                                    isLoading={isLoading}
                                    disableGlobalSearch={false}
                                    getRowLink={getRowLink}
                                />
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </SimplePage>
    );
}
