import React, {useEffect, useState} from "react";
import {useAuth} from "../../../contexts/AuthContext";
import checkPermission from "../../../utils/permissions";
import {useParams} from "react-router-dom";
import {
    createBooking,
    deleteBooking,
    getBooking,
    getStaff,
    getStudentAppointmentListPDF,
    getStudentSlots, removeBlockedSlot,
    updateBooking
} from "../../../api/parent_conference";
import {SimplePage} from "../../elements/Pages";
import {PencilIcon, PlusIcon, TrashIcon, XMarkIcon} from "@heroicons/react/20/solid";
import {toast} from "react-toastify";
import {DownloadButton, PrimaryButton} from "../../elements/Buttons";

import EditBookingModal from "./BookingEditModal";
import {useQuery, useQueryClient} from "@tanstack/react-query";

export default function StudentDetail() {

    const {studentId, conferenceDayId} = useParams()
    const {permissions} = useAuth()
    const queryClient = useQueryClient()
    const [data, setData] = useState()
    const [booking, setBooking] = useState()
    const [modalOpen, setModalOpen] = useState(false)
    const [bookingsLoading, setBookingsLoading] = useState(false)

    const canViewConferenceDay = checkPermission(permissions, "parent_conference.view_conferenceday")
    const canChangeConferenceDay = checkPermission(permissions, "parent_conference.change_conferenceday")

    const {isError, isSuccess, data: staff, error, refetch} = useQuery(
        ["parent_conference_day_staff", conferenceDayId],
        () => getStaff(conferenceDayId),
        {
            enabled: !!canViewConferenceDay, refetchInterval: 1000 * 60 * 60
        }
    )

    useEffect(() => {
        loadData()
    }, [])

    async function loadData() {
        setBookingsLoading(true)
        const newData = await getStudentSlots(conferenceDayId, studentId)
        setData(newData)
        setBookingsLoading(false)
    }

    async function handleRemoveBlockedSlot(slot, startTime) {

        try {
            await removeBlockedSlot(slot.blocked_slot_id, studentId)

            var newRows = {}
            for (const [key, value] of Object.entries(data.rows)) {
                const newColumns = value.columns.map(column => {
                    // if (column.teacher_id == newBooking.teacher_id) {
                        if (column.start_time == slot.start_time) {
                            // new booking for this slot
                            return {...column, booking_id: null, status: 'available'}
                        }
                        // deleted booking
                        // return {...column, booking_id: null, status: 'available'}
                    // }

                    return column
                })

                newRows[key] = {
                    "columns": newColumns
                }
            }

            const newData = {...data, rows: newRows}
            setData(newData)

            toast.success("Block freigegeben")
        } catch (e) {
            toast.error(e.data.status)
        }

    }

    async function handleBooking(slot, startTime) {
        const bookingData = {
            "datetime_from": slot.start_time,
            "teacher_id": slot.teacher_id,
            "student_id": studentId,
            "conference_day_id": conferenceDayId,
            "is_mentor": slot.is_mentors
        }

        try {
            const newBooking = await createBooking(bookingData)
            var newRows = {}
            for (const [key, value] of Object.entries(data.rows)) {
                const newColumns = value.columns.map(column => {
                    if (column.teacher_id == newBooking.teacher_id) {
                        if (column.start_time == slot.start_time) {
                            // new booking for this slot
                            return {...column, booking_id: newBooking.id, status: 'own_booking'}
                        }
                        // deleted booking (replaced)
                        if (column.status === 'own_booking') {
                            return {...column, booking_id: null, status: 'available'}
                        } else {
                            return {...column}
                        }

                        // return {...column, booking_id: null, status: 'available'}
                    }

                    return column
                })

                newRows[key] = {
                    "columns": newColumns
                }
            }

            const newData = {...data, rows: newRows}
            setData(newData)
            toast.success("Termin gebucht")
            queryClient.invalidateQueries({ queryKey: ["parent_conference_day_students", conferenceDayId] })
        } catch (e) {
            toast.error(e.data.status)
        }
    }

    async function handleEditBooking(slot) {
        // load the booking and open the modal
        const newBooking = await getBooking(slot.booking_id)
        setBooking(newBooking)
        setModalOpen(true)
    }

    async function handleSaveBooking() {
        try {
            const newBooking = await updateBooking(booking.id, booking)
            toast.success("Termin aktualisiert")
        } catch (e) {
            toast.error(e.data.status)
        }
    }

    function handleMultiSelect(selectedOptions, path) {
        let newBooking = {...booking}
        newBooking[path] = selectedOptions
        setBooking(newBooking)
    }

    async function handleDelete(slot) {
        await deleteBooking(slot.booking_id)
        toast.success("Termin gelöscht")

        var newRows = {}
        for (const [key, value] of Object.entries(data.rows)) {
            const newColumns = value.columns.map(column => {
                if (column.teacher_id == slot.teacher_id && column.start_time == slot.start_time) {
                    // new booking for this slot
                    return {...column, booking_id: null, status: 'available'}
                }

                return column
            })

            newRows[key] = {
                "columns": newColumns
            }
        }

        const newData = {...data, rows: newRows}
        setData(newData)
    }

    // if (bookingsLoading) return <SimplePage>Lädt...</SimplePage>
    //if (!data) return <></>;
    return (
        <>
            <SimplePage>
                <div className="px-4 sm:px-6 lg:px-8">
                    <div className="sm:flex sm:items-center">
                        <div className="sm:flex-auto">
                            {data && <h3 className="text-lg leading-6 font-medium text-gray-900">
                                {data.conference_day.title} - Termine buchen für {data.student.full_name}
                            </h3>}
                        </div>
                        <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none flex gap-1">
                            <PrimaryButton link={`/parent-conference/${conferenceDayId}/students/${studentId}/list`}
                                           label="Listenansicht"/>

                            {data && <DownloadButton
                                apiFunction={() => getStudentAppointmentListPDF(conferenceDayId, studentId)}
                                fileName={`Termine ${data.student.full_name}.pdf`}
                                label="PDF herunterladen"/>}

                        </div>
                    </div>
                    <div className="flex flex-col mt-3 mb-3 scrollbarHeight">

                        {bookingsLoading && <>Lädt...</>}

                        {data && <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
                            <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">

                                <div className="scrollbarHeight">
                                    <table className="min-w-full divide-y divide-gray-300 mt-3">
                                        <thead className="bg-imsblue-900 text-white text-sm">

                                        <tr>
                                            <td key="slotTimeLeft"></td>
                                            {data.headers.map((header, index) => (
                                                <td className="py-1 px-1" key={index}>
                                                    {header.teacher_name} <br/><br/>
                                                    {header.teacher_room} <br/>
                                                    {header.additional_info}
                                                </td>
                                            ))}
                                            <td key="slotTimeRight"></td>
                                        </tr>

                                        </thead>

                                        <tbody>

                                        {Object.keys(data.rows).map((key, index) => (

                                            <tr key={index}>
                                                <td className="py-1 px-1" key="slotTimeLeft">{key}</td>
                                                {data.rows[key].columns.map((column, columnIndex) => (
                                                    <Slot slot={column}
                                                          key={columnIndex}
                                                          startTime={key}
                                                          student={data.student}
                                                          canViewConferenceDay={canViewConferenceDay}
                                                          canChangeConferenceDay={canChangeConferenceDay}
                                                          canBookAppointment={data.can_book_appointment}
                                                          onDelete={() => handleDelete(column)}
                                                          onBooking={() => handleBooking(column, key)}
                                                          onEdit={() => handleEditBooking(column)}
                                                          onRemoveBlockedSlot={() => handleRemoveBlockedSlot(column)}
                                                    />
                                                ))}
                                                <td className="py-1 px-1" key="slotTimeRight">{key}</td>
                                            </tr>

                                        ))}

                                        </tbody>

                                    </table>
                                </div>

                            </div>
                        </div>}
                    </div>
                </div>
            </SimplePage>
            {canChangeConferenceDay && <EditBookingModal open={modalOpen} setOpen={setModalOpen}
                                                         booking={booking} staff={staff}
                                                         onMultiSelect={handleMultiSelect}
                                                         onSave={handleSaveBooking}/>}
        </>
    );
}

function Slot({
                  startTime,
                  slot,
                  student,
                  canViewConferenceDay,
                  canChangeConferenceDay,
                  canBookAppointment,
                  onBooking,
                  onEdit,
                  onRemoveBlockedSlot,
                  onDelete
              }) {

    // const [booking, setBooking] = useState()

    var tdClassNames
    var slotContent

    if (slot.status === 'blocked') {

        tdClassNames = "bg-gray-300"
        slotContent =
            <>
                <p className="text-sm">
                    {slot.title}
                </p>

                {slot.blocked_slot_can_be_unblocked && slot.can_still_be_unblocked && canChangeConferenceDay && <>
                    <XMarkIcon
                        title={`Block freigeben für ${student.first_name}`}
                        className="h-5 w-5 text-blue-400 cursor-pointer" aria-hidden="true" onClick={onRemoveBlockedSlot}/>
                </>}
            </>

    } else if (slot.booking_id) {

        if (slot.status === 'own_booking') {
            tdClassNames = 'bg-imsblue-300'
            slotContent =
                <>
                    <p className="text-sm text-gray-900">{slot.teacher_name}</p>
                    {slot.additional_participants &&
                        <p className="text-sm text-gray-500">mit {slot.additional_participants}</p>}
                    {canBookAppointment && <>
                        <TrashIcon className="h-5 w-5 text-blue-400 cursor-pointer" aria-hidden="true" onClick={onDelete}/>
                    </>}

                    {canChangeConferenceDay && <>
                        <PencilIcon className="h-5 w-5 text-blue-400 cursor-pointer" aria-hidden="true" onClick={onEdit}/>
                    </>}

                    <p className="text-sm text-gray-500">{startTime}</p>
                </>
        } else if (slot.status === 'sibling_booking') {
            tdClassNames = 'bg-green-100'
            slotContent =
                <>
                    <p className="text-sm text-gray-900">Bestehende Buchung für {slot.booking_student_first_name}</p>
                    {canBookAppointment && <>
                        <>
                            <p className="text-sm text-gray-900">Trotzdem buchen?
                                <PlusIcon className="h-5 w-5 text-green-700 cursor-pointer" aria-hidden="true" onClick={onBooking}/>
                            </p>
                        </>
                    </>}
                    <p className="text-sm text-gray-500">{startTime}</p>
                </>

        } else if (canViewConferenceDay) {
            slotContent =
                <>
                    {/*<p className="text-sm text-gray-500">*/}
                    {/*    {canChangeConferenceDay && <>*/}
                    {/*        <PencilIcon className="h-5 w-5 text-blue-400" aria-hidden="true" onClick={onEdit}/>*/}
                    {/*    </>}*/}
                    {/*</p>*/}
                    <p className="text-sm text-gray-500">{startTime}</p>
                </>

        }

    } else {
        // book this slot
        slotContent =
            <>
                <p className="text-sm text-gray-500">{slot.teacher_name}</p>

                {canBookAppointment && <>
                    <PlusIcon className="h-5 w-5 text-blue-400 cursor-pointer" aria-hidden="true" onClick={onBooking}/>
                </>}

                <p className="text-sm text-gray-500">{startTime}</p>
            </>
    }

    return <td className={"py-1 px-1 min-w-4 " + tdClassNames}>{slotContent}</td>
}
