import {React, useEffect, useState} from "react";
import Joi from "joi"
import {useQuery, useQueryClient} from '@tanstack/react-query'
import {Checkbox, DateInput, Input, Select, TextArea} from "../../elements/forms/Inputs"
import {getGrades, getSchoolSubjectsForeignLanguages, getSchoolTypes} from "../../../api/school";
import Form, {baseHandleChangeNested, baseHandleDateChange, baseHandleListChangeNested} from "../../elements/forms/Form"
import {useNavigate, useParams} from "react-router-dom";
import {toast} from "react-toastify";
import {applicantsApi} from "../../../api/applicants";
import {AddButton, DownloadButton, PrimaryButton, SecondaryButton} from "../../elements/Buttons";
import {formatDate} from "../../../utils/formatters";
import {getFutureSchoolYears} from "../../../api/calendar";
import env from "react-dotenv";
import Alert from "../../elements/Alert";

export default function ApplicantCreateEdit() {

    const queryClient = useQueryClient()
    const {studentId} = useParams()
    const [editingMode, setEditingMode] = useState(studentId ? false : true)

    const [student, setStudent] = useState({
        id: null,
        user: {
            first_name: null,
            last_name: null
        }
    })
    const [errors, setErrors] = useState({}) // validation errors
    const [apiErrors, setApiErrors] = useState("") // validation errors
    const [apiSuccess, setApiSuccess] = useState("") // validation errors
    const [parentRelationshipErrors, setParentRelationshipErrors] = useState([])
    const [newRowId, setNewRowId] = useState(1)

    const {data: options} = useQuery(
        ["applicant_options"],
        () => applicantsApi('options'),
        {}
    )

    const schema = {
        user: Joi.object().keys({
            first_name: Joi.string().required(),
            last_name: Joi.string().required(),
        })
    }

    const {data: grades} = useQuery(['grades'], getGrades, {refetchInterval: 1000 * 60 * 60 * 24})
    const {data: futureSchoolYears} = useQuery(['future_school_years'], getFutureSchoolYears, {refetchInterval: 1000 * 60 * 60 * 24})
    const {data: schoolTypes} = useQuery(['school_types'], getSchoolTypes, {refetchInterval: 1000 * 60 * 60 * 24})
    const {data: languages} = useQuery(['school_subjects_foreign_languages'], getSchoolSubjectsForeignLanguages, {refetchInterval: 1000 * 60 * 60 * 24})

    let navigate = useNavigate();

    useEffect(() => {
        loadData()
    }, [studentId])

    async function loadData() {
        if (!studentId) return
        const newData = await applicantsApi('get', studentId)
        setStudent(newData)
    }

    const successMessage = (studentId || student.id) ? "Bewerber aktualisiert" : "Bewerber erfasst"

    async function onSave() {
        let tmpParentRelationships = student.parent_relationships.map((t, index) => {
            if (t.id && t.id.toString().startsWith("N")) {
                return {...t, id: null}
            }
            return {...t}
        })

        let newData
        let tmpStudent = {...student, parent_relationships: tmpParentRelationships}
        if (!student.id) newData = await applicantsApi('post', null, tmpStudent)
        else newData = await applicantsApi('patch', null, tmpStudent)
        setStudent(newData)

        queryClient.invalidateQueries({ queryKey: ['applicants'] })
        toast.success(successMessage)
    }

    function setUser(user) {
        setStudent({...student, user: user})
    }

    function setParentRelationships(parentRelationships) {
        setStudent({...student, parent_relationships: parentRelationships})
    }

    function handleChange(evt) {
        baseHandleChangeNested(evt, student, setStudent, errors, setErrors, schema)
    }

    function handleDateChange(date, path) {
        baseHandleDateChange(date, path, student, setStudent, errors, setErrors, schema, false)
    }

    function handleListChange(evt, rowId) {
        baseHandleListChangeNested(rowId, evt, student.parent_relationships, setParentRelationships, parentRelationshipErrors, setParentRelationshipErrors, {})
    }

    function addRow(e) {
        // add parent row
        e.preventDefault()
        const newRow = {
            "id": `N${newRowId}`,
            "parent": {
                "status": "PROSPECTIVE"
            },
            "has_parental_authority": true,
        }
        setNewRowId(newRowId + 1)
        setParentRelationships([...student.parent_relationships, newRow])
    }

    return (

        <>
            <main className="py-10 h-full bg-gray-100">
                <div
                    className="max-w-3xl mx-auto px-4 sm:px-6 md:flex md:items-center md:justify-between md:space-x-5 lg:max-w-7xl lg:px-8">
                    <div className="flex items-center space-x-5">
                        {/*<div className="flex-shrink-0">*/}
                        {/*    <div className="relative">*/}
                        {/*        <img*/}
                        {/*            className="h-32 w-32 rounded-full"*/}
                        {/*            src={student.image.detail}*/}
                        {/*            alt={student.full_name}*/}
                        {/*        />*/}
                        {/*        <span*/}
                        {/*            className="absolute inset-0 shadow-inner rounded-full"*/}
                        {/*            aria-hidden="true"*/}
                        {/*        />*/}
                        {/*    </div>*/}
                        {/*</div>*/}
                        <div>
                            <h1 className="text-2xl font-bold text-gray-900">
                                Aufnahmeprotokoll {student.full_name}
                            </h1>
                            {/*<p className="text-sm font-medium text-gray-500">*/}
                            {/*    {student.get_status_display}*/}
                            {/*</p>*/}
                        </div>
                    </div>
                    <div
                        className="mt-6 flex flex-col-reverse justify-stretch space-y-4 space-y-reverse sm:flex-row-reverse sm:justify-end sm:space-x-reverse sm:space-y-0 sm:space-x-3 md:mt-0 md:flex-row md:space-x-3">
                        {/*TODO*/}
                        <DownloadButton label={"Gesprächsprotokoll"} link={`/applicants/${student.id}/protocol`}/>
                    </div>
                </div>

                <Form id="editCreateApplicant" onSave={onSave}
                      data={student} setData={setStudent}
                      errors={errors} setErrors={setErrors}
                      setApiErrors={setApiErrors}
                      setApiSuccess={setApiSuccess}
                      hideFormLayout={true}
                      successMessage={successMessage}
                      schema={schema}>

                    <div
                        className="mt-8 max-w-3xl mx-auto grid grid-cols-1 gap-6 sm:px-6 lg:max-w-7xl lg:grid-flow-col-dense lg:grid-cols-3">
                        <div className="space-y-6 lg:col-start-1 lg:col-span-2">

                            <section aria-labelledby="editing-controls">
                                <div className="bg-white shadow sm:rounded-lg">
                                    {editingMode && <div className="px-4 py-5 sm:px-6 flex justify-end gap-2">

                                        <div className="">
                                            {apiErrors &&
                                                <Alert extraClassName="py-2" variant="danger">{apiErrors}</Alert>}
                                            {apiSuccess &&
                                                <Alert extraClassName="py-2" variant="success">{apiSuccess}</Alert>}
                                        </div>

                                        <SecondaryButton label={"Eingabe schließen"}
                                                         onClick={() => setEditingMode(!editingMode)}/>
                                        <SubmitButton/>
                                    </div>}

                                    {!editingMode && <div className="px-4 py-5 sm:px-6 flex justify-end gap-2">
                                        <PrimaryButton label={"Bearbeiten"}
                                                       onClick={() => setEditingMode(!editingMode)}/>
                                    </div>}
                                </div>
                            </section>


                            <section aria-labelledby="applicant-information-title">
                                <div className="bg-white shadow sm:rounded-lg">

                                    <div className="px-4 py-5 sm:px-6 flex justify-between">
                                        <h2 id="applicant-information-title"
                                            className="text-lg font-medium leading-6 text-gray-900">
                                            Schülerdaten
                                        </h2>
                                    </div>

                                    {editingMode && <>
                                        <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
                                            <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">

                                                {errors?.user &&
                                                    <div className="sm:col-span-2">
                                                        <Alert extraClassName="py-2"
                                                               variant="danger">{errors?.user}</Alert>
                                                    </div>}

                                                <div className="sm:col-span-1">
                                                    <Input
                                                        path="user.first_name"
                                                        type="text"
                                                        label="Vorname"
                                                        onChange={handleChange}
                                                        errors={errors}
                                                        data={student}
                                                    />
                                                </div>
                                                <div className="sm:col-span-1">
                                                    <Input
                                                        path="user.last_name"
                                                        type="text"
                                                        label="Nachname"
                                                        onChange={handleChange}
                                                        errors={errors}
                                                        data={student}
                                                    />
                                                </div>

                                                <div className="sm:col-span-2">
                                                    <Checkbox
                                                        path="boarding_school"
                                                        label="Intern"
                                                        className="sm:col-span-6 hide-label"
                                                        onChange={handleChange}
                                                        errors={errors}
                                                        data={student}/>
                                                </div>

                                                <div className="sm:col-span-1">
                                                    <Select
                                                        path="applying_for_school_year"
                                                        label="für Schuljahr"
                                                        options={futureSchoolYears}
                                                        valueAttr="id"
                                                        labelAttr="name"
                                                        onChange={handleChange}
                                                        errors={errors}
                                                        data={student}
                                                        isClearable={true}
                                                        valueOnly={false}
                                                    />
                                                </div>
                                                <div className="sm:col-span-1">
                                                    <Select
                                                        path="applying_for_grade"
                                                        label="für Klassenstufe"
                                                        options={grades}
                                                        valueAttr="id"
                                                        labelAttr="name"
                                                        onChange={handleChange}
                                                        errors={errors}
                                                        data={student}
                                                        isClearable={true}
                                                        valueOnly={false}
                                                    />
                                                </div>

                                                <div className="sm:col-span-1">
                                                    <DateInput
                                                        className="col-span-3"
                                                        path="birth_date"
                                                        label="Geburtsdatum"
                                                        hideLabel={true}
                                                        onChange={handleDateChange}
                                                        errors={errors}
                                                        data={student}
                                                    />
                                                </div>
                                                <div className="sm:col-span-1">
                                                    <Input
                                                        path="place_of_birth"
                                                        type="text"
                                                        label="Geburtsort"
                                                        className="sm:col-span-6"
                                                        onChange={handleChange}
                                                        errors={errors}
                                                        data={student}
                                                    />
                                                </div>

                                                <div className="sm:col-span-1">
                                                    <Input
                                                        path="source"
                                                        type="text"
                                                        label="Quelle"
                                                        className="sm:col-span-6"
                                                        onChange={handleChange}
                                                        errors={errors}
                                                        data={student}
                                                    />
                                                </div>
                                                <div className="sm:col-span-1">
                                                    <Input
                                                        path="recommended_by"
                                                        type="text"
                                                        label="Empfohlen durch"
                                                        className="sm:col-span-6"
                                                        onChange={handleChange}
                                                        errors={errors}
                                                        data={student}
                                                    />
                                                </div>
                                            </dl>
                                        </div>

                                    </>}

                                    {!editingMode && <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
                                        <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
                                            <div className="sm:col-span-1">
                                                <dt className="text-sm font-medium text-gray-500">Vorname</dt>
                                                <dd className="mt-1 text-sm text-gray-900">{student.user.first_name}
                                                </dd>
                                            </div>
                                            <div className="sm:col-span-1">
                                                <dt className="text-sm font-medium text-gray-500">Nachname</dt>
                                                <dd className="mt-1 text-sm text-gray-900">{student.user.last_name}
                                                </dd>
                                            </div>

                                            <div className="sm:col-span-2">
                                                <dt className="text-sm font-medium text-gray-500">Intern</dt>
                                                <dd className="mt-1 text-sm text-gray-900">{student.boarding_school ? "Ja" : "Nein"}
                                                </dd>
                                            </div>

                                            <div className="sm:col-span-1">
                                                <dt className="text-sm font-medium text-gray-500">Bewerbung zum
                                                    Schuljahr
                                                </dt>
                                                <dd className="mt-1 text-sm text-gray-900">{student.applying_for_school_year?.name}
                                                </dd>
                                            </div>
                                            <div className="sm:col-span-1">
                                                <dt className="text-sm font-medium text-gray-500">Für Klassenstufe</dt>
                                                <dd className="mt-1 text-sm text-gray-900">{student.applying_for_grade?.name}
                                                </dd>
                                            </div>

                                            <div className="sm:col-span-1">
                                                <dt className="text-sm font-medium text-gray-500">Geburtsdatum</dt>
                                                <dd className="mt-1 text-sm text-gray-900">{formatDate(student.birth_date)}
                                                </dd>
                                            </div>
                                            <div className="sm:col-span-1">
                                                <dt className="text-sm font-medium text-gray-500">Geburtsort</dt>
                                                <dd className="mt-1 text-sm text-gray-900">{student.place_of_birth}</dd>
                                            </div>

                                            <div className="sm:col-span-1">
                                                <dt className="text-sm font-medium text-gray-500">Quelle</dt>
                                                <dd className="mt-1 text-sm text-gray-900 whitespace-pre-wrap">{student.source}</dd>
                                            </div>
                                            <div className="sm:col-span-1">
                                                <dt className="text-sm font-medium text-gray-500">Empfohlen durch</dt>
                                                <dd className="mt-1 text-sm text-gray-900 whitespace-pre-wrap">{student.recommended_by}</dd>
                                            </div>
                                        </dl>
                                    </div>}

                                </div>
                            </section>

                            {/* TODO parents */}
                            <section aria-labelledby="applicant-information-title">
                                <div className="bg-white shadow sm:rounded-lg">

                                    <div className="px-4 py-5 sm:px-6">
                                        <h2 id="applicant-information-title"
                                            className="text-lg font-medium leading-6 text-gray-900">
                                            Eltern
                                        </h2>
                                    </div>

                                    <div className="border-t border-gray-200 px-4 py-5 sm:px-6">

                                        {student.parent_relationships?.map((parentRelationship, index) => (
                                            <div key={index}>
                                                {editingMode && <dl
                                                    className={"grid grid-cols-1 gap-x-4 gap-y-4 sm:grid-cols-2 " + (index > 0 ? "mt-8" : "")}>

                                                    <div className="sm:col-span-1">
                                                        <Input
                                                            path="parent.user.first_name"
                                                            type="text"
                                                            label="Vorname"
                                                            onChange={handleListChange}
                                                            errors={parentRelationshipErrors}
                                                            data={student.parent_relationships}
                                                            rowId={parentRelationship.id}
                                                        />
                                                    </div>
                                                    <div className="sm:col-span-1">
                                                        <Input
                                                            path="parent.user.last_name"
                                                            type="text"
                                                            label="Nachname"
                                                            onChange={handleListChange}
                                                            errors={parentRelationshipErrors}
                                                            data={student.parent_relationships}
                                                            rowId={parentRelationship.id}
                                                        />
                                                    </div>

                                                    <div className="sm:col-span-1">
                                                        <Select
                                                            path="parent_role"
                                                            label="Elternrolle"
                                                            options={options?.parent_relationships?.child.children.parent_role?.choices}
                                                            valueAttr="value"
                                                            labelAttr="display_name"
                                                            onChange={handleListChange}
                                                            errors={parentRelationshipErrors}
                                                            data={student.parent_relationships}
                                                            rowId={parentRelationship.id}
                                                            isClearable={true}
                                                            valueOnly={true}
                                                        />
                                                    </div>
                                                    <div className="sm:col-span-1">
                                                        <Checkbox
                                                            path="has_parental_authority"
                                                            label="Erziehungsberechtigt"
                                                            className="sm:col-span-6 hide-label"
                                                            onChange={handleListChange}
                                                            errors={parentRelationshipErrors}
                                                            data={student.parent_relationships}
                                                            rowId={parentRelationship.id}
                                                        />
                                                    </div>
                                                    <div className="sm:col-span-2">
                                                        <Input
                                                            path="parent.street"
                                                            type="text"
                                                            label="Straße"
                                                            onChange={handleListChange}
                                                            errors={parentRelationshipErrors}
                                                            data={student.parent_relationships}
                                                            rowId={parentRelationship.id}
                                                        />
                                                    </div>

                                                    <div className="sm:col-span-1">
                                                        <Input
                                                            path="parent.zip_code"
                                                            type="text"
                                                            label="PLZ"
                                                            onChange={handleListChange}
                                                            errors={parentRelationshipErrors}
                                                            data={student.parent_relationships}
                                                            rowId={parentRelationship.id}
                                                        />
                                                    </div>

                                                    <div className="sm:col-span-1">
                                                        <Input
                                                            path="parent.city"
                                                            type="text"
                                                            label="Ort"
                                                            onChange={handleListChange}
                                                            errors={parentRelationshipErrors}
                                                            data={student.parent_relationships}
                                                            rowId={parentRelationship.id}
                                                        />
                                                    </div>


                                                    <div className="sm:col-span-1">
                                                        <Input
                                                            path="parent.country"
                                                            type="text"
                                                            label="Land"
                                                            onChange={handleListChange}
                                                            errors={parentRelationshipErrors}
                                                            data={student.parent_relationships}
                                                            rowId={parentRelationship.id}
                                                        />
                                                    </div>

                                                    <div className="sm:col-span-1">
                                                        <Input
                                                            path="parent.user.email"
                                                            type="text"
                                                            label="Email"
                                                            onChange={handleListChange}
                                                            errors={parentRelationshipErrors}
                                                            data={student.parent_relationships}
                                                            rowId={parentRelationship.id}
                                                        />
                                                    </div>
                                                    <div className="sm:col-span-1">
                                                        <Input
                                                            path="parent.phone_private"
                                                            type="text"
                                                            label="Telefon privat"
                                                            onChange={handleListChange}
                                                            errors={parentRelationshipErrors}
                                                            data={student.parent_relationships}
                                                            rowId={parentRelationship.id}
                                                        />
                                                    </div>
                                                    <div className="sm:col-span-1">
                                                        <Input
                                                            path="parent.phone_work"
                                                            type="text"
                                                            label="Telefon geschäftlich"
                                                            onChange={handleListChange}
                                                            errors={parentRelationshipErrors}
                                                            data={student.parent_relationships}
                                                            rowId={parentRelationship.id}
                                                        />
                                                    </div>
                                                    <div className="sm:col-span-1">
                                                        <Input
                                                            path="parent.mobile"
                                                            type="text"
                                                            label="Telefon mobil"
                                                            onChange={handleListChange}
                                                            errors={parentRelationshipErrors}
                                                            data={student.parent_relationships}
                                                            rowId={parentRelationship.id}
                                                        />
                                                    </div>
                                                    <div className="sm:col-span-1">
                                                        <Input
                                                            path="parent.profession"
                                                            type="text"
                                                            label="Beruf"
                                                            onChange={handleListChange}
                                                            errors={parentRelationshipErrors}
                                                            data={student.parent_relationships}
                                                            rowId={parentRelationship.id}
                                                        />
                                                    </div>
                                                </dl>}

                                                {!editingMode && <dl key={index}
                                                                     className={"grid grid-cols-1 gap-x-4 gap-y-4 sm:grid-cols-2 " + (index > 0 ? "mt-8" : "")}>
                                                    <div className="sm:col-span-2">
                                                        <dt className="text-md font-medium text-gray-500">{parentRelationship.parent?.user?.full_name}</dt>
                                                    </div>
                                                    <div className="sm:col-span-1">
                                                        <dt className="text-sm font-medium text-gray-500">Elternrolle</dt>
                                                        <dd className="mt-1 text-sm text-gray-900">{parentRelationship.get_parent_role_display}</dd>
                                                    </div>
                                                    <div className="sm:col-span-1">
                                                        <dt className="text-sm font-medium text-gray-500">Erziehungsberechtigt</dt>
                                                        <dd className="mt-1 text-sm text-gray-900">{parentRelationship.has_parental_authority ? "Ja" : "Nein"}</dd>
                                                    </div>
                                                    <div className="sm:col-span-1">
                                                        <dt className="text-sm font-medium text-gray-500">Adresse</dt>
                                                        <dd className="mt-1 text-sm text-gray-900">
                                                            {parentRelationship.parent.street} <br/>
                                                            {parentRelationship.parent.zip_code} {parentRelationship.parent.city}
                                                            <br/>
                                                            {parentRelationship.parent.country}
                                                        </dd>
                                                    </div>
                                                    <div className="sm:col-span-1">
                                                        <dt className="text-sm font-medium text-gray-500">Email</dt>
                                                        <dd className="mt-1 text-sm text-gray-900">{parentRelationship.parent.user?.email}</dd>
                                                    </div>
                                                    <div className="sm:col-span-1">
                                                        <dt className="text-sm font-medium text-gray-500">Telefon privat
                                                        </dt>
                                                        <dd className="mt-1 text-sm text-gray-900">{parentRelationship.parent.phone_private}</dd>
                                                    </div>
                                                    <div className="sm:col-span-1">
                                                        <dt className="text-sm font-medium text-gray-500">Telefon
                                                            geschäftlich
                                                        </dt>
                                                        <dd className="mt-1 text-sm text-gray-900">{parentRelationship.parent.phone_work}</dd>
                                                    </div>
                                                    <div className="sm:col-span-1">
                                                        <dt className="text-sm font-medium text-gray-500">Telefon
                                                            mobil
                                                        </dt>
                                                        <dd className="mt-1 text-sm text-gray-900">{parentRelationship.parent.mobile}</dd>
                                                    </div>
                                                    <div className="sm:col-span-1">
                                                        <dt className="text-sm font-medium text-gray-500">Beruf</dt>
                                                        <dd className="mt-1 text-sm text-gray-900">{parentRelationship.parent.profession}</dd>
                                                    </div>
                                                </dl>}
                                            </div>
                                        ))}

                                    </div>

                                    {editingMode && <div className="flex justify-between">
                                        <div className="my-4 ml-4">
                                            <AddButton onClick={(e) => addRow(e)} label="Elternteil hinzufügen"/>
                                        </div>
                                    </div>}

                                </div>
                            </section>

                            <section aria-labelledby="applicant-information-title">
                                <div className="bg-white shadow sm:rounded-lg">

                                    <div className="px-4 py-5 sm:px-6">
                                        <h2 id="applicant-information-title"
                                            className="text-lg font-medium leading-6 text-gray-900">
                                            Schule
                                        </h2>
                                    </div>

                                    {editingMode && <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
                                        <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
                                            {env.SCHOOL === 'KPI' && <div className="sm:col-span-1">
                                                <Select
                                                    path="school_type"
                                                    label="Schulform"
                                                    options={schoolTypes}
                                                    valueAttr="id"
                                                    labelAttr="name"
                                                    onChange={handleChange}
                                                    errors={errors}
                                                    data={student}
                                                    isClearable={true}
                                                    valueOnly={false}
                                                />
                                            </div>}
                                            <div className="sm:col-span-1">
                                                <Input
                                                    path="previous_school"
                                                    type="text"
                                                    label="Derzeitige Schule"
                                                    onChange={handleChange}
                                                    errors={errors}
                                                    data={student}
                                                />
                                            </div>
                                            <div className="sm:col-span-1">
                                                <Select
                                                    path="foreign_language_1"
                                                    label="1. Fremdsprache"
                                                    options={languages}
                                                    valueAttr="id"
                                                    labelAttr="name"
                                                    onChange={handleChange}
                                                    errors={errors}
                                                    data={student}
                                                    isClearable={true}
                                                    valueOnly={true}
                                                />
                                            </div>
                                            <div className="sm:col-span-1">
                                                <Select
                                                    path="foreign_language_2"
                                                    label="2. Fremdsprache"
                                                    options={languages}
                                                    valueAttr="id"
                                                    labelAttr="name"
                                                    onChange={handleChange}
                                                    errors={errors}
                                                    data={student}
                                                    isClearable={true}
                                                    valueOnly={true}
                                                />
                                            </div>
                                            <div className="sm:col-span-1">
                                                <Select
                                                    path="foreign_language_3"
                                                    label="3. Fremdsprache"
                                                    options={languages}
                                                    valueAttr="id"
                                                    labelAttr="name"
                                                    onChange={handleChange}
                                                    errors={errors}
                                                    data={student}
                                                    isClearable={true}
                                                    valueOnly={true}
                                                />
                                            </div>
                                            <div className="sm:col-span-1">
                                                <Input
                                                    path="new_language_order"
                                                    type="text"
                                                    label="Neue Sprachfolge"
                                                    onChange={handleChange}
                                                    errors={errors}
                                                    data={student}
                                                />
                                            </div>
                                            <div className="sm:col-span-1">
                                                <Input
                                                    path="repeated_grades"
                                                    type="text"
                                                    label="Wiederholte Klassen"
                                                    onChange={handleChange}
                                                    errors={errors}
                                                    data={student}
                                                />
                                            </div>
                                            <div className="sm:col-span-1">
                                                <Input
                                                    path="skipped_grades"
                                                    type="text"
                                                    label="Übersprungene Klassen"
                                                    onChange={handleChange}
                                                    errors={errors}
                                                    data={student}
                                                />
                                            </div>
                                            <div className="sm:col-span-2">
                                                <TextArea
                                                    path="school_particularities"
                                                    label="Schulische
                                                Besonderheiten"
                                                    onChange={handleChange}
                                                    errors={errors}
                                                    data={student}
                                                    rows={3}
                                                />
                                            </div>
                                        </dl>
                                    </div>}

                                    {!editingMode && <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
                                        <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
                                            <div className="sm:col-span-1">
                                                <dt className="text-sm font-medium text-gray-500">Schulform</dt>
                                                <dd className="mt-1 text-sm text-gray-900">{student.school_type?.name}</dd>
                                            </div>
                                            <div className="sm:col-span-1">
                                                <dt className="text-sm font-medium text-gray-500">Derzeitige Schule</dt>
                                                <dd className="mt-1 text-sm text-gray-900">{student.previous_school}</dd>
                                            </div>
                                            <div className="sm:col-span-1">
                                                <dt className="text-sm font-medium text-gray-500">1. Fremdsprache</dt>
                                                <dd className="mt-1 text-sm text-gray-900">{student.foreign_language_1}</dd>
                                            </div>
                                            <div className="sm:col-span-1">
                                                <dt className="text-sm font-medium text-gray-500">2. Fremdsprache</dt>
                                                <dd className="mt-1 text-sm text-gray-900">{student.foreign_language_2}</dd>
                                            </div>
                                            <div className="sm:col-span-1">
                                                <dt className="text-sm font-medium text-gray-500">3. Fremdsprache</dt>
                                                <dd className="mt-1 text-sm text-gray-900">{student.foreign_language_3}</dd>
                                            </div>
                                            <div className="sm:col-span-1">
                                                <dt className="text-sm font-medium text-gray-500">Neue Sprachfolge</dt>
                                                <dd className="mt-1 text-sm text-gray-900">{student.new_language_order}</dd>
                                            </div>
                                            <div className="sm:col-span-1">
                                                <dt className="text-sm font-medium text-gray-500">Wiederholte Klassen
                                                </dt>
                                                <dd className="mt-1 text-sm text-gray-900">{student.repeated_grades}</dd>
                                            </div>
                                            <div className="sm:col-span-1">
                                                <dt className="text-sm font-medium text-gray-500">Übersprungene
                                                    Klassen
                                                </dt>
                                                <dd className="mt-1 text-sm text-gray-900">{student.skipped_grades}</dd>
                                            </div>
                                            <div className="sm:col-span-2">
                                                <dt className="text-sm font-medium text-gray-500">Schulische
                                                    Besonderheiten
                                                </dt>
                                                <dd className="mt-1 text-sm text-gray-900 whitespace-pre-wrap">{student.school_particularities}</dd>
                                            </div>
                                        </dl>
                                    </div>}
                                </div>
                            </section>

                            <section aria-labelledby="applicant-information-title">
                                <div className="bg-white shadow sm:rounded-lg">

                                    <div className="px-4 py-5 sm:px-6">
                                        <h2 id="applicant-information-title"
                                            className="text-lg font-medium leading-6 text-gray-900">
                                            Besonderheiten & Freizeit
                                        </h2>
                                    </div>

                                    {editingMode && <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
                                        <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
                                            <div className="sm:col-span-1">
                                                <Input
                                                    path="gifted"
                                                    type="text"
                                                    label="Hochbegabt"
                                                    onChange={handleChange}
                                                    errors={errors}
                                                    data={student}
                                                />
                                            </div>
                                            <div className="sm:col-span-1">
                                                <Input
                                                    path="ads"
                                                    type="text"
                                                    label="ADS"
                                                    onChange={handleChange}
                                                    errors={errors}
                                                    data={student}
                                                />
                                            </div>
                                            <div className="sm:col-span-1">
                                                <Input
                                                    path="adhs"
                                                    type="text"
                                                    label="ADHS"
                                                    onChange={handleChange}
                                                    errors={errors}
                                                    data={student}
                                                />
                                            </div>
                                            <div className="sm:col-span-1">
                                                <Input
                                                    path="lrs"
                                                    type="text"
                                                    label="LRS"
                                                    onChange={handleChange}
                                                    errors={errors}
                                                    data={student}
                                                />
                                            </div>
                                            <div className="sm:col-span-2">
                                                <TextArea
                                                    path="disciplinary_problems"
                                                    label="Erziehungsprobleme"
                                                    onChange={handleChange}
                                                    errors={errors}
                                                    data={student}
                                                    rows={3}
                                                />
                                            </div>
                                            <div className="sm:col-span-2">
                                                <TextArea
                                                    path="personal_interests"
                                                    label="Interessen/Hobbies"
                                                    onChange={handleChange}
                                                    errors={errors}
                                                    data={student}
                                                    rows={3}
                                                />
                                            </div>
                                        </dl>
                                    </div>}

                                    {!editingMode && <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
                                        <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
                                            <div className="sm:col-span-1">
                                                <dt className="text-sm font-medium text-gray-500">Hochbegabt</dt>
                                                <dd className="mt-1 text-sm text-gray-900">{student.gifted}</dd>
                                            </div>
                                            <div className="sm:col-span-1">
                                                <dt className="text-sm font-medium text-gray-500">ADS</dt>
                                                <dd className="mt-1 text-sm text-gray-900">{student.ads}</dd>
                                            </div>
                                            <div className="sm:col-span-1">
                                                <dt className="text-sm font-medium text-gray-500">ADHS</dt>
                                                <dd className="mt-1 text-sm text-gray-900">{student.adhs}</dd>
                                            </div>
                                            <div className="sm:col-span-1">
                                                <dt className="text-sm font-medium text-gray-500">LRS</dt>
                                                <dd className="mt-1 text-sm text-gray-900">{student.lrs}</dd>
                                            </div>
                                            <div className="sm:col-span-2">
                                                <dt className="text-sm font-medium text-gray-500">Erziehungsprobleme</dt>
                                                <dd className="mt-1 text-sm text-gray-900 whitespace-pre-wrap">{student.disciplinary_problems}</dd>
                                            </div>
                                            <div className="sm:col-span-2">
                                                <dt className="text-sm font-medium text-gray-500">Interessen/Hobbies</dt>
                                                <dd className="mt-1 text-sm text-gray-900 whitespace-pre-wrap">{student.personal_interests}</dd>
                                            </div>
                                        </dl>
                                    </div>}
                                </div>
                            </section>


                            <section aria-labelledby="applicant-information-title">
                                <div className="bg-white shadow sm:rounded-lg">

                                    <div className="px-4 py-5 sm:px-6">
                                        <h2 id="applicant-information-title"
                                            className="text-lg font-medium leading-6 text-gray-900">
                                            Hinweise & Zusagen
                                        </h2>
                                    </div>

                                    {editingMode && <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
                                        <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
                                            <div className="sm:col-span-2">
                                                <TextArea
                                                    path="family_priorities"
                                                    label="Der Familie besonders wichtig"
                                                    onChange={handleChange}
                                                    errors={errors}
                                                    data={student}
                                                    rows={3}
                                                />
                                            </div>
                                            <div className="sm:col-span-2">
                                                <TextArea
                                                    path="school_commitments"
                                                    label="Zusagen & Absprachen"
                                                    onChange={handleChange}
                                                    errors={errors}
                                                    data={student}
                                                    rows={3}
                                                />
                                            </div>
                                        </dl>
                                    </div>}

                                    {!editingMode && <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
                                        <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
                                            <div className="sm:col-span-2">
                                                <dt className="text-sm font-medium text-gray-500">Der Familie besonders
                                                    wichtig
                                                </dt>
                                                <dd className="mt-1 text-sm text-gray-900 whitespace-pre-wrap">{student.family_priorities}</dd>
                                            </div>
                                            <div className="sm:col-span-2">
                                                <dt className="text-sm font-medium text-gray-500">Zusagen & Absprachen
                                                </dt>
                                                <dd className="mt-1 text-sm text-gray-900 whitespace-pre-wrap">{student.school_commitments}</dd>
                                            </div>
                                        </dl>
                                    </div>}
                                </div>
                            </section>

                            <section aria-labelledby="applicant-information-title">
                                <div className="bg-white shadow sm:rounded-lg">

                                    <div className="px-4 py-5 sm:px-6">
                                        <h2 id="applicant-information-title"
                                            className="text-lg font-medium leading-6 text-gray-900">
                                            Einschätzung
                                        </h2>
                                    </div>

                                    {editingMode && <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
                                        <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
                                            <div className="sm:col-span-1">
                                                <Select
                                                    path="personality_type"
                                                    label="Typ"
                                                    options={options?.personality_type?.choices}
                                                    valueAttr="value"
                                                    labelAttr="display_name"
                                                    onChange={handleChange}
                                                    errors={errors}
                                                    data={student}
                                                    isClearable={false}
                                                    valueOnly={true}
                                                />
                                            </div>
                                            <div className="sm:col-span-1">
                                                <TextArea
                                                    path="personality_remarks"
                                                    label="Persönlichkeit Anmerkungen"
                                                    onChange={handleChange}
                                                    errors={errors}
                                                    data={student}
                                                    rows={2}
                                                />
                                            </div>

                                            <div className="sm:col-span-2">
                                                <Select
                                                    path="acceptance_recommended"
                                                    label="Aufnahmeempfehlung"
                                                    options={options?.acceptance_recommended?.choices}
                                                    valueAttr="value"
                                                    labelAttr="display_name"
                                                    onChange={handleChange}
                                                    errors={errors}
                                                    data={student}
                                                    isClearable={false}
                                                    valueOnly={true}
                                                />
                                            </div>

                                            <div className="sm:col-span-2">
                                                <TextArea
                                                    path="interview_remarks"
                                                    label="Sonstige Bemerkungen"
                                                    onChange={handleChange}
                                                    errors={errors}
                                                    data={student}
                                                    rows={3}
                                                />
                                            </div>
                                        </dl>
                                    </div>}

                                    {!editingMode && <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
                                        <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
                                            <div className="sm:col-span-1">
                                                <dt className="text-sm font-medium text-gray-500">Typ</dt>
                                                <dd className="mt-1 text-sm text-gray-900 whitespace-pre-wrap">{student.personality_type}</dd>
                                            </div>
                                            <div className="sm:col-span-1">
                                                <dt className="text-sm font-medium text-gray-500">Persönlichkeit
                                                    Anmerkungen
                                                </dt>
                                                <dd className="mt-1 text-sm text-gray-900 whitespace-pre-wrap">{student.personality_remarks}</dd>
                                            </div>

                                            <div className="sm:col-span-1">
                                                <dt className="text-sm font-medium text-gray-500">Aufnahmeempfehlung</dt>
                                                <dd className="mt-1 text-sm text-gray-900 whitespace-pre-wrap">{student.acceptance_recommended}</dd>
                                            </div>

                                            <div className="sm:col-span-2">
                                                <dt className="text-sm font-medium text-gray-500">Sonstige Bemerkungen
                                                </dt>
                                                <dd className="mt-1 text-sm text-gray-900 whitespace-pre-wrap">{student.interview_remarks}</dd>
                                            </div>
                                        </dl>
                                    </div>}
                                </div>
                            </section>

                            <section aria-labelledby="applicant-information-title">
                                <div className="bg-white shadow sm:rounded-lg">
                                    <div className="px-4 py-5 sm:px-6">
                                        <h2 id="applicant-information-title"
                                            className="text-lg font-medium leading-6 text-gray-900">
                                            Vertrag
                                        </h2>
                                    </div>
                                    {editingMode && <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
                                        <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
                                            <div className="sm:col-span-1">
                                                <Checkbox
                                                    path="contract"
                                                    label="Vertrag"
                                                    className="sm:col-span-6 hide-label"
                                                    onChange={handleChange}
                                                    errors={errors}
                                                    data={student}/>
                                            </div>
                                            <div className="sm:col-span-1">
                                                <DateInput
                                                    path="contract_date"
                                                    label="Datum Vertrag"
                                                    hideLabel={true}
                                                    onChange={handleDateChange}
                                                    todayButton={true}
                                                    errors={errors}
                                                    data={student}
                                                />
                                            </div>
                                            <div className="sm:col-span-2">
                                                <TextArea
                                                    path="not_joined_reason"
                                                    label="Nicht eingetreten Begründung"
                                                    onChange={handleChange}
                                                    errors={errors}
                                                    data={student}
                                                    rows={2}
                                                />
                                            </div>
                                        </dl>
                                    </div>}

                                    {!editingMode && <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
                                        <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
                                            <div className="sm:col-span-1">
                                                <dt className="text-sm font-medium text-gray-500">Vertrag</dt>
                                                <dd className="mt-1 text-sm text-gray-900 whitespace-pre-wrap">{student.contract}</dd>
                                            </div>
                                            <div className="sm:col-span-1">
                                                <dt className="text-sm font-medium text-gray-500">Datum</dt>
                                                <dd className="mt-1 text-sm text-gray-900 whitespace-pre-wrap">{formatDate(student.contract_date)}</dd>
                                            </div>
                                            <div className="sm:col-span-2">
                                                <dt className="text-sm font-medium text-gray-500">Nicht eingetreten
                                                    Begründung
                                                </dt>
                                                <dd className="mt-1 text-sm text-gray-900 whitespace-pre-wrap">{student.not_joined_reason}</dd>
                                            </div>
                                        </dl>
                                    </div>}
                                </div>
                            </section>

                            <section aria-labelledby="applicant-information-title">
                                <div className="bg-white shadow sm:rounded-lg">
                                    {editingMode && <div className="px-4 py-5 sm:px-6 flex justify-end gap-2">

                                        <div className="">
                                            {apiErrors &&
                                                <Alert extraClassName="py-2" variant="danger">{apiErrors}</Alert>}
                                            {apiSuccess &&
                                                <Alert extraClassName="py-2" variant="success">{apiSuccess}</Alert>}
                                        </div>

                                        <SecondaryButton label={"Eingabe schließen"}
                                                         onClick={() => setEditingMode(!editingMode)}/>
                                        <SubmitButton/>
                                    </div>}

                                    {!editingMode && <div className="px-4 py-5 sm:px-6 flex justify-end gap-2">
                                        <PrimaryButton label={"Bearbeiten"}
                                                       onClick={() => setEditingMode(!editingMode)}/>
                                    </div>}
                                </div>
                            </section>

                        </div>

                        <section
                            aria-labelledby="timeline-title"
                            className="lg:col-start-3 lg:col-span-1"
                        >

                            <section aria-labelledby="applicant-information-title">
                                <div className="bg-white shadow overflow-hidden sm:rounded-lg">
                                    <div className="px-4 py-5 sm:px-6">
                                        <h3 className="text-lg leading-6 font-medium text-gray-900">
                                            Status
                                        </h3>
                                    </div>
                                    {!editingMode && <div className="border-t border-gray-200 px-4 py-5 sm:p-0">
                                        <dl className="sm:divide-y sm:divide-gray-200">
                                            <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                                <dt className="text-sm font-medium text-gray-500">Status</dt>
                                                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                                    {student.get_status_display}
                                                </dd>
                                            </div>
                                            <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                                <dt className="text-sm font-medium text-gray-500">Aufnahme</dt>
                                                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                                    {student.get_acceptance_recommended_display}
                                                </dd>
                                            </div>
                                            <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                                <dt className="text-sm font-medium text-gray-500">Vertrag</dt>
                                                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                                    {student.contract ? "Ja" : "Nein"}
                                                </dd>
                                            </div>
                                        </dl>
                                    </div>}

                                    {editingMode && <div className="border-t border-gray-200 px-4 py-5 sm:p-0">
                                        <dl className="sm:divide-y sm:divide-gray-200">
                                            <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                                <Select
                                                    className="sm:col-span-3"
                                                    path="status"
                                                    label="Status"
                                                    options={options?.status?.choices}
                                                    valueAttr="value"
                                                    labelAttr="display_name"
                                                    onChange={handleChange}
                                                    errors={errors}
                                                    data={student}
                                                    isClearable={false}
                                                    valueOnly={true}
                                                />
                                            </div>
                                            <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                                <Select
                                                    className="sm:col-span-3"
                                                    path="acceptance_recommended"
                                                    label="Aufnahmeempfehlung"
                                                    options={options?.acceptance_recommended?.choices}
                                                    valueAttr="value"
                                                    labelAttr="display_name"
                                                    onChange={handleChange}
                                                    errors={errors}
                                                    data={student}
                                                    isClearable={false}
                                                    valueOnly={true}
                                                />
                                            </div>
                                            <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                                <Checkbox
                                                    path="contract"
                                                    label="Vertrag"
                                                    className="sm:col-span-6 hide-label"
                                                    onChange={handleChange}
                                                    errors={errors}
                                                    data={student}/>
                                            </div>
                                        </dl>
                                    </div>}
                                </div>
                            </section>

                            <section aria-labelledby="interview-details">
                                <div className="bg-white shadow overflow-hidden sm:rounded-lg mt-5">
                                    <div className="px-4 py-5 sm:px-6">
                                        <h3 className="text-lg leading-6 font-medium text-gray-900">
                                            Gespräch
                                        </h3>
                                    </div>

                                    {!editingMode && <div className="border-t border-gray-200 px-4 py-5 sm:p-0">
                                        <dl className="sm:divide-y sm:divide-gray-200">
                                            <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                                <dt className="text-sm font-medium text-gray-500">Datum</dt>
                                                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                                    {formatDate(student.interview_date)}
                                                </dd>
                                            </div>
                                            <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                                <dt className="text-sm font-medium text-gray-500">Teilnehmer</dt>
                                                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                                    {student.interview}
                                                </dd>
                                            </div>
                                            <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                                <dt className="text-sm font-medium text-gray-500">Teilnehmer Familie
                                                </dt>
                                                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                                    {student.interview_participants_family}
                                                </dd>
                                            </div>
                                        </dl>
                                    </div>}

                                    {editingMode && <div className="border-t border-gray-200 px-4 py-5 sm:p-0">
                                        <dl className="sm:divide-y sm:divide-gray-200">
                                            <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                                <dt className="text-sm font-medium text-gray-500">Datum</dt>
                                                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                                    <DateInput
                                                        className="col-span-3 hide-label"
                                                        path="interview_date"
                                                        label="Datum"
                                                        hideLabel={true}
                                                        onChange={handleDateChange}
                                                        todayButton={true}
                                                        errors={errors}
                                                        data={student}
                                                    />
                                                </dd>
                                            </div>
                                            <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                                <dt className="text-sm font-medium text-gray-500">Teilnehmer</dt>
                                                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                                    <Input
                                                        path="interview"
                                                        type="text"
                                                        label="Teilnehmer"
                                                        className="sm:col-span-6 hide-label"
                                                        onChange={handleChange}
                                                        errors={errors}
                                                        data={student}
                                                    />
                                                </dd>
                                            </div>
                                            <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                                <dt className="text-sm font-medium text-gray-500">Teilnehmer Familie
                                                </dt>
                                                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                                    <Input
                                                        path="interview_participants_family"
                                                        type="text"
                                                        label="Teilnehmer Familie"
                                                        className="sm:col-span-6 hide-label"
                                                        onChange={handleChange}
                                                        errors={errors}
                                                        data={student}
                                                    />
                                                </dd>
                                            </div>
                                        </dl>
                                    </div>}
                                </div>
                            </section>

                        </section>

                    </div>
                </Form>
            </main>
        </>
    )
}

const SubmitButton = () => {
    return <button type="submit"
                   className="justify-end align-middle
                                            py-2 px-4 border border-transparent
                                            shadow-sm text-sm font-medium rounded-md
                                            text-white bg-imsblue-600 hover:bg-imsblue-700
                                            focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-imsblue-500"
    >Speichern </button>
}