import {React, useEffect, useState} from 'react'
import { Switch } from '@headlessui/react'
import {saveNotificationUnsubscribes, getNotificationDefinitions} from '../../../api/notifications'
import {useQuery} from "@tanstack/react-query";
import {baseHandleListChange} from "../../elements/forms/Form";
import {Toggle} from "../../elements/forms/Inputs";
import {toast} from "react-toastify";


function NotificationItem({definition, handleNotificationChange}) {

    return (
        <Switch.Group as="div" className="relative flex items-start mb-2">
          <span className="flex flex-grow flex-col">
            <Switch.Label as="span" className="text-sm font-medium text-gray-900" passive>
            {definition.title}
            </Switch.Label>
            <Switch.Description as="span" className="text-sm text-gray-500">
              {definition.comments}
            </Switch.Description>
          </span>
          <Toggle
              path="active_subscribe"
              className="sm:col-span-6"
              onChange={handleNotificationChange}
              value={definition.active_subscribe}
              rowId={definition.id}
              />
        </Switch.Group>
    )
}

function NotificationBlock({category, data, handleNotificationChange}) {
    return (
        <div>
            <h4 className="text-lg leading-6 font-medium text-gray-900 mb-4">
                {category ? category : 'Sonstige'}
            </h4>
            {data.filter(elem => elem.category?.title === category).map(item =>
                <NotificationItem
                    key={item.id}
                    definition={item}
                    handleNotificationChange={handleNotificationChange}
                />
            )}
        </div>
    )
}

export default function NotificationUnsubscribe() {

    const [data, setData] = useState([])
    const [errors, setErrors] = useState([])

    const {data: definitionList} = useQuery(
        ["notification_definitions"],
        () => getNotificationDefinitions(),
        {refetchInterval: 1000 * 60 * 60 * 24}
    );

    function handleNotificationChange(evt, rowId) {
        baseHandleListChange(rowId, evt, data, setData, errors, setErrors, {})
    }

    useEffect(() => {
        definitionList && setData(definitionList)
    }, [definitionList])

    async function submitNotifications() {
        try {
            await saveNotificationUnsubscribes(data)
            toast.success("Benachrichtigungseinstellungen aktualisiert")
        } catch (e) {
            toast.error("Beim Speichern ist ein Fehler aufgetreten.")
        }
    }

    let arrOfCategory = []
    data.forEach(item => !arrOfCategory.includes(item.category?.title) && arrOfCategory.push(item.category?.title))

    if (!data) return <></>

    return (
        <div className="shadow sm:rounded-md sm:overflow-hidden">
            <div className="bg-white py-6 px-4 space-y-6 sm:p-6">

                <div>
                <h3 className="text-xl leading-6 font-medium text-gray-900">Benachrichtigungen</h3>
                <p className="text-sm text-gray-500 mt-2">Hier können Sie einzelne Email-Benachrichtigungen abbestellen.
                    Benachrichtigungen auf der Startseite werden davon nicht beeinträchtigt.</p>
                </div>

                {arrOfCategory.map(category =>
                    <NotificationBlock
                        key={category || "misc"}
                        category={category}
                        data={data}
                        handleNotificationChange={handleNotificationChange}
                    />
                )}
            </div>
            <div className="px-4 py-3 bg-gray-50 text-right sm:px-4">
                <button
                    onClick={submitNotifications}
                    type="submit"
                    className="py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-imsblue-600 hover:bg-imsblue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-imsblue-500"
                >
                    Speichern
                </button>
            </div>
        </div>
    )
}