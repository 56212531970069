import {SimplePage} from "../../elements/Pages";
import React, {useEffect, useState} from "react";
import ListViewTable from "../../elements/tables/ListViewTable";
import {getGradesEnteredReport, getGradesEnteredReportExcel} from "../../../api/reports";
import {DownloadButton} from "../../elements/Buttons";
import {useSchoolYears} from "../../../contexts/SchoolYearContext";
import SchoolYearFilter from "../../elements/SchoolYearFilter";


export default function GradesEnteredReport() {

    const {schoolYears, currentSchoolYear} = useSchoolYears();
    const [schoolYearId, setSchoolYearId] = useState();

    const [data, setData] = useState([])

    useEffect(() => {
        loadData()
    }, [schoolYearId])

    async function loadData() {
        if (!schoolYearId) return
        const newData = await getGradesEnteredReport(schoolYearId)
        setData(newData)
    }

    useEffect(() => {
        if (currentSchoolYear) setSchoolYearId(currentSchoolYear["id"]);
    }, [currentSchoolYear]);

    let columns = [
        {
            accessorFn: (row) => `${row.last_name}, ${row.first_name}`,
            id: "teacher_name",
            header: "LehrerIn",
            enableColumnFilter: false,
        },
        ...[1, 2, 3, 4].flatMap((e) => [
            {
                accessorKey: `results_e${e}`,
                id: `results_e${e}`,
                header: `E${e} No`,
                headerClassNames: "narrow-filter",
                enableColumnFilter: false,
                sortingFn: "basic",
                columnType: "number",
            },
            {
                accessorKey: `courses_e${e}`,
                id: `courses_e${e}`,
                header: `E${e} Ku`,
                headerClassNames: "narrow-filter",
                enableColumnFilter: false,
                sortingFn: "basic",
                columnType: "number",
            },
            {
                accessorKey: `avg_e${e}`,
                id: `avg_e${e}`,
                header: `E${e} Du`,
                headerClassNames: "narrow-filter",
                enableColumnFilter: false,
                sortingFn: "basic",
                columnType: "number",
            }
        ]),

        {
            accessorKey: "results_total",
            id: "results_total",
            header: "Gesamt",
            enableColumnFilter: false,
        },
    ]

    // function getRowLink(row) {
    //     return `/courses/${row.original.id}`;
    // }

    return (
        <SimplePage>
            <div className="px-4 sm:px-6 lg:px-8">
                <div className="sm:flex sm:items-center">
                    <div className="sm:flex-auto">
                        <h3 className="text-lg leading-6 font-medium text-gray-900">
                            Eingetragene Noten nach Lehrern
                        </h3>
                    </div>
                    <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
                        <DownloadButton
                            apiFunction={() => getGradesEnteredReportExcel(schoolYearId)}
                            fileName="Eingetragene Noten.xls"
                            label="Excel herunterladen"/>

                    </div>
                </div>
                <div className="flex flex-col">
                    <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">

                        <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                            <SchoolYearFilter
                                schoolYears={schoolYears}
                                schoolYearId={schoolYearId}
                                setSchoolYearId={setSchoolYearId}
                            />
                        </div>

                        <p className="inline-block text-sm min-w-full py-2 mt-2 md:px-6 lg:px-8">Ku = Kurse, No = eingetragene Ergebnisse/Noten, Du = Durchschnitt</p>

                        <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                            {data && (
                                <ListViewTable
                                    data={data}
                                    columns={columns}
                                    isLoading={false}
                                    disableGlobalSearch={false}
                                />
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </SimplePage>
    );
}
