import {SimplePage} from "../../elements/Pages";
import React from "react";

import {useQuery} from "@tanstack/react-query";
import ListViewTable from "../../elements/tables/ListViewTable";
import {useParams} from "react-router-dom";
import {getStudentList} from "../../../api/workshop_day";

const columns = [
    {
        accessorFn: (row) =>
            `${row.student.last_name}, ${row.student.first_name} ${row.student.student_number}`,
        id: "student_search",
        header: "SchülerIn",
        hide: true,
        enableColumnFilter: false,
    },
    {
        accessorKey: 'student',
        id: 'student',
        header: 'SchülerIn',
        columnType: 'student',
        enableColumnFilter: false,
        sortingFn: "lastNameSorting",
    },
    {
        accessorKey: "student.school_class",
        id: "school_class",
        header: "Klasse",
        enableColumnFilter: true,
        headerClassNames: "narrow-filter",
        filterFn: "containsString",
    },
    {
        accessorKey: "student.house",
        id: "house",
        header: "Mentorat",
        enableColumnFilter: true,
        headerClassNames: "narrow-filter",
        filterFn: "containsString",
    },
    {
        accessorKey: "requirements_fulfilled",
        id: "requirements_fulfilled",
        header: "Regeln erfüllt",
        columnType: "yesno",
        enableColumnFilter: true,
        filterFn: "boolean",
        headerClassNames: "narrow-filter",
    },
    {
        accessorKey: "conflicts",
        id: "conflicts",
        header: "Konflikte",
        columnType: "yesno",
        enableColumnFilter: true,
        filterFn: "boolean",
        headerClassNames: "narrow-filter",
    }
];

export default function Students() {

    const {workshopDayId} = useParams()

    const {isLoading, isError, isSuccess, data, error, refetch} = useQuery(
        ["workshop_day_student_list", workshopDayId],
        () => getStudentList(workshopDayId),
        {refetchInterval: 1000 * 60 * 60}
    );

    function getRowLink(row) {
        return `/workshop-day/${workshopDayId}/students/${row.original.student.id}/`;
    }

    if (!data) return <></>
    return (
        <SimplePage>
            <div className="px-4 sm:px-6 lg:px-8">
                <div className="sm:flex sm:items-center">
                    <div className="sm:flex-auto">
                        <h3 className="text-lg leading-6 font-medium text-gray-900">
                            {data.workshop_day.title} SchülerInnen
                        </h3>
                    </div>
                    <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
                    </div>
                </div>
                <div className="flex flex-col">
                    <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
                        <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                            {isSuccess && data && (
                                <ListViewTable
                                    data={data.students}
                                    columns={columns}
                                    isLoading={isLoading}
                                    disableGlobalSearch={false}
                                    getRowLink={getRowLink}
                                />
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </SimplePage>
    );
}
