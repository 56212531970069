import moment from "moment";
import {toast} from "react-toastify";
import React from "react";
import env from "react-dotenv";

export const containsStringNoCase = (strToCheck, subStr) => {
    return `${strToCheck}`.toLowerCase().includes(`${subStr}`.toLowerCase());
};
export const equalStringNoCase = (str1, str2) => {
    return str1.toLowerCase() === str2.toLowerCase();
};
export const stringContainsItemFromArr = (str, arr) => {
    return arr.some((v) => str.includes(v));
};

export const getUniqueArr = (array) =>
    array.filter((item, pos) => array.indexOf(item) === pos);

export const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
};
export const capitalizeFirstLetterAndSplit = (string, split) => {
    return string.split(split).map(item => item.charAt(0).toUpperCase() + item.slice(1)).join(" ")
};
export const getDaysInMonth = (year, month) => {
    return new Date(year, month, 0).getDate();
};
export const getNumberArrayWithRange = (size, startAt = 0) => {
    let arraySize = size
    let arrayStart = startAt
    if (isNaN(size)) {
        arraySize = 0
    }
    if (isNaN(startAt)) {
        arrayStart = 0
    }
    return [...Array(arraySize).keys()].map((i) => i + arrayStart);
};

export const addDaysToDate = (date, days) => {
    var date = new Date(date);
    date.setDate(date.getDate() + days);
    return date;
};

export const getWeekNumber = (d) => {
    // Copy date so don't modify original
    d = new Date(Date.UTC(d.getFullYear(), d.getMonth(), d.getDate()));

    // Thursday in current week decides the year.
    d.setDate(d.getDate() + 3 - (d.getDay() + 6) % 7);
    // January 4 is always in week 1.
    var week1 = new Date(d.getFullYear(), 0, 4);
    // Adjust to Thursday in week 1 and count number of weeks from date to week1.
    const weekN = 1 + Math.round(((d.getTime() - week1.getTime()) / 86400000
        - 3 + (week1.getDay() + 6) % 7) / 7);

    //const weekN = isoWeekN(d)
    // Set to nearest Thursday: current date + 4 - current day number
    // Make Sunday's day number 7
    // d.setUTCDate(d.getUTCDate() + 4 - (d.getUTCDay() || 7));
    // Get first day of year
    // var yearStart = new Date(Date.UTC(d.getUTCFullYear(), 0, 1));
    // Calculate full weeks to nearest Thursday
    //var weekNo = Math.ceil(((d - yearStart) / 86400000 + 1) / 7);
    // Return array of year and week number
    // return [d.getUTCFullYear(), weekNo];
    // console.log('getWeekNumber', weekN)
    return [d.getUTCFullYear(), weekN];
};

export const getFormattedTime = (event) => {
    return moment(event.start).format("hh:mm A");
};

export const copyToClipboard = (text, successMessage) => {
    navigator.clipboard.writeText(text);
    toast.success(successMessage || "Copied to clipboard");
};
export const today = new Date();

export const checkIfDateFallsBetween = (date, start, end) => {
    const fDate = new Date(start).setHours(0, 0, 0, 0);
    const lDate = new Date(end).setHours(23, 59, 59, 999);
    const cDate = new Date(date);
    if (cDate <= lDate && cDate >= fDate) {
        return true;
    }
    return false;
};
export const getDayBetweenDates = (start, end) => {
    const date_1 = new Date(end);
    const date_2 = new Date(start);
    let difference = date_1.getTime() - date_2.getTime();
    return Math.ceil(difference / (1000 * 3600 * 24));
};
export const getMonthOfWeek = (w, y) => {
    var d = 1 + (w - 1) * 7;
    const newDate = new Date(y, 0, d);
    return newDate.getMonth() + 1;
};


export function renderRating (rating) {
    if (env.SCHOOL !== 'KPI') return <></>
    const ratingIcon = rating === 'Grün' ? '/img/plupu_gruen.png' : rating === 'Orange' ? '/img/plupu_gelb.png' : rating === 'Blau' ? '/img/plupu_blau.png' : null;
    if (!ratingIcon) return <></>
    return (
        <div className="py-1 pl-2 align-middle"><img src={ratingIcon}
             alt={rating}
             width={20}
             height={20}
             className="inline-block"/></div>
    );
}