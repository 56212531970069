import React from "react";
import {getAllProducts} from "../../../api/shop";
import {useQuery} from "@tanstack/react-query";
import {Link} from "react-router-dom";
import {formatCurrency} from "../../../utils/formatters";


export default function ProductList() {

    const { isLoading, isError, isSuccess, data, error, refetch } = useQuery(
        ["products"],
        () => getAllProducts(),
        {refetchInterval: 1000 * 60 * 60 * 24}
    );

    if (!data) return <></>;

    return (
        <div className="bg-white">
            <div className="mx-auto max-w-2xl py-16 px-4 sm:py-10 sm:px-6 lg:max-w-7xl lg:px-8">
                <div className="grid grid-cols-1 gap-y-10 gap-x-6 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 xl:gap-x-8">
                    {data.map((product) => (
                        <Link key={product.id} to={`/shop/product/${product.id}`} className="group">
                            <div className="aspect-w-1 aspect-h-1 w-full overflow-hidden rounded-lg bg-gray-200 xl:aspect-w-7 xl:aspect-h-8">
                                <img
                                    src={product.images.thumbnail[0]}
                                    alt={product.imageAlt}
                                    className="h-full w-full object-cover object-center group-hover:opacity-75"
                                />
                            </div>
                            <h3 className="mt-4 text-sm text-gray-700">{product.title}</h3>
                            <p className="mt-1 text-lg font-medium text-gray-900">{formatCurrency(product.price)}</p>
                        </Link>
                    ))}
                </div>
            </div>
        </div>
    )

}
