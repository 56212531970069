import {SimplePage} from "../../elements/Pages";
import React from "react";

import {useQuery} from "@tanstack/react-query";
import {useAuth} from "../../../contexts/AuthContext";
import ListViewTable, {TableLink} from "../../elements/tables/ListViewTable";
import checkPermission from "../../../utils/permissions";
import {Link, useParams} from "react-router-dom";
import {getFlextimeBalance, getFlextimeEntries, getFlextimeExcel, getStaffInfo} from "../../../api/staff";
import {DownloadButton} from "../../elements/Buttons";

const staffColumns = [
    {
        accessorKey: "staff.full_name",
        id: "staff_search",
        header: "Mitarbeiter",
        hide: true,
        enableColumnFilter: false,
    },
    {
        accessorKey: "staff",
        id: "staff",
        header: "Mitarbeiter",
        columnType: "staff",
        enableColumnFilter: false,
    },
];

const columns = [
    {
        accessorKey: "creation_date",
        id: "creation_date",
        header: "Erstellt am",
        columnType: "date",
        enableColumnFilter: false,
        // classNames: 'font-medium text-gray-900'
        classNames: "text-gray-500",
    },
    {
        accessorKey: "year",
        id: "year",
        header: "Jahr",
        enableColumnFilter: false,
    },
    {
        accessorKey: "entry_type",
        id: "entry_type",
        header: "Art",
        enableColumnFilter: true,
        filterFn: "containsString",
        headerClassNames: "narrow-filter",
    },
    {
        accessorKey: "staff_role",
        id: "staff_role",
        header: "Funktion",
        enableColumnFilter: true,
        headerClassNames: "narrow-filter",
    },
    {
        accessorKey: "category.title",
        id: "category",
        header: "Kategorie",
        enableColumnFilter: true,
        headerClassNames: "medium-filter",
    },
    {
        accessorKey: "title",
        id: "title",
        header: "Bezeichnung",
        enableColumnFilter: false,
        headerClassNames: "narrow-filter",
    },
    {
        accessorKey: "get_status_display",
        id: "status",
        header: "Status",
        enableColumnFilter: true,
        headerClassNames: "medium-filter",
    },
    {
        accessorKey: "id",
        id: "link",
        cell: (info) => (
            <TableLink
                label="Bearbeiten"
                to={`/staff/flextime/entries/${info.getValue()}/edit`}
            />
        ),
        header: "Bearbeiten",
        enableColumnFilter: false,
        disableRowLink: true
    },
];

export default function FlextimeEntries({openOnly}) {
    const {staffId} = useParams();
    const {permissions, currentUser} = useAuth();

    const {isLoading, isError, isSuccess, data, error, refetch} = useQuery(
        ["flextime_entries", staffId, currentUser.id, openOnly], // TODO this will be problematic because cached differently for everyone
        () => getFlextimeEntries(staffId, openOnly),
    )

    const {data: staff} = useQuery(
        ["staff_info", staffId],
        () => getStaffInfo(staffId),
        {
            enabled: staffId != null,
        }
    )

    const {data: flexTimeBalance} = useQuery(
        ["flextime_balance", staffId],
        () => getFlextimeBalance(staffId),
        {
            enabled: staffId != null,
        }
    );

    function getRowLink(row) {
        return `/staff/flextime/entries/${row.original.id}/edit`;
    }

    if (!data) return <></>

    const tableColumns = staffId ? columns : staffColumns.concat(columns)

    return (
        <SimplePage>
            <div className="px-4 sm:px-6 lg:px-8">
                <div className="sm:flex sm:items-center">
                    <div className="sm:flex-auto">
                        <h3 className="text-lg leading-6 font-medium text-gray-900">
                            Flextime {staffId && "von " + staff.full_name}
                        </h3>
                        {flexTimeBalance && staffId && <p className="mt-1 text-md">
                            Kontostand: {flexTimeBalance}
                        </p>}
                    </div>
                    <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none flex gap-2">
                        {checkPermission(permissions, "staff.add_flextimeentry") && (
                            <>
                                <DownloadButton
                                    apiFunction={getFlextimeExcel}
                                    fileName={`Flextime.xls`}
                                    label="Excel herunterladen"/>

                                <Link to="/staff/flextime/entries/new">
                                    <button
                                        type="button"
                                        className="inline-flex items-center justify-center rounded-md border border-transparent bg-imsblue-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-imsblue-700 focus:outline-none focus:ring-2 focus:ring-imsblue-500 focus:ring-offset-2 sm:w-auto"
                                    >
                                        Neuer Eintrag
                                    </button>
                                </Link>
                            </>
                        )}
                    </div>
                </div>
                <div className="flex flex-col">
                    <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
                        <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                            {isSuccess && data && (
                                <ListViewTable
                                    data={data}
                                    columns={tableColumns}
                                    isLoading={isLoading}
                                    disableGlobalSearch={false}
                                    getRowLink={getRowLink}
                                />
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </SimplePage>
    );
}
