import {SimplePage} from "../../elements/Pages";
import React, {useEffect, useState} from "react";

import {useQuery} from "@tanstack/react-query";
import {useAuth} from "../../../contexts/AuthContext";
import ListViewTable, {TableEditLink, TableLink, TwoLineColumn} from "../../elements/tables/ListViewTable";
import checkPermission from "../../../utils/permissions";
import SchoolYearFilter from "../../elements/SchoolYearFilter";
import {useSchoolYears} from "../../../contexts/SchoolYearContext";
import {getClassRegisterEntries, startClassRegisterEntryExport} from "../../../api/class_register";
import {AsyncDownloadButton} from "../../elements/Buttons";
import Pagination from "../../elements/Pagination";

const columns = [
    {
        accessorKey: "date",
        id: "date",
        header: "Datum",
        columnType: "date",
        enableColumnFilter: false,
        // classNames: 'font-medium text-gray-900'
        classNames: "text-gray-500",
    },
    {
        accessorKey: "course",
        id: "course",
        header: "Kurs",
        enableColumnFilter: false,
        disableRowLink: true,
        cell: ({row, value}) => (
            <TableLink
                label={row.original.course.course_number}
                to={`/courses/${row.original.course.id}`}
            />
        ),
    },
    {
        accessorKey: "day_period",
        id: "day_period",
        header: "Zeit",
        columnType: "m2m",
        labelAttr: "name",
        enableColumnFilter: false
    },
    {
        accessorKey: "created_by_full_name",
        id: "created_by_full_name",
        header: "Erstellt von",
        // cell: (info) => <UserName link={true} userId={info.getValue()} />,
        enableColumnFilter: false,
    },
    {
        accessorKey: "cancelled",
        id: "cancelled",
        header: "Ausfall",
        disableRowLink: true,
        // columnType: "yesno",
        cell: ({ row, value }) => (
            <TwoLineColumn
                line1={row.original.cancelled ? "Ja" : "Nein"}
                line2={row.original.cancelled ? `${row.original.cancel_reason}` : ''}
            />),
        enableColumnFilter: false,
        headerClassNames: "narrow-filter",
        classNames: "max-w-xs text-gray-500",
    },
    {
        accessorKey: "lesson_contents",
        id: "lesson_contents",
        header: "Unterrichtsinhalt",

        cell: ({ row, value }) => (
            <div className="font-grey-500 whitespace-pre-wrap">
                {row.original.lesson_contents}
            </div>
        ),
        enableColumnFilter: false,
        headerClassNames: "medium-filter",
        classNames: "max-w-md text-gray-500",
    },
    // {
    //     accessorKey: "homework",
    //     id: "homework",
    //     header: "Hausaufgaben",
    //     enableColumnFilter: false,
    //     cell: ({ row, value }) => (
    //         <ul>
    //             {row.original.homework.map((hw, index) => (
    //                 <li key={index}>{hw.task} {hw.due_date && <span>(zum {formatDate(hw.due_date)})</span>}</li>
    //             ))}
    //         </ul>),
    //     headerClassNames: "medium-filter",
    //     classNames: "max-w-md text-gray-500",
    // },
    {
        accessorKey: "id",
        id: "link",
        cell: ({row, value}) => (
            row.original.can_edit ? <TableEditLink
                iconOnly={true}
                label="Bearbeiten"
                to={`/class-register/entries/${row.original.id}/edit`}
            /> : <></>
        ),
        header: "",
        disableRowLink: true,
        enableColumnFilter: false,
    },
];


export default function ClassRegisterEntries() {

    const { permissions } = useAuth();
    const { schoolYears, currentSchoolYear } = useSchoolYears();
    const [rowSelection, setRowSelection] = useState([]);
    const [schoolYearId, setSchoolYearId] = useState();
    const [page, setPage] = useState(1);

    const [data, setData] = useState([])
    const [searchQuery, setSearchQuery] = useState("")
    const [totalCount, setTotalCount] = useState(0)

    const { isLoading, isError, isSuccess, data: rawData, error, refetch } = useQuery(
        ["class_register_entries", schoolYearId, page, searchQuery],
        () => getClassRegisterEntries(schoolYearId, page, searchQuery),
        {
            enabled: !!schoolYearId,
            // refetchInterval: 1000 * 60 * 60,
            keepPreviousData : true
        }
    );

    useEffect(() => {
        setData(rawData?.results.length ? rawData.results : [])
        setTotalCount(rawData?.count)
    }, [rawData])

    useEffect(() => {
        if (currentSchoolYear) setSchoolYearId(currentSchoolYear["id"]);
    }, [currentSchoolYear]);

    useEffect(() => {
        refetch()
    }, [page, searchQuery])

    useEffect(() => {
        setPage(1) // page needs to be reset when search or filters change
    }, [searchQuery])

    function getRowLink(row) {
        return `/class-register/entries/${row.original.id}/`;
    }

    // if (!data) return <></>
    return (
        <SimplePage>
            {/*  Simple striped table */}
            <div className="px-4 sm:px-6 lg:px-8">
                <div className="sm:flex sm:items-center">
                    <div className="sm:flex-auto">
                        <h3 className="text-lg leading-6 font-medium text-gray-900">
                            Klassenbucheinträge
                        </h3>
                    </div>
                    <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
                        {checkPermission(
                            permissions,
                            "class_register.export_all_classregisterentries"
                        ) && (
                            <AsyncDownloadButton
                                apiFunction={() => startClassRegisterEntryExport()}
                                label="Liste herunterladen" />
                        )}
                    </div>
                </div>
                <div className="flex flex-col">
                    <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
                        <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                            <SchoolYearFilter
                                schoolYears={schoolYears}
                                schoolYearId={schoolYearId}
                                setSchoolYearId={setSchoolYearId}
                            />
                        </div>

                        <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                            <Pagination
                                page={page} setPage={setPage}
                                pageSize={50} totalResults={totalCount}
                            />
                        </div>

                        <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                            {isSuccess && data && (
                                <ListViewTable
                                    filterMode={"api"}
                                    data={data}
                                    columns={columns}
                                    isLoading={isLoading}
                                    disableGlobalSearch={false}
                                    rowSelection={rowSelection}
                                    setRowSelection={setRowSelection}
                                    getRowLink={getRowLink}
                                    searchQuery={searchQuery}
                                    setSearchQuery={setSearchQuery}
                                />
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </SimplePage>
    );
}
