import {NarrowPage} from "../../elements/Pages";
import {React, useEffect, useState} from "react";
import Joi from "joi"
import {useQuery} from '@tanstack/react-query'
import {DateInput, Input, MultiSelect, TextArea} from "../../elements/forms/Inputs"
import {FormSectionContent} from "../../elements/forms/FormLayout"
import Form, {baseHandleChange, baseHandleDateChange} from "../../elements/forms/Form"
import {useNavigate, useParams} from "react-router-dom";
import {toast} from "react-toastify";
import {
    addFileToDownload,
    deleteDownload,
    getDownload,
    getDownloadCategories,
    patchDownload,
    postDownload
} from "../../../api/downloads";
import {getGroups} from "../../../api/accounts";
import {useSchoolYears} from "../../../contexts/SchoolYearContext";
import {addFileToTutoringMaterial, getTutoringMaterial, updateTutoringMaterial,
    deleteTutoringMaterial,
    createTutoringMaterial} from "../../../api/tutoring";


export default function MaterialCreateEdit() {

    const {id, tutoringId} = useParams()
    const [data, setData] = useState({
        id: null,
        tutoring: parseInt(tutoringId) || null,
        title: "",
        description: ""
    })

    const [selectedFile, setSelectedFile] = useState();
    const [isFilePicked, setIsFilePicked] = useState(false);

    const [errors, setErrors] = useState({}) // validation errors

    let navigate = useNavigate();

    useEffect(() => {
        loadData()
    }, [id])

    async function loadData() {
        if (!id) return
        const newData = await getTutoringMaterial(id)
        setData(newData)
    }

    const schema = {
        title: Joi.string().min(3)
    }

    const title = (id || data.id) ? "Födermaterial bearbeiten" : "Neues Födermaterial einstellen"
    const successMessage = (id || data.id) ? "Födermaterial aktualisiert" : "Neues Födermaterial eingestellt"

    async function onSave() {
        // saving the instance first
        let newData
        if (!data.id) newData = await createTutoringMaterial(data)
        else newData = await updateTutoringMaterial(data)

        // then adding the file
        if (isFilePicked) {
            const formData = new FormData();
            formData.append('file', selectedFile);
            newData = await addFileToTutoringMaterial(formData, newData.id)
        }

        setData(newData)
        toast.success(successMessage)
        navigate(`/tutoring/${newData.tutoring}`)
    }

    async function onDelete() {
        await deleteTutoringMaterial(data.id)
        toast.success("Fördermaterial gelöscht.")
        navigate(`/tutoring/${data.tutoring}`)
    }

    function handleChange(evt) {
        baseHandleChange(evt, data, setData, errors, setErrors, schema)
    }

    const handleChangeFile = (event) => {
        setSelectedFile(event.target.files[0])
        setIsFilePicked(true)
    }

    return (

        <NarrowPage title={title}>

            <Form id="createLogbookEntry" onSave={onSave}
                  onDelete={onDelete}
                  data={data} setData={setData}
                  errors={errors} setErrors={setErrors}
                  successMessage={successMessage}
                  schema={schema}>

                <FormSectionContent>

                    <Input
                        path="title"
                        type="text"
                        label="Titel"
                        className="sm:col-span-6"
                        onChange={handleChange}
                        errors={errors}
                        data={data}
                    />

                    <TextArea
                        path="description"
                        label="Beschreibung"
                        className="sm:col-span-6"
                        onChange={handleChange}
                        errors={errors}
                        data={data}
                    />

                    <div className="sm:col-span-6 text-sm">
                        <input type="file" name="file" onChange={handleChangeFile}/> <br/>
                        <div>
                            {data.file && <p className="py-1">Bisherige Datei: {data.file}</p>}
                        </div>
                    </div>

                </FormSectionContent>

            </Form>

            <span onClick={() => navigate(-1)}
                  className="cursor-pointer float-right mt-2 font-medium text-imsblue-600">Zurück zur Liste</span>

        </NarrowPage>
    )
}
