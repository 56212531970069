import {SimplePage} from "../../elements/Pages";
import React from 'react';

import {useQuery} from '@tanstack/react-query'
import ListViewTable, {TableLink} from "../../elements/tables/ListViewTable";
import {getHouseExcel, houseApi} from "../../../api/boarding_school";
import {DownloadButton} from "../../elements/Buttons";


const columns = [
    {
        accessorKey: 'name',
        id: 'name',
        header: 'Name',
        enableColumnFilter: false,
        headerClassNames: "medium-filter"
    },
    {
        id: 'mentors',
        accessorKey: 'mentors',
        header: 'Mentoren',
        columnType: 'm2m',
        enableColumnFilter: false,
        labelAttr: "full_name",
        filterFn: "containsString",
    },
    {
        accessorKey: 'max_number_of_students',
        id: 'max_number_of_students',
        header: 'belegt/Kapazität (frei)',
        cell: ({
                   row,
                   value
               }) => `${row.original.number_of_students} ` + (row.original.max_number_of_students ? `/ ${row.original.max_number_of_students}  (${row.original.free_places})` : ''), // TODO
        enableColumnFilter: false,
        classNames: "max-w-md text-gray-500 break-all",
        headerClassNames: "max-w-md"
    },
    // {
    //     accessorKey: 'departure_lists',
    //     id: 'departure_lists',
    //     header: 'Heimfahrtslisten',
    //     cell: ({
    //                row,
    //                value
    //            }) => (<div className="flex gap-4">
    //         <TableLink label="Ansehen" to={`/boarding-school/departure-lists/houses/${row.original.id}`} />
    //         <TableLink label="Erfassen" to={`/boarding-school/departure-lists/new/${row.original.id}`} />
    //     </div>),
    //     enableColumnFilter: false,
    //     classNames: "max-w-md text-gray-500 break-all",
    //     headerClassNames: "max-w-md",
    //     disableRowLink: true
    // }
]


export default function HouseList() {

    const {isLoading, isError, isSuccess, data, error, refetch} = useQuery(['houses'], () => houseApi('get'), {refetchInterval: 1000 * 60 * 60 * 24})

    function getRowLink(row) {
        return `/boarding-school/houses/${row.original.id}/`
    }

    return (

        <SimplePage>
            <div className="px-4 sm:px-6 lg:px-8">

                <div className="sm:flex sm:items-center">
                    <div className="sm:flex-auto">
                        <h3 className="text-lg leading-6 font-medium text-gray-900 mb-2">
                            Mentorate
                        </h3>
                    </div>
                    <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
                        <DownloadButton
                            apiFunction={() => getHouseExcel()}
                            fileName={`Mentorate.xls`}
                            label="Liste herunterladen"/>
                    </div>
                </div>

                <div className="flex flex-col">
                    <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">

                        <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                            {isSuccess && data && <ListViewTable data={data}
                                                                 columns={columns}
                                                                 isLoading={isLoading}
                                                                 disableGlobalSearch={false}
                                                                 getRowLink={getRowLink}
                            />}
                        </div>
                    </div>
                </div>
            </div>
        </SimplePage>
    )
}
