import {SimplePage} from "../../elements/Pages";
import React from "react";

import {useQuery} from "@tanstack/react-query";
import ListViewTable, {TableEditLink} from "../../elements/tables/ListViewTable";
import {useParams} from "react-router-dom";
import {getStudentsForHouse} from "../../../api/boarding_school";
import checkPermission from "../../../utils/permissions";
import {useAuth} from "../../../contexts/AuthContext";


export default function BoardingSchoolAssessmentHouseDetail() {

    const {assessmentPeriodId, houseId} = useParams()
    const {permissions} = useAuth()

    const {isLoading, isError, isSuccess, data, error, refetch} = useQuery(
        ["students_for_house", assessmentPeriodId, houseId],
        () => getStudentsForHouse(assessmentPeriodId, houseId),
        {refetchInterval: 1000 * 60 * 60 * 24}
    );

    function getRowLink(row) {
        return `/assessments/boarding-school/${row.original.assessment.id}`
    }

    const canEdit = (data && data.assessment_period.is_active) || checkPermission(permissions, "assessments.change_assessments_anytime")

    const columns = [
        {
            accessorFn: (row) =>
                `${row.last_name}, ${row.first_name} ${row.student_number}`,
            id: "student_search",
            header: "SchülerIn",
            hide: true,
            enableColumnFilter: false,
        },
        {
            accessorFn: (row) => row.student,
            id: 'student',
            header: 'SchülerIn',
            columnType: 'student',
            enableColumnFilter: false,
            sortingFn: "lastNameSorting",
        },
        {
            accessorKey: "student.school_class",
            id: "school_class",
            header: "Klasse",
            enableColumnFilter: true,
            headerClassNames: "narrow-filter",
        },
        {
            accessorKey: "status",
            id: "status",
            header: "Status",
            enableColumnFilter: true,
            headerClassNames: "narrow-filter",
            cell: ({row, value}) => (
                <>{row.original.assessment.finalized ? "abgeschlossen" : "offen"}</>
            )
        },
        {
            accessorKey: "id",
            id: "link",
            cell: ({row, value}) => (
                 <>{canEdit && <TableEditLink
                    iconOnly={true}
                    label="Bearbeiten"
                    to={`/assessments/boarding-school/${row.original.assessment.id}/edit`}
                />}</>
            ),
            header: "",
            enableColumnFilter: false,
            disableRowLink: true
        },
    ];

    if (!data) return <></>
    return (
        <SimplePage>
            <div className="px-4 sm:px-6 lg:px-8">
                <div className="sm:flex sm:items-center">
                    <div className="sm:flex-auto">
                        <h3 className="text-lg leading-6 font-medium text-gray-900">
                            Internatsbeurteilungen {data.house.name} {data.assessment_period.name}
                        </h3>
                    </div>
                    <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
                    </div>
                </div>
                <div className="flex flex-col">
                    <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
                        <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                            {isSuccess && data && (
                                <ListViewTable
                                    data={data.rows}
                                    columns={columns}
                                    isLoading={isLoading}
                                    disableGlobalSearch={false}
                                    getRowLink={getRowLink}
                                />
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </SimplePage>
    );
}
