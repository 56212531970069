import {React, useEffect, useState} from "react";
import {PrimaryButton, SecondaryButton} from "../../elements/Buttons";
import {Input} from "../../elements/forms/Inputs";
import {baseHandleListChange} from "../../elements/forms/Form";
import {FormSectionContent} from "../../elements/forms/FormLayout";
import {toast} from "react-toastify";
import {getMyAlumniChildren, updateMyAlumniChildren} from "../../../api/alumni";
import {formatDate} from "../../../utils/formatters";

export default function MyChildren() {

    const [data, setData] = useState([])
    const [listErrors, setListErrors] = useState([])
    const [editingMode, setEditingMode] = useState(false)

    const schema = {}

    useEffect(() => {
        loadData()
    }, [])

    async function loadData() {
        const newData = await getMyAlumniChildren()
        setData(newData)
    }

    async function handleSave() {
        try {
            const newData = await updateMyAlumniChildren(data)
            setData(newData)
            toast.success("Kontaktdaten gespeichert")
            setEditingMode(false)
        } catch (e) {
            toast.error("Beim Speichern ist ein Fehler aufgetreten.")
        }
    }

    function handleListChange(evt, rowId) {
        baseHandleListChange(rowId, evt, data, setData, listErrors, setListErrors, schema)
    }

    return (
        <>
            <main className="py-10 h-full bg-gray-100">
                {/* Page header */}
                <div
                    className="max-w-3xl mx-auto px-4 sm:px-6 md:flex md:items-center md:justify-between md:space-x-5 lg:max-w-7xl lg:px-8">
                    <div className="flex items-center space-x-5">
                        <div>
                            <h1 className="text-2xl font-bold text-gray-900">
                                Kontaktdaten meiner Kinder bearbeiten
                            </h1>

                            <p className="mt-1 text-sm text-gray-500">Wenn Du hier die Daten deiner Kinder hinterlegst, schicken wir diesen zu besonderen Anlässen ein kleines Geschenk. Neugeborene erhalten zum Beispiel eine Schloss Torgelow Babydecke. Kindergartenkinder unser Malbuch. Schulanfänger ein Starterset.</p>
                        </div>
                    </div>
                    <div
                        className="mt-6 flex flex-col-reverse justify-stretch space-y-4 space-y-reverse sm:flex-row-reverse sm:justify-end sm:space-x-reverse sm:space-y-0 sm:space-x-3 md:mt-0 md:flex-row md:space-x-3">
                    </div>
                </div>

                <div
                    className="mt-8 max-w-3xl mx-auto grid grid-cols-1 gap-6 sm:px-6 lg:max-w-7xl lg:grid-flow-col-dense lg:grid-cols-3">
                    <div className="space-y-6 lg:col-start-1 lg:col-span-2">

                        {data &&
                            <section aria-labelledby="alumni-children-contact-info">

                                {data.map((alumnus, index) => (
                                    <div key={index} className="bg-white shadow overflow-hidden sm:rounded-lg mb-4">

                                        <div className="border-t border-gray-200 px-4 py-5">

                                            {editingMode &&
                                                <div className="">
                                                    <dl className="sm:divide-y sm:divide-gray-200">

                                                        <div
                                                            className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                                            <dt className="text-sm font-medium text-gray-500">Name</dt>
                                                            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                                                <h3 className="text-md leading-6 font-medium text-gray-900">{alumnus.student.full_name}</h3>
                                                            </dd>
                                                        </div>

                                                        <div
                                                            className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                                            <dt className="text-sm font-medium text-gray-500">Email</dt>
                                                            <dd className="mt-1 text-sm text-gray-900 col-span-2">
                                                                <Input
                                                                    path="student.email"
                                                                    type="email"
                                                                    label="Email"
                                                                    className="sm:col-span-6"
                                                                    onChange={handleListChange}
                                                                    errors={listErrors}
                                                                    data={data}
                                                                    rowId={alumnus.id}
                                                                />
                                                            </dd>
                                                        </div>
                                                        <div
                                                            className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                                            <dt className="text-sm font-medium text-gray-500">Adresse</dt>
                                                            <dd className="mt-1 text-sm text-gray-900 col-span-2">

                                                                <div
                                                                    className={"grid grid-cols-1 gap-x-4 gap-y-4 sm:grid-cols-6"}>
                                                                    <Input
                                                                        path="street"
                                                                        type="text"
                                                                        label="Straße"
                                                                        className="sm:col-span-6"
                                                                        onChange={handleListChange}
                                                                        errors={listErrors}
                                                                        data={data}
                                                                        rowId={alumnus.id}
                                                                    />

                                                                    <Input
                                                                        path="zip_code"
                                                                        type="text"
                                                                        label="PLZ"
                                                                        className="sm:col-span-3"
                                                                        onChange={handleListChange}
                                                                        errors={listErrors}
                                                                        data={data}
                                                                        rowId={alumnus.id}
                                                                    />
                                                                    <Input
                                                                        path="city"
                                                                        type="text"
                                                                        label="Ort"
                                                                        className="sm:col-span-3"
                                                                        onChange={handleListChange}
                                                                        errors={listErrors}
                                                                        data={data}
                                                                        rowId={alumnus.id}
                                                                    />

                                                                    <Input
                                                                        path="country"
                                                                        type="text"
                                                                        label="Land"
                                                                        className="sm:col-span-6"
                                                                        onChange={handleListChange}
                                                                        errors={listErrors}
                                                                        data={data}
                                                                        rowId={alumnus.id}
                                                                    />
                                                                </div>

                                                            </dd>
                                                        </div>
                                                    </dl>
                                                </div>}

                                            {!editingMode &&
                                                <dl className="sm:divide-y sm:divide-gray-200">
                                                    <div
                                                        className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                                        <dt className="text-sm font-medium text-gray-500">Name</dt>
                                                        <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                                            <h3 className="text-md leading-6 font-medium text-gray-900">{alumnus.student.full_name}</h3>
                                                        </dd>
                                                    </div>
                                                    <div
                                                        className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                                        <dt className="text-sm font-medium text-gray-500">Email</dt>
                                                        <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2 whitespace-pre-wrap">{alumnus.student.email}
                                                        </dd>
                                                    </div>
                                                    <div
                                                        className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                                        <dt className="text-sm font-medium text-gray-500">Adresse</dt>
                                                        <dd className="mt-1 text-sm text-gray-900">
                                                            {alumnus.street} <br/>
                                                            {alumnus.zip_code} {alumnus.city} <br/>
                                                            {alumnus.country}
                                                        </dd>
                                                    </div>
                                                    <div
                                                        className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                                        <dt className="text-sm font-medium text-gray-500">Letzte Aktualisierung</dt>
                                                        <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                                            <h3 className="text-md leading-6 font-medium text-gray-900">{formatDate(alumnus.last_updated)}</h3>
                                                        </dd>
                                                    </div>
                                                </dl>}

                                        </div>
                                    </div>))}


                            </section>}


                        <div className="flex justify-between">

                            {editingMode &&

                                <div className="flex justify-end gap-2">
                                    <SecondaryButton
                                        onClick={() => setEditingMode(false)}
                                        classNameIn="my-4" label="Abbrechen"/>
                                    <PrimaryButton
                                        classNameIn="my-4"
                                        onClick={handleSave} label="Speichern"/>

                                </div>
                            }

                            {!editingMode && <PrimaryButton
                                classNameIn="my-4"
                                onClick={() => setEditingMode(true)} label="Bearbeiten"/>}
                        </div>


                    </div>


                </div>

            </main>
        </>
    )
}
