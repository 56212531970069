import {SimplePage} from "../../elements/Pages";
import React, {useState} from "react";

import {useQuery} from "@tanstack/react-query";
import {useAuth} from "../../../contexts/AuthContext";
import ListViewTable, {TableEditLink, TableLink, TwoLineColumn,} from "../../elements/tables/ListViewTable";
import checkPermission from "../../../utils/permissions";
import {classNames} from "../../../utils/misc";
import {getSubstitutionExcel, getTimetableChanges} from "../../../api/timetables";
import {DownloadButton, PrimaryButton} from "../../elements/Buttons";
import {getAnnouncements, keyIssueApi, purchaseRequestApi} from "../../../api/organizational";


export default function KeyIssues() {

    const {permissions, currentUser} = useAuth();
    const [recentOrAll, setRecentOrAll] = useState("recent");

    const {isLoading, isError, isSuccess, data, error, refetch} = useQuery(
        ["key_issues", currentUser.user_id],
        () => keyIssueApi('get'),
        {refetchInterval: 1000 * 60 * 60}
    )

    const canIssueKeys = checkPermission(permissions, "organizational.add_keyissue")

    function getRowLink(row) {
        return canIssueKeys ? `/service/key-issues/${row.original.id}/edit` : null
    }


    const columns = [
        {
            accessorKey: "key.key_number",
            id: "key",
            header: "Schlüssel",
            enableColumnFilter: false,
            headerClassNames: "medium-filter",
        },
        {
            accessorKey: "user.full_name",
            id: "user",
            header: "Ausgabe an",
            enableColumnFilter: true,
            headerClassNames: "medium-filter"
        },
        {
            accessorKey: "issue_date",
            id: "issue_date",
            header: "Ausgegeben am",
            columnType: "date",
            enableColumnFilter: false,
        },
        {
            accessorKey: "return_date",
            id: "return_date",
            header: "Zurückgegeben am",
            columnType: "date",
            enableColumnFilter: false,
        },
        {
            accessorKey: "key_insurance",
            id: "key_insurance",
            header: "Schlüsselversicherung",
            columnType: "yesno",
            enableColumnFilter: false,
        },
        {
            accessorKey: "created_by_full_name",
            id: "created_by_full_name",
            header: "Ausgegeben von",
            enableColumnFilter: true
        },
        ...canIssueKeys ? [ {
            accessorKey: "id",
            id: "edit",
            header: "Bearbeiten",
            enableColumnFilter: false,
            disableRowLink: true,
            cell: ({row, value}) => (
                <TableEditLink iconOnly={true} to={`/service/key-issues/${row.original.id}/edit`}/>
            )
        }] : [],
    ]


    return (
        <SimplePage title="Schlüsselausgaben">

            <div className="px-4 sm:px-6 lg:px-8">
                <div className="sm:flex sm:items-center">
                    <div className="sm:flex-auto">
                        <h3 className="text-lg leading-6 font-medium text-gray-900">
                            Schlüsselausgaben
                        </h3>
                    </div>
                    <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
                        {canIssueKeys && <PrimaryButton
                            label="Schlüssel ausgeben"
                            link={`/service/key-issues/new`}
                        />}
                    </div>
                </div>
                <div className="flex flex-col">
                    <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
                        <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                            {isSuccess && data && data?.length > 0 && (
                                <ListViewTable
                                    data={data}
                                    columns={columns}
                                    isLoading={isLoading}
                                    disableGlobalSearch={false}
                                    getRowLink={getRowLink}
                                />
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </SimplePage>
    )
}
