import React, {useEffect, useState} from "react";
import {useAuth} from "../../../contexts/AuthContext";
import checkPermission from "../../../utils/permissions";
import {useParams} from "react-router-dom";
import {
    deleteBooking,
    getBooking,
    getStaff,
    getTeacherSlots,
    startAllAppointmentListGeneration,
    updateBooking
} from "../../../api/parent_conference";
import {SimplePage} from "../../elements/Pages";
import {PencilIcon, TrashIcon} from "@heroicons/react/20/solid";
import {toast} from "react-toastify";
import {useQuery} from "@tanstack/react-query";
import EditBookingModal from "./BookingEditModal";
import {AsyncDownloadButton} from "../../elements/Buttons";

export default function Teachers() {

    const {conferenceDayId} = useParams();
    const {permissions} = useAuth();
    const [data, setData] = useState()
    const [booking, setBooking] = useState()
    const [modalOpen, setModalOpen] = useState(false)

    const {isLoading, isError, isSuccess, data: staff, error, refetch} = useQuery(
        ["parent_conference_day_staff", conferenceDayId],
        () => getStaff(conferenceDayId),
        {refetchInterval: 1000 * 60 * 60}
    )

    useEffect(() => {
        loadData()
    }, [])

    async function loadData() {
        const newData = await getTeacherSlots(conferenceDayId)
        setData(newData)
    }

    async function handleEditBooking(slot) {
        // load the booking and open the modal
        const newBooking = await getBooking(slot.booking_id)
        setBooking(newBooking)
        setModalOpen(true)
    }

    async function handleSaveBooking() {
        try {
            const newBooking = await updateBooking(booking.id, booking)
            toast.success("Termin aktualisiert")
        } catch (e) {
            toast.error(e.data.status)
        }
    }

    function handleMultiSelect(selectedOptions, path) {
        let newBooking = {...booking}
        newBooking[path] = selectedOptions
        setBooking(newBooking)
    }

    async function handleDelete(slot) {
        await deleteBooking(slot.booking_id)
        toast.success("Termin gelöscht")

        var newRows = {}
        for (const [key, value] of Object.entries(data.rows)) {
            const newColumns = value.columns.map(column => {
                if (column.teacher_id == slot.teacher_id && column.start_time == slot.start_time) {
                    // new booking for this slot
                    return {...column, booking_id: null, status: 'available'}
                }

                return column
            })

            newRows[key] = {
                "columns": newColumns
            }
        }

        const newData = {...data, rows: newRows}
        setData(newData)
    }

    const canChangeConferenceDay = checkPermission(permissions, "parent_conference.change_conferenceday")

    if (isLoading) return <>Lädt...</>
    if (!data) return <></>;
    return (
        <>
            <SimplePage>

                <div className="px-4 sm:px-6 lg:px-8">
                    <div className="sm:flex sm:items-center">
                        <div className="sm:flex-auto">
                            <h3 className="text-lg leading-6 font-medium text-gray-900">
                                {data.conference_day.title} - Terminübersicht Mitarbeiter
                            </h3>
                        </div>
                        <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none flex gap-1">
                            <AsyncDownloadButton
                                apiFunction={() => startAllAppointmentListGeneration(data.conference_day.id)}
                                label="Alle Terminlisten erstellen"/>
                        </div>
                    </div>
                    <div className="flex flex-col mt-3 mb-3 scrollbarHeight">

                        <div className="scrollableTableContainer">

                            <div className="scrollableContent">
                                <table className="min-w-full divide-y divide-gray-300">
                                    <thead className="bg-imsblue-900 text-white text-sm">
                                        <tr>
                                            <th key="timeLeft"></th>
                                            {data.headers.map((header, index) => (
                                                <th className="stickyHeader bg-imsblue-900 text-white text-sm" key={index}>
                                                    {header.teacher_name} <br/><br/>
                                                    {header.teacher_room}
                                                </th>
                                            ))}
                                            <th key="timeRight"></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {Object.keys(data.rows).map((key, index) => (
                                            <tr key={index}>
                                                <td className="py-1 px-1" key="slotTimeLeft">{key}</td>
                                                {data.rows[key].columns.map((column, columnIndex) => (
                                                    <Slot slot={column}
                                                          key={columnIndex}
                                                          startTime={key}
                                                          canChangeConferenceDay={canChangeConferenceDay}
                                                          canBookAppointment={data.can_book_appointment}
                                                          onDelete={() => handleDelete(column)}
                                                          onBooking={() => handleEditBooking(column)}
                                                    />
                                                ))}
                                                <td className="py-1 px-1" key="slotTimeRight">{key}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>

                    </div>
                </div>
            </SimplePage>
            <EditBookingModal open={modalOpen} setOpen={setModalOpen}
                              booking={booking} staff={staff}
                              onMultiSelect={handleMultiSelect}
                              onSave={handleSaveBooking}/>
        </>
    );
}

function Slot({
                  startTime,
                  slot,
                  canChangeConferenceDay,
                  onBooking,
                  onDelete
              }) {

    // const [booking, setBooking] = useState()

    var tdClassNames
    var slotContent

    if (slot.status === 'blocked') {

        tdClassNames = "bg-gray-300"
        slotContent =
            <>
                <p className="text-sm">
                    {slot.title}
                </p>

                {canChangeConferenceDay && slot.blocked_slot_can_be_unblocked && slot.can_still_be_unblocked && <>
                    Remove blocked slot TODO
                </>}
            </>

    } else if (slot.booking_id) {

        if (slot.status === 'own_booking') {
            tdClassNames = 'bg-imsblue-300'
            slotContent =
                <>
                    <p className="text-sm text-gray-900">{slot.booking_student_name}</p>

                    {slot.remarks &&
                        <p className="text-sm text-gray-900">{slot.remarks}</p>
                    }

                    {canChangeConferenceDay && <>
                        <PencilIcon className="h-5 w-5 text-blue-400" aria-hidden="true" onClick={onBooking}/>
                        <TrashIcon className="h-5 w-5 text-blue-400" aria-hidden="true" onClick={onDelete}/>
                    </>}

                    <p className="text-sm text-gray-500">{startTime}</p>
                </>
        } else {
            tdClassNames = 'bg-imsblue-300'
            slotContent =
                <>
                    <p className="text-sm text-gray-900">{slot.booking_student_name}</p>
                    <p className="text-sm text-gray-900">{slot.remarks}</p>

                    {canChangeConferenceDay && <>
                        <PencilIcon className="h-5 w-5 text-blue-400" aria-hidden="true" onClick={onBooking}/>
                        <TrashIcon className="h-5 w-5 text-blue-400" aria-hidden="true" onClick={onDelete}/>
                    </>}

                    <p className="text-sm text-gray-500">{startTime}</p>
                </>
        }

    } else {
        slotContent =
            <>
                <p className="text-sm text-gray-500">{slot.teacher_name}</p>
                <p className="text-sm text-gray-500">{startTime}</p>
            </>
    }

    return <td className={"py-1 px-1 min-w-4 " + tdClassNames}>{slotContent}</td>
}


