import {SimplePage} from "../../elements/Pages";
import React, {useEffect, useState} from "react";

import {useQuery} from "@tanstack/react-query";
import {useAuth} from "../../../contexts/AuthContext";
import ListViewTable, {TableDownloadLink, TableLink} from "../../elements/tables/ListViewTable";
import checkPermission from "../../../utils/permissions";
import SchoolYearFilter from "../../elements/SchoolYearFilter";
import {useSchoolYears} from "../../../contexts/SchoolYearContext";
import {getFacharbeitExcel, getGfsCompleteList, getGfsExcel, getGfsList, getGfsPDF} from "../../../api/school";
import {classNames} from "../../../utils/misc";
import {DownloadButton, PrimaryButton} from "../../elements/Buttons";


export default function GfsList() {

    const {permissions} = useAuth();
    const search = window.location.search;
    const params = new URLSearchParams(search);
    const studentId = params.get('student_id')

    const {schoolYears, currentSchoolYear} = useSchoolYears();
    const [schoolYearId, setSchoolYearId] = useState();
    const [type, setType] = useState('ust');

    const {isLoading, isError, isSuccess, data, error, refetch} = useQuery(
        ["gfs", schoolYearId, type],
        () => getGfsList(schoolYearId, type, studentId),
        {
            enabled: !!schoolYearId, refetchInterval: 1000 * 60 * 60 * 24
        }
    )

    const {data: kursstufeStudentCount} = useQuery(['gfs_kursstufe_student_count'], getGfsCompleteList, {refetchInterval: 1000 * 60 * 60 * 24})

    useEffect(() => {
        if (currentSchoolYear) setSchoolYearId(currentSchoolYear["id"]);
    }, [currentSchoolYear]);

    function getRowLink(row) {
        return `/grades/gfs/${row.original.id}`
    }

    const columns = [
        {
            accessorFn: (row) =>
                `${row.student.last_name}, ${row.student.first_name} ${row.student.student_number}`,
            id: "student_search",
            header: "SchülerIn",
            hide: true,
            enableColumnFilter: false,
        },
        {
            accessorKey: "student",
            id: "student",
            header: "SchülerIn",
            columnType: "student",
            enableColumnFilter: false,
            sortingFn: "lastNameSorting",
        },
        {
            accessorKey: "student.school_class",
            id: "school_class",
            header: "Klasse",
            enableColumnFilter: true,
            headerClassNames: "medium-filter",
        },
        {
            accessorKey: "course.course_number",
            id: "course",
            header: "Kurs",
            enableColumnFilter: true,
            headerClassNames: "narrow-filter",
        },
        {
            accessorKey: "course.school_subject",
            id: "school_subject",
            header: "Fach",
            enableColumnFilter: true,
            // classNames: 'font-medium text-gray-900'
            classNames: "text-gray-500",
        },
        {
            accessorKey: "topic",
            id: "topic",
            header: "Thema",
            enableColumnFilter: false,
        },
        {
            accessorKey: "course.teacher.full_name",
            id: "teacher",
            header: "Lehrer",
            enableColumnFilter: false
        },
        ...type === 'ost' ? [
        {
            accessorKey: "half_year",
            id: "half_year",
            header: "Halbjahr",
            enableColumnFilter: true,
            headerClassNames: "narrow-filter",
        }] : [],
        {
            accessorKey: "date",
            id: "date",
            header: "Datum",
            columnType: "date",
            enableColumnFilter: false
        },
        {
            accessorKey: "mark",
            id: "mark",
            header: "Note",
            enableColumnFilter: false,
            headerClassNames: "narrow-filter",
            cell: ({row, value}) => (
                row.original.result_line && (row.original.result_line.can_edit ?
                    <TableLink label={`${row.original.result_line.mark}`}
                               to={`/grades/results/${row.original.result_line.result_id}/edit`}/>
                    : <>{row.original.result_line.mark}</>)
            ),
            disableRowLink: true,
        },
        ...type === 'ost' ? [
            {
                accessorKey: "ost_count",
                id: "ost_count",
                header: "Kursstufe abgeschl. / eingetr.",
                cell: ({value, row}) => {
                    const c = kursstufeStudentCount.find(i => i.student_id == row.original.student.id)
                    if (c)  return <span>{c.completed_gfs} / {c.registered_gfs} </span>
                },
                columnType: "colouredStatus",
                statusClassNames: (row) => {
                    const c = kursstufeStudentCount.find(i => i.student_id == row.original.student.id)
                    if (!c) return ""
                    if (c.completed_gfs >= 3)  return "bg-green-50"
                    if (c.registered_gfs >= 3) return "bg-yellow-50"
                    return "bg-red-50"
                },
                enableColumnFilter: false,
            },
        ] : [],
        {
            accessorKey: "id",
            id: "link",
            cell: ({value, row}) => (
                <TableDownloadLink
                    label="PDF"
                    fileName={`GFS ${row.original.student.full_name}.pdf`}
                    apiFunction={() => getGfsPDF(row.original.id)}/>
            ),
            disableRowLink: true,
            header: "PDF",
            enableColumnFilter: false
        }
    ];

    return (
        <SimplePage>
            <div className="px-4 sm:px-6 lg:px-8">
                <div className="sm:flex sm:items-center">
                    <div className="sm:flex-auto">
                        <h3 className="text-lg leading-6 font-medium text-gray-900">
                            GFS
                        </h3>
                    </div>
                    <div className="mt-4 sm:mt-0 sm:ml-16 flex gap-2">

                        <DownloadButton
                            apiFunction={() => getGfsExcel(schoolYearId, type)}
                            fileName="GFS.xls"
                            label="Liste herunterladen"/>

                        {checkPermission(permissions, "school.add_gfs")
                            && <PrimaryButton link={`/grades/gfs/new`} label="GFS eintragen"/>}
                    </div>
                </div>
                <div className="flex flex-col">
                    <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">

                        {!studentId && <div className="mt-4 inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                            <nav className="flex space-x-4" aria-label="Tabs">
                                <a
                                    key="ust"
                                    onClick={() => setType("ust")}
                                    className={classNames(
                                        type === "ust"
                                            ? "bg-imsblue-100 text-imsblue-700"
                                            : "text-gray-500 hover:text-gray-700",
                                        "px-3 py-2 font-medium text-sm rounded-md cursor-pointer"
                                    )}
                                    aria-current={type === "ust" ? "page" : undefined}
                                >
                                    7-K0
                                </a>
                                <a
                                    key="ost"
                                    onClick={() => setType("ost")}
                                    className={classNames(
                                        type === "ost"
                                            ? "bg-imsblue-100 text-imsblue-700"
                                            : "text-gray-500 hover:text-gray-700",
                                        "px-3 py-2 font-medium text-sm rounded-md cursor-pointer"
                                    )}
                                    aria-current={type === "ost" ? "page" : undefined}
                                >
                                    K1-K2
                                </a>
                            </nav>
                        </div>}

                        {!studentId && <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                            <SchoolYearFilter
                                schoolYears={schoolYears}
                                schoolYearId={schoolYearId}
                                setSchoolYearId={setSchoolYearId}
                            />
                        </div>}

                        <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                            {isSuccess && data && (
                                <ListViewTable
                                    data={data}
                                    columns={columns}
                                    isLoading={isLoading}
                                    disableGlobalSearch={false}
                                    getRowLink={getRowLink}
                                />
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </SimplePage>
    );
}
