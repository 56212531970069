import React from "react";
import {DangerButton, PrimaryButton} from "../../elements/Buttons";

export function RecipientSelectorFooter({setOpen}) {

  return (
    <div className="flex justify-end">
      <div className="justify-end">
        <PrimaryButton label="Übernehmen" onClick={() => setOpen(false)} />
      </div>
    </div>
  )
}
