import {Link, useParams} from "react-router-dom";
import {React, useEffect, useState} from "react";
import {useQuery} from "@tanstack/react-query";
import {getSchoolClasses, getStudentInfo} from "../../../api/school";
import {PrimaryButton, SecondaryButton} from "../../elements/Buttons";
import {useAuth} from "../../../contexts/AuthContext";
import ListViewTable from "../../elements/tables/ListViewTable";
import {DeleteButton} from "../../elements/DeleteButton";
import {
    addStudentCourses,
    doStudentCourseChange,
    getCourses,
    getCoursesForStudent,
    getStudentCourseDetail, getSuggestedCoursesForSchoolClassChange,
    removeStudentCourse
} from "../../../api/courses";
import {MultiSelect, Select} from "../../elements/forms/Inputs";
import {useSchoolYears} from "../../../contexts/SchoolYearContext";
import {baseHandleChange} from "../../elements/forms/Form";
import Joi from "joi";
import {useUserInfo} from "../../../contexts/UserInfoContext";

export default function StudentCourseDetail() {
    const {studentId} = useParams();
    const {permissions} = useAuth();
    const {renderUserImage} = useUserInfo()
    const {currentSchoolYear} = useSchoolYears();
    const [studentCourses, setStudentCourses] = useState([])
    const [selectedCourses, setSelectedCourses] = useState([])
    const [schoolYearId, setSchoolYearId] = useState();
    const [errors, setErrors] = useState({})

    const [courseChangeData, setCourseChangeData] = useState({
        old_course_id: null,
        new_course_id: null
    })

    const [schoolClassChangeData, setSchoolClassChangeData] = useState({
        new_school_class_id: null
    })

    const {data: schoolClasses} = useQuery(["school_classes"],
        getSchoolClasses, {refetchInterval: 1000 * 60 * 60 * 24}
    );

    const {data: student} = useQuery(["student_info", studentId], () =>
            getStudentInfo(studentId),
        {refetchInterval: 1000 * 60 * 60 * 24}
    );

    const {data: courses} = useQuery(
        ["courses", schoolYearId],
        () => getCourses(schoolYearId),
        {
            enabled: !!schoolYearId, refetchInterval: 1000 * 60 * 60 * 24
        }
    );

    const {data: coursesForStudent} = useQuery(
        ["courses_for_student", studentId, schoolYearId],
        () => getCoursesForStudent(studentId, schoolYearId),
        {
            enabled: !!schoolYearId, refetchInterval: 1000 * 60 * 60
        }
    );

    const schema = {
        old_course_id: Joi.any().required(),
        new_course_id: Joi.any().required()
    };

    useEffect(() => {
        loadData()
    }, [studentId])

    useEffect(() => {
        if (currentSchoolYear) setSchoolYearId(currentSchoolYear["id"]);
    }, [currentSchoolYear]);

    async function loadData() {
        const newData = await getStudentCourseDetail(studentId)
        setStudentCourses(newData)
    }

    const columns = [
        {
            accessorKey: "school_subject.name",
            id: "school_subject",
            header: "Fach",
            enableColumnFilter: false,
        },
        {
            accessorKey: "courses",
            id: "courses",
            header: "Kurse",
            enableColumnFilter: false,
            cell: ({row, value}) => (
                <>
                    {row.original.courses && row.original.courses.map((course, index) => (
                        <span key={index}>
                            {index > 0 ? <br/> : ""}
                            <Link to={`/courses/${course.id}`}>{course.course_number}</Link>
                            <DeleteButton
                                classNameIn="ml-6"
                                onDelete={() => handleDelete(course.id)}
                                iconOnly={true}
                            />
                        </span>
                    ))}
                </>
            ),
        }
    ];

    async function handleDelete(courseId) {
        const newData = await removeStudentCourse(studentId, courseId)
        setStudentCourses(newData)
    }

    function handleCourseSelect(selectedOptions) {
        setSelectedCourses(selectedOptions)
    }

    async function handleAddCourses() {
        const newData = await addStudentCourses(studentId, selectedCourses)
        setStudentCourses(newData)
    }

    async function handleDoCourseChange() {
        const newData = await doStudentCourseChange(studentId, courseChangeData)
        setStudentCourses(newData)
    }

    async function handleDoSchoolClassChange() {
        var newUrl = '/courses/school-class-change/' + studentId + '/' + schoolClassChangeData.new_school_class_id
        window.location.href = newUrl
    }

    function handleChange(evt) {
        baseHandleChange(evt, courseChangeData, setCourseChangeData, errors, setErrors, schema)
    }

    function handleSchoolClassChange(evt) {
        baseHandleChange(evt, schoolClassChangeData, setSchoolClassChangeData, errors, setErrors, {})
    }

    if (!student) return <></>;
    return (
        <>
            <main className="py-10 h-full bg-gray-100">
                {/* Page header */}
                <div
                    className="max-w-3xl mx-auto px-4 sm:px-6 md:flex md:items-center md:justify-between md:space-x-5 lg:max-w-7xl lg:px-8">
                    <div className="flex items-center space-x-5">
                        <div className="flex-shrink-0">
                            <div className="relative">
                                {renderUserImage(student.user_id, 80)}
                                <span
                                    className="absolute inset-0 shadow-inner rounded-full"
                                    aria-hidden="true"
                                />
                            </div>
                        </div>
                        <div>
                            <h1 className="text-2xl font-bold text-gray-900">
                                {student.full_name}
                            </h1>
                            <p className="text-sm font-medium text-gray-500">
                                {student.student_number}
                            </p>
                        </div>
                    </div>
                    <div
                        className="mt-6 flex flex-col-reverse justify-stretch space-y-4 space-y-reverse sm:flex-row-reverse sm:justify-end sm:space-x-reverse sm:space-y-0 sm:space-x-3 md:mt-0 md:flex-row md:space-x-3">
                        <SecondaryButton label="Schülerblatt" link={`/school/students/${studentId}`}/>

                    </div>
                </div>

                <div
                    className="mt-8 max-w-3xl mx-auto grid grid-cols-1 gap-6 sm:px-6 lg:max-w-7xl lg:grid-flow-col-dense lg:grid-cols-3">
                    <div className="space-y-6 lg:col-start-1 lg:col-span-2">

                        <section aria-labelledby="notes-title">

                            {studentCourses?.school_subjects_with_courses && (
                                <ListViewTable
                                    data={studentCourses.school_subjects_with_courses}
                                    columns={columns}
                                    disableGlobalSearch={true}
                                    getRowLink={null}
                                />
                            )}
                        </section>

                    </div>

                    <section
                        aria-labelledby="timeline-title"
                        className="lg:col-start-3 lg:col-span-1 mt-5"
                    >

                        {courses &&
                            <section className="" aria-labelledby="applicant-information-title">

                                <div className="bg-white shadow overflow-hidden sm:rounded-lg">

                                    <div className="px-4 py-5 sm:px-6">
                                        <h3 className="text-lg leading-6 font-medium text-gray-900">
                                            Kurse hinzufügen
                                        </h3>
                                    </div>

                                    <div className="border-t border-gray-200 px-4 py-5">
                                        <MultiSelect
                                            path=""
                                            label="Kurse hinzufügen"
                                            className="sm:col-span-6 hide-label z-50"
                                            options={courses}
                                            valueAttr="id"
                                            labelAttr="course_number"
                                            onChange={handleCourseSelect}
                                            data={selectedCourses}
                                            isClearable={true}
                                            valueOnly={true}
                                        />

                                        <PrimaryButton
                                            classNameIn="mt-2 w-full"
                                            label="Hinzufügen" onClick={handleAddCourses}/>
                                    </div>
                                </div>

                            </section>}


                        {courses &&
                            <section className="mt-4" aria-labelledby="applicant-information-title">

                                <div className="bg-white shadow overflow-hidden sm:rounded-lg">

                                    <div className="px-4 py-5 sm:px-6">
                                        <h3 className="text-lg leading-6 font-medium text-gray-900">
                                            Kurswechsel
                                        </h3>
                                    </div>

                                    <div className="border-t border-gray-200 px-4 py-5">
                                        <Select
                                            path="old_course_id"
                                            label="Bisheriger Kurs"
                                            className="sm:col-span-6"
                                            options={coursesForStudent}
                                            valueAttr="id"
                                            labelAttr="course_number"
                                            onChange={handleChange}
                                            data={courseChangeData}
                                            isClearable={true}
                                            valueOnly={true}
                                        />

                                        <Select
                                            path="new_course_id"
                                            label="Neuer Kurs"
                                            className="sm:col-span-6 mt-2"
                                            options={courses}
                                            valueAttr="id"
                                            labelAttr="course_number"
                                            onChange={handleChange}
                                            data={courseChangeData}
                                            isClearable={true}
                                            valueOnly={true}
                                        />

                                        <PrimaryButton
                                            classNameIn="mt-2 w-full"
                                            label="Kurswechsel" onClick={handleDoCourseChange}/>
                                    </div>
                                </div>

                            </section>}


                        {courses &&
                            <section className="mt-4" aria-labelledby="applicant-information-title">

                                <div className="bg-white shadow overflow-hidden sm:rounded-lg">

                                    <div className="px-4 py-5 sm:px-6">
                                        <h3 className="text-lg leading-6 font-medium text-gray-900">
                                            Klassenwechsel
                                        </h3>
                                    </div>

                                    <div className="border-t border-gray-200 px-4 py-5">

                                        <Select
                                            path="new_school_class_id"
                                            label="Neue Klasse"
                                            className="sm:col-span-6 mt-2"
                                            options={schoolClasses}
                                            valueAttr="id"
                                            labelAttr="group"
                                            onChange={handleSchoolClassChange}
                                            data={schoolClassChangeData}
                                            isClearable={true}
                                            valueOnly={true}
                                        />

                                        <PrimaryButton
                                            classNameIn="mt-2 w-full"
                                            label="Klassenwechsel" onClick={handleDoSchoolClassChange}/>
                                    </div>
                                </div>

                            </section>}

                    </section>


                </div>

            </main>
        </>
    );
}
