import { Page } from "../../elements/Pages";
import React from "react";
import { getMyPeExemptions } from "../../../api/logbook";
import { useQuery } from "@tanstack/react-query";
import ListViewTable from "../../elements/tables/ListViewTable";
import moment from "moment";
import {useAuth} from "../../../contexts/AuthContext";

const columns = [
  {
    accessorKey: "reason",
    id: "reason",
    header: "Grund",
    enableColumnFilter: false,
  },
  {
    accessorKey: "date_from",
    id: "date_from",
    header: "Von",
    columnType: "date",
    enableColumnFilter: false,
    // classNames: 'font-medium text-gray-900'
    classNames: "text-gray-500",
  },
  {
    accessorKey: "date_to",
    id: "date_to",
    header: "Bis",
    columnType: "date",
    enableColumnFilter: false,
    classNames: "text-gray-500",
  },
  {
    accessorKey: "notes",
    id: "notes",
    header: "Bemerkungen",
    cell: (info) => info.getValue(),
    enableColumnFilter: false,
    classNames: "max-w-md text-gray-500",
  },
];

export default function PeExemptionsMy() {

  const {currentUser} = useAuth()
  const { isLoading, isError, isSuccess, data, error } = useQuery(
    ["my_pe_exemptions", currentUser.user_id],
    getMyPeExemptions
  );

  if (!data) return <></>;
  return (
    <Page title="Meine Sportbefreiungen">
      {data.map((item) => {
        return (
          <div className="mb-4 flex flex-col">
            <div className="-mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
              <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                {data.length > 0 && (
                  <h2 className="mb-2 text-lg leading-6 font-medium text-gray-900">
                    {item.student}
                  </h2>
                )}

                <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
                  {isSuccess && item && (
                    <ListViewTable
                      disableGlobalSearch={true}
                      data={item.pe_exemptions}
                      columns={columns}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        );
      })}
    </Page>
  );
}
