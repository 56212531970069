import {useMessenger} from "../../../contexts/MessengerContext";
import {classNames} from "../../../utils/misc";
import React from "react";
import {useAuth} from "../../../contexts/AuthContext";

export function ConversationDetailSubHeader() {

  const {
    selectedConversationData
  } = useMessenger();

  const {currentUser} = useAuth()

  let otherParticipant = null

  if (selectedConversationData.length > 0) {
    otherParticipant = currentUser.user_id == selectedConversationData[0].sender_id ? selectedConversationData[0].recipient : selectedConversationData[0].sender
  }

  return (
    <div className="bg-white py-3.5 shadow">
      <div className="px-4 flex items-baseline justify-between sm:px-6 md:px-8">
        <div className="sm:w-0 sm:flex-1">
          <h1
            id="message-heading"
            className="text-lg font-medium text-gray-900"
          >
            {selectedConversationData.length && selectedConversationData[selectedConversationData.length - 1].subject}
          </h1>
          <p className="mt-1 truncate text-sm text-gray-500">
            {otherParticipant?.full_name}
          </p>
        </div>
      </div>
    </div>
  )
}