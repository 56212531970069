import {NarrowPage} from "../../elements/Pages";
import {React, useEffect, useState} from "react";
import Joi from "joi"
import {useQuery} from '@tanstack/react-query'
import {Select, TextArea} from "../../elements/forms/Inputs"
import {FormSectionContent} from "../../elements/forms/FormLayout"
import {facharbeitApi, getStudentInfoAll} from "../../../api/school";
import Form, {baseHandleChange, baseHandleDateChange} from "../../elements/forms/Form"
import {useNavigate, useParams} from "react-router-dom";
import {toast} from "react-toastify";
import {getStudentCourses} from "../../../api/courses";
import {getTeacherList} from "../../../api/staff";

export default function FacharbeitCreateEdit() {

    const {id} = useParams()

    const [data, setData] = useState({
        id: null,
        student: null,
        course: null,
        topic: null,
        supervisor: null
    })
    const [errors, setErrors] = useState({}) // validation errors

    const schema = {
        student: Joi.object().required(),
        course: Joi.object().required(),
        supervisor: Joi.object().allow(null),
        topic: Joi.string().required()
    }

    const {data: students} = useQuery(["student_info"], getStudentInfoAll, {refetchInterval: 1000 * 60 * 60 * 24})
    const {data: teachers} = useQuery(["teachers"], getTeacherList, {refetchInterval: 1000 * 60 * 60 * 24})
    const {data: courses} = useQuery(["student_course_list"], getStudentCourses, {refetchInterval: 1000 * 60 * 60 * 24})

    let navigate = useNavigate();

    useEffect(() => {
        loadData()
    }, [id])

    async function loadData() {
        if (!id) return
        const newData = await facharbeitApi('get', id)
        setData(newData)
    }

    function getAvailableCourses(student) {
        if (student && courses) {
            const availableCourses = courses.students.find(c => c.id === student.id)
            return availableCourses ? availableCourses.courses : []
        }
        return []
    }

    const title = (id || data.id) ? "Facharbeit bearbeiten" : "Neue Facharbeit eintragen"
    const successMessage = (id || data.id) ? "Facharbeit aktualisiert" : "Facharbeit eingetragen"

    async function onSave() {
        let newData
        if (!data.id) newData = await facharbeitApi('post', null, data)
        else newData = await facharbeitApi('patch', null, data)
        setData(newData)
        toast.success(successMessage)
        navigate(`/school/facharbeit/${newData.id}`)
    }

    async function onDelete() {
        await facharbeitApi('delete', data.id)
        toast.success("Facharbeit gelöscht.")
        navigate(`/school/facharbeit`)
    }

    function handleChange(evt) {
        baseHandleChange(evt, data, setData, errors, setErrors, schema)
    }

    return (

        <NarrowPage title={title}>

            <Form id="createFacharbeit" onSave={onSave}
                  onDelete={onDelete}
                  data={data} setData={setData}
                  errors={errors} setErrors={setErrors}
                  successMessage={successMessage}
                  schema={schema}>

                <FormSectionContent>

                    <Select
                        path="student"
                        label="SchülerIn"
                        className="sm:col-span-3"
                        options={students}
                        valueAttr="id"
                        labelAttr="full_name"
                        onChange={handleChange}
                        errors={errors}
                        data={data}
                        isClearable={false}
                        valueOnly={false}
                    />

                    <Select
                        path="course"
                        label="Kurs"
                        className="sm:col-span-3"
                        options={getAvailableCourses(data.student)}
                        valueAttr="id"
                        labelAttr="course_number"
                        onChange={handleChange}
                        errors={errors}
                        data={data}
                        isClearable={false}
                        valueOnly={false}
                    />

                    <div className="sm:col-span-3">
                        Kurslehrer: {data.course && data.course.teacher?.full_name}
                    </div>

                    <Select
                        path="supervisor"
                        label="Betreuender Lehrer"
                        className="sm:col-span-3"
                        options={teachers}
                        valueAttr="id"
                        labelAttr="full_name"
                        onChange={handleChange}
                        errors={errors}
                        data={data}
                        isClearable={false}
                        valueOnly={false}
                    />

                    <TextArea
                        path="topic"
                        type="text"
                        label="Thema"
                        className="sm:col-span-6"
                        onChange={handleChange}
                        errors={errors}
                        data={data}
                        rows={3}
                    />

                </FormSectionContent>

            </Form>

            <span onClick={() => navigate(-1)}
                  className="cursor-pointer float-right mt-2 font-medium text-imsblue-600">Zurück</span>

        </NarrowPage>
    )
}
