import React, { useEffect, useState } from "react";

const DebouncedInput = ({
  value: initialValue,
  onChange,
  debounce = 500,
  ...props
}) => {
  const [value, setValue] = useState(initialValue);

  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      onChange(value);
    }, debounce);

    return () => clearTimeout(timeout);
  }, [value]);

  return (
    <input
      {...props}
      type="text"
      value={value}
      onChange={(e) => setValue(e.target.value)}
      className="block rounded-md w-full border-gray-300 mb-3 focus:border-imsblue-500 focus:ring-imsblue-500 sm:text-sm"
    />
  );
};

export default DebouncedInput;
