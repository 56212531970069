import React, {useContext} from "react"
import {getCurrentSchoolYear, getSchoolYears} from "../api/calendar";
import {useAuth} from "./AuthContext";
import {useQuery} from "@tanstack/react-query";

const SchoolYearContext = React.createContext()

export function useSchoolYears() {
    return useContext(SchoolYearContext)
}

export function SchoolYearProvider({children}) {

    const {currentUser} = useAuth()

    const {data: schoolYears} = useQuery(
        ["school_years"], getSchoolYears,
        {enabled: !!currentUser, refetchInterval: 1000 * 60 * 60 * 24}
    )

    const {data: currentSchoolYear} = useQuery(
        ["current_school_year"], getCurrentSchoolYear,
        {enabled: !!currentUser, refetchInterval: 1000 * 60 * 60 * 24} // 24 hours
    )

    const value = {
        currentSchoolYear,
        schoolYears
    }

    return (
        <SchoolYearContext.Provider value={value}>
            {/*{!!setCurrentSchoolYear && children}*/}
            {children}
        </SchoolYearContext.Provider>
    )
}
