import {Link} from "react-router-dom";
import React from "react";
import {EditButton} from "../../elements/Buttons";
import {SchoolAssessmentTable} from "./BoardingSchoolAssessmentEdit";

export default function BoardingSchoolAssessmentDetail({assessment, inStudent, canEdit}) {

    const assessmentEditLink = `/assessments/boarding-school/${assessment.id}/edit`

    return (
        <div
            className="py-6 max-w-3xl mx-auto grid grid-cols-1 gap-6 lg:max-w-7xl lg:grid-flow-col-dense lg:grid-cols-4">
            <div className="bg-white space-y-6 lg:col-start-1 lg:col-span-4">

                <section aria-labelledby="applicant-information-title">
                    <div className="shadow overflow-hidden sm:rounded-lg">

                        {canEdit && <div className="px-4 py-5 float-right">
                            <EditButton iconOnly={true} link={assessmentEditLink} />
                        </div>}

                        {!inStudent && <div className="px-4 py-5 sm:px-6">
                            <h3 className="text-lg leading-6 font-medium text-gray-900">
                                <Link to={`/school/students/${assessment.student.id}`}
                                      className="text-gray-900">{assessment.student.full_name}</Link>
                                , {assessment.student.student_number}
                            </h3>
                            <p className="mt-1 max-w-2xl text-sm text-gray-500">
                                Mentorat {assessment.student.house}</p>
                        </div>}

                        <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
                            <dl className="grid grid-cols-3 gap-x-4 gap-y-4 sm:grid-cols-6">

                                <div className="sm:col-span-1">
                                    <dt className="text-sm font-medium text-gray-500">Mentorat</dt>
                                    <dd className="mt-1 text-sm text-gray-900">
                                        {assessment.house.name}
                                    </dd>
                                </div>

                                <div className="sm:col-span-3">
                                    <dt className="text-sm font-medium text-gray-500">MentorInnen</dt>
                                    <dd className="mt-1 text-sm text-gray-900">
                                        {assessment.house.mentors
                                            .map((m) => m.full_name).join(", ")}
                                    </dd>
                                </div>

                                <div className="sm:col-span-2">
                                    <dt className="text-sm font-medium text-gray-500">StammmentorIn</dt>
                                    <dd className="mt-1 text-sm text-gray-900">
                                        {assessment.main_mentor_full_name}
                                    </dd>
                                </div>
                            </dl>
                        </div>


                        <div className="border-t border-gray-200">
                            {assessment.assessment_criterion.length > 0 &&
                            <div className="w-full align-middle">
                                <SchoolAssessmentTable
                                    data={assessment}
                                    setData={() => {}}
                                />
                            </div>}
                        </div>


                        <div className="border-t border-gray-200 px-4 py-5 sm:p-0">
                            <dl className="sm:divide-y sm:divide-gray-200">
                                <div className="py-4 sm:py-5 sm:grid sm:grid-cols-4 sm:gap-4 sm:px-6">
                                    <dt className="text-sm font-medium text-gray-500">Bemerkungen</dt>
                                    <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-3 whitespace-pre-wrap">
                                        {assessment.assessment}
                                    </dd>
                                </div>
                            </dl>
                        </div>
                    </div>
                </section>

            </div>

        </div>

    )
}