import {useMessenger} from "../../../contexts/MessengerContext";
import {
  ArchiveBoxIcon,
  InboxIcon,
  TrashIcon
} from "@heroicons/react/24/outline";
import {Menu, Transition} from "@headlessui/react";
import {classNames} from "../../../utils/misc";
import {ArrowUturnLeftIcon, ChevronDownIcon} from "@heroicons/react/20/solid";
import React, {Fragment} from "react";

export function ConversationDetailHeader() {

  const {
    changeConversationStatus,
    replyOn,
    setReplyOn,
    currentTab,
  } = useMessenger();

  const buttons = [
    {name: "Eingang", value: "inbox", tabValue: "Nachrichten", icon: InboxIcon},
    {name: "Archiv", value: "archived", tabValue: "Archiv", icon: ArchiveBoxIcon},
    {name: "Papierkorb", value: "trash", tabValue: "Papierkorb", icon: TrashIcon},
  ]

  const buttonList = buttons.map(button => {
    if (currentTab !== button.tabValue) {
      return (
        <button
          key={button.value}
          type="button"
          className="relative -ml-px hidden items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-900 hover:bg-gray-50 focus:z-10 focus:border-imsblue-600 focus:outline-none focus:ring-1 focus:ring-imsblue-600 sm:inline-flex"
          onClick={() => changeConversationStatus(button.value)}
        >
          <button.icon
            className="mr-2.5 h-5 w-5 text-gray-400"
            aria-hidden="true"
          />
          <span>{button.name}</span>
        </button>
      )
    }
  })

  const menuList = buttons.map(button => {
    if (currentTab !== button.tabValue) {
      return (
        <Menu.Item key={button.value}>
          {({active}) => (
            <span
              className={classNames(
                active
                  ? "bg-gray-100 text-gray-900"
                  : "text-gray-700",
                "block px-4 py-2 text-sm cursor-pointer"
              )}
              onClick={() => changeConversationStatus(button.value)}
            >
              {button.name}
            </span>
          )}
        </Menu.Item>
      )
    }
  })

  return (
    <div className="flex-shrink-0 border-b border-gray-200 bg-white">
      <div className="flex h-16 flex-col justify-center">
        <div className="px-4 sm:px-6 md:px-8">
          <div className="flex justify-between py-3">
            <div>
              <div className="isolate inline-flex rounded-md shadow-sm sm:space-x-3 sm:shadow-none">
                <span className="inline-flex sm:shadow-sm">
                  <button
                    type="button"
                    onClick={() => setReplyOn(!replyOn)}
                    className="relative inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-900 hover:bg-gray-50 focus:z-10 focus:border-imsblue-600 focus:outline-none focus:ring-1 focus:ring-imsblue-600"
                  >
                    <ArrowUturnLeftIcon
                      className="mr-2.5 h-5 w-5 text-gray-400"
                      aria-hidden="true"
                    />
                    <span>Antworten</span>
                  </button>
                </span>


                <span className="hidden space-x-3 md:flex">
                  {buttonList}
                </span>

                <Menu
                  as="div"
                  className="relative -ml-px block sm:shadow-sm md:hidden"
                >
                  <div>
                    <Menu.Button
                      className="relative inline-flex items-center rounded-r-md border border-gray-300 bg-white px-2 py-2 text-sm font-medium text-gray-900 hover:bg-gray-50 focus:z-10 focus:border-imsblue-600 focus:outline-none focus:ring-1 focus:ring-imsblue-600 sm:rounded-md sm:px-3">
                      <span className="sr-only sm:hidden">Mehr</span>
                      <span className="hidden sm:inline">Mehr</span>
                      <ChevronDownIcon
                        className="h-5 w-5 text-gray-400 sm:ml-2 sm:-mr-1"
                        aria-hidden="true"
                      />
                    </Menu.Button>
                  </div>

                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                  >
                    <Menu.Items
                      className="absolute right-0 z-10 mt-2 w-36 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                      <div className="py-1">
                        {menuList}
                      </div>
                    </Menu.Items>
                  </Transition>
                </Menu>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
