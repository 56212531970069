import {SimplePage} from "../../elements/Pages";
import React, {useState} from "react";

import {useQuery} from "@tanstack/react-query";
import {assessmentPeriodApi} from "../../../api/assessments";
import {useParams} from "react-router-dom";
import checkPermission from "../../../utils/permissions";
import {useAuth} from "../../../contexts/AuthContext";
import {PrimaryButton} from "../../elements/Buttons";
import Alert from "../../elements/Alert";
import {finalizeOpenBoardingSchoolAssessments, getOpenBoardingSchoolAssessments} from "../../../api/boarding_school";
import BoardingSchoolAssessmentDetail from "./BoardingSchoolAssessmentDetail";


export default function BoardingSchoolAssessmentsOpen() {

    const {assessmentPeriodId} = useParams()
    const {permissions} = useAuth()
    const [response, setResponse] = useState()

    const {data: assessmentPeriod} = useQuery(
        ["assessment_periods", assessmentPeriodId],
        () => assessmentPeriodApi('get', assessmentPeriodId),
        {refetchInterval: 1000 * 60 * 60 * 24}
    );

    const {isLoading, isError, isSuccess, data, error, refetch} = useQuery(
        ["open_boarding_school_assessments", assessmentPeriodId],
        () => getOpenBoardingSchoolAssessments(assessmentPeriodId),
        {refetchInterval: 1000 * 60 * 10}
    );

    const canEdit = checkPermission(permissions, 'boarding_school.change_boarding_school_assessment')

    async function handleFinalizeAll() {
        const resp = await finalizeOpenBoardingSchoolAssessments(assessmentPeriodId)
        setResponse(resp)
        refetch()
    }

    return (
        <SimplePage>
            <div className="px-4 sm:px-6 lg:px-8">
                <div className="sm:flex sm:items-center">
                    <div className="sm:flex-auto">
                        <h3 className="text-lg leading-6 font-medium text-gray-900 mb-2">
                            Offene Internatsbeurteilungen für {assessmentPeriod?.name}
                        </h3>
                    </div>
                    <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
                    </div>
                </div>
                <div className="flex flex-col">
                    <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">

                        <div className="inline-block py-2 align-middle mx-auto md:px-6 lg:px-8 flex items-center gap-2">

                            {data && data.length > 0 && <PrimaryButton label="Alle offenen Beurteilungen abschließen"
                                           onClick={handleFinalizeAll}
                            />}
                            <div className="my-2">{response && <Alert variant="info">{response}</Alert>}</div>
                        </div>
                        <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                            {data && data.map((a, index) => (
                                <BoardingSchoolAssessmentDetail assessment={a} inStudent={false}
                                                                key={index} index={index} canEdit={canEdit} />
                            ))}

                            {data && data.length === 0 && <div className="text-gray-500">Keine offenen Beurteilungen für diesen Zeitraum.</div>}
                        </div>
                    </div>
                </div>
            </div>
        </SimplePage>
    );
}
