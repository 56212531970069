import {useEffect} from "react";
import {useParams, useSearchParams} from "react-router-dom";
import {useMessenger} from "../../../contexts/MessengerContext";
import {MessengerSideBar} from "./MessengerSideBar";
import {MessengerMain} from "./MessengerMain";

export default function MessengerIndex() {

    const {
        setIsOpenMessenger,
        clearData,
        changeTempConversationId,
        availableRecipients,
        setSelectedRecipients,
        selectConversationList
    } = useMessenger();

    const {conversationId} = useParams();
    const [searchParams, setSearchParams] = useSearchParams();

    // useEffect(() => {
    //   const search = searchParams.get("search")
    //   if (search) {
    //     setCurrentTab(`Search results for '${search}'`);
    //     searchConversation(search)
    //       .then((res) => {
    //         setConversationList(res);
    //       })
    //       .catch((err) => console.log(err));
    //   }
    // }, [searchParams]);
    useEffect(() => {
        selectConversationList()
    }, [availableRecipients])

    useEffect(() => {
        if (!!availableRecipients.length) {
            const user_id = searchParams.get("user_id")
            if (user_id) {
                const r = [parseInt(user_id)]
                setSelectedRecipients(r)
            }
            changeTempConversationId(conversationId, user_id)
        }
        setIsOpenMessenger(true)
        return (() => clearData())
    }, [availableRecipients])

    return (
        <div className="flex h-full flex-col main-content-height-messenger">
            <div className="flex min-h-0 flex-1 overflow-hidden">
                <MessengerSideBar/>
                <MessengerMain/>
            </div>
        </div>
    );
}

