import {useParams} from "react-router-dom";
import {React} from "react";
import {useQuery} from "@tanstack/react-query";
import {EditButton, PrimaryButton, SecondaryButton} from "../../elements/Buttons";
import checkPermission from "../../../utils/permissions";
import {useAuth} from "../../../contexts/AuthContext";
import ListViewTable from "../../elements/tables/ListViewTable";
import {toast} from "react-toastify";
import {DeleteButton} from "../../elements/DeleteButton";
import {getDefaultStudierzeitTimes, getStudentStudierzeitChanges, studierzeitChangeApi} from "../../../api/studierzeit";
import {useUserInfo} from "../../../contexts/UserInfoContext";


export default function StudentDetail() {

    const {studentId} = useParams();
    const {permissions} = useAuth();
    const {renderUserImage} = useUserInfo();

    const canEdit = checkPermission(permissions, "studierzeit.add_changedstudierzeit")

    let columns = [
        {
            accessorKey: "weekday",
            id: "weekday",
            header: "Tag",
            enableColumnFilter: false,
        },
        {
            accessorKey: "from_studierzeit_time",
            id: "from_studierzeit_time",
            header: "Von",
            enableColumnFilter: false,
            cell: ({row, value}) => (
                <>
                    {row.original.from_studierzeit_time?.name} <br/>
                    {row.original.from_studierzeit_time?.period?.name}
                </>
            ),
        },
        {
            accessorKey: "new_studierzeit_time",
            id: "new_studierzeit_time",
            header: "Zu",
            cell: ({row, value}) => (
                <>
                    {row.original.new_studierzeit_time?.name} <br/>
                    {row.original.new_studierzeit_time?.period?.name} <br/>
                    {row.original.room?.name}
                </>
            ),
            enableColumnFilter: false,
            // classNames: "text-gray-500",
        },
        {
            accessorKey: "reason",
            id: "reason",
            // columnType: "m2m",
            header: "Grund",
            enableColumnFilter: false,
        },
    ];

    if (canEdit) {
        columns.push({
            accessorKey: "id",
            id: "link",
            cell: ({row, value}) => (
                <>
                    <EditButton iconOnly={true} link={`/studierzeit/changes/${row.original.id}/edit`}/>
                    <DeleteButton onDelete={() => handleDelete(row.original.id)} iconOnly={true}/>
                </>
            ),
            header: "Bearbeiten",
            enableColumnFilter: false,
        },)
    }

    const {data: studentInfo} = useQuery(["default_studierzeit_times", studentId], () =>
        getDefaultStudierzeitTimes(studentId)
    )

    const {data: studierzeitChanges, refetch} = useQuery(["student_studierzeit_changes", studentId], () =>
        getStudentStudierzeitChanges(studentId)
    )

    async function handleDelete(id) {
        try {
            await studierzeitChangeApi('delete', id)
            refetch()
            toast.success("Studierzeitwechsel gelöscht.")
        } catch (e) {
            toast.error("Beim Speichern ist ein Fehler aufgetreten.")
        }
    }

    if (!studentInfo || !studentInfo.student) return <></>;

    return (
        <>
            <main className="py-10 h-full bg-gray-100">
                {/* Page header */}
                <div
                    className="max-w-3xl mx-auto px-4 sm:px-6 md:flex md:items-center md:justify-between md:space-x-5 lg:max-w-7xl lg:px-8">
                    <div className="flex items-center space-x-5">
                        <div className="flex-shrink-0">
                            <div className="relative">
                                {renderUserImage(studentInfo.student.user_id)}
                                <span
                                    className="absolute inset-0 shadow-inner rounded-full"
                                    aria-hidden="true"
                                />
                            </div>
                        </div>
                        <div>
                            <h1 className="text-2xl font-bold text-gray-900">
                                {studentInfo.student.full_name}
                            </h1>
                            <p className="text-sm font-medium text-gray-500">
                                {studentInfo.student.student_number}
                            </p>
                        </div>
                    </div>
                    <div
                        className="mt-6 flex flex-col-reverse justify-stretch space-y-4 space-y-reverse sm:flex-row-reverse sm:justify-end sm:space-x-reverse sm:space-y-0 sm:space-x-3 md:mt-0 md:flex-row md:space-x-3">
                        <SecondaryButton label="Schülerblatt" link={`/school/students/${studentId}`}/>

                        {/*<SecondaryButton label="Projektanwesenheit" link={`/projects/attendance/students/${studentId}`}/>*/}

                        {canEdit && (
                            <PrimaryButton
                                link={`/studierzeit/changes/new/${studentId}`}
                                label="Studierzeitwechsel hinzufügen"
                            />
                        )}
                    </div>
                </div>

                <div
                    className="mt-8 max-w-3xl mx-auto grid grid-cols-1 gap-6 sm:px-6 lg:max-w-7xl lg:grid-flow-col-dense lg:grid-cols-3">

                    <div className="space-y-6 lg:col-start-1 lg:col-span-2">

                        <section aria-labelledby="notes-title">

                            {studierzeitChanges &&
                                <ListViewTable
                                    data={studierzeitChanges}
                                    columns={columns}
                                    disableGlobalSearch={true}
                                    getRowLink={null}
                                />}
                        </section>

                        {/* Table */}
                        <section aria-labelledby="applicant-information-title"></section>
                    </div>

                    <section
                        aria-labelledby="timeline-title"
                        className="lg:col-start-3 lg:col-span-1 mt-6"
                    >
                        <section aria-labelledby="applicant-information-title">
                            <div className="bg-white shadow overflow-hidden sm:rounded-lg">

                                <div className="px-4 py-5">
                                    <h3 className="text-lg leading-6 font-medium text-gray-900">
                                        Normale Studierzeit
                                    </h3>
                                </div>
                                <div className="border-t border-gray-200 px-4 py-5">

                                    {studentInfo.default_studierzeit_times.map((st, index) => (
                                        <div key={index} className="">
                                            {st.name}, {st.period.name}
                                        </div>
                                    ))}

                                    <div className="mt-2">{studentInfo.study_hall_name}</div>

                                </div>
                            </div>
                        </section>
                    </section>
                </div>

            </main>
        </>
    )
}

