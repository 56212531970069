import {SimplePage} from "../../elements/Pages";
import React from "react";

import {useQuery} from "@tanstack/react-query";
import {useParams} from "react-router-dom";
import BoardingSchoolAssessmentDetail from "./BoardingSchoolAssessmentDetail";
import {boardingSchoolAssessmentApi} from "../../../api/boarding_school";


export default function BoardingSchoolSingleAssessmentDetail() {

    const {id} = useParams()
    const {isLoading, isError, isSuccess, data: assessment, error, refetch} = useQuery(
        ["boarding_school_assessments", id],
        () => boardingSchoolAssessmentApi('get', id),
    )

    if (!assessment) return <></>

    return (
        <SimplePage>
            <div className="px-4 sm:px-6 lg:px-8">
                <div className="sm:flex sm:items-center">
                    <div className="sm:flex-auto">
                        <h3 className="text-lg leading-6 font-medium text-gray-900">
                            Internatsbeurteilung {assessment.assessment_period.time_period.label}
                        </h3>
                    </div>
                    <div className="mt-4 sm:mt-0 sm:ml-16 flex gap-2">
                    </div>
                </div>
                <div className="bg-gray-100 pt-6 mt-8 px-4 sm:px-6 lg:px-8">
                    <div className="flex flex-col">
                        <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">

                            <div className="inline-block py-2 align-middle md:px-6 lg:px-8 max-w-6xl">
                                {assessment &&
                                    <BoardingSchoolAssessmentDetail assessment={assessment} inStudent={false} canEdit={assessment.can_edit} />
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </SimplePage>
    );
}
