import http from "./../services/httpService";
import {genericApi} from "./generic";

export async function alumniApi(method, id, data) {
    return genericApi('/alumni/', method, id, data)
}

export async function getAmbassadors() {
    const resp = await http.get('/alumni/ambassadors/')
    return resp.data
}

export async function addImageToAlumnus(formData, id) {
    const resp = await http.post(`/alumni/${id}/add_file/`, formData)
    return resp.data
}

export async function getMyAlumnus() {
    const resp = await http.get('/alumni/my/')
    return resp.data
}

export async function getMyAlumniChildren() {
    const resp = await http.get('/alumni/my_children/')
    return resp.data
}

export async function updateMyAlumniChildren(data) {
    const resp = await http.post('/alumni/update_my_children/', data)
    return resp.data
}