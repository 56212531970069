import {useEffect, useState} from "react";
import {useExams} from "../../../contexts/ExamsContext";
import {
    addDaysToDate,
    checkIfDateFallsBetween,
    getDaysInMonth,
    getFormattedTime,
    getMonthOfWeek,
    getNumberArrayWithRange,
    today,
} from "../../../utils/helpers";
import ExamCalendarContainer from "./ExamCalendarContainer";
import ExamMonth from "./ExamMonth";
import {examApi} from "../../../api/school";
import {useNavigate} from "react-router-dom";

export default function ExamCalendar() {
    const navigate = useNavigate()
    const {
        selectedDate,
        setSelectedDate,
        view,
        setView,
        fetchedEventsMonth,
        fetchedEventsWeek,
        openEvent,
    } = useExams();
    const search = window.location.search;
    const params = new URLSearchParams(search);
    const exam_id = params.get("exam_id");
    const year = params.get("year");
    const month = params.get("month");
    const week = params.get("week");
    const date = params.get("date");
    const [days, setDays] = useState([]);
    const [weekDays, setWeekDays] = useState([]);
    const nextPrev = (action, value) => {
        switch (action) {
            case "next":
                if (view === "month")
                    setSelectedDate({
                        year:
                            selectedDate.month === 12
                                ? parseInt(selectedDate.year) + 1
                                : selectedDate.year,
                        month: selectedDate.month === 12 ? 1 : parseInt(selectedDate.month) + 1,
                        date: selectedDate.date,
                    });
                else {
                    const currentDate = new Date(selectedDate.year, selectedDate.month-1, selectedDate.date)
                    const newDate = addDaysToDate(currentDate, 7);

                    setSelectedDate({
                        year: newDate.getFullYear(),
                        month: newDate.getMonth() + 1,
                        date: newDate.getDate(),
                    });
                }
                break;

            case "prev":
                if (view === "month")
                    setSelectedDate({
                        year:
                            selectedDate.month === 1
                                ? selectedDate.year - 1
                                : selectedDate.year,
                        month: selectedDate.month === 1 ? 12 : selectedDate.month - 1,
                        date: selectedDate.date,
                    });
                else {
                    const currentDate = new Date(selectedDate.year, selectedDate.month-1, selectedDate.date)
                    const newDate = addDaysToDate(currentDate, -7);

                    setSelectedDate({
                        year: newDate.getFullYear(),
                        month: newDate.getMonth() + 1,
                        date: newDate.getDate(),
                    });
                }
                break;

            case "curr":
                setSelectedDate({
                    year: today.getFullYear(),
                    month: today.getMonth() + 1,
                    date: today.getDate(),
                });
                break;
            case "value":
                if (view === "month")
                    setSelectedDate({
                        ...value,
                    });
                else {
                    const currentDate = `${value.year}-${value.month}-${value.date}`;
                    const newDate = addDaysToDate(currentDate, -7);

                    setSelectedDate({
                        year: newDate.getFullYear(),
                        month: newDate.getMonth() + 1,
                        date: newDate.getDate(),
                    });
                }
                break;

            default:
                break;
        }
    };
    const setDaysForMonth = () => {
        const currYear = selectedDate.year;
        const currMonth = selectedDate.month
        const lastMonth = currMonth === 1 ? 12 : currMonth - 1;
        const nextMonth = currMonth === 12 ? 1 : currMonth + 1;
        const totalDaysCurrMonth = getDaysInMonth(currYear, currMonth);
        const totalDaysLastMonth = getDaysInMonth(
            currYear,
            currMonth !== 1 ? currMonth - 1 : 12
        );
        // const firstDate = new Date(`${currYear}-${currMonth}-01`)
        const firstDate = new Date(currYear, currMonth - 1, 1);
        const dayOfFirstDate = firstDate.getDay() === 0 ? 6 : firstDate.getDay() - 1;
        const lastDate = new Date(currYear, currMonth - 1, totalDaysCurrMonth);

        const dayOfLastDate = lastDate.getDay() === 0 ? 6 : lastDate.getDay() - 1;
        const lastMonthYear = currMonth === 0 ? currYear - 1 : currYear;
        const nextMonthYear = currMonth === 11 ? currYear + 1 : currYear;

        const days = [
            ...getNumberArrayWithRange(
                dayOfFirstDate,
                totalDaysLastMonth + 1 - dayOfFirstDate
            ).map((date) => {
                return { date: `${lastMonthYear}-${lastMonth}-${date}`, events: [] };
            }),
            ...getNumberArrayWithRange(totalDaysCurrMonth, 1).map((date) => {
                return {
                    date: `${currYear}-${currMonth}-${date}`,
                    isCurrentMonth: true,
                    isToday:
                        `${currYear}-${currMonth}-${date}` ===
                        `${today.getFullYear()}-${today.getMonth() + 1}-${today.getDate()}`,
                    events: fetchedEventsMonth
                        ? fetchedEventsMonth
                            .filter((event) => {
                                return checkIfDateFallsBetween(
                                    `${currMonth}/${date}/${currYear}`,
                                    event.date,
                                    event.date
                                );
                            })
                            .map((event) => {
                                const courses = event.courses.map((c) => { return c.course_number })
                                return {
                                    ...event,
                                    id: event.id,
                                    name: `${courses} ${event.category}`,
                                    time: getFormattedTime(event),
                                    datetime: event.start,
                                    href: "#"
                                };
                            })
                        : [],
                };
            }),
            ...getNumberArrayWithRange(Math.max(6 - dayOfLastDate, 0), 1).map((date) => {
                return { date: `${nextMonthYear}-${nextMonth}-${date}`, events: [] };
            }),
        ];
        setDays(days);
    };

    useEffect(() => {
        if (view === "month") setDaysForMonth();
    }, [selectedDate, fetchedEventsMonth]);

    useEffect(() => {
        if (week) {
            setView("week");
            const month = getMonthOfWeek(parseInt(week), parseInt(year));
            // const date = getDateOfWeek(parseInt(week), parseInt(year));
            setSelectedDate({
                year: parseInt(year),
                month,
                date: parseInt(date),
            });
        } else if (month) {
            setView("month");
            setSelectedDate({
                year: parseInt(year),
                month,
                date: today.getDate(),
            });
        }
    }, [year, month, week, date]);

    useEffect(() => {
        if (view === "month" && exam_id && fetchedEventsMonth?.length) {
            const selectedEvent = fetchedEventsMonth.find(
                (i) => i.id === parseInt(exam_id)
            );
            if (selectedEvent) openEvent(selectedEvent);
        } else if (view === "week" && exam_id && fetchedEventsWeek?.length) {
            const selectedEvent = fetchedEventsWeek.find(
                (i) => i.id === parseInt(exam_id)
            );
            if (selectedEvent) openEvent(selectedEvent);
        }
    }, [exam_id, fetchedEventsMonth]);

    useEffect(() => {
        if (exam_id && !month && !week) navigate(`/class-register/exams/${exam_id}`);
    }, [exam_id]);

    return (
        <ExamCalendarContainer
            setView={setView}
            view={view}
            nextPrev={nextPrev}
            selectedDate={selectedDate}
        >
            <ExamMonth providerFunction={useExams} days={days} />
        </ExamCalendarContainer>
    );
}
