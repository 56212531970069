import {NarrowPage} from "../../elements/Pages";
import {React, useEffect, useState} from "react";
import Joi from "joi"
import {useQuery, useQueryClient} from '@tanstack/react-query'
import {DateInput, MultiSelect, Select, TextArea} from "../../elements/forms/Inputs"
import {FormSectionContent} from "../../elements/forms/FormLayout"
import {getCourseInfoAll} from "../../../api/school";
import Form, {baseHandleChange, baseHandleDateChange} from "../../elements/forms/Form"
import {useNavigate, useParams} from "react-router-dom";
import {toast} from "react-toastify";
import {getDayPeriods} from "../../../api/calendar";
import {cancellationApi} from "../../../api/timetables";

export default function CancellationCreateEdit() {
    
    const {id} = useParams()
    const [data, setData] = useState({})
    const queryClient = useQueryClient()

    const {data: courses} = useQuery(['courses'], getCourseInfoAll, {refetchInterval: 1000 * 60 * 60 * 24})
    const {data: dayPeriods} = useQuery(['day_periods'], getDayPeriods, {refetchInterval: 1000 * 60 * 60 * 24})

    const [errors, setErrors] = useState({}) // validation errors

    let navigate = useNavigate();

    useEffect(() => {
        loadData()
    }, [id])

    async function loadData() {
        if (!id) return
        const newData = await cancellationApi('get', id)
        setData(newData)
    }

    const schema = {
        date: Joi.date().required(),
        remarks: Joi.string().min(5),
        course: Joi.object().required(),
        periods: Joi.array().min(1)
    };

    const title = (id || data.id) ? "Ausfall bearbeiten" : "Ausfall eintragen"
    const successMessage = (id || data.id) ? "Ausfall aktualisiert" : "Ausfall eingetragen"

    async function onSave() {
        let newData
        if (!data.id) newData = await cancellationApi('post', null, data)
        else newData = await cancellationApi('patch', null, data)
        setData(newData)
        queryClient.invalidateQueries({ queryKey: ["timetable_changes"] })
        queryClient.invalidateQueries({ queryKey: ["timetable_changes_teachers_lounge"] })
        queryClient.invalidateQueries({ queryKey: ["timetable_changes_aula"] })
        toast.success(successMessage)
        navigate(`/timetables`)
    }

    async function onDelete() {
        await cancellationApi('delete', data.id)
        toast.success("Ausfall gelöscht.")
        navigate(`/timetables`)
    }

    function handleChange(evt) {
        baseHandleChange(evt, data, setData, errors, setErrors, schema)
    }

    function handleDateChange(date, path) {
        baseHandleDateChange(date, path, data, setData, errors, setErrors, schema, false)
    }

    function handleMultiSelect(selectedOptions, path) {
        let newData = {...data}
        newData[path] = selectedOptions
        setData(newData)
    }

    return (

        <NarrowPage title={title}>

            <Form id="createCancellation" onSave={onSave}
                  onDelete={onDelete}
                  data={data} setData={setData}
                  errors={errors} setErrors={setErrors}
                  successMessage={successMessage}
                  schema={schema}>

                <FormSectionContent>

                    <Select
                        path="course"
                        label="Kurs"
                        className="sm:col-span-6"
                        options={courses}
                        valueAttr="id"
                        labelAttr="course_number"
                        onChange={handleChange}
                        errors={errors}
                        data={data}
                        isClearable={false}
                        valueOnly={false}
                    />

                    <DateInput
                        path="date"
                        label="Datum"
                        className="sm:col-span-3"
                        onChange={handleDateChange}
                        errors={errors}
                        data={data}
                        todayButton={true}
                        tomorrowButton={false}
                    />

                    <MultiSelect
                        path="periods"
                        label="Stunde"
                        className="sm:col-span-3"
                        options={dayPeriods}
                        valueAttr="id"
                        labelAttr="name"
                        onChange={(selectedOptions) => handleMultiSelect(selectedOptions, 'periods')}
                        errors={errors}
                        data={data}
                        isClearable={true}
                        valueOnly={false}
                    />

                    <TextArea
                        path="remarks"
                        label="Anmerkungen"
                        onChange={handleChange}
                        errors={errors}
                        data={data}
                        rows={4}
                        className="sm:col-span-6"
                        helpText=""
                    />
                </FormSectionContent>

            </Form>

            <span onClick={() => navigate(-1)}
                  className="cursor-pointer float-right mt-2 font-medium text-imsblue-600">Zurück</span>

        </NarrowPage>
    )
}
