import React from "react";
import {Route, Routes} from "react-router-dom";
import Registration from "./Registration";
import StudentDetail from "./StudentDetail";
import StudentAppointmentList from "./StudentAppointmentList";
import ParentConferenceDayList from "./ParentConferenceDayList";
import TeacherRooms from "./TeacherRooms";
import TeacherAppointmentList from "./TeacherAppointmentList";
import Students from "./Students";
import Teachers from "./Teachers";
import TeacherMyParentConferenceDays from "./TeacherMyParentConferenceDays";


function ParentConferenceRouter() {
    return (
        <Routes>

            <Route index element={<ParentConferenceDayList/>}/>
            <Route path="/:conferenceDayId/teacher-rooms" element={<TeacherRooms/>}/>
            <Route path="/:conferenceDayId/teachers" element={<Teachers/>}/>
            <Route path="/:conferenceDayId/teachers/:teacherId" element={<TeacherAppointmentList/>}/>
            <Route path="/teachers/my" element={<TeacherMyParentConferenceDays/>}/>

            <Route path="/registration" element={<Registration/>}/>

            <Route path="/:conferenceDayId/students/" element={<Students/>}/>
            <Route path="/:conferenceDayId/students/:studentId" element={<StudentDetail/>}/>
            <Route path="/:conferenceDayId/students/:studentId/list" element={<StudentAppointmentList/>}/>

        </Routes>
    );
}

export default function ParentConference() {
    return <ParentConferenceRouter/>
}
