import {DetailPage} from "../../elements/Pages";
import {React} from 'react'

import {useQuery} from '@tanstack/react-query'
import {useAuth} from "../../../contexts/AuthContext";
import checkPermission from "../../../utils/permissions";
import {Link, useParams} from "react-router-dom";
import {CheckCircleIcon} from "@heroicons/react/20/solid";
import {DangerButton, EditButton, SuccessButton} from "../../elements/Buttons";
import {approveExcursion, excursionApi, rejectExcursion} from "../../../api/school";
import {formatDate, formatDateTime} from "../../../utils/formatters";
import {toast} from "react-toastify";
import {useUserInfo} from "../../../contexts/UserInfoContext";


export default function ExcursionDetail() {

    const {id} = useParams()
    const {permissions} = useAuth()
    const {renderUserImage} = useUserInfo();
    const {isLoading, isError, refetch, data} = useQuery(['excursions', id], () => excursionApi('get', id), {})

    const canApprove = checkPermission(permissions, 'school.change_excursion')

    async function onApprove() {
        try {
            await approveExcursion(data.id);
            refetch()
            toast.success("Schulveranstaltung genehmigt.");
        } catch (e) {
            toast.error(e)
        }
    }

    async function onReject() {
        try {
            await rejectExcursion(data.id);
            refetch()
            toast.success("Schulveranstaltung abgelehnt.");
        } catch (e) {
            toast.error(e)
        }
    }


    if (!data) return <></>
    return (

        <DetailPage>
            <div
                className="max-w-3xl mx-auto px-4 sm:px-6 md:flex md:items-center md:justify-between md:space-x-5 lg:max-w-7xl lg:px-8">
                <div className="flex items-center space-x-5">
                    <h1 className="text-2xl font-bold text-gray-900">{data.title}</h1>
                </div>
                <div
                    className="mt-6 flex flex-col-reverse justify-stretch space-y-4 space-y-reverse sm:flex-row-reverse sm:justify-end sm:space-x-reverse sm:space-y-0 sm:space-x-3 md:mt-0 md:flex-row md:space-x-3">

                    {data.can_edit &&
                        <EditButton
                            iconOnly={false}
                            link={`/school/excursions/${data.id}/edit`}
                            label="Bearbeiten"
                        />}
                </div>
            </div>

            <div
                className="mt-8 max-w-3xl mx-auto grid grid-cols-1 gap-6 sm:px-6 lg:max-w-7xl lg:grid-flow-col-dense lg:grid-cols-3">
                <div className="space-y-6 lg:col-start-1 lg:col-span-2">

                    <section aria-labelledby="applicant-information-title">
                        <div className="bg-white shadow overflow-hidden sm:rounded-lg">
                            <div className="px-4 py-5 sm:px-6">
                                <p className="mt-1 text-sm text-gray-500">{data.description}</p>
                            </div>

                            <div className="border-t border-gray-200 px-4 py-5 sm:p-0">
                                <dl className="sm:divide-y sm:divide-gray-200">
                                    <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                        <dt className="text-sm font-medium text-gray-500">Datum</dt>
                                        <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                            {formatDateTime(data.start)} - {formatDateTime(data.end)}
                                        </dd>
                                    </div>
                                    <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                        <dt className="text-sm font-medium text-gray-500">Begleitet von</dt>
                                        <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                            {data.staff.map(s => s.full_name).join(', ')}
                                        </dd>
                                    </div>

                                    <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                        <dt className="text-sm font-medium text-gray-500">Kategorie</dt>
                                        <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{data.excursion_type}</dd>
                                    </div>
                                    <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                        <dt className="text-sm font-medium text-gray-500">Essen</dt>
                                        <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                            {data.absent_for_lunch && <div className="flex mb-1"><CheckCircleIcon
                                                className="h-5 w-5 text-green-400" aria-hidden="true"/> Abwesend zum
                                                Mittagessen</div>}
                                            {data.absent_for_dinner && <div className="flex mb-1"><CheckCircleIcon
                                                className="h-5 w-5 text-green-400" aria-hidden="true"/> Abwesend zum
                                                Abendessen</div>}
                                            {data.packed_lunch && <div className="flex"><CheckCircleIcon
                                                className="h-5 w-5 text-green-400" aria-hidden="true"/> Lunchpaket
                                                gewünscht</div>}
                                        </dd>
                                    </div>
                                    <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                        <dt className="text-sm font-medium text-gray-500">Beantragt von</dt>
                                        <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{data.created_by_full_name} am {formatDate(data.creation_date)}</dd>
                                    </div>
                                    <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                        <dt className="text-sm font-medium text-gray-500">Status</dt>
                                        <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{data.get_status_display}
                                            {data.status === 'APPROVED' && <div
                                                className="text-sm text-gray-500"> von {data.approved_by_full_name} am {formatDate(data.approved_date)}</div>}
                                        </dd>
                                    </div>

                                    {canApprove &&
                                        <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                            <dt className="text-sm font-medium text-gray-500">Aktionen</dt>
                                            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2 whitespace-pre-wrap">
                                                <div className="approveRejectButtons flex gap-2">

                                                    {data.status !== 'APPROVED' && <SuccessButton
                                                        label={"Genehmigen"}
                                                        onClick={onApprove}
                                                    />}
                                                    {data.status !== 'REJECTED' && <DangerButton
                                                        onClick={onReject}
                                                        label="Ablehnen"
                                                    />}
                                                </div>
                                            </dd>
                                        </div>}
                                </dl>
                            </div>
                        </div>
                    </section>

                </div>

                <div className="lg:col-start-3 lg:col-span-1">
                    {data && (
                        <section aria-labelledby="students">
                            <div className="bg-white shadow overflow-hidden sm:rounded-lg">
                                <div className="px-4 py-5 sm:px-6">
                                    <h3 className="text-lg leading-6 font-medium text-gray-900">
                                        SchülerInnen
                                    </h3>
                                </div>
                                <div className="border-t border-gray-200 px-4 py-5 sm:p-0">
                                    <ul role="list" className="divide-y divide-gray-200">
                                        {data.student_details.map((student, index) => (
                                            <Link key={index} to={`/school/students/${student.id}`}>
                                                <li className="sm:px-6 flex py-2">
                                                    {renderUserImage(student.user_id)}
                                                    <div className="ml-3">
                                                        <p className="text-sm font-medium text-gray-900 py-2">
                                                            {student.full_name}
                                                        </p>
                                                    </div>
                                                </li>
                                            </Link>
                                        ))}
                                    </ul>
                                </div>
                            </div>
                        </section>
                    )}
                </div>

            </div>

        </DetailPage>
    )
}
