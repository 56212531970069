import React from 'react';
import {Route, Routes} from "react-router-dom";
import StudentNotenblatt from "./StudentNotenblatt";
import Students from "./Students";
import CourseNotenblatt from "./CourseNotenblatt";
import GradeLists from "./GradeLists";
import StudentSpecialGrades from "./StudentSpecialGrades";
import SpecialGradeCreateEdit from "./SpecialGradeCreateEdit";
import CourseGrades from "./CourseGrades";
import ResultDetail from "./ResultDetail";
import ResultCreateEdit from "./ResultCreateEdit";
import GfsList from "./GfsList";
import GfsDetail from "./GfsDetail";
import GfsCreateEdit from "./GfsCreateEdit";
import GfsMy from "./GfsMy";


function GradesRouter() {

    return (
        <Routes>

            <Route path="/students" element={<Students />} />
            <Route path="/students/:studentId/notenblatt" element={<StudentNotenblatt />} />

            <Route path="/students/:studentId/special-grades" element={<StudentSpecialGrades />} />
            <Route path="/special-grades/new/:studentId" element={<SpecialGradeCreateEdit />} />
            <Route path="/special-grades/:id/edit" element={<SpecialGradeCreateEdit />} />

            <Route path="/courses/:courseId" element={<CourseGrades />} />

            <Route path="/results/:id" element={<ResultDetail />} />
            <Route path="/results/new/:markDefinitionId" element={<ResultCreateEdit />} />
            <Route path="/results/:id/edit" element={<ResultCreateEdit />} />

            <Route path="/courses/:courseId/notenblatt" element={<CourseNotenblatt />} />

            <Route path="/grade-lists" element={<GradeLists />} />
            
            {/* GFS */}
            <Route path="/gfs" element={<GfsList />} />
            <Route path="/gfs/:id" element={<GfsDetail />} />
            <Route path="/gfs/:id/edit" element={<GfsCreateEdit />} />
            <Route path="/gfs/new/" element={<GfsCreateEdit />} />
            <Route path="/gfs/my" element={<GfsMy />} />

        </Routes>
    )
}


export default function Grades() {
    return <GradesRouter />
}
