import http from "./../services/httpService";
import {genericApi} from "./generic";
import httpServiceDownloads from "../services/httpServiceDownloads";
import httpNoAuthService from "../services/httpNoAuthService";


export async function getQuestionnaireList() {
    const resp = await http.get('/questionnaires/')
    return resp.data
}

export async function getQuestionnaireDetail(questionnaireId) {
    const resp = await http.get(`/questionnaires/${questionnaireId}/`)
    return resp.data
}

export async function findEntry(questionnaireId, staffOrStudentId) {
    const resp = await http.post(`/questionnaire_entries/find/`, {
        "questionnaire_id": questionnaireId,
        "staff_or_student_id": staffOrStudentId
    })
    return resp.data
}

export async function getEntry(entryId) {
    const resp = await http.get(`/questionnaire_entries/${entryId}/`)
    return resp.data
}

export async function deleteEntry(entryId) {
    const resp = await http.delete(`/questionnaire_entries/${entryId}/`)
    return resp.data
}

export async function getEntryResult(entryId) {
    const resp = await http.get(`/questionnaire_entries/${entryId}/result/`)
    return resp.data
}

export async function sendConfirmationEmail(entryId) {
    const resp = await http.post(`/questionnaire_entries/${entryId}/send_confirmation_email/`)
    return resp.data
}

export async function confirmEntry(entryId, secret) {
    const resp = await httpNoAuthService.post(`/forms/entries/${entryId}/confirm/${secret}/`)
    return resp.data
}

export async function getEntryOptions(entryId) {
    const resp = await http.get(`/questionnaire_entries/${entryId}/options/`)
    return resp.data
}

export async function patchEntry(entryId, data) {
    const resp = await http.patch(`/questionnaire_entries/${entryId}/`, data)
    return resp.data
}

export async function addFileToStepEntry(formData, stepEntryId) {
    const resp = await http.post(`/questionnaire_step_entries/${stepEntryId}/add_file/`, formData)
    return resp.data
}

export async function getQuestionnaireEntryExcel(id) {
    const resp = await httpServiceDownloads.get(`/forms/${id}/export/`)
    return resp.data
}