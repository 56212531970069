import React from "react";
import {Route, Routes} from "react-router-dom";
import StaffList from "../staff/StaffList";
import FlextimeStaffList from "./FlextimeStaffList";
import FlextimeEntries from "./FlextimeEntries";
import FlextimeEntryCreateEdit from "./FlextimeEntryCreateEdit";
import FlextimeMyEntries from "./FlextimeMyEntries";


function StaffRouter() {
    return (
        <Routes>
            <Route index element={<StaffList/>}/>
            <Route path="/:staffId" element={<StaffList/>}/>

            <Route path="/flextime" element={<FlextimeStaffList/>}/>
            {/*<Route path="/flextime/open-entries" element={<FlextimeOpenEntries/>}/>*/}
            <Route path="/flextime/entries" element={<FlextimeEntries/>}/>
            <Route path="/flextime/entries/open" element={<FlextimeEntries openOnly={true} />}/>
            <Route path="/flextime/my" element={<FlextimeMyEntries/>}/>
            <Route path="/flextime/:staffId" element={<FlextimeEntries/>}/>
            <Route
                path="/flextime/entries/new"
                element={<FlextimeEntryCreateEdit/>}
            /><Route
            path="/flextime/entries/new/:staffId"
            element={<FlextimeEntryCreateEdit/>}
        />
            <Route
                path="/flextime/entries/:id/edit"
                element={<FlextimeEntryCreateEdit/>}
            />
            {/*<Route path="/flextime/my" element={<FlextimeMyEntries />} />*/}
        </Routes>
    );
}

export default function Staff() {
    return <StaffRouter/>
}
