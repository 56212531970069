import {NarrowPage} from "../../elements/Pages";
import {React, useEffect, useState} from "react";
import Joi from "joi"
import {Input, TextArea} from "../../elements/forms/Inputs"
import {FormSectionContent} from "../../elements/forms/FormLayout"
import Form, {baseHandleChange} from "../../elements/forms/Form"
import {useNavigate, useParams} from "react-router-dom";
import {toast} from "react-toastify";
import {
    addFileToCourseInstructions,
    deleteCourseInstructions,
    getCourse,
    getCourseInstructions,
    patchCourseInstructions,
    postCourseInstructions
} from "../../../api/courses";


export default function CourseInstructionsCreateEdit() {

    const {id, courseId} = useParams()
    const [data, setData] = useState({
        id: null,
        title: "",
        description: null,
        course: {id: parseInt(courseId) || null},
    })
    const [course, setCourse] = useState()

    const [selectedFile, setSelectedFile] = useState();
    const [isFilePicked, setIsFilePicked] = useState(false);

    const [errors, setErrors] = useState({}) // validation errors

    let navigate = useNavigate();

    useEffect(() => {
        loadData()
    }, [id])

    async function loadData() {

        let newData
        let course
        if (id) {
            newData = await getCourseInstructions(id)
            course = await getCourse(newData.course.id)
        } else if (courseId) {
            course = await getCourse(courseId)
            newData = {...data}
        }

        setData(newData)
        setCourse(course)
    }

    const schema = {
        title: Joi.string().min(3)
    }

    const title = (id || data.id) ? "Kursbelehrung bearbeiten" : "Kursbelehrung eintragen"
    const successMessage = (id || data.id) ? "Kursbelehrung aktualisiert" : "Neue Kursbelehrung eingetragen"

    async function onSave() {

        // saving the instance first
        let newData
        if (!data.id) newData = await postCourseInstructions(data)
        else newData = await patchCourseInstructions(data)

        // then adding the file
        if (isFilePicked) {
            const formData = new FormData();
            formData.append('file', selectedFile);
            newData = await addFileToCourseInstructions(formData, newData.id)
        }

        setData(newData)
        toast.success(successMessage)
        navigate(`/courses/${data.course.id}`)
    }

    async function onDelete() {
        await deleteCourseInstructions(data.id)
        toast.success("Kursbelehrung gelöscht.")
        navigate(`/courses/${data.course.id}`)
    }

    function handleChange(evt) {
        baseHandleChange(evt, data, setData, errors, setErrors, schema)
    }

    const handleChangeFile = (event) => {
        setSelectedFile(event.target.files[0])
        setIsFilePicked(true)
    }

    return (

        <NarrowPage title={title}>

            {course && <h2 className="text-md font-bold text-gray-900">{course.course_number} {course.school_subject.name}</h2>}

            <Form id="createCourseInstruction" onSave={onSave}
                  onDelete={onDelete}
                  data={data} setData={setData}
                  errors={errors} setErrors={setErrors}
                  successMessage={successMessage}
                  schema={schema}>

                <FormSectionContent>

                    <Input
                        path="title"
                        type="text"
                        label="Titel"
                        className="sm:col-span-6"
                        onChange={handleChange}
                        errors={errors}
                        data={data}
                    />

                    <TextArea
                        path="description"
                        type="text"
                        label="Beschreibung"
                        className="sm:col-span-6"
                        onChange={handleChange}
                        errors={errors}
                        data={data}
                        rows={3}
                    />

                    <div className="sm:col-span-6 text-sm">
                        <input type="file" name="file" onChange={handleChangeFile}/> <br/>

                        <div>
                            {/*{selectedFile && <p className="py-1">Ausgewählt: {selectedFile.name}<br/></p>}*/}
                            {data.file && <p className="py-1">Bisherige Datei: {data.file}</p>}
                        </div>
                    </div>

                </FormSectionContent>

            </Form>


            <span onClick={() => navigate(`/courses/${courseId}`)}
                  className="cursor-pointer float-right mt-2 font-medium text-imsblue-600">Zurück zum Kurs</span>

        </NarrowPage>
    )
}
