import { ClipboardIcon, PaperClipIcon } from "@heroicons/react/20/solid";
import React, { useEffect } from "react";
import { useEvents } from "../../contexts/EventsContext";
import { formatDateTime } from "../../utils/formatters";
import { copyToClipboard } from "../../utils/helpers";
import {EditButton} from "../elements/Buttons";
import {useAuth} from "../../contexts/AuthContext";
import checkPermission from "../../utils/permissions";
import { LazyLoadImage } from "react-lazy-load-image-component";

const EventDetail = () => {
  const { selectedEvent } = useEvents();
  const {permissions, currentUser, currentUserId} = useAuth();
  const canEditEvent = currentUserId === selectedEvent.event.created_by || checkPermission(permissions, "calendar_app.change_schoolevent") || selectedEvent.event.created_by === currentUser.user_id;
  return (
    <>
      <div className="px-4 py-5 sm:px-6 flex justify-between">
        <h3 className="text-lg font-medium leading-6 text-gray-900">
          {selectedEvent.event?.title}
        </h3>
        {canEditEvent && <EditButton iconOnly={false} label="Bearbeiten" link={`/calendar/events/${selectedEvent.event?.id}/edit`} />}
      </div>
      <div className="border-t border-gray-200 px-4 py-5 sm:p-0">
        <dl className="sm:divide-y sm:divide-gray-200">

          {selectedEvent.event.image &&
            <div className="">
             <LazyLoadImage src={selectedEvent.event.image} />
          </div>}

          <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6">
            <dt className="text-sm font-medium text-gray-500">Datum</dt>
            <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
              {formatDateTime(selectedEvent.start)} bis {formatDateTime(selectedEvent.end)}
            </dd>
          </div>
          <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6">
            <dt className="text-sm font-medium text-gray-500">Wiederholung</dt>
            <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
              {selectedEvent.event.rule && <>{selectedEvent.event.rule.name}
                {selectedEvent.event.end_recurring_period && <>bis {formatDateTime(selectedEvent.event.end_recurring_period)}</>}</>}
            </dd>
          </div>
          <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6">
            <dt className="text-sm font-medium text-gray-500">Raumbuchung</dt>
            <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
              {selectedEvent.event.room_bookings?.map((roomBooking, index) => (
                  <span key={index}>{index > 0 ? <br/> : ""}
                    {roomBooking.room.name}
                  </span>
              ))}
            </dd>
          </div>
          <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6">
            <dt className="text-sm font-medium text-gray-500">Kategorie</dt>
            <dd className="flex items-center mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
              <div className="mr-1">
                <div
                    style={{
                      backgroundColor: selectedEvent.event.category.color,
                      width: '12px',
                      height: '12px',
                      borderRadius: '50%'
                    }}
                ></div>
              </div>
              {selectedEvent.event.category?.name}
            </dd>
          </div>
          <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6">
            <dt className="text-sm font-medium text-gray-500">Zielgruppen</dt>
            <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0 whitespace-pre-wrap">
              {selectedEvent.event.target_groups
                .map((group) => group.name).join(", ")}
            </dd>
          </div>
          <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6">
            <dt className="text-sm font-medium text-gray-500">Beschreibung</dt>
            <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
              {selectedEvent.event.description}
            </dd>
          </div>
          <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6">
            <dt className="text-sm font-medium text-gray-500">Erstellt von</dt>
            <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
              {selectedEvent.event.created_by_full_name}
            </dd>
          </div>
          <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6">
            <dt className="text-sm font-medium text-gray-500">Link</dt>
            <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
              <ul
                role="list"
                className="divide-y divide-gray-200 rounded-md border border-gray-200"
              >
                <li className="flex items-center justify-between py-3 pl-3 pr-4 text-sm">
                  <div className="flex w-0 flex-1 items-center">
                    <span className="ml-2 w-0 flex-1 truncate">
                      {selectedEvent.event.title}
                    </span>
                  </div>
                  <div
                    className="ml-4 flex w-0 flex-1 items-center cursor-pointer"
                    onClick={() =>
                      copyToClipboard(window.location.href, "Link kopiert")
                    }
                  >
                    <ClipboardIcon
                      className="h-5 w-5 flex-shrink-0 text-gray-400"
                      aria-hidden="true"
                    />
                    <p className="font-medium text-imsblue-600 hover:text-imsblue-500">
                      Kopieren
                    </p>
                  </div>
                </li>
              </ul>
            </dd>
          </div>
        </dl>
      </div>
    </>
  );
};

export default EventDetail;
