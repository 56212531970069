import {NarrowPage} from "../../elements/Pages";
import {React, useEffect, useState} from "react";

import {useParams} from 'react-router-dom'
import {Input} from "../../elements/forms/Inputs"
import {FormSectionContent} from "../../elements/forms/FormLayout"
import {approveParentProfileChanges, getParentProfile, patchParentProfile} from "../../../api/school";
import Form, {baseHandleChange} from "../../elements/forms/Form"
import {toast} from "react-toastify";
import {ExclaimationTriangleIcon} from "@heroicons/react/20/solid";
import {SuccessButton} from "../../elements/Buttons";

export default function ReviewParentProfile() {

    const {id} = useParams()

    const [data, setData] = useState()

    useEffect(() => {
        loadData()
    }, [id])

    async function loadData() {
        const newData = await getParentProfile(id)
        setData(newData)
    }

    const [errors, setErrors] = useState({}) // validation errors

    const schema = {}

    async function onSave() {
        const newData = await patchParentProfile(data)
        setData(newData)
        toast.success("Profil gespeichert.")
    }

    async function onApprove() {
        let newData = await patchParentProfile(data)
        newData = await approveParentProfileChanges(newData.id)
        setData(newData)
        toast.success("Profil freigegeben.")
    }

    function handleChange(evt) {
        baseHandleChange(evt, data, setData, errors, setErrors, schema)
    }

    const title = "Elternprofil bearbeiten"
    const successMessage = "Elternprofil aktualisiert"

    if (!data) return <></>
    return (

        <NarrowPage title={title}>

            <h3 className="text-lg font-medium text-gray-900">
                {data.full_name}
            </h3>

            <p className="font-medium text-gray-900">Elternteil von {data.children.map(c => c.full_name).join(", ")}</p>

            <Form id="editParentProfile" onSave={onSave}
                  data={data} setData={setData}
                  className=""
                  errors={errors} setErrors={setErrors}
                  successMessage={successMessage}
                  schema={schema}>

                <FormSectionContent>

                    <div className="col-span-2 font-bold">
                        Bisheriger Wert
                    </div>
                    <div className="col-span-3 font-bold">
                        Neuer Wert
                    </div>
                    <div className="col-span-1 font-bold">Änderung</div>

                    <ReviewRow data={data} handleChange={handleChange} errors={errors} path="profession"/>
                    <ReviewRow data={data} handleChange={handleChange} errors={errors} path="street"/>
                    <ReviewRow data={data} handleChange={handleChange} errors={errors} path="city"/>
                    <ReviewRow data={data} handleChange={handleChange} errors={errors} path="zip_code"/>
                    <ReviewRow data={data} handleChange={handleChange} errors={errors} path="country"/>
                    <ReviewRow data={data} handleChange={handleChange} errors={errors} path="phone_private"/>
                    <ReviewRow data={data} handleChange={handleChange} errors={errors} path="phone_work"/>
                    <ReviewRow data={data} handleChange={handleChange} errors={errors} path="mobile"/>
                    <ReviewRow data={data} handleChange={handleChange} errors={errors} path="fax"/>

                </FormSectionContent>

            </Form>

            <SuccessButton onClick={onApprove} classNameIn="mt-2 float-right" label="Speichern & Freigeben"/>

        </NarrowPage>
    )
}

function ReviewRow({handleChange, errors, data, path, label}) {

    return (<>
            <div className="col-span-2 align-bottom pt-5">
                {data[path]}
            </div>
            <Input
                path={"_" + path}
                type="text"
                label={label}
                className="col-span-3"
                onChange={handleChange}
                errors={errors}
                data={data}
            />
            <div className="col-span-1 align-bottom pt-5 text-center">
                {data[path] !== data["_" + path]
                    ? <ExclaimationTriangleIcon className="h-5 w-5 text-yellow-400" aria-hidden="true"/>
                    : ""}
            </div>
        </>
    )
}