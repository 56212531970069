import React, {useEffect} from 'react';

import {useQuery} from '@tanstack/react-query'
import {useNavigate} from "react-router-dom";
import {getMyAlumnus} from "../../../api/alumni";
import {useAuth} from "../../../contexts/AuthContext";
import {getMyAlumnusStaff} from "../../../api/staff";


export default function MyProfile() {

    const {currentUser} = useAuth()
    const navigate = useNavigate()
    const {data, isSuccess} = useQuery(['my_alumnus_staff', currentUser.user_id], () => getMyAlumnusStaff(), {})

    function getLink(id) {
        return `/alumni-staff/${id}/`
    }

    useEffect(() => {
        if (isSuccess) {
            navigate(getLink(data.id))
        }
    }, [data])

    return <></>
}
