import { Navigate, Outlet, Route, Routes, useLocation } from "react-router-dom";
import Dashboard from "../components/dashboard/Dashboard";
import Login from "../components/auth/Login";
import LoginAs from "../components/auth/LoginAs";
import MainLayout from "../components/layout/MainLayout";
import NoSidebarLayout from "../components/layout/NoSidebarLayout";
import Logout from "../components/auth/Logout";
import { useAuth } from "../contexts/AuthContext";
import Forbidden from "../components/auth/Forbidden";
import ForgotPassword from "../components/auth/ForgotPassword";
import ResetPassword from "../components/auth/ResetPassword";
import ChangePassword from "../components/modules/settings/ChangePassword";
import SettingsLayout from "../components/modules/settings/SettingsLayout";
import Calendar from "../components/calendar/Calendar";
import Logbook from "../components/modules/student_logbook/Logbook";
import School from "../components/modules/school/School";
import Staff from "../components/modules/staff/Staff";
import Downloads from "../components/modules/downloads/Downloads";
import BoardingSchool from "../components/modules/boarding_school/BoardingSchool";
import { EventProvider } from "../contexts/EventsContext";
import Messenger from "../components/modules/messenger/Messenger";
import ParentConference from "../components/modules/parent_conference/ParentConference";
import Reports from "../components/modules/reports/Reports";
import React from "react";
import FlatPages from "../components/modules/misc/FlatPages";
import SchoolEventCreateEdit from "../components/calendar/SchoolEventCreateEdit";
import Tutoring from "../components/modules/tutoring/Tutoring";
import TutoringConfirm from "../components/modules/tutoring/TutoringConfirm";
import Projects from "../components/modules/projects/Projects";
import Courses from "../components/modules/courses/Courses";
import Organizational from "../components/modules/organizational/Organizational";
import ClassRegister from "../components/modules/class_register/ClassRegister";
import EditProfile from "../components/modules/settings/EditProfile";
import Timetables from "../components/modules/timetables/Timetables";
import SubstitutionDetail from "../components/modules/timetables/SubstitutionDetail";
import Studierzeit from "../components/modules/studierzeit/Studierzeit";
import Assessments from "../components/modules/assessments/Assessments";
import Certificates from "../components/modules/certificates/Certificates";
import Grades from "../components/modules/grades/Grades";
import Shop from "../components/modules/shop/Shop";
import TeachersLounge from "../components/modules/timetables/TeachersLounge";
import Aula from "../components/modules/timetables/Aula";
import Plupus from "../components/modules/plupus/Plupus";
import Career from "../components/modules/career/Career";
import WorkshopDay from "../components/modules/workshop_day/WorkshopDay";
import Gallery from "../components/modules/gallery/Gallery";
import Maintenance from "../components/modules/maintenance/Maintenance";
import Notification from "../components/modules/settings/Notifications";
import Alumni from "../components/modules/alumni/Alumni";
import AlumniStaff from "../components/modules/alumni_staff/AlumniStaff";
import Questionnaires from "../components/modules/questionnaires/Questionnaires";
import ConfirmEntry from "../components/modules/questionnaires/ConfirmEntry";
import SchoolYearChange from "../components/modules/school_year_change/SchoolYearChange";
import SchoolEventList from "../components/calendar/SchoolEventList";
import Applicants from "../components/modules/applicants/Applicants";
import UserQuiz from "../components/modules/user_quiz/UserQuiz";

export default function IMSRouter() {

    return (
        <Routes>
            <Route element={<ProtectedRoutes/>}>

                {/* Protected routes with main layout */}

                <Route element={<MainLayout hideSecondaryNav={true} />}>
                    {/* Messenger */}
                    <Route path="/messenger/*" element={<Messenger/>} />
                </Route>
                <Route element={<MainLayout/>}>

                    <Route index element={<Dashboard/>}/>
                    <Route path="/forbidden" element={<Forbidden/>}/>

                    {/* Calendar */}
                    <Route
                        path="/calendar"
                        element={
                            <EventProvider>
                                <Calendar/>
                            </EventProvider>
                        }
                    />
                    <Route path="/calendar/events/list" element={<SchoolEventList />}/>
                    <Route path="/calendar/events/new" element={<SchoolEventCreateEdit/>}/>
                    <Route path="/calendar/events/:id/edit" element={<SchoolEventCreateEdit/>}/>

                    {/* Module with their own routers */}
                    <Route path="/logbook/*" element={<Logbook/>}/>
                    <Route path="/tutoring/*" element={<Tutoring/>}/>
                    <Route path="/courses/*" element={<Courses/>}/>
                    <Route path="/school/*" element={<School/>}/>
                    <Route path="/staff/*" element={<Staff/>}/>
                    <Route path="/timetables/*" element={<Timetables/>}/>
                    <Route path="/shop/*" element={<Shop/>}/>
                    <Route path="/downloads/*" element={<Downloads/>}/>
                    <Route path="/boarding-school/*" element={<BoardingSchool/>}/>
                    <Route path="/parent-conference/*" element={<ParentConference/>}/>
                    <Route path="/reports/*" element={<Reports/>}/>
                    <Route path="/projects/*" element={<Projects/>}/>
                    <Route path="/service/*" element={<Organizational/>}/>
                    <Route path="/class-register/*" element={<ClassRegister/>}/>
                    <Route path="/studierzeit/*" element={<Studierzeit/>}/>
                    <Route path="/assessments/*" element={<Assessments/>}/>
                    <Route path="/certificates/*" element={<Certificates/>}/>
                    <Route path="/grades/*" element={<Grades/>}/>
                    <Route path="/plupus/*" element={<Plupus/>}/>
                    <Route path="/career/*" element={<Career/>}/>
                    <Route path="/workshop-day/*" element={<WorkshopDay/>}/>
                    <Route path="/gallery/*" element={<Gallery />} />
                    <Route path="/maintenance/*" element={<Maintenance />} />
                    <Route path="/alumni/*" element={<Alumni />} />
                    <Route path="/alumni-staff/*" element={<AlumniStaff />} />
                    <Route path="/questionnaires/*" element={<Questionnaires />} />
                    <Route path="/school-year-change/*" element={<SchoolYearChange />} />
                    <Route path="/applicants/*" element={<Applicants/>}/>
                    <Route path="/quiz/*" element={<UserQuiz/>}/>

                    {/* Flat Pages */}
                    <Route path="/pages" element={<FlatPages/>}/>
                    <Route path="/pages/:slug" element={<FlatPages/>}/>

                    <Route path="/users" element={<LoginAs/>}/>

                    {/* Profile and account settings */}
                    <Route element={<SettingsLayout/>}>
                        <Route path="/settings/change-password" element={<ChangePassword/>}/>
                        <Route path="/settings/profile" element={<EditProfile/>}/>
                        <Route path="/settings/notifications" element={<Notification/>}/>
                    </Route>

                </Route>

                {/* Protected routes with no sidebar */}
                <Route element={<NoSidebarLayout/>}>
                    <Route path="/logout" element={<Logout/>}/>
                </Route>
            </Route>

            {/* Public routes with no sidebar */}
            <Route element={<NoSidebarLayout/>}>
                <Route path="/login" element={<Login/>}/>
                <Route path="/forgot-password" element={<ForgotPassword/>}/>

                <Route path="reset-password">
                    <Route path=":token" element={<ResetPassword/>}/>
                    <Route path=":token/:uid" element={<ResetPassword/>}/>
                </Route>

                <Route path="/public/tutoring/:id/confirm/:secret" element={<TutoringConfirm/>}/>
                <Route path="/timetables/substitutions/:id/confirm/:secret" element={<SubstitutionDetail/>}/>
                <Route path="/questionnaires/entries/:id/confirm/:secret" element={<ConfirmEntry />}/>

                <Route
                    path="/public/aula/t0i57tlfe9fq2r2k6nnr2fjvhk82jd92ttqthjgfsqmb55qisl7p3rqa6jyqfw3x8wfxp9x05tfdey9ilgiev6h2ew1jxh8q51ytxl1js3ht0loj4yo6czv"
                    element={<Aula/>}/>
                <Route
                    path="/public/lehrerzimmer/3ldkbczl2urcm04aslk5m9dv1bpag6szszo7k6972p6t9fphcmrk92vrg2q3pzcl8w7dfwopvqbor0sn6czx6bfg4hcpwsifdln8jrgfqjwn3u1zsc8hfkivakri"
                    element={<TeachersLounge/>}/>

            </Route>

        </Routes>
    );


}

const ProtectedRoutes = () => {
  const { currentUser, loading } = useAuth()
  const location = useLocation()
  return !loading && currentUser ? (
    <Outlet />
  ) : (
    <Navigate to="/login" replace={true} state={{ prev: location.pathname }} />
  );
};

const RoleBasedRoutes = ({ allowedGroups }) => {
  const { currentUser, loading } = useAuth();
  return currentUser?.groups?.find((group) =>
    allowedGroups?.includes(group)
  ) ? (
    <Outlet />
  ) : (
    <Navigate to="/forbidden" replace={true} />
  );
};

export const RouteWithPermissionCheck = ({ requiredPermission }) => {
  const { currentUser, permissions, loading } = useAuth();
  if (loading || permissions.length === 0) return <></>;
  return currentUser.is_superuser === true ||
    permissions.find((e) => e === requiredPermission) ? (
    <Outlet />
  ) : (
    <Forbidden />
  );
};
