import {NarrowPage} from "../../elements/Pages";
import {React, useEffect, useState} from "react";
import Joi from "joi"
import {useQuery} from '@tanstack/react-query'
import {DateInput, Select, TextArea} from "../../elements/forms/Inputs"
import {FormSectionContent} from "../../elements/forms/FormLayout"
import {getExamTypeOptions, getGFSHalfYearOptions, getStudentInfoAll, gfsApi} from "../../../api/school";
import Form, {baseHandleChange, baseHandleDateChange} from "../../elements/forms/Form"
import {useNavigate, useParams} from "react-router-dom";
import {toast} from "react-toastify";
import {getStudentCourses} from "../../../api/courses";

const halfYearValidator = (value, helpers) => {
    // Access the parent object to check the student's grade
    const { student } = helpers.state.ancestors[0];
    if (student && (student?.grade === 'K1' || student?.grade === 'K2')) {
        if (!value) {
            // Return an error if half_year is not provided when it should be
            return helpers.error('any.required');
        }
    }
    // Return the value unchanged if validation passes
    return value;
};

export default function GfsCreateEdit() {

    const {id} = useParams()

    const [data, setData] = useState({
        id: null,
        student: null,
        course: null,
        topic: null,
        date: null
    })
    const [errors, setErrors] = useState({}) // validation errors

    const schema = {
        student: Joi.object().required(),
        // half_year: Joi.object().custom(halfYearValidator, 'custom validation for half_year').optional(),
        course: Joi.object().required(),
        topic: Joi.string().required()
    }

        // ['K1', 'K2'].includes(data.student.grade)

    const {data: students} = useQuery(["student_info"], getStudentInfoAll, {refetchInterval: 1000 * 60 * 60 * 24})
    const {data: courses} = useQuery(["student_course_list"], getStudentCourses, {refetchInterval: 1000 * 60 * 60 * 24})
    const {data: halfYearOptions} = useQuery(['gfs_half_year_options'], getGFSHalfYearOptions, {refetchInterval: 1000 * 60 * 60 * 24})

    let navigate = useNavigate();

    useEffect(() => {
        loadData()
    }, [id])

    async function loadData() {
        if (!id) return
        const newData = await gfsApi('get', id)
        setData(newData)
    }

    function getAvailableCourses(student) {
        if (student && courses) {
            const availableCourses = courses.students.find(c => c.id === student.id)
            return availableCourses ? availableCourses.courses : []
        }
        return []
    }

    const title = (id || data.id) ? "GFS bearbeiten" : "Neue GFS eintragen"
    const successMessage = (id || data.id) ? "GFS aktualisiert" : "GFS eingetragen"

    async function onSave() {
        let newData
        if (!data.id) newData = await gfsApi('post', null, data)
        else newData = await gfsApi('patch', null, data)
        setData(newData)
        toast.success(successMessage)
        navigate(`/grades/gfs/${newData.id}`)
    }

    async function onDelete() {
        await gfsApi('delete', data.id)
        toast.success("GFS gelöscht.")
        navigate(`/grades/gfs`)
    }

    function handleChange(evt) {
        baseHandleChange(evt, data, setData, errors, setErrors, schema)
    }

    function handleDateChange(date, path) {
        baseHandleDateChange(date, path, data, setData, errors, setErrors, schema, false)
    }

    return (

        <NarrowPage title={title}>

            <Form id="createFacharbeit" onSave={onSave}
                  onDelete={onDelete}
                  data={data} setData={setData}
                  errors={errors} setErrors={setErrors}
                  successMessage={successMessage}
                  schema={schema}>

                <FormSectionContent>

                    <Select
                        path="student"
                        label="SchülerIn"
                        className="sm:col-span-3"
                        options={students}
                        valueAttr="id"
                        labelAttr="full_name"
                        onChange={handleChange}
                        errors={errors}
                        data={data}
                        isClearable={false}
                        valueOnly={false}
                    />

                    <Select
                        path="course"
                        label="Kurs"
                        className="sm:col-span-3"
                        options={getAvailableCourses(data.student)}
                        valueAttr="id"
                        labelAttr="course_number"
                        onChange={handleChange}
                        errors={errors}
                        data={data}
                        isClearable={false}
                        valueOnly={false}
                    />

                    {['K1', 'K2'].includes(data.student?.grade) && <Select
                        path="half_year"
                        label="Halbjahr"
                        className="sm:col-span-3"
                        options={halfYearOptions}
                        valueAttr="value"
                        labelAttr="label"
                        onChange={handleChange}
                        errors={errors}
                        data={data}
                        isClearable={true}
                        valueOnly={true}
                    />}

                    <DateInput
                        className="col-span-3"
                        path="date"
                        label="Datum"
                        onChange={handleDateChange}
                        errors={errors}
                        data={data}
                        todayButton={true}
                        tomorrowButton={true}
                    />

                    <TextArea
                        path="topic"
                        type="text"
                        label="Thema"
                        className="sm:col-span-6"
                        onChange={handleChange}
                        errors={errors}
                        data={data}
                        rows={3}
                    />

                </FormSectionContent>

            </Form>

            <span onClick={() => navigate(-1)}
                  className="cursor-pointer float-right mt-2 font-medium text-imsblue-600">Zurück</span>

        </NarrowPage>
    )
}
