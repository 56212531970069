import React from "react";
import {Route, Routes} from "react-router-dom";
import ProductList from "./ProductList";
import ProductDetail from "./ProductDetail";
import ProductCart from "./ProductCart";
import OrderList from "./OrderList";
import OrderDetail from "./OrderDetail";


function ShopRouter() {
    return (
        <Routes>
            <Route path="" element={<ProductList/>}/>
            <Route path="product/:id" element={<ProductDetail/>}/>
            <Route path="cart" element={<ProductCart/>}/>
            <Route path="my-orders" element={<OrderList/>}/>
            <Route path="orders/:id" element={<OrderDetail/>}/>
        </Routes>
    )
}

export default function Shop() {

    return <ShopRouter/>
}