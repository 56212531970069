import {SimplePage} from "../../elements/Pages";
import React, {useState} from "react";

import {useQuery} from "@tanstack/react-query";
import {useAuth} from "../../../contexts/AuthContext";
import ListViewTable from "../../elements/tables/ListViewTable";
import checkPermission from "../../../utils/permissions";
import {PrimaryButton} from "../../elements/Buttons";
import {purchaseRequestApi} from "../../../api/organizational";

const columns = [
    {
        accessorKey: "title",
        id: "title",
        header: "Titel",
        enableColumnFilter: false,
        headerClassNames: "medium-filter",
    },
    {
        accessorKey: "costs",
        id: "costs",
        header: "Kosten",
        enableColumnFilter: false,
        headerClassNames: "medium-filter",
        cell: ({row, value}) => (
            <>{row.original.costs} € ({row.original.cost_type.toLowerCase()})</>
        )
    },
    {
        accessorKey: "created_by_full_name",
        id: "created_by_full_name",
        header: "Beantragt von",
        hide: true,
        enableColumnFilter: true
    },
    {
        accessorKey: "reason_choice",
        id: "reason_choice",
        header: "Grund",
        enableColumnFilter: true,
        headerClassNames: "medium-filter",
        filterFn: "containsString",
    },
    {
        accessorKey: "purchase_by",
        id: "purchase_by",
        header: "Anschaffung durch",
        enableColumnFilter: true,
    },
    {
        accessorFn: (row) => row.responsible_for_purchase ? `${row.responsible_for_purchase?.full_name}` : "",
        id: "responsible_for_purchase",
        header: "Zuständig für Bestellung",
        enableColumnFilter: true,
    },
    {
        accessorKey: "has_been_ordered",
        id: "has_been_ordered",
        header: "Bestellt",
        columnType: "yesno",
        enableColumnFilter: true,
        headerClassNames: "narrow-filter",
    },
    {
        accessorKey: "get_status_display",
        id: "get_status_display",
        header: "Status",
        enableColumnFilter: true,
        filterFn: "containsString",
        headerClassNames: "narrow-filter"
    },
]


export default function PurchaseRequests() {

    const {permissions, currentUser} = useAuth();
    const [recentOrAll, setRecentOrAll] = useState("recent");

    const {isLoading, isError, isSuccess, data, error, refetch} = useQuery(
        ["purchase_requests", currentUser.user_id],
        () => purchaseRequestApi('get'),
        {}
    )

    const canAdd = checkPermission(permissions, "organizational.add_purchaserequest")

    function getRowLink(row) {
        return `/service/purchase-requests/${row.original.id}/edit`
    }

    return (
        <SimplePage title="Anschaffungsanträge">

            <div className="px-4 sm:px-6 lg:px-8">
                <div className="sm:flex sm:items-center">
                    <div className="sm:flex-auto">
                        <h3 className="text-lg leading-6 font-medium text-gray-900">
                            Anschaffungsanträge
                        </h3>
                    </div>
                    <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
                        {canAdd && <PrimaryButton
                            label="Neuer Anschaffungsantrag"
                            link={`/service/purchase-requests/new`}
                        />}
                    </div>
                </div>
                <div className="flex flex-col">
                    <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
                        <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                            {isSuccess && data && data?.length > 0 && (
                                <ListViewTable
                                    data={data}
                                    columns={columns}
                                    isLoading={isLoading}
                                    disableGlobalSearch={false}
                                    getRowLink={getRowLink}
                                />
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </SimplePage>
    )
}
