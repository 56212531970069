import env from "react-dotenv";

export default function Logo() {

    return (
        <>
            {env.SCHOOL === 'KPI' && <img
                className="h-8 w-full h-auto py-4 px-2"
                src="/img/kurpfalz_logo.svg"
                alt="Kurpfalz-Internat"
            />}
            {env.SCHOOL === 'ST' && <img
                className="h-8 w-full h-auto px-2 py-4 bg-white w-100"
                src="/img/schloss_logo.svg"
                alt="Schloss Torgelow"
            />}
        </>
    )
}
