import {SimplePage} from "../../elements/Pages";
import React, {useEffect, useState} from "react";

import {useQuery} from "@tanstack/react-query";
import ListViewTable, {TableDownloadLink, TableLink} from "../../elements/tables/ListViewTable";
import {
    getBerlinListPDF,
    getDepartureListExcel, getDepartureListPDF,
    getDepartureWeekends, getHamburgListPDF, getTaxiListPDF,
    houseApi
} from "../../../api/boarding_school";
import {useParams} from "react-router-dom";
import checkPermission from "../../../utils/permissions";
import {useAuth} from "../../../contexts/AuthContext";
import {DownloadButton} from "../../elements/Buttons";
import env from "react-dotenv";

const columns = [
    {
        accessorKey: "departure_date",
        id: "date",
        header: "Abreise",
        columnType: "date",
        enableColumnFilter: false,
        headerClassNames: "narrow-filter",
    },
    {
        accessorKey: "arrival_date",
        id: "date",
        header: "Anreise",
        columnType: "date",
        enableColumnFilter: false,
        headerClassNames: "narrow-filter",
    },
    {
        accessorKey: "id",
        id: "erfassen",
        header: "Erfassen",
        cell: ({value, row}) => (
            <TableLink
                label="Erfassen"
                to={`/boarding-school/departure-lists/${row.original.id}/edit`}
            />
        ),
        enableColumnFilter: false,
        disableRowLink: true
    },
    {
        accessorKey: "id",
        id: "export",
        header: "PDF",
        cell: ({value, row}) => (
            <TableDownloadLink
                label="PDF"
                fileName={`Heimfahrliste.pdf`}
                apiFunction={() => getDepartureListPDF(row.original.id)} />
        ),
        enableColumnFilter: false,
        disableRowLink: true
    },
    {
        accessorKey: "id",
        id: "export",
        header: "Excel",
        cell: ({value, row}) => (
            <TableDownloadLink
                label="Excel"
                fileName={`Heimfahrliste.xls`}
                apiFunction={() => getDepartureListExcel(row.original.id)} />
        ),
        enableColumnFilter: false,
        disableRowLink: true
    }
];

const columnsST = [
    {
        accessorKey: "id",
        id: "berlin_list",
        header: "Berlin",
        cell: ({value, row}) => (
            <TableDownloadLink
                label="Berlin"
                fileName={`Berlinbusliste.xls`}
                apiFunction={() => getBerlinListPDF(row.original.id)} />
        ),
        enableColumnFilter: false,
        disableRowLink: true
    },
    {
        accessorKey: "id",
        id: "hamburg_list",
        header: "Hamburg",
        cell: ({value, row}) => (
            <TableDownloadLink
                label="Hamburg"
                fileName={`Hamburgbusliste.xls`}
                apiFunction={() => getHamburgListPDF(row.original.id)} />
        ),
        enableColumnFilter: false,
        disableRowLink: true
    },
    {
        accessorKey: "id",
        id: "taxi_list",
        header: "Taxi So",
        cell: ({value, row}) => (
            <TableDownloadLink
                label="Taxi So"
                fileName={`Taxiliste So.xls`}
                apiFunction={() => getTaxiListPDF(row.original.id)} />
        ),
        enableColumnFilter: false,
        disableRowLink: true
    }
]

export default function DepartureWeekends() {

    const {permissions} = useAuth()
    const [house, setHouse] = useState()
    const {houseId} = useParams()
    const {isLoading, isError, isSuccess, data, error, refetch} = useQuery(
        ["departure_weekends"],
        () => getDepartureWeekends(),
        {}
    );

    useEffect(() => {
        loadData()
    }, [houseId])

    async function loadData() {
        if (houseId) {
            const newHouse = await houseApi('get', houseId)
            setHouse(newHouse)
        }
    }

    function getRowLink(row) {
        return `/boarding-school/departure-lists/${row.original.id}`;
    }

    let useColumns = env.SCHOOL === 'KPI' ? columns : [...columns, ...columnsST]

    return (
        <SimplePage>
            <div className="px-4 sm:px-6 lg:px-8">
                <div className="sm:flex sm:items-center">
                    <div className="sm:flex-auto">
                        <h3 className="text-lg leading-6 font-medium text-gray-900">
                            Heimfahrtlisten
                        </h3>
                    </div>
                    <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none flex gap-2">
                    </div>
                </div>
                <div className="flex flex-col">
                    <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
                        <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                            {isSuccess && data && (
                                <ListViewTable
                                    data={data}
                                    columns={useColumns}
                                    isLoading={isLoading}
                                    disableGlobalSearch={false}
                                    getRowLink={getRowLink}
                                />
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </SimplePage>
    );
}
