import React from "react";
import {useAuth} from "../contexts/AuthContext";
import {Navigate, Outlet} from "react-router-dom";

export default function checkPermission(permissions, permissionToCheck) {
    return permissions.find(e => e === permissionToCheck) || false
}

export function checkGroup(groupName, currentUser) {
    return currentUser?.groups?.find((group) => group.name == groupName)
}
