import http from "./../services/httpService";


export async function getUserImages() {
    const resp = await http.get('/accounts/user_images/')
    return resp.data
}

export async function getUsersForAdmin() {
    const resp = await http.get('/users_for_admin/')
    return resp.data
}

export async function getActiveStaffAndStudentUsers() {
    const resp = await http.get('/user_info/active_staff_and_student_users/')
    return resp.data
}

export async function getActiveStaffUsers() {
    const resp = await http.get('/user_info/active_staff_users/')
    return resp.data
}

export async function getUserInfo(userId) {
    const resp = await http.get('/user_info/' + userId)
    return resp.data
}

export async function getPermissionsAndNavigation() {
    const resp = await http.get('/users/permissions_and_navigation/')
    return resp.data
}

export async function getGroups() {
    const resp = await http.get('/groups/')
    return resp.data
}

export async function getMainGroups(excludeAlumniGroups) {
    const resp = await http.get(`/groups/main_user_groups/?exclude_alumni_groups=${excludeAlumniGroups}`)
    return resp.data
}

export async function getTutoringTeachers() {
    const resp = await http.get('/user_info/tutoring_teachers/')
    return resp.data
}

export async function getUsers() {
    const resp = await http.get('/users/')
    return resp.data
}

export async function addImageToUser(formData) {
    const resp = await http.post(`/users/add_image/`, formData)
    return resp.data
}
