import React from 'react';
import {Navigate, Route, Routes} from "react-router-dom";
import {RoomBookingProvider} from "../../../contexts/RoomBookingsContext";
import RoomBookingCalendar from "./RoomBookingCalendar";
import RoomBookingCreateEdit from "./RoomBookingCreateEdit";
import {DeviceBookingProvider} from "../../../contexts/DeviceBookingsContext";
import DeviceBookingCalendar from "./DeviceBookingCalendar";
import DeviceBookingCreateEdit from "./DeviceBookingCreateEdit";
import AnnouncementCreateEdit from "./AnnouncementCreateEdit";
import Announcements from "./Announcements";
import PurchaseRequests from "./PurchaseRequests";
import PurchaseRequestCreateEdit from "./PurchaseRequestCreateEdit";
import KitchenMealtimes from "./KitchenMealtimes";
import Keys from "./Keys";
import KeyIssues from "./KeyIssues";
import KeyIssueCreateEdit from "./KeyIssueCreateEdit";
import KeyDetail from "./KeyDetail";


function OrganizationalRouter() {

    return (
        <Routes>


            {/* Room bookings */}
            <Route
                path="/room-bookings/"
                element={<Navigate to="/service/room-bookings/calendar" replace={true}/>}
            />
            <Route
                path="/room-bookings/:roomId/calendar"
                element={<RoomBookingProvider><RoomBookingCalendar/></RoomBookingProvider>}
            />
            <Route
                path="/room-bookings/calendar"
                element={<RoomBookingProvider><RoomBookingCalendar/></RoomBookingProvider>}
            />

            <Route path="/room-bookings/new" element={<RoomBookingCreateEdit/>}/>
            <Route path="/room-bookings/new/:roomId" element={<RoomBookingCreateEdit/>}/>
            <Route path="/room-bookings/:id/edit" element={<RoomBookingCreateEdit/>}/>


            {/* Device bookings */}
            <Route
                path="/device-bookings/"
                element={<Navigate to="/service/device-bookings/calendar" replace={true}/>}
            />
            <Route
                path="/device-bookings/:deviceId/calendar"
                element={<DeviceBookingProvider><DeviceBookingCalendar/></DeviceBookingProvider>}
            />
            <Route
                path="/device-bookings/calendar"
                element={<DeviceBookingProvider><DeviceBookingCalendar/></DeviceBookingProvider>}
            />

            <Route path="/device-bookings/new" element={<DeviceBookingCreateEdit/>}/>
            <Route path="/device-bookings/new/:deviceId" element={<DeviceBookingCreateEdit/>}/>
            <Route path="/device-bookings/:id/edit" element={<DeviceBookingCreateEdit/>}/>

            {/* Announcements */}
            <Route path="/announcements" element={<Announcements/>}/>
            <Route path="/announcements/new" element={<AnnouncementCreateEdit/>}/>
            <Route path="/announcements/:id/edit" element={<AnnouncementCreateEdit/>}/>

            {/* Purchase requests */}
            <Route path="/purchase-requests" element={<PurchaseRequests/>}/>
            <Route path="/purchase-requests/new" element={<PurchaseRequestCreateEdit/>}/>
            <Route path="/purchase-requests/:id/edit" element={<PurchaseRequestCreateEdit/>}/>

            <Route path="/kitchen" element={<KitchenMealtimes/>}/>

            {/* Keys */}
            <Route path="/keys" element={<Keys/>}/>
            <Route path="/keys/:id" element={<KeyDetail/>}/>
            <Route path="/key-issues" element={<KeyIssues/>}/>
            <Route path="/key-issues/new" element={<KeyIssueCreateEdit/>}/>
            <Route path="/key-issues/new/:keyId" element={<KeyIssueCreateEdit/>}/>
            <Route path="/key-issues/:id/edit" element={<KeyIssueCreateEdit/>}/>

        </Routes>
    )
}


export default function Organizational() {
    return <OrganizationalRouter/>
}
