import {SimplePage} from "../../elements/Pages";
import React, {useEffect, useState} from "react";

import {useQuery} from "@tanstack/react-query";
import {useAuth} from "../../../contexts/AuthContext";
import ListViewTable, {TableLink,} from "../../elements/tables/ListViewTable";
import {getAssessmentPeriods, getAssessmentPermissions} from "../../../api/assessments";
import {useSchoolYears} from "../../../contexts/SchoolYearContext";
import env from "react-dotenv";
import SchoolYearFilter from "../../elements/SchoolYearFilter";


export default function StudierzeitAssessmentPeriods() {

    const {currentUser} = useAuth();
    const {schoolYears, currentSchoolYear} = useSchoolYears();
    const [schoolYearId, setSchoolYearId] = useState();

    const {isLoading, isSuccess, data} = useQuery(
        ["assessment_periods", schoolYearId],
        () => getAssessmentPeriods(schoolYearId),
        {
            enabled: !!schoolYearId, refetchInterval: 1000 * 60 * 60 * 24
        }
    )

    useEffect(() => {
        if (currentSchoolYear) setSchoolYearId(currentSchoolYear["id"]);
    }, [currentSchoolYear]);

    const {data: assessmentsPermissions} = useQuery(['assessment_permissions', currentUser.id], getAssessmentPermissions)

    const isManagement = assessmentsPermissions?.is_management
    const isStudierzeitAufsicht = assessmentsPermissions?.is_studierzeit_aufsicht

    const columns = [
        {
            accessorKey: "name",
            id: "name",
            header: "Bezeichnung",
            enableColumnFilter: false,
        },
        {
            accessorKey: "grades_and_school_classes",
            id: "grades_and_school_classes",
            header: "Klassen",
            enableColumnFilter: false,
        },
        ...env.SCHOOL === 'KPI' ? [
            {
                accessorKey: "school_types",
                id: "school_types",
                header: "Schulart",
                enableColumnFilter: false,
            },
        ] : [],
        {
            accessorKey: "entry_start_date",
            id: "entry_start_date",
            header: "Beginn Eingabe",
            columnType: "date",
            enableColumnFilter: false,
        },
        {
            accessorKey: "entry_end_date",
            id: "entry_end_date",
            header: "Ende Eingabe",
            columnType: "date",
            enableColumnFilter: false,
        },
        {
            accessorKey: "marks_end_date",
            id: "marks_end_date",
            header: "Noten bis",
            columnType: "date",
            enableColumnFilter: false,
        },
        {
            accessorKey: "viewing_start_date",
            id: "viewing_start_date",
            header: "Ansicht ab",
            columnType: "date",
            enableColumnFilter: false,
        },
        {
            accessorKey: "id",
            id: "link",
            cell: (info) => (
                <div className="flex gap-4">

                    {isManagement &&
                        <TableLink
                            label="Alle Schüler"
                            to={`/assessments/studierzeit/${info.getValue()}/students`}
                        />}

                    {isStudierzeitAufsicht && <TableLink
                        label="Eingabe"
                        to={`/assessments/studierzeit/${info.getValue()}/entry`}
                    />}

                </div>

            ),
            header: "Studierzeitbeurteilungen",
            enableColumnFilter: false,
            disableRowLink: true
        }
    ]

    function getTrClassNames(row, index) {
        return row.original.is_active ? 'bg-green-50' : (index % 2 === 0 ? undefined : "bg-gray-50 ")
    }

    return (
        <SimplePage>
            <div className="px-4 sm:px-6 lg:px-8">
                <div className="sm:flex sm:items-center">
                    <div className="sm:flex-auto">
                        <h3 className="text-lg leading-6 font-medium text-gray-900">
                            Studierzeitbeurteilungen
                        </h3>
                    </div>
                    <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
                    </div>
                </div>
                <div className="flex flex-col">
                    <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">

                        <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                            <SchoolYearFilter
                                schoolYears={schoolYears}
                                schoolYearId={schoolYearId}
                                setSchoolYearId={setSchoolYearId}
                            />
                        </div>

                        <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                            {isSuccess && data && (
                                <ListViewTable
                                    data={data}
                                    columns={columns}
                                    isLoading={isLoading}
                                    disableGlobalSearch={false}
                                    getRowLink={null}
                                    getTrClassNames={getTrClassNames}
                                />
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </SimplePage>
    );
}
