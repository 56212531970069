import {SimplePage} from "../../elements/Pages";
import React, {useEffect, useState} from "react";

import {useQuery} from "@tanstack/react-query";
import ListViewTable, {TableLink} from "../../elements/tables/ListViewTable";
import {getCourseTopicsCourseList} from "../../../api/courses";
import SchoolYearFilter from "../../elements/SchoolYearFilter";
import {useSchoolYears} from "../../../contexts/SchoolYearContext";

const columns = [
    {
        accessorKey: "course_number",
        id: "course_number",
        header: "Kurs",
        enableColumnFilter: false,
        cell: ({row, value}) => (
            <TableLink
                label={row.original.course_number}
                to={`/courses/${row.original.id}`}
            />
        ),
        disableRowLink: true
    },
    {
        accessorKey: "id",
        id: "nums",
        header: "Erstellt/Epochen",
        enableColumnFilter: false,
        cell: ({row, value}) => (
            <>{row.original.num_tdps}/{row.original.num_epochs}</>
        ),
    },
    {
        accessorKey: "school_subject",
        id: "school_subject",
        header: "Fach",
        enableColumnFilter: true,
        filterFn: "containsString",
    },
    {
        accessorKey: "teacher",
        id: "staff",
        header: "LehrerIn",
        columnType: "staff",
        enableColumnFilter: false,
    },
];

export default function CourseTopicsCourseList() {

    const {schoolYears, currentSchoolYear} = useSchoolYears();
    const [schoolYearId, setSchoolYearId] = useState();

    const {isLoading, isError, isSuccess, data, error, refetch} = useQuery(
        ["course_topcis_course_list", schoolYearId],
        () => getCourseTopicsCourseList(schoolYearId),
        {
            enabled: !!schoolYearId, refetchInterval: 1000 * 60 * 60
        }
    );

    useEffect(() => {
        if (currentSchoolYear) setSchoolYearId(currentSchoolYear["id"]);
    }, [currentSchoolYear]);


    function getRowLink(row) {
        return `/courses/${row.original.id}/course-topics`;
    }

    return (
        <SimplePage>
            {/*  Simple striped table */}
            <div className="px-4 sm:px-6 lg:px-8">
                <div className="sm:flex sm:items-center">
                    <div className="sm:flex-auto">
                        <h3 className="text-lg leading-6 font-medium text-gray-900 mb-2">
                            Themenverteilungspläne nach Kursen
                        </h3>
                    </div>
                    <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
                    </div>
                </div>
                <div className="flex flex-col">
                    <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">

                        <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                            <SchoolYearFilter
                                schoolYears={schoolYears}
                                schoolYearId={schoolYearId}
                                setSchoolYearId={setSchoolYearId}
                            />
                        </div>

                        <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                            {isSuccess && data && (
                                <ListViewTable
                                    data={data}
                                    columns={columns}
                                    isLoading={isLoading}
                                    disableGlobalSearch={false}
                                    rowSelection={null}
                                    setRowSelection={null}
                                    getRowLink={getRowLink}
                                />
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </SimplePage>
    );
}
