import {SimplePage} from "../../elements/Pages";
import React, {useEffect, useState} from "react";

import {getAbsenceNoticeReasonsAbsencesOnly} from "../../../api/logbook";

import {useQuery} from "@tanstack/react-query";
import ListViewTable, {TableLink} from "../../elements/tables/ListViewTable";
import {getAbsencesReport, getAbsencesReportExcel} from "../../../api/reports";
import {DownloadButton} from "../../elements/Buttons";
import {useSchoolYears} from "../../../contexts/SchoolYearContext";
import SchoolYearFilter from "../../elements/SchoolYearFilter";
import {getCancelledProjectsReport, getCancelledProjectsReportExcel} from "../../../api/projects";
import {DocumentCheckIcon} from "@heroicons/react/20/solid";


export default function ProjectsCancelledReport() {

    // const {schoolYears, currentSchoolYear} = useSchoolYears();
    // const [schoolYearId, setSchoolYearId] = useState();

    const {isLoading, isError, isSuccess, data, error, refetch} = useQuery(
        ["cancelled_projects"],
        getCancelledProjectsReport,
        {refetchInterval: 1000 * 60 * 60}
    )

    // const {data: reasons} = useQuery(
    //     ["cancelled_projects"],
    //     () => getCancelledProjectsReport(),
    // )

    // useEffect(() => {
    //     if (currentSchoolYear) setSchoolYearId(currentSchoolYear["id"]);
    // }, [currentSchoolYear]);

    let columns = [
        {
            accessorKey: "project.name",
            id: "project",
            header: "Projekt",
            enableColumnFilter: false,
        },
        {
            accessorKey: "project.get_project_times_str",
            id: "get_project_times_str",
            header: "Zeit",
            enableColumnFilter: false,
        },
        {
            accessorKey: "date",
            id: "date",
            header: "Datum",
            columnType: "date",
            enableColumnFilter: false,
        },
        {
            accessorKey: "project.project_leaders",
            id: "project_leaders",
            header: "Projektleiter",
            columnType: "m2m",
            labelAttr: "full_name",
            enableColumnFilter: true,
            headerClassNames: "medium-filter",
        },
        {
            accessorKey: "cancel_reason",
            id: "cancel_reason",
            header: "Grund",
            enableColumnFilter: false
        },
        {
            accessorKey: "id",
            id: "attendance_link",
            cell: (info) => (

                <TableLink
                    label="Anwesenheit"
                    to={`/projects/${info.getValue()}/attendance`}
                />
            ),
            header: "",
            enableColumnFilter: false,
            disableRowLink: true,
        },
        // {
        //     accessorKey: "student.house",
        //     id: "house",
        //     header: "Mentorat",
        //     enableColumnFilter: true,
        //     headerClassNames: "narrow-filter",
        // },
        // {
        //     accessorKey: "student.school_class",
        //     id: "school_class",
        //     header: "Klasse",
        //     headerClassNames: "narrow-filter",
        // },
        // {
        //     accessorKey: "absence_count",
        //     id: "absence_count",
        //     header: "Fehltage",
        //     enableColumnFilter: false
        // }
    ]

    // if (!reasons) return <></>
    //
    // // add columns dynamically
    // for (var i = 0; i < reasons.length; i++) {
    //     // console.log(reasons[i])
    //     columns.push({
    //         accessorKey: "reasons." + reasons[i].name,
    //         id: reasons[i].id,
    //         header: reasons[i].name,
    //         enableColumnFilter: false
    //     })
    // }

    // function getRowLink(row) {
    //     return `/logbook/absence-notices/students/${row.original.student.id}`;
    // }

    // add more static columns to the end if required
    // columns.push({
    //
    // })

    return (
        <SimplePage>
            {/*  Simple striped table */}
            <div className="px-4 sm:px-6 lg:px-8">
                <div className="sm:flex sm:items-center">
                    <div className="sm:flex-auto">
                        <h3 className="text-lg leading-6 font-medium text-gray-900">
                            Ausgefallene Projekte
                        </h3>
                    </div>
                    <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
                        <DownloadButton
                            apiFunction={() => getCancelledProjectsReportExcel()}
                            fileName="Ausgefallene Projekte.xls"
                            label="Excel herunterladen"/>

                    </div>
                </div>
                <div className="flex flex-col">
                    <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">

                        <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                            {isSuccess && data && (
                                <ListViewTable
                                    data={data}
                                    columns={columns}
                                    isLoading={isLoading}
                                    disableGlobalSearch={false}
                                    getRowLink={null}
                                />
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </SimplePage>
    );
}
