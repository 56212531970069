import http from "./../services/httpService";
import {genericApi} from "./generic";
import httpServiceDownloads from "../services/httpServiceDownloads";



export async function getStaffList() {
    const resp = await http.get("/staff/");
    return resp.data;
}
export async function getStaffInfo(staffId) {
    const resp = await http.get("/staff/" + staffId);
    return resp.data;
}

export async function getTeacherList() {
    const resp = await http.get("/staff/teachers/");
    return resp.data;
}

export async function getTeacherAndMentorList() {
    const resp = await http.get("/staff/teachers_and_mentors/");
    return resp.data;
}

export async function getMentorList(assessmentPeriodId) {
    const resp = await http.get(`/staff/mentors/?assessment_period_id=${assessmentPeriodId}`);
    return resp.data;
}

// Staff exports
export async function getStaffExport() {
    const resp = await httpServiceDownloads.get('/staff/export/')
    return resp.data
}
export async function getStaffExportComplete() {
    const resp = await httpServiceDownloads.get('/staff/export_complete/')
    return resp.data
}

// Flextime
export async function getFlextimeCategories() {
    const resp = await http.get('/flextime_categories/')
    return resp.data
}

export async function getFlextimeStaffList() {
    const resp = await http.get('/flextime_staff/')
    return resp.data
}

export async function getFlextimeExcel() {
    const resp = await httpServiceDownloads.get('/flextime_staff/excel/')
    return resp.data
}

export async function getFlextimeBalance(staffId) {
    const resp = await http.get(`/flextime_staff/${staffId}/balance/`)
    return resp.data
}

export async function getFlextimeEntries(staffId, openOnly) {
    const resp = await http.get(`/flextime_entries/` + (staffId ? `?staff_id=${staffId}` : '')
        + (openOnly ? `?open_only=1` : ''))
    return resp.data
}

export async function getFlextimeEntriesPDF(staffId) {
    const resp = await http.get(`/flextime_staff/${staffId}/pdf/`)
    return resp.data
}

export async function getFlextimeEntry(id) {
    const resp = await http.get(`/flextime_entries/${id}/`)
    return resp.data
}

export async function postFlextimeEntry(data) {
    const resp = await http.post('/flextime_entries/', data)
    return resp.data
}

export async function patchFlextimeEntry(data) {
    const resp = await http.patch(`/flextime_entries/${data.id}/`, data)
    return resp.data
}

export async function deleteFlextimeEntry(id) {
    const resp = await http.delete(`/flextime_entries/${id}/`)
    return resp.data
}

export async function approveFlextimeEntry(id) {
    const resp = await http.post(`/flextime_entries/${id}/approve/`)
    return resp.data
}

export async function rejectFlextimeEntry(id) {
    const resp = await http.post(`/flextime_entries/${id}/reject/`)
    return resp.data
}

export async function alumniStaffApi(method, id, data) {
    return genericApi('/alumni_staff/', method, id, data)
}

export async function getMyAlumnusStaff() {
    const resp = await http.get('/alumni_staff/my/')
    return resp.data
}

export async function addImageToAlumniStaff(formData, id) {
    const resp = await http.patch(`/alumni_staff/${id}/add_file/`, formData)
    return resp.data
}