import {DetailPage} from "../../elements/Pages";
import {Fragment, React} from 'react'

import {useQuery} from '@tanstack/react-query'
import {useAuth} from "../../../contexts/AuthContext";
import checkPermission from "../../../utils/permissions";
import {Link, useParams} from "react-router-dom";
import {DownloadButton, EditButton} from "../../elements/Buttons";
import {facharbeitApi, getFacharbeitPDF} from "../../../api/school";
import {useUserInfo} from "../../../contexts/UserInfoContext";


export default function FacharbeitDetail() {

    const {id} = useParams()
    const {renderUserImage} = useUserInfo();
    const {permissions} = useAuth()
    const { isLoading, isError, isSuccess, data } = useQuery(['facharbeit', id], () => facharbeitApi('get', id), {})
    const canEditSpecialGrade = checkPermission(permissions, 'grades.change_specialgrade')

    if (!data) return <></>
    return (

        <DetailPage>

            {/* Page header */}
            <div className="max-w-3xl mx-auto px-4 sm:px-6 md:flex md:items-center md:justify-between md:space-x-5 lg:max-w-7xl lg:px-8">
                <div className="flex items-center space-x-5">
                    <div className="flex-shrink-0">
                        <div className="relative">
                            {renderUserImage(data.student.user_id, 80)}
                            <span className="absolute inset-0 shadow-inner rounded-full" aria-hidden="true"/>
                        </div>
                    </div>
                    <div>
                        <h1 className="text-2xl font-bold text-gray-900">Facharbeit {data.course.school_subject}</h1>
                        <p className="text-sm font-medium text-gray-500">
                            von <Link to={`/school/students/${data.student.id}`} className="text-gray-900" >{data.student.full_name}</Link>
                            , {data.student.student_number}
                        </p>
                    </div>
                </div>
                <div className="mt-6 flex flex-col-reverse justify-stretch space-y-4 space-y-reverse sm:flex-row-reverse sm:justify-end sm:space-x-reverse sm:space-y-0 sm:space-x-3 md:mt-0 md:flex-row md:space-x-3">

                    <DownloadButton
                        label="PDF herunterladen"
                        fileName={`Facharbeit ${data.student.full_name}.pdf`}
                        apiFunction={() => getFacharbeitPDF(data.id)}
                    />

                    {data.can_edit &&
                        <EditButton
                            iconOnly={false}
                            link={`/school/facharbeit/${data.id}/edit`}
                            label="Bearbeiten"
                        />}
                </div>
            </div>

            <div className="mt-8 max-w-3xl mx-auto grid grid-cols-1 gap-6 sm:px-6 lg:max-w-7xl lg:grid-flow-col-dense lg:grid-cols-3">
                <div className="space-y-6 lg:col-start-1 lg:col-span-2">

                    <section aria-labelledby="applicant-information-title">
                        <div className="bg-white shadow overflow-hidden sm:rounded-lg">
                            <div className="px-4 py-5 sm:px-6">
                                <h3 className="text-lg leading-6 font-medium text-gray-900">{data.topic}</h3>
                                {/*<p className="mt-1 max-w-2xl text-sm text-gray-500">Personal details and*/}
                                {/*    application.</p>*/}
                            </div>
                            <div className="border-t border-gray-200 px-4 py-5 sm:p-0">
                                <dl className="sm:divide-y sm:divide-gray-200">
                                    <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                        <dt className="text-sm font-medium text-gray-500">Kurs</dt>
                                        <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                            <Link className="text-imsblue-600"
                                                  to={`/courses/${data.course.id}`}>{data.course.course_number}</Link> {data.course.school_subject}
                                        </dd>
                                    </div>
                                    <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                        <dt className="text-sm font-medium text-gray-500">Kurslehrer</dt>
                                        <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{data.course.teacher.full_name}
                                        </dd>
                                    </div>
                                    <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                        <dt className="text-sm font-medium text-gray-500">Betreuender Lehrer</dt>
                                        <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{data.supervisor.full_name}</dd>
                                    </div>
                                    <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                        <dt className="text-sm font-medium text-gray-500">Note</dt>
                                        <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2 whitespace-pre-wrap">
                                            {data.mark && <>{canEditSpecialGrade ?
                                                <Link className="text-imsblue-600"
                                                      to={`/grades/special-grades/${data.mark.id}/edit`}>{data.mark.mark}</Link>
                                            : <>{data.mark.mark}</>}
                                            </>}
                                        </dd>
                                    </div>
                                </dl>
                            </div>
                        </div>
                    </section>

                </div>

            </div>

        </DetailPage>
    )
}
