import {React} from "react";
import {findDevice} from "../../../api/organizational";
import {SecondaryButton} from "../../elements/Buttons";
import {CheckCircleIcon} from "@heroicons/react/20/solid";


export function FindDevice({
                             data,
                             setAvailableDevices,
                             errors,
                             setErrors
                         }) {

    async function handleFindDevice() {
        // validate that start and end time are selected
        if (!data.start || !data.end) {
            const newErrors = {
                ...errors,
                start: "Bitte Start- und Endzeit auswählen, um verfügbare Geräte zu finden."
            }
            setErrors(newErrors)
            return
        }

        // make request and display available devices or filter the devices options
        const findDeviceData = {
            start: data.start,
            end: data.end,
            rule_id: data.rule?.id || null,
            end_recurring_period: data.end_recurring_period || null,
            number_of_items: data.number_of_items || null
        }
        // console.log(findDeviceData)
        const availableDevices = await findDevice(findDeviceData)
        // setDevices(availableDevices)
        setAvailableDevices(availableDevices) // set to a separate state variable to display and so other devices can still be selected
    }

    return <SecondaryButton onClick={handleFindDevice} label="Gerät finden"/>
}

export function AvailableDevices({availableDevices, onDeviceSelect, msg}) {

    return <>
        <h3 className="text-lg leading-6 font-medium text-gray-900">
            Verfügbare Geräte
        </h3>

        <p className="mt-1 text-gray-500 py-2">
            {availableDevices.length > 0 ? "Folgende Geräte sind verfügbar für den ausgewählten Zeitraum:"
                : (msg ? msg : "Links Start- und Endzeit auswählen, um verfügbare Geräte zu finden.")}
        </p>
        <ul role="list" className="divide-y space-y-1">
            {availableDevices.map((device, index) => (
                <li key={index} className="flex py-2">
                    <div className="text-sm font-medium text-gray-900">
                        {device.name}

                        <a href="#" onClick={() => onDeviceSelect(device)}>
                            <CheckCircleIcon
                                className="h-5 w-5 text-green-700 inline-flex ml-2 align-middle"
                            /> Auswählen</a>
                    </div>
                </li>
            ))}
        </ul>
    </>
}