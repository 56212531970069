import React, {useEffect, useState} from "react";
import CheckboxTree from "react-checkbox-tree-duplicate-value";
import "react-checkbox-tree-duplicate-value/lib/react-checkbox-tree.css";
import Accordion from "../../elements/accordion";
import {ColumnWithImage} from "../../elements/tables/ListViewTable";
import DebouncedInput from "../../elements/forms/DebouncedInput";
import BasicModal from "../../elements/modal";
import {PrimaryButton} from "../../elements/Buttons";
import {LazyLoadImage} from "react-lazy-load-image-component";

const StudentSelector = ({
                             open,
                             setOpen,
                             allStudents,
                             selectFromAllStudents,
                             setData,
                             data,
                             initialData,
                             path,
                             filters = {},
                             modalTitle,
                             parentLabelAttr,
                             onSaveAndClose
                         }) => {

    // todo only use data for checked instead of having this piece of state twice
    // const [students, setStudents] = useState({
    //     checked: [], // data[path].map(i => i) todo
    //     expanded: [],
    // });
    const [expanded, setExpanded] = useState([])
    const [renderFilters, setRenderFilters] = useState([]);
    const [searchKeyWords, setSearchKeyWords] = useState({});
    const [searchName, setSearchName] = useState(null);
    const [nodes, setNodes] = useState({});

    // // infinite effect loop
    // useEffect(() => {
    //     console.log('opening', data)
    //     console.log({...students,
    //       checked: initialData[path].map(i => i)})
    //     setStudents({...students,
    //       checked: data[path].map(i => i)})
    // }, [initialData]);

    // useEffect(() => {
    //   if (students.checked && allStudents) {
    //     setData({
    //       ...data,
    //       [path]: allStudents
    //         .filter((i) => data.includes(i.id))
    //         .map((i) => i.id),
    //     });
    //   }
    // }, [data, allStudents]);
    useEffect(() => {
        if (renderFilters) {
            let newNodes = {};
            renderFilters.forEach((filter) => {
                const nodes = filters[filter]
                    .map((i) => {
                        return {
                            value: i[parentLabelAttr[filter]]
                                ? i[parentLabelAttr[filter]]
                                : "",
                            label: i[parentLabelAttr[filter]]
                                ? i[parentLabelAttr[filter]]
                                : "",
                            children: i.students.map((j) => {
                                return {
                                    value: j.id,
                                    label: j.full_name,
                                };
                            }),
                        };
                    })
                    .filter((i) =>
                        i.label
                            ?.toLowerCase()
                            .includes(searchKeyWords[filter]?.toLowerCase())
                    );
                newNodes = {
                    ...newNodes,
                    [filter]: nodes,
                };
            });
            setNodes(newNodes);
        }
    }, [renderFilters, searchKeyWords]);
    useEffect(() => {
        if (filters) {
            const newRenderFilters = Object.keys(filters);
            setSearchKeyWords(
                newRenderFilters.reduce((o, key) => ({...o, [key]: ""}), {})
            );
            setRenderFilters(newRenderFilters);
        }
    }, [filters]);

    return (
        <BasicModal open={open} setOpen={setOpen}>
            <div className="w-full mb-3 text-center">
                <h3 className="text-xl font-semibold text-gray-900">{modalTitle}</h3>
            </div>

            <ul role="list" className="grid grid-cols-1 gap-6 sm:grid-cols-2">
                <li
                    className="col-span-1 divide-y divide-gray-200 rounded-lg bg-white"
                    style={{
                        height: "400px",
                        overflowY: "auto",
                    }}
                >
                    <div className="flex w-full items-center justify-between space-y-3 p-2 flex-col">

                        {selectFromAllStudents && allStudents &&
                            <Accordion
                                title="Alle SchülerInnen"
                                id={`student-selector`}
                            >
                                <DebouncedInput
                                    value={searchName}
                                    onChange={(e) => {setSearchName(e)}}
                                    placeholder="Suche nach Name..."
                                />
                                {allStudents.filter(s => s.full_name.search(new RegExp(searchName, "i")) > -1 || !searchName).map((student, index) => (
                                        <div className="">
                                            <div className="flex items-center">
                                                <input
                                                    id={path}
                                                    name={'all_students' + student.id}
                                                    value={student.id}
                                                    checked={data[path] && data[path].find(e => e === student.id) > -1}
                                                    type="checkbox"
                                                    className="focus:ring-imsblue-500 h-4 w-4 text-imsblue-600 border-gray-300"
                                                    onChange={(evt) => setData({...data, [path]: [...data[path], student.id]} )}
                                                />
                                                <label
                                                    htmlFor={'all_students' + student.id}
                                                    className="ml-3 block text-sm font-medium text-gray-700"
                                                    onClick={() => setData({...data, [path]: [...data[path], student.id]} )}
                                                >
                                                    {student.full_name}
                                                </label>
                                            </div>
                                        </div>
                                ))
                                }
                            </Accordion>
                        }

                        {renderFilters.map((filter, index) => (
                            <Accordion
                                title={
                                    (() => {
                                        switch (filter) {
                                            case 'school_classes':
                                                return 'Klassen'
                                                break;
                                            case 'grades':
                                                return 'Klassenstufen'
                                                break;
                                            case 'houses':
                                                return 'Mentorate'
                                                break;
                                            case 'courses':
                                                return 'Kurse'
                                                break;
                                            default:
                                                return filter
                                            // code block
                                        }
                                    })()
                                }
                                id={`${filter}-selector`}
                                key={index}
                            >
                                <DebouncedInput
                                    value={searchKeyWords[filter]}
                                    onChange={(e) => {
                                        setSearchKeyWords({
                                            ...searchKeyWords,
                                            [filter]: e,
                                        });
                                    }}
                                    placeholder="Suche nach Gruppenname..."
                                />
                                <CheckboxTree
                                    icons={{
                                        halfCheck: (
                                            <LazyLoadImage
                                                style={{width: 16, verticalAlign: "text-top"}}
                                                src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAYAAABXAvmHAAAABmJLR0QA/wD/AP+gvaeTAAABOklEQVRoge2ZTU4CQRCFP4iZnVcAEq9hXPC3duvFBE7g0gsRyQTDGnU7BBcNsZwwo1M902VifUknveiueq+BpPIAx3Gcv84IWAFboACOHa/i1GsJDGPFz4H3BKKr1hsw1YofGYuXJgYaAytR5AUYA5mmUEMyYAJsRP+FptBWFBi3pa4BE9E/1xSQP9gUL18mE/2LqkO9mgLHX57rkh819BMJ6Qw3YI0bsMYNWOMGrLlS3rsB7iPulymAZ8LQ2BpypC2zJm5EvrTWDTUA//grNAcegOuWdHwAT5qLPo1a4wascQPWuAFr3IA1dQYOYm8VbJ05VB2qM7AT+9toOc25E/tXTYElX6PshhBzpwp3Z3wPdx81hYaEaNs6Xt+jjNchvLqliT0hpY5iQPgIc9L9xZSfeqpf3nEcJw2fdgIDZjHBi8IAAAAASUVORK5CYII="
                                            />
                                        ),
                                    }}
                                    key={index}
                                    nodes={nodes[filter] || []}
                                    checked={data[path]}
                                    expanded={expanded}
                                    onCheck={(checked) => setData({...data, [path]: checked.map(i => parseInt(i))})}
                                    onExpand={(ex) =>
                                        setExpanded(ex)
                                    }
                                    showNodeIcon={false}
                                />
                            </Accordion>
                        ))}
                    </div>
                </li>
                <li
                    className="col-span-1 divide-y divide-gray-200 rounded-lg bg-white flex flex-col"
                    style={{
                        height: "400px",
                        overflowY: "auto",
                    }}
                >
                    {allStudents
                        ?.filter((i) => data[path]?.includes(i.id))?.map((student) => (
                            <div
                                key={student.id}
                                className={
                                    "whitespace-nowrap py-4 pl-4 text-sm text-center font-medium text-gray-900 "
                                    // + (columnIndex > 0 ? '' : ' pl-4')
                                }
                            >
                                <ColumnWithImage
                                    userId={student.user_id}
                                    line1={`${student.last_name}, ${student.first_name}`}
                                    line2={student.student_number}
                                    clearable={true}
                                    clearAction={() => {
                                        setData({
                                                ...data,
                                                [path]: data[path].filter(
                                                    (i) => i !== student.id
                                                )
                                            }
                                        );
                                    }}
                                />
                            </div>
                        ))}
                </li>
            </ul>

            {onSaveAndClose &&
                <div>
                    <PrimaryButton label="Speichern & schließen"
                                   onClick={() => onSaveAndClose()}/>
                </div>}

        </BasicModal>
    );
};

export default StudentSelector;
