import {SimplePage} from "../../elements/Pages";
import React from "react";

import {useQuery} from "@tanstack/react-query";
import ListViewTable, {TableLink} from "../../elements/tables/ListViewTable";
import {getAttendanceStudents, getProjectAttendances, getProjectStudents} from "../../../api/projects";
import {DeleteButton} from "../../elements/DeleteButton";
import {EditButton} from "../../elements/Buttons";
import {getStudierzeitAttendances} from "../../../api/studierzeit";

const columns = [
    {
        accessorFn: (row) => `${row.studierzeit_type?.name}`,
        id: "studierzeit_type",
        header: "Art",
        enableColumnFilter: true,
        headerClassNames: "narrow-filter",
    },
    {
        // accessorKey: "studierzeit_time.name",
        accessorFn: (row) => `${row.studierzeit_time?.name}`,
        id: "studierzeit_time",
        header: "Block",
        enableColumnFilter: true,
        headerClassNames: "narrow-filter",
    },
    {
        // accessorKey: "room.name",
        accessorFn: (row) => row.room ? `${row.room?.name}` : '',
        id: "room",
        header: "Raum",
        enableColumnFilter: true,
        headerClassNames: "narrow-filter",
    },
    {
        accessorKey: "date",
        id: "date",
        header: "Datum",
        columnType: "date",
        enableColumnFilter: false,
        headerClassNames: "narrow-filter",
    },
    {
        accessorKey: "weekday",
        // accessorFn: (row) => `${row.weekday}`,
        id: "weekday",
        header: "Wochentag",
        enableColumnFilter: true,
        headerClassNames: "narrow-filter",
        // filterFn: "containsString",
    },
    {
        // accessorKey: "studierzeit_time.period.name",
        accessorFn: (row) => row.studierzeit_time?.period ? `${row.studierzeit_time.period.name}`: '',
        id: "period",
        header: "Zeit",
        enableColumnFilter: true,
        headerClassNames: "narrow-filter",
    },
    {
        accessorKey: "created_by_full_name",
        id: "created_by_full_name",
        header: "Erfasst von",
        enableColumnFilter: true,
        headerClassNames: "medium-filter",
    },
    {
        accessorKey: "id",
        id: "link",
        cell: ({row, value}) => (
            <EditButton iconOnly={true} link={`/studierzeit/attendance/${row.original.id}/edit`}/>
        ),
        header: "Bearbeiten",
        enableColumnFilter: false,
    }
];

export default function AttendanceList() {

    const {isLoading, isError, isSuccess, data, error, refetch} = useQuery(
        ["studierzeit_attendance"],
        () => getStudierzeitAttendances()
    );

    function getRowLink(row) {
        return `/studierzeit/attendance/${row.original.id}/homework`;
    }

    return (
        <SimplePage>
            <div className="px-4 sm:px-6 lg:px-8">
                <div className="sm:flex sm:items-center">
                    <div className="sm:flex-auto">
                        <h3 className="text-lg leading-6 font-medium text-gray-900">
                            Eingetragene Studierzeitanwesenheit
                        </h3>
                    </div>
                    <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
                    </div>
                </div>
                <div className="flex flex-col">
                    <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
                        <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                            {isSuccess && data && (
                                <ListViewTable
                                    data={data}
                                    columns={columns}
                                    isLoading={isLoading}
                                    disableGlobalSearch={false}
                                    getRowLink={getRowLink}
                                />
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </SimplePage>
    );
}
