import React from "react";
import ListItemLoader from "../../elements/ListItemLoader";
import {useMessenger} from "../../../contexts/MessengerContext";
import {classNames} from "../../../utils/misc";
import {PencilSquareIcon} from "@heroicons/react/24/outline";
import {formatDateTime} from "../../../utils/formatters";
import Pagination from "../../elements/Pagination";
import {useAuth} from "../../../contexts/AuthContext";
import {PaperClipIcon, TrashIcon} from "@heroicons/react/20/solid";

export function MessengerMainSideBar() {

    const {
        selectedConversationId,
        conversationList,
        page,
        setPage,
        totalCount
    } = useMessenger();

    return (
        <aside
            className={classNames(
                selectedConversationId ? "hidden" : "block",
                "w-full lg:w-96 lg:order-first lg:block lg:flex-shrink-0",
            )}
        >
            <div className="relative flex h-full w-full flex-col border-r border-gray-200 bg-gray-100">
                <MessengerMainSideBarHeader/>
                <nav aria-label="Message list" className="min-h-0 flex-1 overflow-y-auto">
                    {conversationList
                        ? <ul role="list" className="divide-y divide-gray-200 border-b border-gray-200">
                            {conversationList?.map(item =>
                                <MessengerMainSideBarItem key={item.parent_message_id} item={item}/>
                            )}
                        </ul>
                        : <MessengerMainSideBarLoading/>
                    }
                </nav>

               <Pagination
                  page={page} setPage={setPage}
                  pageSize={50} totalResults={totalCount()}
                  compact={true}
                />
            </div>


        </aside>
    )
}

function MessengerMainSideBarHeader() {

    const {
        currentTab,
        conversationList,
        handleNewConversation,
        handleEmptyTrash
    } = useMessenger();


    return (
        <div className="flex-shrink-0 border-b border-gray-200">
            <div className="flex h-16 flex-row justify-between items-center bg-white px-6 space-x-4">
                <div className="flex items-baseline space-x-2 overflow-x-hidden truncate">
                    <h2 className="text-lg font-medium text-gray-900">
                        {currentTab}
                    </h2>
                    {currentTab === "Inbox" &&
                        <p className="text-sm font-medium text-gray-500">
                            {conversationList?.length} messages
                        </p>}
                </div>
                {currentTab === "Entwürfe" &&
                    <button
                        type="button"
                        className="relative -ml-px hidden items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-900 hover:bg-gray-50 focus:z-10 focus:border-imsblue-600 focus:outline-none focus:ring-1 focus:ring-imsblue-600 sm:inline-flex"
                        onClick={handleNewConversation}
                    >
                        <PencilSquareIcon
                            className="mr-2.5 h-5 w-5 text-gray-400"
                            aria-hidden="true"
                        />
                        <span>Neue Nachricht</span>
                    </button>}
                {currentTab === "Papierkorb" &&
                    <button
                        type="button"
                        className="relative -ml-px hidden items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-900 hover:bg-gray-50 focus:z-10 focus:border-imsblue-600 focus:outline-none focus:ring-1 focus:ring-imsblue-600 sm:inline-flex"
                        onClick={handleEmptyTrash}
                    >
                        <TrashIcon
                            className="mr-2.5 h-5 w-5 text-gray-400"
                            aria-hidden="true"
                        />
                        <span>Papierkorb leeren</span>
                    </button>}
            </div>
        </div>
    )
}

function MessengerMainSideBarItem({item}) {

    const {
        selectedConversationId,
        changeSelectedConversationId,
        currentTab,
    } = useMessenger();

    const {currentUser} = useAuth();

    return (
        <li
            className={classNames(
                item.parent_message_id === selectedConversationId
                    ? "bg-gray-100"
                    : "bg-white",
                "relative py-5 px-6 focus-within:ring-2 focus-within:ring-inset focus-within:ring-imsblue-600 hover:bg-gray-50"
            )}
            onClick={() => {
                item.parent_message_id !== selectedConversationId && changeSelectedConversationId(item, true)
            }}
        >
            <div className="flex justify-between space-x-3">
                <div className="min-w-0 flex-1">
                    <div className="flex justify-between space-x-3 items-center">
                        <div className="">
                            <p className="truncate text-sm whitespace-pre-wrap">
                                {item.sent_at && formatDateTime(item.sent_at)}
                            </p>
                            <p className="truncate text-sm font-medium text-gray-900 whitespace-pre-wrap">
                                {item.subject}
                            </p>
                            <p className="truncate text-sm font-normal text-gray-700 whitespace-pre-wrap">
                                {currentTab === "Gesendet" ? <>an {item.recipient?.full_name}</> : <>von {item.sender?.full_name}</>}
                            </p>
                        </div>

                        <div className="flex items-end justify-end">
                            <p className="truncate text-sm whitespace-pre-wrap mr-2 mb-1">
                                {item.attachments.length > 0 && <PaperClipIcon className="text-right min-h-[1rem] min-w-[1rem] max-h-[1rem] max-w-[1rem]"
                                               aria-hidden="true"/>}
                            </p>
                            <p className="truncate text-sm font-medium text-gray-900 whitespace-pre-wrap">
                                {!item.read_at && item.recipient?.id == currentUser.user_id &&
                                    <span
                                        className="bg-green-100 text-green-800 inline-flex items-center rounded-full px-3 h-6 text-sm font-medium">
                                Neu
                            </span>}
                            </p>
                        </div>

                    </div>
                </div>
            </div>
        </li>
    )
}

function MessengerMainSideBarLoading() {
    return (
        <ul
            role="list"
            className="divide-y divide-gray-200 border-b border-gray-200"
        >
            {[...Array(6)].map((item, index) => (
                <li key={index}>
                    <ListItemLoader profileImage={false}/>
                </li>
            ))}
        </ul>
    )
}