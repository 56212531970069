import {useMessenger} from "../../../contexts/MessengerContext";
import {CheckCircleIcon} from "@heroicons/react/20/solid";
import {FileAttachments} from "./FileList";
import React, {useLayoutEffect, useRef} from "react";
import {formatDateTime} from "../../../utils/formatters";
import {useAuth} from "../../../contexts/AuthContext";

export function ConversationDetailBody() {
  const {
    selectedConversationData,
    replyOn,
    filesToSend
  } = useMessenger();

  const h2ref = useRef(null);

  useLayoutEffect(() => {
    h2ref.current.scrollIntoView();
  }, [selectedConversationData, replyOn, filesToSend]);

  if (!selectedConversationData) return null

  return (
    <div className="min-h-0 flex-1 overflow-y-auto mt-0.5">
      <ul role="list" className="space-y-2 py-2 sm:space-y-4 sm:px-6 md:px-8">
        {selectedConversationData.map((item) =>
          <ConversationDetailBodyThreadItem
            key={item.id}
            item={item}
          />
        )}
        <div ref={h2ref}>{}</div>
      </ul>
    </div>
  )
}

function ConversationDetailBodyThreadItem({item}) {
  const {
      deleteDraft,
      startEditDraft
  } = useMessenger();

  const {currentUser} = useAuth()

  return (
    <li
      className={"bg-white px-4 py-6 shadow sm:rounded-lg sm:px-6 w-5/6 " + (item.sender_id === currentUser.user_id ? "ml-auto" : "mr-auto") }
    >
      <div className="flex justify-between w-full">
        <h3 className="text-base font-medium">
          <span className="text-gray-900">
            {item.sender.full_name}
          </span>
          {" "}
          <span className="text-gray-600">schrieb:</span>
        </h3>
        {item.sender_id === currentUser.user_id && item.sender_status === "draft" && <div>
            <button
              className="mr-5 inline-flex items-center rounded-full bg-red-100 px-2.5 py-0.5 text-xs font-medium text-red-800"
              onClick={() => deleteDraft(item.id)}
            >
              Löschen
            </button>
            <button
              className="inline-flex items-center rounded-full bg-imsblue-100 px-2.5 py-0.5 text-xs font-medium text-imsblue-800"
              onClick={() => startEditDraft(item)}
            >
              Entwurf bearbeiten
            </button>
          </div>}

        <p className="mt-1 whitespace-nowrap text-sm text-gray-600 inline-flex">
          {item.sent_at ? <time dateTime={item.sent_at}>{formatDateTime(item.sent_at)}</time> :  <time dateTime={item.last_update_date}>{formatDateTime(item.last_update_date)}</time>}

          {item.sent_at && !item.read_at && <CheckCircleIcon
              height={20}
              width={20}
              title={"Gesendet"}
              className="text-gray-600 ml-2"
          />}

          {item.read_at && <CheckCircleIcon
              height={20}
              width={20}
              title={"Gelesen"}
              className="text-blue-500 ml-2"
          />}
        </p>

      </div>

      <div className="my-2 space-y-6 text-sm text-gray-800 whitespace-pre-wrap">
        {item.message}
      </div>

      <FileAttachments fileList={item.attachments} isDownload/>
    </li>
  )
}
