import {SimplePage} from "../../elements/Pages";
import React from "react";

import {useQuery} from "@tanstack/react-query";
import ListViewTable from "../../elements/tables/ListViewTable";
import {DownloadButton} from "../../elements/Buttons";
import {getProjectsReport, getProjectsReportExcel} from "../../../api/projects";


export default function ProjectsReport() {

    const {isLoading, isError, isSuccess, data, error, refetch} = useQuery(
        ["projects_report"],
        getProjectsReport,
        {refetchInterval: 1000 * 60 * 60}
    )

    let columns = [
        {
            accessorKey: "name",
            id: "project",
            header: "Projekt",
            enableColumnFilter: false,
        },
        {
            accessorKey: "project_leaders",
            id: "project_leaders",
            header: "Projektleiter",
            columnType: "m2m",
            labelAttr: "full_name",
            enableColumnFilter: false,
            headerClassNames: "medium-filter",
        },
        {
            accessorKey: "num_not_cancelled",
            id: "num_not_cancelled",
            header: "Stattgefunden",
            enableColumnFilter: false
        },
        {
            accessorKey: "num_cancelled",
            id: "num_cancelled",
            header: "Ausgefallen",
            enableColumnFilter: false
        }
    ]

    return (
        <SimplePage>
            {/*  Simple striped table */}
            <div className="px-4 sm:px-6 lg:px-8">
                <div className="sm:flex sm:items-center">
                    <div className="sm:flex-auto">
                        <h3 className="text-lg leading-6 font-medium text-gray-900">
                            Projekttermine
                        </h3>
                    </div>
                    <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
                        <DownloadButton
                            apiFunction={() => getProjectsReportExcel()}
                            fileName="Projekttermine.xls"
                            label="Excel herunterladen"/>

                    </div>
                </div>
                <div className="flex flex-col">
                    <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">

                        <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                            {isSuccess && data && (
                                <ListViewTable
                                    data={data}
                                    columns={columns}
                                    isLoading={isLoading}
                                    disableGlobalSearch={false}
                                    getRowLink={null}
                                />
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </SimplePage>
    );
}
