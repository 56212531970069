import {useParams} from "react-router-dom";
import {React, useEffect, useState} from "react";
import {DownloadButton, PrimaryButton, SecondaryButton} from "../../elements/Buttons";
import checkPermission from "../../../utils/permissions";
import {useAuth} from "../../../contexts/AuthContext";
import {DetailPage} from "../../elements/Pages";
import {formatDate, formatDateTime} from "../../../utils/formatters";
import {toast} from "react-toastify";
import {bookWorkshop, cancelBooking, getWorkshopDetail, getWorkshopPDF} from "../../../api/workshop_day";


export default function WorkshopDetail() {

    const {id} = useParams();
    const {permissions} = useAuth();
    const [data, setData] = useState()

    useEffect(() => {
        loadData();
    }, [id]);

    async function loadData() {
        if (!id) return;
        const newData = await getWorkshopDetail(id);
        setData(newData);
    }

    const canViewAttendance = checkPermission(permissions, "workshop_day.view_booking")

    async function handleBookWorkshop() {
        try {
            await bookWorkshop(id)
            toast.success("Zum Workshop angemeldet.")
            loadData()
        } catch (e) {
            toast.error("Beim Speichern ist ein Fehler aufgetreten.")
        }
    }

    async function handleCancelWorkshop() {
        try {
            await cancelBooking(data.booking.id)
            toast.success("Anmeldung gelöscht.")
            loadData()
        } catch (e) {
            toast.error("Beim Speichern ist ein Fehler aufgetreten.")
        }
    }

    if (!data) return <></>;
    return (

        <>
            <DetailPage>
                <div
                    className="max-w-3xl mx-auto px-4 sm:px-6 md:flex md:items-center md:justify-between md:space-x-5 lg:max-w-7xl lg:px-8">
                    <div className="flex items-center space-x-5">
                        <div>
                            <h1 className="text-2xl font-bold text-gray-900">{data.workshop.title}</h1>
                        </div>
                    </div>
                    <div
                        className="mt-6 flex flex-col-reverse justify-stretch space-y-4 space-y-reverse sm:flex-row-reverse sm:justify-end sm:space-x-reverse sm:space-y-0 sm:space-x-3 md:mt-0 md:flex-row md:space-x-3">
                    </div>
                </div>

                <div
                    className="mt-8 max-w-3xl mx-auto grid grid-cols-1 gap-6 sm:px-6 lg:max-w-7xl lg:grid-flow-col-dense lg:grid-cols-3">
                    <div className="space-y-6 lg:col-start-1 lg:col-span-2">

                        <section aria-labelledby="applicant-information-title">
                            <div className="bg-white shadow overflow-hidden sm:rounded-lg">

                                <div className="border-t border-gray-200 px-4 py-5 sm:p-0">
                                    <dl className="sm:divide-y sm:divide-gray-200">
                                        <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                            <dt className="text-sm font-medium text-gray-500">Datum</dt>
                                            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                                {formatDateTime(data.workshop.datetime_from)} - {formatDate(data.workshop.datetime_to)}
                                            </dd>
                                        </div>
                                        <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                            <dt className="text-sm font-medium text-gray-500">Art</dt>
                                            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{data.workshop.category?.title}
                                            </dd>
                                        </div>
                                        <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                            <dt className="text-sm font-medium text-gray-500">Kategorien</dt>
                                            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                                {data.workshop.topic_categories?.map(c => c.title).join(', ')}
                                            </dd>
                                        </div>
                                        <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                            <dt className="text-sm font-medium text-gray-500">Referent</dt>
                                            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{data.workshop.operator}
                                            </dd>
                                        </div>
                                        <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                            <dt className="text-sm font-medium text-gray-500">Betreuung durch</dt>
                                            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{data.workshop.co_operator}
                                            </dd>
                                        </div>
                                        <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                            <dt className="text-sm font-medium text-gray-500">Maximale Teilnehmerzahl
                                            </dt>
                                            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{data.workshop.max_participants}
                                            </dd>
                                        </div>
                                        <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                            <dt className="text-sm font-medium text-gray-500">Klassenstufen</dt>
                                            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                                {data.workshop.grades?.map(c => c.name).join(', ')}
                                            </dd>
                                        </div>


                                        <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                            <dt className="text-sm font-medium text-gray-500">Beschreibung</dt>
                                            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2 whitespace-pre-wrap">{data.workshop.description}
                                            </dd>
                                        </div>

                                    </dl>
                                </div>
                            </div>
                        </section>

                    </div>

                    <div className="space-y-6 lg:col-span-1">

                        <div className="bg-white shadow sm:rounded-lg sm:overflow-hidden">
                            <div className="divide-y divide-gray-200">
                                <div className="px-4 py-5 sm:px-6 flex justify-between">
                                    <h2 id="notes-title" className="text-lg font-medium text-gray-900">
                                        Meine Buchung
                                    </h2>
                                </div>
                                <div className="border-t border-gray-200 px-4 py-5">
                                    {data.can_book &&
                                        <PrimaryButton
                                            onClick={() => handleBookWorkshop()}
                                            label="Anmelden"/>}

                                    {data.can_cancel &&
                                        <SecondaryButton
                                            onClick={() => handleCancelWorkshop()}
                                            label="Anmeldung absagen"/>}
                                </div>
                            </div>
                        </div>

                        {canViewAttendance && <div className="bg-white shadow sm:rounded-lg sm:overflow-hidden">
                            <div className="divide-y divide-gray-200">
                                <div className="px-4 py-5 sm:px-6 flex justify-between">
                                    <h2 id="notes-title" className="text-lg font-medium text-gray-900">
                                        Teilnehmer
                                    </h2>
                                    <DownloadButton
                                        apiFunction={() => getWorkshopPDF(id)}
                                        fileName={`Teilnehmerliste ${data.workshop.title}.pdf`}
                                        label="Teilnehmerliste"/>
                                </div>
                                <div className="border-t border-gray-200 px-4 py-5">
                                    <ul role="list" className="divide-y divide-gray-200">
                                        {data.attendees.map((user, index) => (
                                            <li key={index} className="sm:px-6 flex py-4">
                                                {user.full_name}
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            </div>
                        </div>}

                    </div>

                </div>

                <div
                    className="mt-8 max-w-3xl mx-auto grid grid-cols-1 gap-6 sm:px-6 lg:max-w-7xl lg:grid-flow-col-dense lg:grid-cols-3">

                </div>

            </DetailPage>
        </>
    );
}
