import { SimplePage } from "../../elements/Pages";
import React from "react";

import { getPenalties, penaltiesApi } from "../../../api/logbook";

import { useQuery } from "@tanstack/react-query";
import { useAuth } from "../../../contexts/AuthContext";
import ListViewTable, {
  TableEditLink,
  TableLink,
  TwoLineColumn,
} from "../../elements/tables/ListViewTable";
import checkPermission from "../../../utils/permissions";
import moment from "moment";
import { Link } from "react-router-dom";
import { useParams } from "react-router-dom";
import {useState} from "react";
import {classNames} from "../../../utils/misc";


export default function Penalties() {
  const { penaltyName } = useParams();
  const { permissions } = useAuth();

  const [uncompensatedOrAll, setUncompensatedOrAll] = useState("uncompensated");

  const { isLoading, isSuccess, data } = useQuery(
    ["penalties", uncompensatedOrAll, penaltyName],
    () => getPenalties(uncompensatedOrAll, penaltyName)
  )

  const canEdit = checkPermission(permissions, "student_logbook.change_penalty")

  const columns = [
    {
      accessorFn: (row) =>
          `${row.student.last_name}, ${row.student.first_name} ${row.student.student_number}`,
      id: "student_search",
      header: "SchülerIn",
      hide: true,
      enableColumnFilter: false,
    },
    {
      accessorKey: "student",
      id: "student",
      header: "SchülerIn",
      columnType: "student",
      enableColumnFilter: false,
      sortingFn: "lastNameSorting",
    },
    {
      accessorKey: "student.house",
      id: "house",
      header: "Mentorat",
      enableColumnFilter: true,
      headerClassNames: "narrow-filter",
    },
    {
      accessorKey: "student.school_class",
      id: "school_class",
      header: "Klasse",
      headerClassNames: "narrow-filter",
    },
    {
      accessorKey: "penalty.name",
      id: "penalty",
      header: "Strafe",
      headerClassNames: "medium-filter",
      enableColumnFilter: true,
      // filterFn: "containsString",
    },
    {
      accessorKey: "date_from",
      id: "date_from",
      header: "Von",
      columnType: "date",
      enableColumnFilter: false,
      classNames: "text-gray-500",
    },
    {
      accessorKey: "date_to",
      id: "date_to",
      header: "Bis",
      columnType: "date",
      enableColumnFilter: false,
      classNames: "text-gray-500",
    },
    {
      accessorKey: "compensated",
      id: "compensated",
      header: "Abgeleistet",
      columnType: "yesno",
      enableColumnFilter: false,
      classNames: "text-gray-500",
      headerClassNames: "narrow-filter",
    },
    {
      accessorKey: "remarks",
      id: "remarks",
      header: "Anmerkungen",
      cell: (info) => info.getValue(),
      enableColumnFilter: false,
      classNames: "max-w-md text-gray-500",
      headerClassNames: "max-w-md",
    },

    {
      accessorKey: "responsible_staff",
      id: "responsible_staff",
      header: "Verantwortlich",
      columnType: "m2m",
      labelAttr: "full_name",
      headerClassNames: "medium-filter",
      filterFn: "containsString",
    },
    {
      accessorKey: "responsible_creators",
      id: "responsible_creators",
      header: "Aufgaben",
      columnType: "m2m",
      labelAttr: "full_name",
      headerClassNames: "medium-filter",
      filterFn: "containsString",
    },
    {
      id: "creation_date",
      header: "Erstellt von",
      cell: ({ row, value }) => (
          <TwoLineColumn
              line1={`${row.original.created_by_full_name}`}
              line2={"am " + moment(value).format("DD.MM.YYYY")}
          />
      ),
      enableColumnFilter: false,
      classNames: "text-gray-500",
    },
    ... canEdit ? [{
      accessorKey: "id",
      id: "link",
      cell: (info) => (
          <TableEditLink
              label="Bearbeiten"
              iconOnly={true}
              to={`/logbook/penalties/${info.getValue()}/edit`}
          />
      ),
      header: "Bearbeiten",
      enableColumnFilter: false,
      disableRowLink: true
    }] : []
  ]

  return (
    <SimplePage>
      <div className="px-4 sm:px-6 lg:px-8">
        <div className="sm:flex sm:items-center">
          <div className="sm:flex-auto">
            <h3 className="text-lg leading-6 font-medium text-gray-900">
              Strafen
            </h3>
          </div>
          <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
            {checkPermission(permissions, "student_logbook.add_penalty") && (
              <Link to="/logbook/penalties/new">
                <button
                  type="button"
                  className="inline-flex items-center justify-center rounded-md border border-transparent bg-imsblue-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-imsblue-700 focus:outline-none focus:ring-2 focus:ring-imsblue-500 focus:ring-offset-2 sm:w-auto"
                >
                  Strafe eintragen
                </button>
              </Link>
            )}
          </div>
        </div>
        <div className="flex flex-col">
          <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">

            <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
              <nav className="flex space-x-4" aria-label="Tabs">
                <a
                    key="uncompensated"
                    onClick={() => setUncompensatedOrAll("uncompensated")}
                    className={classNames(
                        uncompensatedOrAll === "uncompensated"
                            ? "bg-imsblue-100 text-imsblue-700"
                            : "text-gray-500 hover:text-gray-700",
                        "px-3 py-2 font-medium text-sm rounded-md cursor-pointer"
                    )}
                    aria-current={uncompensatedOrAll === "uncompensated" ? "page" : undefined}
                >
                  Offen
                </a>
                <a
                    key="all"
                    onClick={() => setUncompensatedOrAll("all")}
                    className={classNames(
                        uncompensatedOrAll === "all"
                            ? "bg-imsblue-100 text-imsblue-700"
                            : "text-gray-500 hover:text-gray-700",
                        "px-3 py-2 font-medium text-sm rounded-md cursor-pointer"
                    )}
                    aria-current={uncompensatedOrAll === "all" ? "page" : undefined}
                >
                  Alle
                </a>
              </nav>
            </div>

            <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
              {isSuccess && data && (
                <ListViewTable
                  data={data}
                  columns={columns}
                  isLoading={isLoading}
                  disableGlobalSearch={false}
                  getRowLink={null}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </SimplePage>
  );
}
