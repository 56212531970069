import {React, useEffect, useState} from "react";
import Joi from "joi"
import {useQuery} from '@tanstack/react-query'
import {Checkbox, DateInput, Input, Select, TextArea} from "../../elements/forms/Inputs"
import Form, {baseHandleChange, baseHandleDateListChange, baseHandleListChange} from "../../elements/forms/Form"
import {Link, useNavigate, useParams} from "react-router-dom";
import {toast} from "react-toastify";
import {addImageToAlumnus, alumniApi} from "../../../api/alumni";
import {getMaritalStatusOptions} from "../../../api/utils";
import {DeleteButton} from "../../elements/DeleteButton";
import {AddButton} from "../../elements/Buttons";
import {addImageToAlumniStaff, alumniStaffApi} from "../../../api/staff";


export default function AlumniStaffEdit() {

    const {id} = useParams()
    const [data, setData] = useState()

    const [selectedFile, setSelectedFile] = useState();
    const [isFilePicked, setIsFilePicked] = useState(false);

    const [errors, setErrors] = useState({}) // validation errors

    let navigate = useNavigate();

    useEffect(() => {
        loadData()
    }, [id])

    async function loadData() {
        if (!id) return
        const newData = await alumniStaffApi('get', id)
        setData(newData)
    }

    const schema = {
        email: Joi.string().email({tlds: {allow: false}}).allow('', null).label("Email")
    };

    const successMessage = "Profil aktualisiert"

    async function onSave() {

        // saving the instance first
        let newData = await alumniStaffApi('patch', null, data)

        // then adding the file
        if (isFilePicked) {
            const formData = new FormData();
            formData.append('file', selectedFile);
            newData = await addImageToAlumniStaff(formData, newData.id)
        }

        setData(newData)
        toast.success(successMessage)
    }

    function handleChange(evt) {
        baseHandleChange(evt, data, setData, errors, setErrors, schema)
    }

    const handleChangeFile = (event) => {
        setSelectedFile(event.target.files[0])
        setIsFilePicked(true)
    }

    if (!data || !data.can_edit) return <></>

    return (

        <main className="py-10 h-full bg-gray-100">

            <div
                className="max-w-3xl mx-auto px-4 sm:px-6 md:flex md:items-center md:justify-between md:space-x-5 lg:max-w-7xl lg:px-8">
                <div className="flex items-center space-x-5">
                    <div>
                        <h1 className="text-2xl font-bold text-gray-900">
                            Profil bearbeiten
                        </h1>
                    </div>
                </div>
            </div>

            <div
                className="max-w-7xl mx-auto grid grid-cols-1 gap-6 lg:max-w-7xl lg:grid-flow-col-dense lg:grid-cols-1">
                <div className="space-y-6 lg:col-span-4">

                    <Form id="updateAlumniProfile" onSave={onSave}
                          onDelete={null}
                          data={data} setData={setData}
                          errors={errors} setErrors={setErrors}
                          successMessage={successMessage}
                          schema={schema}>

                        <div
                            className="mt-8 max-w-3xl mx-auto grid grid-cols-1 gap-6 sm:px-6 lg:max-w-7xl lg:grid-flow-col-dense lg:grid-cols-3">
                            <div className="space-y-6 lg:col-start-1 lg:col-span-2">

                                <section aria-labelledby="applicant-information-title">
                                    <div className="bg-white shadow sm:rounded-lg">

                                        <div className="px-4 py-5 sm:px-6">
                                            <h2 id="applicant-information-title"
                                                className="text-lg font-medium leading-6 text-gray-900">
                                                Profil
                                            </h2>
                                        </div>
                                        <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
                                            <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">

                                                <div className="sm:col-span-2">
                                                    <div className="flex items-center space-x-5">
                                                        <div className="flex-shrink-0">
                                                            <div className="relative">
                                                                {data.image.detail && <img
                                                                    className="h-32 w-32 rounded-full"
                                                                    src={data.image.detail}
                                                                    alt={data.full_name}
                                                                />}
                                                                <span
                                                                    className="absolute inset-0 shadow-inner rounded-full"
                                                                    aria-hidden="true"
                                                                />
                                                            </div>
                                                        </div>
                                                        <div>
                                                            <p>Profilbild</p>
                                                            <input type="file" name="profile_image"
                                                                   onChange={handleChangeFile}/>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="sm:col-span-1">
                                                    <dt className="text-sm font-medium text-gray-500">Vorname</dt>
                                                    <dd className="mt-1 text-sm text-gray-900">
                                                        <Input
                                                            path="first_name"
                                                            type="text"
                                                            label="Vorname"
                                                            className="sm:col-span-6 hide-label"
                                                            onChange={handleChange}
                                                            errors={errors}
                                                            data={data}
                                                        />
                                                    </dd>
                                                </div>

                                                <div className="sm:col-span-1">
                                                    <dt className="text-sm font-medium text-gray-500">Nachname</dt>
                                                    <dd className="mt-1 text-sm text-gray-900">
                                                        <Input
                                                            path="last_name"
                                                            type="text"
                                                            label="Nachname"
                                                            className="sm:col-span-6 hide-label"
                                                            onChange={handleChange}
                                                            errors={errors}
                                                            data={data}
                                                        />
                                                    </dd>
                                                </div>

                                                <div className="sm:col-span-1">
                                                    <dt className="text-sm font-medium text-gray-500">Email</dt>
                                                    <dd className="mt-1 text-sm text-gray-900">
                                                        <Input
                                                            path="email"
                                                            type="email"
                                                            label="Email"
                                                            className="sm:col-span-6 hide-label"
                                                            onChange={handleChange}
                                                            errors={errors}
                                                            data={data}
                                                        />
                                                    </dd>
                                                </div>

                                                <div className="sm:col-span-1">
                                                    <dt className="text-sm font-medium text-gray-500">Passwort</dt>
                                                    <dd className="mt-1 text-sm text-gray-900">
                                                        <Link className="text-imsblue-600"
                                                              to={`/settings/change-password`}>Passwort ändern</Link>
                                                    </dd>
                                                </div>

                                            </dl>
                                        </div>
                                    </div>
                                </section>

                            </div>

                            <section
                                aria-labelledby="timeline-title"
                                className="lg:col-start-3 lg:col-span-1"
                            >
                            </section>
                        </div>

                    </Form>

                    <span onClick={() => navigate(-1)}
                          className="cursor-pointer float-right mt-2 font-medium text-imsblue-600">Zurück</span>

                </div>

            </div>
        </main>
    )
}
