import {useState} from "react";
import {TrashIcon} from "@heroicons/react/20/solid";
import {classNames} from "../../utils/misc";


export function DeleteButton ({onDelete, label, iconOnly, confirmPrompt, classNameIn}) {

    const [open, setOpen] = useState(false)

    function handleDelete() {
        onDelete()
        setOpen(false)
    }

    return (
        <>

            {open && <div className="text-red-700">
                {confirmPrompt && <span>{confirmPrompt}</span>}
                {!confirmPrompt && <span>Wirklich löschen?</span>}
                <div className="text-red-600 inline-flex gap-2">
                <button type="button" className="inline-flex w-full justify-center rounded-md border border-transparent bg-red-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-red-700 sm:ml-3 sm:w-auto sm:text-sm"
                                          onClick={handleDelete}>Ja</button>
                <button type="button"
                    className="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-imsblue-500 focus:ring-offset-2 sm:mt-0 sm:w-auto sm:text-sm"
                    onClick={() => setOpen(false)}>Abbrechen</button>
                </div>
            </div>}

            {!open && <button
            type="button"
            className={classNames("text-red-700 inline-flex py-2 " + classNameIn)}
            onClick={() => setOpen(true)}>
                <TrashIcon className="h-5 w-5" /> {!iconOnly && <>{label || "Löschen"}</>}
            </button>}

        </>
    )

}


export function DeleteImmediatelyButton ({onDelete, label, iconOnly, classNameIn}) {

    function handleDelete() {
        onDelete()
    }

    return (
        <>
            <button
                type="button"
                className={classNames("text-red-700 inline-flex py-2 " + classNameIn)}
                onClick={handleDelete}>
                <TrashIcon className="h-5 w-5" /> {!iconOnly && <>{label || "Löschen"}</>}
            </button>
        </>
    )

}

