import React from 'react';
import {Route, Routes} from "react-router-dom";
import GalleryList from "./GalleryList";
import GalleryDetail from "./GalleryDetail";


function GalleryRouter() {

    return (
        <Routes>
            <Route index element={<GalleryList/>}/>
            <Route path=":id" element={<GalleryDetail/>}/>
        </Routes>
    )
}


export default function Gallery() {
    return <GalleryRouter/>
}
