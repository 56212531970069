import React, { useEffect, useRef, useState } from "react";
import { getEventsByMonth } from "../../api/calendar";
import {
  checkIfDateFallsBetween,
  getDaysInMonth,
  getFormattedTime,
  getNumberArrayWithRange,
  today,
} from "../../utils/helpers";
import { PrimaryButton } from "../elements/Buttons";
import { MultiSelect } from "../elements/forms/Inputs";
import Month from "./Month";
import { useEvents } from "../../contexts/EventsContext";
import { calendarPdfExportOptions } from "./calendarUtils";
import { PDFExport } from "@progress/kendo-react-pdf";

const ExportForm = ({ months, defaultSelectedMonth }) => {
  const exportRef = useRef();
  const { filter } = useEvents();
  const [monthWithEvents, setMonthWithEvents] = useState(
    months.map((month) => {
      return {
        ...month,
        events: [],
      };
    })
  );
  const [data, setData] = useState({
    selectedMonths: [defaultSelectedMonth],
    monthEvents: [],
  });
  const getDaysForMonth = (selectedDate, fetchedEventsMonth) => {
    const dateObj = new Date(selectedDate.year, selectedDate.month - 1, selectedDate.date)
    // const currDate = today.getDate();
    const currYear = dateObj.getFullYear();
    const currMonth = dateObj.getMonth() + 1;
    const lastMonth = currMonth === 1 ? 12 : currMonth - 1;
    const nextMonth = currMonth === 12 ? 1 : currMonth + 1;
    const totalDaysCurrMonth = getDaysInMonth(currYear, currMonth);
    const totalDaysLastMonth = getDaysInMonth(
      currYear,
      currMonth !== 1 ? currMonth - 1 : 12
    );
    // const dayOfFirstDate = new Date(`${currYear}-${currMonth}-1`).getDay();
    const dayOfFirstDate = new Date(currYear, currMonth - 1, 1).getDay();
    const dayOfLastDate = new Date(
      `${currYear}-${currMonth}-${totalDaysCurrMonth}`
    ).getDay();
    const lastMonthYear =
      dateObj.getMonth() === 0 ? dateObj.getFullYear() - 1 : currYear;
    const nextMonthYear =
      dateObj.getMonth() === 11 ? dateObj.getFullYear() + 1 : currYear;
    const days = [
      ...getNumberArrayWithRange(
        dayOfFirstDate,
        totalDaysLastMonth + 1 - dayOfFirstDate
      ).map((date) => {
        return { date: `${lastMonthYear}-${lastMonth}-${date}`, events: [] };
      }),
      ...getNumberArrayWithRange(totalDaysCurrMonth, 1).map((date) => {
        return {
          date: `${currYear}-${currMonth}-${date}`,
          isCurrentMonth: true,
          isToday: false,
          events: fetchedEventsMonth
            ? fetchedEventsMonth
                .filter((event) => {
                  return checkIfDateFallsBetween(
                    `${currMonth}/${date}/${currYear}`,
                    event.start,
                    event.end
                  );
                })
                .map((event) => {
                  return {
                    ...event,
                    id: event.event_id,
                    name: event.event.title,
                    time: getFormattedTime(event),
                    datetime: event.start,
                    href: "#",
                  };
                })
            : [],
        };
      }),
      ...getNumberArrayWithRange(Math.max(6 - dayOfLastDate, 0), 1).map((date) => {
        return { date: `${nextMonthYear}-${nextMonth}-${date}`, events: [] };
      }),
    ];
    return days;
  };
  const exportPdf = () => {};
  useEffect(() => {
    const selectedMonthWithEvents = monthWithEvents.filter((i) =>
      data.selectedMonths.includes(i.id)
    );
    const monthWithEmptyEvents = selectedMonthWithEvents.filter(
      (i) => i.events.length === 0
    );
    if (monthWithEmptyEvents.length) {
      monthWithEmptyEvents.forEach((month) => {
        getEventsByMonth(months.find((i) => i.id === month.id).year, month.id)
          .then((res) => {
            // console.log(res);
            const newMonthWithEvents = monthWithEvents.map((monthObj) => {
              return {
                ...monthObj,
                events: monthObj.id === month.id ? res : monthObj.events,
              };
            });
            setMonthWithEvents(newMonthWithEvents);
          })
          .catch((err) => {});
      });
    }
  }, [data.selectedMonths]);

  const filteredEvents = (eventsByMonth) => {
    if (filter.categories.length || filter.groups.length) {
      let filteredEventsData = [];
      if (filter.categories.length) {
        filteredEventsData = [
          ...filteredEventsData,
          ...eventsByMonth.map((event) => {
            return {
              ...event,
              events: event.events.filter((i) =>
                filter.categories.includes(i.event.category.id)
              ),
            };
          }),
        ];
      }
      if (filter.groups.length) {
        filteredEventsData = [
          ...filteredEventsData,
          ...eventsByMonth.map((event) => {
            return {
              ...event,
              events: event.events.filter((i) =>
                i.event.target_groups.some((j) => filter.groups.includes(j.id))
              ),
            };
          }),
        ];
      }
      return filteredEventsData;
    }
    return eventsByMonth;
  };
  const getDaysByMonthId = (month) => {
    const selectedDateObj = {
      date: today.getDate(),
      month,
      year: months.find((i) => i.id === month).year,
    };
    return filteredEvents(
      getDaysForMonth(
        selectedDateObj,
        monthWithEvents.find((i) => i.id === month).events
      )
    );
  };
  return (
    <>
      <h4>Kalender als PDF herunterladen</h4>
      <div className="flex py-6">
        <MultiSelect
          path="selectedMonths"
          label="Monate auswählen"
          emptyLabel="Monate auswählen"
          className="w-full hide-label mr-3"
          options={months}
          valueAttr="id"
          labelAttr="name"
          onChange={(value) => {
            setData({ selectedMonths: value });
          }}
          data={data}
          isClearable={false}
          valueOnly={true}
        />
        <PrimaryButton
          onClick={() => {
            if (exportRef.current) {
              exportRef.current.save();
            }
          }}
          label={"Herunterladen"}
        />
      </div>
      <PDFExport
        forcePageBreak=".page-break"
        ref={exportRef}
        margin="10mm"
        paperSize="A4"
        landscape={true}
        scale={0.7}
        fileName={data.selectedMonths
          .map((monthId) => months.find((i) => i.id === monthId).name)
          .toString()}
      >
        {data.selectedMonths.map((month, index) => (
          <>
            <h2 className={`mb-2 ${index !== 0 ? "page-break" : ""}`}>
              {months.find((i) => i.id === month).name}
            </h2>
            <Month
              providerFunction={useEvents}
              days={getDaysByMonthId(month)}
              key={month}
              expanded={true}
            />
          </>
        ))}
      </PDFExport>
    </>
  );
};

export default ExportForm;
