import {Link, useParams} from "react-router-dom";
import {React} from "react";
import {useQuery} from "@tanstack/react-query";
import checkPermission from "../../../utils/permissions";
import {useAuth} from "../../../contexts/AuthContext";
import {formatDate} from "../../../utils/formatters";
import {alumniApi} from "../../../api/alumni";
import {
    AcademicCapIcon,
    BuildingOfficeIcon,
    CalendarIcon,
    EnvelopeIcon,
    MapPinIcon,
    TagIcon
} from "@heroicons/react/20/solid";
import {FacebookIcon, InstagramIcon, LinkedInIcon} from "../../elements/Icons";
import {EditButton} from "../../elements/Buttons";

export default function AlumnusDetail() {
    const {id} = useParams();
    const {permissions} = useAuth();

    const {data: alumnus} = useQuery(["alumni", id], () =>
        alumniApi('get', id)
    )

    if (!alumnus) return <></>;

    const student = alumnus.student
    const hasExtendedProfile = alumnus.has_enabled_extended_profile
    const canViewComplete = checkPermission(permissions, "alumni.view_alumni_complete")

    return (
        <>
            <main className="py-10 h-full bg-gray-100">
                <div
                    className="max-w-3xl mx-auto px-4 sm:px-6 md:flex md:items-center md:justify-between md:space-x-5 lg:max-w-7xl lg:px-8">
                    <div className="flex items-center space-x-5">
                        <div className="flex-shrink-0">
                            <div className="relative">
                                <img
                                    className="h-32 w-32 rounded-full"
                                    src={student.image?.detail}
                                    alt={student.full_name}
                                />
                                <span
                                    className="absolute inset-0 shadow-inner rounded-full"
                                    aria-hidden="true"
                                />
                            </div>
                        </div>
                        <div>
                            <h1 className="text-2xl font-bold text-gray-900">
                                {student.full_name}
                            </h1>
                        </div>
                    </div>
                    <div
                        className="mt-6 flex flex-col-reverse justify-stretch space-y-4 space-y-reverse sm:flex-row-reverse sm:justify-end sm:space-x-reverse sm:space-y-0 sm:space-x-3 md:mt-0 md:flex-row md:space-x-3">

                        {alumnus.can_edit && <EditButton
                            link={ `/alumni/${alumnus.id}/edit` }
                            label="Bearbeiten"
                        />}


                        {alumnus.user_id && <Link to={`/messenger/new?user_id=${alumnus.user_id}`}>
                            <button type="button"
                                    className={"cursor-pointer inline-flex items-center " +
                                        "justify-center px-4 py-2 border border-transparent text-sm font-medium " +
                                        "rounded-md shadow-sm text-white bg-blue-600 hover:bg-blue-700 " +
                                        "focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-blue-500"}
                            ><EnvelopeIcon className="h-5 w-5 mr-2"/> Nachricht senden
                            </button>
                        </Link>}

                    </div>
                </div>

                <div
                    className="mt-8 max-w-3xl mx-auto grid grid-cols-1 gap-6 sm:px-6 lg:max-w-7xl lg:grid-flow-col-dense lg:grid-cols-3">
                    <div className="space-y-6 lg:col-start-1 lg:col-span-2">

                        <section aria-labelledby="applicant-information-title">
                            <div className="bg-white shadow sm:rounded-lg">

                                <div className="px-4 py-5 sm:px-6">
                                    <h2 id="applicant-information-title"
                                        className="text-lg font-medium leading-6 text-gray-900">
                                        Profil
                                    </h2>
                                </div>
                                <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
                                    <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">

                                        {alumnus.former_last_name && <div className="sm:col-span-1">
                                            <dt className="text-sm font-medium text-gray-500">Früherer Name</dt>
                                            <dd className="mt-1 text-sm text-gray-900">{alumnus.former_last_name}
                                            </dd>
                                        </div>}

                                        {/*<div className="sm:col-span-1">*/}
                                        {/*    <dt className="text-sm font-medium text-gray-500">Geburtsdatum</dt>*/}
                                        {/*    <dd className="mt-1 text-sm text-gray-900">{formatDate(student.birth_date)}*/}
                                        {/*    </dd>*/}
                                        {/*</div>*/}
                                        <div className="sm:col-span-1">
                                            <dt className="text-sm font-medium text-gray-500">War Schüler/In</dt>
                                            <dd className="mt-1 text-sm text-gray-900">{formatDate(student.entry_date)} bis {formatDate(student.exit_date)}
                                            </dd>
                                        </div>
                                        <div className="sm:col-span-1">
                                            <dt className="text-sm font-medium text-gray-500">Familienstand</dt>
                                            <dd className="mt-1 text-sm text-gray-900">{alumnus.marital_status}
                                            </dd>
                                        </div>
                                        <div className="sm:col-span-1">
                                            <dt className="text-sm font-medium text-gray-500">Kinder</dt>
                                            <dd className="mt-1 text-sm text-gray-900">{alumnus.children}
                                            </dd>
                                        </div>
                                        <div className="sm:col-span-1">
                                            <dt className="text-sm font-medium text-gray-500">Abiturjahrgang</dt>
                                            <dd className="mt-1 text-sm text-gray-900">{alumnus.graduation_year}
                                            </dd>
                                        </div>
                                        {alumnus.ambassador_for && <div className="sm:col-span-1">
                                            <dt className="text-sm font-medium text-gray-500">BotschafterIn für</dt>
                                            <dd className="mt-1 text-sm text-gray-900">{alumnus.ambassador_for}
                                            </dd>
                                        </div>}
                                        {canViewComplete && <div className="sm:col-span-1">
                                            <dt className="text-sm font-medium text-gray-500">Email</dt>
                                            <dd className="mt-1 text-sm text-gray-900">
                                                <a href={`mailto:${student.email}`}>{student.email}</a>
                                            </dd>
                                        </div>}
                                        <div className="sm:col-span-1">
                                            <dt className="text-sm font-medium text-gray-500">Letzter Login</dt>
                                            <dd className="mt-1 text-sm text-gray-900">{formatDate(student.last_login)}
                                            </dd>
                                        </div>
                                        <div className="sm:col-span-1">
                                            <dt className="text-sm font-medium text-gray-500">Letzte Aktualisierung</dt>
                                            <dd className="mt-1 text-sm text-gray-900">{formatDate(alumnus.last_updated)}
                                            </dd>
                                        </div>

                                    </dl>
                                </div>
                            </div>
                        </section>

                        {hasExtendedProfile && alumnus.professional_experiences.length > 0 &&
                            <section aria-labelledby="applicant-information-title">
                                <div className="bg-white shadow sm:rounded-lg">

                                    <div className="px-4 py-5 sm:px-6">
                                        <h2 id="applicant-information-title"
                                            className="text-lg font-medium leading-6 text-gray-900">
                                            Berufliche Stationen
                                        </h2>
                                    </div>
                                    <div className="border-t">

                                        <div className="overflow-hidden bg-white shadow sm:rounded-md">
                                            <ul role="list" className="divide-y divide-gray-200">
                                                {alumnus.professional_experiences.map((position) => (
                                                    <li key={position.id}>
                                                        <div className="block hover:bg-gray-50">
                                                            <div className="px-4 py-4 sm:px-6">
                                                                <div className="flex items-center justify-between">

                                                                    <div>
                                                                        <p className="truncate text-sm font-medium text-imsblue-600">{position.position}</p>
                                                                        <p className="mt-1 text-gray-900 text-sm whitespace-pre-wrap">{position.description}</p>
                                                                    </div>
                                                                    <div className="ml-2 flex flex-shrink-0">
                                                                        <p className="inline-flex rounded-full bg-green-100 px-2 text-xs font-semibold leading-5 text-green-800">
                                                                            {position.employment_type}
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                                <div className="mt-2 sm:flex sm:justify-between">
                                                                    <div className="sm:flex">
                                                                        <p className="flex items-center text-sm text-gray-500">
                                                                            <BuildingOfficeIcon
                                                                                className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400"
                                                                                aria-hidden="true"/>
                                                                            {position.company}
                                                                        </p>
                                                                        <p className="mt-2 flex items-center text-sm text-gray-500 sm:mt-0 sm:ml-6">
                                                                            <MapPinIcon
                                                                                className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400"
                                                                                aria-hidden="true"/>
                                                                            {position.country}
                                                                        </p>
                                                                        <p className="mt-2 flex items-center text-sm text-gray-500 sm:mt-0 sm:ml-6">
                                                                            <TagIcon
                                                                                className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400"
                                                                                aria-hidden="true"/>
                                                                            {position.industry}
                                                                        </p>
                                                                    </div>
                                                                    <div
                                                                        className="mt-2 flex items-center text-sm text-gray-500 sm:mt-0">
                                                                        <CalendarIcon
                                                                            className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400"
                                                                            aria-hidden="true"/>
                                                                        <p>
                                                                            {formatDate(position.date_from)} {position.date_to && <>- {formatDate(position.date_to)}</>}
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </li>
                                                ))}
                                            </ul>
                                        </div>

                                    </div>
                                </div>
                            </section>}

                        {hasExtendedProfile && alumnus.education.length > 0 &&
                            <section aria-labelledby="applicant-information-title">
                                <div className="bg-white shadow sm:rounded-lg">

                                    <div className="px-4 py-5 sm:px-6">
                                        <h2 id="applicant-information-title"
                                            className="text-lg font-medium leading-6 text-gray-900">
                                            Ausbildung
                                        </h2>
                                    </div>
                                    <div className="border-t">

                                        <div className="overflow-hidden bg-white shadow sm:rounded-md">
                                            <ul role="list" className="divide-y divide-gray-200">
                                                {alumnus.education.map((position) => (
                                                    <li key={position.id}>
                                                        <div className="block hover:bg-gray-50">
                                                            <div className="px-4 py-4 sm:px-6">
                                                                <div className="flex items-center justify-between">

                                                                    <div>
                                                                        <p className="truncate text-sm font-medium text-imsblue-600">{position.course}</p>
                                                                        <p className="mt-1 text-gray-900 text-sm whitespace-pre-wrap">{position.description}</p>
                                                                    </div>
                                                                    <div className="ml-2 flex flex-shrink-0">
                                                                        <p className="inline-flex rounded-full bg-green-100 px-2 text-xs font-semibold leading-5 text-green-800">
                                                                            {position.degree}
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                                <div className="mt-2 sm:flex sm:justify-between">
                                                                    <div className="sm:flex">
                                                                        <p className="flex items-center text-sm text-gray-500">
                                                                            <AcademicCapIcon
                                                                                className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400"
                                                                                aria-hidden="true"/>
                                                                            {position.school}
                                                                        </p>
                                                                        <p className="mt-2 flex items-center text-sm text-gray-500 sm:mt-0 sm:ml-6">
                                                                            <MapPinIcon
                                                                                className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400"
                                                                                aria-hidden="true"/>
                                                                            {position.city} {position.country && <>, {position.country}</>}
                                                                        </p>
                                                                    </div>
                                                                    <div
                                                                        className="mt-2 flex items-center text-sm text-gray-500 sm:mt-0">
                                                                        <CalendarIcon
                                                                            className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400"
                                                                            aria-hidden="true"/>
                                                                        <p>
                                                                            {formatDate(position.date_from)} {position.date_to && <>- {formatDate(position.date_to)}</>}
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </li>
                                                ))}
                                            </ul>
                                        </div>

                                    </div>
                                </div>
                            </section>}

                    </div>

                    <section
                        aria-labelledby="timeline-title"
                        className="lg:col-start-3 lg:col-span-1"
                    >
                        {hasExtendedProfile &&
                            <section aria-labelledby="student-links">
                                <div className="bg-white shadow overflow-hidden sm:rounded-lg mt-0">
                                    <div className="px-4 py-5 sm:px-6">
                                        <h3 className="text-lg leading-6 font-medium text-gray-900">
                                            Private Kontaktdaten
                                        </h3>
                                    </div>
                                    <div className="border-t border-gray-200 px-4 py-5 sm:p-4">
                                        <dl className={"grid grid-cols-1 gap-x-4 gap-y-4 sm:grid-cols-2 "}>
                                            <div className="sm:col-span-1">
                                                <dt className="text-sm font-medium text-gray-500">Adresse</dt>
                                                <dd className="mt-1 text-sm text-gray-900">
                                                    {alumnus.street} <br/>
                                                    {alumnus.zip_code} {alumnus.city} <br/>
                                                    {alumnus.country}
                                                </dd>
                                            </div>
                                            <div className="sm:col-span-1">
                                                <dt className="text-sm font-medium text-gray-500">Telefon</dt>
                                                <dd className="mt-1 text-sm text-gray-900">{alumnus.phone_private}</dd>
                                            </div>
                                            <div className="sm:col-span-1">
                                                <dt className="text-sm font-medium text-gray-500">Handy</dt>
                                                <dd className="mt-1 text-sm text-gray-900">{alumnus.mobile}</dd>
                                            </div>
                                            <div className="sm:col-span-1">
                                                <dt className="text-sm font-medium text-gray-500">Weitere Email</dt>
                                                <dd className="mt-1 text-sm text-gray-900">
                                                    <a href={`mailto:${alumnus.additional_email}`}>{alumnus.additional_email}</a>
                                                </dd>
                                            </div>
                                            <div className="sm:col-span-1">
                                                <dt className="text-sm font-medium text-gray-500">Soziale Medien</dt>
                                                <dd className="mt-1 text-sm text-gray-900 flex gap-2">
                                                    {alumnus.facebook &&
                                                        <a href={alumnus.facebook} target="_blank" rel="noreferrer">
                                                            <FacebookIcon className="h-8 w-8 text-gray-400"
                                                                          aria-hidden="true"/>
                                                        </a>}
                                                    {alumnus.instagram &&
                                                        <a href={alumnus.instagram} target="_blank" rel="noreferrer">
                                                            <InstagramIcon className="h-8 w-8 text-gray-400"
                                                                           aria-hidden="true"/>
                                                        </a>}
                                                    {alumnus.linkedin &&
                                                        <a href={alumnus.linkedin} target="_blank" rel="noreferrer">
                                                            <LinkedInIcon className="py-0.5 h-8 w-8 text-gray-400"
                                                                          aria-hidden="true"/>
                                                        </a>}
                                                </dd>
                                            </div>
                                            {alumnus.website && <div className="sm:col-span-1">
                                                <dt className="text-sm font-medium text-gray-500">Website</dt>
                                                <dd className="mt-1 text-sm text-gray-900 break-all overflow-scroll whitespace-pre-wrap">
                                                    <a href={alumnus.website} target="_blank">{alumnus.website}</a>
                                                </dd>
                                            </div>}
                                        </dl>
                                    </div>
                                </div>
                            </section>}

                        {hasExtendedProfile && alumnus.has_work_contact_info &&
                            <section aria-labelledby="student-links">
                                <div className="bg-white shadow overflow-hidden sm:rounded-lg">
                                    <div className="px-4 py-5 sm:px-6">
                                        <h3 className="text-lg leading-6 font-medium text-gray-900">
                                            Geschäftliche Kontaktdaten
                                        </h3>
                                    </div>
                                    <div className="border-t border-gray-200 px-4 py-5 sm:p-4">
                                        <dl className={"grid grid-cols-1 gap-x-4 gap-y-4 sm:grid-cols-2 "}>
                                            <div className="sm:col-span-1">
                                                <dt className="text-sm font-medium text-gray-500">Firma</dt>
                                                <dd className="mt-1 text-sm text-gray-900">{alumnus.company}</dd>
                                            </div>
                                            <div className="sm:col-span-1">
                                                <dt className="text-sm font-medium text-gray-500">Branche</dt>
                                                <dd className="mt-1 text-sm text-gray-900">{alumnus.company_industry}</dd>
                                            </div>
                                            <div className="sm:col-span-1">
                                                <dt className="text-sm font-medium text-gray-500">Adresse</dt>
                                                <dd className="mt-1 text-sm text-gray-900">
                                                    {alumnus.street_work} <br/>
                                                    {alumnus.zip_code_work} {alumnus.city_work} <br/>
                                                    {alumnus.country_work}
                                                </dd>
                                            </div>
                                            <div className="sm:col-span-1">
                                                <dt className="text-sm font-medium text-gray-500">Telefon</dt>
                                                <dd className="mt-1 text-sm text-gray-900">{alumnus.phone_work}</dd>
                                            </div>
                                            <div className="sm:col-span-1">
                                                <dt className="text-sm font-medium text-gray-500">Handy</dt>
                                                <dd className="mt-1 text-sm text-gray-900">{alumnus.mobile_work}</dd>
                                            </div>
                                            <div className="sm:col-span-1">
                                                <dt className="text-sm font-medium text-gray-500">Email</dt>
                                                <dd className="mt-1 text-sm text-gray-900">
                                                    <a href={`mailto:${alumnus.email_work}`}>{alumnus.email_work}</a>
                                                </dd>
                                            </div>
                                        </dl>
                                    </div>
                                </div>
                            </section>}

                        {canViewComplete &&
                            <section aria-labelledby="student-links">
                                <div className="bg-white shadow overflow-hidden sm:rounded-lg mt-5">
                                    <div className="px-4 py-5 sm:px-6">
                                        <h3 className="text-lg leading-6 font-medium text-gray-900">
                                            Administratives
                                        </h3>
                                    </div>
                                    <div className="border-t border-gray-200 px-4 py-5 sm:p-4">
                                        <dl className={"grid grid-cols-1 gap-x-4 gap-y-4 sm:grid-cols-2 "}>
                                            <div className="sm:col-span-1">
                                                <dt className="text-sm font-medium text-gray-500">Ehem. Schülernummer</dt>
                                                <dd className="mt-1 text-sm text-gray-900">{student.student_number}</dd>
                                            </div>
                                            <div className="sm:col-span-1">
                                                <dt className="text-sm font-medium text-gray-500">Erhält Korrespondenz</dt>
                                                <dd className="mt-1 text-sm text-gray-900">{alumnus.receives_correspondence ? "Ja" : "Nein"}</dd>
                                            </div>
                                            {alumnus.remarks && <div className="sm:col-span-2">
                                                <dt className="text-sm font-medium text-gray-500">Bemerkungen</dt>
                                                <dd className="mt-1 text-sm text-gray-900 whitespace-pre-wrap">{alumnus.remarks}</dd>
                                            </div>}
                                            {/*<div className="sm:col-span-1">*/}
                                            {/*    <dt className="text-sm font-medium text-gray-500">Schüler</dt>*/}
                                            {/*    <dd className="mt-1 text-sm text-gray-900">*/}
                                            {/*        <Link className="text-imsblue-600" to={`/school/students/${student.id}`}>{student.full_name}</Link>*/}
                                            {/*    </dd>*/}
                                            {/*</div>*/}
                                        </dl>
                                    </div>
                                </div>
                            </section>}
                    </section>
                </div>

            </main>
        </>
    );
}
