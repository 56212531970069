import React from "react";
import Logo from "../layout/Logo";
import {useRef, useState} from 'react'
import {Link, useNavigate} from 'react-router-dom'
import Alert from './../elements/Alert'

import {useAuth} from "./../../contexts/AuthContext"
import http from "./../../services/httpNoAuthService";
import env from "react-dotenv";


const Joi = require('joi');

export default function ForgotPassword() {

    const usernameRef = useRef()

    const { currentUser } = useAuth() // todo if set, return to dashboard?
    const [success, setSuccess] = useState("")
    const [error, setError] = useState("")
    const [loading, setLoading] = useState(false)
    const navigate = useNavigate()

    async function handleSubmit(e) {
        e.preventDefault()

        try {
            setError("")
            setSuccess("")
            setLoading(true)

            await http.post("/password_reset/", {
                email: usernameRef.current.value
            });

        } catch (e) {}

        // we will set this in any case to prevent attacks trying out different users
        setSuccess("Bitte prüfe dein Email-Postfach für den Link zum Zurücksetzen deines Passworts.")
        setLoading(false)
    }

    return (
        <>
            <div className={"min-h-screen flex flex-col justify-center sm:py-4 lg:py-12 sm:px-6 md:px-8 " + (env.SCHOOL === 'KPI' ? "bg-imsblue " : "")}>
                <div className="sm:mx-auto sm:w-full sm:max-w-md">
                    <div className="bg-imsblue">
                        <Logo />
                    </div>
                    <h2 className={"mt-4 text-center text-2xl font-bold" + (env.SCHOOL === 'KPI' ? " text-white" : " text-imsblue")}>
                        Passwort zurücksetzen</h2>
                </div>

                <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
                    <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
                        <form id="loginForm" className="space-y-6" onSubmit={handleSubmit}>

                            {error && <Alert variant="danger">{error}</Alert>}
                            {success && <Alert variant="success">{success}</Alert>}

                            <div>
                                <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                                    Email
                                </label>
                                <div className="mt-1">
                                    <input
                                        id="email"
                                        ref={usernameRef}
                                        name="email"
                                        type="text"
                                        autoComplete="email"
                                        required
                                        className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-imsblue-500 focus:border-imsblue-500 sm:text-sm"
                                    />
                                </div>
                            </div>

                            <div>
                                <button
                                    disabled={loading}
                                    type="submit"
                                    className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-imsblue-600 hover:bg-imsblue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-imsblue-500"
                                >
                                    Link zum Zurücksetzen anfordern
                                </button>
                            </div>
                        </form>

                        <div className="my-6">
                            <div className="my-6">

                                <div className="text-sm text-center">
                                    <Link to="/login" className="font-medium text-imsblue-600 hover:text-imsblue-500">
                                        Zurück zum Login
                                    </Link>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

//
// class ForgotPassword extends React.Component {
//
//     state = {
//         data: { username: "" },
//         submitted: false,
//         errors: {},
//         formOptions: {
//             submitButtonLabel: "Request password reset"
//         }
//     };
//
//     schema = {
//         1: {
//             username: Joi.string().email({ tlds: {allow: false} }).required().label("Email")
//         }
//     };
//
//     handleChange = ({ currentTarget: input }) => {
//         // const { data, errors } = baseHandleChange(input, this.state.data, this.state.errors, this.schema);
//         // this.setState({ data, errors });
//     };
//
//     handleValidate = () => {
//         // return validate(this.schema, this.state.data)
//     }
//
//     handleSubmit = async (e) => {
//
//         if (e) e.preventDefault();
//
//         const errors = validate(this.schema, this.state.data);
//         this.setState({ errors: errors || {} });
//         if (errors) return false;
//
//         return await this.doSubmit();
//
//     };
//
//     doSubmit = async () => {
//
//         try {
//             const { data } = this.state;
//             await http.post(config.API_URL + "/password_reset/", {
//                 email: data.username
//             });
//         } catch (ex) {
//             // ignore errors to not give away whether the email address exists
//             if (ex.response && ex.response.status === 400) {
//                 const errors = { ...this.state.errors };
//                 this.setState({ errors });
//             }
//         }
//         this.setState({ submitted: true });
//         // toast.success("Sent successfully if email exists.", {autoClose: 3000});
//     };
//
//     render() {
//         if (auth.getCurrentUser()) return <Redirect to="/" />;
//
//         const { state } = this.props.location;
//
//         if (state) {
//             const { errorMessage, successMessage } = state;
//
//             if (errorMessage) {
//                 toast.error(errorMessage);
//             }
//             if (successMessage) {
//                 toast.success(successMessage, {autoClose: 3000});
//             }
//         }
//
//         return (
//             <div className="container-narrow pt-5">
//                 <h1>Reset your password</h1>
//                 {!this.state.submitted &&
//                     <Form onSubmit={this.handleSubmit}
//                           validate={this.handleValidate}
//                           options={this.state.formOptions} >
//                         {renderInput(this.state.data, this.state.errors, this.handleChange, "username", "Email")}
//                     </Form>}
//
//                 {this.state.submitted &&
//                     <p>Please check your email for the password reset link.</p>
//                 }
//
//             </div>
//         );
//     }
// }
//
// export default ForgotPassword;
