import {SimplePage} from "../../elements/Pages";
import React from "react";

import {useQuery} from "@tanstack/react-query";
import {useAuth} from "../../../contexts/AuthContext";
import ListViewTable from "../../elements/tables/ListViewTable";
import {getTeacherCourses, getTeacherCoursesExcel} from "../../../api/courses";
import {DownloadButton} from "../../elements/Buttons";
import {Link} from "react-router-dom";

const columns = [

    {
        accessorFn: (row) => row,
        id: "staff",
        header: "Mitarbeiter",
        columnType: "staff",
        enableColumnFilter: false,
    },
    {
        accessorKey: "course_number",
        id: "course_number",
        header: "Kurse",
        enableColumnFilter: false,
        disableRowLink: true,
        cell: ({row, value}) => (
            <>
                {row.original.courses && row.original.courses.map((course, index) => (
                    <span key={index}>
                        {index > 0 ? ", " : ""}
                        <Link className="font-medium text-imsblue-600"
                              to={`/courses/${course.id}`}>{course.course_number}</Link>
                    </span>
                ))}
            </>
        ),
    },
    {
        accessorKey: "school_class.name",
        id: "school_class",
        header: "Klassenlehrer",
        enableColumnFilter: false,
        cell: ({row, value}) => (
            <>
                {row.original.school_class && row.original.school_class.map((sc, index) => (
                    <span key={index}>
                        {index > 0 ? ", " : ""}
                        <Link className="font-medium text-imsblue-600"
                              to={`/school-classes/${sc.id}`}>{sc.group}</Link>
                    </span>
                ))}
            </>
        ),
    }
];

export default function TeacherCourseList() {

    const {isLoading, isError, isSuccess, data, error, refetch} = useQuery(
        ["teacher_courses"], getTeacherCourses, {refetchInterval: 1000 * 60 * 60 * 24}
    );

    function getRowLink(row) {
        return `/staff/${row.original.id}/`;
    }

    return (
        <SimplePage>
            <div className="px-4 sm:px-6 lg:px-8">
                <div className="sm:flex sm:items-center">
                    <div className="sm:flex-auto">
                        <h3 className="text-lg leading-6 font-medium text-gray-900 mb-2">
                            Lehrerkurse
                        </h3>
                    </div>
                    <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
                        <DownloadButton
                            apiFunction={() => getTeacherCoursesExcel()}
                            fileName={`Lehrerkurse.xls`}
                            label="Excel herunterladen"/>
                    </div>
                </div>
                <div className="flex flex-col">
                    <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">

                        <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                            {isSuccess && data && (
                                <ListViewTable
                                    data={data}
                                    columns={columns}
                                    isLoading={isLoading}
                                    disableGlobalSearch={false}
                                    rowSelection={null}
                                    setRowSelection={null}
                                    getRowLink={getRowLink}
                                />
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </SimplePage>
    );
}
