import {NarrowPage} from "../../elements/Pages";
import {React, useEffect, useState} from "react";
import Joi from "joi"

import {Link, useNavigate, useParams} from 'react-router-dom'
import {useQuery, useQueryClient} from '@tanstack/react-query'
import {DateInput, DateTimeInput, Input, TextArea} from "../../elements/forms/Inputs"
import {FormSectionContent} from "../../elements/forms/FormLayout"
import {getStudentInfo, getStudentInfoAll} from "../../../api/school";
import Form, {baseHandleChange, baseHandleDateChange, baseHandleListChange} from "../../elements/forms/Form"
import {toast} from "react-toastify";
import {Select} from "../../elements/forms/Inputs"
import {
    deleteProjectParticipation,
    getProjectList,
    getProjectParticipation,
    patchProjectParticipation,
    postProjectParticipation
} from "../../../api/projects";
import {AddButton, PrimaryButton, SecondaryButton} from "../../elements/Buttons";
import ListViewTable, {TableDownloadLink} from "../../elements/tables/ListViewTable";
import {getTutoringInvoicePDF} from "../../../api/tutoring";
import {DeleteButton} from "../../elements/DeleteButton";
import {getWeekdayOptions} from "../../../api/calendar";
import {validateProperty} from "../../../utils/validation";
import moment from "moment";
import {getCourse, patchCourse} from "../../../api/courses";


export default function CourseEdit() {

    const {id} = useParams()
    const [data, setData] = useState({})
    const queryClient = useQueryClient()
    const navigate = useNavigate()

    useEffect(() => {
        loadData()
    }, [id])

    async function loadData() {
        if (!id) return
        const newData = await getCourse(id)
        setData(newData)
    }

    const [errors, setErrors] = useState({})

    const schema = {};

    async function onSave() {
        const newData = await patchCourse(data)
        setData(newData)
        queryClient.invalidateQueries({ queryKey: ['course_detail', id] })
        toast.success("Kursthemen aktualisiert.")
        navigate(`/courses/${id}`)
    }

    function handleChange(evt) {
        baseHandleChange(evt, data, setData, errors, setErrors, schema)
    }

    const title = "Kursthemen bearbeiten"
    const successMessage = "Kursthemen aktualisiert."

    return (

        <NarrowPage title={title}>

            <Form id="editCourse" onSave={onSave}
                  onDelete={null}
                  data={data} setData={setData}
                  className=""
                  errors={errors} setErrors={setErrors}
                  successMessage={successMessage}
                  schema={schema}>
                <h2 className="text-lg font-medium text-gray-900">{data.course_number} {data.school_subject?.name}</h2>

                <FormSectionContent>

                    <Input
                        className="col-span-3"
                        path="course_topic_hy_1"
                        label="Thema 1. Halbjahr"
                        onChange={handleChange}
                        errors={errors}
                        data={data}
                        todayButton={true}
                        tomorrowButton={false}
                    />

                    <Input
                        className="col-span-3"
                        path="course_topic_hy_2"
                        label="Thema 2. Halbjahr"
                        onChange={handleChange}
                        errors={errors}
                        data={data}
                        todayButton={true}
                        tomorrowButton={false}
                    />

                </FormSectionContent>

            </Form>

            <Link to={`/courses/${id}`}
                  className="float-right mt-2 font-medium text-imsblue-600">Zurück</Link>

        </NarrowPage>
    )
}
