import http from "./../services/httpService";
import {getDownloadFiltersName, getFiltersString, getRealFiltersName} from "./utils";

export async function getDownloadCategories(filtersOnly) {
    const resp = await http.get('/download_categories/' + (filtersOnly ? `filter_categories/` : ''))
    return resp.data
}

export async function getDownloadCreators() {
    const resp = await http.get('/download_creators/')
    return resp.data
}

export async function getDownloads(page, search = '', filters = []) {
    const resp = await http.get(`/downloads/`
        // + (categoryId ? `?category_id=${categoryId}` : '')
        + (page > 0 ? `?page=${page}` : '')
        + (search ? `&search=${search}` : '')
        + (filters.length ? `&${getFiltersString(getDownloadFiltersName(filters))}` : '')
    )
    return resp.data
}

export async function getDownload(id) {
    const resp = await http.get(`/downloads/${id}/`)
    return resp.data
}

export async function postDownload(data) {
    const resp = await http.post('/downloads/', data)
    return resp.data
}

export async function addFileToDownload(formData, id) {
    const resp = await http.post(`/downloads/${id}/add_file/`, formData)
    return resp.data
}

export async function patchDownload(data) {
    const resp = await http.patch(`/downloads/${data.id}/` , data)
    return resp.data
}

export async function deleteDownload(id) {
    const resp = await http.delete(`/downloads/${id}/`)
    return resp.data
}

export async function deleteDownloads(data) {
    const resp = await http.post(`/downloads/delete_multiple/`, data)
    return resp.data
}