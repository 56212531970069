import React from 'react';
import {Route, Routes} from "react-router-dom";
import ProjectList from "./ProjectList";
import ProjectDetail from "./ProjectDetail";
import Students from "./Students";
import StudentDetail from "./StudentDetail";
import ParticipationCreateEdit from "./ParticipationCreateEdit";
import AttendanceStudentList from "./AttendanceStudentList";
import AttendanceStudentDetail from "./AttendanceStudentDetail";
import AttendanceStudentListMusic from "./AttendanceStudentListMusic";
import AttendanceList from "./AttendanceList";
import AttendanceProjectDetail from "./AttendanceProjectDetail";
import AttendanceCreateEdit from "./AttendanceCreateEdit";
import MyProjects from "./MyProjects";
import MyProjectsProjectLeader from "./MyProjectsProjectLeader";
import ProjectCancellationCreateEdit from "./ProjectCancellationCreateEdit";
import ProjectCreateEdit from "./ProjectCreateEdit";


function ProjectRouter() {

    return (
        <Routes>

            <Route index element={<ProjectList/>}/>
            <Route path=":id" element={<ProjectDetail/>}/>
            <Route path=":id/edit" element={<ProjectCreateEdit/>}/>

            <Route path="students" element={<Students/>}/>
            <Route path="students/:studentId" element={<StudentDetail/>}/>

            <Route path="participation/new/:studentId" element={<ParticipationCreateEdit/>}/>
            <Route path="participation/:id/edit" element={<ParticipationCreateEdit/>}/>

            <Route path="attendance/students" element={<AttendanceStudentList/>}/>
            <Route path="attendance/students/music" element={<AttendanceStudentListMusic/>}/>
            <Route path="attendance/students/:studentId" element={<AttendanceStudentDetail/>}/>
            <Route path="attendance/students/:studentId/:music" element={<AttendanceStudentDetail/>}/>

            <Route path="attendance" element={<AttendanceList/>}/>
            <Route path="attendance/projects/:projectId" element={<AttendanceProjectDetail/>}/>

            <Route path="attendance/:id/edit" element={<AttendanceCreateEdit/>}/>
            <Route path="attendance/new/:projectId" element={<AttendanceCreateEdit/>}/>

            <Route path="/my" element={<MyProjects/>}/>
            <Route path="/my-projects-pl" element={<MyProjectsProjectLeader/>}/>

            <Route path="/cancellations/new" element={<ProjectCancellationCreateEdit/>}/>
            <Route path="/cancellations/:id/edit" element={<ProjectCancellationCreateEdit/>}/>

        </Routes>
    )
}


export default function Projects() {
    return <ProjectRouter/>
}
