import Joi from "joi";
import {React, useEffect, useState} from "react";
import {Page} from "../../elements/Pages";
import {useQuery, useQueryClient} from "@tanstack/react-query";
import {useNavigate, useParams} from "react-router-dom";
import {toast} from "react-toastify";
import Form, {baseHandleChange, baseHandleDateChange,} from "../../elements/forms/Form";
import {FormSectionContent} from "../../elements/forms/FormLayout";
import {DateTimeInput, Input, MultiSelect, Select,} from "../../elements/forms/Inputs";
import {
    addFileToAnnouncement,
    addImageToAnnouncement,
    announcementApi,
    approveAnnouncement,
    getAnnouncementCategories,
    getExistingDownloads
} from "../../../api/organizational";
import {getMainGroups} from "../../../api/accounts";
import {useMessenger} from "../../../contexts/MessengerContext";
import {SecondaryButton, SuccessButton} from "../../elements/Buttons";
import Alert from "../../elements/Alert";
import draftToHtml from 'draftjs-to-html';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import toolbarConfig from "../../../utils/wysiwygToolbarConfig";
import {ContentState, convertFromHTML, convertToRaw, EditorState} from 'draft-js';
import {Editor} from "react-draft-wysiwyg";
import checkPermission from "../../../utils/permissions";
import {useAuth} from "../../../contexts/AuthContext";
import RecipientsSelector from "../messenger/RecipientsSelector";

export default function AnnouncementCreateEdit() {
    const {id} = useParams();

    const {permissions, currentUser} = useAuth()

    // not ideal to have this dependency but I guess it works
    const {selectedRecipients, setSelectedRecipients} = useMessenger();

    const [data, setData] = useState({
        title: "",
        content: "",
        target_users: [],
        target_groups: []
    });
    const [detailSelectOpen, setDetailSelectOpen] = useState(false);
    const [editorState, setEditorState] = useState();

    const [selectedFiles, setSelectedFiles] = useState([]);
    const [isFilePicked, setIsFilePicked] = useState(false);

    const [selectedImage, setSelectedImage] = useState([]);
    const [isImagePicked, setIsImagePicked] = useState(false);

    const canModerateAnnouncements = checkPermission(permissions, "organizational.moderate_announcements")
    const canCreateAlumniAnnouncements = checkPermission(permissions, "organizational.create_alumni_announcements")
    const canCreateAnnouncementsWithDownloads = checkPermission(permissions, "organizational.create_announcements_with_downloads")
    const isAlumnus = currentUser.is_alumnus && !canModerateAnnouncements

    const {data: categories} = useQuery(["announcement_categories"], getAnnouncementCategories, {refetchInterval: 1000 * 60 * 60 * 24});
    const {data: groups} = useQuery(["main_user_groups", canCreateAlumniAnnouncements], () => getMainGroups(!canCreateAlumniAnnouncements), {refetchInterval: 1000 * 60 * 60 * 24});
    const {data: existingDownloads} = useQuery(["existing_downloads", currentUser.id], getExistingDownloads);

    const [errors, setErrors] = useState({}); // validation errors

    let navigate = useNavigate();
    const queryClient = useQueryClient()

    useEffect(() => {
        loadData();
    }, [id]);

    async function loadData() {
        // load existing announcement if editing
        if (!id) return
        const newData = await announcementApi("get", id)
        setData(newData)

        if (newData.content.length > 0) {
            let editorState = EditorState.createWithContent(
                ContentState.createFromBlockArray(
                    convertFromHTML(newData.content)
                )
            )
            setEditorState(editorState)
        }

        // set selected recipients from target users
        setSelectedRecipients(newData.target_users.map(u => u.id))
    }

    const schema = {
        title: Joi.string().required(),
        category: Joi.object().required()
    }

    const title =
        id || data.id
            ? "Tweet bearbeiten"
            : "Tweet verfassen";
    let successMessage

    async function onSave() {
        let newData;

        let tmpData = {
            ...data,
            target_users: selectedRecipients.map(r => ({"id": r})),
            content: draftToHtml(convertToRaw(editorState.getCurrentContent()))
        }

        try {
            if (!data.id) {
                newData = await announcementApi("post", null, tmpData);
            } else {
                newData = await announcementApi("patch", null, tmpData);
            }
            setData(newData);

            // downloads
            if (isFilePicked) {
                const formData = new FormData();
                for (let i = 0; i < selectedFiles.length; i++) {
                    formData.append('file', selectedFiles[i]);
                    newData = await addFileToAnnouncement(formData, newData.id)
                }
            }

            // image
            if (isImagePicked) {
                const formData = new FormData();
                formData.append('file', selectedImage);
                newData = await addImageToAnnouncement(formData, newData.id)
            }

            if (newData.is_published) {
                successMessage = "Tweet veröffentlicht"
            } else if (newData.is_approved) {
                successMessage = "Tweet wird zum gewählten Datum veröffentlicht"
            } else {
                successMessage = "Tweet gespeichert und muss noch freigeschaltet werden"
            }
            await queryClient.invalidateQueries({ queryKey: ['announcements'] })
            setSelectedRecipients([])
            toast.success(successMessage)
            navigate(`/service/announcements`)
        } catch (e) {
            toast.error(e)
        }
    }

    async function onDelete() {
        await announcementApi("delete", data.id);
        toast.success("Tweet gelöscht.");
        navigate(`/service/announcements`)
    }

    async function onApprove() {
        try {
            const newData = await approveAnnouncement(data.id);
            setData(newData);
            toast.success("Tweet freigegeeben.");
            navigate(`/service/announcements`)
        } catch (e) {
            toast.error(e)
        }
    }

    function handleChange(evt) {
        baseHandleChange(evt, data, setData, errors, setErrors, schema);
    }

    function handleDateChange(date, path) {
        baseHandleDateChange(
            date,
            path,
            data,
            setData,
            errors,
            setErrors,
            schema,
            true
        );
    }

    function handleMultiSelect(selectedOptions, path) {
        let newData = {...data}
        newData[path] = selectedOptions
        setData(newData)
    }

    const handleChangeFile = (event) => {
        setSelectedFiles(event.target.files)
        setIsFilePicked(true)
    }

    const handleChangeImage = (event) => {
        setSelectedImage(event.target.files[0])
        setIsImagePicked(true)
    }

    if (!data) return <></>
    return (
        <>

            <RecipientsSelector
                open={detailSelectOpen}
                setOpen={setDetailSelectOpen}
            />

            <Page title={title}>
                <div
                    className="max-w-3xl mx-auto grid grid-cols-1 gap-6  lg:max-w-7xl lg:grid-flow-col-dense lg:grid-cols-6">
                    <div className="space-y-6 lg:col-span-4">
                        <Form
                            id="createAnnouncement"
                            onSave={onSave}
                            onDelete={onDelete}
                            data={data}
                            setData={setData}
                            errors={errors}
                            setErrors={setErrors}
                            successMessage={successMessage}
                            schema={schema}
                        >
                            <FormSectionContent>

                                <Input
                                    path="title"
                                    type="text"
                                    label="Titel"
                                    className="sm:col-span-6"
                                    onChange={handleChange}
                                    errors={errors}
                                    data={data}
                                />

                                {!isAlumnus && <>
                                    <Select
                                        path="category"
                                        label="Kategorie"
                                        className="sm:col-span-3"
                                        options={categories}
                                        valueAttr="id"
                                        labelAttr="title"
                                        onChange={handleChange}
                                        errors={errors}
                                        data={data}
                                        isClearable={true}
                                        valueOnly={false}
                                    />

                                    {canModerateAnnouncements && <DateTimeInput
                                        className="col-span-3"
                                        path="date_published"
                                        label="Veröffentlichungsdatum"
                                        helpText="Leer lassen für sofortige Veröffentlichung"
                                        onChange={handleDateChange}
                                        errors={errors}
                                        data={data}
                                        todayButton={true}
                                        tomorrowButton={true}
                                    />}

                                    <MultiSelect
                                        path="target_groups"
                                        label="Sichtbar für (Gruppen)"
                                        className="sm:col-span-5"
                                        options={groups}
                                        valueAttr="id"
                                        labelAttr="name"
                                        onChange={(selectedOptions) => handleMultiSelect(selectedOptions, 'target_groups')}
                                        errors={errors}
                                        data={data}
                                        isClearable={true}
                                        valueOnly={false}
                                    />

                                    <div className="sm:col-span-1 items-center flex">
                                        <SecondaryButton
                                            classNameIn="mt-5 w-full"
                                            onClick={() => setDetailSelectOpen(true)}
                                            label={"Detailauswahl"}/>

                                    </div>

                                    <div className="sm:col-span-6 -mt-4">
                                        {selectedRecipients.length} Benutzer ausgewählt
                                    </div>

                                </>}

                                <div className="sm:col-span-6">
                                    <label htmlFor="content" className="block text-sm font-medium text-gray-900">
                                        Inhalt
                                    </label>
                                    <div className="mt-1 w-full shadow-sm focus:ring-imsblue-500
                                    focus:border-imsblue-500 sm:text-sm border border-gray-300 rounded-md">
                                        <Editor
                                            handlePastedText={() => false}
                                            editorState={editorState}
                                            toolbarClassName="toolbarClassName"
                                            wrapperClassName="wrapperClassNameEdit"
                                            editorClassName="editorClassName min-h-[20rem] resize-y px-2"
                                            onEditorStateChange={(editorState) => {
                                                setEditorState(editorState)
                                            }}
                                            toolbar={toolbarConfig}
                                        />
                                    </div>
                                </div>


                                <div className="sm:col-span-6 text-sm">

                                    <h3 className="text-md leading-6 font-medium text-gray-900">Bild</h3>
                                    <input type="file" multiple={false} name="file" onChange={handleChangeImage}/>
                                    <br/>
                                    <div>
                                        {data.image && <p className="py-1">Bisheriges Bild: {data.image}</p>}
                                    </div>

                                </div>


                                {canCreateAnnouncementsWithDownloads &&
                                    <div className="sm:col-span-6 text-sm">

                                        <h3 className="text-md leading-6 font-medium text-gray-900">Anhänge</h3>

                                        <input type="file" multiple={true} name="file" onChange={handleChangeFile}/>
                                        <br/>
                                        <div>
                                            {data.file && <p className="py-1">Bisherige Datei: {data.file}</p>}
                                        </div>

                                        <br/>
                                        <p>Oder aus bereits verfügbaren Downloads auswählen:</p>

                                        <MultiSelect
                                            path="downloads"
                                            label="Anhänge"
                                            emptyLabel="Anhänge"
                                            className="w-full hide-label"
                                            options={existingDownloads}
                                            valueAttr="id"
                                            labelAttr="get_file_name"
                                            onChange={(selectedOptions) => handleMultiSelect(selectedOptions, 'downloads')}
                                            data={data}
                                            isClearable={true}
                                            valueOnly={false}
                                        />

                                    </div>}


                                {(data.target_groups.find(g => (g.name === 'Eltern' || g.name === 'Schüler'))) &&
                                    <Alert variant="warning" extraClassName="sm:col-span-6">
                                        Sind Sie sicher? Dieser Tweet ist für Schüler und/oder Eltern sichtbar!
                                    </Alert>}

                            </FormSectionContent>


                        </Form>


                        {canModerateAnnouncements && !data.is_approved && data.id &&
                            <div className="approveRejectButtons justify-end flex gap-2 mt-4 mb-2">
                                <SuccessButton
                                    label={"Tweet freigeben"}
                                    onClick={onApprove}
                                />
                            </div>
                        }
                        <span
                            onClick={() => navigate(-1)}
                            className="cursor-pointer float-right mt-2 font-medium text-imsblue-600"
                        >
              Zurück
            </span>
                    </div>

                    <div className="lg:col-span-2 pl-3 sm:max-h-[80%] min-h-0 flex-1 overflow-y-auto">


                    </div>

                </div>
            </Page>
        </>
    );
}

