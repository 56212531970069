import {SimplePage} from "../../elements/Pages";
import React, {useEffect, useState} from "react";

import {useQuery} from "@tanstack/react-query";
import ListViewTable, {TableEditLink, TableLink, TwoLineColumn} from "../../elements/tables/ListViewTable";
import {useParams} from "react-router-dom";
import {getHomeworkDataForCourse} from "../../../api/class_register";
import {getCourse} from "../../../api/courses";
import {EditButton, PrimaryButton} from "../../elements/Buttons";
import {classNames} from "../../../utils/misc";


export default function CourseHomework() {

    const {courseId} = useParams()
    const [previousOrCurrent, setPreviousOrCurrent] = useState('current')
    const [data, setData] = useState()

    const {data: course} = useQuery(
        ["course_detail", courseId],
        () => getCourse(courseId)
    )

    useEffect(() => {
        loadData()
    }, [previousOrCurrent, courseId])

    async function loadData() {
        const newData = await getHomeworkDataForCourse(courseId, previousOrCurrent)
        setData(newData)
    }

    function getRowLink(row) {
        return `/class-register/homework/${row.original.id}/`;
    }

    function getExamRowLink(row) {
        // TODO fix this link - properly point to the calendar
        return `/class-register/exams/${row.original.id}/`;
    }

    if (!course) return <></>

    const homeworkColumns = [
        {
            accessorKey: "due_date",
            id: "due_date",
            header: "Zum",
            columnType: "date",
            enableColumnFilter: false,
            // classNames: 'font-medium text-gray-900'
            classNames: "text-gray-500",
        },
        {
            accessorKey: "type",
            id: "type",
            header: "Art",
            enableColumnFilter: false
        },
        {
            accessorKey: "students",
            id: "students",
            header: "SchülerInnen",
            enableColumnFilter: false,
            cell: ({row, value}) => (
                (row.original.type === 'Hausaufgabe' ? 'alle' :
                        <>{row.original.students.map((student, index) => (
                            <>
                                {(index > 1 ? ', ' : '')}
                                <TableLink
                                    label={student.full_name}
                                    to={`/school/students/${student.id}`}
                                />
                            </>
                        ))}
                        </>
                )
            ),
            disableRowLink: true
        },
        {
            accessorKey: "title",
            id: "title",
            header: "Aufgabe",
            cell: ({row, value}) => (
                <div className="whitespace-pre-wrap">
                    <TwoLineColumn
                        line1={row.original.title}
                        line2={row.original.task}
                    /></div>
            ),
            enableColumnFilter: false
        }
    ]

    const examColumns = [
        {
            accessorKey: "date",
            id: "date",
            header: "Datum",
            columnType: "date",
            enableColumnFilter: false,
        },
        {
            accessorKey: "time",
            id: "time",
            header: "Zeit",
            columnType: "m2m",
            labelAttr: "name",
            enableColumnFilter: false
        },
        {
            accessorKey: "room_bookings",
            id: "room_bookings",
            header: "Raum",
            enableColumnFilter: false,
            cell: ({row, value}) => (
                <>
                    {row.original.room_bookings.map((r, index) => (
                        <span key={index}>
                        {index > 0 ? ", " : ""}
                            {r.room.name}
                    </span>
                    ))}
                </>
            ),
        },
        {
            accessorKey: "category",
            id: "category",
            header: "Art",
            enableColumnFilter: false,
        },
        {
            accessorKey: "topic",
            id: "topic",
            header: "Thema",
            enableColumnFilter: false,
            headerClassNames: "medium-filter"
        },
        {
            accessorKey: "teachers", // todo
            id: "teachers",
            header: "Lehrer",
            columnType: "m2mLinked",
            getItemLink: (item) => {
                return `/staff/${item.id}/`
            },
            labelAttr: "full_name",
            enableColumnFilter: false,
            filterFn: "containsString",
        }
    ]

    if (course.can_create_class_register_entry) {
        homeworkColumns.push({
            accessorKey: "id",
            id: "link",
            cell: (info) => (
                <TableEditLink
                    iconOnly={true}
                    label="Bearbeiten"
                    to={`/class-register/homework/${info.getValue()}/edit`}
                />
            ),
            header: "",
            disableRowLink: true,
            enableColumnFilter: false,
        })

        examColumns.push({
            accessorKey: "id",
            id: "link",
            cell: ({row, value}) => (
                <>
                    <EditButton iconOnly={true} link={`/class-register/exams/${row.original.id}/edit`}/>
                </>
            ),
            header: "Bearbeiten",
            enableColumnFilter: false,
            disableRowLink: true
        })
    }

    return (
        <SimplePage>
            {/*  Simple striped table */}
            <div className="px-4 sm:px-6 lg:px-8">
                <div className="sm:flex sm:items-center">
                    <div className="sm:flex-auto">
                        <h3 className="text-lg leading-6 font-medium text-gray-900">
                            Hausaufgaben & Klausuren für {course.course_number} {course.school_subject.name}
                            {course.school_year.is_current_school_year ? '' : ` (${course.school_year.name})`}
                        </h3>
                    </div>
                    <div className="mt-4 sm:mt-0 sm:ml-16 flex gap-2">
                        <PrimaryButton link={`/class-register/courses/${courseId}`}
                                       label="Kursbuch"/>
                    </div>
                </div>
                <div className="flex flex-col">
                    <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">

                        <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                            <nav className="flex space-x-4" aria-label="Tabs">
                                <a
                                    key="current"
                                    onClick={() => setPreviousOrCurrent("current")}
                                    className={classNames(
                                        previousOrCurrent === "current"
                                            ? "bg-imsblue-100 text-imsblue-700"
                                            : "text-gray-500 hover:text-gray-700",
                                        "px-3 py-2 font-medium text-sm rounded-md cursor-pointer"
                                    )}
                                    aria-current={previousOrCurrent === "current" ? "page" : undefined}
                                >
                                    Aktuelle
                                </a>
                                <a
                                    key="previous"
                                    onClick={() => setPreviousOrCurrent("previous")}
                                    className={classNames(
                                        previousOrCurrent === "previous"
                                            ? "bg-imsblue-100 text-imsblue-700"
                                            : "text-gray-500 hover:text-gray-700",
                                        "px-3 py-2 font-medium text-sm rounded-md cursor-pointer"
                                    )}
                                    aria-current={previousOrCurrent === "previous" ? "page" : undefined}
                                >
                                    Vorherige
                                </a>
                            </nav>
                        </div>

                        <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                            <div className="">
                                <h2 id="notes-title" className="text-md font-medium text-gray-900">
                                    Hausaufgaben
                                </h2>
                                <div className="">
                                    {data && data.homework.length > 0 && (
                                        <ListViewTable
                                            data={data.homework}
                                            columns={homeworkColumns}
                                            disableGlobalSearch={true}
                                            getRowLink={getRowLink}
                                        />
                                    )}
                                </div>
                            </div>

                            <div className="mt-8">
                                <h2 id="notes-title" className="text-md font-medium text-gray-900">
                                    Klausuren & Tests
                                </h2>
                                <div className="">
                                    {data && data.exams.length > 0 && (
                                        <ListViewTable
                                            data={data.exams}
                                            columns={examColumns}
                                            disableGlobalSearch={true}
                                            getRowLink={getExamRowLink}
                                        />
                                    )}
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </SimplePage>
    );
}
