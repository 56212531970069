import React from 'react';
import {Route, Routes} from "react-router-dom";
import SchoolYearChangeDetail from "./SchoolYearChangeDetail";

function SchoolYearChangeRouter() {

    return (
        <Routes>

            <Route path="/" element={<SchoolYearChangeDetail />} />

        </Routes>
    )
}


export default function SchoolYearChange() {
    return <SchoolYearChangeRouter/>
}
