import React, {useState} from "react";
import BasicModal from "../../elements/modal";
import "react-checkbox-tree-duplicate-value/lib/react-checkbox-tree.css";
import {RecipientSelectorBody} from "./RecipientsSelectorBody";
import {RecipientSelectorFooter} from "./RecipientsSelectorFooter";

const RecipientsSelector = ({ open, setOpen }) => {

  const [subGroups, setSubGroups] = useState({
    checked: [],
    expanded: [],
  });
  const [groupsState, setGroupsState] = useState({
    checked: [],
    expanded: [],
  });
  const [detailGroupState, setDetailGroupState] = useState({
    checked: [],
    expanded: [],
  });

  return (
    <BasicModal
      open={open}
      setOpen={setOpen}
      wide={true}
      width="3xl"
      footer={<RecipientSelectorFooter
        detailGroupState={detailGroupState}
        subGroups={subGroups}
        setOpen={setOpen}
      />}
    >
      <RecipientSelectorBody
        subGroups={subGroups}
        setSubGroups={setSubGroups}
        groupsState={groupsState}
        setGroupsState={setGroupsState}
        detailGroupState={detailGroupState}
        setDetailGroupState={setDetailGroupState}
      />
    </BasicModal>
  );
};

export default RecipientsSelector;
