import http from "./../services/httpService";
import {genericApi} from "./generic";
import httpServiceDownloads from "../services/httpServiceDownloads";

export async function getWorkshopDays() {
    const resp = await http.get(`/workshop_days/`);
    return resp.data;
}

export async function getWorkshopDayPDF(workshopDayId) {
    const resp = await httpServiceDownloads.get(`/workshop_days/${workshopDayId}/pdf/`);
    return resp.data;
}

export async function getWorkshopList(workshopDayId) {
    const resp = await http.get(`/workshop_day/${workshopDayId}/workshops/`);
    return resp.data;
}

export async function getWorkshopDetail(workshopId) {
    const resp = await http.get(`/workshop_day/workshops/${workshopId}/`);
    return resp.data;
}

export async function getWorkshopPDF(workshopId) {
    const resp = await httpServiceDownloads.get(`/workshop_day/workshops/${workshopId}/pdf/`);
    return resp.data;
}

export async function bookWorkshop(workshopId) {
    const resp = await http.post(`/workshop_day/workshops/${workshopId}/book/`);
    return resp.data;
}

export async function cancelBooking(bookingId) {
    const resp = await http.post(`/workshop_day/bookings/${bookingId}/cancel/`);
    return resp.data;
}

export async function getStudentList(workshopDayId) {
    const resp = await http.get(`/workshop_day/${workshopDayId}/students/`);
    return resp.data;
}

export async function getStudentDetail(workshopDayId, studentId) {
    const resp = await http.get(`/workshop_day/${workshopDayId}/students/${studentId}/`);
    return resp.data;
}

export async function getStudentDetailPDF(workshopDayId, studentId) {
    const resp = await httpServiceDownloads.get(`/workshop_day/${workshopDayId}/students/${studentId}/pdf/`);
    return resp.data;
}

// export async function workshopDayApi(method, id, data) {
//     return genericApi('/workshop_days/', method, id, data)
// }
//
// export async function attendanceApi(method, id, data) {
//     return genericApi('/career_attendances/', method, id, data)
// }
//
// export async function updateCareerModuleAttendances(id, data) {
//     const resp = await http.post(`/career_modules/${id}/update_attendances/`, data);
//     return resp.data;
// }
//
// export async function getCareerStudents() {
//     const resp = await http.get(`/career/students/`);
//     return resp.data;
// }
//

//
// export async function getCareerStudentDetail(studentId) {
//     const resp = await http.get(`/career/students/${studentId}/`);
//     return resp.data;
// }
