import React from "react";

import {useQuery} from "@tanstack/react-query";
import {useParams} from "react-router-dom";
import {getStudentAssessmentPeriods} from "../../../api/assessments";
import {getStudentInfoInclAlumni} from "../../../api/school";
import ListViewTable, {TableLink} from "../../elements/tables/ListViewTable";
import {useUserInfo} from "../../../contexts/UserInfoContext";


export default function StudentAssessmentPeriods() {

    const {studentId} = useParams()
    const {renderUserImage} = useUserInfo();

    const {data: student} = useQuery(["student_info", studentId], () =>
        getStudentInfoInclAlumni(studentId),
        {refetchInterval: 1000 * 60 * 60 * 24}
    )

    const {data} = useQuery(
        ["student_assessment_periods", studentId],
        () => getStudentAssessmentPeriods(studentId),
        {refetchInterval: 1000 * 60 * 60}
    )

    if (!student) return <></>


    const columns = [
        {
            accessorKey: "time_period_name",
            id: "time_period_name",
            header: "Zeitraum",
            enableColumnFilter: false,
        },
        {
            accessorKey: "viewing_start_date",
            id: "viewing_start_date",
            header: "Ansicht ab",
            columnType: "date",
            enableColumnFilter: false,
        },
        {
            accessorKey: "id",
            id: "link",
            cell: ({row, value}) => (
                <div className="flex gap-4">

                    {row.original.has_assessments && <TableLink
                        label="Beurteilungen"
                        to={`/assessments/assessment-periods/${row.original.id}/students/${studentId}`}
                    />}
                    {row.original.has_boarding_school_assessments && <TableLink
                        label="Internatsbeurteilungen"
                        to={`/assessments/boarding-school/${row.original.id}/students/${studentId}`}
                    />}
                    {row.original.has_studierzeit_assessments && <TableLink
                        label="Studierzeitbeurteilung"
                        to={`/assessments/studierzeit/${row.original.id}/students/${studentId}`}
                    />}

                </div>

            ),
            header: "Beurteilungen",
            enableColumnFilter: false,
            disableRowLink: true
        }
    ]

    return (

        <>
            <main className="py-10 h-full bg-gray-100">
                <div
                    className="max-w-3xl mx-auto px-4 sm:px-6 md:flex md:items-center md:justify-between md:space-x-5 lg:max-w-7xl lg:px-8">
                    <div className="flex items-center space-x-5">
                        <div className="flex-shrink-0">
                            <div className="relative">
                                {renderUserImage(student.user_id)}
                                <span
                                    className="absolute inset-0 shadow-inner rounded-full"
                                    aria-hidden="true"
                                />
                            </div>
                        </div>
                        <div>
                            <h1 className="text-2xl font-bold text-gray-900">
                                Beurteilungen für {student.full_name}
                            </h1>
                            <p className="text-sm font-medium text-gray-500">
                                {student.student_number}
                            </p>
                        </div>
                    </div>
                    <div
                        className="mt-6 flex flex-col-reverse justify-stretch space-y-4 space-y-reverse sm:flex-row-reverse sm:justify-end sm:space-x-reverse sm:space-y-0 sm:space-x-3 md:mt-0 md:flex-row md:space-x-3">
                    </div>
                </div>

                <div
                    className="max-w-3xl mx-auto grid grid-cols-1 gap-6 sm:px-6 lg:max-w-7xl lg:grid-flow-col-dense lg:grid-cols-3">
                    <div className="space-y-6 lg:col-start-1 lg:col-span-3">

                        <div className="bg-gray-100 pt-6 px-4 sm:px-6 lg:px-8">
                            <div className="flex flex-col">

                                {data && data.map((item, index) => (
                                    <div key={index}>
                                        <h2 className="text-lg font-medium text-gray-900">Schuljahr {item.school_year}</h2>

                                        <div className="-mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">

                                            <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8 mb-8">
                                                {(
                                                    <ListViewTable
                                                        data={item.assessment_periods}
                                                        columns={columns}
                                                        isLoading={false}
                                                        disableGlobalSearch={true}
                                                        rowSelection={null}
                                                        setRowSelection={null}
                                                        getRowLink={null}
                                                    />
                                                )}
                                            </div>
                                        </div>
                                    </div>

                                ))}

                            </div>
                        </div>

                    </div>
                </div>

            </main>

        </>
    );
}
