import {Link, useParams} from "react-router-dom";
import {React, useEffect, useState} from "react";
import Joi from "joi";
import {useQuery} from "@tanstack/react-query";
import {AddButton, DangerButton, EditButton, PrimaryButton, SecondaryButton} from "../../elements/Buttons";
import moment from "moment";
import ListViewTable, {
    TableDownloadLink,
    TwoLineColumn,
    TwoLineColumnWithLink
} from "../../elements/tables/ListViewTable";
import {
    cancelTutoring,
    confirmTutoring,
    createInvoiceForTutoring,
    createOrUpdateMultipleTutoringLessons,
    deleteTutoringLesson,
    deleteTutoringMaterial,
    getCancellationDates,
    getStatusOptions,
    getTutoring,
    getTutoringInvoicePDF,
    getTutoringLessons,
    getTutoringMaterials,
    getTutoringPermissions,
    patchTutoring,
    rejectTutoring
} from "../../../api/tutoring";
import {DateInput, Input, Select, TextArea} from "../../elements/forms/Inputs";
import {baseHandleChange, baseHandleDateListChange, baseHandleListChange} from "../../elements/forms/Form";
import {toast} from "react-toastify";
import {DeleteButton} from "../../elements/DeleteButton";
import {formatDate} from "../../../utils/formatters";
import {
    CalendarIcon,
    CheckCircleIcon,
    EnvelopeIcon,
    InformationCircleIcon,
    NoSymbolIcon,
    PlusCircleIcon,
    XCircleIcon
} from "@heroicons/react/20/solid";
import env from "react-dotenv";
import {useUserInfo} from "../../../contexts/UserInfoContext";


export default function TutoringDetail() {
    const {id} = useParams();
    const {renderUserImage} = useUserInfo();
    const [errors, setErrors] = useState({}) // validation errors
    const [tutoring, setTutoring] = useState()

    const [cancellationFormOpen, setCancellationFormOpen] = useState(false)
    const [cancellationDate, setCancellationDate] = useState()

    useEffect(() => {
        loadData()
    }, [id])

    async function loadData() {
        const newData = await getTutoring(id)
        setTutoring(newData)
    }

    const {data: statusOptions} = useQuery(
        ["tutoring_status_options"],
        () => getStatusOptions(),
        {refetchInterval: 1000 * 60 * 60 * 24}
    )

    const {data: tutoringPermissions} = useQuery(
        ["tutoring_permissions", id],
        () => getTutoringPermissions(id),
        {refetchInterval: 1000 * 60 * 60 * 24}
    )

    const {data: cancellationDates} = useQuery(
        ["tutoring_cancellation_dates"],
        () => getCancellationDates(),
        {refetchInterval: 1000 * 60 * 60 * 24}
    )

    async function handleConfirm() {
        try {
            const newData = await confirmTutoring(tutoring)
            setTutoring(newData)
            toast.success("Fördermaßnahme bestätigt")
        } catch (e) {
            toast.error("Beim Speichern ist ein Fehler aufgetreten.")
        }
    }

    async function handleReject() {
        try {
            const newData = await rejectTutoring(tutoring)
            setTutoring(newData)
            toast.success("Fördermaßnahme abgelehnt")
        } catch (e) {
            toast.error("Beim Speichern ist ein Fehler aufgetreten.")
        }
    }

    async function handleCancellationSubmit() {

        if (!cancellationDate) {
            setErrors({cancellation_date: "Bitte ein Datum auswählen"})
            return
        }

        try {
            const newData = await cancelTutoring(tutoring.id, cancellationDate)
            setTutoring(newData)
            toast.success("Fördermaßnahme gekündigt")
        } catch (e) {
            toast.error("Beim Speichern ist ein Fehler aufgetreten.")
        }
    }

    function handleCancellationChange(evt) {
        baseHandleChange(evt, cancellationDate, setCancellationDate, errors, setErrors, {})
    }

    function handleChange(evt) {
        baseHandleChange(evt, tutoring, setTutoring, errors, setErrors, {})
    }

    async function handleSave() {
        try {
            const newData = await patchTutoring(tutoring)
            setTutoring(newData)
            toast.success("Status gespeichert")
        } catch (e) {
            toast.error("Beim Speichern ist ein Fehler aufgetreten.")
        }
    }

    if (!tutoring) return <></>;

    const canChangeTutoring = tutoring.can_edit

    return (
        <>
            <main className="py-10 h-full bg-gray-100">
                <div
                    className="max-w-3xl mx-auto px-4 sm:px-6 md:flex md:items-center md:justify-between md:space-x-5 lg:max-w-7xl lg:px-8">
                    <div className="flex items-center space-x-5">
                        <div className="flex-shrink-0">
                            <div className="relative">
                                {renderUserImage(tutoring.student.user_id)}
                                <span
                                    className="absolute inset-0 shadow-inner rounded-full"
                                    aria-hidden="true"
                                />
                            </div>
                        </div>
                        <div>
                            <h1 className="text-2xl font-bold text-gray-900">
                                Nachhilfe {tutoring.subject.name} für {tutoring.student.full_name}
                            </h1>
                            <p className="text-sm font-medium text-gray-500">
                                {tutoring.student.student_number}, Klasse {tutoring.student.school_class}
                            </p>
                        </div>
                    </div>
                    <div
                        className="mt-6 flex flex-col-reverse justify-stretch space-y-4 space-y-reverse sm:flex-row-reverse sm:justify-end sm:space-x-reverse sm:space-y-0 sm:space-x-3 md:mt-0 md:flex-row md:space-x-3">
                        <SecondaryButton label="Nachhilfen" link={`/tutoring/students/${tutoring.student.id}`}/>
                        <SecondaryButton label="Schülerblatt" link={`/school/students/${tutoring.student.id}`}/>
                        {canChangeTutoring && <EditButton link={`/tutoring/${tutoring.id}/edit`} label="Bearbeiten" />}
                    </div>
                </div>

                <div
                    className="mt-8 max-w-3xl mx-auto grid grid-cols-1 gap-6 sm:px-6 lg:max-w-7xl lg:grid-flow-col-dense lg:grid-cols-3">
                    <div className="space-y-6 lg:col-start-1 lg:col-span-2">

                        <section aria-labelledby="applicant-information-title">
                            <div className="bg-white shadow sm:rounded-lg">
                                {/*<div className="px-4 py-5 sm:px-6">*/}
                                {/*    <h2 id="applicant-information-title" className="text-lg font-medium leading-6 text-gray-900">*/}
                                {/*        För*/}
                                {/*    </h2>*/}
                                {/*    <p className="mt-1 max-w-2xl text-sm text-gray-500">Personal details and application.</p>*/}
                                {/*</div>*/}
                                <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
                                    <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
                                        <div className="sm:col-span-1">
                                            <dt className="text-sm font-medium text-gray-500">Fach</dt>
                                            <dd className="mt-1 text-sm text-gray-900">{tutoring.subject.name}</dd>
                                        </div>
                                        <div className="sm:col-span-1">
                                            <dt className="text-sm font-medium text-gray-500">Fachlehrer</dt>
                                            <dd className="mt-1 text-sm text-gray-900">{tutoring.teacher?.full_name}</dd>
                                        </div>
                                        <div className="sm:col-span-1">
                                            <dt className="text-sm font-medium text-gray-500">Nachhilfelehrer</dt>
                                            <dd className="mt-1 text-sm text-gray-900">{tutoring.tutoring_teacher ? tutoring.tutoring_teacher.full_name : ""}</dd>
                                        </div>
                                        <div className="sm:col-span-1">
                                            <dt className="text-sm font-medium text-gray-500"></dt>
                                            <dd className="mt-1 text-sm text-gray-900"></dd>
                                        </div>
                                        <div className="sm:col-span-1">
                                            <dt className="text-sm font-medium text-gray-500">Beantragt von</dt>
                                            <dd className="mt-1 text-sm text-gray-900">{tutoring.get_requested_by_display}</dd>
                                        </div>
                                        <div className="sm:col-span-1">
                                            {tutoring.status === 'REJECTED' && <>
                                                <dt className="text-sm font-medium text-gray-500">Abgelehnt von</dt>
                                                <dd className="mt-1 text-sm text-gray-900">{tutoring.get_rejected_by_display}</dd>
                                            </>}
                                        </div>
                                        <div className="sm:col-span-2">
                                            <dt className="text-sm font-medium text-gray-500">Status</dt>
                                            <dd className="mt-1 text-sm text-gray-900 flex">

                                                {canChangeTutoring &&
                                                    <>
                                                        <Select
                                                            path="status"
                                                            label="Status"
                                                            className="w-1/2 hide-label"
                                                            options={statusOptions}
                                                            valueAttr="value"
                                                            labelAttr="label"
                                                            onChange={handleChange}
                                                            errors={errors}
                                                            data={tutoring}
                                                            isClearable={false}
                                                            valueOnly={true}
                                                        />
                                                        <PrimaryButton
                                                            classNameIn="ml-2"
                                                            onClick={handleSave} label="Speichern"/>
                                                    </>}

                                                {!canChangeTutoring && <>{tutoring.get_status_display}</>}

                                            </dd>
                                        </div>
                                        <div className="sm:col-span-2">
                                            <dt className="text-sm font-medium text-gray-500">Themenverteilungsplan</dt>
                                            <dd className="mt-1 text-sm text-gray-900 whitespace-pre-wrap ">
                                                {tutoring.course_info && <Link target="_blank" to={`/courses/${tutoring.course_info.id}/course-topics`} className="text-imsblue-600">im Kurs {tutoring.course_info.course_number}</Link>}
                                            </dd>
                                        </div>
                                        <div className="sm:col-span-2">
                                            <dt className="text-sm font-medium text-gray-500">Förderplan</dt>
                                            <dd className="mt-1 text-sm text-gray-900 whitespace-pre-wrap ">
                                                {tutoring.tutoring_plan}
                                            </dd>
                                        </div>

                                        {env.SCHOOL === "KPI" && tutoring.is_parent && (tutoring.status == 'ACTIVE' || tutoring.status == 'PENDING') && <div className="sm:col-span-2">
                                            <dt className="text-sm font-medium text-gray-500">Aktionen</dt>
                                            <dd className="mt-1 text-sm text-gray-900 flex gap-2">

                                                {tutoring.status == 'ACTIVE' && <>

                                                    {cancellationFormOpen &&
                                                        <div className="">
                                                            <Select
                                                                path="cancellation_date"
                                                                label="Kündigen zum"
                                                                className="sm:col-span-3"
                                                                options={cancellationDates}
                                                                valueAttr="id"
                                                                labelAttr="date_display"
                                                                onChange={handleCancellationChange}
                                                                errors={errors}
                                                                data={cancellationDate}
                                                                isClearable={false}
                                                                valueOnly={false}
                                                            />
                                                            <DangerButton label="Kündigen" onClick={() => handleCancellationSubmit()} />
                                                    </div>}

                                                    {!cancellationFormOpen &&
                                                        <DangerButton label="Kündigen" onClick={() => setCancellationFormOpen(true)} />}
                                                </>}



                                                {tutoring.status == 'PENDING' && <PrimaryButton label="Bestätigen" onClick={handleConfirm} />}
                                                {tutoring.status == 'PENDING' && <DangerButton label="Ablehnen" onClick={handleReject} />}

                                            </dd>
                                        </div>}

                                    </dl>
                                </div>
                            </div>
                        </section>

                        {/* Table */}
                        <section aria-labelledby="applicant-information-title"></section>
                    </div>

                    <section aria-labelledby="timeline-title" className="lg:col-span-1 lg:col-start-3">
                        <div className="bg-white px-4 py-5 shadow sm:rounded-lg sm:px-6">
                            <h2 id="timeline-title" className="text-lg font-medium text-gray-900">
                                Historie
                            </h2>
                            <div className="mt-6 flow-root">
                                <ul role="list" className="-mb-8">

                                    {tutoring.date_cancelled &&
                                        <TimelineItem index={0} date={tutoring.date_cancelled} icon={XCircleIcon}>
                                            Fördermaßnahme gekündigt
                                            zum {formatDate(tutoring.date_cancellation_effective)}
                                        </TimelineItem>}

                                    {tutoring.start_date &&
                                        <TimelineItem index={0} date={tutoring.start_date} icon={CalendarIcon}>
                                            Fördermaßnahme begonnen
                                        </TimelineItem>}

                                    {tutoring.date_rejected &&
                                        <TimelineItem index={0} date={tutoring.date_rejected} icon={NoSymbolIcon}>
                                            Fördermaßnahme abgelehnt durch {tutoring.get_rejected_by_display}
                                        </TimelineItem>}

                                    {tutoring.date_approved &&
                                        <TimelineItem index={0} date={tutoring.date_approved} icon={CheckCircleIcon}>
                                            Fördermaßnahme von den Eltern genehmigt
                                        </TimelineItem>}

                                    {tutoring.parents_approval_request_sent &&
                                        <TimelineItem index={0} date={tutoring.parents_approval_request_sent} icon={EnvelopeIcon}>
                                        Bestäitungsemail an die Eltern gesendet
                                    </TimelineItem>}

                                    <TimelineItem index={0} date={tutoring.creation_date} icon={PlusCircleIcon} isLast={true}>
                                        Erstellt {(() => {
                                            switch (tutoring.requested_by) {
                                                case "PARENTS":
                                                    return "durch Antrag der Eltern";
                                                    break;
                                                case "SCHOOL":
                                                    return "von der Schule";
                                                    break;
                                                default:
                                                    return "aufgrund einer Nachhilfeempfehlung in der Beurteilung";
                                                    break;
                                            }
                                        }).call(this)}
                                    </TimelineItem>

                                </ul>
                            </div>

                        </div>
                    </section>
                </div>


                <div className="mt-8 max-w-3xl mx-auto grid grid-cols-1 sm:px-6 lg:max-w-7xl lg:grid-flow-col-dense">
                    <section aria-labelledby="notes-title">
                        <Materials
                            tutoring={tutoring}
                            permissions={tutoringPermissions}
                        />
                    </section>
                </div>


                <div className="mt-8 max-w-3xl mx-auto grid grid-cols-1 sm:px-6 lg:max-w-7xl lg:grid-flow-col-dense">
                    <section aria-labelledby="notes-title">
                        <TutoringLessons
                            reloadData={loadData}
                            tutoring={tutoring}
                            permissions={tutoringPermissions}
                        />
                    </section>
                </div>

            </main>
        </>
    );
}


function TimelineItem({children, date, index, icon, isLast}) {
    const IconComponent = icon
    return (
        <li key={index}>
            <div className="relative pb-8">
                {!isLast && <span
                    className="absolute top-4 left-4 -ml-px h-full w-0.5 bg-gray-200"
                    aria-hidden="true"
                />}
                <div className="relative flex space-x-3">
                    <div>
                          <span
                              className='bg-imsblue-500 h-8 w-8 rounded-full flex items-center justify-center ring-8 ring-white'>
                              {icon && <IconComponent className="h-5 w-5 text-white" aria-hidden="true"/>}
                              {!icon && <InformationCircleIcon className="h-5 w-5 text-white" aria-hidden="true"/>}
                          </span>
                    </div>
                    <div className="flex min-w-0 flex-1 justify-between space-x-4 pt-1.5">
                        <div>
                            <p className="text-sm text-gray-500">
                                {children}
                            </p>
                        </div>
                        <div className="whitespace-nowrap text-right text-sm text-gray-500">
                            <time dateTime={date}>{formatDate(date)}</time>
                        </div>
                    </div>
                </div>
            </div>
        </li>
    )
}


function Materials({tutoring, permissions}) {

    const {data: materials, refetch} = useQuery(
        ["tutoring_materials", tutoring.id],
        () => getTutoringMaterials(tutoring.id),
        {}
    )

    const columns = [
        {
            accessorKey: "get_file_name",
            id: "get_file_name",
            header: "Datei",
            cell: ({row, value}) => (
                <TwoLineColumnWithLink
                    link={`${row.original.file}`}
                    line1={`${row.original.title}`}
                    line2={`${row.original.get_file_name}`}
                />
            ),
            enableColumnFilter: false,
            classNames: "max-w-md text-gray-500 break-all",
            headerClassNames: "max-w-md",
        },
        {
            accessorKey: "title",
            id: "title",
            header: "Titel",
            enableColumnFilter: false,
            classNames: "text-gray-500",
        },
        {
            accessorKey: "description",
            id: "description",
            header: "Beschreibung",
            enableColumnFilter: false,
        },
        {
            id: "creation_date",
            header: "Erstellt von",
            cell: ({row, value}) => (
                <TwoLineColumn
                    line1={`${row.original.created_by_full_name}`}
                    line2={"am " + moment(value).format("DD.MM.YYYY")}
                />
            ),
            enableColumnFilter: false,
            classNames: "text-gray-500",
        },
        {
            accessorKey: "id",
            id: "link",
            cell: (info) => (
                <>
                    <DeleteButton onDelete={() => handleDelete(info.getValue())}
                                  iconOnly={true}
                    />
                    <EditButton iconOnly={true} link={`/tutoring/material/${info.getValue()}/edit`}/>

                </>
            ),
            header: "Bearbeiten",
            enableColumnFilter: false,
        }
    ]

    async function handleDelete(id) {
        await deleteTutoringMaterial(id)
        refetch()
    }

    return (
        <div className="bg-white shadow sm:rounded-lg sm:overflow-hidden">
            <div className="divide-y divide-gray-200">
                <div className="px-4 py-5 sm:px-6 flex justify-between">

                    <h2 id="notes-title" className="text-lg font-medium text-gray-900">
                        Materialien
                    </h2>
                    {permissions && permissions.can_create_material &&
                        <PrimaryButton label="Materialien hochladen"
                                       link={`/tutoring/${tutoring.id}/material/new`}/>}
                </div>
                <div className="">
                    {materials && <ListViewTable
                        disableGlobalSearch={true}
                        data={materials} columns={columns}/>}
                </div>
            </div>
        </div>
    )
}

function TutoringLessons({tutoring, permissions}) {

    const schema = {
        topic: Joi.string().required()
    }
    const [errors, setErrors] = useState([]) // validation errors

    const [lessons, setLessons] = useState()
    const [newRowId, setNewRowId] = useState(1)

    useEffect(() => {
        loadData()
    }, [])

    async function loadData() {
        const newData = await getTutoringLessons(tutoring.id)
        setLessons(newData)
    }

    const columnsViewOnly = [
        {
            accessorKey: "date",
            id: "date",
            header: "Datum",
            columnType: "date",
            enableColumnFilter: false,
            classNames: "text-gray-500",
        },
        {
            accessorKey: "topic",
            id: "topic",
            header: "Thema",
            enableColumnFilter: false,
            classNames: "text-gray-500",
        },
        {
            accessorKey: "notes",
            id: "notes",
            header: "Anmerkungen",
            enableColumnFilter: false,
        },
        {
            accessorKey: "invoice",
            id: "invoice",
            header: "Abgerechnet",
            enableColumnFilter: false,
            classNames: "text-gray-500",
            cell: ({row, value}) => (
                row.original.invoice ?
                    <TableDownloadLink
                        apiFunction={() => getTutoringInvoicePDF(row.original.invoice)}
                        fileName={`Rechnung ${tutoring.student.full_name}.pdf`}
                        label="PDF"/>
                    : ""
            ),
            disableRowLink: true
        }
    ]

    function handleChange(evt, rowId) {
        baseHandleListChange(rowId, evt, lessons, setLessons, errors, setErrors, schema)
    }

    function handleDateChange(date, path, rowId) {
        baseHandleDateListChange(rowId, date, path, lessons, setLessons, errors, setErrors, schema, false)
    }

    function addRow(e) {
        e.preventDefault()
        const newRow = {
            "id": `N${newRowId}`,
            "date": null,
            "topic": "",
            "notes": "",
            "invoice": null,
            "tutoring": tutoring.id
        }
        setNewRowId(newRowId + 1)
        setLessons([...lessons, newRow])
    }

    async function handleSave() {
        try {
            await createOrUpdateMultipleTutoringLessons(lessons)
            loadData()
            toast.success("Förderstunden gespeichert")
        } catch (e) {
            toast.error("Beim Speichern ist ein Fehler aufgetreten.")
        }
    }

    async function handleDelete(lessonId) {

        if (lessonId.toString().startsWith("N")) {
            const newData = lessons.filter(row => row.id !== lessonId)
            setLessons(newData)
        } else {
            try {
                await deleteTutoringLesson(lessonId)
                loadData()
                toast.success("Förderstunde gelöscht")
            } catch (e) {
                toast.error("Beim Speichern ist ein Fehler aufgetreten.")
            }
        }
    }

    async function handleCreateInvoices() {
        try {
            await createInvoiceForTutoring(tutoring.id)
            loadData()
            toast.success("Rechnung erstellt")
        } catch (e) {
            toast.error("Beim Speichern ist ein Fehler aufgetreten.")
        }
    }

    if (!permissions) return <></>

    return (
        <div className="bg-white shadow sm:rounded-lg sm:overflow-hidden">
            <div className="divide-y divide-gray-200">
                <div className="px-4 py-5 sm:px-6 flex justify-between">

                    <h2 id="notes-title" className="text-lg font-medium text-gray-900">
                        Förderstunden
                    </h2>
                    {permissions.can_edit_lessons && <SecondaryButton
                        onClick={handleCreateInvoices} label="Abrechnungen für offene Stunden erstellen"/>}
                </div>
                <div className="">

                    {permissions.can_edit_lessons && lessons &&
                        <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
                            <table className="min-w-full divide-y divide-gray-300">
                                <tbody>
                                {lessons.map((lesson, index) => (
                                    <tr key={index}
                                        className={(index % 2 === 0 ? undefined : "bg-gray-50")}
                                    >
                                        <td className="break-word py-4 pl-4 pr-3 text-sm">
                                            <DateInput
                                                className="col-span-2"
                                                path="date"
                                                label="Datum"
                                                onChange={(date, path, rowId) => handleDateChange(date, path, rowId)}
                                                errors={errors}
                                                data={lessons}
                                                todayButton={true}
                                                tomorrowButton={false}
                                                rowId={lesson.id}
                                            />
                                        </td>
                                        <td className="break-word py-4 pl-4 pr-3 text-sm">
                                            <Input
                                                path="topic"
                                                label="Thema"
                                                className="sm:col-span-6"
                                                onChange={handleChange}
                                                errors={errors}
                                                data={lessons}
                                                rowId={lesson.id}
                                            />
                                        </td>

                                        <td className="break-word py-4 pl-4 pr-3 text-sm">
                                            <TextArea
                                                path="notes"
                                                label="Anmerkungen"
                                                className="sm:col-span-6"
                                                onChange={handleChange}
                                                errors={errors}
                                                data={lessons}
                                                rowId={lesson.id}
                                            />
                                        </td>

                                        <td className="break-word py-4 pl-4 pr-3 text-sm">
                                            {lesson.invoice ?
                                                <TableDownloadLink
                                                    apiFunction={() => getTutoringInvoicePDF(lesson.invoice)}
                                                    fileName={`Rechnung ${tutoring.student.full_name} ${lesson.invoice} ${formatDate(lesson.invoice_date)}.pdf`}
                                                    label="Rechnung PDF"/> : ""}
                                        </td>

                                        {permissions.can_edit_lessons &&
                                            <td className="break-word py-4 pl-4 pr-3 text-sm">
                                                <DeleteButton onDelete={() => handleDelete(lesson.id)}/>
                                            </td>}

                                    </tr>
                                ))}

                                </tbody>
                            </table>
                        </div>}

                    {!permissions.can_edit_lessons && lessons &&
                        <ListViewTable disableGlobalSearch={true} data={lessons} columns={columnsViewOnly}/>}

                    {permissions.can_edit_lessons &&

                        <div className="flex justify-between">
                            <div className="my-4 ml-4">
                                <AddButton onClick={(e) => addRow(e)} label="Förderstunde hinzufügen"/>
                            </div>

                            <div>
                                {permissions.can_edit_lessons && <SecondaryButton classNameIn="mr-2"
                                    onClick={handleCreateInvoices} label="Abrechnungen für offene Stunden erstellen"/>}
                                <PrimaryButton
                                    classNameIn="my-4 mr-4"
                                    onClick={handleSave} label="Förderstunden speichern"/>
                            </div>
                        </div>

                    }

                </div>
            </div>
        </div>
    )
}
