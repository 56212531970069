import {SimplePage} from "../../elements/Pages";
import React, {useState} from "react";

import {useQuery} from "@tanstack/react-query";
import ListViewTable, {TableLink} from "../../elements/tables/ListViewTable";
import {
    assessmentPeriodApi,
    getStudentsForAssessmentPeriod,
    sendForAllStudents,
    sendForSelectedStudents
} from "../../../api/assessments";
import {useParams} from "react-router-dom";
import {DangerButton, PrimaryButton} from "../../elements/Buttons";
import env from "react-dotenv";


export default function Students() {

    const {assessmentPeriodId} = useParams()

    const [rowSelection, setRowSelection] = useState({});

    const {data: assessmentPeriod} = useQuery(
        ["assessment_periods", assessmentPeriodId],
        () => assessmentPeriodApi('get', assessmentPeriodId),
        {refetchInterval: 1000 * 60 * 60 * 24}
    );

    const {isLoading, isError, isSuccess, data, error, refetch} = useQuery(
        ["students_for_assessment_period", assessmentPeriodId],
        () => getStudentsForAssessmentPeriod(assessmentPeriodId),
        {refetchInterval: 1000 * 60 * 60 * 24}
    );

    function getRowLink(row) {
        return `/assessments/assessment-periods/${assessmentPeriodId}/students/${row.original.id}/`
    }

    async function handleSendSelected() {
        await sendForSelectedStudents(assessmentPeriodId, rowSelection)
        refetch()
    }

    async function handleSendAll() {
        await sendForAllStudents(assessmentPeriodId, rowSelection)
        refetch()
    }

    const showBoardingSchoolAssessmentLink = (env.SCHOOL === 'ST' && (assessmentPeriod?.name === '4. Epoche' || assessmentPeriod?.name === '2. Epoche')) ? false : true

    const columns = [
        {
            accessorKey: "id",
            id: "select",
            columnType: "select",
        },
        {
            accessorFn: (row) =>
                `${row.last_name}, ${row.first_name} ${row.student_number}`,
            id: "student_search",
            header: "SchülerIn",
            hide: true,
            enableColumnFilter: false,
        },
        {
            accessorFn: (row) => row,
            id: 'student',
            header: 'SchülerIn',
            columnType: 'student',
            enableColumnFilter: false,
            sortingFn: "lastNameSorting",
        },
        {
            accessorKey: "grade",
            id: "grade",
            header: "Stufe",
            enableColumnFilter: true,
            headerClassNames: "narrow-filter",
        },
        {
            accessorKey: "school_class",
            id: "school_class",
            header: "Klasse",
            sortingFn: "schoolClassSorting",
            enableColumnFilter: true,
            headerClassNames: "narrow-filter",
        },
        {
            accessorKey: "house",
            id: "house",
            header: "Mentorat",
            enableColumnFilter: true,
            headerClassNames: "narrow-filter",
        },
        {
            accessorKey: "assessment_notification_last_sent",
            id: "assessment_notification_last_sent",
            header: "Zuletzt versendet",
            columnType: "date",
            enableColumnFilter: false,
            headerClassNames: "narrow-filter",
        },
        {
            accessorKey: "id",
            id: "link",
            cell: (info) => (
                <div className="flex gap-2 justify-between">
                    <TableLink
                        label="Beurteilungen"
                        to={`/assessments/assessment-periods/${assessmentPeriodId}/students/${info.getValue()}`}
                    />
                    {showBoardingSchoolAssessmentLink && <TableLink
                        label="Internatsbeurteilungen"
                        to={`/assessments/boarding-school/${assessmentPeriodId}/students/${info.getValue()}`}
                    />}
                </div>

            ),
            header: "Links",
            enableColumnFilter: false,
            disableRowLink: true
        },
    ];

    return (
        <SimplePage>
            <div className="px-4 sm:px-6 lg:px-8">
                <div className="sm:flex sm:items-center">
                    <div className="sm:flex-auto">
                        <h3 className="text-lg leading-6 font-medium text-gray-900">
                            Beurteilungen nach Schülern für {assessmentPeriod?.name}
                        </h3>
                    </div>
                    <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
                    </div>
                </div>
                <div className="flex flex-col">
                    <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">


                        <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                            {isSuccess && data && (
                                <ListViewTable
                                    data={data}
                                    columns={columns}
                                    isLoading={isLoading}
                                    disableGlobalSearch={false}
                                    rowSelection={rowSelection}
                                    setRowSelection={setRowSelection}
                                    getRowLink={getRowLink}
                                />
                            )}
                        </div>
                    </div>

                    <div className="mt-4 flex gap-2 justify-start">
                        <PrimaryButton onClick={handleSendSelected} label={`Beurteilungen für ${Object.keys(rowSelection).length} ausgewählte Schüler versenden`} />
                        <DangerButton onClick={handleSendAll} label="Beurteilungen für alle Schüler versenden" />
                    </div>
                </div>
            </div>
        </SimplePage>
    );
}
