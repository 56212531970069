import {Page} from "../../elements/Pages";
import {React, useEffect, useState} from "react";
import Joi from "joi"

import {Link, useNavigate, useParams} from 'react-router-dom'
import {useQuery} from '@tanstack/react-query'
import {Checkbox, DateInput, Input, SelectForStudent} from "../../elements/forms/Inputs"
import {FormSectionContent} from "../../elements/forms/FormLayout"
import Form, {baseHandleChange, baseHandleDateChange} from "../../elements/forms/Form"
import {toast} from "react-toastify";
import {
    deleteProjectAttendance,
    getAbsenceNoticesAndPeExemptions,
    getProject,
    getProjectAttendance,
    patchProjectAttendance,
    postProjectAttendance
} from "../../../api/projects";
import {getAttendedOptions} from "../../../api/utils";
import {formatDate, formatTimeFromDateTime} from "../../../utils/formatters";
import moment from "moment";


export default function AttendanceCreateEdit() {

    const {id, projectId} = useParams()

    const [project, setProject] = useState()
    const [absenceNoticesAndPeExemptions, setAbsenceNoticesAndPeExemptions] = useState()
    const [data, setData] = useState({
        id: null,
        project: {id: parseInt(projectId) || null},
        date: moment(new Date()).format("YYYY-MM-DD"),
        cancelled: false,
        cancel_reason: null,
        attendance_lines: []
    })

    const {data: attendanceOptions} = useQuery(['attendance_options'], getAttendedOptions, {refetchInterval: 1000 * 60 * 60 * 24})
    const [errors, setErrors] = useState({})
    let navigate = useNavigate()

    useEffect(() => {
        loadData()
    }, [id, projectId, attendanceOptions])

    useEffect(() => {
        loadAbsenceNoticesAndPeExemptions()
    }, [data.date, data.project])

    useEffect(() => {
        loadAbsenceNoticesAndPeExemptions()
    }, [])

    async function loadAbsenceNoticesAndPeExemptions() {
        if (!data.date || !data.project.id) return
        const a = await getAbsenceNoticesAndPeExemptions(data.project.id, data.date)
        setAbsenceNoticesAndPeExemptions(a)
    }

    async function loadData() {
        if (!attendanceOptions) return
        // load the students of the project - for each of them, create an item
        let newData
        let project
        if (id) {
            newData = await getProjectAttendance(id)
            project = await getProject(newData.project.id)
        } else if (projectId) {
            project = await getProject(projectId)
            newData = {...data}
        }

        // add missing lines
        let attendance_lines = [...newData.attendance_lines]

        for (var i = 0; i < project.active_participants.length; i++) {
            let student = project.active_participants[i]

            if (!attendance_lines.find(l => l.student.id == student.id)) {
                const newLine = {
                    id: null,
                    student: student,
                    attended: attendanceOptions[0],
                }
                attendance_lines.push(newLine)
            }
        }
        setProject(project)
        setData({...newData, attendance_lines: attendance_lines})

    }

    const schema = {
        project: Joi.object().required(),
        date: Joi.date().required()
    }

    async function onSave() {
        let newData
        if (!data.id) newData = await postProjectAttendance(data)
        else newData = await patchProjectAttendance(data)
        setData(newData)
        toast.success("Projektanwesenheit erfasst.")
        navigate(`/projects/attendance/projects/${project.id}`)
    }

    async function onDelete() {
        await deleteProjectAttendance(data.id)
        toast.success("Projektanwesenheit gelöscht.")
        navigate(`/projects/attendance/projects/${project.id}`)
    }

    function handleChange(evt) {
        baseHandleChange(evt, data, setData, errors, setErrors, schema)
    }

    function handleDateChange(date, path) {
        baseHandleDateChange(date, path, data, setData, errors, setErrors, schema)
    }

    function handleAttendedChange(evt, studentId) {
        const newAttendanceLines = data.attendance_lines.map(row => {
            if (row.student.id === studentId) {
                var value = evt.target.value
                return {...row, [evt.target.name]: value}
            }
            return row
        })
        setData({...data, attendance_lines: newAttendanceLines})

    }

    if (!project) return <></>

    const title = (id || data.id) ? "Projektanwesenheit bearbeiten" : "Neue Projektanwesenheit erfassen"
    const successMessage = (id || data.id) ? "Projektanwesenheit aktualisiert" : "Projektanwesenheit erfasst"

    return (

        <Page title={title}>

            <div
                className="max-w-3xl mx-auto md:flex md:items-center md:justify-between lg:max-w-7xl">
                <div className="flex items-center space-x-5">
                    <div className="flex-shrink-0">
                        <div className="relative">
                            <h2 className="text-lg font-medium text-gray-900">{project.name}</h2>
                        </div>
                    </div>
                </div>
            </div>

            <div
                className=" max-w-3xl mx-auto grid grid-cols-1 gap-6 lg:max-w-7xl lg:grid-flow-col-dense lg:grid-cols-3">

                <div className="space-y-6 lg:col-start-1 lg:col-span-2">

                    <section aria-labelledby="notes-title">

                        <Form id="createProjectParticipation" onSave={onSave}
                              onDelete={onDelete}
                              data={data} setData={setData}
                              className=""
                              errors={errors} setErrors={setErrors}
                              successMessage={successMessage}
                              schema={schema}>

                            <FormSectionContent>

                                <DateInput
                                    className="col-span-6"
                                    path="date"
                                    label="Datum"
                                    onChange={handleDateChange}
                                    errors={errors}
                                    data={data}
                                    todayButton={true}
                                    tomorrowButton={false}
                                />

                                <Checkbox
                                    path="cancelled"
                                    label="Ausgefallen"
                                    className="sm:col-span-2"
                                    onChange={handleChange}
                                    errors={errors}
                                    data={data}/>

                                <Input
                                    path="cancel_reason"
                                    type="text"
                                    label="Grund"
                                    className="sm:col-span-4"
                                    onChange={handleChange}
                                    errors={errors}
                                    data={data}
                                />

                                <div className="col-span-6 divide-y divide-gray-200">
                                    <div className="py-5 flex justify-between">
                                        <h2 id="notes-title" className="text-lg font-medium text-gray-900">
                                            Teilnahme
                                        </h2>
                                    </div>
                                    <div className="z-100 overflow-y-visible">

                                        {data.attendance_lines &&
                                            <div
                                                className="shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
                                                <table className="min-w-full divide-y divide-gray-300">
                                                    <tbody>
                                                    {data.attendance_lines.map((l, index) => (
                                                        <tr key={index}
                                                            className={(index % 2 === 0 ? undefined : "bg-gray-50")}
                                                        >
                                                            <td className="py-4 pl-4 pr-3 text-sm">
                                                                {l.student.full_name}
                                                            </td>

                                                            <td className="py-4 pl-4 pr-3 text-sm">
                                                                <SelectForStudent
                                                                    path="attended"
                                                                    label="Anwesend"
                                                                    className="sm:col-span-6 z-50 hide-label"
                                                                    onChange={handleAttendedChange}
                                                                    options={attendanceOptions}
                                                                    labelAttr="name"
                                                                    valueAttr="id"
                                                                    valueOnly={false}
                                                                    data={data.attendance_lines}
                                                                    studentId={l.student.id}
                                                                    disabled={data.cancelled}
                                                                />
                                                            </td>

                                                        </tr>
                                                    ))}

                                                    </tbody>
                                                </table>
                                            </div>}

                                    </div>

                                </div>

                            </FormSectionContent>

                        </Form>

                        <Link to={`/projects`}
                              className="float-right mt-2 font-medium text-imsblue-600">Zurück zur
                            Liste</Link>
                    </section>

                </div>

                <section aria-labelledby="timeline-title"
                         className="lg:col-start-3 lg:col-span-1 mt-6"
                >
                    <section aria-labelledby="applicant-information-title" className="bg-gray-100">

                        <div className="">
                            <div className="px-4 py-5 sm:px-6">
                                <h3 className="text-lg leading-6 font-medium text-gray-900">
                                    Abwesenheitsmeldungen
                                </h3>
                            </div>
                            <div className="border-t border-gray-200 px-4 py-5 sm:p-0">

                                {absenceNoticesAndPeExemptions && absenceNoticesAndPeExemptions.absence_notices &&
                                    <table className="min-w-full table-fixed divide-y divide-gray-300">
                                        <tbody className="divide-y divide-gray-200 bg-white">
                                        {absenceNoticesAndPeExemptions.absence_notices.map((a, index) => (
                                            <tr key={index}>
                                                <td className="px-4 py-4">
                                                    {a.absence_notice.student.full_name}
                                                </td>
                                                <td className="py-4">
                                                    <>{formatTimeFromDateTime(a.occurrence.start)} - {formatTimeFromDateTime(a.occurrence.end)}</>
                                                </td>
                                            </tr>
                                        ))}
                                        </tbody>
                                    </table>}

                            </div>
                        </div>

                    </section>

                    <section aria-labelledby="applicant-information-title" className="bg-gray-100 mt-8">

                        <div className="">
                            <div className="px-4 py-5 sm:px-6">
                                <h3 className="text-lg leading-6 font-medium text-gray-900">
                                    Sportbefreiungen
                                </h3>
                            </div>
                            <div className="border-t border-gray-200 px-4 py-5 sm:p-0">
                                {absenceNoticesAndPeExemptions && absenceNoticesAndPeExemptions.pe_exemptions &&
                                    <table className="min-w-full table-fixed divide-y divide-gray-300">
                                        <tbody className="divide-y divide-gray-200 bg-white">
                                        {absenceNoticesAndPeExemptions.pe_exemptions.map((p, index) => (
                                            <tr key={index}>
                                                <td className="px-4 py-4">
                                                    {p.student.full_name}
                                                </td>
                                                <td className="py-4">
                                                    {formatDate(p.date_from)} - {formatDate(p.date_to)}
                                                </td>
                                            </tr>
                                        ))}
                                        </tbody>
                                    </table>}

                            </div>
                        </div>

                    </section>
                </section>
            </div>


        </Page>
    )
}
