import Joi from "joi";
import {React, useEffect, useState} from "react";
import {Page} from "../../elements/Pages";

import {CheckCircleIcon, XCircleIcon} from "@heroicons/react/20/solid";
import {useQuery, useQueryClient} from "@tanstack/react-query";
import {useNavigate, useParams} from "react-router-dom";
import {toast} from "react-toastify";
import {
    absenceNoticeApi,
    cancelAbsenceNoticeOccurrence,
    getAbsenceNoticeReasons,
    uncancelAbsenceNoticeOccurrence,
} from "../../../api/logbook";
import {getRecurringRules} from "../../../api/schedule";
import {
    getExamWarningsForAbsenceNotice,
    getStudentInfoAll, getStudentInfoAllWithImages,
    getStudentInfoGrouped,
    getStudentInfoGroupedCourse,
} from "../../../api/school";
import {formatDateTime} from "../../../utils/formatters";
import Form, {baseHandleChange, baseHandleDateChange,} from "../../elements/forms/Form";
import {FormSectionContent} from "../../elements/forms/FormLayout";
import {DateTimeInput, MultiSelect, Select, TextArea,} from "../../elements/forms/Inputs";
import StudentSelector from "./StudentSelector";
import ListViewTable from "../../elements/tables/ListViewTable";

const columns = [
    {
        accessorKey: 'student',
        id: "student",
        header: "SchülerIn",
        enableColumnFilter: false,
    },
    {
        accessorKey: 'exam.courses',
        id: 'course',
        header: 'Kurs',
        columnType: "m2m",
        labelAttr: "school_subject",
        enableColumnFilter: false,
        headerClassNames: "narrow-filter"
    },
    {
        accessorKey: 'exam.teachers',
        id: 'teachers',
        header: 'Fachlehrer',
        columnType: "m2m",
        labelAttr: "full_name",
        enableColumnFilter: false,
        headerClassNames: "narrow-filter"
    },
    {
        accessorKey: 'exam.time',
        id: 'time',
        columnType: "m2m",
        labelAttr: "name",
        header: 'Stunde',
        enableColumnFilter: false,
        headerClassNames: "narrow-filter"
    }
]

export default function AbsenceNoticeCreateEdit() {
    const {id, studentId} = useParams();
    const queryClient = useQueryClient()

    const [data, setData] = useState({
        id: null,
        student_id: studentId ? [studentId.toString()] : [],
        notes: "",
        reason: null,
        rule: null,
        start: null,
        end: null
    });

    const [examWarnings, setExamWarnings] = useState()
    const [studentSelectorOpen, setStudentSelectorOpen] = useState(false)
    const {data: students} = useQuery(["student_info_with_images"], getStudentInfoAllWithImages, {refetchInterval: 1000 * 60 * 60 * 24})
    const {data: reasons} = useQuery(["absence_notice_reasons"], getAbsenceNoticeReasons, {refetchInterval: 1000 * 60 * 60 * 24})
    const {data: rules} = useQuery(["recurring_rules"], getRecurringRules, {refetchInterval: 1000 * 60 * 60 * 24})
    const {data: groups} = useQuery(["student_info_grouped"], getStudentInfoGrouped, {refetchInterval: 1000 * 60 * 60 * 24})
    const {data: courses} = useQuery(["student_info_grouped_course"], getStudentInfoGroupedCourse, {refetchInterval: 1000 * 60 * 60 * 24})

    const [errors, setErrors] = useState({}); // validation errors

    let navigate = useNavigate();

    useEffect(() => {
        loadData();
    }, [id]);

    useEffect(() => {
        loadExamWarnings()
    }, [data.student_id, data.start, data.end])

    async function loadData() {
        if (!id) return;
        const newData = await absenceNoticeApi("get", id);
        setData(newData);
    }

    async function loadExamWarnings() {
        if (!data.student_id.length || !data.start || !data.end) return
        const newData = await getExamWarningsForAbsenceNotice(data.student_id, data.start, data.end)
        setExamWarnings(newData)
    }

    const schema = {
        student_id: Joi.any().required(),
        reason: Joi.object().required(),
        start: Joi.date().required(),
        end: Joi.date().required().min(
            Joi.ref("start", {
                adjust: (value) => (value ? value.setSeconds(1) : new Date(1970, 1, 1)),
            })
        ).messages({"date.min": "Ende muss nach dem Start liegen"})
    }

    const title =
        id || data.id
            ? "Abwesenheitsmeldung bearbeiten"
            : "Neue Abwesenheitsmeldung eintragen";
    const successMessage =
        id || data.id
            ? "Abwesenheitsmeldung aktualisiert"
            : "Neue Abwesenheitsmeldung eingetragen";

    async function onSave() {
        let newData;
        if (!data.id) await absenceNoticeApi("post", null, data);
        else {
            newData = await absenceNoticeApi("patch", null, data);
            setData(newData);
        }
        toast.success(successMessage);
        queryClient.invalidateQueries({ queryKey: ['absence_notices'] })
        navigate(`/logbook/absence-notices`)
    }

    async function onDelete() {
        await absenceNoticeApi("delete", data.id);
        toast.success("Abwesenheitsmeldung gelöscht.");
        navigate(`/logbook/absence-notices`);
    }

    function handleChange(evt) {
        baseHandleChange(evt, data, setData, errors, setErrors, schema);
    }

    function handleDateChange(date, path) {
        baseHandleDateChange(
            date,
            path,
            data,
            setData,
            errors,
            setErrors,
            schema,
            true
        );
    }

    function handleMultiSelectChange(value, fieldKey) {
        setData({
            ...data,
            [fieldKey]: value,
        });
    }

    function handelCancelOccurrence(occ) {
        cancelAbsenceNoticeOccurrence(data.id, occ);
        loadData();
    }

    function handelUncancelOccurrence(occ) {
        uncancelAbsenceNoticeOccurrence(data.id, occ);
        loadData();
    }

    return (
        <>
            {!id && <StudentSelector
                open={studentSelectorOpen}
                setOpen={setStudentSelectorOpen}
                allStudents={students}
                setData={setData}
                data={data}
                path="student_id"
                modalTitle="SchülerInnen auswählen"
                filters={{
                    ...groups,
                    ...courses,
                }}
                parentLabelAttr={{
                    courses: "course_number",
                    houses: "name",
                    school_classes: "group",
                    grades: "name",
                }}
            />}

            <Page title={title}>
                <div
                    className="max-w-3xl mx-auto grid grid-cols-1 gap-6  lg:max-w-7xl lg:grid-flow-col-dense lg:grid-cols-6">
                    <div className="space-y-6 lg:col-span-4">
                        <Form
                            id="createAbsenceNotice"
                            onSave={onSave}
                            onDelete={onDelete}
                            data={data}
                            setData={setData}
                            errors={errors}
                            setErrors={setErrors}
                            successMessage={successMessage}
                            schema={schema}
                        >
                            <FormSectionContent>

                                {id && <p className="sm:col-span-6 font-medium">{data.student?.full_name}</p>}
                                {!id && <MultiSelect
                                    path="student_id"
                                    label="SchülerIn"
                                    className="sm:col-span-6"
                                    options={students}
                                    valueAttr="id"
                                    labelAttr="full_name"
                                    onChange={(value) =>
                                        handleMultiSelectChange(value, "student_id")
                                    }
                                    errors={errors}
                                    data={data}
                                    isClearable={false}
                                    valueOnly={true}
                                />}

                                {!id && <div className="sm:col-span-6 flex">
                                    <button
                                        type="button"
                                        onClick={() => setStudentSelectorOpen(true)}
                                        className="flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-imsblue-600 hover:bg-imsblue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-imsblue-500 mr-3"
                                    >
                                        SchülerInnen auswählen
                                    </button>
                                    {data.student_id.length ? (
                                        <span
                                            className="bg-imsblue-100 text-imsblue-700 px-3 py-2 font-medium text-sm rounded-md"
                                        >
                      <span className="inline-flex justify-center items-center
                      mr-2 w-4 h-4 text-xs font-semibold text-blue-800 bg-blue-200 rounded-full">
                        {data.student_id.length}
                      </span>
                      SchülerInnen ausgewählt
                    </span>
                                    ) : (
                                        ""
                                    )}
                                </div>}

                                <Select
                                    path="reason"
                                    label="Grund"
                                    className="sm:col-span-6"
                                    options={reasons}
                                    valueAttr="id"
                                    labelAttr="name"
                                    onChange={handleChange}
                                    errors={errors}
                                    data={data}
                                    isClearable={false}
                                    valueOnly={false}
                                />

                                <DateTimeInput
                                    className="col-span-3"
                                    path="start"
                                    label="Von"
                                    onChange={handleDateChange}
                                    errors={errors}
                                    data={data}
                                    todayButton={true}
                                    tomorrowButton={true}
                                />

                                <DateTimeInput
                                    className="col-span-3"
                                    path="end"
                                    label="Bis"
                                    onChange={handleDateChange}
                                    errors={errors}
                                    data={data}
                                    todayButton={true}
                                    tomorrowButton={true}
                                    minDate={new Date(data.start) || null}
                                />

                                <Select
                                    path="rule"
                                    label="Wiederholung"
                                    className="sm:col-span-3"
                                    options={rules}
                                    valueAttr="id"
                                    labelAttr="name"
                                    onChange={handleChange}
                                    errors={errors}
                                    data={data}
                                    isClearable={true}
                                    valueOnly={false}
                                />

                                <DateTimeInput
                                    className="col-span-3"
                                    path="end_recurring_period"
                                    label="Ende Wiederholung"
                                    onChange={handleDateChange}
                                    errors={errors}
                                    data={data}
                                    todayButton={false}
                                    tomorrowButton={false}
                                    minDate={new Date(data.start) || null}
                                />

                                <TextArea
                                    path="notes"
                                    type="text"
                                    label="Anmerkungen"
                                    className="sm:col-span-6"
                                    onChange={handleChange}
                                    errors={errors}
                                    data={data}
                                />
                            </FormSectionContent>
                        </Form>

                        <span
                            onClick={() => navigate(-1)}
                            className="cursor-pointer float-right mt-2 font-medium text-imsblue-600"
                        >
              Zurück
            </span>
                    </div>

                    {data.id && (
                        <div className="lg:col-span-2 pl-3">
                            <h3 className="text-lg leading-6 font-medium text-gray-900">
                                Wiederholungen
                            </h3>
                            <p className="mt-1 text-gray-500 py-2">
                                Einzelne Wiederholungen absagen oder wiederherstellen:
                            </p>

                            <ul role="list" className="divide-y space-y-1">
                                {data.future_occurrences &&
                                    data.future_occurrences.map((occ, index) => (
                                        <li key={index} className="flex py-2">
                                            <div
                                                className={
                                                    "text-sm font-medium " +
                                                    (occ.cancelled ? "text-gray-400" : "text-gray-900")
                                                }
                                            >
                                                {occ.cancelled && <span>(Abgesagt) </span>}
                                                {formatDateTime(occ.start)} - {formatDateTime(occ.end)}
                                                {!occ.cancelled && (
                                                    <XCircleIcon
                                                        className="h-5 w-5 text-red-600 inline-flex ml-2 align-middle"
                                                        onClick={() => handelCancelOccurrence(occ)}
                                                    />
                                                )}
                                                {occ.cancelled && (
                                                    <CheckCircleIcon
                                                        className="h-5 w-5 text-green-800 inline-flex ml-2 align-middle"
                                                        onClick={() => handelUncancelOccurrence(occ)}
                                                    />
                                                )}
                                            </div>
                                        </li>
                                    ))}
                            </ul>
                        </div>
                    )}
                </div>

                <div className="mt-4">
                    <div className="sm:flex sm:items-center">
                        <div className="sm:flex-auto">
                            <h3 className="text-lg leading-6 font-medium text-gray-900">
                                Klausuren an diesem Tag
                            </h3>
                        </div>
                    </div>
                    <div className="flex flex-col">
                        <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">

                            <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                                {examWarnings && examWarnings.messages && <ListViewTable data={examWarnings.messages}
                                                                                         columns={columns}
                                                                                         disableGlobalSearch={true}
                                                                                         getRowLink={null}
                                />}
                            </div>
                        </div>
                    </div>
                </div>

            </Page>
        </>
    );
}
