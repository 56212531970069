import React from 'react';
import {Route, Routes} from "react-router-dom";
import ApplicantCreateEdit from "./ApplicantCreateEdit";
import ApplicantList from "./ApplicantList";


function ApplicantsRouter() {

    return (
        <Routes>
            <Route index element={<ApplicantList />}/>
            <Route path="/new" element={<ApplicantCreateEdit />}/>
            <Route path="/:studentId" element={<ApplicantCreateEdit />}/>
        </Routes>
    )
}

export default function Applicants() {
    return <ApplicantsRouter/>
}
