import {NarrowPage} from "../../elements/Pages";
import {React, useEffect, useState} from "react";
import Joi from "joi"
import {useQuery, useQueryClient} from '@tanstack/react-query'
import {DateInput, Input, MultiSelect} from "../../elements/forms/Inputs"
import {FormSectionContent} from "../../elements/forms/FormLayout"
import Form, {baseHandleChange, baseHandleDateChange} from "../../elements/forms/Form"
import {useNavigate, useParams} from "react-router-dom";
import {toast} from "react-toastify";
import {
    addFileToDownload,
    deleteDownload,
    getDownload,
    getDownloadCategories,
    patchDownload,
    postDownload
} from "../../../api/downloads";
import {getGroups} from "../../../api/accounts";


export default function DownloadsCreateEdit() {

    const queryClient = useQueryClient()
    const {id} = useParams()
    const [data, setData] = useState({
        id: null,
        title: "",
        categories: [],
        target_groups: [],
        expiration_date: null
    })

    const {data: categories} = useQuery(['download_categories'], getDownloadCategories, {refetchInterval: 1000 * 60 * 60 * 24})
    const {data: groups} = useQuery(['groups'], getGroups, {refetchInterval: 1000 * 60 * 60 * 24})

    const [selectedFile, setSelectedFile] = useState();
    const [isFilePicked, setIsFilePicked] = useState(false);

    const [errors, setErrors] = useState({}) // validation errors

    let navigate = useNavigate();

    useEffect(() => {
        loadData()
    }, [id])

    async function loadData() {
        if (!id) return
        const newData = await getDownload(id)
        setData(newData)
    }

    let subObjectSchema = Joi.object().keys({
        id: Joi.number().required(),
    })

    const schema = {
        title: Joi.string().min(3),
        categories: Joi.array().items(subObjectSchema).min(1),
        target_groups: Joi.array().items(subObjectSchema).min(1)
    };

    const title = (id || data.id) ? "Download bearbeiten" : "Neuen Download erstellen"
    const successMessage = (id || data.id) ? "Download aktualisiert" : "Neuen Download erstellt"

    async function onSave() {
        // console.log(data)

        // saving the instance first
        let newData
        if (!data.id) newData = await postDownload(data)
        else newData = await patchDownload(data) // TODO

        // then adding the file
        if (isFilePicked) {
            const formData = new FormData();
            formData.append('file', selectedFile);
            newData = await addFileToDownload(formData, newData.id)
        }

        setData(newData)
        toast.success(successMessage)
        queryClient.invalidateQueries({ queryKey: ['downloads'] })
        navigate(-1)
    }

    async function onDelete() {
        await deleteDownload(data.id)
        queryClient.invalidateQueries({ queryKey: ['downloads'] })
        toast.success("Download gelöscht.")
        navigate(`/downloads`)
    }

    function handleChange(evt) {
        baseHandleChange(evt, data, setData, errors, setErrors, schema)
    }

    function handleDateChange(date, path) {
        baseHandleDateChange(date, path, data, setData, errors, setErrors, schema, false)
    }

    const handleChangeFile = (event) => {
        setSelectedFile(event.target.files[0])
        setIsFilePicked(true)
    }

    function handleMultiSelect(selectedOptions, path) {
        let newData = {...data}
        newData[path] = selectedOptions
        setData(newData)
    }

    return (

        <NarrowPage title={title}>

            <Form id="createLogbookEntry" onSave={onSave}
                  onDelete={onDelete}
                  data={data} setData={setData}
                  errors={errors} setErrors={setErrors}
                  successMessage={successMessage}
                  schema={schema}>

                <FormSectionContent>

                    <Input
                        path="title"
                        type="text"
                        label="Titel"
                        className="sm:col-span-6"
                        onChange={handleChange}
                        errors={errors}
                        data={data}
                    />

                    <MultiSelect
                        path="categories"
                        label="Kategorien"
                        className="sm:col-span-6"
                        options={categories}
                        valueAttr="id"
                        labelAttr="name"
                        onChange={(selectedOptions) => handleMultiSelect(selectedOptions, 'categories')}
                        errors={errors}
                        data={data}
                        isClearable={true}
                        valueOnly={false}
                    />

                    <MultiSelect
                        path="target_groups"
                        label="Zielgruppen"
                        className="sm:col-span-6"
                        options={groups}
                        valueAttr="id"
                        labelAttr="name"
                        onChange={(selectedOptions) => handleMultiSelect(selectedOptions, 'target_groups')}
                        errors={errors}
                        data={data}
                        isClearable={true}
                        valueOnly={false}
                    />

                    <DateInput
                        className="col-span-3"
                        path="expiration_date"
                        label="Ablaufdatum"
                        onChange={handleDateChange}
                        errors={errors}
                        data={data}
                        todayButton={true}
                        tomorrowButton={true}
                    />

                    <div className="sm:col-span-6 text-sm">
                        <input type="file" name="file" onChange={handleChangeFile}/> <br/>

                        <div>
                            {/*{selectedFile && <p className="py-1">Ausgewählt: {selectedFile.name}<br/></p>}*/}
                            {data.file && <p className="py-1">Bisherige Datei: {data.file}</p>}
                        </div>
                    </div>

                </FormSectionContent>

            </Form>

            <span onClick={() => navigate("/downloads")}
                  className="cursor-pointer float-right mt-2 font-medium text-imsblue-600">Zurück zur Liste</span>

        </NarrowPage>
    )
}
