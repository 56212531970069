import React from "react";

import {useQuery} from "@tanstack/react-query";
import {Link, useParams} from "react-router-dom";
import {ExportPDFButton, PrimaryButton} from "../../elements/Buttons";
import {getStudierzeitAssessmentsForStudent} from "../../../api/studierzeit";
import {useUserInfo} from "../../../contexts/UserInfoContext";


export default function StudierzeitAssessmentsStudentDetail() {

    const {assessmentPeriodId, studentId} = useParams()
    const {renderUserImage} = useUserInfo()

    const {data} = useQuery(
        ["studierzeit_assessment_period_for_student", assessmentPeriodId, studentId],
        () => getStudierzeitAssessmentsForStudent(assessmentPeriodId, studentId),
        {}
    )

    if (!data) return <></>
    return (

        <>
            <main className="py-10 h-full bg-gray-100">
                {/* Page header */}
                <div
                    className="max-w-3xl mx-auto px-4 sm:px-6 md:flex md:items-center md:justify-between md:space-x-5 lg:max-w-7xl lg:px-8">
                    <div className="flex items-center space-x-5">
                        <div className="flex-shrink-0">
                            <div className="relative">
                                {renderUserImage(data.student.user_id, 80)}
                                <span
                                    className="absolute inset-0 shadow-inner rounded-full"
                                    aria-hidden="true"
                                />
                            </div>
                        </div>
                        <div>
                            <h1 className="text-2xl font-bold text-gray-900">
                                Studierzeitbeurteilung für {data.student.full_name}: {data.assessment_period.name}
                            </h1>
                            <p className="text-sm font-medium text-gray-500">
                                {data.student.student_number}
                            </p>
                        </div>
                    </div>
                    <div
                        className="mt-6 flex flex-col-reverse justify-stretch space-y-4 space-y-reverse sm:flex-row-reverse sm:justify-end sm:space-x-reverse sm:space-y-0 sm:space-x-3 md:mt-0 md:flex-row md:space-x-3">

                        <ExportPDFButton
                            portrait={false}
                            data={
                                <div className="flex flex-col px-2">
                                    <StudierzeitAssessmentStudentDetailContent data={data}
                                                                               exporting={true}/>
                                </div>}
                            fileName={`Studierzeitbeurteilung ${data.student.full_name}.pdf`}
                            label="PDF herunterladen"
                        />

                        <PrimaryButton label="Schülerblatt" link={`/school/students/${studentId}`}/>
                    </div>
                </div>

                <div
                    className="max-w-3xl mx-auto grid grid-cols-1 gap-6 sm:px-6 lg:max-w-7xl lg:grid-flow-col-dense lg:grid-cols-3">
                    <div className="space-y-6 lg:col-start-1 lg:col-span-3">

                        <div className="bg-gray-100 pt-6 px-4 sm:px-6 lg:px-8">
                            <div className="flex flex-col">
                                <StudierzeitAssessmentStudentDetailContent data={data}
                                                                           exporting={false}/>
                            </div>
                        </div>

                    </div>
                </div>


            </main>

        </>
    );
}

function StudierzeitAssessmentStudentDetailContent({data, exporting = false}) {
    return <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div className="inline-block py-2 align-middle md:px-6 lg:px-8 max-w-6xl">
            {exporting && <div>
                <h1 className="text-lg font-medium">
                    Studierzeitbeurteilung für {data.student.full_name}: {data.assessment_period.name}
                </h1>
            </div>}
            {data && data.assessments.map((a, index) => (
                <StudierzeitAssessmentDetail assessment={a}
                                             student={data.student}
                                             key={index}
                />
            ))}
        </div>
    </div>
}


function StudierzeitAssessmentDetail({assessment, student}) {

    return (
        <div className="py-6">
            <div className="bg-white space-y-6">

                <section aria-labelledby="applicant-information-title">
                    <div className="shadow overflow-hidden sm:rounded-lg">

                        <div className="px-4 py-5 sm:px-6">
                            <h3 className="text-lg leading-6 font-medium text-gray-900">
                                <Link to={`/school/students/${student.id}`}
                                      className="text-gray-900">{student.full_name}</Link>
                                , {student.student_number}
                            </h3>
                            <p className="mt-1 max-w-2xl text-sm text-gray-500">
                                {student.school_class}</p>
                        </div>

                        <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
                            <dl className="grid grid-cols-2 gap-x-4 gap-y-8 sm:grid-cols-2">
                                <div className="sm:col-span-1">
                                    <dt className="text-sm font-medium text-gray-500">Arbeitsauffassung</dt>
                                    <dd className="mt-1 text-sm text-gray-900">
                                        {assessment.mark_attitude}
                                    </dd>
                                </div>
                                <div className="sm:col-span-1">
                                    <dt className="text-sm font-medium text-gray-500">Verhalten</dt>
                                    <dd className="mt-1 text-sm text-gray-900">
                                        {assessment.mark_behavior}
                                    </dd>
                                </div>
                            </dl>
                        </div>

                        <div className="border-t border-gray-200 px-4 py-5 sm:p-0">
                            <dl className="sm:divide-y sm:divide-gray-200">
                                <div className="py-4 sm:py-5 sm:grid sm:grid-cols-4 sm:gap-4 sm:px-6">
                                    <dt className="text-sm font-medium text-gray-500 sm:col-span-2">Erstellt von</dt>
                                    <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                        {assessment.created_by?.full_name}
                                    </dd>
                                </div>
                            </dl>
                        </div>
                    </div>
                </section>

            </div>

        </div>

    )
}
