import React from 'react';
import {Route, Routes} from "react-router-dom";
import StudentList from "./StudentList";
import StudentDetail from "./StudentDetail";
import StudentDocumentCreateEdit from "./StudentDocumentCreateEdit";
import NewStudents from "./NewStudents";
import SchoolClassList from "./SchoolClassList";
import SchoolClassDetail from "./SchoolClassDetail";
import MyTeachers from "./MyTeachers";
import TeachersStudentDetail from "./TeachersStudentDetail";
import FacharbeitList from "./FacharbeitList";
import FacharbeitDetail from "./FacharbeitDetail";
import FacharbeitCreateEdit from "./FacharbeitCreateEdit";
import FacharbeitMy from "./FacharbeitMy";
import StudentsWithTerminationDate from "./StudentsWithTerminationDate";
import ReviewParentProfile from "./ReviewParentProfile";
import ExcursionCreateEdit from "./ExcursionCreateEdit";
import ExcursionList from "./ExcursionList";
import ExcursionDetail from "./ExcursionDetail";
import EnrollmentCertificates from "./EnrollmentCertificates";
import StudentLeadershipRoles from "./StudentLeadershipRoles";
import LeadershipRoleStudentEdit from "./LeadershipRoleStudentEdit";
import MyBookCodes from "./MyBookCodes";
import StudentBookCodesDetail from "./StudentBookCodesDetail";


function SchoolRouter() {

    return (
        <Routes>

            <Route path="/students" element={<StudentList/>}/>
            <Route path="/students/:studentId" element={<StudentDetail/>}/>
            <Route path="/students/new-students/" element={<NewStudents/>}/>
            <Route path="/students/with-termination" element={<StudentsWithTerminationDate/>}/>

            <Route path="/students/leadership-roles" element={<StudentLeadershipRoles/>}/>
            <Route path="/students/leadership-roles/edit/:studentId/" element={<LeadershipRoleStudentEdit/>}/>
            <Route path="/students/leadership-roles/new/" element={<LeadershipRoleStudentEdit/>}/>

            <Route path="/student-documents/new/:studentId" element={<StudentDocumentCreateEdit/>}/>
            <Route path="/student-documents/:id/edit" element={<StudentDocumentCreateEdit/>}/>

            <Route path="/school-classes" element={<SchoolClassList/>}/>
            <Route path="/school-classes/:id" element={<SchoolClassDetail/>}/>

            <Route path="/teachers/my" element={<MyTeachers/>}/>
            <Route path="/teachers/students/:studentId" element={<TeachersStudentDetail/>}/>
            <Route path="/students/enrollment-certificate" element={<EnrollmentCertificates/>}/>

            <Route path="/facharbeit" element={<FacharbeitList/>}/>
            <Route path="/facharbeit/:id" element={<FacharbeitDetail/>}/>
            <Route path="/facharbeit/:id/edit" element={<FacharbeitCreateEdit/>}/>
            <Route path="/facharbeit/new/" element={<FacharbeitCreateEdit/>}/>
            <Route path="/facharbeit/my" element={<FacharbeitMy/>}/>

            <Route path="/parent-profiles/:id" element={<ReviewParentProfile/>}/>

            <Route path="/excursions" element={<ExcursionList/>}/>
            <Route path="/excursions/:id" element={<ExcursionDetail/>}/>
            <Route path="/excursions/:id/edit" element={<ExcursionCreateEdit/>}/>
            <Route path="/excursions/new/" element={<ExcursionCreateEdit/>}/>

            <Route path="/book-codes/my" element={<MyBookCodes/>}/>
            <Route path="/book-codes/:studentId" element={<StudentBookCodesDetail/>}/>
        </Routes>
    )
}

export default function School() {

    return <SchoolRouter/>
}
