import React from 'react';
import {Route, Routes} from "react-router-dom";
import QuizRun from "./QuizRun";

function UserQuizRouter() {

    return (
        <Routes>

            {/*
                start new quiz
                study mode
                previous results

            */}

            <Route index element={<QuizRun/>}/>
            {/*<Route path="my" element={<MyTutorings/>}/>*/}
            {/*<Route path="students/:studentId" element={<TutoringList/>}/>*/}

            {/*<Route path=":id" element={<TutoringDetail/>}/>*/}
            {/*<Route path=":id/edit" element={<TutoringEdit/>}/>*/}
            {/*<Route path="new" element={<TutoringCreateMultiple/>}/>*/}
            {/*<Route path="request" element={<TutoringRequest/>}/>*/}

            {/*<Route path=":tutoringId/material/new" element={<MaterialCreateEdit/>}/>*/}
            {/*<Route path="material/:id/edit" element={<MaterialCreateEdit/>}/>*/}
        </Routes>
    )
}


export default function UserQuiz() {
    return <UserQuizRouter/>
}
