import {useParams} from "react-router-dom";
import {React, useEffect, useState} from "react";
import {useQuery} from "@tanstack/react-query";
import {EditButton, PrimaryButton} from "../../elements/Buttons";
import checkPermission from "../../../utils/permissions";
import {useAuth} from "../../../contexts/AuthContext";
import ListViewTable from "../../elements/tables/ListViewTable";
import {DetailPage} from "../../elements/Pages";
import {attendanceApi, careerModuleApi, updateCareerModuleAttendances} from "../../../api/career";
import {formatDate} from "../../../utils/formatters";
import {DeleteButton} from "../../elements/DeleteButton";
import {getStudentInfoAll, getStudentInfoAllWithImages, getStudentInfoGrouped} from "../../../api/school";
import StudentSelector from "../student_logbook/StudentSelector";
import {toast} from "react-toastify";


export default function ModuleDetail() {

    const {id} = useParams();
    const {permissions} = useAuth();
    const [module, setModule] = useState()
    const [data, setData] = useState({});
    const [studentSelectorOpen, setStudentSelectorOpen] = useState(false);

    useEffect(() => {
        loadData();
    }, [id]);

    async function loadData() {
        if (!id) return;
        const newData = await careerModuleApi("get", id);
        setModule(newData);
        setData({...data, student_id: newData.attendances.map(a => a.student.id)})
    }

    const {data: students} = useQuery(["student_info_with_images"], getStudentInfoAllWithImages, {refetchInterval: 1000 * 60 * 60 * 24});
    const {data: groups} = useQuery(
        ["student_info_grouped", 'career'],
        () => getStudentInfoGrouped('career'),
        {refetchInterval: 1000 * 60 * 60 * 24}
    )

    const canEdit = checkPermission(permissions, "career.change_module")
    const canEditAttendance = checkPermission(permissions, "career.change_attendance")
    const canViewAttendances = checkPermission(permissions, "career.view_attendance")

    let attendanceColumns = [
        {
            accessorFn: (row) => row.student,
            id: "student",
            columnType: "student",
            header: "SchülerIn",
            enableColumnFilter: false,
        },
        {
            accessorKey: "student.school_class",
            id: "school_class",
            header: "Klasse",
            enableColumnFilter: false,
            classNames: "max-w-md",
        },
        {
            accessorKey: "creation_date",
            id: "creation_date",
            header: "Eingetragen am",
            columnType: "date",
            enableColumnFilter: false,
            classNames: "text-gray-500",
        }
    ]

    if (canEditAttendance) {
        attendanceColumns.push({
            accessorKey: "id",
            id: "link",
            cell: ({row, value}) => (
                <>
                    <DeleteButton iconOnly={true}
                                  onDelete={() => handleDeleteAttendance(row.original.id)}/>
                </>
            ),
            header: "",
            enableColumnFilter: false,
        })
    }

    async function handleDeleteAttendance(attendanceId) {
        try {
            await attendanceApi('delete', attendanceId)
            const newData = {...module, attendances: module.attendances.filter(row => row.id !== attendanceId)}
            setModule(newData)
            toast.success("Teilnahme gelöscht.")
        } catch (e) {
            toast.error("Beim Speichern ist ein Fehler aufgetreten.")
        }
    }

    async function handleSaveStudents() {

        // save the students & reload
        await updateCareerModuleAttendances(id, data)
        loadData()

        // close the modal
        setStudentSelectorOpen(false)
    }

    if (!module) return <></>;
    return (

        <>
            {canEditAttendance && <StudentSelector
                open={studentSelectorOpen}
                setOpen={setStudentSelectorOpen}
                allStudents={students}
                setData={setData}
                data={data}
                path={"student_id"}
                modalTitle={"SchülerInnen auswählen"}
                filters={{
                    ...groups,
                    //...courses,
                }}
                parentLabelAttr={{
                    courses: "course_number",
                    houses: "name",
                    school_classes: "group",
                    grades: "name"
                }}
                onSaveAndClose={handleSaveStudents}
                selectFromAllStudents={true}
            />}
            <DetailPage>
                <div
                    className="max-w-3xl mx-auto px-4 sm:px-6 md:flex md:items-center md:justify-between md:space-x-5 lg:max-w-7xl lg:px-8">
                    <div className="flex items-center space-x-5">
                        <div>
                            <h1 className="text-2xl font-bold text-gray-900">{module.title}</h1>
                        </div>
                    </div>
                    <div
                        className="mt-6 flex flex-col-reverse justify-stretch space-y-4 space-y-reverse sm:flex-row-reverse sm:justify-end sm:space-x-reverse sm:space-y-0 sm:space-x-3 md:mt-0 md:flex-row md:space-x-3">
                        {canEdit && <EditButton
                            link={`/career/modules/${id}/edit`}
                            label="Bearbeiten"
                        />}
                    </div>
                </div>

                <div
                    className="mt-8 max-w-3xl mx-auto grid grid-cols-1 gap-6 sm:px-6 lg:max-w-7xl lg:grid-flow-col-dense lg:grid-cols-3">
                    <div className="space-y-6 lg:col-start-1 lg:col-span-2">

                        <section aria-labelledby="applicant-information-title">
                            <div className="bg-white shadow overflow-hidden sm:rounded-lg">

                                <div className="border-t border-gray-200 px-4 py-5 sm:p-0">
                                    <dl className="sm:divide-y sm:divide-gray-200">

                                        <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                            <dt className="text-sm font-medium text-gray-500">Kategorie</dt>
                                            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{module.category?.name}
                                            </dd>
                                        </div>
                                        <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                            <dt className="text-sm font-medium text-gray-500">Datum</dt>
                                            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                                {formatDate(module.date_from)} - {formatDate(module.date_to)}
                                            </dd>
                                        </div>
                                        <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                            <dt className="text-sm font-medium text-gray-500">Credits</dt>
                                            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{module.credits}
                                            </dd>
                                        </div>
                                        <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                            <dt className="text-sm font-medium text-gray-500">Zeitaufwand</dt>
                                            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{module.time_needed}
                                            </dd>
                                        </div>
                                        <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                            <dt className="text-sm font-medium text-gray-500">Kosten</dt>
                                            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{module.costs} €
                                            </dd>
                                        </div>
                                        <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                            <dt className="text-sm font-medium text-gray-500">Beschreibung</dt>
                                            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2 whitespace-pre-wrap">{module.description}
                                            </dd>
                                        </div>

                                    </dl>
                                </div>
                            </div>
                        </section>
                        <div className="space-y-6 lg:col-start-1 lg:col-span-3"></div>
                    </div>

                </div>

                <div
                    className="mt-8 max-w-3xl mx-auto grid grid-cols-1 gap-6 sm:px-6 lg:max-w-7xl lg:grid-flow-col-dense lg:grid-cols-3">
                    <div className="space-y-6 lg:col-start-1 lg:col-span-3">

                        {canViewAttendances && <div className="bg-white shadow sm:rounded-lg sm:overflow-hidden">
                            <div className="divide-y divide-gray-200">
                                <div className="px-4 py-5 sm:px-6 flex justify-between">
                                    <h2 id="notes-title" className="text-lg font-medium text-gray-900">
                                        Teilnehmende SchülerInnen
                                    </h2>

                                    {canEditAttendance &&
                                        <PrimaryButton
                                            // link={`/career/modules/${id}/edit`}
                                            onClick={() => setStudentSelectorOpen(true)}
                                            label="Teilnahme hinzufügen/bearbeiten"/>}
                                </div>
                                <div className="">
                                    <ListViewTable
                                        data={module.attendances}
                                        columns={attendanceColumns}
                                        disableGlobalSearch={true}
                                        getRowLink={null}
                                    />
                                </div>
                            </div>
                        </div>}

                    </div>
                </div>

            </DetailPage>
        </>
    );
}
