import {SimplePage} from "../../elements/Pages";
import {React} from "react";
import env from "react-dotenv";
import {useParams} from 'react-router-dom'
import {
    getBerlinListPDF,
    getDepartureList,
    getDepartureListExcel,
    getDepartureListPDF,
    getDepartureWeekend, getHamburgListPDF, getTaxiListPDF
} from "../../../api/boarding_school";
import {formatDate} from "../../../utils/formatters";
import {DownloadButton, EditButton} from "../../elements/Buttons";
import {useQuery} from "@tanstack/react-query";
import ListViewTable from "../../elements/tables/ListViewTable";


const columns = [
    {
        accessorFn: (row) => `${row.student.last_name}, ${row.student.first_name} ${row.student.student_number}`,
        id: "student_search",
        header: "SchülerIn",
        hide: true,
        enableColumnFilter: false,
    },
    {
        accessorKey: "student",
        id: "student",
        header: "SchülerIn",
        columnType: "student",
        enableColumnFilter: false,
        sortingFn: "lastNameSorting",
    },
    {
        accessorKey: "student.house",
        id: "house",
        header: "Mentorat",
        enableColumnFilter: true,
        headerClassNames: "narrow-filter"
    },
    {
        accessorKey: "stays",
        id: "stays",
        header: "Bleibt im Internat",
        columnType: "yesno",
        enableColumnFilter: true,
        headerClassNames: "narrow-filter"
    },
];


const stColumns = [
    {
        accessorKey: "option_departure.name",
        id: "option_departure",
        header: "Abreise",
        enableColumnFilter: true,
        headerClassNames: "narrow-filter"
    },
    {
        accessorKey: "option_arrival.name",
        id: "option_arrival",
        header: "Anreise",
        enableColumnFilter: true,
        headerClassNames: "narrow-filter"
    },
    {
        accessorKey: "departure_time",
        id: "departure_time",
        header: "Fr Bhf",
        enableColumnFilter: true,
        headerClassNames: "narrow-filter"
    },
    {
        accessorKey: "arrival_time",
        id: "arrival_time",
        header: "So Taxi",
        enableColumnFilter: true,
        headerClassNames: "narrow-filter"
    },
    {
        accessorKey: "remarks",
        id: "remarks",
        header: "Anmerkungen",
        enableColumnFilter: false
    }
]

const kpiColumns = [
    {
        accessorKey: "penalty",
        id: "penalty",
        columnType: "yesno",
        header: "Strafe/Silentium",
        enableColumnFilter: true
    }
]

export default function DepartureListDetail() {

    const {weekendId} = useParams()

    const {data: weekend} = useQuery(
        ["departure_weekend", weekendId],
        () => getDepartureWeekend(weekendId),
        {}
    )

    const {isLoading, isError, isSuccess, data, error, refetch} = useQuery(
        ["departure_list_items", weekendId],
        () => getDepartureList(weekendId),
        {}
    )

    const useColumns = env.SCHOOL === 'ST' ? [...columns, ...stColumns] :  [...columns, ...kpiColumns]

    if (!weekend || !data) return <></>

    return (

        <SimplePage>
            <div className="px-4 sm:px-6 lg:px-8">
            <div className="mb-2 sm:flex sm:items-center xl:col-span-3">
                <div className="sm:flex-auto">
                    <h3 className="text-lg leading-6 font-medium text-gray-900">
                        Heimfahrliste {formatDate(weekend.departure_date)} - {formatDate(weekend.arrival_date)}
                    </h3>
                </div>
                <div className="mt-4 sm:mt-0 sm:ml-16 flex gap-2">

                    <EditButton link={`/boarding-school/departure-lists/${weekendId}/edit`} label="Bearbeiten" />

                    <DownloadButton
                        apiFunction={() => getDepartureListExcel(weekendId)}
                        fileName="Heimfahrliste.xls"
                        label="Excel"/>

                    <DownloadButton
                        apiFunction={() => getDepartureListPDF(weekendId)}
                        fileName="Heimfahrliste.pdf"
                        label="PDF"/>

                    <DownloadButton
                        apiFunction={() => getBerlinListPDF(weekendId)}
                        fileName="Berlinbus.pdf"
                        label="Berlin"/>

                    <DownloadButton
                        apiFunction={() => getHamburgListPDF(weekendId)}
                        fileName="Hamburgbus.pdf"
                        label="Hamburg"/>

                    <DownloadButton
                        apiFunction={() => getTaxiListPDF(weekendId)}
                        fileName="Taxiliste.pdf"
                        label="Taxi"/>
                </div>
            </div>

            <div
                className="max-w-full mx-auto grid grid-cols-1 gap-6 lg:grid-flow-col-dense lg:grid-cols-3">
                <div className="space-y-6 lg:col-span-3">
                    <section aria-labelledby="notes-title">

                        <div className="col-span-6 divide-y divide-gray-200">

                            <div className="mt-0">
                                {isSuccess && data && (
                                    <ListViewTable
                                        data={data}
                                        columns={useColumns}
                                        isLoading={isLoading}
                                        disableGlobalSearch={false}
                                        noTopSpace={true}
                                    />
                                )}
                            </div>

                        </div>

                    </section>

                </div>

            </div>
            </div>

        </SimplePage>
    )
}
