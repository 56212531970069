import {SimplePage} from "../../elements/Pages";
import React from "react";

import {useQuery} from "@tanstack/react-query";
import ListViewTable from "../../elements/tables/ListViewTable";
import {DownloadButton} from "../../elements/Buttons";
import {useSchoolYears} from "../../../contexts/SchoolYearContext";
import {useEffect} from "react";
import SchoolYearFilter from "../../elements/SchoolYearFilter";
import {getGivenHomeworkByTeacherReport, getGivenHomeworkByTeacherReportExcel} from "../../../api/reports";
import {useState} from "react";


export default function GivenHomeworkByTeacherReport() {

    const {schoolYears, currentSchoolYear} = useSchoolYears();
    const [schoolYearId, setSchoolYearId] = useState();

    const {isLoading, isError, isSuccess, data, error, refetch} = useQuery(
        ["given_homework_by_teacher_report", schoolYearId],
        () => getGivenHomeworkByTeacherReport(schoolYearId),
        {
            enabled: !!schoolYearId, refetchInterval: 1000 * 60 * 60
        }
    )

    useEffect(() => {
        if (currentSchoolYear) setSchoolYearId(currentSchoolYear["id"]);
    }, [currentSchoolYear])


    let columns = [
        {
            accessorKey: "full_name",
            id: "staff",
            header: "Lehrer",
            enableColumnFilter: false,
        },
        {
            accessorKey: "cre_count",
            id: "cre_count",
            header: "Anzahl KBE",
            enableColumnFilter: false
        },
        {
            accessorKey: "with_homework",
            id: "with_homework",
            header: "mit HA",
            enableColumnFilter: false
        },
        {
            accessorKey: "without_homework",
            id: "without_homework",
            header: "ohne HA",
            enableColumnFilter: false
        },
        {
            accessorKey: "percentage",
            id: "percentage",
            header: "%",
            enableColumnFilter: false
        }
    ]

    return (
        <SimplePage>
            {/*  Simple striped table */}
            <div className="px-4 sm:px-6 lg:px-8">
                <div className="sm:flex sm:items-center">
                    <div className="sm:flex-auto">
                        <h3 className="text-lg leading-6 font-medium text-gray-900">
                            Gegebene Hausaufgaben nach Lehrern
                        </h3>
                    </div>
                    <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
                        <DownloadButton
                            apiFunction={() => getGivenHomeworkByTeacherReportExcel(schoolYearId)}
                            fileName="Gegebene Hausaufgaben nach Lehrern.xls"
                            label="Excel herunterladen"/>

                    </div>
                </div>
                <div className="flex flex-col">
                    <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">

                        <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                            <SchoolYearFilter
                                schoolYears={schoolYears}
                                schoolYearId={schoolYearId}
                                setSchoolYearId={setSchoolYearId}
                            />
                        </div>

                        <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                            {isSuccess && data && (
                                <ListViewTable
                                    data={data}
                                    columns={columns}
                                    isLoading={isLoading}
                                    disableGlobalSearch={false}
                                    getRowLink={null}
                                />
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </SimplePage>
    );
}
