import {SimplePage} from "../../elements/Pages";
import React, {useEffect, useState} from "react";
import ListViewTable, {TableDownloadLink, TableEditLink} from "../../elements/tables/ListViewTable";
import {useParams} from "react-router-dom";
import {getCertificate, getCertificateRemarks, getTransferCertificate} from "../../../api/certificates";
import {ExportPDFButton} from "../../elements/Buttons";


export default function ClassTeacherDetail() {

    const {assessmentPeriodId, classTeacherId} = useParams()
    const [data, setData] = useState()

    useEffect(() => {
        loadData()
    }, [])

    async function loadData() {
        const newData = await getCertificateRemarks(assessmentPeriodId, classTeacherId)
        setData(newData)
    }

    const columnsViewOnly = [
        {
            accessorFn: (row) => `${row.student.last_name}, ${row.student.first_name} ${row.student.student_number}`,
            id: "student",
            header: "SchülerIn",
            enableColumnFilter: false,
        },
        {
            accessorKey: "student.school_class",
            id: "school_class",
            header: "Klasse",
            enableColumnFilter: true,
            classNames: "text-gray-500",
            headerClassNames: "narrow-filter"
        },
        {
            accessorKey: "remarks",
            id: "remarks",
            header: "Zeugnissbemerkung",
            enableColumnFilter: false,
            classNames: "max-w-md"
        }
    ]

    if (data) {
        // add conduct grade definitions to the columns
        for (var i = 0; i < data.conduct_grade_definitions.length; i++) {
            columnsViewOnly.push({
                accessorKey: "final_conduct_grades_list." + data.conduct_grade_definitions[i].id,
                id: data.conduct_grade_definitions[i].id,
                header: data.conduct_grade_definitions[i].name,
                enableColumnFilter: false,
            })
        }
    }

    // columnsViewOnly.push({
    //     accessorKey: "id",
    //     id: "pdf",
    //     cell: ({row, value}) => (
    //         <div className="flex gap-2 justify-between">
    //             <TableDownloadLink
    //                 label="Zeugnis"
    //                 fileName={`Zeugnis ${row.original.student.full_name}.pdf`}
    //                 apiFunction={() => getCertificate(assessmentPeriodId, row.original.student.id)}/>
    //             <TableDownloadLink
    //                 label="(Ü)"
    //                 fileName={`Übergangszeugnis ${row.original.student.full_name}.pdf`}
    //                 apiFunction={() => getTransferCertificate(assessmentPeriodId, row.original.student.id)}/>
    //         </div>
    //     ),
    //     header: "Zeugnis",
    //     enableColumnFilter: false,
    //     disableRowLink: true
    // })
    columnsViewOnly.push({
        accessorKey: "student.id",
        id: "link",
        cell: (info) => (
            <TableEditLink
                iconOnly={true}
                label="Bearbeiten"
                to={`/certificates/assessment-periods/${assessmentPeriodId}/certificate-remarks/students/${info.getValue()}/edit`}
            />
        ),
        header: "",
        disableRowLink: true,
        enableColumnFilter: false,
    })

    let pdfColumns = [
        {
            accessorFn: (row) => `${row.student.last_name}, ${row.student.first_name} ${row.student.student_number}`,
            id: "student",
            header: "SchülerIn",
            enableColumnFilter: false,
        },
        {
            accessorKey: "student.school_class",
            id: "school_class",
            header: "Klasse",
            enableColumnFilter: false,
            classNames: "text-gray-500",
            headerClassNames: "narrow-filter"
        },
        {
            accessorKey: "remarks",
            id: "remarks",
            header: "Zeugnissbemerkung",
            enableColumnFilter: false,
            classNames: "max-w-md"
        }
    ]

    if (data) {
        // add conduct grade definitions to the columns
        for (var i = 0; i < data.conduct_grade_definitions.length; i++) {
            pdfColumns.push({
                accessorKey: "final_conduct_grades_list." + data.conduct_grade_definitions[i].id,
                id: data.conduct_grade_definitions[i].id,
                header: data.conduct_grade_definitions[i].name,
                enableColumnFilter: false,
            })
        }
    }

    if (!data) return <></>;

    const exportPDFData = <ClassTeacherDetailPDF rows={data} columns={pdfColumns}/>

    return (
        <SimplePage>
            <div className="px-4 sm:px-6 lg:px-8">
                <div className="sm:flex sm:items-center xl:col-span-3">
                    <div className="sm:flex-auto">
                        <h3 className="text-lg leading-6 font-medium text-gray-900">
                            Kopfnoten & Zeugnissätze {data?.assessment_period?.name}
                        </h3>
                    </div>
                    <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
                        {data && data.students && <ExportPDFButton
                            portrait={false}
                            data={exportPDFData}
                            fileName={`Kopfnoten & Zeugnissätze.pdf`}
                            label="PDF herunterladen"
                        />}

                        {/*<EditButton label="Alle Zeugnissätze bearbeiten" onClick={() => setEditing(!editing)} />*/}
                    </div>
                </div>
                <div className="mt-4 flex flex-col xl:col-span-2">
                    <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
                        <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">

                            {data &&
                                <ListViewTable data={data.students}
                                               columns={columnsViewOnly}/>}

                        </div>
                    </div>
                </div>
            </div>
        </SimplePage>
    );
}

function ClassTeacherDetailPDF({rows, columns}) {

    return <>

        <div className="px-4 sm:px-6 lg:px-8">
            <div className="sm:flex sm:items-center xl:col-span-3">
                <div className="sm:flex-auto">
                    <h3 className="text-lg leading-6 font-medium text-gray-900">
                        Kopfnoten & Zeugnissätze {rows?.assessment_period?.name}
                    </h3>
                </div>
            </div>
            <div className="mt-4 flex flex-col xl:col-span-2">
                <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
                    <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                        {rows && rows.students &&
                            <ListViewTable data={rows.students}
                                           disableGlobalSearch={true}
                                           columns={columns} />}

                    </div>
                </div>
            </div>
        </div>
    </>

}