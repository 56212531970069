import {SimplePage} from "../../elements/Pages";
import React, {useState} from "react";

import {useQuery} from "@tanstack/react-query";
import {useAuth} from "../../../contexts/AuthContext";
import ListViewTable, {TableEditLink, TwoLineColumn,} from "../../elements/tables/ListViewTable";
import checkPermission from "../../../utils/permissions";
import {classNames} from "../../../utils/misc";
import {getRepairOrders, repairOrderApi} from "../../../api/maintenance";
import {DeleteButton} from "../../elements/DeleteButton";
import {PrimaryButton} from "../../elements/Buttons";
import {toast} from "react-toastify";


export default function RepairOrders() {

    const {permissions} = useAuth();
    const [incompleteOrAll, setIncompleteOrAll] = useState("incomplete");

    const {isLoading, isError, isSuccess, data, error, refetch} = useQuery(
        ["repair_orders", incompleteOrAll],
        () => getRepairOrders(incompleteOrAll),
        {}
    );

    function getRowLink(row) {
        return `/maintenance/repair-orders/${row.original.id}/`;
    }

    async function handleDelete(repairOrderId) {
        try {
            await repairOrderApi("delete", repairOrderId)
            refetch()
            toast.success("Auftrage gelöscht")
        } catch (e) {
            toast.error("Beim Speichern ist ein Fehler aufgetreten.")
        }
    }

    const canEdit = checkPermission(permissions, "maintenance.change_repairorder")
    const canAdd = checkPermission(permissions, "maintenance.add_repairorder")

    const columns = [
        {
            accessorKey: "title",
            id: "title",
            header: "Titel",
            cell: ({row, value}) => (
                <TwoLineColumn
                    line1={row.original.title}
                    line2={row.original.order}
                />),
            enableColumnFilter: false,
            classNames: "text-gray-500 min-w-lg",
        },
        {
            accessorKey: "location",
            id: "location",
            header: "Ort",
            enableColumnFilter: false,
            disableRowLink: true
        },
        {
            accessorKey: "category.name",
            id: "category",
            header: "Kategorie",
            enableColumnFilter: true,
            disableRowLink: false,
            filterFn: "containsString",
            classNames: "max-w-md",
            headerClassNames: "narrow-filter"
        },
        {
            accessorKey: "creation_date",
            id: "creation_date",
            header: "Erstellt am",
            columnType: "date",
            enableColumnFilter: false,
        },
        {
            accessorKey: "created_by_full_name",
            id: "created_by_full_name",
            header: "Erstellt von",
            enableColumnFilter: true,
            filterFn: "containsString",
            headerClassNames: "narrow-filter"
        },
        {
            accessorKey: "assigned_to",
            id: "assigned_to",
            header: "Zugewiesen an",
            columnType: "m2m",
            labelAttr: "full_name",
            enableColumnFilter: false,
            headerClassNames: "narrow-filter"
        },
        {
            accessorKey: "in_progress_since",
            id: "in_progress_since",
            header: "In Bearbeitung seit",
            columnType: "date",
            enableColumnFilter: false,
        },
        {
            accessorKey: "completed_date",
            id: "completed_date",
            header: "Erledigt am",
            columnType: "date",
            enableColumnFilter: false,
        },
        // {
        //     accessorKey: "completed_remarks",
        //     id: "completed_remarks",
        //     header: "Bemerkungen",
        //     enableColumnFilter: false,
        // },
        ...canEdit ? [
            {
                accessorKey: "id",
                id: "link",
                cell: (info) => (
                    <div className="flex gap-2 items-center">
                        <TableEditLink iconOnly={true}
                                       label="Bearbeiten"
                                       to={`/maintenance/repair-orders/${info.getValue()}/edit`}/>
                        <DeleteButton onDelete={() => handleDelete(info.getValue())} label={"Löschen"} iconOnly={true}/>
                    </div>
                ),
                header: "",
                enableColumnFilter: false,
                disableRowLink: true
            }
        ] : []
    ];

    return (
        <SimplePage title="Reparaturaufträge">

            <div className="px-4 sm:px-6 lg:px-8">
                <div className="sm:flex sm:items-center">
                    <div className="sm:flex-auto">
                        <h3 className="text-lg leading-6 font-medium text-gray-900">
                            Reparaturaufträge
                        </h3>
                    </div>
                    <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
                        {canAdd &&
                            <PrimaryButton label="Neuer Reparaturauftrag" link="/maintenance/repair-orders/new"/>}
                    </div>
                </div>
                <div className="flex flex-col">
                    <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
                        <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                            <nav className="flex space-x-4 mt-2" aria-label="Tabs">
                                <a
                                    key="incomplete"
                                    onClick={() => setIncompleteOrAll("incomplete")}
                                    className={classNames(
                                        incompleteOrAll === "incomplete"
                                            ? "bg-imsblue-100 text-imsblue-700"
                                            : "text-gray-500 hover:text-gray-700",
                                        "px-3 py-2 font-medium text-sm rounded-md cursor-pointer"
                                    )}
                                    aria-current={incompleteOrAll === "incomplete" ? "page" : undefined}
                                >
                                    Offen
                                </a>
                                <a
                                    key="all"
                                    onClick={() => setIncompleteOrAll("all")}
                                    className={classNames(
                                        incompleteOrAll === "all"
                                            ? "bg-imsblue-100 text-imsblue-700"
                                            : "text-gray-500 hover:text-gray-700",
                                        "px-3 py-2 font-medium text-sm rounded-md cursor-pointer"
                                    )}
                                    aria-current={incompleteOrAll === "all" ? "page" : undefined}
                                >
                                    Alle
                                </a>
                            </nav>
                        </div>

                        <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                            <div className="flex flex-col">
                                {isSuccess && data && (
                                    <ListViewTable
                                        data={data}
                                        columns={columns}
                                        isLoading={isLoading}
                                        disableGlobalSearch={false}
                                        getRowLink={getRowLink}
                                    />
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </SimplePage>
    )
}
