import {useParams} from "react-router-dom";
import {React} from "react";
import {useQuery} from "@tanstack/react-query";
import {DownloadButton} from "../../elements/Buttons";
import {useAuth} from "../../../contexts/AuthContext";
import ListViewTable from "../../elements/tables/ListViewTable";
import {CheckCircleIcon, XMarkIcon} from "@heroicons/react/20/solid";
import {formatDateTime} from "../../../utils/formatters";
import {getStudentDetail, getStudentDetailPDF} from "../../../api/workshop_day";
import {useUserInfo} from "../../../contexts/UserInfoContext";


export default function StudentDetail() {

    const {workshopDayId, studentId} = useParams();
    const {permissions} = useAuth();
    const {renderUserImage} = useUserInfo();

    const {isLoading, isError, isSuccess, data, error, refetch} = useQuery(
        ["workshop_day_student_detail", workshopDayId, studentId],
        () => getStudentDetail(workshopDayId, studentId),
        {}
    );

    let columns = [
        {
            accessorFn: (row) => `${formatDateTime(row.workshop.datetime_from)} - ${formatDateTime(row.workshop.datetime_to)}`,
            id: "date",
            header: "Datum",
            enableColumnFilter: false,
        },
        {
            accessorKey: "workshop.title",
            id: "title",
            header: "Modul",
            enableColumnFilter: false,
        },
        {
            accessorKey: "workshop.category.title",
            id: "category",
            header: "Kategorie",
            enableColumnFilter: false,
        },
        {
            accessorKey: "workshop.operator",
            id: "operator",
            header: "Referent",
            enableColumnFilter: false,
        },
        {
            accessorKey: "workshop.room.name",
            id: "room",
            header: "Raum",
            enableColumnFilter: false,
        }
    ]

    function getRowLink(row) {
        return `/workshop-day/workshops/${row.original.workshop.id}/`;
    }

    if (!data) return <></>;
    return (
        <>
            <main className="py-10 h-full bg-gray-100">
                {/* Page header */}
                <div
                    className="max-w-3xl mx-auto px-4 sm:px-6 md:flex md:items-center md:justify-between md:space-x-5 lg:max-w-7xl lg:px-8">
                    <div className="flex items-center space-x-5">
                        <div className="flex-shrink-0">
                            <div className="relative">
                                {renderUserImage(data.student.user_id, 80)}
                                <span
                                    className="absolute inset-0 shadow-inner rounded-full"
                                    aria-hidden="true"
                                />
                            </div>
                        </div>
                        <div>
                            <h1 className="text-2xl font-bold text-gray-900">
                                Buchungen von {data.student.full_name} {data.workshop_day.title}
                            </h1>
                            <p className="text-sm font-medium text-gray-500">
                                {data.student.student_number}
                            </p>
                        </div>
                    </div>
                    <div
                        className="mt-6 flex flex-col-reverse justify-stretch space-y-4 space-y-reverse sm:flex-row-reverse sm:justify-end sm:space-x-reverse sm:space-y-0 sm:space-x-3 md:mt-0 md:flex-row md:space-x-3">

                        <DownloadButton
                            apiFunction={() => getStudentDetailPDF(workshopDayId, studentId)}
                            fileName={`${data.workshop_day.title} ${data.student.full_name}.pdf`}
                            label="PDF herunterladen"/>
                    </div>
                </div>

                <div
                    className="mt-8 max-w-3xl mx-auto grid grid-cols-1 gap-6 sm:px-6 lg:max-w-7xl lg:grid-flow-col-dense lg:grid-cols-4">

                    <div className="space-y-6 lg:col-start-1 lg:col-span-3">

                        <section aria-labelledby="notes-title">
                                <ListViewTable
                                    data={data.bookings}
                                    columns={columns}
                                    disableGlobalSearch={true}
                                    getRowLink={null}
                                    getRowLink={getRowLink}
                                />
                        </section>

                        {/* Table */}
                        <section aria-labelledby="applicant-information-title"></section>
                    </div>

                    <section aria-labelledby="timeline-title" className="lg:col-span-1 mt-6">
                        <section aria-labelledby="applicant-information-title">
                            <div className="bg-white shadow overflow-hidden sm:rounded-lg">

                                <div className="px-4 py-5 sm:px-6">
                                    <h3 className="text-lg leading-6 font-medium text-gray-900">
                                        Buchungsstatus
                                    </h3>
                                </div>
                                <div className="border-t border-gray-200 px-4 py-5">

                                    {data.requirements_fulfilled &&
                                        <div className="flex content-center">
                                            <CheckCircleIcon className="h-5 w-5 text-green-400 mr-1"
                                                             aria-hidden="true"/>
                                            Regeln erfüllt
                                        </div>}

                                    {!data.requirements_fulfilled &&
                                        <div className="flex align-middle content-center text-red-400">
                                            <XMarkIcon className="h-5 w-5  mr-1" aria-hidden="true"/>
                                            Regeln nicht erfüllt
                                        </div>}

                                    {!data.conflicts &&
                                        <div className="flex">
                                            <CheckCircleIcon className="h-5 w-5 text-green-400 mr-1"
                                                             aria-hidden="true"/>
                                            Keine Konflikte
                                        </div>}

                                    {data.conflicts &&
                                        <div className="flex text-red-500 ">
                                            <XMarkIcon className="h-5 w-5mr-1" aria-hidden="true"/>
                                            Konflikte bei der Belegung
                                        </div>}


                                </div>
                            </div>
                        </section>
                    </section>
                </div>

            </main>
        </>
    )
}

