import Joi from "joi";
import {React, useEffect, useState} from "react";
import {Page} from "../../elements/Pages";
import {useNavigate, useParams} from "react-router-dom";
import {toast} from "react-toastify";
import Form, {baseHandleChange,} from "../../elements/forms/Form";
import {FormSectionContent} from "../../elements/forms/FormLayout";
import {Checkbox, Input, Select, TextArea,} from "../../elements/forms/Inputs";
import {approvePurchaseRequest, purchaseRequestApi, rejectPurchaseRequest} from "../../../api/organizational";
import {DangerButton, SuccessButton} from "../../elements/Buttons";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import checkPermission from "../../../utils/permissions";
import {useAuth} from "../../../contexts/AuthContext";
import {formatDate} from "../../../utils/formatters";
import {useQuery, useQueryClient} from "@tanstack/react-query";
import {getStaffList} from "../../../api/staff";


export default function PurchaseRequestCreateEdit() {

    const {id} = useParams();

    const [options, setOptions] = useState();
    const [data, setData] = useState({});
    const [errors, setErrors] = useState({}); // validation errors
    const queryClient = useQueryClient()

    const {permissions, currentUser} = useAuth()
    const canChangePurchaseRequest = checkPermission(permissions, "organizational.change_purchaserequest")

    const {data: staff} = useQuery(['staff'], getStaffList, {refetchInterval: 1000 * 60 * 60 * 24})

    let navigate = useNavigate();

    useEffect(() => {
        loadData();
    }, [id]);

    async function loadData() {
        // get options
        const newOptions = await purchaseRequestApi('options')
        setOptions(newOptions)

        if (!id) return
        const newData = await purchaseRequestApi("get", id)
        setData(newData)
    }

    const schema = {
        title: Joi.string().required(),
        reason_choice: Joi.string().required(),
        purchase_by: Joi.string().required(),
        costs: Joi.number().required()
    }

    const title =
        id || data.id
            ? "Anschaffungsantrag bearbeiten"
            : "Anschaffungsantrag erstellen";
    let successMessage = "Anschaffungsantrag gespeichert"

    async function onSave() {
        let newData
        try {
            if (!data.id) newData = await purchaseRequestApi("post", null, data)
            else newData = await purchaseRequestApi("patch", null, data)
            setData(newData)
            queryClient.invalidateQueries({ queryKey: ["purchase_requests", currentUser.user_id] })
            toast.success(successMessage)
            navigate(`/service/purchase-requests`)
        } catch (e) {
            toast.error(e)
        }
    }

    async function onDelete() {
        await purchaseRequestApi("delete", data.id);
        toast.success("Anschaffungsantrag gelöscht.");
        navigate(`/service/purchase-requests`)
    }

    async function onApprove() {
        try {
            const newData = await approvePurchaseRequest(data.id);
            setData(newData);
            toast.success("Anschaffungsantrag genehmigt.");
            navigate(`/service/purchase-requests`)
        } catch (e) {
            toast.error(e)
        }
    }

    async function onReject() {
        try {
            const newData = await rejectPurchaseRequest(data.id);
            setData(newData);
            toast.success("Anschaffungsantrag abgelehnt.");
            navigate(`/service/purchase-requests`)
        } catch (e) {
            toast.error(e)
        }
    }

    function handleChange(evt) {
        baseHandleChange(evt, data, setData, errors, setErrors, schema);
    }

    if (!data || !options) return <></>
    return (
        <>
            <Page title={title}>
                <div
                    className="max-w-3xl mx-auto grid grid-cols-1 gap-6  lg:max-w-7xl lg:grid-flow-col-dense lg:grid-cols-6">
                    <div className="space-y-6 lg:col-span-4">
                        <Form
                            id="createPurchaseRequest"
                            onSave={onSave}
                            onDelete={onDelete}
                            data={data}
                            setData={setData}
                            errors={errors}
                            setErrors={setErrors}
                            successMessage={successMessage}
                            schema={schema}
                        >
                            <FormSectionContent>

                                <Input
                                    path="title"
                                    type="text"
                                    label="Titel"
                                    className="sm:col-span-6"
                                    onChange={handleChange}
                                    errors={errors}
                                    data={data}
                                />

                                <TextArea
                                    path="description"
                                    label="Beschreibung"
                                    className="sm:col-span-6"
                                    onChange={handleChange}
                                    errors={errors}
                                    data={data}
                                    rows={3}
                                />


                                <Select
                                    path="reason_choice"
                                    label="Grund"
                                    className="sm:col-span-3"
                                    options={options.reason_choice.choices}
                                    valueAttr="value"
                                    labelAttr="display_name"
                                    onChange={handleChange}
                                    errors={errors}
                                    data={data}
                                    isClearable={false}
                                    valueOnly={true}
                                />

                                <Input
                                    path="reason_text"
                                    type="text"
                                    label="Details (Fach, Projekt, Mentorat etc.)"
                                    className="sm:col-span-3"
                                    onChange={handleChange}
                                    errors={errors}
                                    data={data}
                                />

                                <h3 className="font-medium sm:col-span-6">Kosten</h3>

                                <Input
                                    path="costs"
                                    type="number"
                                    label="Kosten"
                                    className="sm:col-span-2"
                                    onChange={handleChange}
                                    errors={errors}
                                    data={data}
                                />

                                <Select
                                    path="cost_type"
                                    label="Einmalig/regelmäßig"
                                    className="sm:col-span-2"
                                    options={options.cost_type.choices}
                                    valueAttr="value"
                                    labelAttr="display_name"
                                    onChange={handleChange}
                                    errors={errors}
                                    data={data}
                                    isClearable={false}
                                    valueOnly={true}
                                />

                                <Input
                                    path="cost_details"
                                    type="text"
                                    label="Details (von/bis etc.)"
                                    className="sm:col-span-2"
                                    onChange={handleChange}
                                    errors={errors}
                                    data={data}
                                />

                                <Checkbox
                                    path="cost_allocation_to_students"
                                    label="Umlage auf SchülerInnen"
                                    className="sm:col-span-6"
                                    onChange={handleChange}
                                    errors={errors}
                                    hideLegend={true}
                                    data={data}/>


                                <Select
                                    path="purchase_by"
                                    label="Anschaffung durch"
                                    className="sm:col-span-3"
                                    options={options.purchase_by.choices}
                                    valueAttr="value"
                                    labelAttr="display_name"
                                    onChange={handleChange}
                                    errors={errors}
                                    data={data}
                                    isClearable={false}
                                    valueOnly={true}
                                />

                                <Input
                                    path="purchase_by_notes"
                                    type="text"
                                    label="Details"
                                    className="sm:col-span-3"
                                    onChange={handleChange}
                                    errors={errors}
                                    data={data}
                                />

                                <Select
                                    path="responsible_for_purchase"
                                    label="Zuständig für Bestellung"
                                    className="sm:col-span-6"
                                    options={staff}
                                    valueAttr="id"
                                    labelAttr="full_name"
                                    onChange={handleChange}
                                    errors={errors}
                                    data={data}
                                    isClearable={false}
                                    valueOnly={false}
                                />

                                <Checkbox
                                    path="has_been_ordered"
                                    label="Bestellt"
                                    className="sm:col-span-6"
                                    onChange={handleChange}
                                    errors={errors}
                                    hideLegend={true}
                                    data={data}/>


                                {canChangePurchaseRequest && data.id && <TextArea
                                    path="remarks"
                                    label="Bemerkungen"
                                    className="sm:col-span-6"
                                    onChange={handleChange}
                                    errors={errors}
                                    data={data}
                                    rows={3}
                                />}

                                {data.created_by && <p className="mt-4 text-md sm:col-span-6">
                                    Beantragt von {data.created_by_full_name} am {formatDate(data.creation_date)}
                                </p>}

                            </FormSectionContent>


                        </Form>


                        {canChangePurchaseRequest && data.id &&
                            <div className="approveRejectButtons justify-end flex gap-2 mt-4 mb-2">

                                {data.status !== 'APPROVED' && <SuccessButton
                                    label={"Antrag genehmigen"}
                                    onClick={onApprove}
                                />}
                                {data.status !== 'REJECTED' && <DangerButton
                                    onClick={onReject}
                                    label="Antrag ablehnen"
                                />}
                            </div>
                        }
                        <span
                            onClick={() => navigate(-1)}
                            className="cursor-pointer float-right mt-2 font-medium text-imsblue-600"
                        >Zurück</span>
                    </div>

                    <div className="lg:col-span-2 pl-3 sm:max-h-[80%] min-h-0 flex-1 overflow-y-auto">
                    </div>

                </div>
            </Page>
        </>
    );
}

