import {SimplePage} from "../../elements/Pages";
import React, {useState} from "react";

import {useQuery} from "@tanstack/react-query";
import ListViewTable from "../../elements/tables/ListViewTable";
import {
    assessmentPeriodApi,
    finalizeOpenAssessments,
    getCoursesForAssessmentPeriod,
    getOpenAssessments
} from "../../../api/assessments";
import {useParams} from "react-router-dom";
import AssessmentDetail from "./AssessmentDetail";
import checkPermission from "../../../utils/permissions";
import {useAuth} from "../../../contexts/AuthContext";
import {PrimaryButton} from "../../elements/Buttons";
import Alert from "../../elements/Alert";

const columns = [
    {
        accessorKey: "course_number",
        id: "course_number",
        header: "Nr.",
        enableColumnFilter: false,
    },
    {
        accessorKey: "school_subject",
        id: "school_subject",
        header: "Fach",
        enableColumnFilter: false,
    },
    {
        accessorFn: (row) => `${row.last_name}, ${row.first_name}`,
        id: "teacher",
        header: "LehrerIn",
        enableColumnFilter: true,
        filterFn: "containsString",
        labelAttr: "full_name",
    },
    {
        accessorKey: "finalized",
        id: "finalized",
        header: "abgeschl.",
        enableColumnFilter: false,
    },
    {
        accessorKey: "open",
        id: "open",
        header: "offen",
        enableColumnFilter: false,
    },
    {
        accessorKey: "missing",
        id: "missing",
        header: "fehl.",
        enableColumnFilter: false,
    },
    {
        accessorKey: "num_students",
        id: "num_students",
        header: "Schüler",
        enableColumnFilter: false,
    }
];

export default function OpenAssessments() {

    const {assessmentPeriodId} = useParams()
    const {permissions} = useAuth()
    const [response, setResponse] = useState()

    const {data: assessmentPeriod} = useQuery(
        ["assessment_periods", assessmentPeriodId],
        () => assessmentPeriodApi('get', assessmentPeriodId),
        {refetchInterval: 1000 * 60 * 60 * 24}
    );

    const {isLoading, isError, isSuccess, data, error, refetch} = useQuery(
        ["open_assessments", assessmentPeriodId],
        () => getOpenAssessments(assessmentPeriodId),
        {}
    );

    const canEdit = checkPermission(permissions, 'assessments.change_assessment')

    async function handleFinalizeAll() {
        const resp = await finalizeOpenAssessments(assessmentPeriodId)
        setResponse(resp)
        refetch()
    }

    return (
        <SimplePage>
            <div className="px-4 sm:px-6 lg:px-8">
                <div className="sm:flex sm:items-center">
                    <div className="sm:flex-auto">
                        <h3 className="text-lg leading-6 font-medium text-gray-900 mb-2">
                            Offene Beurteilungen für {assessmentPeriod?.name}
                        </h3>
                    </div>
                    <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
                    </div>
                </div>
                <div className="flex flex-col">
                    <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">

                        <div className="inline-block py-2 align-middle mx-auto md:px-6 lg:px-8 flex items-center gap-2">

                            <PrimaryButton label="Alle offenen Beurteilungen abschließen"
                                           onClick={handleFinalizeAll}
                            />
                            <div className="my-2">{response && <Alert variant="info">{response}</Alert>}</div>
                        </div>
                        <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                            {data && data.map((a, index) => (
                                <AssessmentDetail assessment={a} inStudent={true} inCourse={true} key={index} index={index} canEdit={canEdit} />
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </SimplePage>
    );
}
