import {SimplePage} from "../../elements/Pages";
import React from "react";

import {useQuery} from "@tanstack/react-query";
import {useAuth} from "../../../contexts/AuthContext";
import ListViewTable, {TableLink} from "../../elements/tables/ListViewTable";
import checkPermission from "../../../utils/permissions";
import {Link} from "react-router-dom";
import {getFlextimeBalance, getFlextimeEntries} from "../../../api/staff";

const columns = [
    {
        accessorKey: "creation_date",
        id: "creation_date",
        header: "Erstellt am",
        columnType: "date",
        enableColumnFilter: false,
        // classNames: 'font-medium text-gray-900'
        classNames: "text-gray-500",
    },
    {
        accessorKey: "year",
        id: "year",
        header: "Jahr",
        enableColumnFilter: false,
    },
    {
        accessorKey: "entry_type",
        id: "entry_type",
        header: "Art",
        enableColumnFilter: true,
        headerClassNames: "narrow-filter",
    },
    {
        accessorKey: "staff_role",
        id: "staff_role",
        header: "Funktion",
        enableColumnFilter: true,
        headerClassNames: "narrow-filter",
    },
    {
        accessorKey: "category.title",
        id: "category",
        header: "Kategorie",
        enableColumnFilter: true,
        headerClassNames: "medium-filter",
    },
    {
        accessorKey: "title",
        id: "title",
        header: "Bezeichnung",
        enableColumnFilter: false,
        headerClassNames: "narrow-filter",
    },
    {
        accessorKey: "get_status_display",
        id: "status",
        header: "Status",
        enableColumnFilter: true,
        headerClassNames: "medium-filter",
    },
    // {
    //     accessorKey: "id",
    //     id: "link",
    //     cell: (info) => (
    //         <TableLink
    //             label="Bearbeiten"
    //             to={`/staff/flextime/entries/${info.getValue()}/edit`}
    //         />
    //     ),
    //     header: "Bearbeiten",
    //     enableColumnFilter: false,
    //     disableRowLink: true
    // },
];

export default function FlextimeMyEntries() {
    const {permissions, currentUser} = useAuth();

    const {isLoading, isError, isSuccess, data, error, refetch} = useQuery(
        ["my_flextime_entries", currentUser.user_id],
        () => getFlextimeEntries(),
        {refetchInterval: 1000 * 60 * 60}
    );
    const {data: flexTimeBalance} = useQuery(
        ["flextime_balance", currentUser.staff_id],
        () => getFlextimeBalance(currentUser.staff_id),
        {
            enabled: currentUser.staff_id != null,
        }
    );

    function getRowLink(row) {
        return `/staff/flextime/entries/${row.original.id}/edit`;
    }

    if (!data) return <></>;

    return (
        <SimplePage>
            {/*  Simple striped table */}
            <div className="px-4 sm:px-6 lg:px-8">
                <div className="sm:flex sm:items-center">
                    <div className="sm:flex-auto">
                        <h3 className="text-lg leading-6 font-medium text-gray-900">
                            Meine Flextime
                        </h3>
                        {flexTimeBalance && currentUser && <p className="mt-1 text-md">
                            Kontostand: {flexTimeBalance}
                        </p>}
                    </div>
                    <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
                        {currentUser.staff_id && (
                            <Link to={`/staff/flextime/entries/new/${currentUser.staff_id}`}>
                                <button
                                    type="button"
                                    className="inline-flex items-center justify-center rounded-md border border-transparent bg-imsblue-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-imsblue-700 focus:outline-none focus:ring-2 focus:ring-imsblue-500 focus:ring-offset-2 sm:w-auto"
                                >
                                    Neuer Eintrag
                                </button>
                            </Link>
                        )}
                    </div>
                </div>
                <div className="flex flex-col">
                    <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
                        <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                            {isSuccess && data && (
                                <ListViewTable
                                    data={data}
                                    columns={columns}
                                    isLoading={isLoading}
                                    disableGlobalSearch={false}
                                    // getRowLink={getRowLink}
                                />
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </SimplePage>
    );
}
