import React, {useEffect, useState} from "react";
import {SimplePage} from "../../elements/Pages";
import {useQuery} from "@tanstack/react-query";
import SchoolYearFilter from "../../elements/SchoolYearFilter";
import {useSchoolYears} from "../../../contexts/SchoolYearContext";
import {formatDateTime} from "../../../utils/formatters";
import {classNames} from "../../../utils/misc";
import {useNavigate} from "react-router-dom";
import BasicModal from "../../elements/modal";
import {ArrowLeftIcon, ArrowRightIcon} from "@heroicons/react/20/solid";
import {getGalleryList} from "../../../api/gallery";

import {LazyLoadImage} from "react-lazy-load-image-component";
import ListViewTable from "../../elements/tables/ListViewTable";

export default function GalleryList() {

    const {schoolYears, currentSchoolYear} = useSchoolYears();
    const [schoolYearId, setSchoolYearId] = useState();

    const {isLoading, isError, isSuccess, data, error, refetch} = useQuery(
        ["gallery", schoolYearId],
        () => getGalleryList(schoolYearId),
        {
            enabled: !!schoolYearId, refetchInterval: 1000 * 60 * 60
        }
    );

    useEffect(() => {
        if (currentSchoolYear) setSchoolYearId(currentSchoolYear["id"]);
    }, [currentSchoolYear]);

    if (!data) return <></>

    return (

        <SimplePage>

            <div className="px-4 sm:px-6 lg:px-8">
                {/*<div className="sm:flex sm:items-center">*/}
                {/*    <div className="sm:flex-auto">*/}
                {/*        <h3 className="text-lg leading-6 font-medium text-gray-900 mb-2">*/}
                {/*            Themenverteilungspläne nach Kursen*/}
                {/*        </h3>*/}
                {/*    </div>*/}
                {/*    <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">*/}
                {/*    </div>*/}
                {/*</div>*/}
                <div className="flex flex-col">
                    <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">

                        <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                            <SchoolYearFilter
                                schoolYears={schoolYears}
                                schoolYearId={schoolYearId}
                                setSchoolYearId={setSchoolYearId}
                            />
                        </div>

                        <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                            {data.map(gallery => <GalleryItem key={gallery.id} gallery={gallery}/>)}
                        </div>
                    </div>
                </div>
            </div>
        </SimplePage>

    )
}

export function GalleryItem ({gallery, isDetail}) {

    const navigate = useNavigate();

    const [open, setOpen] = useState(false)
    const [currentPhoto, setCurrentPhoto] = useState()

    const handleCurrentPhoto = (idx) => {
        setCurrentPhoto({idx, ...gallery.photos[idx]})
        setOpen(true)
    }

    const changeCurrentPhoto = (dir) => {
        let idx = 0
        if (dir) {
            if (currentPhoto.idx < gallery.photos.length - 1) {
                idx = currentPhoto.idx+1
            }
        } else {
            if (currentPhoto.idx > 0) {
                idx = currentPhoto.idx-1
            } else {
                idx = gallery.photos.length - 1
            }
        }
        setCurrentPhoto({idx, ...gallery.photos[idx]})
    }

    function getRowLink() {
        setOpen(!open)
        !isDetail && navigate(`/gallery/${gallery.id}/`)
    }

    return (
        <div
            className={classNames("p-2 md:p-4 min-w-[18rem]", !isDetail && "rounded-lg hover:bg-gray-100 transition ease-in-out duration-150 cursor-pointer")}
            onClick={getRowLink}
        >
            <h1 className="block truncate text-2xl font-medium text-gray-900">
                {gallery.title}
            </h1>
            <h2 className="block my-2 text-xl font-medium text-gray-500">
                {gallery.description}
            </h2>
            <div className={classNames("flex", isDetail ? "flex-col-reverse" : "flex-col")}>
                <div className={classNames("grid grid-cols-2 grid-rows-none gap-x-4 gap-y-4 sm:grid-cols-3 sm:gap-x-6  md:grid-cols-4 md:gap-x-8  lg:grid-cols-5 lg:gap-x-10", !isDetail && "overflow-hidden max-h-40 sm:max-h-48 md:max-h-56 lg:max-h-64")}>
                    {gallery.photos.map((photo, idx) => <GalleryPhotoItem key={idx} idx={idx} photo={photo} handleCurrentPhoto={handleCurrentPhoto} isDetail={isDetail}/>)}
                </div>
                <div className={classNames("flex flex-row flex-wrap gap-x-4 justify-between text-md font-medium text-gray-500", isDetail ? "mb-2" : "mt-2")}>
                    <span>Erstellt am {formatDateTime(gallery.date_added)}</span>
                </div>
            </div>
            <BasicModal open={open} setOpen={setOpen} width="7xl">
                {currentPhoto && <GalleryPhotoModal currentPhoto={currentPhoto} changeCurrentPhoto={changeCurrentPhoto}/>}
            </BasicModal>
        </div>
    )
}

function GalleryPhotoItem ({idx, photo, handleCurrentPhoto, isDetail}) {
    return (
        <div
            className="relative"
            onClick={() => handleCurrentPhoto(idx)}
        >
            <div className={classNames("flex block w-full rounded-lg bg-gray-100 overflow-hidden content-center h-32 sm:h-40 md:h-48 lg:h-56", isDetail && "cursor-pointer hover:opacity-75 transition ease-in-out duration-150")}>
                <LazyLoadImage
                    src={isDetail ? photo.photo : photo}
                    alt=""
                    className={'pointer-events-none object-cover'}
                />
            </div>
            {isDetail && <p className="pointer-events-none mt-2 block truncate text-sm font-medium max-h-6 text-gray-900">
                {photo.title}
            </p>}
        </div>
    )
}

function GalleryPhotoModal ({currentPhoto, changeCurrentPhoto}) {
    return (
        <div className={'w-full h-full'}>
            <div className="absolute top-[50%] left-0 block pl-2 sm:pl-4">
                <button
                    type="button"
                    className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-imsblue-500 focus:ring-offset-2"
                    onClick={() => changeCurrentPhoto(false)}
                >
                    <span className="sr-only">Schließen</span>
                    <ArrowLeftIcon className="h-6 w-6" aria-hidden="true" />
                </button>
            </div>
            <div className={'flex flex-row flex-no-wrap justify-center w-full h-full'}>
                <LazyLoadImage
                    src={currentPhoto.photo}
                    alt=""
                />
            </div>
            <div className="absolute top-[50%] right-0 block pr-2 sm:pr-4">
                <button
                    type="button"
                    className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-imsblue-500 focus:ring-offset-2"
                    onClick={() => changeCurrentPhoto(true)}
                >
                    <span className="sr-only">Schließen</span>
                    <ArrowRightIcon className="h-6 w-6" aria-hidden="true" />
                </button>
            </div>
        </div>
    )
}
