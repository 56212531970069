import React, {useEffect, useState} from "react";
import {SimplePage} from "../../elements/Pages";
import {useQuery} from "@tanstack/react-query";
import {ExportPDFButton, PrimaryButton} from "../../elements/Buttons";
import {useSchoolYears} from "../../../contexts/SchoolYearContext";
import {getStudentNotenblatt, startStudentNotenblattUpdate} from "../../../api/grades";
import SchoolYearFilter from "../../elements/SchoolYearFilter";
import {Link, useParams} from "react-router-dom";
import {toast} from "react-toastify";
import {ArrowPathIcon} from "@heroicons/react/20/solid";
import {formatDate} from "../../../utils/formatters";
import checkPermission from "../../../utils/permissions";
import {useAuth} from "../../../contexts/AuthContext";
import {classNames} from "../../../utils/misc"


export function AsyncUpdateButton({label, apiFunction, classNameIn, ...rest}) {

    async function handleStart() {
        try {
            await apiFunction()
            toast.success("Gestartet. Die Aktualisierung kann ca. 30-90 Sekunden dauern. Bitte laden Sie dann diese Seite erneut")
        } catch (e) {
            toast.error("Es ist ein Fehler aufgetreten")
        }
    }

    const button = <button onClick={handleStart}
                           type="button"
                           title={label}
                           className="cursor-pointer inline-flex items-center justify-center px-4 py-2
                           border border-gray-300 shadow-sm text-sm font-medium rounded-md
                           text-gray-700 bg-white hover:bg-gray-50 focus:outline-none
                           focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100
                           focus:ring-blue-500"
                           {...rest}
    ><ArrowPathIcon className="h-5 w-5" aria-hidden="true"/>
    </button>

    return button
}


export default function StudentNotenblatt() {

    const {permissions} = useAuth()
    const {studentId} = useParams()
    const {schoolYears, currentSchoolYear} = useSchoolYears();
    const [schoolYearId, setSchoolYearId] = useState();

    useEffect(() => {
        if (currentSchoolYear) setSchoolYearId(currentSchoolYear["id"]);
    }, [currentSchoolYear])

    const {isLoading, isError, isSuccess, data, error, refetch} = useQuery(
        ["student_notenblatt", studentId, schoolYearId],
        () => getStudentNotenblatt(studentId, schoolYearId),
        {
            enabled: !!schoolYearId,
        }
    );

    const canUpdate = checkPermission(permissions, "grades.view_studentnotenblatt")

    if (!data) return <></>
    return (
        <>
            <SimplePage>
                <div className="px-4 sm:px-6 lg:px-8">
                    <div className="sm:flex sm:items-center">
                        <div className="sm:flex-auto">
                            <h3 className="text-lg leading-6 font-medium text-gray-900">
                                Notenblatt
                                von {data.notenblatt_data.student.full_name} (Stand {formatDate(data.last_update_date)})
                            </h3>
                        </div>
                        <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none flex gap-1">

                            {canUpdate && <AsyncUpdateButton
                                apiFunction={() => startStudentNotenblattUpdate(studentId, schoolYearId)}
                                label="Notenblatt aktualisieren"/>}

                            <ExportPDFButton
                                portrait={false}
                                data={<StudentNotenblattDetail data={data} exporting={true}/>}
                                fileName={`Notenblatt ${data.notenblatt_data.student.full_name}.pdf`}
                                label="PDF exportieren"
                            />

                            <PrimaryButton link={`/school/students/${studentId}`} label="Schülerblatt"/>

                        </div>
                    </div>

                    <div className="flex flex-col mt-3 mb-3 scrollbarHeight">
                        <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">

                            <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                                <SchoolYearFilter
                                    schoolYears={schoolYears}
                                    schoolYearId={schoolYearId}
                                    setSchoolYearId={setSchoolYearId}
                                />

                            </div>

                            <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">

                                <div className="overflow-scroll">
                                    <StudentNotenblattDetail data={data} exporting={false}/>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </SimplePage>

        </>
    );
}

const getColumnDivideIndex = (columns) => {
    const resultArr = []
    columns.reduce((acc, cur) => {
        let res = acc + cur.colspan
        resultArr.push(res)
        return res
    }, 0)
    return resultArr
}

function StudentNotenblattDetail({data, exporting}) {

    const header = exporting ? <h3 className="text-sm leading-6 font-medium">
        Notenblatt von {data.notenblatt_data.student.full_name} (Stand {formatDate(data.last_update_date)})
    </h3> : <></>

    const availableColumns = getColumnDivideIndex(data.notenblatt_data.top_columns)
    const leftBorderStyle = "border-l-2 border-gray-200"

    const table = <table
        className={"min-w-full border-x border-y divide-gray-200 border-gray-200" + (exporting ? " border-collapse notenblatt" : " mt-3 ")}>
        <thead className="bg-gray-50">

        {/* Top level: top columns */}
        <tr className="divide-y divide-x-2 divide-gray-200">
            {data.notenblatt_data.top_columns.map((c, index) => (
                <th className={exporting ? "text-center text-xs" : "py-3.5 text-center text-sm"} key={index}
                    colSpan={c.colspan}
                >
                    {c.header}
                </th>
            ))}
        </tr>

        {/* 2nd level: main columns */}
        <tr className="divide divide-gray-200">
            {data.notenblatt_data.main_columns.map((c, index) => {
                const additionalStyle = availableColumns.includes(index) && leftBorderStyle
                return (
                    <th
                        className={classNames(
                            "border border-gray-200",
                            exporting ? "text-center text-xs" : "py-3.5 text-center text-sm",
                            additionalStyle
                        )}
                        key={index} dangerouslySetInnerHTML={{__html: c}}>
                    </th>
                )
            })}
        </tr>

        </thead>

        <tbody>

        {data.notenblatt_data.rows.map((courseRow, courseIndex) => {

            return <>
                {/* regular mark definitions */}
                {courseRow.mark_definitions.map((md, rowIndex) => (
                    <tr key={rowIndex} className="border border-gray-200">

                        {rowIndex === 0 &&
                            <td
                                className={exporting ? "border border-gray-200 text-xs" : "border py-4 pl-4 pr-3 text-sm border-gray-200"}
                                key="course"
                                rowSpan={courseRow.mark_definitions.length + 0}
                            >{courseRow.course.school_subject} <br/>
                                <Link className="text-imsblue-600"
                                      to={`/courses/${courseRow.course.id}`}>{courseRow.course.course_number}</Link>
                                <br/>
                                {courseRow.course.teacher?.full_name}
                            </td>}

                        <td className={classNames("border ", exporting ? "text-xs" : "py-4 pl-4 pr-3 text-sm")}
                            key="title">{md.title}</td>
                        <td className={classNames("border ", exporting ? "text-xs" : "py-4 pl-4 pr-3 text-sm")}
                            key="type">{md.type}</td>

                        {md.values.map((v, slotIndex) => {

                            const additionalStyle = rowIndex !== 0
                                ? leftBorderStyle
                                : availableColumns.includes(slotIndex + availableColumns[0])
                                    ? leftBorderStyle
                                    : "border"

                            return (
                                <Slot slot={v}
                                      key={slotIndex}
                                      slotIndex={slotIndex}
                                      rowIndex={rowIndex}
                                      exporting={exporting}
                                      rowSpan={courseRow.mark_definitions.length + 0}
                                      additionalStyle={additionalStyle}
                                />
                            )
                        })}
                    </tr>
                ))
                }

                {/*special grades*/}
                {courseRow.special_grades?.map((sg, rowIndex) => (
                    <tr key={rowIndex} className="divide-x divide-y divide-gray-200">

                        {rowIndex === 0 &&
                            <td
                                className={exporting ? "divide-y divide-gray-200 text-xs" : "divide-y divide-gray-200 py-4 pl-4 pr-3 text-sm"}
                                key="course"
                                rowSpan={courseRow.special_grades.length}
                            >{courseRow.course.school_subject} <br/>
                                {courseRow.course.course_number}
                            </td>}

                        <td className={exporting ? "text-xs" : "py-4 pl-4 pr-3 text-sm"}
                            key="title">{sg.special_grade_definition}</td>
                        <td className={exporting ? "text-xs" : "py-4 pl-4 pr-3 text-sm"}
                            key="type">{sg.special_grade_mark}</td>

                        {sg.values.map((v, slotIndex) => {

                            const additionalStyle = rowIndex !== 0
                                ? leftBorderStyle
                                : availableColumns.includes(slotIndex + availableColumns[0])
                                    ? leftBorderStyle
                                    : "border"

                            return (
                                <Slot slot={v}
                                      key={slotIndex}
                                      slotIndex={slotIndex}
                                      rowIndex={rowIndex}
                                      exporting={exporting}
                                      rowSpan={courseRow.special_grades.length}
                                      additionalStyle={additionalStyle}
                                />
                            )
                        })}
                    </tr>
                ))
                }
            </>
        })}
        </tbody>

    </table>

    return <>{header} {table}</>
}


function Slot({slot, slotIndex, rowIndex, exporting, rowSpan, additionalStyle}) {

    if (typeof slot === 'object') {
        return <td
            key={slotIndex}
            className={classNames(exporting ? "text-xs" : "py-4 pl-4 pr-3 text-sm", additionalStyle)}
        >
            {slot?.results?.map((result, resultIndex) => (
                <span key={resultIndex}>
                    {(resultIndex > 0 ? ', ' : '')}
                    <Link className={exporting ? "" : "text-imsblue-600"} to={`/grades/results/${result.result_id}`} >{result.mark_display}</Link>
                </span>
            ))}
        </td>
    }

    if (rowIndex === 0) {
        // averages only for the first row in this mark definition
        return (
            <td
                key={slotIndex}
                rowSpan={rowSpan}
                className={classNames(exporting ? "text-xs" : "py-4 pl-4 pr-3 text-sm", additionalStyle)}
                dangerouslySetInnerHTML={{__html: slot}}>

            </td>
        )
    }

}