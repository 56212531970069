import React from 'react';
import {Route, Routes} from "react-router-dom";
import RepairOrders from "./RepairOrders";
import RepairOrderDetail from "./RepairOrderDetail";
import RepairOrderCreateEdit from "./RepairOrderCreateEdit";
import MyRepairOrders from "./MyRepairOrders";


function MaintenanceRouter() {

    return (
        <Routes>
            
            <Route path="/repair-orders" element={<RepairOrders/>}/>
            <Route path="/repair-orders/my" element={<MyRepairOrders/>}/>
            <Route path="/repair-orders/:id" element={<RepairOrderDetail/>}/>
            <Route path="/repair-orders/new" element={<RepairOrderCreateEdit/>}/>
            <Route path="/repair-orders/:id/edit" element={<RepairOrderCreateEdit/>}/>
        </Routes>
    )
}

export default function Maintenance() {

    return (
        <MaintenanceRouter />
    )
}
