import React, {useContext} from "react";
import {useQuery} from "@tanstack/react-query";
import {getEventsByMonth, getEventsByWeek} from "../api/calendar";
import {useEffect, useState, useMemo} from "react";
import {getWeekNumber} from "../utils/helpers";
import {useNavigate} from "react-router-dom";

const keys = ["id"];
const EventContext = React.createContext();
const today = new Date();

export function useEvents() {
    return useContext(EventContext);
}

export function EventProvider({children}) {
    const [view, setView] = useState("month");
    const [selectedDate, setSelectedDate] = useState({
        year: today.getFullYear(),
        month: today.getMonth() + 1,
        date: today.getDate(),
    });
    const [selectedDateWeek, setSelectedDateWeek] = useState(
        getWeekNumber(today)
    );
    const [filter, setFilter] = useState({
        categories: [],
        groups: [],
    });
    const navigate = useNavigate();
    const [selectedEvent, setSelectedEvent] = useState(null);
    const [eventDetailOpen, setEventDetailOpen] = useState(false);
    const [eventCategoriesMonth, setEventCategoriesMonth] = useState([]);
    const [eventtargetGroupsMonth, setEventtargetGroupsMonth] = useState([]);
    const [eventCategoriesWeek, setEventCategoriesWeek] = useState([]);
    const [eventtargetGroupsWeek, setEventtargetGroupsWeek] = useState([]);
    const [loaded, setLoaded] = useState(false);

    const {data: eventsByMonth, loading: loadingMonth} = useQuery(
        ["events_month", selectedDate],
        () => getEventsByMonth(selectedDate.year, selectedDate.month),
        {
            enabled: view === 'month',
            keepPreviousData: true
        }
    );
    const {
        data: eventsByWeek,
        loading: loadingWeek,
        isRefetching: isRefetchingWeek,
    } = useQuery(["events_week", selectedDateWeek], () =>
            getEventsByWeek(selectedDateWeek[0], selectedDateWeek[1]),
        {
            refetchInterval: 1000 * 60 * 60,
            enabled: view === 'week',
            keepPreviousData: true
        }
    );
    const openEvent = (event) => {
        setSelectedEvent(event);
        setEventDetailOpen(true);
        if (view === "month")
            navigate(
                `/calendar?event_id=${event.event_id}&year=${selectedDate.year}&month=${selectedDate.month}`
            );
        else
            navigate(
                `/calendar?event_id=${event.event_id}&year=${selectedDate.year}&week=${selectedDateWeek[1]+1}&date=${selectedDate.date}`
            );
        // setSelectedEvent(event)
        // setEventDetailOpen(true);
        // const startDate = new Date(event.start)
        // const useDate = {
        //     year: startDate.getFullYear(),
        //     month: startDate.getMonth() + 1,
        //     date: startDate.getDate(),
        //     week: getWeekNumber(startDate)[1] + 1
        // }
        // if (view === "month")
        //     navigate(
        //         `/calendar?event_id=${event.event_id}&year=${useDate.year}&month=${useDate.month}`
        //     );
        // else
        //     navigate(
        //         `/calendar?event_id=${event.event_id}&year=${useDate.year}&week=${useDate.week}&date=${useDate.date}`
        //     );
    };
    useEffect(() => {
        const weekNumber = getWeekNumber(
            new Date(selectedDate.year, selectedDate.month-1, selectedDate.date)
        );
        setSelectedDateWeek(weekNumber);
    }, [selectedDate]);
    useEffect(() => {
        if (!eventDetailOpen && loaded) navigate("/calendar");
        else setLoaded(true);
    }, [eventDetailOpen]);

    const fetchedEventsMonth = useMemo(() => {
        if (eventsByMonth) {
            if (filter.categories.length || filter.groups.length) {
                let filteredEvents = [];
                if (filter.categories.length && filter.groups.length) {
                    filteredEvents = [
                        ...filteredEvents,
                        ...eventsByMonth.filter((i) =>
                            filter.categories.includes(i.event.category.id) && i.event.target_groups.some((j) => filter.groups.includes(j.id)
                            ))
                    ];
                } else {
                    if (filter.categories.length) {
                        filteredEvents = [
                            ...filteredEvents,
                            ...eventsByMonth.filter((i) =>
                                filter.categories.includes(i.event.category.id)
                            ),
                        ];
                    }
                    if (filter.groups.length) {
                        filteredEvents = [
                            ...filteredEvents,
                            ...eventsByMonth.filter((i) =>
                                i.event.target_groups.some((j) => filter.groups.includes(j.id))
                            ),
                        ];
                    }
                }
                return filteredEvents;
            }
            return eventsByMonth;
        }
        return null;
    }, [eventsByMonth, filter]);
    const fetchedEventsWeek = useMemo(() => {
        if (eventsByWeek) {
            if (filter.categories.length || filter.groups.length) {
                let filteredEvents = [];
                if (filter.categories.length) {
                    filteredEvents = [
                        ...filteredEvents,
                        ...eventsByWeek.filter((i) =>
                            filter.categories.includes(i.event.category.id)
                        ),
                    ];
                }
                if (filter.groups.length) {
                    filteredEvents = [
                        ...filteredEvents,
                        ...eventsByWeek.filter((i) =>
                            i.event.target_groups.some((j) => filter.groups.includes(j.id))
                        ),
                    ];
                }
                return filteredEvents;
            }
            return eventsByWeek;
        }
        return null;
    }, [eventsByWeek, filter]);
    useEffect(() => {
        if (eventsByWeek?.length) {
            let allGroups = [];
            const uniqueGroups = [
                ...new Set(
                    [].concat.apply(
                        [],
                        eventsByWeek.map((event) => [
                            ...allGroups,
                            ...event.event.target_groups,
                        ])
                    )
                ),
            ];

            setEventtargetGroupsWeek(
                uniqueGroups.filter(
                    (value, index, self) =>
                        self.findIndex((v) => keys.every((k) => v[k] === value[k])) ===
                        index
                )
            );
            const uniqueCategories = [
                ...new Set(eventsByWeek.map((event) => event.event.category)),
            ];
            try {
                setEventCategoriesWeek(
                    uniqueCategories.filter(
                        (value, index, self) =>
                            self.findIndex((v) => keys.every((k) => v[k] === value[k])) ===
                            index
                    )
                );
            } catch (e) {
            }
        }
    }, [eventsByWeek]);
    useEffect(() => {
        if (eventsByMonth?.length) {
            let allGroups = [];
            const uniqueGroups = [
                ...new Set(
                    [].concat.apply(
                        [],
                        eventsByMonth.map((event) => [
                            ...allGroups,
                            ...event.event.target_groups,
                        ])
                    )
                ),
            ];

            setEventtargetGroupsMonth(
                uniqueGroups.filter(
                    (value, index, self) =>
                        self.findIndex((v) => keys.every((k) => v[k] === value[k])) ===
                        index
                )
            );
            const allCategories = eventsByMonth.map((event) => event.event.category);
            const uniqueCategories = allCategories.filter(
                (value, index, self) => {
                    if (!value) return false;
                    return self.findIndex((v) =>
                        v && keys.every((k) => v[k] === value[k])
                    ) === index;
                }
            );
            setEventCategoriesMonth(uniqueCategories);
        }
    }, [eventsByMonth]);

    const value = {
        selectedDate,
        setSelectedDate,
        fetchedEventsMonth,
        fetchedEventsWeek,
        view,
        setView,
        selectedEvent,
        openEvent,
        eventDetailOpen,
        setEventDetailOpen,
        eventCategoriesMonth,
        eventCategoriesWeek,
        eventtargetGroupsMonth,
        eventtargetGroupsWeek,
        filter,
        setFilter,
        loadingWeek: loadingWeek || isRefetchingWeek,
    };

    return (
        <EventContext.Provider value={value}>
            {!loadingWeek && !loadingMonth && children}
        </EventContext.Provider>
    );
}
