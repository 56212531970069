import React from "react";
import {Link, useParams} from "react-router-dom";
import {SimplePage} from "../../elements/Pages";
import {GalleryItem} from "./GalleryList";
import {getGalleryDetail} from "../../../api/gallery";
import {useQuery} from "@tanstack/react-query";

export default function GalleryDetail() {

    const {id} = useParams();

    const {data: gallery} = useQuery(
        ["gallery_detail", id],
        () => getGalleryDetail(id),
        {refetchInterval: 1000 * 60 * 60 * 24}
    )

    if (!gallery) return <></>;

    return (

        <SimplePage>
            <div className="px-4 sm:px-6 lg:px-8">
                <div className="sm:flex sm:items-center">
                    <div className="sm:flex-auto">
                        {/*<h3 className="text-lg leading-6 font-medium text-gray-900 mb-2">*/}
                        {/*    Themenverteilungspläne nach Kursen*/}
                        {/*</h3>*/}
                    </div>
                    <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
                    </div>
                </div>
                <div className="flex flex-col">
                    <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
                        <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                            <div className="px-4 pt-4"><Link to={`/gallery`} className="font-medium text-imsblue-600">Zurück
                                zur Liste</Link></div>
                            <GalleryItem gallery={gallery} isDetail={true}/>
                            <div className="px-4 pt-4"><Link to={`/gallery`} className="font-medium text-imsblue-600">Zurück
                                zur Liste</Link></div>
                        </div>
                    </div>
                </div>
            </div>
        </SimplePage>
    )
}