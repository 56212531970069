import {SimplePage} from "../../elements/Pages";
import React, {useEffect, useState} from "react";

import {getLogbookEntries, getUnapprovedLogbookEntries} from "../../../api/logbook";

import {useQuery} from "@tanstack/react-query";
import {useAuth} from "../../../contexts/AuthContext";
import ListViewTable, {TableEditLink, TwoLineColumn} from "../../elements/tables/ListViewTable";
import checkPermission from "../../../utils/permissions";
import {Link, useParams} from "react-router-dom";
import SchoolYearFilter from "../../elements/SchoolYearFilter";
import {useSchoolYears} from "../../../contexts/SchoolYearContext";
import Pagination from "../../elements/Pagination";
import {getSchoolClassesFull} from "../../../api/school";
import {houseApi} from "../../../api/boarding_school";
import {SecondaryButton} from "../../elements/Buttons";
import env from "react-dotenv";
import {getRecurringRules} from "../../../api/schedule";

const columns = [
    {
        accessorFn: (row) =>
            `${row.student.last_name}, ${row.student.first_name} ${row.student.student_number}`,
        id: "student_search",
        header: "SchülerIn",
        hide: true,
        enableColumnFilter: false,
    },
    {
        accessorKey: "student",
        id: "student",
        header: "SchülerIn",
        columnType: "student",
        enableColumnFilter: false,
        sortingFn: "lastNameSorting",
    },
    ...env.SCHOOL === 'KPI' ? [{
        accessorKey: "student.current_plupu_rating",
        id: "current_plupu_rating",
        header: "Status",
        columnType: "plupu_rating",
        enableColumnFilter: false
    }] : [],
    {
        accessorKey: "categories",
        id: "categories",
        header: "Kategorien",
        columnType: "m2m",
        labelAttr: "name",
        enableColumnFilter: true,
        headerClassNames: "medium-filter",
        filterFn: "containsString",
    },
    {
        accessorKey: "creation_date",
        id: "creation_date",
        header: "Datum",
        columnType: "date",
        enableColumnFilter: false,
        // classNames: 'font-medium text-gray-900'
        classNames: "text-gray-500",
    },
    {
        accessorKey: "student.house",
        id: "house",
        header: "Mentorat",
        enableColumnFilter: true,
        headerClassNames: "narrow-filter",
    },
    {
        accessorKey: "student.school_class",
        id: "school_class",
        header: "Klasse",
        enableColumnFilter: true,
        headerClassNames: "narrow-filter",
    },
    {
        accessorKey: "get_visibility_display",
        id: "get_visibility_display",
        header: "Sichtbarkeit",
        cell: (info) => info.getValue(),
        enableColumnFilter: true,
        headerClassNames: "narrow-filter",
    },
    {
        accessorKey: "title",
        id: "title",
        header: "Titel",
        enableColumnFilter: false,
        classNames: "max-w-md text-gray-500",
        headerClassNames: "max-w-md",
    },
    {
        accessorKey: "created_by_full_name",
        id: "created_by",
        header: "Eintrag von",
        enableColumnFilter: false,
        headerClassNames: "medium-filter",
    }
]

export default function LogbookEntriesUnapproved() {
    const {permissions} = useAuth();

    const canEditLogbookEntries = checkPermission(permissions, "student_logbook.change_logbookentry")

    let editColumns = canEditLogbookEntries ? [
        {
            accessorKey: "id",
            id: "link",
            cell: (info) => (
                <TableEditLink
                    label="Bearbeiten"
                    iconOnly={true}
                    to={`/logbook/entries/${info.getValue()}/edit`}
                />
            ),
            header: "Bearbeiten",
            enableColumnFilter: false,
            disableRowLink: true
        }
    ] : []

    const {data, isLoading, isSuccess} = useQuery(['unapproved_logbook_entries'], getUnapprovedLogbookEntries, {refetchInterval: 1000 * 60 * 60 * 2})
    const complColumns = [...columns, ...editColumns]

    function getRowLink(row) {
        return `/logbook/entries/${row.original.id}/`;
    }

    if (!data) return <></>
    return (
        <SimplePage>
            <div className="px-4 sm:px-6 lg:px-8">
                <div className="sm:flex sm:items-center">
                    <div className="sm:flex-auto">
                        <h3 className="text-lg leading-6 font-medium text-gray-900">
                            Noch nicht freigegebene Logbucheinträge
                        </h3>
                    </div>
                    <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">

                        {checkPermission(
                            permissions,
                            "student_logbook.add_logbookentry"
                        ) && (
                            <Link to="/logbook/entries/new">
                                <button
                                    type="button"
                                    className="inline-flex items-center justify-center rounded-md border border-transparent bg-imsblue-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-imsblue-700 focus:outline-none focus:ring-2 focus:ring-imsblue-500 focus:ring-offset-2 sm:w-auto"
                                >
                                    Neuer Eintrag
                                </button>
                            </Link>
                        )}
                    </div>
                </div>
                <div className="flex flex-col">
                    <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">

                        <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                            {isSuccess && data && (
                                <ListViewTable
                                    data={data}
                                    columns={complColumns}
                                    isLoading={isLoading}
                                    disableGlobalSearch={false}
                                    getRowLink={getRowLink}
                                />
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </SimplePage>
    );
}
