import {SimplePage} from "../../elements/Pages";
import React from "react";

import {useQuery} from "@tanstack/react-query";
import ListViewTable, {TableLink} from "../../elements/tables/ListViewTable";
import {getStudentListForStudierzeit} from "../../../api/studierzeit";
import {PrimaryButton} from "../../elements/Buttons";
import moment from "moment";

const columns = [
    {
        accessorFn: (row) =>
            `${row.last_name}, ${row.first_name} ${row.student_number}`,
        id: "student_search",
        header: "SchülerIn",
        hide: true,
        enableColumnFilter: false,
    },
    {
        accessorFn: (row) => row,
        id: 'student',
        header: 'SchülerIn',
        columnType: 'student',
        enableColumnFilter: false,
        sortingFn: "lastNameSorting",
    },
    {
        accessorKey: "is_sternchen",
        id: "is_sternchen",
        header: "Sternchen",
        columnType: "yesno",
        enableColumnFilter: true,
        filterFn: "boolean",
        headerClassNames: "narrow-filter",
    },
    {
        accessorKey: "house",
        id: "house",
        header: "Mentorat",
        enableColumnFilter: true,
        headerClassNames: "narrow-filter",
    },
    {
        accessorKey: "school_class",
        id: "school_class",
        header: "Klasse",
        enableColumnFilter: true,
        sortingFn: "schoolClassSorting",
        headerClassNames: "narrow-filter",
    },
];

export default function StudierzeitDetail() {

    const search = window.location.search;
    const params = new URLSearchParams(search);

    const {isLoading, isError, isSuccess, data, error, refetch} = useQuery(
        ["studierzeit_details", search],
        () => getStudentListForStudierzeit(params),
        {refetchInterval: 1000 * 60 * 60}
    )

    function getRowLink(row) {
        return `/class-register/students/${row.original.id}/`;
    }

    return (
        <SimplePage>
            <div className="px-4 sm:px-6 lg:px-8">
                <div className="sm:flex sm:items-center">
                    <div className="sm:flex-auto">
                        <h3 className="text-lg leading-6 font-medium text-gray-900">
                            Studierzeit {data && <>{data.room_name}, {data.studierzeit_time_name}, {data.period_name}, {data.weekday}</>}
                        </h3>
                    </div>
                    <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
                        <PrimaryButton link={`/studierzeit/attendance/new?studierzeit_time=${params.get('studierzeit_time')}&room=${params.get('room')}&date=${moment().format("yyyy-MM-DD")}`} label="Anwesenheit erfassen" />
                    </div>
                </div>
                <div className="flex flex-col">
                    <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
                        <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                            {isSuccess && data.students && (
                                <ListViewTable
                                    data={data.students}
                                    columns={columns}
                                    isLoading={isLoading}
                                    disableGlobalSearch={false}
                                    getRowLink={getRowLink}
                                />
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </SimplePage>
    );
}
