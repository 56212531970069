import http from "../services/httpService";

export async function genericApi(endpoint, method, id, data) {

    let resp
    switch(method) {
        case 'options':
            resp = await http.options(`${endpoint}`)
            return resp.data.actions.POST
            break;
        case 'get':
            if (id) resp = await http.get(`${endpoint}${id}/`)
            else resp = await http.get(`${endpoint}`)
            break;
        case 'patch':
            resp = await http.patch(`${endpoint}${data.id}/` , data)
            break;
        case 'post':
            resp = await http.post(`${endpoint}` , data)
            break;
        case 'delete':
            resp = await http.delete(`${endpoint}${id}/`)
            break;
        default:
        // code block
    }
    return resp.data
}