import {Menu, Transition} from "@headlessui/react";
import {ChevronDownIcon, ChevronLeftIcon, ChevronRightIcon, EllipsisHorizontalIcon,} from "@heroicons/react/20/solid";
import React, {Fragment, useMemo, useState} from "react";
import {useEvents} from "../../contexts/EventsContext";
import {classNames} from "../../utils/misc";
import CalendarMonthFilter from "../elements/CalendarMonthFilter";
import {MultiSelect} from "../elements/forms/Inputs";
import BasicModal from "../elements/modal";
import EventDetail from "./EventDetail";
import {DownloadButton, ListButton, PrimaryButton} from "../elements/Buttons";
import {months} from "./calendarUtils";
import ExportForm from "./ExportForm";
import {useAuth} from "../../contexts/AuthContext";
import checkPermission from "../../utils/permissions";

const CalendarContainer = ({
  children,
  setView,
  view,
  nextPrev,
  selectedDate,
}) => {
  const [exportModalOpen, setExportModalOpen] = useState(false);
  const {permissions} = useAuth();
  const {
    eventDetailOpen,
    setEventDetailOpen,
    eventCategoriesMonth,
    eventCategoriesWeek,
    eventtargetGroupsMonth,
    eventtargetGroupsWeek,
    filter: data,
    setFilter: setData,
  } = useEvents();
  const handleChange = (value, key) => {
    setData({
      ...data,
      [key]: value,
    });
  };

  const monthName = useMemo(() => {
    return months.find((i) => i.id === parseInt(selectedDate.month))?.name
  }, [selectedDate]);

  const canAddEvent = checkPermission(permissions, "calendar_app.add_schoolevent");
  return (
    <>
      <BasicModal open={eventDetailOpen} setOpen={setEventDetailOpen}>
        <EventDetail />
      </BasicModal>
      <BasicModal open={exportModalOpen} setOpen={setExportModalOpen}>
        <ExportForm months={months} defaultSelectedMonth={selectedDate.month} />
      </BasicModal>
      <div className="flex h-full flex-col">
        <CalendarMonthFilter
          providerFunction={useEvents}
          items={months}
          selectedValue={`${selectedDate.year}-${selectedDate.month}`}
        />
        <header className="flex flex-none items-center justify-between border-b border-gray-200 py-4 px-6">
          <h1 className="text-lg font-semibold text-gray-900">
            <time dateTime="2022-01">
              {monthName}{" "}{selectedDate.year}
            </time>
          </h1>
          <div className="flex items-center">
            {canAddEvent && <div className="hidden md:ml-4 md:flex md:items-center w-40">
              <MultiSelect
                path="groups"
                label="Zielgruppen"
                emptyLabel="Zielgruppen"
                className="w-full hide-label"
                options={
                  view === "month"
                    ? eventtargetGroupsMonth
                    : eventtargetGroupsWeek
                }
                valueAttr="id"
                labelAttr="name"
                onChange={(value) => handleChange(value, "groups")}
                data={data}
                isClearable={false}
                valueOnly={true}
              />
            </div>}
            <div className="hidden md:ml-4 md:flex md:items-center w-40">
              <MultiSelect
                path="categories"
                label="Kategorie"
                emptyLabel="Kategorie"
                className="w-full hide-label"
                options={
                  view === "month" ? eventCategoriesMonth : eventCategoriesWeek
                }
                valueAttr="id"
                labelAttr="name"
                onChange={(value) => handleChange(value, "categories")}
                data={data}
                isClearable={false}
                valueOnly={true}
              />
            </div>
            <div className="flex gap-2 items-center rounded-md shadow-sm md:items-stretch md:ml-4">
              <button
                type="button"
                onClick={() => nextPrev("prev")}
                className="flex items-center justify-center rounded border border-gray-300 bg-white py-2 pl-3 pr-4 text-gray-400 hover:text-gray-500 focus:relative md:w-9 md:px-2 md:hover:bg-gray-50"
              >
                <span className="sr-only">Previous {view}</span>
                <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
              </button>
              <button
                type="button"
                onClick={() => nextPrev("curr")}
                className="hidden border rounded border-gray-300 bg-white px-3.5 text-sm font-medium text-gray-700 hover:bg-gray-50 hover:text-gray-900 focus:relative md:block"
              >
                Heute
              </button>
              <span className="relative -mx-px h-5 w-px bg-gray-300 md:hidden" />
              <button
                type="button"
                onClick={() => nextPrev("next")}
                className="flex items-center justify-center rounded border border-gray-300 bg-white py-2 pl-4 pr-3 text-gray-400 hover:text-gray-500 focus:relative md:w-9 md:px-2 md:hover:bg-gray-50"
              >
                <span className="sr-only">Next {view}</span>
                <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
              </button>
            </div>
            <div className="hidden md:ml-4 md:flex md:items-center">
              <Menu as="div" className="relative">
                <Menu.Button
                  type="button"
                  className="flex items-center rounded-md border border-gray-300 bg-white py-2 pl-3 pr-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50"
                >
                  {view === 'month' ? 'Monat' : 'Woche'}
                  <ChevronDownIcon
                    className="ml-2 h-5 w-5 text-gray-400"
                    aria-hidden="true"
                  />
                </Menu.Button>

                <Transition
                  as={Fragment}
                  enter="transition ease-out duration-100"
                  enterFrom="transform opacity-0 scale-95"
                  enterTo="transform opacity-100 scale-100"
                  leave="transition ease-in duration-75"
                  leaveFrom="transform opacity-100 scale-100"
                  leaveTo="transform opacity-0 scale-95"
                >
                  <Menu.Items className="absolute right-0 z-10 mt-3 w-36 origin-top-right overflow-hidden rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                    <div className="py-1">
                      <Menu.Item>
                        {({ active }) => (
                          <a
                            href="#"
                            className={classNames(
                              active
                                ? "bg-gray-100 text-gray-900"
                                : "text-gray-700",
                              "block px-4 py-2 text-sm"
                            )}
                            onClick={() => setView("week")}
                          >
                            Woche
                          </a>
                        )}
                      </Menu.Item>
                      <Menu.Item>
                        {({ active }) => (
                          <a
                            href="#"
                            className={classNames(
                              active
                                ? "bg-gray-100 text-gray-900"
                                : "text-gray-700",
                              "block px-4 py-2 text-sm"
                            )}
                            onClick={() => setView("month")}
                          >
                            Monat
                          </a>
                        )}
                      </Menu.Item>
                    </div>
                  </Menu.Items>
                </Transition>
              </Menu>
              <div className="hidden md:ml-4 md:flex md:items-center flex gap-4" >

                <ListButton link={"/calendar/events/list"} label="Liste" />

                {canAddEvent && <PrimaryButton
                  link={"/calendar/events/new"}
                  label="Termin eintragen"
                  classNameIn={"mr-2"}
                />}
                <DownloadButton
                  onClick={() => setExportModalOpen(true)}
                  label="PDF herunterladen"
                />
              </div>
            </div>
            <Menu as="div" className="relative ml-6 md:hidden">
              <Menu.Button className="-mx-2 flex items-center rounded-full border border-transparent p-2 text-gray-400 hover:text-gray-500">
                <span className="sr-only">Menü öffnen</span>
                <EllipsisHorizontalIcon
                  className="h-5 w-5"
                  aria-hidden="true"
                />
              </Menu.Button>

              <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
              >
                <Menu.Items className="absolute right-0 z-10 mt-3 w-36 origin-top-right divide-y divide-gray-100 overflow-hidden rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                  {canAddEvent && <div className="py-1">
                    <Menu.Item>
                        <a
                          href="/calendar/events/new"
                          className="text-gray-700 block px-4 py-2 text-sm"
                        >
                          Termin eintragen
                        </a>
                    </Menu.Item>
                  </div>}
                  <div className="py-1">
                    <Menu.Item>
                      {({ active }) => (
                        <a
                          href="#"
                          className={classNames(
                            active
                              ? "bg-gray-100 text-gray-900"
                              : "text-gray-700",
                            "block px-4 py-2 text-sm"
                          )}
                          onClick={() => nextPrev("curr")}
                        >
                          Heute
                        </a>
                      )}
                    </Menu.Item>
                  </div>
                  {/*<div className="py-1">*/}
                    {/*<Menu.Item>*/}
                    {/*  {({ active }) => (*/}
                    {/*    <a*/}
                    {/*      href="#"*/}
                    {/*      className={classNames(*/}
                    {/*        active*/}
                    {/*          ? "bg-gray-100 text-gray-900"*/}
                    {/*          : "text-gray-700",*/}
                    {/*        "block px-4 py-2 text-sm"*/}
                    {/*      )}*/}
                    {/*      onClick={() => setView("week")}*/}
                    {/*    >*/}
                    {/*      Woche*/}
                    {/*    </a>*/}
                    {/*  )}*/}
                    {/*</Menu.Item>*/}
                    {/*<Menu.Item>*/}
                    {/*  {({ active }) => (*/}
                    {/*    <a*/}
                    {/*      href="#"*/}
                    {/*      className={classNames(*/}
                    {/*        active*/}
                    {/*          ? "bg-gray-100 text-gray-900"*/}
                    {/*          : "text-gray-700",*/}
                    {/*        "block px-4 py-2 text-sm"*/}
                    {/*      )}*/}
                    {/*      onClick={() => setView("month")}*/}
                    {/*    >*/}
                    {/*      Monat*/}
                    {/*    </a>*/}
                    {/*  )}*/}
                    {/*</Menu.Item>*/}
                  {/*</div>*/}
                </Menu.Items>
              </Transition>
            </Menu>
          </div>
        </header>
        {children}
      </div>
    </>
  );
};

export default CalendarContainer;
