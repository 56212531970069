import Joi from "joi";
import {React, useEffect, useState} from "react";
import {Page} from "../../elements/Pages";
import {useNavigate, useParams} from "react-router-dom";
import {toast} from "react-toastify";
import Form, {baseHandleChange, baseHandleDateChange,} from "../../elements/forms/Form";
import {FormSectionContent} from "../../elements/forms/FormLayout";
import {Checkbox, DateInput, Select,} from "../../elements/forms/Inputs";
import {getKeys, keyIssueApi} from "../../../api/organizational";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import checkPermission from "../../../utils/permissions";
import {useAuth} from "../../../contexts/AuthContext";
import {formatDate} from "../../../utils/formatters";
import {useQuery} from "@tanstack/react-query";
import {getActiveStaffAndStudentUsers, getActiveStaffUsers} from "../../../api/accounts";


export default function KeyIssueCreateEdit() {

    const {id, keyId} = useParams();

    const [options, setOptions] = useState();
    const [data, setData] = useState({
        key: {id: parseInt(keyId) || null},
    })
    const [errors, setErrors] = useState({}); // validation errors

    const {permissions, currentUser} = useAuth()
    const canChange = checkPermission(permissions, "organizational.change_keyissue")

    const {data: keys} = useQuery(['keys'], getKeys, {refetchInterval: 1000 * 60 * 60 * 24})
    const {data: users} = useQuery(['active_staff_users'], getActiveStaffUsers, {refetchInterval: 1000 * 60 * 60 * 24})

    let navigate = useNavigate();

    useEffect(() => {
        loadData();
    }, [id]);

    async function loadData() {
        // get options
        const newOptions = await keyIssueApi('options')
        setOptions(newOptions)

        if (!id) return
        const newData = await keyIssueApi("get", id)
        setData(newData)
    }

    const schema = {
        key: Joi.object().required(),
        user: Joi.object().required(),
        issue_date: Joi.date().required(),
        return_date: Joi.date().allow(null, ''),
    }

    const title =
        id || data.id
            ? "Schlüsselausgabe bearbeiten"
            : "Schlüsselausgabe erstellen";
    let successMessage = "Schlüsselausgabe gespeichert"

    async function onSave() {
        let newData
        try {
            if (!data.id) newData = await keyIssueApi("post", null, data);
            else newData = await keyIssueApi("patch", null, data);
            setData(newData);
            toast.success(successMessage)
            navigate(`/service/key-issues`)
        } catch (e) {
            toast.error(e)
        }
    }

    async function onDelete() {
        await keyIssueApi("delete", data.id);
        toast.success("Schlüsselausgabe gelöscht.");
        navigate(`/service/key-issues`)
    }

    function handleChange(evt) {
        baseHandleChange(evt, data, setData, errors, setErrors, schema);
    }

    function handleDateChange(date, path) {
        baseHandleDateChange(date, path, data, setData, errors, setErrors, schema)
    }

    if (!data || !options) return <></>
    return (
        <>
            <Page title={title}>
                <div
                    className="max-w-3xl mx-auto grid grid-cols-1 gap-6  lg:max-w-7xl lg:grid-flow-col-dense lg:grid-cols-6">
                    <div className="space-y-6 lg:col-span-4">
                        <Form
                            id="createKeyIssue"
                            onSave={onSave}
                            onDelete={onDelete}
                            data={data}
                            setData={setData}
                            errors={errors}
                            setErrors={setErrors}
                            successMessage={successMessage}
                            schema={schema}
                        >
                            <FormSectionContent>

                                <Select
                                    path="key"
                                    label="Schlüssel"
                                    className="sm:col-span-3"
                                    options={keys}
                                    valueAttr="id"
                                    labelAttr="key_number"
                                    onChange={handleChange}
                                    errors={errors}
                                    data={data}
                                    isClearable={false}
                                    valueOnly={false}
                                />

                                <Select
                                    path="user"
                                    label="Ausgabe an"
                                    className="sm:col-span-3"
                                    options={users}
                                    valueAttr="id"
                                    labelAttr="full_name"
                                    onChange={handleChange}
                                    errors={errors}
                                    data={data}
                                    isClearable={false}
                                    valueOnly={false}
                                />


                                <DateInput
                                    className="col-span-3"
                                    path="issue_date"
                                    label="Ausgabe am"
                                    onChange={handleDateChange}
                                    errors={errors}
                                    data={data}
                                    todayButton={true}
                                />

                                <DateInput
                                    className="col-span-3"
                                    path="return_date"
                                    label="Zurückgegeben am"
                                    onChange={handleDateChange}
                                    errors={errors}
                                    data={data}
                                    todayButton={true}
                                />

                                <Checkbox
                                    path="key_insurance"
                                    label="Schlüsselversicherung"
                                    className="sm:col-span-6"
                                    onChange={handleChange}
                                    errors={errors}
                                    hideLegend={true}
                                    data={data}
                                />

                                {data.created_by && <p className="mt-4 text-md sm:col-span-6">
                                    Erstellt von {data.created_by_full_name} am {formatDate(data.creation_date)}
                                </p>}

                            </FormSectionContent>


                        </Form>


                        <span
                            onClick={() => navigate(-1)}
                            className="cursor-pointer float-right mt-2 font-medium text-imsblue-600"
                        >Zurück</span>
                    </div>

                    <div className="lg:col-span-2 pl-3 sm:max-h-[80%] min-h-0 flex-1 overflow-y-auto">
                    </div>

                </div>
            </Page>
        </>
    );
}

