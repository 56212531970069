import http from "./../services/httpService";
import {genericApi} from "./generic";
import httpServiceDownloads from "../services/httpServiceDownloads";


// Houses
export async function getHouseList() {
    const resp = await http.get('/house_list/')
    return resp.data
}

export async function houseApi(method, id, data) {
    return genericApi('/houses/', method, id, data)
}

export async function getHouseExcel() {
    const resp = await httpServiceDownloads.get(`/houses/excel/`)
    return resp.data
}

export async function getHouseStudentDetail(studentId) {
    const resp = await http.get(`/houses/student_detail/?student_id=${studentId}`)
    return resp.data
}

// Teams
export async function teamApi(method, id, data) {
    return genericApi('/student_teams/', method, id, data)
}

// Main Mentors
export async function getStudentsMainMentors() {
    const resp = await http.get('/boarding_school/main_mentor_students/')
    return resp.data
}

// Support plan meetings
export async function supportPlanMeetingApi(method, id, data) {
    return genericApi('/support_plan_meetings/', method, id, data)
}

export async function addHandoutToSupportPlanMeeting(formData, id) {
    const resp = await http.post(`/support_plan_meetings/${id}/add_file/`, formData)
    return resp.data
}

export async function deleteSupportPlanMeetings(data) {
    const resp = await http.post(`/support_plan_meetings/delete_multiple/`, data)
    return resp.data
}

export async function getStudentListMentor() {
    const resp = await http.get('/houses/student_list_mentor/')
    return resp.data
}

export async function getMyHouses() {
    const resp = await http.get('/houses/my_houses/')
    return resp.data
}

// Assessments
export async function getHousesForAssessmentPeriod(assessmentPeriodId) {
    const resp = await http.get(`/boarding_school/assessments/${assessmentPeriodId}/houses/`)
    return resp.data
}

export async function getStudentsForHouse(assessmentPeriodId, houseId) {
    const resp = await http.get(`/boarding_school/assessments/${assessmentPeriodId}/houses/${houseId}/`)
    return resp.data
}

export async function boardingSchoolAssessmentApi(method, id, data) {
    return genericApi('/boarding_school_assessments/', method, id, data)
}
export async function finalizeBoardingSchoolAssessment(id) {
    const resp = await http.post(`/boarding_school/assessments/${id}/finalize/`)
    return resp.data
}

export async function getBoardingSchoolAssessmentsForStudent(assessmentPeriodId, studentId) {
    const resp = await http.get(`/boarding_school_assessments/?student_id=${studentId}&assessment_period_id=${assessmentPeriodId}`)
    return resp.data
}

export async function getBoardingSchoolAssessmentsForMentor(assessmentPeriodId, staffId) {
    const resp = await http.get(`/boarding_school/assessments/boarding_school_assessments_for_mentor/?staff_id=${staffId}&assessment_period_id=${assessmentPeriodId}`)
    return resp.data
}
export async function getBoardingSchoolAssessmentsForMentorTextOnlyPDF(assessmentPeriodId, staffId) {
    const resp = await httpServiceDownloads.get(`/boarding_school/assessments/boarding_school_assessments_for_mentor_text_only_pdf/?staff_id=${staffId}&assessment_period_id=${assessmentPeriodId}`)
    return resp.data
}

export async function getOpenBoardingSchoolAssessments(assessmentPeriodId) {
    const resp = await http.get(`/boarding_school/assessments/${assessmentPeriodId}/open/`)
    return resp.data
}

export async function finalizeOpenBoardingSchoolAssessments(assessmentPeriodId) {
    const resp = await http.post(`/boarding_school/assessments/${assessmentPeriodId}/finalize_all/`)
    return resp.data
}

export async function getDepartureOptions() {
    const resp = await http.get(`/departure_options/`)
    return resp.data
}

export async function getArrivalOptions() {
    const resp = await http.get(`/arrival_options/`)
    return resp.data
}

export async function getDepartureWeekends() {
    const resp = await http.get(`/departure_weekends/`)
    return resp.data
}

export async function getDepartureWeekend(id) {
    const resp = await http.get(`/departure_weekends/${id}/`)
    return resp.data
}

export async function getDepartureList(weekendId) {
    const resp = await http.get(`/departure_list_items/?weekend_id=${weekendId}`)
    return resp.data
}

export async function updateMultipleDepartureListItems(data) {
    const resp = await http.post(`/departure_list_items/update_multiple/`, data)
    return resp.data
}

export async function getDepartureListPDF(weekendId) {
    const resp = await httpServiceDownloads.get(`/boarding_school/departure_list_pdf/${weekendId}/`)
    return resp.data
}

export async function getDepartureListExcel(weekendId) {
    const resp = await httpServiceDownloads.get(`/boarding_school/departure_list_export/${weekendId}/`)
    return resp.data
}

export async function getBerlinListPDF(weekendId) {
    const resp = await httpServiceDownloads.get(`/boarding_school/departure_list_berlin/${weekendId}/`)
    return resp.data
}

export async function getHamburgListPDF(weekendId) {
    const resp = await httpServiceDownloads.get(`/boarding_school/departure_list_hamburg/${weekendId}/`)
    return resp.data
}

export async function getTaxiListPDF(weekendId) {
    const resp = await httpServiceDownloads.get(`/boarding_school/departure_list_taxi/${weekendId}/`)
    return resp.data
}