import {SimplePage} from "../../elements/Pages";
import React, {useEffect, useRef, useState} from "react";

import {useQuery} from "@tanstack/react-query";
import ListViewTable from "../../elements/tables/ListViewTable";
import {getTimetableChangesAula} from "../../../api/timetables";

const substitutionColumns = [
    {
        accessorKey: "date",
        id: "date",
        header: "Datum",
        columnType: "dateWithWeekday",
        enableColumnFilter: false,
        // classNames: "text-gray-500",
    },
    {
        accessorKey: "course.course_number",
        id: "course",
        header: "Kurs",
        enableColumnFilter: false,
        disableRowLink: true
    },
    {
        accessorKey: "course.school_subject",
        id: "subject",
        header: "Fach",
        enableColumnFilter: false,
    },
    {
        accessorKey: "periods",
        id: "periods",
        header: "Zeit",
        columnType: "m2m",
        labelAttr: "name",
        enableColumnFilter: false
    },
    {
        accessorKey: "replaced_teacher.full_name",
        id: "replaced_teacher",
        header: "Es fehlt",
        enableColumnFilter: false
    },
    {
        accessorKey: "replacing_teacher.full_name",
        id: "replacing_teacher",
        header: "Vertretung durch",
        enableColumnFilter: false,
        disableRowLink: true
    },
    {
        accessorKey: "room.name",
        id: "room",
        header: "Raum",
        enableColumnFilter: false,
    },
    {
        accessorKey: "remarks",
        id: "remarks",
        header: "Anmerkungen",
        enableColumnFilter: false,
    },
    // {
    //     accessorKey: "confirmed",
    //     id: "confirmed",
    //     header: "Bestätigt",
    //     columnType: "yesno",
    //     enableColumnFilter: false,
    // }
];

const cancellationColumns = [
    {
        accessorKey: "date",
        id: "date",
        header: "Datum",
        columnType: "dateWithWeekday",
        enableColumnFilter: false,
        // classNames: 'font-medium text-gray-900'
        classNames: "text-gray-500",
    },
    {
        accessorKey: "course.course_number",
        id: "course",
        header: "Kurs",
        enableColumnFilter: false,
        disableRowLink: true
    },
    {
        accessorKey: "course.school_subject",
        id: "subject",
        header: "Fach",
        enableColumnFilter: false,
    },
    {
        accessorKey: "periods",
        id: "periods",
        header: "Zeit",
        columnType: "m2m",
        labelAttr: "name",
        enableColumnFilter: false
    },
    {
        accessorKey: "remarks",
        id: "remarks",
        header: "Anmerkungen",
        enableColumnFilter: false,
    }
];

const roomChangesColumns = [
    {
        accessorKey: "date",
        id: "date",
        header: "Datum",
        columnType: "dateWithWeekday",
        enableColumnFilter: false,
        // classNames: 'font-medium text-gray-900'
        classNames: "text-gray-500",
    },
    {
        accessorKey: "course.course_number",
        id: "course",
        header: "Kurs",
        enableColumnFilter: false
    },
    {
        accessorKey: "course.school_subject",
        id: "subject",
        header: "Fach",
        enableColumnFilter: false,
    },
    {
        accessorKey: "periods",
        id: "periods",
        header: "Zeit",
        columnType: "m2m",
        labelAttr: "name",
        enableColumnFilter: false
    },
    {
        accessorKey: "room.name",
        id: "room",
        header: "Raum",
        enableColumnFilter: false,
    },
    {
        accessorKey: "remarks",
        id: "remarks",
        header: "Anmerkungen",
        enableColumnFilter: false,
    }
];

const projectCancellationColumns = [
    {
        accessorKey: "date",
        id: "date",
        header: "Datum",
        columnType: "dateWithWeekday",
        enableColumnFilter: false,
        // classNames: 'font-medium text-gray-900'
        classNames: "text-gray-500",
    },
    {
        accessorKey: "project.name",
        id: "project",
        header: "Projekt",
        enableColumnFilter: false
    },
    {
        accessorKey: "remarks",
        id: "remarks",
        header: "Anmerkungen",
        enableColumnFilter: false,
    }
];

export default function Aula() {

    const {isLoading, isError, isSuccess, data, error, refetch} = useQuery(
        ["timetable_changes_aula"],
        () => getTimetableChangesAula(),
        {
            refetchInterval: 1000 * 60 * 5 // refetch every five minutes
        }
    )

    const scrollContainerRef = useRef(null);
    const [enableScroll, setEnableScroll] = useState(false);

    // Function to update the scroll based on content height
    const updateScroll = () => {
        const scrollContainer = scrollContainerRef.current;
        const scrollContent = scrollContainer.firstChild;

        if (scrollContent.scrollHeight > scrollContainer.clientHeight) {
            setEnableScroll(true);
        } else {
            setEnableScroll(false);
        }
    };

    // useEffect to check the scroll on mount and on window resize
    useEffect(() => {
        updateScroll();
        window.addEventListener('resize', updateScroll);
        return () => window.removeEventListener('resize', updateScroll); // Cleanup on unmount
    }, []);

    return (
        <SimplePage title="Vertretungen, Raumwechsel & Ausfälle">

                <div ref={scrollContainerRef} className={`auto-infinite-scroll ${enableScroll ? 'enable-scroll' : ''}`}>

                    <div className="px-4 sm:px-6 lg:px-8 auto-infinite-scroll-content">

                        <div className="sm:flex sm:items-center">
                            <div className="sm:flex-auto">
                                <h3 className="mb-4 text-lg leading-6 font-medium text-gray-900">
                                    Vertretungen, Raumwechsel & Ausfälle
                                </h3>
                            </div>
                            <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
                            </div>
                        </div>
                        <div className="flex flex-col">
                            <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">

                                <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                                    <div className="sm:flex sm:items-center">
                                        <div className="sm:flex-auto">
                                            <h3 className="text-lg leading-6 font-medium text-gray-900">
                                                Vertretungen
                                            </h3>
                                        </div>
                                        <div className="mt-4 sm:mt-0 sm:ml-16 flex gap-2 ">
                                        </div>
                                    </div>
                                    <div className="flex flex-col">
                                        {isSuccess && data && data?.substitutions.length > 0 && (
                                            <ListViewTable
                                                data={data.substitutions}
                                                columns={substitutionColumns}
                                                isLoading={isLoading}
                                                disableGlobalSearch={true}
                                                getRowLink={null}
                                            />
                                        )}
                                    </div>
                                </div>

                                <div className="mt-4 inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                                    <div className="sm:flex sm:items-center">
                                        <div className="sm:flex-auto">
                                            <h3 className="text-lg leading-6 font-medium text-gray-900">
                                                Ausfall
                                            </h3>
                                        </div>
                                        <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
                                        </div>
                                    </div>
                                    <div className="flex flex-col">
                                        {isSuccess && data && data?.cancellations.length > 0 && (
                                            <ListViewTable
                                                data={data.cancellations}
                                                columns={cancellationColumns}
                                                isLoading={isLoading}
                                                disableGlobalSearch={true}
                                                getRowLink={null}
                                            />
                                        )}
                                    </div>
                                </div>

                                <div className="mt-4 inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                                    <div className="sm:flex sm:items-center">
                                        <div className="sm:flex-auto">
                                            <h3 className="text-lg leading-6 font-medium text-gray-900">
                                                Raumwechsel
                                            </h3>
                                        </div>
                                        <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
                                        </div>
                                    </div>
                                    <div className="flex flex-col">
                                        {isSuccess && data && data?.room_changes.length > 0 && (
                                            <ListViewTable
                                                data={data.room_changes}
                                                columns={roomChangesColumns}
                                                isLoading={isLoading}
                                                disableGlobalSearch={true}
                                                getRowLink={null}
                                            />
                                        )}
                                    </div>
                                </div>

                                <div className="mt-4 inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                                    <div className="sm:flex sm:items-center">
                                        <div className="sm:flex-auto">
                                            <h3 className="text-lg leading-6 font-medium text-gray-900">
                                                Projektausfall
                                            </h3>
                                        </div>
                                        <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
                                        </div>
                                    </div>
                                    <div className="flex flex-col">
                                        {isSuccess && data && data?.project_cancellations.length > 0 && (
                                            <ListViewTable
                                                data={data.project_cancellations}
                                                columns={projectCancellationColumns}
                                                isLoading={isLoading}
                                                disableGlobalSearch={true}
                                                getRowLink={null}
                                            />
                                        )}
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
        </SimplePage>
    )
}
