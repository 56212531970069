import React from "react";
import {SimplePage} from "../../elements/Pages";
import {useQuery} from "@tanstack/react-query";
import {
    deleteStudierzeitChangesForStudents,
    getStudierzeitList,
    getStudierzeitStudentList, startStudierzeitListDownloadGeneration
} from "../../../api/studierzeit";
import {AddButton, AsyncDownloadButton, EditButton, ListButton} from "../../elements/Buttons";
import {Link} from "react-router-dom";
import ListViewTable, {IndeterminateCheckbox, TableDownloadLink, TableEditLink, TableLink} from "../../elements/tables/ListViewTable";
import {useState} from "react";
import {getParticipantListPDF} from "../../../api/projects";
import {CalendarIcon, DocumentCheckIcon} from "@heroicons/react/20/solid";

import env from "react-dotenv";
import {startAllAppointmentListGeneration} from "../../../api/parent_conference";
import {getStudents, startNotenblattGeneration} from "../../../api/grades";

const columns = [
    {
        accessorFn: (row) =>
            `${row.last_name}, ${row.first_name} ${row.student_number}`,
        id: "student_search",
        header: "SchülerIn",
        hide: true,
        enableColumnFilter: false,
    },
    {
        accessorFn: (row) => row,
        id: 'student',
        header: 'SchülerIn',
        columnType: 'student',
        enableColumnFilter: false,
        sortingFn: "lastNameSorting",
    },
    ...env.SCHOOL === 'KPI' ? [{
        accessorKey: "current_plupu_rating",
        id: "current_plupu_rating",
        header: "Status",
        columnType: "plupu_rating",
        enableColumnFilter: true,
        headerClassNames: "narrow-filter",
    }] : [],
    {
        accessorKey: "grade",
        id: "grade",
        header: "Stufe",
        enableColumnFilter: true,
        headerClassNames: "narrow-filter",
    },
    {
        accessorKey: "school_class",
        id: "school_class",
        header: "Klasse",
        sortingFn: "schoolClassSorting",
        enableColumnFilter: true,
        headerClassNames: "narrow-filter",
    },
    {
        accessorKey: "result_line_count",
        id: "result_line_count",
        header: "Noten",
        enableColumnFilter: false,
        headerClassNames: "narrow-filter",
    },
    {
        accessorKey: "id",
        id: "links",
        cell: ({row, value}) => (
            <>
                <div className="flex gap-2 justify-between">
                    <TableLink
                        label="Notenblatt"
                        to={`/grades/students/${row.original.id}/notenblatt`}
                    />
                    <TableLink
                        label="Sondernoten"
                        to={`/grades/students/${row.original.id}/special-grades`}
                    />
                </div>
            </>
        ),
        header: "Links",
        enableColumnFilter: false,
        disableRowLink: true
    }
];


export default function Students() {

    const {
        isLoading,
        isSuccess,
        data,
        refetch
    } = useQuery(["grades_students"], getStudents, {refetchInterval: 1000 * 60 * 60 * 24})


    function getRowLink(row) {
        return `/grades/students/${row.original.id}/notenblatt`
    }

    return (
        <>
            <SimplePage>
                <div className="px-4 sm:px-6 lg:px-8">
                    <div className="sm:flex sm:items-center">
                        <div className="sm:flex-auto">
                            <h3 className="text-lg leading-6 font-medium text-gray-900">
                                Noten nach Schülern
                            </h3>
                        </div>
                        <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none flex gap-1">
                            <AsyncDownloadButton
                                apiFunction={() => startNotenblattGeneration()}
                                label="Alle Notenlisten generierien" />
                        </div>
                    </div>

                    <div className="flex flex-col mt-3 mb-3 scrollbarHeight">
                        <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
                            <div className="inline-block py-2 align-middle md:px-6 lg:px-8">

                                {isSuccess && data && (
                                    <ListViewTable
                                        data={data}
                                        columns={columns}
                                        isLoading={isLoading}
                                        disableGlobalSearch={false}
                                        getRowLink={getRowLink}
                                    />
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </SimplePage>

        </>
    );
}
