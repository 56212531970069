import React from 'react';
import {Route, Routes} from "react-router-dom";
import WorkshopDays from "./WorkshopDays";
import Workshops from "./Workshops";
import WorkshopDetail from "./WorkshopDetail";
import Students from "./Students";
import StudentDetail from "./StudentDetail";
import MyBookings from "./MyBookings";


function WorkshopDayRouter() {

    return (
        <Routes>
            <Route index element={<WorkshopDays/>}/>
            <Route path="/:workshopDayId/my" element={<MyBookings/>}/>
            <Route path="/:workshopDayId/workshops" element={<Workshops/>}/>
            <Route path="/workshops/:id" element={<WorkshopDetail/>}/>

            <Route path="/:workshopDayId/students" element={<Students/>}/>
            <Route path="/:workshopDayId/students/:studentId" element={<StudentDetail/>}/>
        </Routes>
    )
}


export default function WorkshopDay() {
    return <WorkshopDayRouter/>
}
