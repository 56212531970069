import BasicModal from "../../elements/modal";
import {formatTimeFromDateTime} from "../../../utils/formatters";
import {MultiSelect} from "../../elements/forms/Inputs";
import {PrimaryButton} from "../../elements/Buttons";
import React from "react";

export default function EditBookingModal({open, setOpen, booking, onMultiSelect, staff, onSave}) {

    return (
        <BasicModal open={open} setOpen={setOpen}>
            <div
                className="flex flex-col"
                style={{
                    maxHeight: "80vh",
                    minHeight: "500px",
                    overflowY: "auto",
                }}
            >
                <h3 className="text-lg leading-6 font-medium text-gray-900 mb-2">Buchung bearbeiten</h3>

                {booking &&
                    <>
                        <p className="text-gray-900 mb-2">
                            {formatTimeFromDateTime(booking.datetime_from)} - {formatTimeFromDateTime(booking.datetime_to)}
                        </p>
                        <p className="text-sm text-gray-900">
                            SchülerIn: {booking.student.full_name} <br/>
                            MitarbeiterIn: {booking.teacher.full_name}
                        </p>
                    </>
                }

                {staff && <MultiSelect
                    path="additional_participants"
                    label="Zusätzliche TeilnehmerInnen:"
                    className="sm:col-span-6 my-4"
                    options={staff}
                    valueAttr="id"
                    labelAttr="full_name"
                    onChange={(selectedOptions) => onMultiSelect(selectedOptions, 'additional_participants')}
                    data={booking}
                    isClearable={true}
                    valueOnly={false}
                />}
                <PrimaryButton classNameIn="float-right" onClick={onSave} label="Speichern"/>

            </div>
        </BasicModal>
    )
}