import env from "react-dotenv";


const local = {
    API_URL: "http://localhost:8000/api",
    ADMIN_URL: "http://localhost:8000/admin",
}

const stagingKpi = {
    API_URL: "https://api-test.kurpfalz-internat.de/api/",
    ADMIN_URL: "https://api-test.kurpfalz-internat.de/admin/",
}

const stagingSt = {
    API_URL: "https://api-staging.schlosstorgelow.de/api/",
    ADMIN_URL: "https://api-staging.schlosstorgelow.de/admin/",
}

const productionKpi = {
    API_URL: "https://api.kurpfalz-internat.de/api",
    ADMIN_URL: "https://api.kurpfalz-internat.de/admin",
}

const productionSt = {
    API_URL: "https://api.schlosstorgelow.de/api",
    ADMIN_URL: "https://api.schlosstorgelow.de/admin",
}

let config;

if (env.REACT_APP_STAGE === 'local') {
    config = local
} else if (env.REACT_APP_STAGE === 'production') {
    if (env.SCHOOL === 'KPI') config = productionKpi
    else config = productionSt
} else {
    if (env.SCHOOL === 'KPI') config = stagingKpi
    else config = stagingSt
}

export default config;
