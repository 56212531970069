export const validateData = (data, columns) => {

    let newData = [...data]

    function funcOne(obj, keys) {
        const keys_list = [...keys]
        const first_key = keys_list.shift()

        if (first_key) {
            if (keys_list.length > 0) {
                if (obj[first_key]) {
                    funcOne(obj[first_key], keys_list)
                } else {
                    obj[first_key] = {}
                    funcOne(obj[first_key], keys_list)
                }
            } else {
                if (!obj[first_key]) {
                    obj[first_key] = ""
                }
            }
        }
    }

    newData.map(item => {
        columns.map(column => {
            const keys = column.accessorKey ? column.accessorKey.split('.') : []
            funcOne(item, keys);
        })
    })

    return newData
}
