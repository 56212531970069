import {DetailPage} from "../../elements/Pages";
import {React} from 'react'
import {useQuery} from '@tanstack/react-query'
import {useAuth} from "../../../contexts/AuthContext";
import checkPermission from "../../../utils/permissions";
import moment from "moment";
import {Link, useParams} from "react-router-dom";
import {PencilSquareIcon} from "@heroicons/react/20/solid";
import {classNames} from "../../../utils/misc";
import {PrimaryButton, SecondaryButton} from "../../elements/Buttons";
import {classRegisterEntryApi} from "../../../api/class_register";
import {formatDate} from "../../../utils/formatters";


export default function ClassRegisterEntryDetail() {

    const {id} = useParams()
    const {permissions} = useAuth()

    const {
        data
    } = useQuery(['class_register_entry', id], () => classRegisterEntryApi('get', id), {})

    if (!data) return <></>
    return (

        <DetailPage>
            <div
                className="max-w-3xl mx-auto px-4 sm:px-6 md:flex md:items-center md:justify-between md:space-x-5 lg:max-w-7xl lg:px-8">
                <div className="flex items-center space-x-5">
                    <div>
                        <h1 className="text-2xl font-bold text-gray-900">Kursbucheintrag {formatDate(data.date)} {data.course.course_number}</h1>
                        <p className="text-md font-medium text-gray-500">
                            {data.course.school_subject.name}
                        </p>
                    </div>
                </div>
                <div
                    className="mt-6 flex flex-col-reverse justify-stretch space-y-4 space-y-reverse sm:flex-row-reverse sm:justify-end sm:space-x-reverse sm:space-y-0 sm:space-x-3 md:mt-0 md:flex-row md:space-x-3">

                    {data.can_edit &&
                        <SecondaryButton
                            link={`/class-register/entries/${data.id}/edit`}
                            label="Bearbeiten"
                        />}

                    {checkPermission(permissions, 'student_logbook.add_penalty') &&
                        <PrimaryButton
                            link={`/class-register/courses/${data.course.id}`}
                            label="Kursbuch"
                        />}
                </div>
            </div>

            <div
                className="mt-8 max-w-3xl mx-auto grid grid-cols-1 gap-6 sm:px-6 lg:max-w-7xl lg:grid-flow-col-dense lg:grid-cols-3">
                <div className="space-y-6 lg:col-start-1 lg:col-span-2">
                    {/* Description list*/}
                    <section aria-labelledby="applicant-information-title">
                        <div className="bg-white shadow overflow-hidden sm:rounded-lg">
                            {/*<div className="px-4 py-5 sm:px-6">*/}
                            {/*    <h3 className="text-lg leading-6 font-medium text-gray-900">Applicant*/}
                            {/*        Information</h3>*/}
                            {/*    <p className="mt-1 max-w-2xl text-sm text-gray-500">Personal details and*/}
                            {/*        application.</p>*/}
                            {/*</div>*/}
                            <div className="border-t border-gray-200 px-4 py-5 sm:p-0">
                                <dl className="sm:divide-y sm:divide-gray-200">
                                    <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                        <dt className="text-sm font-medium text-gray-500">Stunde</dt>
                                        <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                            {data.day_period.map(u => u.name).join(', ')}
                                        </dd>
                                    </div>
                                    <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                        <dt className="text-sm font-medium text-gray-500">Ausfall</dt>
                                        <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                            {data.cancelled ? "Ja" : "Nein"}
                                            {data.cancelled && <span><br/>{data.cancel_reason}</span>}
                                        </dd>
                                    </div>
                                    <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                        <dt className="text-sm font-medium text-gray-500">Erstellt von</dt>
                                        <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{data.created_by_full_name}
                                        </dd>
                                    </div>
                                    <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                        <dt className="text-sm font-medium text-gray-500">Erstellt am</dt>
                                        <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{formatDate(data.creation_date)}
                                        </dd>
                                    </div>
                                    <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                        <dt className="text-sm font-medium text-gray-500">Unterrichtsinhalt</dt>
                                        <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2 whitespace-pre-wrap">
                                            {data.lesson_contents}
                                        </dd>
                                    </div>
                                    <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                        <dt className="text-sm font-medium text-gray-500">Fehlende Materialien</dt>
                                        <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2 whitespace-pre-wrap">
                                            <ul>{data.students_with_missing_materials.map((student, index) => (
                                                <li key={index}><Link className="font-medium text-imsblue-600"
                                                                      to={`/school/students/${student.id}`}>{student.full_name}</Link>
                                                </li>
                                            ))}</ul>
                                        </dd>
                                    </div>
                                    <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                        <dt className="text-sm font-medium text-gray-500">Fehlende Hausaufgaben</dt>
                                        <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2 whitespace-pre-wrap">
                                            <ul>{data.students_with_missing_homework.map((student, index) => (
                                                <li key={index}><Link className="font-medium text-imsblue-600"
                                                                      to={`/school/students/${student.id}`}>{student.full_name}</Link>
                                                </li>
                                            ))}</ul>
                                        </dd>
                                    </div>
                                </dl>
                            </div>
                        </div>
                    </section>

                </div>
                <section aria-labelledby="timeline-title" className="lg:col-start-3 lg:col-span-1">
                    <div className="bg-white px-4 py-5 shadow sm:rounded-lg sm:px-6">
                        <h2 id="timeline-title" className="text-lg font-medium text-gray-900">
                            Hausaufgaben
                        </h2>

                        {/* Homework */}
                        {data.homework && <div className="mt-6 flow-root">
                            <ul role="list" className="-mb-8">
                                {data.homework.map((item, itemIdx) => (
                                    <li key={item.id}>
                                        <div className="relative pb-8">
                                            {itemIdx !== data.homework.length - 1 ? (
                                                <span
                                                    className="absolute top-4 left-4 -ml-px h-full w-0.5 bg-gray-200"
                                                    aria-hidden="true"
                                                />
                                            ) : null}
                                            <div className="relative flex space-x-3">
                                                <div>

                              <span
                                  className={classNames(
                                      "bg-gray-400",
                                      'h-8 w-8 rounded-full flex items-center justify-center ring-8 ring-white'
                                  )}
                              >
                                <PencilSquareIcon className="w-5 h-5 text-white" aria-hidden="true"/>
                              </span>
                                                </div>
                                                <div
                                                    className="min-w-0 flex-1 pt-1.5 flex justify-between space-x-4">
                                                    <div>
                                                        {/*<h4 className="mt-4 text-md leading-6 font-medium text-gray-900">{item.title}</h4>*/}
                                                        <p className="text-sm text-gray-500">
                                                            <Link className="text-imsblue-600"
                                                                  to={`/class-register/homework/${item.id}`}>{item.task}</Link>
                                                        </p>
                                                    </div>
                                                    <div
                                                        className="text-right text-sm whitespace-nowrap text-gray-500">
                                                        zum {moment(item.due_date).format("DD.MM.YYYY")}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                ))}
                            </ul>
                        </div>}
                        {/*<div className="mt-6 flex flex-col justify-stretch">*/}
                        {/*    <PrimaryButton classNameIn="w-full"*/}
                        {/*                   link={`/class-register/courses/${data.course.id}`}*/}
                        {/*                   label="Alle Hausaufgaben & Klausuren ansehen"/>*/}
                        {/*</div>*/}
                    </div>
                </section>
            </div>

        </DetailPage>
    )
}
