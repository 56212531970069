import React, {useContext} from "react";
import {useQuery} from "@tanstack/react-query";
import {getBookableRooms, getRoomBookingsByWeek} from "../api/organizational";
import {useEffect, useState, useMemo} from "react";
import {getWeekNumber} from "../utils/helpers";
import {useNavigate} from "react-router-dom";

const keys = ["id"];
const RoomBookingContext = React.createContext();
const today = new Date();

export function useRoomBookings() {
    return useContext(RoomBookingContext);
}

export function RoomBookingProvider({children}) {
    const [view, setView] = useState("week");
    const [selectedDate, setSelectedDate] = useState({
        year: today.getFullYear(),
        month: today.getMonth() + 1,
        date: today.getDate(),
    });
    const [selectedDateWeek, setSelectedDateWeek] = useState(
        getWeekNumber(today)
    );
    const [filter, setFilter] = useState({
        room: null,
        groups: [],
    });
    const navigate = useNavigate();
    const [selectedEvent, setSelectedEvent] = useState(null);
    const [eventDetailOpen, setEventDetailOpen] = useState(false);
    // const [eventCategoriesMonth, setEventCategoriesMonth] = useState([]);
    const [eventtargetGroupsMonth, setEventtargetGroupsMonth] = useState([]);
    // const [eventCategoriesWeek, setEventCategoriesWeek] = useState([]);
    const [eventtargetGroupsWeek, setEventtargetGroupsWeek] = useState([]);
    const [loaded, setLoaded] = useState(false);

    const loadingMonth = false
    const {
        data: eventsByWeek,
        loading: loadingWeek,
        isRefetching: isRefetchingWeek,
    } = useQuery(["room_bookings", selectedDateWeek], () =>
            getRoomBookingsByWeek(selectedDateWeek[0], selectedDateWeek[1]),
        {refetchInterval: 1000 * 60 * 60}
    )

    const {data: bookableRooms} = useQuery(["bookable_rooms"], getBookableRooms, {refetchInterval: 1000 * 60 * 60 * 24})

    const openEvent = (event) => {
        setSelectedEvent(event);
        setEventDetailOpen(true);
        navigate(
            `/service/room-bookings/calendar?event_id=${event.event_id}&year=${selectedDate.year}&week=${selectedDateWeek[1]+1}&date=${selectedDate.date}`
        );
        // setSelectedEvent(event)
        // setEventDetailOpen(true);
        // const startDate = new Date(event.start)
        // const useDate = {
        //     year: startDate.getFullYear(),
        //     month: startDate.getMonth() + 1,
        //     date: startDate.getDate(),
        //     week: getWeekNumber(startDate)[1] + 1
        // }
        // if (view === "month")
        //     navigate(
        //         `/service/room-bookings/calendar?event_id=${event.event_id}&year=${useDate.year}&month=${useDate.month}`
        //     );
        // else
        //     navigate(
        //         `/service/room-bookings/calendar?event_id=${event.event_id}&year=${useDate.year}&week=${useDate.week}&date=${useDate.date}`
        //     );
    };
    useEffect(() => {
        const weekNumber = getWeekNumber(
            new Date(selectedDate.year, selectedDate.month-1, selectedDate.date)
        );
        setSelectedDateWeek(weekNumber);
    }, [selectedDate]);
    useEffect(() => {
        // todo probably make sure we maintain the selected room
        if (!eventDetailOpen && loaded) navigate("/service/room-bookings/calendar");
        else setLoaded(true);
    }, [eventDetailOpen]);

    const fetchedEventsMonth = []
    // const fetchedEventsMonth = useMemo(() => {
    //     if (eventsByMonth) {
    //         if (filter.rooms.length || filter.groups.length) {
    //             let filteredEvents = [];
    //             if (filter.rooms.length) {
    //                 filteredEvents = [
    //                     ...filteredEvents,
    //                     ...eventsByMonth.filter((i) =>
    //                         filter.rooms.includes(i.event.category.id)
    //                     ),
    //                 ];
    //             }
    //             if (filter.groups.length) {
    //                 filteredEvents = [
    //                     ...filteredEvents,
    //                     ...eventsByMonth.filter((i) =>
    //                         i.event.target_groups.some((j) => filter.groups.includes(j.id))
    //                     ),
    //                 ];
    //             }
    //             return filteredEvents;
    //         }
    //         return eventsByMonth;
    //     }
    //     return null;
    // }, [eventsByMonth, filter]);
    const fetchedEventsWeek = useMemo(() => {
        if (eventsByWeek) {
            if (filter.room) {
                let filteredEvents = [];
                filteredEvents = [
                    ...filteredEvents,
                    ...eventsByWeek.filter((i) =>
                        filter.room === i.event.room.id
                    ),
                ];
                return filteredEvents;
            }
            return eventsByWeek;
        }
        return null;
    }, [eventsByWeek, filter]);


    // useEffect(() => {
    //     if (eventsByWeek?.length) {
    //         const uniqueCategories = [
    //             ...new Set(eventsByWeek.map((event) => event.event.room)),
    //         ];
    //         try {
    //             setEventCategoriesWeek(
    //                 uniqueCategories.filter(
    //                     (value, index, self) =>
    //                         self.findIndex((v) => keys.every((k) => v[k] === value[k])) ===
    //                         index
    //                 )
    //             );
    //         } catch (e) {
    //         }
    //     }
    // }, [eventsByWeek]);

    const value = {
        selectedDate,
        setSelectedDate,
        fetchedEventsMonth,
        fetchedEventsWeek,
        view,
        setView,
        selectedEvent,
        openEvent,
        eventDetailOpen,
        setEventDetailOpen,
        bookableRooms,
        // eventCategoriesMonth,
        // eventCategoriesWeek,
        eventtargetGroupsMonth,
        eventtargetGroupsWeek,
        filter,
        setFilter,
        loadingWeek: loadingWeek || isRefetchingWeek,
    };

    return (
        <RoomBookingContext.Provider value={value}>
            {!loadingWeek && !loadingMonth && children}
        </RoomBookingContext.Provider>
    );
}
