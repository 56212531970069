import {DetailPage} from "../../elements/Pages";
import {React} from 'react'
import {getLogbookEntry, getLogbookEntryTimeline} from '../../../api/logbook'

import {useQuery} from '@tanstack/react-query'
import {useAuth} from "../../../contexts/AuthContext";
import checkPermission from "../../../utils/permissions";
import moment from "moment";
import {Link, useParams} from "react-router-dom";
import {ChatBubbleLeftIcon} from "@heroicons/react/20/solid";
import {classNames} from "../../../utils/misc";
import {PrimaryButton, SecondaryButton} from "../../elements/Buttons";
import {useUserInfo} from "../../../contexts/UserInfoContext";
import env from "react-dotenv";


export default function LogbookEntryDetail() {

    const {id} = useParams()
    const {permissions} = useAuth()
    const {renderUserImage} = useUserInfo();

    const {isLoading, isError, isSuccess, data} = useQuery(['logbook_entry', id], () => getLogbookEntry(id), {})
    const {data: timelineData} = useQuery(['logbook_entry_timeline', id], () => getLogbookEntryTimeline(id))

    if (!data) return <></>
    return (

        <DetailPage>
            <div
                className="max-w-3xl mx-auto px-4 sm:px-6 md:flex md:items-center md:justify-between md:space-x-5 lg:max-w-7xl lg:px-8">
                <div className="flex items-center space-x-5">
                    <div className="flex-shrink-0">
                        <div className="relative">
                            {renderUserImage(data.student.user_id, 80)}
                            <span className="absolute inset-0 shadow-inner rounded-full" aria-hidden="true"/>
                        </div>
                    </div>
                    <div>
                        <h1 className="text-2xl font-bold text-gray-900">{data.title}</h1>
                        <p className="text-sm font-medium text-gray-500">
                            über <Link to={`/school/students/${data.student.id}`}
                                       className="text-gray-900">{data.student.full_name}</Link>
                            , {data.student.student_number} vom {moment(data.creation_date).format("DD.MM.YYYY")}
                        </p>
                    </div>
                </div>
                <div
                    className="mt-6 flex flex-col-reverse justify-stretch space-y-4 space-y-reverse sm:flex-row-reverse sm:justify-end sm:space-x-reverse sm:space-y-0 sm:space-x-3 md:mt-0 md:flex-row md:space-x-3">

                    {checkPermission(permissions, 'student_logbook.view_logbookentry') &&
                        <SecondaryButton
                            link={`/logbook/entries/${data.id}/edit`}
                            label="Bearbeiten"
                        />}

                    {checkPermission(permissions, 'student_logbook.add_penalty') &&
                        <PrimaryButton
                            link={`/logbook/penalties/new/${data.student.id}`}
                            label="Strafe erteilen"
                        />}
                </div>
            </div>

            <div
                className="mt-8 max-w-3xl mx-auto grid grid-cols-1 gap-6 sm:px-6 lg:max-w-7xl lg:grid-flow-col-dense lg:grid-cols-3">
                <div className="space-y-6 lg:col-start-1 lg:col-span-2">
                    {/* Description list*/}
                    <section aria-labelledby="applicant-information-title">
                        <div className="bg-white shadow overflow-hidden sm:rounded-lg">
                            <div className="border-t border-gray-200 px-4 py-5 sm:p-0">
                                <dl className="sm:divide-y sm:divide-gray-200">
                                    <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                        <dt className="text-sm font-medium text-gray-500">Erstellt von</dt>
                                        <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{data.created_by_full_name}
                                        </dd>
                                    </div>
                                    <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                        <dt className="text-sm font-medium text-gray-500">Kategorie</dt>
                                        <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{data.categories.map((category) => category.name).join(', ')}
                                        </dd>
                                    </div>
                                    <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                        <dt className="text-sm font-medium text-gray-500">Sichtbarkeit</dt>
                                        <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{data.get_visibility_display}</dd>
                                    </div>
                                    <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                        <dt className="text-sm font-medium text-gray-500">Eintrag</dt>
                                        <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2 whitespace-pre-wrap">
                                            {data.entry}
                                        </dd>
                                    </div>
                                    {env.SCHOOL !== 'KPI' && <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                        <dt className="text-sm font-medium text-gray-500">Strafeinträge</dt>
                                        <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{data.number_of_penalties}
                                            {data.number_of_penalties > 0 && <> ({data.penalties_open} offen, {data.penalties_time_barred} verjährt)</>}
                                        </dd>
                                    </div>}
                                    {env.SCHOOL === 'KPI' && data.plupu_points != 0 && <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                        <dt className="text-sm font-medium text-gray-500">PluPu</dt>
                                        <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{data.plupu_points}</dd>
                                    </div>}
                                </dl>
                            </div>
                        </div>
                    </section>

                </div>
                <section aria-labelledby="timeline-title" className="lg:col-start-3 lg:col-span-1">
                    <div className="bg-white px-4 py-5 shadow sm:rounded-lg sm:px-6">
                        <h2 id="timeline-title" className="text-lg font-medium text-gray-900">
                            Weitere Einträge
                        </h2>

                        {/* Activity Feed */}
                        {timelineData && <div className="mt-6 flow-root">
                            <ul role="list" className="-mb-8">
                                {timelineData.map((item, itemIdx) => (
                                    <li key={item.id}>
                                        <div className="relative pb-8">
                                            {itemIdx !== timelineData.length - 1 ? (
                                                <span
                                                    className="absolute top-4 left-4 -ml-px h-full w-0.5 bg-gray-200"
                                                    aria-hidden="true"
                                                />
                                            ) : null}
                                            <div className="relative flex space-x-3">
                                                <div>

                              <span
                                  className={classNames(
                                      "bg-gray-400",
                                      'h-8 w-8 rounded-full flex items-center justify-center ring-8 ring-white'
                                  )}
                              >
                                <ChatBubbleLeftIcon className="w-5 h-5 text-white" aria-hidden="true"/>
                              </span>

                                                </div>
                                                <div className="min-w-0 flex-1 flex justify-between space-x-4">
                                                    <div>
                                                        <p className="text-sm whitespace-nowrap text-gray-500">
                                                            {moment(item.creation_date).format("DD.MM.YYYY")}
                                                        </p>
                                                        <p className="text-sm text-gray-500">
                                                            <Link to={`/logbook/entries/${item.id}`}
                                                                  className="font-medium text-gray-900">
                                                                {item.title}
                                                            </Link> von {item.created_by_full_name}
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                ))}
                            </ul>
                        </div>}
                        <div className="mt-6 flex flex-col justify-stretch">
                            <PrimaryButton classNameIn="w-full"
                                           link={`/logbook/students/${data.student.id}`} label="Alle ansehen"/>
                        </div>
                    </div>
                </section>
            </div>

        </DetailPage>
    )
}
