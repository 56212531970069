import {SimplePage} from "../../elements/Pages";
import React from "react";

import {useQuery} from "@tanstack/react-query";
import {useAuth} from "../../../contexts/AuthContext";
import ListViewTable from "../../elements/tables/ListViewTable";
import {DownloadButton} from "../../elements/Buttons";
import {formatDateTime} from "../../../utils/formatters";
import {getWorkshopDayPDF, getWorkshopList} from "../../../api/workshop_day";
import {useParams} from "react-router-dom";
import {CheckCircleIcon} from "@heroicons/react/20/solid";


export default function Workshops() {

    const {workshopDayId} = useParams()
    const {permissions} = useAuth();

    const {isLoading, isSuccess, data} = useQuery(
        ["workshop_days", workshopDayId],
        () => getWorkshopList(workshopDayId),
        {refetchInterval: 1000 * 60 * 60}
    );

    function getRowLink(row) {
        return `/workshop-day/workshops/${row.original.id}/`;
    }

    if (!data) return <></>

    const canBook = data.bookings_open

    let columns = [
        {
            accessorFn: (row) => `${formatDateTime(row.datetime_from)} - ${formatDateTime(row.datetime_to)}`,
            id: "date",
            header: "Datum",
            enableColumnFilter: false,
            // headerClassNames: "medium-filter"
        },
        {
            accessorKey: "title",
            id: "title",
            header: "Titel",
            enableColumnFilter: false
        },
        {
            accessorKey: "category.title",
            id: "category",
            header: "Art",
            enableColumnFilter: true,
            labelAttr: "name",
            filterFn: "containsString",
            headerClassNames: "medium-filter"
        },
        {
            accessorKey: "topic_categories",
            id: "topic_categories",
            header: "Kategorien",
            columnType: "m2m",
            separator: "<br/>",
            labelAttr: "title",
            enableColumnFilter: true,
            headerClassNames: "medium-filter"
        },
        {
            accessorKey: "max_participants",
            id: "max_participants",
            header: "Max. Teiln.",
            enableColumnFilter: false,
        },
        {
            accessorKey: "grades",
            id: "grades",
            header: "Klassenstufen",
            columnType: "m2m",
            labelAttr: "name",
            enableColumnFilter: true,
            headerClassNames: "narrow-filter"
        },
        {
            accessorKey: "id",
            id: "booking",
            cell: ({row, value}) => (
                row.original.bookings.length > 0 ? <CheckCircleIcon className="h-5 w-5 text-green-400 mr-1"
                                                                    aria-hidden="true"/> : ""
            ),
            header: "Angemeldet",
            enableColumnFilter: false,
            disableRowLink: true
        },
    ];

    return (
        <SimplePage>
            <div className="px-4 sm:px-6 lg:px-8">
                <div className="sm:flex sm:items-center">
                    <div className="sm:flex-auto">
                        <h3 className="text-lg leading-6 font-medium text-gray-900 mb-2">
                            {data.workshop_day.title}
                        </h3>
                    </div>
                    <div className="mt-4 sm:mt-0 sm:ml-16 flex gap-2">
                        <DownloadButton
                            apiFunction={() => getWorkshopDayPDF(workshopDayId)}
                            fileName={`${data.workshop_day.title}.pdf`}
                            label="PDF herunterladen"/>
                    </div>
                </div>
                <div className="flex flex-col">
                    <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">

                        <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                            {isSuccess && data && (
                                <ListViewTable
                                    data={data.workshops}
                                    columns={columns}
                                    isLoading={isLoading}
                                    disableGlobalSearch={false}
                                    getRowLink={getRowLink}
                                />
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </SimplePage>
    );
}
