import React from "react";

import {useQuery} from "@tanstack/react-query";
import {useAuth} from "../../../contexts/AuthContext";
import ListViewTable from "../../elements/tables/ListViewTable";
import checkPermission from "../../../utils/permissions";
import {useParams} from "react-router-dom";
import {teamApi} from "../../../api/boarding_school";

const columns = [
    {
        accessorFn: (row) =>
            `${row.last_name}, ${row.first_name} ${row.student_number}`,
        id: "student_search",
        header: "SchülerIn",
        hide: true,
        enableColumnFilter: false,
    },
    {
        accessorFn: (row) => row,
        id: "student",
        header: "SchülerIn",
        columnType: "student",
        enableColumnFilter: false,
        sortingFn: "lastNameSorting"
    },
    {
        accessorKey: "grade",
        id: "grade",
        header: "Stufe",
        enableColumnFilter: true,
        headerClassNames: "narrow-filter",
        filterFn: "containsString",
    },
    {
        accessorKey: "school_class",
        id: "school_class",
        header: "Klasse",
        enableColumnFilter: true,
        sortingFn: "schoolClassSorting",
        headerClassNames: "narrow-filter",
        filterFn: "containsString",
    },
    {
        accessorKey: "house",
        id: "house",
        header: "Mentorat",
        enableColumnFilter: true,
        headerClassNames: "narrow-filter",
        filterFn: "containsString",
    },
];

export default function TeamDetail() {
    const {teamId} = useParams();
    const {permissions} = useAuth();

    const {isLoading, isError, isSuccess, data, error, refetch} = useQuery(
        ["teams", teamId],
        () => teamApi("get", teamId),
        {refetchInterval: 1000 * 60 * 60 * 24}
    );

    const canViewStudentBasic = checkPermission(permissions,"school.view_student_basic")

    function getRowLink(row) {
        if (canViewStudentBasic) return `/school/students/${row.original.id}/`;
        return '';
    }

    if (!data) return <></>;
    return (
        <>
            <main className="py-10 h-full bg-gray-100">
                {/* Page header */}
                <div
                    className="max-w-3xl mx-auto px-4 sm:px-6 md:flex md:items-center md:justify-between md:space-x-5 lg:max-w-7xl lg:px-8">
                    <div className="flex items-center space-x-5">
                        <div>
                            <h1 className="text-2xl font-bold text-gray-900">{data.name}</h1>
                            {/*<p className="text-sm font-medium text-gray-500">{student.student_number}</p>*/}
                        </div>
                    </div>
                    <div
                        className="mt-6 flex flex-col-reverse justify-stretch space-y-4 space-y-reverse sm:flex-row-reverse sm:justify-end sm:space-x-reverse sm:space-y-0 sm:space-x-3 md:mt-0 md:flex-row md:space-x-3">
                        {/*<SecondaryButton label="Schülerblatt" link={`/students/${studentId}`}/>*/}

                        {/*{checkPermission(permissions, 'student_logbook.add_absencenotice') &&*/}
                        {/*    <PrimaryButton link={`/logbook/absence-notices/new/${studentId}`}*/}
                        {/*                   label="Neue Abwesenheitsmeldung"/>}*/}
                    </div>
                </div>

                <div
                    className="mt-8 max-w-3xl mx-auto grid grid-cols-1 gap-6 sm:px-6 lg:max-w-7xl lg:grid-flow-col-dense lg:grid-cols-3">
                    <div className="space-y-6 lg:col-start-1 lg:col-span-2">
                        {/* Schüler */}
                        <div className="bg-white shadow sm:rounded-lg sm:overflow-hidden">
                            <div className="divide-y divide-gray-200">
                                <div className="px-4 py-5 sm:px-6">
                                    <h2
                                        id="notes-title"
                                        className="text-lg font-medium text-gray-900"
                                    >
                                        Schüler
                                    </h2>
                                </div>
                                <div className="mt-0">
                                    {isSuccess && data && (
                                        <ListViewTable
                                            data={data.students}
                                            columns={columns}
                                            isLoading={isLoading}
                                            disableGlobalSearch={true}
                                            getRowLink={getRowLink}
                                            noTopSpace={true}
                                        />
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>

                    <section
                        aria-labelledby="timeline-title"
                        className="lg:col-start-3 lg:col-span-1"
                    >
                        <img
                            className="mx-auto max-h-60 max-w-60 rounded-full"
                            src={data.logo}
                            alt={data.name}
                        />
                    </section>
                </div>
            </main>
        </>
    );
}
