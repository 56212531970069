import { SimplePage } from "../../elements/Pages";
import React, { useState } from "react";

import { getPeExemptions } from "../../../api/logbook";

import { useQuery } from "@tanstack/react-query";
import { useAuth } from "../../../contexts/AuthContext";
import ListViewTable, { TableLink } from "../../elements/tables/ListViewTable";
import checkPermission from "../../../utils/permissions";
import moment from "moment";
import { Link, useParams } from "react-router-dom";

const columns = [
  // {
  //     accessorKey: 'id',
  //     id: 'select',
  //     columnType: 'select'
  // },
  {
    accessorFn: (row) =>
      `${row.student.last_name}, ${row.student.first_name} ${row.student.student_number}`,
    id: "student_search",
    header: "SchülerIn",
    hide: true,
    enableColumnFilter: false,
  },
  {
    accessorKey: "student",
    id: "student",
    header: "SchülerIn",
    columnType: "student",
    enableColumnFilter: false,
    sortingFn: "lastNameSorting",
  },
  {
    accessorKey: "date_from",
    id: "date_from",
    header: "Von",
    columnType: "date",
    enableColumnFilter: false,
    // classNames: 'font-medium text-gray-900'
    classNames: "text-gray-500",
  },
  {
    accessorKey: "date_to",
    id: "date_to",
    header: "Bis",
    columnType: "date",
    enableColumnFilter: false,
    // classNames: 'font-medium text-gray-900'
    classNames: "text-gray-500",
  },
  {
    accessorKey: "reason",
    id: "reason",
    header: "Grund",
    enableColumnFilter: false,
  },
  {
    accessorKey: "notes",
    id: "notes",
    header: "Bemerkungen",
    cell: (info) => info.getValue(),
    enableColumnFilter: false,
    classNames: "max-w-md text-gray-500",
  },
  {
    accessorKey: "id",
    id: "link",
    cell: (info) => (
      <TableLink
        label="Bearbeiten"
        to={`/logbook/pe-exemptions/${info.getValue()}/edit`}
      />
    ),
    header: "Bearbeiten",
    enableColumnFilter: false,
    disableRowLink: true
  },
];

export default function PeExemptions() {
  const { studentId } = useParams();

  const { permissions } = useAuth();
  const [rowSelection, setRowSelection] = useState([]);

  const { isLoading, isError, isSuccess, data, error } = useQuery(
    ["pe_exemptions", studentId],
    () => getPeExemptions(studentId)
  );

  // selected ids
  // console.log(Object.keys(rowSelection))

  const selectedRowsCount = Object.keys(rowSelection).length;

  if (!data) return <></>;
  return (
    <SimplePage>
      <div className="px-4 sm:px-6 lg:px-8">
        <div className="sm:flex sm:items-center">
          <div className="sm:flex-auto">
            <h3 className="text-lg leading-6 font-medium text-gray-900">
              Sportbefreiungen{" "}
              {studentId && data && "von " + data[0].student.full_name}
            </h3>
          </div>
          <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
            {checkPermission(
              permissions,
              "student_logbook.add_peexemption"
            ) && (
              <Link to="/logbook/pe-exemptions/new">
                <button
                  type="button"
                  className="inline-flex items-center justify-center rounded-md border border-transparent bg-imsblue-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-imsblue-700 focus:outline-none focus:ring-2 focus:ring-imsblue-500 focus:ring-offset-2 sm:w-auto"
                >
                  Neue Sportbefreiung eintragen
                </button>
              </Link>
            )}
          </div>
        </div>
        <div className="flex flex-col">
          <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
              {isSuccess && data && (
                <ListViewTable
                  data={data}
                  columns={columns}
                  disableGlobalSearch={false}
                  rowSelection={rowSelection}
                  setRowSelection={setRowSelection}
                />
              )}
            </div>
          </div>
        </div>

        {/* Indicator for number of selected rows TODO needs styling */}
        {selectedRowsCount > 0 && (
          <div>
            {selectedRowsCount} von {data.length} ausgewählt
          </div>
        )}
      </div>
    </SimplePage>
  );
}
