import React from "react";

import {useQuery} from "@tanstack/react-query";
import {DownloadButton, PrimaryButton, AsyncDownloadButton} from "../../elements/Buttons";
import {
    getParentConferenceDays,
    getRegistrationExport,
    startAllAppointmentListGeneration, startAllParentAppointmentListGeneration
} from "../../../api/parent_conference";
import {useAuth} from "../../../contexts/AuthContext";
import checkPermission from "../../../utils/permissions";

export default function ParentConferenceDayList() {

    const { isLoading, isError, isSuccess, data, error, refetch } = useQuery(
        ["parent_conference_day"],
        () => getParentConferenceDays(),
        {refetchInterval: 1000 * 60 * 60 * 24}
    );

    const {permissions} = useAuth();
    const canEdit = checkPermission(permissions, "parent_conference.change_conferenceday");

    if (!data) return <></>;
    return (
        <>
            {data.map(conference_day =>
                <main key={conference_day.id} className="py-10 h-full bg-gray-100">

                    <div
                        className="max-w-3xl mx-auto px-4 sm:px-6 md:flex md:items-center md:justify-between md:space-x-5 lg:max-w-7xl lg:px-8">
                        <div className="flex items-center space-x-5">
                            <div>
                                <h1 className="text-2xl font-bold text-gray-900">{conference_day.title}</h1>
                                <p>{conference_day.get_times}</p>
                            </div>
                        </div>
                        <div
                            className="mt-6 flex flex-col-reverse justify-stretch space-y-4 space-y-reverse sm:flex-row-reverse sm:justify-end sm:space-x-reverse sm:space-y-0 sm:space-x-3 md:mt-0 md:flex-row md:space-x-3">
                        </div>
                    </div>

                    <div
                        className="mt-8 max-w-3xl mx-auto grid grid-cols-1 gap-6 sm:px-6 lg:max-w-7xl lg:grid-flow-col-dense lg:grid-cols-3">
                        <div className="space-y-6 lg:col-start-1 lg:col-span-2">
                            {/* Schüler */}
                            <div className="bg-white shadow sm:rounded-lg sm:overflow-hidden">
                                <div className="divide-y divide-gray-200">
                                    <div className="px-4 py-5 sm:px-6 mt-1 text-sm text-gray-700">
                                        <p className="whitespace-pre-wrap">{conference_day.registration_text}</p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <section
                            aria-labelledby="timeline-title"
                            className="lg:col-start-3 lg:col-span-1"
                        >

                            {canEdit && <section aria-labelledby="applicant-information-title">
                                    <div className="bg-white shadow overflow-hidden sm:rounded-lg">
                                        <div className="px-4 py-5 sm:px-6">
                                            <h3 className="text-lg leading-6 font-medium text-gray-900">
                                                Übersichten
                                            </h3>
                                        </div>
                                        <div className="border-t border-gray-200 px-4 py-5 sm:p-0">

                                            <div className="flex-col flex gap-2 px-4 py-5">
                                            <PrimaryButton label="Schüler"
                                                           link={`/parent-conference/${conference_day.id}/students`} />

                                            <PrimaryButton label="Lehrer"
                                                           link={`/parent-conference/${conference_day.id}/teachers`} />

                                            <PrimaryButton label="Lehrer/Räume"
                                                           link={`/parent-conference/${conference_day.id}/teacher-rooms`} />

                                            <DownloadButton
                                                apiFunction={() => getRegistrationExport(conference_day.id)}
                                                fileName="Elternsprechtag.xls"
                                                label="Anmeldungen herunterladen" />

                                                <AsyncDownloadButton
                                                    apiFunction={() => startAllAppointmentListGeneration(conference_day.id)}
                                                    label="Alle Terminlisten erstellen (Mitarbeiter)" />

                                                <AsyncDownloadButton
                                                    apiFunction={() => startAllParentAppointmentListGeneration(conference_day.id)}
                                                    label="Alle Terminlisten erstellen (Eltern)" />

                                            </div>

                                        </div>
                                    </div>
                                </section>}

                        </section>

                    </div>
                </main>
            )}
        </>
    );
}
