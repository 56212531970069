import {genericApi} from "./generic";
import http from "../services/httpService";
import httpServiceDownloads from "../services/httpServiceDownloads";

export async function getRepairOrders(incompleteOrAll) {
    const resp = await http.get(`/repair_orders/`
        + (incompleteOrAll ? `?incomplete=${incompleteOrAll}` : ''))
    return resp.data
}

export async function repairOrderApi(method, id, data) {
    return genericApi('/repair_orders/', method, id, data)
}

export async function getRepairOrderCategories() {
    const resp = await http.get(`/repair_order_categories/`)
    return resp.data
}
export async function getRepairOrderAssignees() {
    const resp = await http.get(`/repair_order_assignees/`)
    return resp.data
}

export async function getMyRepairOrders(incompleteOrAll) {
    const resp = await http.get(`/repair_orders/my/`
        + (incompleteOrAll ? `?incomplete=${incompleteOrAll}` : ''))
    return resp.data
}

export async function getRepairOrderListPDF() {
    const resp = await httpServiceDownloads.get(`/repair_orders/my_repair_orders_pdf/`)
    return resp.data
}

export async function markRepairOrderInProgress(repairOrderId) {
    const resp = await http.post(`/repair_orders/${repairOrderId}/mark_in_progress/`)
    return resp.data
}
export async function markRepairOrderIncomplete(repairOrderId) {
    const resp = await http.post(`/repair_orders/${repairOrderId}/mark_incomplete/`)
    return resp.data
}

export async function markRepairOrderCompleted(repairOrderId, data) {
    const resp = await http.post(`/repair_orders/${repairOrderId}/mark_complete/`, data)
    return resp.data
}