import {DetailPage} from "../../elements/Pages";
import {React} from 'react'
import {watchlistApi} from '../../../api/logbook'

import {useQuery} from '@tanstack/react-query'
import {useAuth} from "../../../contexts/AuthContext";
import checkPermission from "../../../utils/permissions";
import moment from "moment";
import {Link, useParams} from "react-router-dom";
import {PrimaryButton} from "../../elements/Buttons";
import {useUserInfo} from "../../../contexts/UserInfoContext";


export default function WatchlistEntryDetail() {

    const {id} = useParams()
    const {permissions} = useAuth()
    const {renderUserImage} = useUserInfo();

    const { data } = useQuery(['logbook_entry', id], () => watchlistApi('get', id), {})

    if (!data) return <></>
    return (

        <DetailPage>

            {/* Page header */}
            <div className="max-w-3xl mx-auto px-4 sm:px-6 md:flex md:items-center md:justify-between md:space-x-5 lg:max-w-7xl lg:px-8">
                <div className="flex items-center space-x-5">
                    <div className="flex-shrink-0">
                        <div className="relative">
                            {renderUserImage(data.student.user_id, 80)}
                            <span className="absolute inset-0 shadow-inner rounded-full" aria-hidden="true"/>
                        </div>
                    </div>
                    <div>
                        <h1 className="text-2xl font-bold text-gray-900"><Link to={`/school/students/${data.student.id}`} className="text-gray-900" >{data.student.full_name}</Link></h1>
                        <div className="text-sm font-medium text-gray-500 space-x-2">
                            <span>{data.student.student_number}{' '}</span>
                            <span className="text-gray-500 font-medium">&middot;</span>{' '}
                            <span>{data.student.school_class}{' '}</span>
                            <span className="text-gray-500 font-medium">&middot;</span>{' '}
                            <span>{data.student.house}</span>
                        </div>
                    </div>
                </div>
                <div className="mt-6 flex flex-col-reverse justify-stretch space-y-4 space-y-reverse sm:flex-row-reverse sm:justify-end sm:space-x-reverse sm:space-y-0 sm:space-x-3 md:mt-0 md:flex-row md:space-x-3">

                    {checkPermission(permissions, 'student_logbook.change_watchlistentry') &&
                        <PrimaryButton
                            link={`/logbook/watchlist/${data.id}/edit`}
                            label="Bearbeiten"
                        />}
                </div>
            </div>

            <div className="mt-8 max-w-3xl mx-auto grid grid-cols-1 gap-6 sm:px-6 lg:max-w-7xl lg:grid-flow-col-dense lg:grid-cols-3">
                <div className="space-y-6 lg:col-start-1 lg:col-span-2">
                    {/* Description list*/}
                    <section aria-labelledby="applicant-information-title">
                        <div className="bg-white shadow overflow-hidden sm:rounded-lg">
                            <div className="px-4 py-5 sm:px-6">
                                <h3 className="text-lg leading-6 font-medium text-gray-900">Watchlisteintrag
                                    </h3>
                            </div>
                            <div className="border-t border-gray-200 px-4 py-5 sm:p-0">
                                <dl className="sm:divide-y sm:divide-gray-200">
                                    <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                        <dt className="text-sm font-medium text-gray-500">Epoche</dt>
                                        <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{data.epoch.name}
                                        </dd>
                                    </div>
                                    <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                        <dt className="text-sm font-medium text-gray-500">Anmerkungen</dt>
                                        <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2 whitespace-pre-wrap">{data.notes}
                                        </dd>
                                    </div>
                                    <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                        <dt className="text-sm font-medium text-gray-500">Protokoll Interventionsgespräch</dt>
                                        <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2 whitespace-pre-wrap">{data.intervention_protocol}
                                        </dd>
                                    </div>
                                    <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                        <dt className="text-sm font-medium text-gray-500">Erstellt</dt>
                                        <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">von {data.created_by_full_name} am {moment(data.creation_date).format("DD.MM.YYYY")}
                                        </dd>
                                    </div>
                                </dl>
                            </div>
                        </div>
                    </section>

                </div>

            </div>

        </DetailPage>
    )
}
