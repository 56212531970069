import React from 'react';
import {Route, Routes} from "react-router-dom";
import Students from "./Students";
import Modules from "./Modules";
import ModuleDetail from "./ModuleDetail";
import ModuleCreateEdit from "./ModuleCreateEdit";
import StudentDetail from "./StudentDetail";
import MyCareer from "./MyCareer";


function CareerRouter() {

    return (
        <Routes>

            <Route path="/modules" element={<Modules/>}/>
            <Route path="/modules/:id" element={<ModuleDetail/>}/>
            <Route path="/modules/new" element={<ModuleCreateEdit/>}/>
            <Route path="/modules/:id/edit" element={<ModuleCreateEdit/>}/>

            <Route path="/students" element={<Students/>}/>
            <Route path="/students/:studentId" element={<StudentDetail/>}/>

            <Route path="/my" element={<MyCareer/>}/>

        </Routes>
    )
}


export default function Career() {
   return <CareerRouter />
}
