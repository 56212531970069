import {useNavigate, useParams} from "react-router-dom";
import {React, useEffect, useState} from "react";
import {useQuery} from "@tanstack/react-query";
import {getStudentInfo} from "../../../api/school";
import {PrimaryButton} from "../../elements/Buttons";
import {doSchoolClassChange, getCourses, getSuggestedCoursesForSchoolClassChange} from "../../../api/courses";
import {Select} from "../../elements/forms/Inputs";
import {useSchoolYears} from "../../../contexts/SchoolYearContext";
import {baseHandleListChange} from "../../elements/forms/Form";
import {toast} from "react-toastify";

export default function StudentSchoolClassChange() {
    const {studentId} = useParams();
    const {newSchoolClassId} = useParams();

    const [courseMapping, setCourseMapping] = useState([])

    const {currentSchoolYear} = useSchoolYears();
    const [schoolYearId, setSchoolYearId] = useState();
    const [errors, setErrors] = useState([]) // validation errors

    const navigate = useNavigate()

    const {data: student} = useQuery(["student_info", studentId], () =>
            getStudentInfo(studentId),
        {refetchInterval: 1000 * 60 * 60 * 24}
    );

    const {data: courses} = useQuery(
        ["courses", schoolYearId],
        () => getCourses(schoolYearId),
        {
            enabled: !!schoolYearId, refetchInterval: 1000 * 60 * 60 * 24
        }
    );

    useEffect(() => {
        loadData()
    }, [studentId, newSchoolClassId])

    useEffect(() => {
        if (currentSchoolYear) setSchoolYearId(currentSchoolYear["id"]);
    }, [currentSchoolYear]);

    async function loadData() {
        if (!studentId || !newSchoolClassId) return;
        let tmpData = {
            student_id: studentId,
            new_school_class_id: newSchoolClassId
        }
        const newData = await getSuggestedCoursesForSchoolClassChange(tmpData)
        setCourseMapping(newData)
    }

    function handleChange(evt, rowId) {
        baseHandleListChange(rowId, evt, courseMapping, setCourseMapping, errors, setErrors, {})
    }

    async function handleSave() {
        try {
            await doSchoolClassChange(studentId, newSchoolClassId, courseMapping)
            toast.success("Klassen- und Kurswechsel gespeichert")
        } catch (e) {
            toast.error("Beim Speichern ist ein Fehler aufgetreten.")
        }
        navigate(`/courses/student-courses/${studentId}`)
    }

    if (!student) return <></>;
    return (
        <>
            <main className="py-10 h-full bg-gray-100">
                <div
                    className="max-w-3xl mx-auto px-4 sm:px-6 md:flex md:items-center md:justify-between md:space-x-5 lg:max-w-7xl lg:px-8">
                    <div className="flex items-center space-x-5">
                        <div>
                            <h1 className="text-2xl font-bold text-gray-900">
                                Klassenwechsel für {student.full_name}, {student.student_number}
                            </h1>
                            <p className="text-xl font-medium text-gray-500">
                                Aktuelle Klasse: {student.school_class}
                            </p>
                            <p className="text-sm text-gray-500">
                               Wenn Kurswechsel hier vorgenommen werden, werden die Noten automatisch übertragen.
                            </p>
                        </div>
                    </div>
                </div>

                <div
                    className="mt-8 max-w-3xl mx-auto grid grid-cols-1 gap-6 sm:px-6 lg:max-w-7xl lg:grid-flow-col-dense lg:grid-cols-3">
                    <div className="space-y-6 lg:col-start-1 lg:col-span-2">

                        <section aria-labelledby="notes-title">

                            <div className="">

                                {courseMapping &&
                                    <div
                                        className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
                                        <table className="min-w-full divide-y divide-gray-300">
                                            <thead>
                                            <tr>
                                                <th className="px-4 py-3 text-left text-sm font-medium text-gray-500">
                                                    Fach
                                                </th>
                                                <th className="px-4 py-3 text-left text-sm font-medium text-gray-500">
                                                    Alter Kurs
                                                </th>
                                                <th className="px-4 py-3 text-left text-sm font-medium text-gray-500">
                                                    Neuer Kurs
                                                </th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            {courseMapping.map((course, index) => (
                                                <tr key={index}
                                                    className={(index % 2 === 0 ? undefined : "bg-gray-50")}
                                                >
                                                    <td className="break-word py-4 pl-4 pr-3 text-sm">
                                                        {course.school_subject}
                                                    </td>
                                                    <td className="break-word py-4 pl-4 pr-3 text-sm">
                                                        {course.course_number}
                                                    </td>
                                                    <td className="break-word py-4 pl-4 pr-3 text-sm">
                                                        <Select
                                                            path="suggested_new_course"
                                                            label="Neuer Kurs"
                                                            className="sm:col-span-6 hide-label"
                                                            onChange={handleChange}
                                                            errors={errors}
                                                            data={courseMapping}
                                                            rowId={course.id}
                                                            options={courses}
                                                            valueAttr="course_number"
                                                            labelAttr="course_number"
                                                            valueOnly={true}
                                                        />
                                                    </td>

                                                </tr>
                                            ))}

                                            </tbody>
                                        </table>
                                    </div>}


                                    <div className="flex justify-between">
                                        <div>
                                            <PrimaryButton
                                                classNameIn="my-4 mr-4"
                                                onClick={handleSave} label="Kurse wechseln"/>
                                        </div>
                                    </div>

                            </div>

                        </section>

                    </div>
                </div>

            </main>
        </>
    );
}
