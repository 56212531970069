import {SimplePage} from "../../elements/Pages";
import React from "react";

import {useQuery} from "@tanstack/react-query";
import ListViewTable, {TableEditLink} from "../../elements/tables/ListViewTable";
import {useParams} from "react-router-dom";
import {getStudentInfo} from "../../../api/school";
import {getSpecialGrades} from "../../../api/grades";
import {PrimaryButton, SecondaryButton} from "../../elements/Buttons";
import checkPermission from "../../../utils/permissions";
import {useAuth} from "../../../contexts/AuthContext";


export default function StudentSpecialGrades() {

    const {studentId} = useParams()
    const {permissions} = useAuth();

    const {data: student} = useQuery(["student_info", studentId], () =>
        getStudentInfo(studentId),
        {refetchInterval: 1000 * 60 * 60 * 24}
    )

    const {isLoading, isError, isSuccess, data, error, refetch} = useQuery(
        ["special_grades_for_student", studentId],
        () => getSpecialGrades(studentId),
        {refetchInterval: 1000 * 60 * 60}
    );

    const canEdit = checkPermission(permissions, "grades.change_specialgrade")

    let columns = [
        {
            accessorKey: "date",
            id: "date",
            columnType: "date",
            header: "Datum",
            enableColumnFilter: false,
        },
        {
            accessorFn: (row) => `${row.course.course_number} ${row.course.school_subject}`,
            id: "course",
            header: "Kurs",
            enableColumnFilter: false,
            headerClassNames: "narrow-filter",
        },
        {
            accessorKey: "special_grade_definition.name",
            id: "special_grade_definition",
            header: "Sondernote",
            enableColumnFilter: false,
        },
        {
            accessorKey: "topic",
            id: "topic",
            header: "Thema",
            enableColumnFilter: false,
        },
        {
            accessorKey: "mark.display",
            id: "mark",
            header: "Note",
            enableColumnFilter: false,
        },
        {
            accessorKey: "absolute_bonus",
            id: "absolute_bonus",
            header: "Bonus",
            enableColumnFilter: false,
        },
        {
            accessorKey: "weight_in_course",
            id: "weight_in_course",
            header: "Gewichtung im Kurs",
            enableColumnFilter: false,
        }
    ]

    if (canEdit) {
        columns.push({
            accessorKey: "id",
            id: "link",
            cell: (info) => (
                <TableEditLink iconOnly={true}
                               label="Bearbeiten"
                               to={`/grades/special-grades/${info.getValue()}/edit`}
                />
            ),
            header: "Bearbeiten",
            enableColumnFilter: false,
            disableRowLink: true
        })
    }

    if (!student) return <></>

    return (
        <SimplePage>
            {/*  Simple striped table */}
            <div className="px-4 sm:px-6 lg:px-8">
                <div className="sm:flex sm:items-center">
                    <div className="sm:flex-auto">
                        <h3 className="text-lg leading-6 font-medium text-gray-900 mb-2">
                            Sondernoten von {student?.full_name}
                        </h3>
                    </div>
                    <div className="mt-4 sm:mt-0 sm:ml-16 flex gap-2">
                        <SecondaryButton link={`/grades/students/${studentId}/notenblatt/`} label="Notenblatt"/>
                        <SecondaryButton link={`/school/students/${studentId}`} label="Schülerblatt"/>
                        {canEdit && <PrimaryButton link={`/grades/special-grades/new/${studentId}`} label="Sondernote eintragen"/>}
                    </div>
                </div>
                <div className="flex flex-col">
                    <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">

                        <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                            {isSuccess && data && (
                                <ListViewTable
                                    data={data}
                                    columns={columns}
                                    isLoading={isLoading}
                                    disableGlobalSearch={true}
                                    rowSelection={null}
                                    setRowSelection={null}
                                    getRowLink={null}
                                />
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </SimplePage>
    );
}
