import {sideBarTabsList, useMessenger} from "../../../contexts/MessengerContext";
import {classNames} from "../../../utils/misc";

export function MessengerSideBar() {
  return (
    <nav
      aria-label="Sidebar"
      className="border border-r-gray-300 md:border-0 block md:block md:flex-shrink-0 md:overflow-y-auto md:bg-gray-800"
    >
      <div className="relative flex w-14 md:w-20 flex-col space-y-3 p-2">
        {sideBarTabsList.map(item =>
          <MessengerSideBarItem key={item.name} item={item}/>
        )}
      </div>
    </nav>
  )
}

function MessengerSideBarItem({item}) {

  const {
    currentTab,
    changeCurrentTab,
  } = useMessenger();

  return (
    <div
      onClick={() => changeCurrentTab(item.name)}
      className={classNames(
        item.name === currentTab
          ? "bg-imsblue text-white"
          : "text-gray-400 hover:bg-gray-700",
        "flex-shrink-0 inline-flex items-center justify-center h-10 md:h-14 w-10 md:w-14 rounded-lg cursor-pointer"
      )}
    >
      <span className="sr-only">{item.name}</span>
      <item.icon className="h-6 w-6" aria-hidden="true"/>
    </div>
  )
}