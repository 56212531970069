import http from "./../services/httpService";
import httpServiceDownloads from "../services/httpServiceDownloads";
import {genericApi} from "./generic";


export async function applicantsApi(method, id, data) {
    return genericApi('/applicants/', method, id, data)
}

export async function getApplicants(openOrDeclined) {
    const resp = await http.get(`/applicants/`
        + (openOrDeclined === 'declined' ? `?declined=true` : ``)
    )
    return resp.data
}