import {SimplePage} from "../../elements/Pages";
import React, {useEffect, useState} from "react";

import {getAbsenceNoticeReasonsAbsencesOnly} from "../../../api/logbook";

import {useQuery} from "@tanstack/react-query";
import ListViewTable from "../../elements/tables/ListViewTable";
import {getAbsencesReport, getAbsencesReportExcel} from "../../../api/reports";
import {DownloadButton, PrimaryButton} from "../../elements/Buttons";
import {useSchoolYears} from "../../../contexts/SchoolYearContext";
import SchoolYearFilter from "../../elements/SchoolYearFilter";
import {DateInput} from "../../elements/forms/Inputs";
import {baseHandleDateChange} from "../../elements/forms/Form";
import {getDownload} from "../../../api/downloads";


export default function AbsencesReport() {

    const {schoolYears, currentSchoolYear} = useSchoolYears();
    const [schoolYearId, setSchoolYearId] = useState();

    const [data, setData] = useState([])
    const [selectedDates, setSelectedDates] = useState({
        start_date: null,
        end_date: null
    })
    const [errors, setErrors] = useState()

    const {data: reasons} = useQuery(
        ["absence_notice_reasons_absences_only"],
        getAbsenceNoticeReasonsAbsencesOnly,
        {refetchInterval: 1000 * 60 * 60 * 24}
    )

    useEffect(() => {
        loadData()
    }, [schoolYearId])

    async function loadData() {
        if (!schoolYearId) return
        const newData = await getAbsencesReport(schoolYearId, selectedDates.start_date, selectedDates.end_date)
        setData(newData)
    }

    useEffect(() => {
        if (currentSchoolYear) setSchoolYearId(currentSchoolYear["id"]);
    }, [currentSchoolYear]);

    let columns = [
        {
            accessorFn: (row) =>
                `${row.student.last_name}, ${row.student.first_name} ${row.student.student_number}`,
            id: "student_search",
            header: "SchülerIn",
            hide: true,
            enableColumnFilter: false,
        },
        {
            accessorKey: "student",
            id: "student",
            header: "SchülerIn",
            columnType: "student_no_image",
            enableColumnFilter: false,
            sortingFn: "lastNameSorting",
        },
        {
            accessorKey: "student.house",
            id: "house",
            header: "Mentorat",
            enableColumnFilter: true,
            headerClassNames: "narrow-filter",
        },
        {
            accessorKey: "student.school_class",
            id: "school_class",
            header: "Klasse",
            headerClassNames: "narrow-filter",
        },
        {
            accessorKey: "absence_count",
            id: "absence_count",
            header: "Fehltage",
            enableColumnFilter: false
        }
    ]

    if (!reasons) return <></>

    // add columns dynamically
    for (var i = 0; i < reasons.length; i++) {
        columns.push({
            accessorKey: "reasons." + reasons[i].id,
            id: reasons[i].id,
            header: reasons[i].name,
            enableColumnFilter: false
        })
    }

    function getRowLink(row) {
        return `/logbook/absence-notices/students/${row.original.student.id}`;
    }

    function handleDateChange(date, path) {
        baseHandleDateChange(date, path, selectedDates, setSelectedDates, errors, setErrors, {}, false)
    }

    function handleDateFilter() {
        loadData()
    }

    return (
        <SimplePage>
            {/*  Simple striped table */}
            <div className="px-4 sm:px-6 lg:px-8">
                <div className="sm:flex sm:items-center">
                    <div className="sm:flex-auto">
                        <h3 className="text-lg leading-6 font-medium text-gray-900">
                            Fehltage nach Schülern und Gründen
                        </h3>
                    </div>
                    <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
                        <DownloadButton
                            apiFunction={() => getAbsencesReportExcel(schoolYearId)}
                            fileName="Fehltage.xls"
                            label="Excel herunterladen"/>

                    </div>
                </div>
                <div className="flex flex-col">
                    <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">

                        <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                            <SchoolYearFilter
                                schoolYears={schoolYears}
                                schoolYearId={schoolYearId}
                                setSchoolYearId={setSchoolYearId}
                            />
                        </div>

                        <div className="flex gap-2 py-2 md:px-6 lg:px-8">
                            <DateInput
                                path="start_date"
                                label="Von"
                                onChange={handleDateChange}
                                errors={errors}
                                data={selectedDates}
                                todayButton={false}
                                tomorrowButton={false}
                            />
                            <DateInput
                                path="end_date"
                                label="Bis"
                                onChange={handleDateChange}
                                errors={errors}
                                data={selectedDates}
                                todayButton={false}
                                tomorrowButton={false}
                            />
                            <div className="self-end">
                                <PrimaryButton onClick={handleDateFilter} label="Filtern"/>
                            </div>
                        </div>

                        <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                            {data && (
                                <ListViewTable
                                    data={data}
                                    columns={columns}
                                    isLoading={false}
                                    disableGlobalSearch={false}
                                    getRowLink={getRowLink}
                                />
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </SimplePage>
    );
}
