import {Page} from "../../elements/Pages";
import {React, useEffect, useState} from "react";
import Joi from "joi"
import {useQuery, useQueryClient} from '@tanstack/react-query'
import {DateInput, Input, MultiSelect, Select, TextArea} from "../../elements/forms/Inputs"
import {FormSectionContent} from "../../elements/forms/FormLayout"
import {
    examApi,
    getExamEntryOpenWarnings,
    getExamTypeOptions,
    getExamWarnings,
    getOtherExams
} from "../../../api/school";
import Form, {baseHandleChange, baseHandleDateChange, baseHandleListChange} from "../../elements/forms/Form"
import {Link, useNavigate, useParams} from "react-router-dom";
import {toast} from "react-toastify";
import {getBookableRooms, roomBookingApi} from "../../../api/organizational";

import {AvailableRooms, FindRoom} from "../../modules/organizational/FindRoom";
import {DeleteButton} from "../../elements/DeleteButton";
import {AddButton} from "../../elements/Buttons";
import {getCourseList} from "../../../api/courses";
import {getDayPeriods} from "../../../api/calendar";
import ListViewTable from "../../elements/tables/ListViewTable";
import Alert from "../../elements/Alert";
import {ArrowTopRightOnSquareIcon} from "@heroicons/react/20/solid";


const examColumns = [
    {
        accessorKey: "date",
        id: "date",
        header: "Datum",
        enableColumnFilter: false,
        columnType: "date",
        headerClassNames: "narrow-filter",
    },
    {
        accessorKey: "category",
        id: "category",
        header: "Art",
        enableColumnFilter: false,
    },
    {
        accessorKey: "topic",
        id: "topic",
        header: "Thema",
        enableColumnFilter: false,
    },
    {
        accessorKey: "courses",
        id: "courses",
        header: "Kurs",
        enableColumnFilter: false,
        columnType: "m2m",
        separator: " <br/>",
        labelAttr: "course_number",
        headerClassNames: "narrow-filter",
    }
];


const extraExamColumns = [
    {
        accessorKey: "date",
        id: "date",
        header: "Datum",
        enableColumnFilter: false,
        columnType: "date",
        headerClassNames: "narrow-filter",
    },
    {
        accessorFn: (row) =>
            `${row.student.last_name}, ${row.student.first_name}`,
        id: "student",
        header: "SchülerIn",
        enableColumnFilter: false,
    },
    {
        accessorKey: "course.course_number",
        id: "course",
        header: "Kurs",
        enableColumnFilter: false,
        headerClassNames: "narrow-filter",
    },
    {
        accessorKey: "topic",
        id: "topic",
        header: "Thema",
        enableColumnFilter: false,
    }
];

export default function ExamCreateEdit() {

    const {id, courseId} = useParams()
    const queryClient = useQueryClient()

    const [data, setData] = useState({
        id: null,
        title: null,
        start: null,
        courses: courseId ? [{id: parseInt(courseId)}] : null,
        end: null,
        date: null,
        category: null,
        room_bookings: []
    })

    const {data: courses} = useQuery(['course_list'], getCourseList, {refetchInterval: 1000 * 60 * 60 * 24})
    const {data: dayPeriods} = useQuery(['day_periods'], getDayPeriods, {refetchInterval: 1000 * 60 * 60 * 24})
    const {data: examCategories} = useQuery(['exam_categories'], getExamTypeOptions, {refetchInterval: 1000 * 60 * 60 * 24})

    const [availableRooms, setAvailableRooms] = useState([])
    const [selectedRowId, setSelectedRowId] = useState(null)
    const [newRowId, setNewRowId] = useState(1)
    const {data: rooms} = useQuery(["bookable_rooms"], getBookableRooms, {refetchInterval: 1000 * 60 * 60 * 24});
    const [otherExams, setOtherExams] = useState({})
    const [examWarnings, setExamWarnings] = useState({})
    const [examEntryOpenWarnings, setExamEntryOpenWarnings] = useState({})

    const [errors, setErrors] = useState({})
    const [roomBookingsErrors, setRoomBookingsErrors] = useState([])
    const [bookingValidationErrors, setBookingValidationErrors] = useState([])

    let navigate = useNavigate();

    useEffect(() => {
        loadData()
    }, [id])

    async function loadData() {
        if (!id) return
        const newData = await examApi('get', id)
        setData(newData)
    }

    useEffect(() => {
        loadOtherExamsForCourse()
        loadExamWarnings()
        loadExamEntryOpenWarning()
    }, [data.courses, data.date])

    async function loadOtherExamsForCourse() {
        if (!data.courses || !data.courses.length > 0) return
        const newData = await getOtherExams(data.courses[0].id, data.date, data.id)
        setOtherExams(newData)
    }

    async function loadExamWarnings() {
        if (!data.courses || !data.courses.length > 0 || !data.date) return
        const newData = await getExamWarnings(data.courses[0].id, data.date, data.id)
        setExamWarnings(newData)
    }

    async function loadExamEntryOpenWarning() {
        if (!data.courses || !data.courses.length > 0 || !data.date) return
        const newData = await getExamEntryOpenWarnings(data.courses[0].id, data.date, data.id)
        setExamEntryOpenWarnings(newData)
    }

    const schema = {
        topic: Joi.string().required(),
        category: Joi.string().required(),
        time: Joi.array().min(1).required()
    }

    const title = (id || data.id) ? "Klausur bearbeiten" : "Klausur eintragen"
    const successMessage = (id || data.id) ? "Klausur aktualisiert" : "Klausur eingetragen"

    async function onSave() {
        try {
            let newData
            if (!data.id) newData = await examApi('post', null, data)
            else newData = await examApi('patch', null, data)
            setBookingValidationErrors([])

            setData(newData)
            queryClient.invalidateQueries({ queryKey: ['exams'] })
            toast.success(successMessage)
            navigate(`/class-register/exams/${newData.id}`)
        } catch (e) {
            // console.log(e.data)
            if (e.data.room_booking_validation_errors) {
                setBookingValidationErrors(e.data.room_booking_validation_errors)
            }
            throw(e)
        }
    }

    async function onDelete() {
        await examApi('delete', data.id)
        toast.success("Klausur gelöscht.")
        navigate(`/class-register/exams/calendar`)
    }

    function handleChange(evt) {
        baseHandleChange(evt, data, setData, errors, setErrors, schema)
    }

    function handleDateChange(date, path) {
        baseHandleDateChange(date, path, data, setData, errors, setErrors, schema, false)
    }

    function handleMultiSelect(selectedOptions, path) {
        let newData = {...data}
        newData[path] = selectedOptions
        setData(newData)
    }

    function addRow(room) {
        const newRow = {
            id: `N${newRowId}`,
            room: room
        }
        setSelectedRowId(`N${newRowId}`)
        setNewRowId(newRowId + 1)
        setData({
            ...data,
            room_bookings: [...data.room_bookings, newRow]
        })
    }

    function setRoomBookings(roomBookingsData) {
        setData({...data, room_bookings: roomBookingsData})
    }

    function handleRoomChange(evt, rowId) {
        baseHandleListChange(rowId, evt, data.room_bookings, setRoomBookings, roomBookingsErrors, setRoomBookingsErrors, {})
    }

    function handleSelectRoom(room) {

        let selectedRowIdR = selectedRowId
        if (data.room_bookings.length === 0) {
            addRow(room)
            return
        }
        if (data.room_bookings.length === 1) {
            selectedRowIdR = data.room_bookings[0].id
        }

        const newRoomBookings = data.room_bookings.map(row => {
            if (row.id === selectedRowIdR) {
                return {...row, room: room}
            }
            return row
        })

        setData({...data, room_bookings: newRoomBookings})
    }

    async function handleRoomBookingDelete(roomBookingId) {
        if (roomBookingId.toString().startsWith("N")) {
            const newRoomBookings = data.room_bookings.filter(row => row.id !== roomBookingId)
            setData({...data, room_bookings: newRoomBookings})
        } else {
            try {
                await roomBookingApi("delete", roomBookingId)
                const newRoomBookings = data.room_bookings.filter(row => row.id !== roomBookingId)
                setData({...data, room_bookings: newRoomBookings})
                // loadData()
                toast.success("Raumbuchung gelöscht")
            } catch (e) {
                toast.error("Beim Speichern ist ein Fehler aufgetreten.")
            }
        }
    }

    return (

        <Page title={title}>

            <div
                className="max-w-3xl mx-auto grid grid-cols-1 gap-6  lg:max-w-7xl lg:grid-flow-col-dense lg:grid-cols-6">
                <div className="space-y-6 lg:col-span-4">

                    <Form id="createExam" onSave={onSave}
                          onDelete={onDelete}
                          data={data} setData={setData}
                          errors={errors} setErrors={setErrors}
                          successMessage={successMessage}
                          hideSubmitButton={examEntryOpenWarnings?.messages && examEntryOpenWarnings.messages.length > 0}
                          schema={schema}>

                        <FormSectionContent>

                            <MultiSelect
                                path="courses"
                                label="Kurse"
                                className="sm:col-span-6"
                                options={courses}
                                valueAttr="id"
                                labelAttr="course_number"
                                onChange={(selectedOptions) => handleMultiSelect(selectedOptions, 'courses')}
                                errors={errors}
                                data={data}
                                isClearable={true}
                                valueOnly={false}
                            />

                            <Input
                                path="topic"
                                type="text"
                                label="Thema"
                                className="sm:col-span-6"
                                onChange={handleChange}
                                errors={errors}
                                data={data}
                            />

                            <TextArea
                                path="description"
                                type="text"
                                label="Beschreibung"
                                className="sm:col-span-6"
                                onChange={handleChange}
                                errors={errors}
                                data={data}
                            />

                            <Select
                                path="category"
                                label="Art"
                                className="sm:col-span-6"
                                options={examCategories}
                                valueAttr="value"
                                labelAttr="label"
                                onChange={handleChange}
                                errors={errors}
                                data={data}
                                isClearable={true}
                                valueOnly={true}
                            />

                            <DateInput
                                className="col-span-3"
                                path="date"
                                label="Datum"
                                onChange={handleDateChange}
                                errors={errors}
                                data={data}
                                todayButton={true}
                                tomorrowButton={true}
                            />

                            <MultiSelect
                                path="time"
                                label="Zeit"
                                className="sm:col-span-3"
                                options={dayPeriods}
                                valueAttr="id"
                                labelAttr="name"
                                onChange={(selectedOptions) => handleMultiSelect(selectedOptions, 'time')}
                                errors={errors}
                                data={data}
                                isClearable={true}
                                valueOnly={false}
                            />

                            <div className="sm:col-span-6 py-4 border-y divide-gray-100">

                                <h3 className="text-lg leading-6 font-medium text-gray-900">Raum buchen</h3>

                                {bookingValidationErrors &&
                                    <div className="my-2 px-2 bg-red-50 text-red-700 sm:col-span-6">
                                        <ul role="list" className="space-y-1">
                                            {bookingValidationErrors.map((error, index) => (
                                                <li key={index} className="flex py-2">{error.error}: &nbsp;
                                                    <Link className="font-medium underline"
                                                          target={"_blank"}
                                                          to={`/service/room-bookings/calendar/?event_id=${error.room_booking.id}`}>
                                                        {error.room_booking?.id} von {error.room_booking?.created_by_full_name} <ArrowTopRightOnSquareIcon className="h-4 w-4 inline-flex align-middle"/>
                                                    </Link>
                                                </li>
                                            ))}
                                        </ul>

                                    </div>}

                                {data && data.room_bookings?.map((roomBooking, index) => (
                                    <div key={index} className="flex justify-between my-4">
                                        <Select
                                            path="room"
                                            label="Raum"
                                            className="sm:col-span-5 flex-grow"
                                            options={rooms}
                                            valueAttr="id"
                                            labelAttr="name"
                                            onChange={handleRoomChange}
                                            data={data.room_bookings}
                                            isClearable={true}
                                            valueOnly={false}
                                            rowId={roomBooking.id}
                                        />

                                        <div className="flex justify-start items-end ml-4">
                                            <DeleteButton iconOnly={true}
                                                          onDelete={() => handleRoomBookingDelete(roomBooking.id)}/>
                                        </div>

                                    </div>))}

                                <div className="flex justify-start gap-4 items-end sm:col-span-2">
                                    <FindRoom data={data}
                                              setAvailableRooms={setAvailableRooms}
                                              errors={errors}
                                              setErrors={setErrors}/>

                                    <AddButton onClick={addRow} label="Raum hinzufügen"/>
                                </div>

                            </div>

                            {examWarnings?.messages && examWarnings.messages.length > 0 &&
                                <div className="sm:col-span-6">
                                    <Alert variant="warning">
                                        <ul role="list" className="space-y-1">
                                            {examWarnings.messages.map((m, index) => (
                                                <li key={index}>{m}</li>
                                            ))}
                                        </ul>
                                    </Alert>
                                </div>}

                            {examEntryOpenWarnings?.messages && examEntryOpenWarnings.messages.length > 0 &&
                                <div className="sm:col-span-6">
                                    <Alert variant="warning">
                                        <ul role="list" className="space-y-1">
                                            {examEntryOpenWarnings.messages.map((m, index) => (
                                                <li key={index}>{m}</li>
                                            ))}
                                        </ul>
                                    </Alert>
                                </div>}

                        </FormSectionContent>

                    </Form>

                    <span onClick={() => navigate(-1)}
                          className="cursor-pointer float-right mt-2 font-medium text-imsblue-600">Zurück</span>

                </div>

                <div className="lg:col-span-2 pl-3 sm:max-h-[80%] min-h-0 flex-1 overflow-y-auto">

                    <div className="relative mb-8">
                        <AvailableRooms availableRooms={availableRooms}
                                        msg="Start- und Endzeit auswählen und 'Raum finden' klicken, um hier verfügbare Räume anzuzeigen."
                                        onRoomSelect={handleSelectRoom}/>
                    </div>

                </div>
            </div>

            <div
                className="max-w-3xl mx-auto grid grid-cols-1 gap-6  lg:max-w-7xl lg:grid-flow-col-dense lg:grid-cols-6">
                <div className="lg:col-span-4">

                    <h3 className="text-lg leading-6 font-medium text-gray-900">
                        Andere Klausuren
                    </h3>

                    <p className="text-gray-500">
                        SchülerInnen im ausgewählten Kurs schreiben in dieser und der darauf folgenden Woche auch
                        folgende Klausuren und Nachholklausuren:
                    </p>
                    <div className="">
                        {otherExams?.exams && <ListViewTable data={otherExams.exams}
                                                             columns={examColumns}
                                                             disableGlobalSearch={true}
                                                             getRowLink={null}
                        />}
                    </div>


                    {otherExams?.extra_exams && otherExams?.extra_exams.length > 0 && <>
                        <h3 className="mt-6 text-md leading-6 font-medium text-gray-900">
                            Nachholklausuren
                        </h3>
                        <ListViewTable data={otherExams.extra_exams}
                                       columns={extraExamColumns}
                                       disableGlobalSearch={true}
                                       getRowLink={null}
                        />
                    </>}

                </div>
            </div>

        </Page>
    )
}
