import {SimplePage} from "../../elements/Pages";
import React, {useState} from "react";

import {useQuery} from "@tanstack/react-query";
import {useAuth} from "../../../contexts/AuthContext";
import ListViewTable, {TableEditLink, TableLink,} from "../../elements/tables/ListViewTable";
import checkPermission from "../../../utils/permissions";
import {classNames} from "../../../utils/misc";
import {getSubstitutionExcel, getTimetableChanges} from "../../../api/timetables";
import {DownloadButton, PrimaryButton} from "../../elements/Buttons";

const substitutionBaseColumns = [
    {
        accessorKey: "date",
        id: "date",
        header: "Datum",
        columnType: "dateWithWeekday",
        enableColumnFilter: false,
        // classNames: 'font-medium text-gray-900'
        classNames: "text-gray-500",
    },
    {
        accessorKey: "course",
        id: "course",
        header: "Kurs",
        enableColumnFilter: false,
        cell: ({row, value}) => (
            <TableLink
                label={row.original.course.course_number}
                to={`/courses/${row.original.course.id}`}
            />
        ),
        disableRowLink: true
    },
    {
        accessorKey: "course.school_subject",
        id: "subject",
        header: "Fach",
        enableColumnFilter: false,
    },
    {
        accessorKey: "periods",
        id: "periods",
        header: "Zeit",
        columnType: "m2m",
        labelAttr: "name",
        enableColumnFilter: false
    },
    {
        accessorKey: "replaced_teacher.full_name",
        id: "replaced_teacher",
        header: "Es fehlt",
        enableColumnFilter: false
    },
    {
        accessorKey: "replacing_teacher",
        id: "replacing_teacher",
        header: "Vertretung durch",
        enableColumnFilter: false,
        cell: ({row, value}) => (
            <TableLink
                label={row.original.replacing_teacher.full_name}
                to={`/staff/${row.original.replacing_teacher.id}`}
            />
        ),
        disableRowLink: true
    },
    {
        accessorKey: "room.name",
        id: "room",
        header: "Raum",
        enableColumnFilter: false,
    },
    {
        accessorKey: "remarks",
        id: "remarks",
        header: "Anmerkungen",
        enableColumnFilter: false,
    },
    {
        accessorKey: "confirmed",
        id: "confirmed",
        header: "Bestätigt",
        columnType: "yesno",
        enableColumnFilter: false,
    },
    {
        accessorKey: "kbe", // todo
        id: "kbe",
        header: "KBE",
        enableColumnFilter: false,
        cell: ({row, value}) => (
            row.original.class_register_entry_id ?
                <TableLink label="Ansehen"
                           to={`/class-register/entries/${row.original.class_register_entry_id}`} />
                :
                <TableLink  label="Erstellen"
                            to={`/class-register/entries/new/${row.original.course.id}`} />
        ),
        disableRowLink: true
    },
];

const substitutionEditColumn = {
    accessorKey: "id",
    id: "link",
    cell: (info) => (
        <TableEditLink iconOnly={true}
            label="Bearbeiten"
            to={`/timetables/substitutions/${info.getValue()}/edit`}
        />
    ),
    header: "Bearbeiten",
    enableColumnFilter: false,
    disableRowLink: true
}

const cancellationsBaseColumns = [
    {
        accessorKey: "date",
        id: "date",
        header: "Datum",
        columnType: "dateWithWeekday",
        enableColumnFilter: false,
        // classNames: 'font-medium text-gray-900'
        classNames: "text-gray-500",
    },
    {
        accessorKey: "course",
        id: "course",
        header: "Kurs",
        enableColumnFilter: false,
        cell: ({row, value}) => (
            <TableLink
                label={row.original.course.course_number}
                to={`/courses/${row.original.course.id}`}
            />
        ),
        disableRowLink: true
    },
    {
        accessorKey: "course.school_subject",
        id: "subject",
        header: "Fach",
        enableColumnFilter: false,
    },
    {
        accessorKey: "periods",
        id: "periods",
        header: "Zeit",
        columnType: "m2m",
        labelAttr: "name",
        enableColumnFilter: false
    },
    {
        accessorKey: "remarks",
        id: "remarks",
        header: "Anmerkungen",
        enableColumnFilter: false,
    }
];

const cancellationsEditColumn = {
    accessorKey: "id",
    id: "link",
    cell: (info) => (
        <TableEditLink iconOnly={true}
                    label="Bearbeiten"
                    to={`/timetables/cancellations/${info.getValue()}/edit`}
        />
    ),
    header: "Bearbeiten",
    enableColumnFilter: false,
    disableRowLink: true
}


const roomChangesBaseColumns = [
    {
        accessorKey: "date",
        id: "date",
        header: "Datum",
        columnType: "dateWithWeekday",
        enableColumnFilter: false,
        // classNames: 'font-medium text-gray-900'
        classNames: "text-gray-500",
    },
    {
        accessorKey: "course",
        id: "course",
        header: "Kurs",
        enableColumnFilter: false,
        cell: ({row, value}) => (
            <TableLink
                label={row.original.course.course_number}
                to={`/courses/${row.original.course.id}`}
            />
        ),
        disableRowLink: true
    },
    {
        accessorKey: "course.school_subject",
        id: "subject",
        header: "Fach",
        enableColumnFilter: false,
    },
    {
        accessorKey: "periods",
        id: "periods",
        header: "Zeit",
        columnType: "m2m",
        labelAttr: "name",
        enableColumnFilter: false
    },
    {
        accessorKey: "room.name",
        id: "room",
        header: "Raum",
        enableColumnFilter: false,
    },
    {
        accessorKey: "remarks",
        id: "remarks",
        header: "Anmerkungen",
        enableColumnFilter: false,
    }
];

const roomChangesEditColumn = {
    accessorKey: "id",
    id: "link",
    cell: (info) => (
        <TableEditLink iconOnly={true}
                    label="Bearbeiten"
                    to={`/timetables/room-changes/${info.getValue()}/edit`}
        />
    ),
    header: "Bearbeiten",
    enableColumnFilter: false,
    disableRowLink: true
}

const projectCancellationsBaseColumns = [
    {
        accessorKey: "date",
        id: "date",
        header: "Datum",
        columnType: "dateWithWeekday",
        enableColumnFilter: false,
        // classNames: 'font-medium text-gray-900'
        classNames: "text-gray-500",
    },
    {
        accessorKey: "project",
        id: "project",
        header: "Projekt",
        enableColumnFilter: false,
        cell: ({row, value}) => (
            <TableLink
                label={row.original.project.name}
                to={`/projects/${row.original.project.id}`}
            />
        ),
        disableRowLink: true
    },
    {
        accessorKey: "remarks",
        id: "remarks",
        header: "Anmerkungen",
        enableColumnFilter: false,
    }
];

const projectCancellationsEditColumn = {
    accessorKey: "id",
    id: "link",
    cell: (info) => (
        <TableEditLink iconOnly={true}
                       label="Bearbeiten"
                       to={`/projects/cancellations/${info.getValue()}/edit`}
        />
    ),
    header: "Bearbeiten",
    enableColumnFilter: false,
    disableRowLink: true
}

export default function TimetableChanges() {

    const { permissions } = useAuth();
    const [futureOrAll, setFutureOrAll] = useState("future");

    const { isLoading, isError, isSuccess, data, error, refetch } = useQuery(
        ["timetable_changes", futureOrAll],
        () => getTimetableChanges(futureOrAll),
        {refetchInterval: 1000 * 60 * 10}
    );

    function getSubstitutionRowLink(row) {
        return `/timetables/substitutions/${row.original.id}/`;
    }

    const canChangeSubstitutions = checkPermission(permissions, "timetables.change_substitution")
    const canChangeCancellation = checkPermission(permissions, "timetables.change_cancellation")
    const canChangeRoomChange = checkPermission(permissions, "timetables.change_roomchange")
    const canChangeProjectCancellation = checkPermission(permissions, "projects.change_projectcancellation")

    let substitutionColumns = [...substitutionBaseColumns]
    if (canChangeSubstitutions) {
        substitutionColumns.push(substitutionEditColumn)
    }
    let cancellationColumns = [...cancellationsBaseColumns]
    if (canChangeCancellation) {
        cancellationColumns.push(cancellationsEditColumn)
    }
    let roomChangesColumns = [...roomChangesBaseColumns]
    if (canChangeRoomChange) {
        roomChangesColumns.push(roomChangesEditColumn)
    }
    let projectCancellationColumns = [...projectCancellationsBaseColumns]
    if (canChangeProjectCancellation) {
        projectCancellationColumns.push(projectCancellationsEditColumn)
    }

    return (
        <SimplePage title="Vertretungen, Raumwechsel & Ausfälle">

            <div className="px-4 sm:px-6 lg:px-8">
                <div className="sm:flex sm:items-center">
                    <div className="sm:flex-auto">
                        <h3 className="text-lg leading-6 font-medium text-gray-900">
                            Vertretungen, Raumwechsel & Ausfälle
                        </h3>
                    </div>
                    <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
                    </div>
                </div>
                <div className="flex flex-col">
                    <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
                        <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                            <nav className="flex space-x-4 mt-2" aria-label="Tabs">
                                <a
                                    key="future"
                                    onClick={() => setFutureOrAll("future")}
                                    className={classNames(
                                        futureOrAll === "future"
                                            ? "bg-imsblue-100 text-imsblue-700"
                                            : "text-gray-500 hover:text-gray-700",
                                        "px-3 py-2 font-medium text-sm rounded-md cursor-pointer"
                                    )}
                                    aria-current={futureOrAll === "future" ? "page" : undefined}
                                >
                                    Aktuell
                                </a>
                                <a
                                    key="today"
                                    onClick={() => setFutureOrAll("all")}
                                    className={classNames(
                                        futureOrAll === "all"
                                            ? "bg-imsblue-100 text-imsblue-700"
                                            : "text-gray-500 hover:text-gray-700",
                                        "px-3 py-2 font-medium text-sm rounded-md cursor-pointer"
                                    )}
                                    aria-current={futureOrAll === "all" ? "page" : undefined}
                                >
                                    Alle
                                </a>
                            </nav>
                        </div>


                        <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                            <div className="sm:flex sm:items-center">
                                <div className="sm:flex-auto">
                                    <h3 className="text-lg leading-6 font-medium text-gray-900">
                                        Vertretungen
                                    </h3>
                                </div>
                                <div className="mt-4 sm:mt-0 sm:ml-16 flex gap-2 ">
                                    {checkPermission(permissions,"timetables.add_substitution") && <DownloadButton
                                        apiFunction={() => getSubstitutionExcel()}
                                        fileName="Vertretungen.xls"
                                        label="Excel herunterladen" />}

                                    {checkPermission(permissions,"timetables.add_substitution") && <PrimaryButton link="/timetables/substitutions/new" label="Vertretung eintragen" />}
                                </div>
                            </div>
                            <div className="flex flex-col">
                                {isSuccess && data && data?.substitutions.length > 0 && (
                                    <ListViewTable
                                        data={data.substitutions}
                                        columns={substitutionColumns}
                                        isLoading={isLoading}
                                        disableGlobalSearch={true}
                                        getRowLink={getSubstitutionRowLink}
                                    />
                                )}
                            </div>
                        </div>

                        <div className="mt-4 inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                            <div className="sm:flex sm:items-center">
                                <div className="sm:flex-auto">
                                    <h3 className="text-lg leading-6 font-medium text-gray-900">
                                        Ausfall
                                    </h3>
                                </div>
                                <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
                                    {checkPermission(permissions,"timetables.add_cancellation") && <PrimaryButton link="/timetables/cancellations/new" label="Ausfall eintragen" />}
                                </div>
                            </div>
                            <div className="flex flex-col">
                                {isSuccess && data && data?.cancellations.length > 0 && (
                                    <ListViewTable
                                        data={data.cancellations}
                                        columns={cancellationColumns}
                                        isLoading={isLoading}
                                        disableGlobalSearch={true}
                                        getRowLink={null}
                                    />
                                )}
                            </div>
                        </div>

                        <div className="mt-4 inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                            <div className="sm:flex sm:items-center">
                                <div className="sm:flex-auto">
                                    <h3 className="text-lg leading-6 font-medium text-gray-900">
                                        Raumwechsel
                                    </h3>
                                </div>
                                <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
                                    {checkPermission(permissions,"timetables.add_roomchange") && <PrimaryButton link="/timetables/room-changes/new" label="Raumwechsel eintragen" />}
                                </div>
                            </div>
                            <div className="flex flex-col">
                                {isSuccess && data && data?.room_changes.length > 0 && (
                                    <ListViewTable
                                        data={data.room_changes}
                                        columns={roomChangesColumns}
                                        isLoading={isLoading}
                                        disableGlobalSearch={true}
                                        getRowLink={null}
                                    />
                                )}
                            </div>
                        </div>

                        <div className="mt-4 inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                            <div className="sm:flex sm:items-center">
                                <div className="sm:flex-auto">
                                    <h3 className="text-lg leading-6 font-medium text-gray-900">
                                        Projektausfall
                                    </h3>
                                </div>
                                <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
                                    {checkPermission(permissions,"projects.add_projectcancellation") && <PrimaryButton link="/projects/cancellations/new" label="Projektausfall eintragen" />}
                                </div>
                            </div>
                            <div className="flex flex-col">
                                {isSuccess && data && data?.project_cancellations.length > 0 && (
                                    <ListViewTable
                                        data={data.project_cancellations}
                                        columns={projectCancellationColumns}
                                        isLoading={isLoading}
                                        disableGlobalSearch={true}
                                        getRowLink={null}
                                    />
                                )}
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </SimplePage>
    )
}
