import {Link, useParams} from "react-router-dom";
import {React} from "react";
import {useQuery} from "@tanstack/react-query";
import {formatDate} from "../../../utils/formatters";
import {EditButton} from "../../elements/Buttons";
import {alumniStaffApi} from "../../../api/staff";
import {EnvelopeIcon} from "@heroicons/react/20/solid";

export default function AlumniStaffDetail() {
    const {id} = useParams();

    const {data: staff} = useQuery(["alumni_staff", id], () =>
        alumniStaffApi('get', id)
    )

    if (!staff) return <></>;

    return (
        <>
            <main className="py-10 h-full bg-gray-100">
                <div
                    className="max-w-3xl mx-auto px-4 sm:px-6 md:flex md:items-center md:justify-between md:space-x-5 lg:max-w-7xl lg:px-8">
                    <div className="flex items-center space-x-5">
                        <div className="flex-shrink-0">
                            <div className="relative">
                                {staff.image.detail && <img
                                    className="h-32 w-32 rounded-full"
                                    src={staff.image.detail}
                                    alt={staff.full_name}
                                />}
                                <span
                                    className="absolute inset-0 shadow-inner rounded-full"
                                    aria-hidden="true"
                                />
                            </div>
                        </div>
                        <div>
                            <h1 className="text-2xl font-bold text-gray-900">
                                {staff.full_name}
                            </h1>
                        </div>
                    </div>
                    <div
                        className="mt-6 flex flex-col-reverse justify-stretch space-y-4 space-y-reverse sm:flex-row-reverse sm:justify-end sm:space-x-reverse sm:space-y-0 sm:space-x-3 md:mt-0 md:flex-row md:space-x-3">

                        {staff.can_edit && <EditButton
                            link={ `/alumni-staff/${staff.id}/edit` }
                            label="Bearbeiten"
                        />}

                        {staff.user_id && <Link to={`/messenger/new?user_id=${staff.user_id}`}>
                            <button type="button"
                                    className={"cursor-pointer inline-flex items-center " +
                                        "justify-center px-4 py-2 border border-transparent text-sm font-medium " +
                                        "rounded-md shadow-sm text-white bg-blue-600 hover:bg-blue-700 " +
                                        "focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-blue-500"}
                            ><EnvelopeIcon className="h-5 w-5 mr-2"/> Nachricht senden
                            </button>
                        </Link>}

                    </div>
                </div>

                <div
                    className="mt-8 max-w-3xl mx-auto grid grid-cols-1 gap-6 sm:px-6 lg:max-w-7xl lg:grid-flow-col-dense lg:grid-cols-3">
                    <div className="space-y-6 lg:col-start-1 lg:col-span-2">

                        <section aria-labelledby="applicant-information-title">
                            <div className="bg-white shadow sm:rounded-lg">

                                <div className="px-4 py-5 sm:px-6">
                                    <h2 id="applicant-information-title"
                                        className="text-lg font-medium leading-6 text-gray-900">
                                        Profil
                                    </h2>
                                </div>
                                <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
                                    <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
                                        <div className="sm:col-span-1">
                                            <dt className="text-sm font-medium text-gray-500">Email</dt>
                                            <dd className="mt-1 text-sm text-gray-900">
                                                <a href={`mailto:${staff.email}`}>{staff.email}</a>
                                            </dd>
                                        </div>
                                        <div className="sm:col-span-1">
                                            <dt className="text-sm font-medium text-gray-500">Letzter Login</dt>
                                            <dd className="mt-1 text-sm text-gray-900">{formatDate(staff.last_login)}
                                            </dd>
                                        </div>

                                    </dl>
                                </div>
                            </div>
                        </section>

                    </div>

                    <section
                        aria-labelledby="timeline-title"
                        className="lg:col-start-3 lg:col-span-1"
                    >
                    </section>
                </div>

            </main>
        </>
    );
}
