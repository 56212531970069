import React, {useEffect, useState} from "react";
import {useMessenger} from "../../../contexts/MessengerContext";
import {Input, MultiSelect, Select,} from "../../elements/forms/Inputs";
import RecipientsSelector from "./RecipientsSelector";
import {ConversationFooter} from "./ConversationFooter";

const ConversationNew = () => {
  return (
    <section
      aria-labelledby="message-heading"
      className="flex h-full min-w-0 flex-1 flex-col overflow-hidden xl:order-last"
    >
      <ConversationNewHeader />
      <ConversationFooter />
    </section>
  );
};

const ConversationNewHeader = () => {

  const {
    availableRecipients,
    selectedRecipients,
    isMessageMultipleRecipients,
    messageData,
    changeMessageData,
    handleSingleSelectRecipients,
    handleMultiSelectRecipients,
    replyOn
  } = useMessenger();

  const [errors, setErrors] = useState({});
  const [detailSelectOpen, setDetailSelectOpen] = useState(false);

  useEffect(() => {
    handleMultiSelectRecipients(selectedRecipients, "draft_recipients");
  }, [selectedRecipients]);

  return (
    <>
      <div className="min-h-0 flex-1 overflow-y-auto px-4 pt-4">
        <Input
          path={"subject"}
          label="Betreff"
          className={"mb-3"}
          errors={errors}
          data={messageData}
          inputClassName=""
          onChange={changeMessageData}
        />
        <div className="grid grid-cols-2 gap-4 items-end">

          {isMessageMultipleRecipients
            ? <>
              <MultiSelect
                path={"draft_recipients"}
                label={"Empfänger"}
                options={availableRecipients}
                valueAttr={"value"}
                labelAttr={"label"}
                errors={errors}
                data={messageData}
                onChange={(selection) => handleMultiSelectRecipients(selection, "draft_recipients")}
                valueOnly={true}
              />
              <div>
                <button
                  type="button"
                  onClick={() => setDetailSelectOpen(true)}
                  className="inline-flex items-center justify-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-blue-500"
                >
                  Detaillierte Auswahl
                </button>
              </div>
            </>
            : <Select
              path={"draft_recipients[0]"}
              label="Empfänger"
              options={availableRecipients}
              valueAttr={"value"}
              labelAttr={"label"}
              errors={errors}
              data={messageData}
              onChange={(selection) => handleSingleSelectRecipients(selection, "draft_recipients")}
              valueOnly={true}
            />}
        </div>

      </div>

      <RecipientsSelector
        open={detailSelectOpen}
        setOpen={setDetailSelectOpen}
      />
    </>
  )
}

export default ConversationNew;
