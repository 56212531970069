import React from "react";

const ClearableBadge = ({ title, action, clearable }) => {
  return (
    <span
      className={`inline-flex items-center rounded-full bg-imsblue-100 py-0.5 pl-2 ${
        clearable ? "pr-0.5" : "pr-2"
      } text-xs font-medium text-imsblue-700 ml-2 cursor-pointer`}
      onClick={() => {
        if (!clearable) action();
      }}
    >
      {title}
      {clearable ? (
        <button
          type="button"
          onClick={action}
          className="ml-0.5 inline-flex h-4 w-4 flex-shrink-0 items-center justify-center rounded-full text-imsblue-400 hover:bg-imsblue-200 hover:text-imsblue-500 focus:bg-imsblue-500 focus:text-white focus:outline-none"
        >
          <span className="sr-only">Clear</span>
          <svg
            className="h-2 w-2"
            stroke="currentColor"
            fill="none"
            viewBox="0 0 8 8"
          >
            <path
              strokeLinecap="round"
              strokeWidth="1.5"
              d="M1 1l6 6m0-6L1 7"
            />
          </svg>
        </button>
      ) : (
        ""
      )}
    </span>
  );
};

export default ClearableBadge;
