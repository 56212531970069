import React, {useEffect} from "react";
import _ from "lodash";
// import Logo from "../layout/Logo";
// import {useRef, useState} from 'react'
// import {Link} from 'react-router-dom'
// import Alert from './../elements/Alert'
// import { useParams } from "react-router-dom";
//
// import http from "./../../services/httpNoAuthService";
//
// import Joi from "joi"
// import useValidator from "react-joi"
//
// import {passwordRegEx, passwordErrorMessage} from "../../utils/regularExpressions";
// import {validate, validateProperty} from "../../utils/validation";


export function FormSectionTitle({title, children}) {

    return (
        <div>
            <h3 className="text-lg leading-6 font-medium text-gray-900">{title}</h3>
            <p className="mt-1 text-sm text-gray-500">
                {children}
            </p>
        </div>
    )
}


export function FormSectionContent({children}) {

    return (
        <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
            {children}
        </div>
    )
}

export function FormRow({children}) {

    return (
        <div className="col-span-6">
            {children}
        </div>
    )
}