import http from "./../services/httpService";
import {genericApi} from "./generic";

// rooms & room bookings
export async function getRooms() {
    const resp = await http.get("/rooms/");
    return resp.data;
}

export async function getBookableRooms() {
    const resp = await http.get("/rooms/?can_be_booked=true");
    return resp.data;
}

export async function getStudierzeitRooms() {
    const resp = await http.get("/rooms/?is_studierzeit_room=true");
    return resp.data;
}

export async function getRoomBookingsByWeek(year, week) {
    const resp = await http.get(`/room_bookings/week/?year=${year}&week=${week}`);
    return resp.data;
}

export async function roomBookingApi(method, id, data) {
    return genericApi('/room_bookings/', method, id, data)
}

export async function findRoom(data) {
    const resp = await http.post(`/room_bookings/find_room/`, data);
    return resp.data;
}

export async function cancelRoomBookingOccurrence(id, data) {
    const resp = await http.post(`/room_bookings/${id}/cancel_occurrence/`, data)
    return resp.data
}

export async function uncancelRoomBookingOccurrence(id, data) {
    const resp = await http.post(`/room_bookings/${id}/uncancel_occurrence/`, data)
    return resp.data
}

// devices & device bookings
export async function getDevices() {
    const resp = await http.get("/devices/");
    return resp.data;
}

export async function getDeviceBookingsByWeek(year, week) {
    const resp = await http.get(`/device_bookings/week/?year=${year}&week=${week}`);
    return resp.data;
}

export async function deviceBookingApi(method, id, data) {
    return genericApi('/device_bookings/', method, id, data)
}

export async function findDevice(data) {
    const resp = await http.post(`/device_bookings/find_device/`, data);
    return resp.data;
}

export async function cancelDeviceBookingOccurrence(id, data) {
    const resp = await http.post(`/device_bookings/${id}/cancel_occurrence/`, data)
    return resp.data
}

export async function uncancelDeviceBookingOccurrence(id, data) {
    const resp = await http.post(`/device_bookings/${id}/uncancel_occurrence/`, data)
    return resp.data
}

export async function getAnnouncementCategories() {
    const resp = await http.get("/announcement_categories/");
    return resp.data;
}

export async function getAnnouncements(recentOrAll) {
    const resp = await http.get("/announcements/" + (recentOrAll ? `?all_or_recent=${recentOrAll}` : ''));
    return resp.data;
}

export async function announcementApi(method, id, data) {
    return genericApi('/announcements/', method, id, data)
}

export async function approveAnnouncement(id) {
    const resp = await http.post(`/announcements/${id}/approve/`)
    return resp.data
}

export async function getAnnouncementFeed() {
    const resp = await http.get("/announcements/feed/")
    return resp.data;
}

export async function getNotificationFeed() {
    const resp = await http.get("/notifications/feed/")
    return resp.data;
}

export async function addFileToAnnouncement(formData, id) {
    const resp = await http.post(`/announcements/${id}/add_file/`, formData)
    return resp.data
}

export async function addImageToAnnouncement(formData, id) {
    const resp = await http.post(`/announcements/${id}/add_image/`, formData)
    return resp.data
}

export async function getExistingDownloads() {
    const resp = await http.get(`/announcements/get_downloads/`)
    return resp.data;
}

export async function purchaseRequestApi(method, id, data) {
    return genericApi('/purchase_requests/', method, id, data)
}

export async function approvePurchaseRequest(id) {
    const resp = await http.post(`/purchase_requests/${id}/approve/`)
    return resp.data
}

export async function rejectPurchaseRequest(id) {
    const resp = await http.post(`/purchase_requests/${id}/reject/`)
    return resp.data
}

export async function getKitchenMealtimes() {
    const resp = await http.get(`/organizational/kitchen_mealtimes/`)
    return resp.data;
}

export async function getKeys() {
    const resp = await http.get(`/keys/`)
    return resp.data
}

export async function getKey(id) {
    const resp = await http.get(`/keys/${id}/`)
    return resp.data
}

export async function getKeySystems() {
    const resp = await http.get(`/key_systems/`)
    return resp.data
}

export async function keyIssueApi(method, id, data) {
    return genericApi('/key_issues/', method, id, data)
}

export async function getKeyIssuesForUser(userId) {
    const resp = await http.get(`/key_systems/?user_id=${userId}`)
    return resp.data
}